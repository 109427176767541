import React, { useState, useEffect } from "react";
import Dash from "../../common/dash";
import { Button, ButtonBase, Card, Grid, Input, Select, Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Stack, Box, Modal, MenuItem, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, CircularProgress, Pagination, AppBar, Toolbar, TablePagination, Typography } from "@mui/material";
import { ExpandMore, StarBorder, Done, NoteAlt, ArrowBack, Close, FiberManualRecord } from "@mui/icons-material";
import Switch from "@mui/material/Switch";
import SearchIcon from "@mui/icons-material/Search";
import { Delete, Download } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import approvetick from "../../common/images/approvetick.svg";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import possvg from "../../common/images/pos.svg";
import printersvg from "../../common/images/printer.svg";
import powericon from "../../common/images/powericon.svg";
import cardpay from "../../common/images/cardpay.svg";
import sendpayrequest from "../../common/images/sendpayrequest.svg";
import { escape, parseInt } from "lodash";
import { useSelector } from "react-redux";
import { OutOfStock, MorePayments, SucessModal, CustomerAccounts, ConfirmOrder, SuccessCustomerOnAccounts, AlertIfBalanceIsDepleted, MpesaPaymentModal, BankPayModal, CreditnotepayModal, ConfirmPayCreditNote, ConfirmOverPaymentCreditnote, AllPaymentModal, CashModal, Confirmcashpaymentmodal } from "./startorderModals/modals";

import { universalStyles } from "./startorderuniversalstyles/styles";

import CloseIcon from "@mui/icons-material/Close";

import moment from "moment-timezone";
import { color } from "@mui/system";
import HttpComponent from "../School/MakeRequest";
const baseUrl = process.env.REACT_APP_BASE_URL;
import { useDispatch } from "react-redux";
import { addStartOrderArray, removeStartOrderArray, deleteItemFromArray } from "../../features/startorderSlice";
import { Requestpayment } from "./requestpayment/requestpayment";
import { ErrorAlertRightAligned } from "./startorderModals/modals";

const StartOrder = ({parentId}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const { defaultPosArray } = useSelector((store) => store.startorder);

  const AddProductModalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "477px",
    height: "400px",
  };
  const style = {
    borderColor: "transparent",
    borderRadius: "36px",
    backgroundColor: "#fff",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "300px",
    height: "300px",
  };

  // const set state;
  const businessCategory = localStorage.getItem("businessCategory");
  // CATEGORY TABLE PAGINATION;

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // console.log(pageSize);
  const [totalCategoryCount, setCategoryCount] = useState(0);

  const noOfPages = Math.ceil(totalCategoryCount / pageSize);

  const handlePage = (event, value) => {
    // console.log(value);
    setPage(value);
  };

  // handle table page and row change;
  const [tablePage, setTablePage] = useState(1);
  const [tablePageSize, setTablePageSize] = useState(5);
  const [newCategoryName, setItemCategory] = useState("");
  const handleTableChange = (value) => {
    setTablePage(value);
    // getItemsPerCategory(newCategoryName);
  };
  // ADD NOTE MODAL
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const closeNoteModal = () => {
    setOpenNoteModal(false);
  };

  // BILL COMPONENT DISPLY
  const [billDisplay, setBillDisplay] = useState(false);

  // SAVE BILL MODAL
  const [saveBill, setOpenSaveBill] = useState(false);
  const closeSaveBill = () => {
    setOpenSaveBill(false);
  };

  // SEND BILLT TO STORE;
  const [storeBill, setStoreBill] = useState(false);
  const closeStoreBill = () => {
    setStoreBill(false);
  };

  // POP UP CUSTOMER ON ACCOUNTS MODAL;
  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const closeCustomerModal = () => {
    setOpenCustomerModal(false);
  };

  // WATCH SWITCH STATE;
  const [customerOnAccountsModal, setCustomerOnAccountsModal] = useState(false);
  const [openSwitch, setOpenSwitch] = useState(false);
  const handleSwitchChange = (e) => {
    setOpenSwitch(e.target.checked);
    if (!openSwitch) {
      setCustomerOnAccountsModal(true);
    }
  };

  // CUSTOM COMPONENTS RENDERING;
  const [startOrderComponent, setStartOrderComponent] = useState(true);
  const [requestPaymentComponent, setRequestOrder] = useState(false);
  const [requestPayId, setRequestPayId] = useState("")

  const toggleStartOrderComponents = () => {
    if (startOrderComponent) {
      setStartOrderComponent(false);
      setRequestOrder(true);
    }

    if (requestPaymentComponent) {
      setRequestOrder(false);
      setStartOrderComponent(true);
    }
  };
  // PAYMENT MODAL POP UP;
  const [openPayModal, setPayOpenModal] = useState(false);

  const closePaymentModal = () => {
    setPayOpenModal(false);
  };

  // SNACKBARS;
  const [openSnack, setOpenSnack] = useState(false);

  // CASH PAYMENT MODAL POP UP;
  const [cashModalPayment, setOpenCashPayment] = useState(false);
  const closeCashModal = () => {
    setOpenCashPayment(false);
  };

  // AACORDION DISPLAY;
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // PAYMENT SUCCESSFULLY RECIEVED;
  const [successfullyPaid, setSuccessfullyPaid] = useState(false);
  const closeSuccessfullyPaid = () => {
    setSuccessfullyPaid(false);
  };

  // ITEMS DISCOUNTS;
  const [toggleGeneralDiscount, setToggleGeneralDiscount] = useState(false);
  const [generalDiscountType, setGeneralDiscountType] = useState("");
  const [generalDiscount, setGeneralDiscount] = useState(0);
  const [generalDiscountPercentage, setGeneralDiscountPerecentage] = useState(0);
  const [generalDiscountAmount, setGeneralDiscountAmount] = useState(0);
  // const [toggleDiscountBox, setToggleDiscountBox] = useState("")

  // set general disocunt;
  const setGeneralDiscountFnc = () => {
    if (generalDiscountType === "Amount") {
      if (generalDiscount < overallTotal) setGeneralDiscountAmount(generalDiscount);
    } else if (generalDiscountType === "Percentage") {
      if (generalDiscount < 100) setGeneralDiscountAmount(Math.round((generalDiscount / 100) * parseInt(overallTotal)));
    }
  };

  useEffect(() => {
    let isDiscount = false;

    if (!isDiscount) {
      setGeneralDiscountFnc();
    }
    return () => {
      isDiscount = true;
    };
  }, [generalDiscount, generalDiscountPercentage]);

  const removeDiscount = () => {
    if (!toggleGeneralDiscount) {
      setGeneralDiscount(0);
    }
  };
  useEffect(() => {
    removeDiscount();
  }, [toggleGeneralDiscount]);

  // POS PAYMENT MODAL;
  const [posPayment, setPospayment] = useState(false);

  // Toggle POS AND PRINTER;
  const [showPrinter, setShowPrinter] = useState(false);
  const [sendRequest, setSendRequest] = useState(false);

  const [openVoucherPay, setopenVoucherPay] = useState(false);
  const closeVoucherPay = () => setopenVoucherPay(false);

  // SEND ID TO POS WHEN PAYING;
  const [posPayId, setPosPayId] = useState("");
  const [mobilePayIds, setMobilePayIds] = useState("");
  // CANT ADD PRODUCT MODAL;
  const [noItems, setNoItems] = useState(false);

  // CARD PAYMENT POP UP; && CARD DETAILS;
  const [cardModal, setCardModalOpen] = useState(false);
  const [confirmPayDetails, setConfirmPatDetails] = useState(false);
  const [cardDiscount, setToggleCardDiscount] = useState(false);
  const [cardTypes, setCardTypes] = useState([]);
  const [getAcquirers, setCardAcquirers] = useState([]);
  const [cardPayDiscount, setCardPayDiscount] = useState(0);

  // ONCHANGE CARD DETAILS;
  const [getCardType, setCardType] = useState("");
  const [refNo, setRefNo] = useState("");
  const [getAcquirer, setAcquirer] = useState("");
  const [cardCustomer, setCardCustomerName] = useState("");

  // CHILD ORDER DETAILS;
  // const { parentId } = useParams();
  // const parentId = props?.parentId
  const { carriedCustomerName } = useParams();
  // const [pageSize, setPageSize] = useState(10);
  // const [page, setPage] = React.useState(1);

  // LOGGED IN USER;
  const loggedInUser = localStorage.getItem("group");

  const [isOpen, setIsOpen] = useState(true);
  const onChange = ({ value, name }) => {};

  // GET ALL PRODUCT CATEGORIES;

  const [categoriesList, setCategories] = useState([]);
  const [productsList, setProductsList] = useState({ products: [], isLoading: true });
  const [customerOnAccounts, setCustomerOnAccounts] = useState([]);
  // const [categoryName, setCategoryName] = useState("");
  // console.log(productsList);
  // POS PRODUCT ONCHANGE;

  // ORDER DETAILS
  const [ordermodal, setOrderModal] = useState(false);
  const [order, setOrder] = useState([]);
  const [mpesapay, setMpesaPay] = useState(false);
  const [productsAccordion, setProductsAccordion] = useState(true);
  const [orderOverview, setOrderOverview] = useState(true);
  const [customerType, setCustomerType] = useState("regular");
  const [parentOrder, setParentOrder] = useState(false);
  const [messagesTobeFetched, setMessagesToBeFetched] = useState("");

  const [orderDetails, setOrderDetails] = useState({
    amount: 0,
    transdate: "",
    cashier: "",
    subtotal: 0,
    vat: 0,
    totalAmount: 0,
  });
  const [messagesCollection, setAllMessages] = useState([]);
  const [mobilePayQuery, setMobilePayQuery] = useState("");
  const [bankPayQuery, setBankPayQuery] = useState("");
  const [mpesaAmountModal, setMpesaAmountModal] = useState(false);
  // console.log(allMessages);
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "KES",
    }).format(value);

  // const [itemCount, setProductValue] = useState(0);
  const [itemName, setCheckedProductName] = useState("");
  const [checkedProductId, setCheckedProductId] = useState("");
  const [itemAmount, setCheckedProductPrice] = useState(0);
  const [customerNote, setCustomerNote] = useState("");

  const [tableNo, setTableNo] = useState("");
  const [pay, setPay] = useState(false);
  const [customerBalance, setCustomerBalance] = useState(0);
  const [customerName, setCustomerName] = useState("");
  const [savedOrderId, setSavedOrderId] = useState([]);

  const [paybillBalance, setPayBillBalance] = useState(0);
  const [itemCount, setItemCount] = useState(1);
  const [customerId, setCustomerId] = useState("");
  const [dup, setDup] = useState("");
  const [posSerialNo, setPosSerialNo] = useState("");
  const [idtoPos, setIdToPos] = useState("");
  const [printUnpaidBill, setPrintUnpaidBill] = useState(false);
  const [categoryName, setQueryCategory] = useState("");
  const [categoryCount, setCategoryLimit] = useState("");
  const [queryProducts, setQueryproducts] = useState("");

  // GET PRINTERS;
  const [printers, setPrinters] = useState([]);
  const [pos, setPos] = useState([]);

  // customer on accounts details;

  const [customerOnAccountsName, setCustomerOnAccountsName] = useState("");
  const [customerOnAccountsPrintBill, setCustomerOnAccountsPrintBill] = useState("");
  const [customerOnAccountsId, setCustomerOnAccountsId] = useState("");
  const [customerOnAccountsQuery, setCustomerOnAccountsQuery] = useState("");
  // console.log(customerOnAccountsPrintBill);
  const [customeronAccountsCustomerType, setCustomerOnAccountsCustomerType] = useState("");
  const [customerPaymentType, setCustomerPaymentType] = useState("")
  const [customerOnAccountsBalance, setCustomerOnAccountsBalance] = useState(0);
  const [customerPreview, setCustomerPreview] = useState(false);
  const [customerConfirm, setCustomerConfirm] = useState(false);
  const [sucessCustomerAccounts, setSuccessCustomerAccounts] = useState(false);
  const [canCustomerPay, setCustomerOnAccountsCanPay] = useState(true);
  const [customerBalanceDepleted, setCustomerBalanceDepleted] = useState(false);

  // POS ARRAY;
  const [posArray, setPosArray] = useState(defaultPosArray);
  // console.log(posArray);
  const [overallTotal, setOverallTotal] = useState(0);
  const updateInputValue = (e) => {
    setProductValue(e.target.value);
  };


  const newOverallTotal = parseInt(overallTotal - generalDiscountAmount);
  const customerOnAccountsBill = overallTotal - generalDiscountAmount;

  // check if customer on accounts can pay;
  const checkCustomeronAccounts = () => {
    if (customerOnAccountsBalance > customerOnAccountsBill && customerType === "customeronaccounts") {
      setCustomerOnAccountsCanPay(true);
      setCustomerBalanceDepleted(false);
    } else if (customerOnAccountsBalance === customerOnAccountsBill && customerType === "customeronaccounts") {
      setCustomerOnAccountsCanPay(true);
      setCustomerBalanceDepleted(false);
    } else if (customerOnAccountsBalance < customerOnAccountsBill && customerType === "customeronaccounts") {
      setCustomerOnAccountsCanPay(false);
      setCustomerBalanceDepleted(true);
    }
  };
  useEffect(() => {
    let canPay = false;

    if (!canPay) {
      checkCustomeronAccounts();
    }

    return () => {
      canPay = true;
    };
  }, [customerOnAccountsBill, customerOnAccountsBalance]);

  // cash payment details;
  const [itemDiscount, setItemDiscount] = useState(0);
  const [cashRecieved, setCashRecieved] = useState(0);
  // CASH PAYMENT DETAILS;
  const [cashPaymentDetails, setCashPaymentDetails] = useState(false);
  const closeCashPaymentDetails = () => {
    setCashPaymentDetails(false);
  };
  const [confrimPayModal, setConfirmPayModal] = useState(false);

  // DSCOUNT CHECK BOX TOGGLE;
  const [checkedDiscount, setCheckedDiscount] = useState(false);
  const setCheckledStatus = () => {
    setCheckedDiscount(!checkedDiscount);
  };
  // const newTransAmount = newOverallTotal - parseInt(cashRecieved)

  let balance = parseInt(cashRecieved) - parseInt(overallTotal - generalDiscountAmount);

  // GET CUSTOMER BALANCE;
  if (Math.sign(balance) === -1) {
    var newcustomerBalance = balance * -1;
  } else if (Math.sign(balance) === 1) {
    var newcustomerBalance = balance;
  }
  var cashtransamount = 0;

  if (Math.sign(balance) === -1) {
    cashtransamount = parseInt(cashRecieved);
  } else if (Math.sign(balance) === 1) {
    cashtransamount = parseInt(newOverallTotal);
  } else if (Math.sign(balance) === 0) {
    cashtransamount = parseInt(newOverallTotal);
  }

  // MPESA VOOMA AND EQUITEL DISCOUNT;
  const allPayTotals = parseInt(overallTotal) - parseInt(generalDiscountAmount);
  const [allPaymentsDiscount, setAllPaymentsDiscount] = useState(0);
  const [allPaymentsCheck, setAllPaymentsCheck] = useState(false);

  const [allPayAmount, setAllPayAmount] = useState(0);

  const [allPayCustomerName, setAllPayCustomerName] = useState("");
  const [allPayCustomerNumber, setAllPayCustomerNumber] = useState("");
  const [allPayTransId, setAllPayTransId] = useState("");

  // console.log("new logs", allPayAmount, allPayCustomerName, allPayTransId);
  // MPESA PAY DETAILS;
  const [mpesaRecieved, setMpesaRecieved] = useState(0);
  const [mpesaCustomeerName, setMpesaCustomerName] = useState("");
  const [mpesaCustomerNumber, setMpesaCustomerNumber] = useState("");
  const [mpesaTransId, setMpesaTransId] = useState("");
  const [checked, setMpesaChecked] = useState([]);
  const [mpesaPayModal, setMpesaPayModal] = useState(false);

  const mpesaBalance = parseInt(allPayAmount) - parseInt(overallTotal - generalDiscountAmount);
  const mpesaTotal = parseInt(overallTotal) - parseInt(generalDiscountAmount);

  // console.log(mpesaBalance);
  var mpesatransamount = 0;
  var newMpesaBalance = 0;
  if (Math.sign(mpesaBalance) === -1) {
    mpesatransamount = parseInt(allPayAmount);
    var newMpesaBalance = mpesaBalance * -1;
  } else if (Math.sign(mpesaBalance) === 1) {
    mpesatransamount = parseInt(mpesaTotal);
  } else if (Math.sign(mpesaBalance) === 0) {
    mpesatransamount = parseInt(mpesaTotal);
  }

  // VOOMA PAYMENTS;
  const [voomaRecieved, setVoomsRecieved] = useState(0);
  const [voomaName, setVoomaName] = useState("");
  const [voomaCustomerNum, setVoomaCustomerNum] = useState("");
  const [voomaTransId, setVoomaTransId] = useState("");

  const voomaBalance = parseInt(allPayAmount) - parseInt(overallTotal - generalDiscountAmount);
  const voomaTotal = parseInt(overallTotal) - parseInt(generalDiscountAmount);

  var voomaAmount = 0;
  var newVoomaBalance = 0;
  if (Math.sign(voomaBalance) === -1) {
    voomaAmount = parseInt(allPayAmount);
    var newVoomaBalance = voomaBalance * -1;
  } else if (Math.sign(voomaBalance) === 1) {
    voomaAmount = parseInt(voomaTotal);
  } else if (Math.sign(voomaBalance) === 0) {
    voomaAmount = parseInt(voomaTotal);
  }

  // EQUITELN PAYMENTS;

  const [equitelRecieved, setEquitelRecieved] = useState(0);
  const [equitelName, setEquitelName] = useState("");
  const [equitelCustomerNum, setEquitelCustomerNum] = useState("");
  const [equitelTransId, setEquitelTransId] = useState("");

  const equitelBalance = parseInt(allPayAmount) - parseInt(overallTotal - generalDiscountAmount);
  const equitelTotal = parseInt(overallTotal) - parseInt(generalDiscountAmount);

  var equitelAmount = 0;
  var newEquitelBalance = 0;
  if (Math.sign(equitelBalance) === -1) {
    equitelAmount = parseInt(allPayAmount);
    var newEquitelBalance = equitelBalance * -1;
  } else if (Math.sign(equitelBalance) === 1) {
    equitelAmount = parseInt(equitelTotal);
  } else if (Math.sign(equitelBalance) === 0) {
    equitelAmount = parseInt(equitelTotal);
  }

  // CARD PAYMENTS;

  const cardTotal = parseInt(overallTotal - generalDiscountAmount);
  const cardBalance = parseInt(cardTotal - generalDiscountAmount);

  // BANK PAYMENT DETAILS;
  const [bankPayModal, setBankPayModal] = useState(false);
  const [bankPay, setNewBankPayModal] = useState(false);
  const [bankDetailsDropDown, setBanDetailsDropDown] = useState(true);
  const [bankDetailsMessages, setBankDetailsMessages] = useState(false);
  // BANK PAYMENTS;
  const [bankList, setBankLists] = useState(true);
  const [bankMessages, setBankMessages] = useState(false);
  const [bankCustomerName, setBankCustomerName] = useState("");
  const [bankpayAmount, setPayBankAmount] = useState(0);
  const [bankPayNum, setBankPayNum] = useState("");

  const [bankPayDate, setpayBankDate] = useState("");
  const [bankPayId, setPayBankId] = useState("");

  const [voucherNumber, setvoucherNumber] = useState("");

  const bankTotal = parseInt(overallTotal) - parseInt(generalDiscountAmount);

  const bankBalance = parseInt(bankpayAmount) - parseInt(overallTotal - generalDiscountAmount);
  let bankCustomerBalance = 0;
  var bankAmount = 0;
  if (Math.sign(bankBalance) === -1) {
    bankAmount = parseInt(bankpayAmount);
    bankCustomerBalance = bankBalance * -1;
  } else if (Math.sign(bankBalance) === 1) {
    bankAmount = parseInt(bankTotal);
  } else if (bankBalance === 0) {
    bankAmount = parseInt(bankTotal);
  }

  // CREDIT NOTE PAYMENT;
  const [openCreditNote, setOpenCreditNote] = useState(false);
  const [creditnotesearch, setCreditnotesearch] = useState("");
  const [creditNoteData, setCreditNoteData] = useState("");
  const [creditNoteError, setCreditNoteError] = useState("");
  const [openConfirmCredit, setopenConfirmCredit] = useState(false);
  const [creditNotedetails, setCreditNoteDetails] = useState({ amount: 0, no: "" });
  const [openCreditpayOverPayment, setOpenCreditPayOverPayment] = useState(false);
  // get creditnotes ;
  const [creditNoteId, setCreditNoteId] = useState("");
  const [creditNotePayId, setCreditNotePayId] = useState("");

  // REQUEST PAYMENT SETUP
  const [requestPaymentId, setRequestPaymentId] = useState("")


  const searchCreditUrl = () => {
    HttpComponent({
      method: "GET",
      url: `/api/search_credit_note_voucher?voucherNumber=${creditnotesearch}`,
      token: localStorage.getItem("X-Authorization"),
      body: null,
    })
      .then((data) => {
        // console.log(data);
        if (data.status === 200) {
          setCreditNoteData(data.response.data);
        }

        if (data.status === 400) {
          setCreditNoteError(data.response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    searchCreditUrl();
  }, [creditnotesearch]);

  const getBsType = async () => {
    try {
      const bsTypeResp = await fetch(baseUrl + "/api/listBusinessCategory?state=Active", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      //  console.log(bsTypeResp);
      //  const business
    } catch (error) {
      console.log(error.messsage);
    }
  };

  useEffect(() => {
    getBsType();
  }, []);

  const getAllCategories = async () => {
    try {
      const allCategoriesResp = await fetch(baseUrl + `/api/listByCategories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      await allCategoriesResp.json().then((data) => {
        setCategoryCount(data.count);
        const categoryData = data?.categories?.map((cat) => {
          // getItemsPerCategory(cat.category);

          return {
            category: cat.category,
          };
        });

        setCategories(categoryData);
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    let checkReturned = false;
    if (!checkReturned) {
      getAllCategories();
    }

    return () => {
      checkReturned = true;
    };
  }, []);

  // GET PRODUCTS PER CATEGORIES
  const getItemsPerCategory = async (categoryName) => {
    // console.log(categoryName);
    // if(categoryName)
    const itemPerCategoryResp = await fetch(
      // baseUrl + `/api/searchByCategories?search=Soft Drinks`,

      // baseUrl + `/api/listByProducts?search=${categoryName}&page=1&limit=10`,
      // baseUrl + `/api/listByProducts?search=${escape(categoryName)}`,
      // baseUrl + `/api/listByProducts?search=${escape(categoryName)}&page=${tablePage}&limit=${tablePageSize}`,
      baseUrl + `/api/listByProducts?search=${escape(categoryName)}&page=${1}&limit=${5}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      }
    );

    // console.log(itemPerCategoryResp);
    await itemPerCategoryResp.json().then((data) => {
      // console.log(data);
      const productData = data?.products?.map((product) => {
        // console.log(product);
        return {
          id: product._id,
          productCategory: product.productCategory,
          productPrice: product.productPrice,
          productName: product.productName,
          outOfStock: product.outOfStock,
          productCode: product.productCode,
          unitOfMeasure: product.unitOfMeasure,
          quantityInStock: product.quantityInStock,
          priceStatus: product.priceStatus,
        };
      });

      setProductsList({ ...productsList, products: productData, isLoading: false });
    });
  };

  // query categories;
  const queryItemsPerCategory = async () => {
    // console.log(queryProducts.length);
    if (queryProducts.length === 0) {
      getItemsPerCategory(escape(newCategoryName));
    }
    if (newCategoryName && queryProducts)
      // console.log(newCategoryName, "======>", queryProducts);

      try {
        // `/api/searchProducts?c_search=&p_search=&page=1&limit=10&searchValue=${escape(productValue)}
        const searchedItemsResp = await fetch(baseUrl + `/api/searchProducts?c_search=&p_search=&page=1&limit=10&searchValue=${escape(queryProducts)}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        });

        // console.log(searchedItemsResp);
        const searchedItemData = await searchedItemsResp.json();
        // console.log(searchedItemData);

        if (queryProducts.length === 0) {
          getItemsPerCategory(escape(newCategoryName));
        } else {
          setProductsList({ ...productsList, products: searchedItemData.products });
        }
      } catch (error) {
        console.log(error.message);
      }
  };

  useEffect(() => {
    let isItemsTrue = false;

    if (!isItemsTrue) {
      queryItemsPerCategory();
    }
    isItemsTrue = true;
  }, [newCategoryName, queryProducts]);

  // QR CODE SCANNER;
  const [barCode, setBarCode] = useState("");

  const addProductsByScan = () => {
    var barCode = "";
    var interval;

    document.addEventListener("keydown", function (e) {
      if (interval) {
        clearInterval(interval);
      }

      if (e.code == "Enter") {
        if (barCode) {
          setBarCode(barCode);
          barCode = "";
          return;
        }
      }

      if (e.key != "Shift") {
        barCode += e.key;
        interval = setInterval(() => (barCode = ""), 20);
      }
    });
  };
  useEffect(() => {
    let isBarCodeRead = false;

    if (!isBarCodeRead) {
      addProductsByScan();
    }

    return () => {
      // console.log("Clean up function just happened!");
      setBarCode("");
      isBarCodeRead = true;
    };
  }, [barCode]);

  const getProductWithQr = async () => {
    if (barCode) {
      try {
        const getProductWithQrResp = await fetch(baseUrl + `/api/get_product_by_barcode?barcode=${barCode}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        });

        // console.log(getProductWithQrResp);

        switch (getProductWithQrResp.status) {
          case 200:
            const getProductWithQrData = await getProductWithQrResp.json();
            // console.log(getProductWithQrData.data);

            addProductsToPosArray(getProductWithQrData.data);
            checkprodIsoutOfStock(getProductWithQrData.data);
            setBarCode("");
            break;

          default:
            break;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    let isProductAdded = false;

    if (!isProductAdded) {
      getProductWithQr();
    }

    return () => {
      // console.log("product clean up function just happened!");
      setBarCode("");
      isProductAdded = true;
    };
  }, [barCode]);

  const addProductsToPosArray = (product) => {
    // console.log(product);
    if (product.outOfStock === true || product.quantityInStock === 0 || Math.sign(product.quantityInStock) === -1) {
      setNoItems(true);
    } else {
      const newPosArray = [...posArray];
      // create object and add
      const posProduct = {
        itemName: product.productName,
        itemAmount: product.productPrice,
        checkedProductId: product.id || product._id,
        itemCount: 1,
        totalAmount: product.productPrice * 1,
        customerNote: "",
        itemCategory: product.productCategory,
        unitOfMeasure: product.unitOfMeasure,
        reciptNumber: Date.now(),
        quantityInStock: product.quantityInStock,
        priceStatus: product.priceStatus,
      };

      if (posProduct.itemName) {
        const currentIndex = newPosArray.findIndex((posProduct) => posProduct.checkedProductId === product.id);
        if (currentIndex === -1) {
          dispatch(addStartOrderArray(posProduct));
          newPosArray.push(posProduct);
        } else {
          newPosArray?.map((item, index) => {
            if (index === currentIndex) {
              // console.log(item);
              item.itemCount = parseInt(item.itemCount) + 1;
              item.totalAmount = item.itemCount * item.itemAmount;
            }
            return item;
          });
        }
        setPosArray(newPosArray);
      }
    }
  };

  // const filter pos array;

  // const filterPosArray = (posId) => {
  //   setPosArray(posArray.filter((posItem) => posItem.checkedProductId != posId));
  // };
  // remove product from pos Array
  const removeproductFromPosArray = (product) => {
    const newPosRemove = [...posArray];

    const currentIndex = newPosRemove.indexOf(product);

    if (currentIndex >= 0) {
      newPosRemove?.map((item, index) => {
        if (currentIndex === index) {
          newPosRemove.splice(currentIndex, 1);
        }
        return item;
      });
      // newPosRemove.map((item, index) => {
      //   if(currentIndex === index){
      //     if(item.itemCount < 2){
      //       newPosRemove.splice(currentIndex, 1);
      //     }else {
      //       item.itemCount = parseInt(item.itemCount)  - 1
      //      item.totalAmount = item.itemCount * item.itemAmount
      //     }
      //   }
      //   return item
      // })
    }
    setPosArray(newPosRemove);
  };

  const clearStartOrderFromRedux = () => {
    dispatch(removeStartOrderArray());
  };

  // get if product is out of stock;
  const checkprodIsoutOfStock = (posProduct) => {
    // console.log(posProduct);

    const checkoutOfStock = [...posArray];
    let itemCount = 1;
    checkoutOfStock.forEach((product, index) => {
      if (posProduct._id === product.checkedProductId) {
        itemCount += product.itemCount;

        if (itemCount > posProduct.quantityInStock) {
          setNoItems(true);
        }
      }
    });
  };

  // u[pdate variable item price]

  // const updateVariablePriceTotals = (e) => {
  //   const value = e.target.value;
  //   const name = e.target.name;

  //   setPosArray(
  //     posArray.map((item) => ({
  //       ...item,
  //       ...(name === item.checkedProductId ? { itemAmount: value, totalAmount: item.itemCount * value } : {}),
  //     }))
  //   );
  // };

  const updateVariablePriceTotals = (e) => {
    let updateVariableTotals = [...posArray];
    const value = e.target.value;
    updateVariableTotals?.map((prod, index) => {
      if (parseInt(e.target.name) === index) {
        const itemAmount = prod.itemAmount;
        const itemCount = prod.itemCount;
        const totalAmount = prod.totalAmount;
        const newValue = e.target.value;

        prod.itemAmount = e.target.value;
        prod.totalAmount = e.target.value * prod.itemCount;
      }
    });

    setPosArray(updateVariableTotals);
  };

  // UPDATE EACH INPUT TOTALS;

  // const updateInputTotals = (e) => {
  //   const value = e.target.value;
  //   const name = e.target.name;

  //   setPosArray(
  //     posArray
  //       .map((item) => ({
  //         ...item,
  //         ...(name === item.checkedProductId ? { itemCount: value, totalAmount: item.itemAmount * value } : {}),
  //       }))
  //       .filter((item) => {
  //         item.itemCount > item.quantityInStock ? setNoItems(true) : "";
  //         return item;
  //       })
  //   );
  // };

  // configure pay
  // document.addEventListener("keyup", function(e){

  //   // console.log();
  //   if(e.key === "Enter" && posArray.length > 0 && customerType === "regular"){
  //     // executePay()
  //   setPayOpenModal(true);
  //   }else if(e.key === "Enter" && posArray.length > 0 && customerType === "customeronaccounts") {
  //     setCustomerPreview(true)
  //   }
  // })

  const updateInputTotals = (e) => {
    let updateTotalsArray = [...posArray];
    const value = e.target.value;
    updateTotalsArray?.map((prod, index) => {
      if (parseInt(e.target.name) === index) {
        const itemAmount = prod.itemAmount;
        const itemCount = prod.itemCount;
        const totalAmount = prod.totalAmount;
        const newValue = e.target.value;

        prod.itemCount = e.target.value;
        prod.totalAmount = e.target.value * prod.itemAmount;
      }
    });

    updateTotalsArray.filter((prod, index) => {
      if (parseInt(e.target.name) === index) {
        if (prod.itemCount > prod.quantityInStock) {
          setNoItems(true);
          prod.itemCount = prod.quantityInStock - 1;
        }
      }
    });

    setPosArray(updateTotalsArray);
    // updateTotalsArray.map((prod, index) => ({...prod, ...(parseInt(e.target.name) === index ? {itemCount: value, totalAmount: prod.itemAmount * value} :{} )}))
  };

  // get overrall total;
  useEffect(() => {
    setOverallTotal(
      posArray.length > 0 &&
        posArray.reduce((total, curr) => {
          return (total += curr.totalAmount);
        }, 0)
    );
  });

  // UPDATE CUSTOMER NOTE;

  // const addArrayNote = (id) => {
  //   // posArray.length > 0 &&
  //   posArray.filter((item) => {
  //     console.log(item);
  //     if (item.checkedProductId === customerId) {
  //       item.customerNote = customerNote;
  //     }
  //   });
  // };

  // console.log(messagesTobeFetched);

  // GET POS AND PRINTERS;
  const getPrinters = async () => {
    try {
      const getPrintersResp = await fetch(baseUrl + "/api/get_business_printers", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({
          businessNo: localStorage.getItem("businessId"),
          userId: localStorage.getItem("userId"),
        }),
      });

      if (getPrintersResp.status === 200) {
        const printers = await getPrintersResp.json();
        setPrinters({ ...printers, printers: printers.data });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getPrinters();
  }, []);

  // GET POS;
  const getPos = async () => {
    try {
      const getPosResp = await fetch(baseUrl + "/api/allTerminals/Active", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      if (getPosResp.status === 200) {
        const posS = await getPosResp.json();

        setPos(posS.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getPos();
  }, []);

  // GET CARD TYPES;
  const getCardTypes = async () => {
    try {
      const getCardsResp = await fetch(baseUrl + "/api/getCards", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      const cardData = await getCardsResp.json();
      setCardTypes(cardData.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getCardTypes();
  }, []);

  // GET ACQUIRER;
  const getAcquirersCard = async () => {
    const getAcquirersResp = await fetch(baseUrl + "/api/get_acquirer_networks", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });

    const acquirerData = await getAcquirersResp.json();

    setCardAcquirers(acquirerData.data);
  };

  useEffect(() => {
    getAcquirersCard();
  }, []);

  // GET ALL CUSTOMERS ON ACCOUNT;
  const getAllCustomersOnAccounts = async () => {
    try {
      const allCustomersResp = await fetch(baseUrl + "/api/customers_list", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      await allCustomersResp.json().then((data) => {
        setCustomerOnAccounts(data.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCustomersOnAccounts();
  }, []);

  // GET SINGLE CUSTOMER ON ACCOUNTS  CHILD ORDER;

  const getSingleCustomer = (customer) => {
 
    setCustomerType("customeronaccounts");
    setCustomerOnAccountsName(customer?.customerName);
    setCustomerOnAccountsBalance(customer?.availableBalance);
    setCustomerOnAccountsCustomerType(customer?.customerType);
    setCustomerOnAccountsId(customer?._id);
    setCustomerPaymentType(customer?.paymentType)
  };

  // PRINT UNPAID BILL

  // SAVE ORDER TO THE THE DB;
  const saveOrderToBd = async () => {
    try {
      const saveOrderResp = await fetch(baseUrl + "/api/savePushy", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({
          beneficiary: "N/A",
          businessId: localStorage.getItem("businessId"),
          cashier: localStorage.getItem("username"),
          customerBalance: customerType === "regular" ? newcustomerBalance : customerOnAccountsBalance - overallTotal,
          customerId: customerType === "customeronaccounts" ? customerOnAccountsId : "N/A",
          customerName: customerType === "regular" ? customerName : customerOnAccountsName,
          customerPaymentType: customerType === "customeronaccounts" ? customerPaymentType : "N/A",
          customerType: customerType === "regular" ? "regular" : customerType === "customeronaccounts" ? customeronAccountsCustomerType : "N/A",
          items: posArray,
          mileage: "0",
          orderTable: tableNo ,
          orderType: customerType === "customeronaccounts" ? "Customer On Account" : "N/A",
          parentOrderId: parentId === "startorder" ? "" : parentId,
          paymentMethod: "N/A",
          pushTransactionId: Date.now(),
          serialNo: "",
          status: "unpaid",
          transamount: customerType === "customeronaccounts" ? parseInt(customerOnAccountsBill) : overallTotal,
          transtime: new Date(Date.now()),
          userId: localStorage.getItem("userId"),
          // discountAmount: generalDiscountAmount,
          discountAmount: parseInt(generalDiscountAmount),
        }),
      });

      const saveOrderData = await saveOrderResp.json();

      if (saveOrderResp.status === 201) {
        clearStartOrderFromRedux();
        setCustomerOnAccountsPrintBill(saveOrderData.data._id);
        setOpenSaveBill(false);
        if (customerType === "customeronaccounts") {
          setCustomerConfirm(true);
        }
        if (businessCategory !== "Eatery, Bar and Restaurant" || businessCategory !== "Hotel") {
          setOpenSnack(true);

          if (customerType === "regular") {
            setTimeout(() => {
              setOpenSnack(false);
              window.location.reload();
              // setSuccessfullyPaid(true);
            }, 2000);
          } else {
            setTimeout(() => {
              setOpenSnack(false);
              // window.location.reload();
              // setSuccessfullyPaid(true);
            }, 2000);
          }
        } else {
          setOpenSnack(true);
          // setSuccessfullyPaid(true);
        }

        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      }else if(saveOrderResp.status === 400){
        setIsError({state:true, message: saveOrderData.message})
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // PRINT UNPAID BILL AND SAVE ORDER TO THE DATABASE;

  const saveOrderToDbAndPrint = async () => {
    try {
      const saveOrderResp = await fetch(baseUrl + "/api/savePushy", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({
          beneficiary: "N/A",
          businessId: localStorage.getItem("businessId"),
          cashier: localStorage.getItem("username"),
          customerBalance: customerType === "regular" ? 0 : customerOnAccountsBalance - overallTotal,
          customerId: "N/A",
          customerName: customerType === "regular" ? customerName : customerOnAccountsName,
          customerPaymentType: "N/A",
          customerType: customerType === "regular" ? "regular" : customerType === "customeronaccounts" ? "customeronaccounts" : "N/A",
          items: posArray,
          mileage: "0",
          orderTable: tableNo,
          orderType: "N/A",
          parentOrderId: parentId === "startorder" ? "" : parentId,
          paymentMethod: "N/A",
          pushTransactionId: Date.now(),
          serialNo: "",
          status: "unpaid",
          transamount: customerType === "customeronaccounts" ? parseInt(customerOnAccountsBill) : overallTotal,
          transtime: new Date(),
          userId: localStorage.getItem("userId"),
          discountAmount: generalDiscountAmount,
        }),
      });
      // console.log(saveOrderResp);

      const saveOrderData =   await saveOrderResp.json()
      if (saveOrderResp.status === 201) {
        clearStartOrderFromRedux();
        setOpenSnack(true);
        setTimeout(() => {
          setOpenSnack(false);

          // setSuccessfullyPaid(true);
        }, 2000);
        setIdToPos(saveOrderData.data._id);

        setSendRequest(false);
        setPospayment(true);
        setStoreBill(true);
      }else if(saveOrderResp.status === 400){
        setIsError({state:true, message: saveOrderData.message})
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // SEND DATA TO PRINTER

  const sendToPrinter = async () => {
    const sendToPrinterResp = await fetch(baseUrl + "/api/sendPushy", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": " *",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
      body: JSON.stringify({
        pushTransactionId: posPayId !== "" ? posPayId : mobilePayIds !== "" ? mobilePayIds : customerType === "customeronaccounts" ? customerOnAccountsPrintBill : creditNoteId ? creditNoteId : requestPaymentComponent ?  localStorage.getItem("requestPayId") :idtoPos,
        serialNo: posSerialNo,
      }),
    });

    if (sendToPrinterResp.status === 409) {
      setPospayment(false);
      closeSuccessfullyPaid(true);
      setIdToPos("");
      setPosPayId("");
      setMobilePayIds("");
      setCreditNoteId("");
      localStorage.removeItem("requestPayId")
      window.location.reload();
    } else {
      setIdToPos("");
      setPosPayId("");
      setCreditNoteId("");
      localStorage.removeItem("requestPayId")
      window.location.reload();
    }
  };

  //  EXECUTE THE PAY;

  // SAVE ORDER TO THE THE DB;
  const saveOrderToDb = async () => {
    try {
      const saveOrderResp = await fetch(baseUrl + "/api/savePushy", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({
          beneficiary: "N/A",
          businessId: localStorage.getItem("businessId"),
          cashier: localStorage.getItem("username"),
          customerBalance: customerType === "regular" ? 0 : customerOnAccountsBalance - overallTotal,
          customerId: "N/A",
          customerName: customerType === "regular" ? customerName : customerOnAccountsName,
          customerPaymentType: "N/A",
          customerType: customerType === "regular" ? "regular" : customerType === "customeronaccounts" ? "customeronaccounts" : "N/A",
          items: posArray,
          mileage: "0",
          orderTable: tableNo,
          orderType: "N/A",
          parentOrderId: parentId === "startorder" ? "" : parentId,
          paymentMethod: "N/A",
          pushTransactionId: Date.now(),
          serialNo: "",
          status: "unpaid",
          transamount: overallTotal,
          transtime: new Date(),
          userId: localStorage.getItem("userId"),
          discountAmount: generalDiscountAmount,
        }),
      });

      const saveOrderData =   await saveOrderResp.json()
      if (saveOrderResp.status === 201) {
        clearStartOrderFromRedux();
        executePay(saveOrderData.data._id);
        // console.log(saveOrderData.data._id);
        setPosPayId(saveOrderData.data._id);
      }else if(saveOrderResp.status === 400){
        setIsError({state:true, message: saveOrderData.message})
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // search voucher7
  const [searchedVoucher, setSearchedVoucher] = useState();
  const [resultSearch, setresultSearch] = useState(false);
  const [searchVoucherMessage, setsearchVoucherMessage] = useState("");
  const [VoucherInfomessage, setVoucherInfomessage] = useState("");
  const [titleMessage, settitleMessage] = useState("Confirmation Message");
  const [voucherValidationOpen, setvoucherValidationOpen] = React.useState(false);
  const ClosevoucherValidationClose = () => setvoucherValidationOpen(false);

  const [confirmationVoucherOPen, setconfirmationVoucherOPen] = React.useState(false);
  const closeconfirmationVoucher = () => setconfirmationVoucherOPen(false);

  const searchVoucher = async () => {
    try {
      const res = await fetch(`${baseUrl}/api/search_voucher_by_voucher_number?voucherNumber=${voucherNumber}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      const data = await res.json();
      if (data.status == "Success") {
        setresultSearch(true);
        setSearchedVoucher(data.data);
      } else {
        setsearchVoucherMessage(data.message);
        setresultSearch(false);
      }
    } catch {}
  };
  useEffect(() => {
    if (voucherNumber != "") {
      searchVoucher();
    }
  }, [voucherNumber]);

  // console.log(cashRecieved - overallTotal);
  // console.log(balance);

  // SAVE ORDER TO THE DATABASE AND POPULATE MPESA
  const saveOrderToDbAndPopulateMpesa = async () => {
    try {
      const saveOrderResp = await fetch(baseUrl + "/api/savePushy", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({
          beneficiary: "N/A",
          businessId: localStorage.getItem("businessId"),
          cashier: localStorage.getItem("username"),
          customerBalance: customerType === "regular" ? 0 : customerOnAccountsBalance - overallTotal,
          customerId: "N/A",
          customerName: customerType === "regular" ? customerName : customerOnAccountsName,
          customerPaymentType: "N/A",
          customerType: customerType === "regular" ? "regular" : customerType === "customeronaccounts" ? "customeronaccounts" : "N/A",
          items: posArray,
          mileage: "0",
          orderTable: tableNo,
          orderType: "N/A",
          parentOrderId: parentId === "startorder" ? "" : parentId,
          paymentMethod: "N/A",
          pushTransactionId: Date.now(),
          serialNo: "",
          status: "unpaid",
          transamount: overallTotal,
          transtime: new Date(),
          userId: localStorage.getItem("userId"),
          discountAmount: generalDiscountAmount,
        }),
      });

      const saveOrderData =   await saveOrderResp.json()
      if (saveOrderResp.status === 201) {
        clearStartOrderFromRedux();
        setOpenSnack(true);
        setTimeout(() => {
          setOpenSnack(false);

          // setSuccessfullyPaid(true);
        }, 2000);
        setRequestPaymentId(saveOrderData.data._id)
        getDetailedBill(saveOrderData.data._id);
        // console.log(saveOrderData.data._id);
        // console.log(saveOrderData.data._id);
        setMobilePayIds(saveOrderData.data._id);
        // if(messagesTobeFetched === "MOESA"){
        localStorage.setItem("bankPayId", saveOrderData.data._id);
        localStorage.setItem("mpesaPayId", saveOrderData.data._id);
        // }else if(messagesTobeFetched === "VOOMA"){
        localStorage.setItem("voomaPayId", saveOrderData.data._id);
        // }else if(messagesTobeFetched === "EQUITEL"){
        localStorage.setItem("equitelPayId", saveOrderData.data._id);
        localStorage.setItem("voucher", saveOrderData.data._id);
        // }else if(messagesTobeFetched === "CARD"){
        localStorage.setItem("cardPayId", saveOrderData.data._id);
        setCreditNotePayId(saveOrderData.data._id);
        setRequestPayId(saveOrderData.data._id)
        localStorage.setItem("requestPayId", saveOrderData.data._id)
        // }
      }else if(saveOrderResp.status === 400){
        setIsError({state:true, message: saveOrderData.message})
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // MPESA PAY POPULATE DETAILED BILL;
  // GET DETAILED BILL
  const getDetailedBill = async (id) => {
    // console.log(id);
    if (id)
      try {
        const getDetailResp = await fetch(baseUrl + "/api/order_payment_status", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
          body: JSON.stringify({
            pushyTransactionId: id,
            businessNo: localStorage.getItem("businessId"),
          }),
        });

        await getDetailResp.json().then((data) => {
          const getOrderData = data.order;
          // console.log(getOrderData);
          setOrder(getOrderData.items);
          const { cashier, transamount, subTotal, vat, createdAt } = getOrderData;

          setOrderDetails({
            ...orderDetails,
            amount: transamount,
            transdate: createdAt,
            cashier: cashier,
            subtotal: subTotal.toFixed(2),
            vat: vat.toFixed(2),
            totalAmount: 0,
          });
        });
      } catch (error) {
        console.log(error);
      }
  };

  useEffect(() => {
    getDetailedBill();
  }, []);

  // GET UNUSED MPESA MESSAGES;
  const getUnusedMpesaMessage = async () => {
    try {
      const messagesResp = await fetch(baseUrl + `/api/getAllSms?type=${messagesTobeFetched}&status=Unused`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      // console.log(messagesResp);
      if (messagesResp.status === 200) {
        const messagesData = await messagesResp.json();

        setAllMessages(messagesData.sms);
        // console.log(messagesData.sms);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    getUnusedMpesaMessage();
  }, [messagesTobeFetched]);

  // clear used messages;
  const unUseMessage = async (sms) => {
    // console.log("function running", sms.sms);
    try {
      const unUseMessoResp = await fetch(baseUrl + "/api/updatePosSms", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({
          sms: sms.sms,
        }),
      });
      // const unUseMessoRespData = await unUseMessoResp.json();
    } catch (error) {
      console.log(error.message);
    }
  };

  const executePay = async (data) => {
    try {
      const executePayResp = await fetch(baseUrl + "/api/transactions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({
          appBuildTime: "N/A",
          billRefNo: Date.now(),
          businessName: localStorage.getItem("businessName"),
          businessNo: localStorage.getItem("businessId"),
          businessShortCode: "0000",
          cashier: localStorage.getItem("username"),
          customerFirstName: messagesTobeFetched === "COOP" ? bankCustomerName : messagesTobeFetched === "DTB" ? bankCustomerName : messagesTobeFetched === "ABSA" ? bankCustomerName : messagesTobeFetched === "MPESA" ? allPayCustomerName : messagesTobeFetched === "VOOMA" ? allPayCustomerName : messagesTobeFetched === "EQUITEL" ? allPayCustomerName : customerType === "customeronaccounts" ? customerOnAccountsName : " ",
          customerMiddleName: "N/A",
          customerPhone: messagesTobeFetched === "COOP" ? bankPayNum : messagesTobeFetched === "DTB" ? bankPayNum : messagesTobeFetched === "ABSA" ? bankPayNum : messagesTobeFetched === "MPESA" ? allPayCustomerNumber : messagesTobeFetched === "VOOMA" ? allPayCustomerNumber : messagesTobeFetched === "EQUITEL" ? allPayCustomerNumber : " ",
          customerSecondName: "N/A",
          items: posArray,
          // paybillBalance: balance,
          paybillBalance: messagesTobeFetched === "COOP" ? bankBalance : messagesTobeFetched === "ABSA" ? bankBalance : messagesTobeFetched === "DTB" ? bankBalance : messagesTobeFetched === "CARD" ? cardBalance : messagesTobeFetched === "MPESA" ? mpesaBalance : messagesTobeFetched === "VOOMA" ? voomaBalance : messagesTobeFetched === "EQUITEL" ? equitelBalance : customerType === "customeronaccounts" ? 0 : balance,
          // discountAmount: messagesTobeFetched === "COOP" ? bankDiscountAmount : messagesTobeFetched === "ABSA" ? bankDiscountAmount : messagesTobeFetched === "DTB" ? bankDiscountAmount : messagesTobeFetched === "CARD" ? parseInt(cardPayDiscount) : messagesTobeFetched === "MPESA" ? parseInt(allPaymentsDiscount) : messagesTobeFetched === "VOOMA" ? parseInt(allPaymentsDiscount) : messagesTobeFetched === "EQUITEL" ? parseInt(allPaymentsDiscount) : parseInt(itemDiscount),
          discountAmount: customerType === "customeronaccounts" ? 0 : parseInt(generalDiscountAmount),
          paymentChanel: "Web",
          productCategory: "--",
          productName: "--",
          pushyTransactionId: customerType === "customeronaccounts" ? [customerOnAccountsPrintBill] : messagesTobeFetched === "COOP" ? [localStorage.getItem("bankPayId")] : messagesTobeFetched === "ABSA" ? [localStorage.getItem("bankPayId")] : messagesTobeFetched === "DTB" ? [localStorage.getItem("bankPayId")] : messagesTobeFetched === "MPESA" ? [localStorage.getItem("mpesaPayId")] : messagesTobeFetched === "VOOMA" ? [localStorage.getItem("voomaPayId")] : messagesTobeFetched === "EQUITEL" ? [localStorage.getItem("equitelPayId")] : messagesTobeFetched === "CARD" ? [localStorage.getItem("cardPayId")] : [data],
          receiptNumber: Date.now(),
          requestType: "N/A",
          serialNo: " N/A",
          transactionID: Date.now(),
          transactionType: messagesTobeFetched === "COOP" ? "COOP" : messagesTobeFetched === "DTB" ? "DTB Payment" : messagesTobeFetched === "ABSA" ? "ABSA" : messagesTobeFetched === "MPESA" ? "Mpesa Payment" : messagesTobeFetched === "VOOMA" ? "Vooma Payment" : messagesTobeFetched === "CARD" ? "Card Payment" : customerType === "customeronaccounts" ? "Customer On Account" : messagesTobeFetched === "EQUITEL" ? "Equity Payment" : "Cash Payment",
          transamount: messagesTobeFetched === "COOP" ? bankAmount : messagesTobeFetched === "ABSA" ? bankAmount : messagesTobeFetched === "DTB" ? bankAmount : messagesTobeFetched === "MPESA" ? mpesatransamount : messagesTobeFetched === "VOOMA" ? voomaAmount : messagesTobeFetched === "EQUITEL" ? equitelAmount : messagesTobeFetched === "CARD" ? cardTotal : customerType === "customeronaccounts" ? parseInt(customerOnAccountsBill) : cashtransamount,
          // transamount: overallTotal,
          transtime: new Date(Date.now()),
          uploadTime: new Date(Date.now()),
          userId: localStorage.getItem("userId"),
          versionCode: "webv1",
          versionName: "webv1",
        }),
      });

      if (executePayResp.status === 201) {
        clearStartOrderFromRedux();
        localStorage.removeItem("bankPayId");
        localStorage.removeItem("mpesaPayId");
        localStorage.removeItem("voomaPayId");
        localStorage.removeItem("cardPayId");
        localStorage.removeItem("voomaPayId");
        localStorage.removeItem("equitelPayId");
        setMessagesToBeFetched("");
        setPosArray([]);
        setSuccessfullyPaid(true);
        const paidData = await executePayResp.json();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const executePayVoucher = async () => {
    const OrderIds = localStorage.getItem("voucher");
    const voucherIds = creditNoteId ? "" : searchedVoucher._id;

    // voucherIds
    // creditNoteId

    const voucherBody = {
      transactionType: "Voucher",
      appBuildTime: new Date(Date.now()),
      orderIds: [OrderIds],
      versionName: "webv1",
      voucherIds: [voucherIds],
      versionCode: "webv1",
      paymentChanel: "Web",
      serialNo: " N/A",
    };

    const creditNotebody = {
      transactionType: "CreditNote",
      appBuildTime: new Date(Date.now()),
      orderIds: [creditNotePayId],
      versionName: "webv1",
      creditNoteId: creditNoteId,
      versionCode: "webv1",
      paymentChanel: "Web",
      serialNo: " N/A",
    };

    try {
      const savePayment = await fetch(baseUrl + "/api/v1/payments/pay", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify(creditNoteId ? creditNotebody : voucherBody),
      });

      if (savePayment.status === 200) {
        clearStartOrderFromRedux();
        localStorage.removeItem("bankPayId");
        localStorage.removeItem("mpesaPayId");
        localStorage.removeItem("voomaPayId");
        localStorage.removeItem("cardPayId");
        localStorage.removeItem("voomaPayId");

        setopenVoucherPay(false);
        setconfirmationVoucherOPen(false);
        setMessagesToBeFetched("");
        setPosArray([]);
        setSuccessfullyPaid(true);
        const paidData = await savePayment.json();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const [isError, setIsError] = useState({state:false, message:''})

  // errror alerts on startOrder!
  const ALERTERROR = <ErrorAlertRightAligned message={isError.message} error={isError.state} />

  useEffect(() => {
    if(isError.state){
      setTimeout(() => {
        setIsError({state:false})
      }, 4000);
    }
  }, [isError.state])
  return (
    <div style={{fontFamily:'Poppins', width:"100%"}}>
      {/* <input type="month" id="bdaymonth" name="bdaymonth"/> */}
      {/* {billDisplay && <Bills/>}   */}
      {/*main Grid*/}
      {isError.state && ALERTERROR }
      {startOrderComponent && (
        <Grid container padding={"20px"} className={"container-fluid"}>
          {/*Top Level tabs*/}
          {/* <Grid container direction={"row"} justifyContent={"space-between"} style={{ backgroundColor: "#f9f9f8", padding: "7px" }}>
            <Grid item>
              <span
              onClick={() => navigate(`/orders/unpaidbills`)}
                style={{ color: "#032541", cursor: "pointer" }}
              >
                Unpaid Bills
              </span>
            </Grid>
            <Grid item>
              <span
               onClick={() => navigate(`/orders/paidbills`)}
                style={{ color: "#032541", cursor: "pointer" }}
              >
                Paid Bills
              </span>
            </Grid>
            <Grid item>
              <span
              onClick={() => navigate(`/orders/partialpayment`)}
                style={{ color: "#032541", cursor: "pointer" }}
              >
                Partial Payment
              </span>
            </Grid>
            <Grid item>
              <span
               onClick={() => navigate(`/orders/cancelledbills`)}
                style={{ color: "#032541", cursor: "pointer" }}
              >
                Cancelled Bills
              </span>
            </Grid>
            <Grid item>
              <span
              onClick={() => navigate(`/orders/report`)}
                style={{ color: "#032541", cursor: "pointer" }}
              >
                Report
              </span>
            </Grid>

            <Grid item xs={3}></Grid>
          </Grid> */}

          {/*Order Container*/}
          <Grid container justifyContent={"space-between"} alignContent={"center"} style={{ marginTop: "2%" }}>
            {productsAccordion && (
              <Grid style={{ backgroundColor: "#fff" }} item xs={12} sm={12} md={5}>
                {/*Top part*/}
                <Grid container justifyContent={"space-between"} alignContent={"center"}>
                  <Grid item>
                    <Button style={{ color: "#6e7074" }}>Products</Button>
                  </Grid>
                  <Grid item>
                    <Button style={{ color: "#6e7074" }}>Favorites Products</Button>
                  </Grid>
                  <Grid item>
                    <span>Customer Account</span>
                    <Switch inputProps={{ "aria-label": "controlled" }} color="warning" checked={openSwitch} onChange={handleSwitchChange} />
                  </Grid>
                </Grid>

                {/* customer on accounts */}
                {/* <-==== CONDITIONAL RENDERING CUSTOMER ON ACCOUNTS ======> */}
                {openSwitch && (
                  <Grid
                    container
                    justifyContent="space-between"
                    style={{
                      borderRadius: "6px",
                      // border: "solid 1px #707070",
                      backgroundColor: "#d9f6ec",
                      boxShadow: "0 3px 6px 0 #d9f6ec",
                      margin: "0px 0px 5px 0px",
                      padding: "5px",
                      boxShadow: "4px 4px 4px #fff",
                      cursor: "pointer",
                    }}
                  >
                    <Grid item style={{ height: "fit-content" }}>
                      <p style={universalStyles.xlParaBold}>{customerOnAccountsName}</p>
                      <p style={universalStyles.smParaNotBold}>Customer Type: {customeronAccountsCustomerType}</p>
                    </Grid>

                    <Grid item style={{ height: "fit-content" }}>
                      <p style={universalStyles.smParaNotBold}>Available Balance</p>
                      <p style={universalStyles.smParaNotBoldGreen}>KES {customerOnAccountsBalance}</p>
                    </Grid>
                  </Grid>
                )}

                {/*Second Part pageSize && search*/}
                <Grid container justifyContent={"space-between"} alignContent={"center"} style={{ marginTop: "2%" }}>
                  {/*PageSize*/}
                  <Grid item>
                    <span
                      style={{
                        color: "#6e7074",
                        fontSize: "12px",
                        marginRight: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      Show
                    </span>
                    <select
                      style={{
                        width: "61px",
                        height: "23px",
                        borderRadius: "6px",
                        border: "solid 1px #707070",
                        backgroundColor: "#f8f8f8",
                      }}
                      onChange={(e) => setPageSize(parseInt(e.target.value))}
                      value={categoryCount}
                    >
                      <option>{pageSize}</option>
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    <span
                      style={{
                        color: "#6e7074",
                        fontSize: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      {" "}
                      Entries
                    </span>
                  </Grid>

                  {/*SearchBox*/}
                  <Grid item style={{ margin: "0px 0px 5px 0px" }}>
                    <label style={universalStyles.searchLabelBold}>Search : </label>
                    <input
                      startDecorator={<SearchIcon />}
                      size="sm"
                      variant="plain"
                      style={universalStyles.searchInputBlack}
                      placeholder="search here"
                      value={categoryName}
                      onChange={(e) => {
                        setQueryCategory(e.target.value);
                        getItemsPerCategory(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>

                {/*Products Accordion*/}

                <Grid container style={{ marginTop: "2%" }} direction="column">
                  {/* <ACTIVESTOCK page={page} pageSize={pageSize} /> */}

                  {
                    // categoriesList.length > 0 &&

                    categoriesList
                      ?.filter((category) => {
                        return categoryName?.toLowerCase() === "" ? category : category?.category?.toLowerCase().includes(categoryName.toLowerCase());
                      })
                      ?.slice((page - 1) * pageSize, page * pageSize)
                      .map((category, index) => {
                        return (
                          // <div>
                          <Accordion key={index} onChange={handleChange(category.category)} expanded={expanded === category.category}>
                            <AccordionSummary
                              onClick={() => {
                                getItemsPerCategory(category.category);
                                setItemCategory(category.category);
                              }}
                              expandIcon={<ExpandMore />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              {/* <span>{category.category.toLocaleUpperCase()}</span> */}
                              <span>{category.category}</span>
                            </AccordionSummary>

                            <AccordionDetails>
                              {productsList.isLoading === true ? (
                                <>
                                  <Box sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                                    <CircularProgress color="inherit" />
                                  </Box>
                                </>
                              ) : (
                                <TableContainer component={Paper}>
                                  <p style={{ textAlign: "right" }}>
                                    <Input size="sm" variant="plain" value={queryProducts} onChange={(e) => setQueryproducts(e.target.value)} style={{ objectFit: "contain", borderRadius: "6px", outline: "none", border: "solid 1px #032541", fontSize: "12px", paddingLeft: "10px" }} placeholder="Search product" />
                                  </p>
                                  <Table stickyHeader sx={{ width: "100%" }}>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell style={{ fontWeight: "bold" }}>Product Name</TableCell>
                                        {/* <TableCell>Category</TableCell> */}
                                        <TableCell style={{ fontWeight: "bold" }}>In Stock</TableCell>
                                        <TableCell style={{ fontWeight: "bold" }}>Cost</TableCell>
                                      </TableRow>
                                    </TableHead>

                                    <TableBody>
                                      {
                                        // productsList?.products.length > 0 &&
                                        productsList.products
                                          // .filter((product) => {
                                          //   return queryProducts === "" ? product : product.productName.toLocaleLowerCase().includes(queryProducts.toLocaleLowerCase());
                                          // })
                                          ?.map((product, index) => {
                                            var outOf = "";
                                            var stockamount = "";
                                            if (product.outOfStock === true) {
                                              var outOf = "out of stock";
                                            } else {
                                              var outOf = "stock available";
                                              var stockamount = product.quantityInStock;
                                            }

                                            return (
                                              <TableRow style={{ cursor: "pointer" }} key={index} onClick={() => addProductsToPosArray(product)}>
                                                <TableCell>{product.productName}</TableCell>
                                                {/* <TableCell>{product.productCategory}</TableCell> */}
                                                <TableCell> {outOf === "out of stock" ? "out of stock" : stockamount} </TableCell>
                                                <TableCell>{product.productPrice}</TableCell>
                                              </TableRow>
                                            );
                                          })
                                      }
                                    </TableBody>
                                  </Table>

                                  <Grid item style={{ width: "100%", marginTop: "2%", marginBottom: "2%" }}>
                                    <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                      {/* <Pagination count={5} page={tablePage} defaultPage={1} onChange={(e, value) => handleTableChange(value)} variant="outlined" shape="rounded" /> */}
                                      <Pagination count={0} page={1} defaultPage={1} onChange={handleTableChange} variant="outlined" shape="rounded" />
                                    </Box>
                                  </Grid>
                                </TableContainer>
                              )}
                            </AccordionDetails>
                          </Accordion>
                          // </div>
                        );
                      })
                  }

                  <Grid item style={{ width: "100%", marginTop: "2%" }}>
                    <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                      <Pagination count={noOfPages} page={page} defaultPage={1} onChange={(e, value) => setPage(value)} variant="outlined" shape="rounded" />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/* START OF OTHER HALF */}
            {/*Order half*/}

            {orderOverview && (
              <Grid item style={{ backgroundColor: "#f9fafb" }} xs={12} sm={12} md={7} spacing={2} >
                {/*First Part Headers && Save*/}

                <Grid container justifyContent={"space-between"} alignContent={"center"} style={{ margin: "5px 0px 10px 0px", paddingLeft: "25px" }}>
                  {/*Order OverView*/}
                  <Grid item>
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "#dc3545",
                      }}
                    >
                      Order Overview
                    </span>
                  </Grid>

                  {/*Save Order*/}
                  <Grid item>
                    {!toggleGeneralDiscount && (
                      <span
                        onClick={() => {
                          if (posArray.length > 0) {
                            if (businessCategory === "Eatery, Bar and Restaurant" || businessCategory === "Hotel") {
                              setOpenSaveBill(true);
                              // setPosArray([]);
                            } else {
                              saveOrderToBd();
                              setPosArray([]);
                            }
                          }
                        }}
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "#17ae7b",
                          cursor: "pointer",
                        }}
                      >
                        <Box>
                          <Download />
                          Save
                        </Box>
                      </span>
                    )}
                  </Grid>
                </Grid>

                {/* POS ITEMS */}
                <div
                  style={{
                    width: "100%",
                    padding: "10px",
                    // border: "3px solid green",
                    height: "70vh",
                    // flexDirection: "column-reverse",
                  }}
                >
                  {posArray.length > 0 && (
                    <TableContainer component={Paper} style={{ flexDirection: "column-reverse", height: "40vh", overflow: "auto", display: "flex" }}>
                      <Table stickyHeader sx={{ width: "100%" }}>
                        <TableHead style={{ fontWeight: "bold", fontSize: "10px" }}>
                          <TableRow>
                            <TableCell style={{ fontWeight: "bold" }}>Product Name</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Item Amount(KES)</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="center">
                              Quantity
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Total</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {posArray?.map((posItem, index) => {
                            const { itemName, checkedProductId, itemAmount, itemCount, totalAmount, priceStatus } = posItem;

                            if (priceStatus === "Variable Price Item") {
                              return (
                                <TableRow style={{ cursor: "pointer" }} key={index} onClick={() => addProductsToPosArray(posItem)}>
                                  <TableCell>{itemName}</TableCell>
                                  <TableCell>
                                    <input
                                      required
                                      onChange={updateVariablePriceTotals}
                                      value={itemAmount}
                                      style={{
                                        width: "20%",
                                        outline: "none",
                                        margin: "0px 10px 0px 0px",
                                      }}
                                      id={checkedProductId}
                                      name={index}
                                      type="number"
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    {" "}
                                    <input
                                      value={itemCount}
                                      onChange={updateInputTotals}
                                      required
                                      id={checkedProductId}
                                      name={index}
                                      style={{
                                        width: "20%",

                                        margin: "0px 10px 0px 0px",
                                      }}
                                      type="number"
                                    />{" "}
                                  </TableCell>
                                  <TableCell>{itemAmount * itemCount}</TableCell>
                                  <TableCell>
                                    {" "}
                                    <Delete
                                      onClick={() => {
                                        removeproductFromPosArray(posItem);
                                        dispatch(deleteItemFromArray(posItem));
                                      }}
                                      style={{ color: "#dc3545", cursor: "pointer" }}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            } else {
                              return (
                                <TableRow style={{ cursor: "pointer" }} key={index} onClick={() => addProductsToPosArray(posItem)}>
                                  <TableCell>{itemName}</TableCell>
                                  <TableCell>{itemAmount}</TableCell>
                                  <TableCell align="center">
                                    {" "}
                                    <input
                                      type="number"
                                      required
                                      value={itemCount}
                                      onChange={updateInputTotals}
                                      id={checkedProductId}
                                      name={index}
                                      style={{
                                        // padding: "7px",
                                        width: "20%",
                                        outline: "none",
                                        margin: "0px 10px 0px 0px",
                                      }}
                                    />{" "}
                                  </TableCell>
                                  <TableCell>{itemAmount * itemCount}</TableCell>
                                  <TableCell>
                                    {" "}
                                    <Delete
                                      // onClick={() => {
                                      //   filterPosArray(checkedProductId);
                                      //   // getOverallItem();
                                      // }}
                                      onClick={() => {
                                        removeproductFromPosArray(posItem)
                                        dispatch(deleteItemFromArray(posItem));
                                      } }
                                      style={{ color: "#dc3545", cursor: "pointer" }}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}

                  {posArray.length === 0 && (
                    <div style={{ height: "45vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <p style={{ textAlign: "center" }}>
                        {/* <img src={emptyState} /> */}
                        <p style={universalStyles.xlParaBold}>No product added!</p>
                      </p>
                    </div>
                  )}

                  {/* CONTROL BUTTONS */}
                  {/* {posArray?.length > 0 && ( */}
                  <Grid container style={{ width: "100%", margin: "10px 0px 0px 0px", paddingLeft: "25px", paddingRight: "25px", backgroundColor: "#fff", fontFamily: "Poppins" }}>
                    <Grid item style={{ margin: "0px 0px 10px 0px", width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <span>Subtotal</span>
                      <span style={{ fontWeight: "bold", color: "#17ae7b" }}>{numberFormat(overallTotal)}</span>
                    </Grid>

                    <Grid item style={{ margin: "0px 0px 10px 0px", width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <span>Number of Items</span>
                      <span style={{ fontWeight: "bold", color: "#17ae7b" }}>{posArray.length}</span>
                    </Grid>

                    <Grid item style={{ margin: "0px 0px 20px 0px", width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      {posArray.length > 0 && (
                        <span>
                          <Checkbox onClick={() => setToggleGeneralDiscount(!toggleGeneralDiscount)} /> Discount
                        </span>
                      )}

                      {toggleGeneralDiscount && (
                        <>
                          <select value={generalDiscountType} onChange={(e) => setGeneralDiscountType(e.target.value)} id="selectdiscount" name="selectdiscount" style={{ border: "solid 1px #b2b4bb", borderRadius: "6px", padding: "5px", outline: "none" }}>
                            <option>Type</option>
                            <option value="Amount">Amount</option>
                            <option value="Percentage">Percentage</option>
                          </select>
                          <input placeholder="discount" style={{ outline: "none", borderRadius: "6px", border: "1px solid #b2b4bb", padding: "5px" }} value={generalDiscount} onChange={(e) => setGeneralDiscount(e.target.value)} type="number" />
                          {/* <input placeholder="%" style={{ outline: "none", borderRadius: "6px", border: "1px solid #b2b4bb", padding: "5px"}} value={generalDiscountPercentage} onChange={(e) => setGeneralDiscountPerecentage(e.target.value)} />   */}
                        </>
                      )}
                    </Grid>

                    <Grid item style={{ margin: "0px 0px 10px 0px", width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <span>Discount</span>
                      <span style={{ fontWeight: "bold", color: "#17ae7b" }}>{numberFormat(generalDiscountAmount)}</span>
                    </Grid>

                    <Grid item style={{ width: "100%", padding: "0px 0px 0px 0px" }}>
                      <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <p style={{ color: "#17ae7b", fontWeight: "bold" }}>TOTAL AMOUNT</p>
                        <p style={{ textAlign: "right", fontFamily: "Poppins", fontWeight: "bold", color: "#17ae7b" }}>{numberFormat(overallTotal - generalDiscountAmount)}</p>
                      </Box>
                    </Grid>

                    {customerType === "customeronaccounts" && (
                      <Grid item style={{ margin: "0px 0px 10px 0px", width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <span style={{ fontWeight: "bold", color: "#17ae7b" }}>CA BALANCE</span>
                        <span style={{ fontWeight: "bold", color: "#17ae7b" }}>
                          {" "}
                          <p style={{ textAlign: "right", color: "#17ae7b", fontWeight: "bold" }}>{numberFormat(customerOnAccountsBalance - customerOnAccountsBill)}</p>
                        </span>
                      </Grid>
                    )}

                    {posArray.length === 0 ||
                      (!canCustomerPay && (
                        <Grid item style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <Button
                            style={{
                              borderRadius: "5px",
                              border: "solid 1px rgba(220,53,69, .3)",
                              color: "rgba(220,53,69, .3)",
                              margin: "0px 20px 0px 0px",
                              padding: "10px 20px 10px 20px",
                            }}
                            // onClick={() => {
                            //   if (posArray.length > 0) {
                            //     window.location.reload();

                            //     setTimeout(() => {
                            //       navigate("/orders");
                            //     }, 1000);
                            //   }
                            // }}
                          >
                            Cancel
                          </Button>

                          {parentId === "startorder" ? (
                            <Button
                              style={{
                                backgroundColor: "rgba(3,37,65, .3)",
                                borderRadius: "5px",
                                color: "#fff",
                                margin: "0px 0px 0px 20px",
                                padding: "10px 20px 10px 20px",
                              }}
                              // onClick={() => {
                              //   if (posArray.length > 0) {
                              //     setPay(true);
                              //     setPayOpenModal(true);
                              //   }
                              // }}
                            >
                              Pay Now
                            </Button>
                          ) : null}

                          {!toggleGeneralDiscount && (
                            <Button
                              style={{
                                backgroundColor: "rgba(3,37,65, .3)",
                                borderRadius: "5px",
                                color: "#fff",
                                margin: "0px 0px 0px 20px",
                                padding: "10px 20px 10px 20px",
                              }}
                              // onClick={() => {
                              //   if (posArray.length > 0) {
                              //     setSendRequest(true);
                              //     saveOrderToDbAndPrint();
                              //   }
                              // }}
                            >
                              Print bill
                            </Button>
                          )}

                          {!toggleGeneralDiscount && (
                            <Button
                              style={{
                                backgroundColor: "rgba(3,37,65, .3)",
                                borderRadius: "5px",
                                color: "#fff",
                                margin: "0px 0px 0px 20px",
                                padding: "10px 20px 10px 20px",
                              }}
                              // onClick={() => {
                              //   if (posArray.length > 0) {
                              //     setOpenSaveBill(true);
                              //   }
                              // }}
                            >
                              Print Later
                            </Button>
                          )}
                        </Grid>
                      ))}

                    {posArray.length > 0 && canCustomerPay && (
                      <Grid item style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Button
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px #dc3545",
                            color: "#dc3545",
                            margin: "0px 20px 0px 0px",
                            padding: "10px 20px 10px 20px",
                          }}
                          onClick={() => {
                            if (posArray.length > 0) {
                              dispatch(removeStartOrderArray());
                              setTimeout(() => {
                                window.location.reload();
                              }, 1000);
                            }
                          }}
                        >
                          CLEAR
                        </Button>

                        {parentId === "startorder" ? (
                          <Button
                            style={{
                              backgroundColor: "#032541",
                              borderRadius: "5px",
                              color: "#fff",
                              margin: "0px 0px 0px 20px",
                              padding: "10px 20px 10px 20px",
                            }}
                            onClick={() => {
                              if (posArray.length > 0) {
                                if (customerType === "customeronaccounts") {
                                  // console.log("we on");
                                  setCustomerPreview(true);
                                } else {
                                  setPay(true);
                                  setPayOpenModal(true);
                                }
                              }
                            }}
                          >
                            Pay Now
                          </Button>
                        ) : null}

                        {/* {parentId === "startorder" ? (
                          <Button
                            style={{
                              backgroundColor: "#032541",
                              borderRadius: "5px",
                              color: "#fff",
                              margin: "0px 0px 0px 20px",
                              padding: "10px 20px 10px 20px",
                            }}
                            onClick={() => {
                              if (posArray.length > 0) {
                                saveOrderToDbAndPopulateMpesa()
                                .then((data) => {
                                  
                                navigate(`/orders/requestPayment?startOrderTag?${requestPaymentId}?${newOverallTotal}`)
                                  
                                })
                              }
                            }}
                          >
                            Request Payment
                          </Button>
                        ) : null} */}

                        {!toggleGeneralDiscount && (
                          <Button
                            style={{
                              backgroundColor: "#032541",
                              borderRadius: "5px",
                              color: "#fff",
                              margin: "0px 0px 0px 20px",
                              padding: "10px 20px 10px 20px",
                            }}
                            onClick={() => {
                              if (posArray.length > 0) {
                                setSendRequest(true);
                                saveOrderToDbAndPrint();
                              }
                            }}
                          >
                            Print bill
                          </Button>
                        )}

                        {!toggleGeneralDiscount && (
                          <Button
                            style={{
                              backgroundColor: "#032541",
                              borderRadius: "5px",
                              color: "#fff",
                              margin: "0px 0px 0px 20px",
                              padding: "10px 20px 10px 20px",
                            }}
                            onClick={() => {
                              if (posArray.length > 0) {
                                if (businessCategory === "Eatery, Bar and Restaurant" || businessCategory === "Hotel") {
                                  setOpenSaveBill(true);
                                  // setPosArray([]);
                                } else {
                                  saveOrderToBd();
                                  setPosArray([]);
                                }
                              }
                            }}
                            // onClick={() => {
                            //   if (posArray.length > 0) {
                            //     setOpenSaveBill(true);
                            //   }
                            // }}
                          >
                            Print Later
                          </Button>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </div>
                {/* )} */}
              </Grid>
            )}

            {/* END OF OTHER HALF */}
          </Grid>

          {/* MODAL */}
          <Modal
            open={openNoteModal}
            // onClose={closeNoteModal}
          >
            <div style={universalStyles.customerNoteModal}>
              <Box
                style={{
                  width: "100%",
                  padding: "20px",
                }}
              >
                <ArrowBack style={{ color: "#dc3545", marginRight: "30px" }} onClick={closeNoteModal} />

                <span style={universalStyles.spanLBlueBold}>Preference</span>
              </Box>

              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "90%", padding: "5px" },
                }}
                style={{ padding: "20px" }}
                noValidate
                autoComplete="off"
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                <TextField id="outlined-basic" label="Preference" variant="outlined" value={customerNote} onChange={(e) => setCustomerNote(e.target.value)} />
                <TextField id="outlined-select-meals" select label="Preference" defaultValue={customerNote} helperText="Select your preference" onChange={(e) => setCustomerNote(e.target.value)}>
                  <MenuItem value="Hot meals">Hot meals</MenuItem>
                </TextField>
              </Box>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#032541",
                    color: "#ffffff",
                    width: "126px",
                  }}
                  onClick={closeNoteModal}
                >
                  Done
                </Button>
              </Box>
            </div>
          </Modal>

          <Modal open={voucherValidationOpen} onClose={ClosevoucherValidationClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  marginLeft: "100px",
                  padding: "12px 30px 80px",
                  objectFit: "contain",
                }}
              >
                <img style={{ width: "82px", height: "82px", margin: "4px 0px 0px -15px" }} src={powericon} alt="img" />
              </div>
              <h4
                style={{
                  fontSize: "18px",
                  fontWeight: " bold",
                  fontStretch: "normal",
                  fontStyle: " normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "center",
                  color: " #032541",
                }}
              ></h4>

              <h4
                style={{
                  fontSize: "18px",
                  fontWeight: " bold",
                  fontStretch: "normal",
                  fontStyle: " normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "center",
                  color: "green",
                }}
              >
                {titleMessage}
              </h4>
              <h4
                style={{
                  marginTop: "16px",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                {VoucherInfomessage}
              </h4>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  type="submit"
                  variant="standard"
                  style={{
                    color: "#fff",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    width: "240px",
                    height: "40px",
                    margin: "20px 10px 0 30px",
                    backgroundColor: "#032541",
                  }}
                  onClick={ClosevoucherValidationClose}
                >
                  ok
                </Button>
              </div>
            </Box>
          </Modal>

          <Modal open={confirmationVoucherOPen} onClose={closeconfirmationVoucher} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box
              sx={{
                borderColor: "transparent",
                borderRadius: "36px",
                backgroundColor: "#fff",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "387px",
                height: "382px",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  marginLeft: "100px",
                  padding: "12px 30px 80px",
                  objectFit: "contain",
                }}
              >
                <img style={{ width: "82px", height: "82px", display: "flex", marginTop: "20px", justifyContent: "center", alignItems: "center" }} src={powericon} alt="img" />
              </div>
              <h4
                style={{
                  fontSize: "22px",
                  fontWeight: " bold",
                  fontStretch: "normal",
                  fontStyle: " normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "center",
                  color: " #032541",
                  marginTop: "24px",
                  color: "#032541",
                }}
              >
                Confirm Payment
              </h4>
              <h4 style={{ padding: "0 60px 10px 60px", fontSize: "18px" }}>
                Are you sure you want to pay with Voucher No. <strong className="text-success">{searchedVoucher?.voucherNo}</strong>
              </h4>
              <h4 style={{ textAlign: "center", fontSize: "18px" }}>
                Amount <strong className="text-success">{numberFormat(searchedVoucher?.amount)}</strong>
              </h4>
              <div className="button-row" style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                <Button
                  type="submit"
                  variant="standard"
                  style={{
                    color: "#dc3445",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    width: "140px",
                    height: "40px",
                    margin: "20px 0px 0 0px",
                    border: "solid 1px  #dc3545",
                  }}
                  onClick={closeconfirmationVoucher}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="standard"
                  style={{
                    color: "#fff",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    width: "140px",
                    height: "40px",
                    margin: "20px 0px 0 0px",
                    backgroundColor: "#032541",
                  }}
                  onClick={() => {
                    console.log("i Was clicked");

                    executePayVoucher();
                    ClosevoucherValidationClose();
                  }}
                >
                  Confirm
                </Button>
              </div>
            </Box>
          </Modal>

          {/* SAVE BILL MODAL */}

          {/* MODAL */}
          <Modal open={saveBill} onClose={closeSaveBill}>
            <div style={universalStyles.saveBillModal}>
              <Box
                style={{
                  width: "100%",
                  padding: "20px",
                }}
              >
                <ArrowBack style={{ color: "#dc3545", marginRight: "30px" }} onClick={closeSaveBill} />

                <span style={universalStyles.spanXXLBlueBold}>Save Bill</span>
              </Box>

              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "90%", padding: "5px" },
                }}
                style={{ padding: "20px" }}
                noValidate
                autoComplete="off"
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                <TextField id="outlined-basic" label="Table No" variant="outlined" value={tableNo} onChange={(e) => setTableNo(e.target.value)} />
                <TextField id="outlined-basic" label="Customer Name" variant="outlined" value={customerName} onChange={(e) => setCustomerName(e.target.value)} />
              </Box>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#032541",
                    color: "#ffffff",
                    width: "126px",
                  }}
                  onClick={() => {
                    saveOrderToBd();
                  }}
                >
                  save
                </Button>
              </Box>
            </div>
          </Modal>
          {/* SAVE BILL SUCCESS MODAL */}
          <Modal
            open={storeBill}
            //  onClose={closeStoreBill}
          >
            <div style={universalStyles.saveBillSuccessModal}>
              <div style={{ width: "100%", padding: "20px" }}>
                <p style={{ textAlign: "center", margin: "0px 0px 5px 0px" }}>
                  <img
                    style={{
                      height: "50px",
                      width: "50px",
                      objectFit: "contain",
                    }}
                    src={approvetick}
                  />
                </p>

                <p style={universalStyles.xxxlParaBold}>Success</p>

                <p style={universalStyles.xxlParaBold}>Order saved successfully!</p>

                <p style={{ textAlign: "center", margin: "0px 0px 5px 0px" }}>
                  <Button
                    style={{
                      textAlign: "center",
                      margin: "0",
                      backgroundColor: "#032541",
                      color: "#ffffff",
                    }}
                    onClick={() => {
                      closeStoreBill();
                      window.location.reload();
                    }}
                  >
                    ok
                  </Button>
                </p>
              </div>
            </div>
          </Modal>

          {/* SELECT CUSTOMER ON ACCOUNTS */}
          <Modal
            open={customerOnAccountsModal}
            // onClose={() => {
            //   setOpenSwitch(!openSwitch);
            // }}
          >
            <div style={universalStyles.customerOnAccountsModal}>
              <Box
                style={{
                  padding: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "#dc3545",
                  borderRadius: "15px 15px 0px 0px",
                }}
              >
                <ArrowBack
                  style={{
                    color: "#ffffff",
                    marginRight: "30px",
                    cursor: "pointer",
                  }}
                  onClick={() => setCustomerOnAccountsModal(false)}
                />

                <span style={universalStyles.spanXXLwhite}>Select Customer</span>

                <Close style={{ color: "#ffffff", cursor: "pointer" }} onClick={() => setCustomerOnAccountsModal(false)} />
              </Box>

              <Box style={{ width: "100%", padding: "20px" }}>
                <input style={{ width: "80%", border: "none", outline: "none" }} value={customerOnAccountsQuery} onChange={(e) => setCustomerOnAccountsQuery(e.target.value)} placeholder="Search Customer Name" />
              </Box>

              <Box
                style={{
                  width: "100%",
                  padding: "20px",
                  overflow: "auto",
                  height: "55vh",
                  overflow: "auto",
                  // border:"2px solid red"
                }}
              >
                {customerOnAccounts.length > 0 &&
                  customerOnAccounts
                    ?.filter((customer) => {
                      return customerOnAccountsQuery.toLocaleLowerCase() === "" ? customer : customer.customerName.toLocaleLowerCase().includes(customerOnAccountsQuery.toLocaleLowerCase());
                    })
                    ?.map((customer) => {
                      return (
                        <Grid
                          key={customer._id}
                          container
                          justifyContent="space-between"
                          style={{
                            borderRadius: "6px",
                            // border: "solid 1px #707070",
                            backgroundColor: "#fff",
                            margin: "0px 0px 5px 0px",
                            padding: "5px",
                            boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            getSingleCustomer(customer);
                            setCustomerOnAccountsModal(false);
                          }}
                        >
                          <Grid item style={{ height: "fit-content" }}>
                            <p style={universalStyles.xlParaBold}>{customer.customerName}</p>
                            <p style={universalStyles.smParaNotBold}>Customer Type: {customer.customerType}</p>
                          </Grid>

                          <Grid item style={{ height: "fit-content" }}>
                            <p style={universalStyles.smParaNotBold}>Available Balance</p>
                            <p style={universalStyles.smParaNotBoldGreenAlignRight}>KES {customer.availableBalance}</p>
                          </Grid>
                        </Grid>
                      );
                    })}
              </Box>
            </div>
          </Modal>

          {/* SAVE BILL MODAL */}
          <Modal
            open={successfullyPaid}
            // onClose={closeSuccessfullyPaid}
          >
            <div style={universalStyles.successFullyPaidModal}>
              <div style={{ width: "100%", padding: "20px" }}>
                <p style={{ textAlign: "center", margin: "0px 0px 5px 0px" }}>
                  <img
                    style={{
                      height: "50px",
                      width: "50px",
                      objectFit: "contain",
                    }}
                    src={approvetick}
                  />
                </p>

                <p style={universalStyles.xxxlParaBoldCenter}>Success</p>

                <p style={universalStyles.xxxlParaNotBoldWithMarginTextCenter}>Do you want to print reciept now?</p>

                <p style={{ textAlign: "center", margin: "0px 0px 5px 0px" }}>
                  <Button
                    style={{
                      textAlign: "center",
                      margin: "0px 20px 0px 0px",
                      backgroundColor: "#032541",
                      color: "#ffffff",
                    }}
                    onClick={() => {
                      setSuccessfullyPaid(false);
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    }}
                  >
                    Later
                  </Button>

                  <Button
                    style={{
                      textAlign: "center",
                      margin: "0",
                      backgroundColor: "#032541",
                      color: "#ffffff",
                      margin: "0px 20px 0px 0px",
                    }}
                    onClick={() => {
                      // closeStoreBill();
                      // navigate("/orders");
                      closeSuccessfullyPaid(false);
                      setSendRequest(true);
                      setTimeout(() => {
                        setSendRequest(false);
                        setPospayment(true);
                      }, 2000);
                    }}
                  >
                    Print
                  </Button>
                </p>
              </div>
            </div>
          </Modal>

          {/* PRINT BILL POP UP */}
          <Modal open={posPayment} onClose={() => setPospayment(false)}>
            <div style={universalStyles.printBillModal}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "20px",
                  backgroundColor: "#dc3545",
                  width: "100%",
                  borderRadius: "15px 15px 0px 0px",
                }}
              >
                <span
                  style={universalStyles.spanXXLwhiteBold}
                  onClick={() => {
                    if (posArray.length > 0) {
                      posPayment(true);
                    }
                  }}
                ></span>
              </Box>

              <Grid container justifyContent="space-around" style={{ width: "100%", padding: "20px" }} alignItems="center">
                <Grid item>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "5px",
                      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                      // border: "solid 3px #032541",
                      borderRadius: "6px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      setShowPrinter(false);
                    }}
                  >
                    <img src={possvg} />
                    <span style={universalStyles.spanNormalBlack}>Send to POS</span>
                  </Box>
                </Grid>

                <Grid item>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "5px",
                      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                      // border: "solid 3px #032541",
                      borderRadius: "6px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowPrinter(!showPrinter);
                    }}
                  >
                    <img src={printersvg} />
                    <span style={universalStyles.spanNormalBlack}>Send to Printer</span>
                  </Box>
                </Grid>
              </Grid>

              {!showPrinter && (
                <>
                  <Grid container style={{ width: "100%", padding: "20px" }}>
                    <Grid item style={{ width: "100%" }}>
                      <TextField style={{ width: "100%" }} id="outlined-select-meals" select label="Pos" defaultValue={posSerialNo} helperText="POS" onChange={(e) => setPosSerialNo(e.target.value)}>
                        {pos.length > 0 &&
                          pos?.map((pos) => {
                            return (
                              <MenuItem key={pos._id} value={pos.terminalSerialNumber}>
                                {pos.terminalSerialNumber}
                              </MenuItem>
                            );
                          })}
                      </TextField>

                      {pos.length === 0 && <p style={{ textAlign: "right", color: "red" }}> You do not have any POS</p>}
                    </Grid>
                  </Grid>
                </>
              )}

              {showPrinter && (
                <>
                  <Grid container style={{ width: "100%", padding: "20px" }}>
                    <Grid item style={{ width: "100%" }}>
                      <TextField
                        style={{ width: "100%" }}
                        id="outlined-select-meals"
                        select
                        label="Printer"
                        // defaultValue={customerNote}
                        helperText="Select Printer"
                        // onChange={(e) => setCustomerNote(e.target.value)}
                      >
                        {printers.length > 0 &&
                          printers.printers?.map((printer) => {
                            return <MenuItem value="HJFY875GCUYTR">HJFY875GCUYTR</MenuItem>;
                          })}
                      </TextField>
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid container justifyContent="space-around" alignItems="center" style={{ width: "100%", padding: "20px" }} direction="row">
                <Grid item>
                  <Button
                    style={{
                      border: "solid 1px #dc3545",
                      color: "#dc3545",
                      // margin: "0px 20px 0px 0px",
                      padding: "8px 20px 8px 20px",
                    }}
                    onClick={() => {
                      setPospayment(false);
                      window.reload();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={sendToPrinter}
                    style={{
                      backgroundColor: "#032541",
                      color: "#ffffff",
                      // margin: "0px 0px 0px 20px",
                      padding: "8px 30px 8px 30px",
                    }}
                  >
                    Send
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Modal>

          {/* CARD PAY MODAL */}
          {/* CARD PAYMENT MODAL */}
          <Modal
            open={cardModal}
            //  onClose={() => setCardModalOpen(false)}
          >
            <div style={universalStyles.cardPayModal}>
              {/* <Box style={{ width: "100%", padding: "10px" }}>
              <p style={{ textAlign: "right" }}>
                <Checkbox value={cardDiscount} onChange={(e) => setToggleCardDiscount(!cardDiscount)} /> Discount
              </p>
            </Box> */}

              <Grid container direction="row" style={{ width: "100%" }}>
                <Grid item style={{ width: "100%" }}>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      margin: "0",
                      lineHeight: "1.7",
                    }}
                  >
                    <img
                      style={{
                        width: "70px",
                        height: "70px",
                        objectFit: "contain",
                      }}
                      src={cardpay}
                    />
                  </p>
                  <p style={universalStyles.xxxlParaBoldWithWidth}>Card Payment</p>
                  <p style={universalStyles.xxxlParaBoldWithWidth}>Total = {numberFormat(cardTotal)}</p>
                </Grid>

                <Grid container style={{ width: "100%", padding: "20px" }}>
                  <Grid item style={{ width: "100%" }}>
                    {cardDiscount && (
                      <Grid item style={{ width: "100%" }}>
                        <TextField id="outlined-select-meals" label="Discount" style={{ width: "100%" }} defaultValue={cardPayDiscount} helperText={"Enter discount"} type="number" onChange={(e) => setCardPayDiscount(e.target.value)} />
                      </Grid>
                    )}

                    <TextField id="outlined-select-meals" select label="Card Type" style={{ width: "100%" }} defaultValue={getCardType} helperText={"Select Card Type"} onChange={(e) => setCardType(e.target.value)}>
                      {cardTypes.length > 0 &&
                        cardTypes?.map((card) => {
                          return (
                            <MenuItem key={card._id} value={card.cardType}>
                              {card.cardType}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </Grid>

                  <Grid item style={{ width: "100%" }}>
                    <TextField id="outlined-select-meals" label="Reference number" style={{ width: "100%" }} defaultValue={refNo} helperText={"Select Reference Number"} onChange={(e) => setRefNo(e.target.value)} />
                  </Grid>

                  <Grid item style={{ width: "100%" }}>
                    <TextField id="outlined-select-meals" select label="Reference No" style={{ width: "100%" }} defaultValue={getAcquirer} helperText={"Select Card Type"} onChange={(e) => setAcquirer(e.target.value)}>
                      {getAcquirers.length > 0 &&
                        getAcquirers?.map((acquirer, index) => {
                          return (
                            <MenuItem key={index} value={acquirer.name}>
                              {acquirer.name}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </Grid>

                  <Grid item style={{ width: "100%" }}>
                    <TextField id="outlined-select-meals" label="Customer's Name" style={{ width: "100%" }} defaultValue={cardCustomer} helperText={"Enter name of the  Customer's Name"} onChange={(e) => setCardCustomerName(e.target.value)} />
                  </Grid>
                </Grid>

                <Grid container style={{ width: "100%", padding: "10px" }} direction="row" justifyContent={"center"} alignItems="center">
                  <Grid item>
                    <Button
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#dc3545",
                        border: "1px solid #dc3545",
                        margin: "0px 20px 0px 0px",
                      }}
                      onClick={() => setCardModalOpen(false)}
                    >
                      Cancel
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      style={{
                        objectFit: "contain",
                        borderRadius: "5px",
                        backgroundColor: "#032541",
                        color: "#ffffff",
                        margin: "0px 0px 0px 20px",
                      }}
                      onClick={() => {
                        setCardModalOpen(false);
                        // setConfirmPay(true);
                        setConfirmPatDetails(true);
                        closePaymentModal();
                      }}
                    >
                      Pay Now
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Modal>

          {/* CONFIRM CARD PAY */}
          <Modal
            open={confirmPayDetails}
            //  onClose={() => setConfirmPatDetails(false)}
          >
            <div style={universalStyles.confirmPayModal}>
              <Box>
                <p style={{ textAlign: "center" }}>
                  <img style={{ objectFit: "contain" }} src={powericon} />
                </p>

                <p style={universalStyles.xxxlParaBoldTextCenter}>Confirm Payment</p>

                <p style={universalStyles.xlParaBoldCenter}>Are you sure you want to pay with</p>

                <br />

                <p style={universalStyles.xlNormalParaCenter}>{messagesTobeFetched === "MPESA" ? "MPESA" : messagesTobeFetched === "EQUITEL" ? "EQUITEL" : messagesTobeFetched === "VOOMA" ? "VOOMA" : getCardType} ?</p>

                <p style={universalStyles.xlNormalParaCenter}>For {messagesTobeFetched === "MPESA" ? allPayCustomerName : messagesTobeFetched === "EQUITEL" ? allPayCustomerName : messagesTobeFetched === "VOOMA" ? allPayCustomerName : cardCustomer}</p>

                <p style={universalStyles.xlNormalParaCenter}>Total {messagesTobeFetched === "MPESA" ? numberFormat(allPayTotals) : messagesTobeFetched === "EQUITEL" ? numberFormat(allPayTotals) : messagesTobeFetched === "VOOMA" ? numberFormat(allPayTotals) : numberFormat(cardTotal)}</p>

                <p style={universalStyles.xlNormalParaCenter}>Amount {messagesTobeFetched === "MPESA" ? numberFormat(allPayAmount) : messagesTobeFetched === "EQUITEL" ? numberFormat(allPayAmount) : messagesTobeFetched === "VOOMA" ? numberFormat(allPayAmount) : numberFormat(cardTotal)}</p>

                <p style={universalStyles.xlNormalParaCenter}>Balance {messagesTobeFetched === "MPESA" ? numberFormat(newMpesaBalance) : messagesTobeFetched === "EQUITEL" ? numberFormat(newEquitelBalance) : messagesTobeFetched === "VOOMA" ? numberFormat(newVoomaBalance) : numberFormat(0)}</p>

                <p style={{ textAlign: "center" }}>
                  <Button
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#dc3545",
                      border: "1px solid #dc3545",
                      margin: "0px 20px 0px 0px",
                    }}
                    onClick={() => {
                      setConfirmPatDetails(false);
                      window.location.reload();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    style={{
                      objectFit: "contain",
                      borderRadius: "5px",
                      backgroundColor: "#032541",
                      color: "#ffffff",
                      margin: "0px 0px 0px 20px",
                    }}
                    onClick={() => {
                      setConfirmPatDetails(false);
                      // setsuccessPay(true);
                      executePay();
                      console.log("i Was clicked");
                    }}
                  >
                    Confirm
                  </Button>
                </p>
              </Box>
            </div>
          </Modal>

          {/* voucher payment */}
          <Modal open={openVoucherPay} onClose={closeVoucherPay} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={AddProductModalstyle}>
              <Box
                sx={{
                  width: "100%",
                  padding: "33.8px 55.6px 0.8px 43.6px",
                }}
              >
                <div className="topbar-voucher d-flex" style={{ display: "flex", justifyContent: "space-between" }}>
                  <ArrowBack onClick={closeVoucherPay} />
                  <h6>Voucher</h6>
                  <CloseIcon onClick={closeVoucherPay} />
                </div>
                <div className="billDate">
                  <div
                    className="d-flex "
                    style={{
                      width: "407px",
                      height: " 54px",
                      margin: " 28px 0 20px 0px",
                      padding: "17px 10px 16px",
                      display: "flex",
                      justifyContent: "space-between",

                      borderRadius: "6px",
                      backgroundColor: "#d8eaf5",
                    }}
                  >
                    <h4
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        fontStretch: " normal",
                        fontStyle: "normal",
                        lineHeight: "1.67",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#032541",
                      }}
                    >
                      Total Bill Amount
                    </h4>
                    <h4
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        fontStretch: " normal",
                        fontStyle: "normal",
                        lineHeight: "1.67",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#17ae7b",
                      }}
                    >
                      {numberFormat(overallTotal - generalDiscountAmount)}
                    </h4>
                  </div>
                </div>
                <div className="search-holder">
                  {/* <Paper
                  component="form"
                  sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                >
                 
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                   
                    
                    placeholder="Search voucher number or scan QR Code"
                    inputProps={{ 'aria-label': 'Search voucher number or scan QR Code' }}
                  />
                  <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                </Paper> */}
                  <input onChange={(e) => setvoucherNumber(e.target.value)} style={{ width: "400px", padding: "17px 10px 16px", height: " 54px", fontSize: "13px", fontWeight: "normal", letterSpacing: "normal", textAlign: "left", color: "#b4bcc4", outline: "none", border: "solid grey 1px", borderRadius: "5px" }} placeholder="Search voucher number or Scan code" type="text" name="" id="" />
                </div>
                {resultSearch === false ? (
                  <span
                    style={{
                      fontSize: "14px",
                      marginTop: "20px",
                      color: "red",
                    }}
                  >
                    {searchVoucherMessage}
                  </span>
                ) : (
                  <>
                    <div
                      className="voucher"
                      style={{
                        margin: " 25px 14px 85px 15px",
                        padding: "17px 18px 16px",
                        borderRadius: "6px",
                        border: "solid 1px #707070",
                        backgroundColor: "#fff",
                      }}
                    >
                      {console.log("resultSearch VOUCHERSSSSS", resultSearch)}

                      <div
                        className="voucher-component"
                        onClick={() => {
                          if (searchedVoucher.usage_status == "USED") {
                            setVoucherInfomessage("The voucher have already been used");
                            settitleMessage("Voucher Already used");
                            setvoucherValidationOpen(true);
                          } else if (overallTotal - generalDiscountAmount < searchedVoucher.amount) {
                            setVoucherInfomessage("The voucher amount exceeds total bill kindly add some item");
                            settitleMessage("Voucher Bill");
                            setvoucherValidationOpen(true);
                          } else if (searchedVoucher.usage_status == "EXPIRED") {
                            setVoucherInfomessage("The voucher has already expired");
                            settitleMessage("Voucher Expired");
                            setvoucherValidationOpen(true);
                          } else {
                            setconfirmationVoucherOPen(true);
                            setvoucherValidationOpen(false);
                            setopenVoucherPay(false);
                          }
                        }}
                      >
                        <div className="d-flex" style={{ display: "flex", justifyContent: "space-between" }}>
                          <div className="voucher-details">
                            <div
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "500",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                color: "#032541",
                              }}
                            >
                              Voucher No. {searchedVoucher?.voucherNo}
                            </div>
                            <div
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "11px",
                                fontWeight: "500",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                color: "#032541",
                              }}
                            >
                              Voucher Amount: <span className="amount">{numberFormat(searchedVoucher?.amount)}</span>
                            </div>
                            <div
                              style={{
                                fontSize: "11px",
                                fontWeight: "500",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                color: "#032541",
                              }}
                            >
                              {" "}
                              Expiry Date <span> {moment(searchedVoucher?.expiryDate).format("DD/MM/YYYY:HH:MM:SS")}</span>{" "}
                            </div>
                          </div>
                          <div className="voucher-status">
                            <span
                              style={{
                                fontSize: "16px",
                                marginTop: "10px",
                                fontWeight: "500",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                color: "#17ae7b",
                              }}
                            >
                              {searchedVoucher?.usage_status}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Box>
            </Box>
          </Modal>

          <Modal>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                width: "20%",
                height: "fit-content",
              }}
            >
              <Box style={{ width: "100%", padding: "10px" }}>
                <p style={{ textAlign: "center" }}>
                  <img src={powericon} />
                </p>
              </Box>
            </div>
          </Modal>

          {/* ALL PAYMENT MODAL */}
          {openPayModal && <AllPaymentModal openPayModal={openPayModal} setPayOpenModal={setPayOpenModal} saveOrderToDbAndPopulateMpesa={saveOrderToDbAndPopulateMpesa} setMessagesToBeFetched={setMessagesToBeFetched} setMpesaPayModal={setMpesaPayModal} cashModalPayment={cashModalPayment} setOpenCashPayment={setOpenCashPayment} setCardModalOpen={setCardModalOpen} setBankPayModal={setBankPayModal} setopenVoucherPay={setopenVoucherPay} setOpenCreditNote={setOpenCreditNote} />}
          {/* OUT OF STOCK MODAL */}
          {noItems && <OutOfStock noItems={noItems} setNoItems={setNoItems} />}

          {/* CASH PAYMENT MODAL */}
          {cashModalPayment && <CashModal newOverallTotal={newOverallTotal} cashModalPayment={cashModalPayment} overallTotal={overallTotal} generalDiscountAmount={generalDiscountAmount} setItemDiscount={setItemDiscount} checkedDiscount={checkedDiscount} setOpenCashPayment={setOpenCashPayment} setCashRecieved={setCashRecieved} cashRecieved={cashRecieved} setCashPaymentDetails={setCashPaymentDetails} />}

          {cashPaymentDetails && <Confirmcashpaymentmodal cashPaymentDetails={cashPaymentDetails} setCashPaymentDetails={setCashPaymentDetails} newOverallTotal={newOverallTotal} cashRecieved={cashRecieved} generalDiscountAmount={generalDiscountAmount} newcustomerBalance={newcustomerBalance} saveOrderToDb={saveOrderToDb} />}

          {/* OVERPAYMENT MODAL */}
          {mpesaAmountModal && <MorePayments mpesaAmountModal={mpesaAmountModal} setMpesaAmountModal={setMpesaAmountModal} />}

          {/* SUICCESS ORDER SAVED */}
          {openSnack && <SucessModal openSnack={openSnack} setOpenSnack={setOpenSnack} message={"Order saved successfully!"} />}

          {customerPreview && <CustomerAccounts customerPreview={customerPreview} setCustomerPreview={setCustomerPreview} customerOnAccountsName={customerOnAccountsName} customerOnAccountsBalance={customerOnAccountsBalance} customerOnAccountsBill={customerOnAccountsBill} setCustomerConfirm={setCustomerConfirm} saveOrderToBd={saveOrderToBd} generalDiscountAmount={generalDiscountAmount} />}

          {customerConfirm && <ConfirmOrder customerConfirm={customerConfirm} setCustomerConfirm={setCustomerConfirm} customerOnAccountsName={customerOnAccountsName} customerOnAccountsBill={customerOnAccountsBill} saveOrderToBd={saveOrderToBd} executePay={executePay} />}

          {sucessCustomerAccounts && <SuccessCustomerOnAccounts sucessCustomerAccounts={sucessCustomerAccounts} setSuccessCustomerAccounts={setSuccessCustomerAccounts} />}

          {/*  */}
          {customerBalanceDepleted && <AlertIfBalanceIsDepleted message={"The bill total exceeds the customer balance Top up your account  or review your order to proceed"} customerBalanceDepleted={customerBalanceDepleted} />}

          {/* MPESA PAY MODAL */}
          {mpesaPayModal && <MpesaPaymentModal mpesaPayModal={mpesaPayModal} setMpesaPayModal={setMpesaPayModal} messagesCollection={messagesCollection} allPayTotals={allPayTotals} setAllPayAmount={setAllPayAmount} setAllPayCustomerName={setAllPayCustomerName} setAllPayCustomerNumber={setAllPayCustomerNumber} setAllPayTransId={setAllPayTransId} setMpesaAmountModal={setMpesaAmountModal} unUseMessage={unUseMessage} setConfirmPatDetails={setConfirmPatDetails} />}

          {bankPayModal && <BankPayModal setBankCustomerName={setBankCustomerName} setPayBankAmount={setPayBankAmount} setBankPayNum={setBankPayNum} setpayBankDate={setpayBankDate} setPayBankId={setPayBankId} bankPayModal={bankPayModal} setBankPayModal={setBankPayModal} bankTotal={bankTotal} setMessagesToBeFetched={setMessagesToBeFetched} messagesCollection={messagesCollection} setMpesaPayModal={setMpesaPayModal} setBankLists={setBankLists} bankMessages={bankMessages} setBankMessages={setBankMessages} bankList={bankList} setMpesaAmountModal={setMpesaAmountModal} setConfirmPayModal={setConfirmPayModal} unUseMessage={unUseMessage} />}

          {/* credit note payment */}
          {openCreditNote && <CreditnotepayModal openCreditNote={openCreditNote} billTotal={overallTotal} setCreditnotesearch={setCreditnotesearch} creditNoteData={creditNoteData} creditnotesearch={creditnotesearch} creditNoteError={creditNoteError} setCreditNotePayId={setCreditNotePayId} setOpenCreditNote={setOpenCreditNote} setCreditNoteId={setCreditNoteId} setopenConfirmCredit={setopenConfirmCredit} creditNotedetails={creditNotedetails} setCreditNoteDetails={setCreditNoteDetails} setOpenCreditPayOverPayment={setOpenCreditPayOverPayment} />}

          {openConfirmCredit && <ConfirmPayCreditNote openConfirmCredit={openConfirmCredit} setopenConfirmCredit={setopenConfirmCredit} creditNoteAmount={creditNotedetails.amount} creditNoteNumber={creditNotedetails.no} executePayVoucher={executePayVoucher} setOpenCreditPayOverPayment={setOpenCreditPayOverPayment} />}

          {openCreditpayOverPayment && <ConfirmOverPaymentCreditnote openCreditpayOverPayment={openCreditpayOverPayment} message={"The Bill Amount is less than the credit note amount add more items to redeem the credit note"} setOpenCreditPayOverPayment={setOpenCreditPayOverPayment} />}
          {/* SENDING REQUEST MODAL */}
          <Modal
            open={sendRequest}
            //  onClose={() => setSendRequest(false)}
          >
            <div style={universalStyles.sendRequestModal}>
              <Box style={{ width: "100%", textAlign: "right", padding: "10px" }}>
                <span>
                  <Close onClick={() => setSendRequest(false)} style={{ cursor: "pointer" }} />
                </span>
              </Box>

              <Box style={{ width: "100%" }}>
                <p style={{ textAlign: "center" }}>
                  <img
                    style={{
                      width: "80px",
                      heigth: "80px",
                      objectFit: "fit-content",
                    }}
                    src={sendpayrequest}
                  />
                </p>

                <p style={universalStyles.xlNormalParaTextCenter}>
                  Sending the request...
                  <br />
                  Please Wait
                </p>
              </Box>
            </div>
          </Modal>

          {/* MPESA  PAYMENTS MODALS */}

          <Modal
            open={confrimPayModal}
            //  onClose={() => setConfirmPayModal(false)}
          >
            <div style={universalStyles.confirmBankPaymentsModal}>
              <Box style={{ width: "100%" }}>
                <p style={{ textAlign: "center" }}>
                  <img src={powericon} />
                </p>
              </Box>
              <Box style={{ width: "100%" }}>
                <p style={universalStyles.mdNormalParaTextAlignCenter}>Confirm Payment</p>
                <p style={universalStyles.mdNormalParaTextAlignCenter}>Are you sure you want to confirm this payment</p>
              </Box>

              <Grid container justifyContent={"space-between"} alignItems={"center"} style={{ width: "100%", padding: "0px 30px 0px 30px" }}>
                <Grid item>
                  <span style={universalStyles.smNormalParaTextAlignCenter}>Amount</span>
                </Grid>

                <Grid item>
                  <span style={universalStyles.smNormalParaTextAlignCenter}>{numberFormat(bankpayAmount)}</span>
                </Grid>
              </Grid>

              <Grid container justifyContent={"space-between"} alignItems={"center"} style={{ width: "100%", padding: "0px 30px 0px 30px" }}>
                <Grid item>
                  <span style={universalStyles.smNormalParaTextAlignCenter}>Customer Name</span>
                </Grid>

                <Grid item>
                  <span style={universalStyles.smNormalParaTextAlignCenter}>{bankCustomerName}</span>
                </Grid>
              </Grid>

              <Grid container justifyContent={"space-between"} alignItems={"center"} style={{ width: "100%", padding: "0px 30px 0px 30px" }}>
                <Grid item>
                  <span style={universalStyles.smNormalParaTextAlignCenter}>Phone Number</span>
                </Grid>

                <Grid item>
                  <span style={universalStyles.smNormalParaTextAlignCenter}>{bankPayNum}</span>
                </Grid>
              </Grid>

              <Grid container justifyContent={"space-between"} alignItems={"center"} style={{ width: "100%", padding: "0px 30px 0px 30px" }}>
                <Grid item>
                  <span style={universalStyles.smNormalParaTextAlignCenter}>Transaction Date</span>
                </Grid>

                <Grid item>
                  <span style={universalStyles.smNormalParaTextAlignCenter}>{bankPayDate}</span>
                </Grid>
              </Grid>

              <Grid container justifyContent={"space-between"} alignItems={"center"} style={{ width: "100%", padding: "0px 30px 0px 30px" }}>
                <Grid item>
                  <span style={universalStyles.smNormalParaTextAlignCenter}>Reference No.</span>
                </Grid>

                <Grid item>
                  <span style={universalStyles.smNormalParaTextAlignCenter}>{bankPayId}</span>
                </Grid>
              </Grid>

              <Grid container justifyContent={"space-between"} alignItems={"center"} style={{ width: "100%", padding: "0px 30px 0px 30px" }}>
                <Grid item>
                  <span style={universalStyles.smNormalParaTextAlignCenter}>Type</span>
                </Grid>

                <Grid item>
                  <span style={universalStyles.smNormalParaTextAlignCenter}>{messagesTobeFetched}</span>
                </Grid>
              </Grid>

              <Grid container justifyContent={"space-between"} alignItems={"center"} style={{ width: "100%", padding: "0px 30px 0px 30px" }}>
                <Grid item>
                  <span style={universalStyles.smNormalParaTextAlignCenter}>Total Amount</span>
                </Grid>

                <Grid item>
                  <span style={universalStyles.smNormalParaTextAlignCenter}>{numberFormat(bankTotal)}</span>
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                style={{
                  width: "100%",
                  padding: "0px 30px 0px 30px",
                  margin: "0px 0px 10px 0px",
                }}
              >
                <Grid item>
                  <span style={universalStyles.smNormalParaTextAlignCenter}>Balance</span>
                </Grid>

                <Grid item>
                  <span style={universalStyles.smNormalParaTextAlignCenter}>{numberFormat(bankCustomerBalance)}</span>
                </Grid>
              </Grid>

              <Grid container justifyContent={"space-between"} alignItems={"center"} style={{ width: "100%", padding: "0px 30px 0px 30px" }}>
                <Grid item>
                  <Button onClick={() => setConfirmPayModal(false)} style={{ border: "solid 1px #dc3545", color: "#dc3545" }}>
                    Cancel
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    onClick={() => {
                      executePay();
                      setConfirmPayModal(false);
                    }}
                    style={{ color: "#ffffff", backgroundColor: "#032541" }}
                  >
                    PAY NOW
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Modal>

          {/* SUCCESSFULLY BILL REQUEST */}
        </Grid>
      )}

      {requestPaymentComponent && <Requestpayment billTotal={newOverallTotal}  toggleStartOrderComponents={toggleStartOrderComponents} requestPayId={requestPayId} setSuccessfullyPaid={setSuccessfullyPaid} />}
    </div>
  );
};
export default StartOrder;
