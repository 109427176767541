import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    servicesToAdd: null,
    categories:null,
    currentServices:null
};

const servicesSlice = createSlice({
    name: "services",
    initialState,
    reducers: {
        addService: (state, action) => {
            if (!state.servicesToAdd) {
                state.servicesToAdd = [action.payload];
            } else {
                state.servicesToAdd.push(action.payload);
            }
        },
        setCategories:(state,action ) =>{
            state.categories = action.payload
        },
        removeFromServicesToAdd:(state, action) =>{
            let index = action.payload.index;
            if (index > -1) {
                state.servicesToAdd.splice(index, 1);
            }
        },
        clearServicesToAdd:(state, action) =>{
            state.servicesToAdd = null;
        },
        setCurrentServices:(state,action) =>{
            state.currentServices = action.payload
        },
        addToCurrentService: (state, action) => {
            if (!state.currentServices) {
                state.currentServices = [action.payload];
            } else {
                state.currentServices.push(action.payload);
            }
        },
        removeFromCurrentServices : (state, action) =>{
            let index = action.payload.index;
            if (index > -1) {
                state.currentServices.splice(index, 1);
            }
        }
    },
});

export const {addService,setCategories,removeFromServicesToAdd, clearServicesToAdd,setCurrentServices,addToCurrentService,removeFromCurrentServices} = servicesSlice.actions;

export default servicesSlice.reducer;
