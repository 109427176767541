import React, { useState } from "react";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, Input } from "@material-ui/core";
import ShareIcon from "@mui/icons-material/Share";
import AddIcon from "@mui/icons-material/Add";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, Button, TextField, Tab, Modal, Tabs, FormControlLabel, Alert, Snackbar } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { alpha, styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import moment from "moment-timezone";
import Typography from "@mui/material/Typography";
import { ArrowBack } from "@material-ui/icons";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { SuccessVoucher } from "./successalert";
import { Link } from "react-router-dom";
import { ConfirmationAlert } from "./questionModal";
import decline from "../../common/images/dissaprovetick.svg";
import success from "../../common/images/success.png";
import Checkbox from "@material-ui/core/Checkbox";
import { FormGroup } from "@material-ui/core";
import { SuccessUpdateVoucher } from "./successmodal";
import { ValidationAlertMessage } from "./validation";
import { useNavigate } from "react-router-dom";

// Custom Tab Styling
const AntTabs = styled(TabList)({
  borderBottom: "0px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#ffffff00",
  },
});
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  fontSize: "12px",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "2.75",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#6e7074",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    color: "#032541",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "#dc3545",
    fontWeight: 600,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "blue",
  },
}));

const AddProductModalstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "15px",
  backgroundColor: "#fff",
  width: "665px",
  height: "650px",
};

const VouchersPage = () => {
  // handle tab Changes
  const [pageSize, setPageSize] = React.useState(5);

  const [tabValue, setTabValue] = React.useState("Active");
  console.log(tabValue);
  const handleTabChange = (event, newValue) => {
    console.log("TabValue", newValue);
    setTabValue(newValue);
  };
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 100,
    // search : searchValue
  });

  const [approved, setApproved] = useState([]);
  const [activeTab, setActiveTabs] = useState([]);
  const [pendingTab, setPendingTabs] = useState([]);
  const [declineTab, setDeclineTabs] = useState([]);

  // submit details
  const [NoOfVouchers, setNoOfVouchers] = useState("");
  const [VoucherAmount, SetVoucherAmount] = useState("");
  const [startDate, setstartDate] = useState("");
  const [expiryDate, setexpiryDate] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [searchValue, setSearchValue] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [selectedRows, setSelectedRows] = useState([]);
  const [voucherAmtRow, setVoucherAmt] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [openeErrorSnack, setOpenErrorSnack] = useState(false);
  const [availableCustomers, setAvailableCustomers] = useState([]);
  const [customerInfo, setCustomerInfo] = useState();

  console.log(customerInfo, "customer info loggiing out");
  //fetch available customers

  const fetchAvailableCustomers = async () => {
    try {
      const response = await fetch(baseUrl + "/api/customers_list", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      const responseFromApi = await response.json();

      if (response.status === 200) {
        setAvailableCustomers(responseFromApi.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //use effect to fetch available customers
  useEffect(() => {
    fetchAvailableCustomers();
  }, []);

  //update customerInfo

  const updateCustomerInfo = () => {};

  //handle  customer id update

  const addIds = (newId, voucherAmount) => {
    const newIdArray = [...selectedRows];

    const newVoucherAmt = [...voucherAmtRow];
    const index = newIdArray.indexOf(newId);

    if (index === -1) {
      newIdArray.push(newId);
    } else {
      newIdArray.splice(index, 1);
    }
    setSelectedRows(newIdArray);

    if (index === -1) {
      newVoucherAmt.push(voucherAmount);
    } else {
      newVoucherAmt.splice(index, 1);
    }
    setVoucherAmt(newVoucherAmt);
  };
  console.log(voucherAmtRow);
  console.log(selectedRows);

  const OpeneditDetails = (row) => {
    setEditNoOfVouchers(row.voucherCount);
    SetEditVoucherAmount(row.voucherAmount);
    setEditstartDate(row.dateCreated);
    setEditexpiryDate(row.expiryDate);
  };

  var TOTALVALUE = 0;

  console.log(voucherAmtRow.length);

  for (let i = 0; i < voucherAmtRow.length; i++) {
    TOTALVALUE += parseInt(voucherAmtRow[i]);
  }
  console.log(TOTALVALUE);

  // Currency Formatter
  const ActiveColumns = [
    {
      field: "batchNumber",
      headerName: "Voucher Batch ID",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              <Link to={`/voucher/${params.row.batchId}`}>{params.value}</Link>
            </Typography>
          </div>
        );
      },
    },
    {
      field: "voucherCount",
      headerName: "No of Vouchers",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              <Link to={`/voucher/${params.row.batchId}`}>{params.value}</Link>
            </Typography>
          </div>
        );
      },
    },

    {
      field: "approvedByName",
      headerName: "Approved BY",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              <Link to={`/voucher/${params.row.batchId}`}>{params.value}</Link>
            </Typography>
          </div>
        );
      },
    },
    {
      field: "createdByName",
      headerName: "Submitted By",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "dateApproved",
      headerName: "Approved ON",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              <Link to={`/voucher/${params.row.batchId}`}>
                {/*         return moment(params.value).format("DD/MM/YYYY");
                 */}
                {moment(params.value).format("DD/MM/YYYY")}
              </Link>
            </Typography>
          </div>
        );
      },
    },

    {
      field: "voucherAmount",
      headerName: "Total Amount",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              <Link to={`/voucher/${params.row.batchId}`}>{params.row.voucherAmount * params.row.voucherCount}</Link>
            </Typography>
          </div>
        );
      },
      valueFormatter: (params) => {
        // console.log("params", params);
        return numberFormat(params.value);
      },
    },
  ];
  const pending = [
    {
      field: "batchNumber",
      headerName: "Voucher Batch ID",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "voucherCount",
      headerName: "No of Vouchers",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "createdByName",
      headerName: "Submitted By",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "voucherAmount",
      headerName: "Voucher Amount ",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "dateApproved",
      headerName: "Submmited ON",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      valueFormatter: (params) => {
        // console.log("params", params);
        return moment(params.value);
      },
    },

    {
      field: "Action",
      headerName: "Status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          // add a checkbox to select multiple rows
          <div>
            <input
              type="checkbox"
              onClick={() => {
                addIds(params.row.batchNumber, params.row.voucherAmount);
                OpeneditDetails(params.row);
              }}
            />
          </div>
        );
      },
    },
  ];
  const declineColumns = [
    {
      field: "batchNumber",
      headerName: "Voucher Batch ID",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "voucherCount",
      headerName: "No of Vouchers",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "createdByName",
      headerName: "Submitted By",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "declinedByName",
      headerName: "Declined BY",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "dateDeclined",
      headerName: "Declined On",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      valueFormatter: (params) => {
        // console.log("params", params);
        return moment(params.value);
      },
    },
  ];

  const ativeRow = activeTab
    .filter((item) => {
      return searchValue?.toLowerCase() === "" ? item : item?.batchNumber?.toLowerCase().includes(searchValue?.toLowerCase());
    })
    .map((item, index) => ({
      id: item.batchId,
      batchId: item.batchId,
      batchNumber: item.batchNumber,
      voucherCount: item.voucherCount,
      createdByName: item.createdByName,
      approvedByName: item.approvedByName,
      usage_status: item.usage_status,
      dateApproved: item.dateApproved,
      voucherAmount: item.voucherAmount,
    }));

  const declineapprovalRow = declineTab
    .filter((item) => {
      return searchValue?.toLowerCase() === "" ? item : item?.batchNumber?.toLowerCase().includes(searchValue?.toLowerCase());
    })
    .map((item, index) => ({
      id: item.batchId,
      batchId: item.batchId,
      batchNumber: item.batchNumber,
      voucherCount: item.voucherCount,
      createdByName: item.createdByName,
      declinedByName: item.declinedByName,
      dateDeclined: item.dateDeclined,
      usage_status: item.usage_status,
      voucherAmount: item.voucherAmount,
    }));
  const pendingapprovalRow = pendingTab
    .filter((item) => {
      return searchValue?.toLowerCase() === "" ? item : item?.batchNumber?.toLowerCase().includes(searchValue?.toLowerCase());
    })
    .map((item, index) => ({
      id: item.batchId,
      batchId: item.batchId,
      batchNumber: item.batchNumber,
      voucherCount: item.voucherCount,
      createdByName: item.createdByName,
      dateCreated: item.dateCreated,
      usage_status: item.usage_status,
      voucherAmount: item.voucherAmount,
    }));

  const handleSearchBox = (event) => {
    setSearchValue(event.target.value);
  };
  useEffect(() => {
    get_approved_batches();
    get_decline_batches();
    get_pending_batches();
  }, [tabValue, pageState.page, pageState.pageSize]);

  const get_approved_batches = async () => {
    try {
      // console.log("ON");
      setPageState((old) => ({ ...old, isLoading: true }));
      const ActiveResponse = await fetch(`${baseUrl}/api/get_approved_batches?limit=${pageState.pageSize}&page=${pageState.page}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      const data = await ActiveResponse.json();
      setActiveTabs(data.results);
      // setPageState((old) => ({
      //   ...old,
      //   isLoading: false,
      //   data: data.results,
      // }));
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: data.results,
        total: data.count,
      }));
      if (ActiveResponse.status === 200) {
        setActiveTabs(data.results);
        console.log("response Data", data.results);
        localStorage.setItem("tabValue", "Active");
      } else if (ActiveResponse.status === 401) {
        handleSessionOpen();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const get_pending_batches = async () => {
    try {
      // console.log("ON");
      setPageState((old) => ({ ...old, isLoading: true }));
      const ActiveResponse = await fetch(`${baseUrl}/api/get_pending_or_declined_vouchers?limit=${pageState.pageSize}&page=${pageState.page}&status=pending`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      const data = await ActiveResponse.json();
      setPendingTabs(data.results);
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: data.results,
      }));
      if (ActiveResponse.status === 200) {
        setPendingTabs(data.results);
        console.log("Pending Data", data.results);
        localStorage.setItem("tabValue", "Active");
      } else if (ActiveResponse.status === 401) {
        handleSessionOpen();
      }
    } catch (e) {
      console.log(e);
    }
  };
  const get_decline_batches = async () => {
    try {
      // console.log("ON");
      setPageState((old) => ({ ...old, isLoading: true }));
      const ActiveResponse = await fetch(`${baseUrl}/api/get_pending_or_declined_vouchers?limit=${pageState.pageSize}&page=${pageState.page}&status=declined`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      const data = await ActiveResponse.json();
      setDeclineTabs(data.results);
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: data.results,
      }));
      if (ActiveResponse.status === 200) {
        setDeclineTabs(data.results);
        console.log("Decline Data", data.results);
        localStorage.setItem("tabValue", "Active");
      } else if (ActiveResponse.status === 401) {
        handleSessionOpen();
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handlExpiryDateChange = (value) => {
    setexpiryDate(value);
    setbtnState(true);
  };

  const handleStartDateChange = (value) => {
    setstartDate(value);
  };

  const handleEditExpiryDateChange = (value) => {
    setEditexpiryDate(value);
    setbtnState(true);
  };

  const handleEditStartDateChange = (value) => {
    setEditexpiryDate(value);
  };
  const [error, seterror] = useState(false);
  const [btnState, setbtnState] = useState(false);
  console.log("btnState", btnState);

  let color = btnState ? "#032541" : " #9ba6b3";

  const handleSubmut = async (e) => {
    e.preventDefault();
    console.log("NoOfVouchers", NoOfVouchers);
    console.log("VoucherAmount", VoucherAmount);
    console.log("startDate", startDate);
    console.log("expiryDate", expiryDate);

    if (customerInfo.limit < NoOfVouchers * VoucherAmount) {
      setOpenErrorSnack(true);
      setSnackError("Check voucher limit...");
      setTimeout(() => {
        setOpenErrorSnack(false);
      }, 2000);
      return;
    }
    const expiryDateObj = dayjs(expiryDate);
    // Format the date as "YYYY-MM-DDTHH:mm:ssZ"
    const formattedExpiryDate = expiryDateObj.format("YYYY-MM-DDTHH:mm:ssZ");
    // Add one year to the date and format it again
    const nextYearExpiryDate = expiryDateObj.add(1, "year").format("YYYY-MM-DDTHH:mm:ssZ");
    console.log("formattedExpiryDate", formattedExpiryDate);

    const startDateObj = dayjs(startDate);
    // Format the date as "YYYY-MM-DDTHH:mm:ssZ"
    const formattedStartDate = startDateObj.format("YYYY-MM-DDTHH:mm:ssZ");
    // Add one year to the date and format it again
    const nextYearStartDate = startDateObj.add(1, "year").format("YYYY-MM-DDTHH:mm:ssZ");
    console.log("formattedStartDate", formattedStartDate);

    try {
      const response = await fetch(`${baseUrl}/api/create_voucher_batch`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({
          noOfVouchers: NoOfVouchers,
          eachVoucherAmount: VoucherAmount,
          startDate: formattedStartDate,
          expiryDate: formattedExpiryDate,
          customerId: customerInfo._id,
        }),
      });
      const data = await response.json();
      console.log("created voucher is ", data);
      if (response.status === 200) {
        console.log("data", data);
        // setDeactivateModalOpen(true);
        setMessage("Voucher created successfully...");

        setOpenSnack(true);
        setTimeout(() => {
          setOpenSnack(false);
          window.location.reload();
          // setSuccessfullyPaid(true);
        }, 2000);

        handleClose();
      }
    } catch {
      console.log("error", error);
    }
  };

  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
  const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

  const [deactivateModalOpen1, setDeactivateModalOpen1] = useState(false);
  const DeactivateModalHandleClose1 = () => setDeactivateModalOpen1(false);

  const [approvemodalOpen, SetApproveModalOpen] = useState(false);
  const ApproveModalClose = () => SetApproveModalOpen(false);

  const [successModalOPen, setSuccessUpdate] = useState(false);
  const UpdateModalHandleClose = () => setSuccessUpdate(false);

  const [validateModalOPen, setvalidateModalOPen] = useState(false);
  const validateModalClose = () => setvalidateModalOPen(false);
  const [message, setMessage] = useState("Successfully...");
  const [snackError, setSnackError] = useState("");
  const [validate_message, setvalidate_message] = useState("");
  const [titleMessage, settitleMessage] = useState("Decline");

  // EDIT
  const [editVoucherObj, setEditVoucherObj] = useState([]);
  const [editNoOfVouchers, setEditNoOfVouchers] = useState("");
  const [editVoucherAmount, SetEditVoucherAmount] = useState("");
  const [editstartDate, setEditstartDate] = useState("");
  const [editexpiryDate, setEditexpiryDate] = useState("");

  // approve
  // {"action":"declined","batchIds":["B19","B21","B22"]}
  // POST https://zed.swerri.io/api/action_batches
  const approveVoucher = async (e) => {
    e.preventDefault();

    try {
      console.log("ON");
      setPageState((old) => ({ ...old, isLoading: true }));
      const ActiveResponse = await fetch(`${baseUrl}/api/action_batches`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({
          action: "approved",
          batchIds: selectedRows,
        }),
      });
      if (ActiveResponse.status === 200) {
        SetApproveModalOpen(false);
        setMessage("Voucher approved");

        // setSuccessUpdate(true);
        setOpenSnack(true);
        setTimeout(() => {
          setOpenSnack(false);
          window.location.reload();
          // setSuccessfullyPaid(true);
        }, 2000);

        // window.location.reload(true)
      } else if (ActiveResponse.status === 401) {
        alert("Error");
      }
    } catch (e) {
      console.log(e);
    }
  };

  // decline
  const declineVoucher = async (e) => {
    e.preventDefault();

    try {
      console.log("ON");
      setPageState((old) => ({ ...old, isLoading: true }));
      const ActiveResponse = await fetch(`${baseUrl}/api/action_batches`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({
          action: "declined",
          batchIds: selectedRows,
        }),
      });
      if (ActiveResponse.status === 200) {
        // alert("Declined");
        setDeactivateModalOpen1(false);
        // setSuccessUpdate(true);
        // window.location.reload(true)
        setMessage("Voucher declined");
        setOpenSnack(true);
        setTimeout(() => {
          setOpenSnack(false);
          window.location.reload();
          // setSuccessfullyPaid(true);
        }, 2000);
      } else if (ActiveResponse.status === 401) {
        alert("Error");
      }
    } catch (e) {
      console.log(e);
      const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 1000,
        // search : searchValue
      });
    }
  };

  let navigate = useNavigate();
  const navigateToVouchers = (id) => {
    // /voucher/641c6198ab0b5347711be9d2
    navigate(`/voucher/${id}`);
  };
  const getDetailsforCustomerAcc = async () => {};
  return (
    <div>
      <div>
        <div>
          <div>
            <Grid container spacing={2} justifyContent="space-between" direction={"row"} alignItems="center">
              <Grid item column={6}>
                <span
                  style={{
                    margin: "0px 90px 21px 0",

                    fontSize: "25px",
                    fontWeight: "600",
                    fontStretch: " normal",
                    fontStyle: " normal",
                    lineHeight: " 1.32",
                    letterSpacing: " normal",
                    textAlign: " left",
                    color: "#dc3545",
                  }}
                >
                  {" "}
                  vouchers
                </span>
              </Grid>
              {tabValue === "Active" && (
                <Grid item column={6}>
                  <Grid container spacing={2} justifyContent="space-between" direction={"row"} alignItems="center">
                    <Grid item column={6}>
                      {/* <Button
                        style={{
                          // width: " 146px",
                          // height: "55px",
                          // margin: "0px 20px 24px 6px",
                          // padding: "16px 30px 14px",
                          // borderRadius: " 4px",
                          // backgroundColor: "#032541",
                          // color: "#fff",
                          // textAlign: "#center",
                        }}
                      >
                        <ShareIcon style={{ marginRight: "2px" }} /> share
                      </Button> */}
                    </Grid>
                    <Grid item column={6}>
                      <Button
                        onClick={handleOpen}
                        style={{
                          width: " 250px",
                          height: "55px",
                          margin: "0px 20px 24px 6px",
                          padding: "18px 25.7px 17px 25px",
                          borderRadius: " 5px",
                          backgroundColor: "#032541",
                          color: "#fff",
                          textAlign: "#center",
                        }}
                      >
                        {" "}
                        <AddIcon style={{ marginRight: "3px" }} />
                        Create Voucher
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {tabValue === "Pending" && (
                <Grid item column={6}>
                  <Grid container spacing={2} justifyContent="space-between" direction={"row"} alignItems="center">
                    {/* <Grid item column={4}>
                      <h6
                        style={{
                          margin: "10px 0px 0px -10px",

                          fontSize: "14px",
                          fontWeight: "bold",
                          fontStretch: "normal",
                          fontStyle: " normal",
                          lineHeight: " normal",
                          letterSpacing: " normal",
                          textAlign: "center",
                          color: "#032541",
                        }}
                        onClick={() => {
                          if (selectedRows == "") {
                            setvalidate_message("Kindly select the vouchers you want edit");
                            setvalidateModalOPen(true);
                          } else {
                            // setOpen1(true);
                            alert('Coming soon')
                            console.log(selectedRows)
                          }
                        }}
                      >
                        Edit Vouchers
                      </h6>
                    </Grid> */}
                    <Grid item column={4}>
                      <Button
                        onClick={() => {
                          if (selectedRows == "") {
                            setvalidate_message("Kindly select the vouchers you want decline");
                            settitleMessage("Decline Voucher");
                            setvalidateModalOPen(true);
                          } else {
                            setDeactivateModalOpen1(true);
                          }
                        }}
                        style={{
                          margin: "0",
                          padding: "10px",
                          borderRadius: " 5px",
                          border: "1px solid #032541",
                          width: " 146px",
                          color: "#dc3545",
                          textAlign: "#center",
                        }}
                      >
                        Decline
                      </Button>
                    </Grid>
                    <Grid item column={4}>
                      <Button
                        onClick={() => {
                          if (selectedRows == "") {
                            setvalidate_message("Kindly select the vouchers you want approve");
                            settitleMessage("Approve Voucher");

                            setvalidateModalOPen(true);
                          } else {
                            SetApproveModalOpen(true);
                          }
                        }}
                        style={{
                          margin: "0px 20px 0px 6px",
                          width: " 250px",
                          height: "55px",
                          padding: "10px",
                          borderRadius: " 5px",
                          backgroundColor: "#032541",
                          color: "#fff",
                          textAlign: "#center",
                        }}
                      >
                        {" "}
                        <AddIcon style={{ marginRight: "3px" }} />
                        Approve
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {tabValue === "Declined" && (
                <Grid item column={6}>
                  <Button
                    onClick={handleOpen}
                    style={{
                      width: " 250px",
                      height: "55px",
                      margin: "0px 20px 24px 6px",
                      padding: "18px 25.7px 17px 25px",
                      borderRadius: " 5px",
                      backgroundColor: "#032541",
                      color: "#fff",
                      textAlign: "#center",
                    }}
                  >
                    {" "}
                    <AddIcon style={{ marginRight: "3px" }} />
                    Create Voucher
                  </Button>
                </Grid>
              )}
            </Grid>
            <div className="bread-crumbs">
              <span
                style={{
                  height: "28px",
                  margin: "21px 0px 39px 12px",
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: "600",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1.65",
                  letterSpacing: "normal",
                  textAlign: "left",
                  color: "#6e7074",
                }}
              >
                Dashboard
              </span>
              <span
                style={{
                  height: "28px",
                  margin: "21px 0px 39px 12px",
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: "600",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1.65",
                  letterSpacing: "normal",
                  textAlign: "left",
                  color: "#6e7074",
                }}
              >
                \
              </span>
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: "600",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1.65",
                  letterSpacing: "normal",
                  textAlign: "left",
                  color: "#032541",
                }}
              >
                {console.log("tabValuesssss", tabValue)}
                {tabValue === "Active" ? "Active Vouchers" : tabValue === "Pending" ? "Pending Vouchers" : tabValue === "Declined" ? "Declined Vouchers" : tabValue === "Expired" ? "Expired Vouchers" : ""}
              </span>
            </div>
            <div></div>
            <TabContext value={tabValue}>
              <Box>
                <div className="filter-tabs">
                  <div>
                    <Grid container justifyContent="space-between" direction={"row"} alignItems="center">
                      <div style={{ display: "flex" }}>
                        <AntTabs onChange={handleTabChange} aria-label="voucher tabs" TabIndicatorProps={{ hidden: false }} textColor="primary">
                          <AntTab
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "2.06",
                              letterSpacing: "normal",
                              textAlign: "left",

                              margin: "0px 84px 0px 0px",
                            }}
                            label="Active"
                            value="Active"
                          />
                          <AntTab
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "2.06",
                              letterSpacing: "normal",
                              textAlign: "left",

                              margin: "0px 84px 0px 0px",
                            }}
                            label="Pending Approval"
                            value="Pending"
                          />
                          <AntTab
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "2.06",
                              letterSpacing: "normal",
                              textAlign: "left",

                              margin: "0px 84px 0px 0px",
                            }}
                            label="Decline"
                            value="Declined"
                          />
                          {/* <AntTab
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "2.06",
                              letterSpacing: "normal",
                              textAlign: "left",

                              margin: "0px 84px 0px 0px",
                            }}
                            label="Expired"
                            value="Expired"
                          /> */}
                        </AntTabs>
                      </div>

                      <Grid item>
                        <label
                          style={{
                            height: "12px",
                            fontFamily: "Poppins",
                            fontSize: "9px",
                            fontWeight: "bold",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "2.78",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#032541",
                          }}
                        >
                          Search :{" "}
                        </label>
                        <Input
                          startDecorator={<SearchIcon />}
                          size="sm"
                          variant="plain"
                          value={searchValue}
                          onChange={handleSearchBox}
                          style={{
                            fontSize: "12px",
                            color: "#272d3b",
                            borderRadius: "6px",
                            width: "238px",
                            height: "36px",
                            margin: "0 0 0 20.4px",
                            padding: "7px 107.7px 6.4px 11.1px",
                            objectFit: "contain",
                            border: "solid 1px #032541",
                          }}
                          placeholder="search here"
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>

                <Grid container justifyContent="space-between" direction={"row"} alignItems="center">
                  {/*SubCategories Label*/}
                  <Grid item>
                    <Grid item justifyContent="space-between" style={{ marginTop: "34px" }}>
                      {/* <label
                        style={{
                          fontFamily: "Poppins",
                          margin: "0 7px 2px 0",

                          fontSize: "18px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "1.78",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#6e7074",
                          marginRight: "18px",
                        }}
                      >
                        {" "}
                        show{" "}
                      </label> */}
                      {/* <FormControl sx={{ m: 1 }} size="small">
                        <Select
                          sx={{
                            height: "40px",
                            borderRadius: "6px",
                            fontFamily: "Poppins",
                            border: "solid 1px #707070",
                            width: "80px",
                            marginRight: "18px",
                          }}
                          labelId="demo-select-small"
                          id="demo-select-small"
                          defaultValue={10}
                        >
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={20}>20</MenuItem>
                        </Select>
                      </FormControl>
                      <label
                        style={{
                          fontFamily: "Poppins",
                          margin: "0 7px 2px 0",

                          fontSize: "18px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "1.78",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#6e7074",
                          marginRight: "18px",
                        }}
                      >
                        Entries
                      </label> */}

                      {/* <label
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "18px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "2.78",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#6e7074",
                          marginLeft: "25px",
                        }}
                      >
                        {" "}
                        Filter by :{" "}
                      </label>
                      <FormControl sx={{ m: 1 }} size="small">
                        <Select
                          sx={{
                            height: "40px",
                            borderRadius: "6px",
                            fontFamily: "Poppins",
                            border: "solid 1px #707070",
                            width: "120px",
                            marginRight: "18px",
                          }}
                          labelId="demo-select-small"
                          id="demo-select-small"
                          defaultValue={10}
                        
                        >
                       
                        </Select>
                      </FormControl> */}

                      {/* <label
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "18px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "2.78",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#6e7074",
                          marginLeft: "25px",
                        }}
                      >
                      
                        Filter By Date{" "}
                      </label>
                      <FormControl style={{ marginLeft: "10px" }} size="small">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="FIlter by Date"
                            renderInput={(params) => <TextField {...params} />}
                            // format="YYYY-MM-DD HH:MM:ss"
                            inputFormat="DD-MM-YYYY HH:mm:ss"
                            format="DD-MM-YYYY HH:MM:ss"
                          />
                        </LocalizationProvider>
                      </FormControl> */}
                    </Grid>
                  </Grid>

                  {/*Export buttons*/}
                  <Grid item alignContent={"right"} justifyContent="space-between" style={{ marginTop: "34px" }}>
                    <Button
                      sx={{
                        fontWeight: 600,
                        color: "#fff",
                        width: "50px",
                        height: "37px",
                        margin: "0 12px",
                        padding: "9px 7px 8px",
                        backgroundColor: "#05d8e1",
                      }}
                    >
                      CSV
                    </Button>

                    <Button
                      sx={{
                        fontWeight: 600,
                        color: "#fff",
                        width: "50px",
                        height: "37px",
                        margin: "0 12px",
                        padding: "9px 7px 8px",
                        backgroundColor: "#032541",
                      }}
                    >
                      Excel
                    </Button>

                    <Button
                      sx={{
                        fontWeight: 600,
                        color: "#fff",
                        width: "50px",
                        height: "37px",
                        margin: "0 0 0 22px",
                        padding: "9px 12px 8px",
                        backgroundColor: "#dc3545",
                      }}
                    >
                      PDF
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <TabPanel value="Active">
                <DataGrid
                  style={{ height: "500px", width: "auto" }}
                  rows={ativeRow}
                  columns={ActiveColumns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  pagination
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "10px",
                    color: "#272d3b",
                  }}
                  onRowClick={(row) => {
                    // handle row click event here
                    console.log("Clicked row:", row.id);
                    navigateToVouchers(row.id);
                  }}
                />
              </TabPanel>
              <TabPanel value="Pending">
                <DataGrid style={{ height: "500px", width: "auto" }} rows={pendingapprovalRow} columns={pending} paginationMode="server" page={pageState.page - 1} pagination rowsPerPageOptions={[5, 10, 20, 50, 100]} rowCount={pageState.total} loading={pageState.isLoading} sx={{ fontFamily: "Poppins", fontSize: "10px", color: "#272d3b" }} />
              </TabPanel>
              <TabPanel value="Declined">
                <DataGrid
                  style={{ height: "500px", width: "auto" }}
                  rows={declineapprovalRow}
                  columns={declineColumns}
                  paginationMode="server"
                  page={pageState.page - 1}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  rowCount={pageState.total}
                  loading={pageState.isLoading}
                  pagination
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "10px",
                    color: "#272d3b",
                  }}
                />
              </TabPanel>
              {/* <TabPanel value="Expired">
                <DataGrid
                  style={{ height: "500px", width: "auto" }}
                  rows={ativeRow}
                  columns={ActiveColumns}
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "10px",
                    color: "#272d3b",
                  }}
                />
              </TabPanel> */}
            </TabContext>
          </div>
        </div>
      </div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={AddProductModalstyle}>
          <Box
            sx={{
              width: "665px",
              height: "90px",
              margin: "0 0 9px",
              padding: "35px 30px 34px",
              borderRadius: "16px",
              backgroundColor: "#dc3545",
            }}
          >
            <Typography
              style={{
                width: "100%",
                height: "21px",
                margin: "0 423px 0 0",
                fontFamily: "Poppins",
                fontSize: "15px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "center",
                color: "#fff",
              }}
            >
              <div className="top-modal-bar" style={{ display: "flex", justifyContent: "space-around" }}>
                <ArrowBack onClick={handleClose} />
                <span>CREATE A VOUCHER</span>
                <CloseIcon onClick={handleClose} />
              </div>
            </Typography>
          </Box>
          <Box
            sx={{
              width: "605.2px",
              height: "494.5px",
              margin: "18.2px 29.4px 6.2px 30.4px",
              padding: "33.8px 55.6px 0.8px 43.6px",
              backgroundColor: "#f5f7ff",
            }}
          >
            <form onSubmit={handleSubmut}>
              <FormControl
                variant="standard"
                style={{
                  width: "100%",
                  height: "100%",
                  margin: "0 0 9px",
                }}
              >
                <TextField
                  id="No of Vouchers"
                  label="No of Vouchers"
                  required
                  style={{
                    marginTop: "20px",
                  }}
                  value={NoOfVouchers}
                  type="number"
                  onChange={(e) => {
                    setNoOfVouchers(e.target.value);
                  }}
                />
                <TextField
                  style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                  value={VoucherAmount}
                  required
                  id="outlined-multiline-static"
                  label="Each Voucher Amount"
                  defaultValue="Each Voucher Amount"
                  type="number"
                  placeholder="Each Voucher Amount"
                  onChange={(e) => {
                    SetVoucherAmount(e.target.value);
                  }}
                />
                <div>
                  <TextField fullWidth id="filled-select-currency" select label="Available customers" onChange={(e) => setCustomerInfo(e.target.value)}>
                    {availableCustomers.map((option) => (
                      <MenuItem value={option} key={option._id}>
                        {option.customerName} {option.limit}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <FormControl style={{ marginTop: "20px" }} size="small">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Start Date"
                      renderInput={(params) => <TextField {...params} />}
                      // format="YYYY-MM-DD HH:MM:ss"
                      inputFormat="DD-MM-YYYY HH:mm:ss"
                      value={startDate}
                      onChange={handleStartDateChange}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl style={{ marginTop: "20px" }} size="small">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Expiry Date"
                      renderInput={(params) => <TextField {...params} />}
                      // format="YYYY-MM-DD HH:MM:ss"
                      inputFormat="DD-MM-YYYY HH:mm:ss"
                      value={expiryDate}
                      onChange={handlExpiryDateChange}
                    />
                  </LocalizationProvider>
                </FormControl>
                <div sx={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      margin: "20px 0px 0",
                      borderRadius: "4px",
                      backgroundColor: `${color}`,
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#fff",
                      width: "502px",
                      height: "50px",
                    }}
                  >
                    Submit for Approval
                  </Button>
                </div>
              </FormControl>
            </form>
          </Box>
        </Box>
      </Modal>

      <SuccessUpdateVoucher deactivateModalOpen={successModalOPen} onClose={UpdateModalHandleClose} action={message} />
      <ValidationAlertMessage deactivateModalOpen={validateModalOPen} onClose={validateModalClose} message={validate_message} titleMessage={titleMessage} setSuccessUpdate={setSuccessUpdate} />

      <SuccessVoucher deactivateModalOpen={deactivateModalOpen} onClose={DeactivateModalHandleClose} />
      <ConfirmationAlert deactivateModalOpen={deactivateModalOpen1} onClose={DeactivateModalHandleClose1} formAction={declineVoucher} actionImage={decline} action="decline" amt={TOTALVALUE} title="Decline Vouchers" processName={"Decline"} />
      <ConfirmationAlert deactivateModalOpen={approvemodalOpen} onClose={ApproveModalClose} formAction={approveVoucher} actionImage={success} action="approve" amt={TOTALVALUE} title={"Approve Vouchers"} processName={"Approve"} />
      <Snackbar open={openSnack} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity="success" sx={{ width: "100%" }}>
          <span>{message}</span>
        </Alert>
      </Snackbar>
      <Snackbar open={openeErrorSnack} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity="error" sx={{ width: "100%" }}>
          <span>{snackError}</span>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default VouchersPage;
