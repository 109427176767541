import React, {useState, useEffect} from 'react';
import {Box, Button, TextField, Typography} from "@mui/material";
import CustomInputLabel from "../../School/CustomInputLabel";
import CustomTextField from "../../School/CustomTextField";
import CustomSelectField from "../../School/CustomSelectField";
import {useDispatch, useSelector} from "react-redux";
import {updateItem} from "../../../features/businessCategorySlice";
import {SuccessAlert} from "../../snackBar Alerts/successAlert";

const EditServiceCategoryForm = (props) =>{
    const [successToast, setSuccessToast] = useState({state: false, message: ""})
    const [successShow, setSuccessShow] = useState({state: false, message: ""})
    const { categoriesToAdd} = useSelector((store) => store.businessCategory);
    // const [formData, setFormData] = useState({})
    const cancelButtonStyles =
        {"width":"7.813rem",
            "height":"2.813rem",
            "padding":"12.5px 36.7px 12.5px 38.3px",
            "borderRadius":"4px",
            "border":"solid 1px #dc3545",
            "color":"#dc3545"
        }

    const buttonStyles =
        {"width":"7.813rem",
            "height":"2.813rem",
            "borderRadius":"4px",
        }

    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        categoryName:"",
        categoryType:"",
        categoryDescription:"",
    })

    useEffect(() => {
        setFormData(props.data || {});
      }, [props.data]);
    

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    // get stored form data details
    useEffect(() =>{
        const populateInput = () =>{
            if(categoriesToAdd){
                categoriesToAdd.map((item) =>{
                    formData.categoryName = item.name;
                    formData.categoryDescription = item.description;
                    formData.categoryType = item.type;
                })
            }
        }
        populateInput()
    },[categoriesToAdd, formData])


    // force component to rerender
    // const useForceUpdate = () =>{
    //     const [value, setValue] = useState(0);
    //     return () => {setValue( value => value + 1); console.log(value)};
    // }

    // const forceUpdate = useForceUpdate();

    // re-render
    // useEffect(() =>{
    //     forceUpdate();
    // },[forceUpdate])

    const updateItem = () =>{
        let newData ={
            name:formData.categoryName,
            type: formData.categoryType,
            description:formData.categoryDescription,
            productService:"Service"
        }
        dispatch(updateItem(newData))
        setFormData({
            categoryName: "",
            categoryType:"",
            categoryDescription:""
        })
        setSuccessShow({state:true, message:"Category(s) created successfully."})
    }

    const saveHandler = () =>{
        updateItem();
        setSuccessToast({state:true, message: "Category(s) created successfully."})
        props.setView("done")
    }

    const categoryTypeOptions = [
        {value:"Product", label:"Product"},
        {value:"Service", label:"Service"}
    ]


    return(
        <Box component="div" sx={{display:"flex", flexDirection:"column", marginX:1}}>
            <SuccessAlert
                vertical="top"
                horizontal="right"
                onClose={()=>setSuccessShow({...successShow, state:false})}
                open={successShow.state}
                message={successShow.message}
            />
            <SuccessAlert  vertical="top"
                               horizontal="right"
                               onClose={()=>setSuccessToast({...successToast, state:false})}
                               open={successToast.state}
                               message={successToast.message} />

                <Box style={{ width:'100%' }}>
                    <Box style={{ display:'flex' }}>
                        <Box>
                            <CustomInputLabel required={true} label={"Category Name"}/>
                            <CustomTextField value={formData.categoryName} onChange={handleInputChange} name={"categoryName"} placeholder={"Category Name"} sm />
                        </Box>
                        <Box style={{ marginLeft:'16px' }}>
                            <CustomInputLabel required={true} label={"Category Type"}/>
                            <CustomSelectField value={formData.categoryType} onChange={handleInputChange} name={"categoryType"} placeholder={"Category Type"} options={categoryTypeOptions} medium sm />
                        </Box>
                    </Box>

                    <CustomInputLabel required={true} label={"Category Description"}/>
                    <TextField
                    name={"categoryDescription"}
                    value={formData.categoryDescription}
                    onChange={handleInputChange}
                    placeholder={"Category Description"}
                    variant="outlined"
                    margin="dense"
                    sx={{marginBottom:2}}
                    multiline
                    minRows={3}
                    InputProps={{
                        sx: {
                            width: '548px',
                            fontSize:"0.875rem",
                            "& fieldset": {
                                borderColor: "#bec5d1"
                            }
                        }
                    }}
                    />
                </Box>

            <Box component="div" sx={{width:"100%", display:"flex", justifyContent:"end"}}>
                <Box component="div">
                    <Button sx={{
                            ...buttonStyles,
                            marginRight:1,
                            color:"red",
                            '&:hover': {
                                backgroundColor:"transparent",
                                color: 'red'
                            }
                        }}
                        onClick={props.next}
                        >
                            Skip
                        </Button>
    
                        <Button sx={{
                            ...buttonStyles,
                            border:"solid 1px rgba(0, 37, 67, 0.4)",
                            marginRight:1,
                            color:"rgba(0, 37, 67, 0.4)",
                            '&:hover': {
                                backgroundColor:"transparent",
                            }
                        }}
                        id="cancel-category"
                        onClick={()=> {
                            //add if for incase some categories has been added
                            console.log("ndjw")
                            if(!categoriesToAdd || categoriesToAdd?.length < 1){
                                props.setView("initial")
                            }else{
                                props.setView("done")
                            }
                        }}
                        >
                            Cancel
                        </Button>
    
                        <Button sx={{
                            ...buttonStyles,
                            border:"none",
                            borderRadius: '4px',
                            backgroundColor:'rgba(3, 37, 65, 0.4)',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor:'rgba(3, 37, 65, 0.4)',
                                color: '#fff'
                            }
                        }}
                        onClick={saveHandler}
                                // disabled={!formData.categoryName.trim()}
                                id="preview-category"
                        >
                            Update
                        </Button>
                    </Box>

            </Box>
        </Box>
    )
}

export default EditServiceCategoryForm;
