import React, { useState, useEffect } from "react";
import { Grid, Box, Button, Tab, styled, Stack,CircularProgress } from "@mui/material";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import { DataGrid } from "@mui/x-data-grid";
import emptyState from "../../../common/images/emptystate.svg";
import { id } from "date-fns/locale";
import { time } from "ag-charts-community";

const baseUrl = process.env.REACT_APP_BASE_URL;

// filter cashier
// https://zed.swerri.io/api/fetchBillsByCashier/?status=unpaid&businessId=9000001&cashier=Jod%20Kem&fromDate=2023-02-18&toDate=2023-02-18
// POST https://zed.swerri.io/fetchTransactions/dateRange

// {"dateFrom":"2023-02-01","dateTo":"2023-02-19"}
// Filter transaction Report

const Reports = (props) => {



  const filterStartDate = props.filterStartDate;
  const filterEndDate = props.filterEndDate;
  const paymentType = props.paymentType
  const cashier = props.cashier;


  const [reports, setReports] = useState([]);
  const [loadingReports, setLoadingReports] = useState(true)
  const getReports = async () => {

    try{
      const getReportsResp = await fetch(baseUrl + "/viewAllTransactions", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
  
      await getReportsResp.json().then((data) => {
   
        const reportsData = data.data.map((trans) => {
          const newTime = new Date(trans.createdAt);
          const date = newTime.toLocaleDateString();
          const time = newTime.toLocaleTimeString();
  
          return {
            id: trans._id,
            createdAt: `${date} ${time}`,
            transactionType: trans.transactionType,
            transamount: trans.transamount,
            cashier:trans.cashier
          };
        });
        setReports(reportsData);
        setLoadingReports(false)
      })
    }catch(error){
      console.log(error.message)
      setLoadingReports(false)
    }

  };

  useEffect(() => {
    getReports();
  }, []);

  // REPORTS COLUMN
  const reportCoulmn = [
    { field: "createdAt", headerName: "Date and Time", flex: 1 },
    { field: "transactionType", headerName: "Payment Mode", flex: 1 },
    { field: "transamount", headerName: "Amount", flex: 1 },
  ];

  // REPORTS ROW
  const reportsRow = reports
  .filter((report) => {
    return cashier === "" ? report : cashier === "Select Cashier" ? report : report.cashier.toLowerCase().includes(cashier.toLowerCase())
  })
  .filter((report) => {
    return paymentType === "" ? report: paymentType === "Payment Type" ? report : report.transactionType.toLocaleLowerCase().includes(paymentType.toLowerCase())
  })
  .map((report) => ({
    id: report.id,
    createdAt: report.createdAt,
    transactionType: report.transactionType,
    transamount: report.transamount,
  }));

  // FILTER BILLS ACCORDING TO DATE;
  const filterReportsByDate = async () => {
    if(filterStartDate && filterEndDate)
    try {
      const filteredReportsResp = await fetch(baseUrl + `/fetchTransactions/dateRange`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({
          dateFrom: filterStartDate,
          dateTo: filterEndDate,
        }),
      });

      await filteredReportsResp.json().then((data) => {
        
        const reportsData = data.data.map((trans) => {
          const newTime = new Date(trans.createdAt);
          const date = newTime.toLocaleDateString();
          const time = newTime.toLocaleTimeString();
  
          return {
            id: trans._id,
            createdAt: `${date} ${time}`,
            transactionType: trans.transactionType,
            transamount: trans.transamount,
            cashier:trans.cashier
          };
        });
        setReports(reportsData);
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    let isFilteredDataBack = false;
    // if(filterStartDate && filterEndDate){
    if (!isFilteredDataBack) {
      filterReportsByDate();
      // }
    }

    return () => {
      isFilteredDataBack = true;
    };
  }, [filterStartDate, filterEndDate]);




  return (
    <div style={{width:"100%"}}>

      <DataGrid
        sx={{
          height: "400px",
          width: "auto",
          fontFamily: "Poppins",
          fontSize: "10px",
          color: "#272d3d",
          boxShadow: 0,
          border: 0,
        }}
        columns={reportCoulmn}
        style={{
          height: "400px",
          width: "100%",
          // overflow: "visible",
        }}
        rows={reportsRow}
        pageSize={5}
        rowsPerPageOptions={[5]}
      
        components={{
          NoRowsOverlay: () => (            
            <Box sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", height:"100%"}}>
           {reportsRow.length === 0 ? null :   <CircularProgress color="inherit" />}

          </Box>
          ),
        }}
      />
    </div>
  );
};

export default Reports;
