import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Link} from "react-router-dom";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {List, ListItemIcon} from "@mui/material";
import DashboardIcon from '@mui/icons-material/Dashboard';


const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(2),
	},
}));

function SendIcon() {
	return null;
}

export default function Dashboard () {
	const classes = useStyles();

	return (
		<div>
			<Link style={{textDecoration: 'none', color : '#fff'}} to="/dashboard">
				<ListItem button className={classes.nested} disablepadding="true">
					<div style={{marginRight : '5%'}}>
						<DashboardIcon />
					</div>
					<div>
						<ListItemText primary="Dashboard" />
					</div>
				</ListItem>
			</Link>			
		</div>
	)
}