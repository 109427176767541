import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Dash from "../../../common/dash";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import user from "../../../common/images/user-icon.png";
import bell from "../../../common/images/bell-icon.png";
import logout from "../../../common/images/logout-icon.png";
import logoutuser from "../../logout/logout";
import {Button, IconButton, Table, TableCell, TableHead, TableRow} from "@mui/material";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import mpesaIcon from "../../../common/images/mpesa-icn.svg";
import cardIcon from "../../../common/images/card-icn.svg";
import creditIcon from "../../../common/images/creditbal-icn.svg";
import {ReceivedPayment} from "../../Modals/Invoice/receivedPayment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {PaymentMethods} from "../../Modals/Invoice/paymentMethods";
import SyncIcon from '@mui/icons-material/Sync';
import "./tenantInvoice.css"
const moment = require('moment');

const baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
	address_text_common: {
		fontSize: "13px",
		fontWeight: "300",
	},
	table_titles: {
		fontSize: "14px",
		fontWeight: "700",
	},
	table_body: {
		fontSize: "13px",
		fontWeight: "300",
		padding: theme.spacing(0.25),
	},
	footer_text: {
		fontSize: "10px",
		fontWeight: "300",
		padding: theme.spacing(0.25),
	},
	totals: {
		fontSize: "14px",
		padding: theme.spacing(0.25),
	},
	p1: {
		padding: theme.spacing(0.25),
	},
	p2: {
		padding: theme.spacing(0.5),
	},
	p3: {
		padding: theme.spacing(1),
	},
	mt4: {
		marginTop: theme.spacing(4),
	},
	m1: {
		margin: theme.spacing(1),
	},
	textLeft: {
		textAlign: "left",
	},
	textCenter: {
		textAlign: "center",
	},
	textRight: {
		textAlign: "right",
	},
}));

const TenantPayInvoice = (props) =>{
	const navigate = useNavigate();
	const { invoiceNumber } = useParams();
	const classes = useStyles();
	const [total, setTotal] = useState(0)
	const [businessInfo, setBusinessInfo] = useState()
	const [invoiceData, setInvoiceData] = useState()
	const [receivePaymentOpen, setReceivePaymentOpen] = useState(false);
	const [paymentMethodsOpen, setPaymentMethodsOpen] = useState(false);
	const [currentEmail, setCurrentEmail] = useState()
	const [errorFetching, setErrorFetching] = useState(false);
	const [invoicePayments, setInvoicePayments] = useState();
	const [invoiceBalance, setInvoiceBalance] = useState();
	const [houseNumber, setHouseNumber] = useState('')
	const [houseType, setHouseType] = useState('')
	
	const downloadPDF = () => {
		const input = document.getElementById('downloadable');
		html2canvas(input)
			.then((canvas) => {
				const imgData = canvas.toDataURL('image/png', 1.0);
				const pdf = new jsPDF("p","px", [800, 900]);
				pdf.addImage(imgData, 'JPEG', 0, 0,  800, 900);
				pdf.save(`invoice.pdf`);
			})
	}
	const getInvoiceDetails = async () => {
		try {
			const invoiceDetailsResponse = await fetch(baseUrl+`/api/get_invoice_by_invoice_number?invoiceNumber=${invoiceNumber}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
				}
			);
			
			if (invoiceDetailsResponse.status === 200) {
				const invoiceDetails = await invoiceDetailsResponse.json();
				console.log("invoice data ", invoiceDetails.data)
				setInvoiceData(invoiceDetails.data);
			}else{
				setErrorFetching(true)
			}
		} catch (error) {
			console.error("error fetching invoice details",error.message);
		}
	};
	
	const numberFormat = (value) => new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "KES",
		}).format(value);
	
	const formatDate = (date) =>{
		return moment(date).format("dddd, MMMM Do, YYYY");
	}
	
	const formatPaymentDate = (unFormatted) =>{
		const date = new Date(unFormatted);
		const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
		const formattedDate = date.toLocaleString('en-US', options);
		const dateSuffix = function () {
			const date = new Date();
			const dateNumber = date.getDate();
			switch (dateNumber) {
				case 1:
				case 21:
				case 31:
					return 'st';
				case 2:
				case 22:
					return 'nd';
				case 3:
				case 23:
					return 'rd';
				default:
					return 'th';
			}
		};
		return `${date.getDate()}${dateSuffix()} ${formattedDate.slice(0, 3)} ${formattedDate.slice(7)}`
	}
	
	const getBusinessDetails = async () => {
		try {
			const businessDetailsResponse = await fetch(baseUrl+"/api/get_business_info",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
				}
			);
			
			
			if (businessDetailsResponse.status === 200) {
				const businessDetailsData = await businessDetailsResponse.json();
				console.log("business data ", businessDetailsData.data)
				setBusinessInfo(businessDetailsData.data);
			}else{
				setErrorFetching(true)
			}
		} catch (error) {
			console.error("error fetching customer details",error.message);
		}
	};
	
	const calculateTotal = () => {
		if(invoiceData){
			let calculatedTotal = 0;
			invoiceData?.items?.forEach((product) => {
				calculatedTotal += product.productPrice * product.quantity;
			});
			setTotal(calculatedTotal);
		}
	}
	
	const back = () =>{
		navigate("/invoices")
	}
	
	const [isSpinning, setIsSpinning] = useState(false);
	
	const refresh = () => {
		setIsSpinning(true);
		getInvoiceDetails().then(()=> {
			console.log("done fetching invoice data")
			setIsSpinning(false);
		})
	};
	
	const getCurrentEmail = () =>{
		setCurrentEmail(localStorage.getItem('email'));
	}
	
	const fetchBusiInvoiceData = async () => {
		const businessInvoiceDetailsResponse = await fetch(baseUrl + `/api/get_invoice_and_business_data/${invoiceNumber}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
			}
		);
		if (businessInvoiceDetailsResponse.status === 200) {
			const businessDetailsData = await businessInvoiceDetailsResponse.json();
			console.log("business Invoice data ", businessDetailsData.data)
			setBusinessInfo(businessDetailsData.data);
			setInvoiceData(businessDetailsData.data)
		}else{
			setErrorFetching(true)
		}
	}
	
	const getInvoicePayments = async () =>{
		const invoicePaymentDetailsResponse = await fetch(baseUrl + `/api/get_invoice_payments/${invoiceNumber}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					'X-Authorization' : localStorage.getItem('X-Authorization')
				},
			}
		);
		if (invoicePaymentDetailsResponse.status === 200) {
			const paymentDetailsData = await invoicePaymentDetailsResponse.json();
			console.log("Invoice payment data ", paymentDetailsData.data)
			let {invoicePayments,invoiceBalance} = paymentDetailsData.data
			console.log("ip",invoicePayments)
			setInvoicePayments(invoicePayments);
			setInvoiceBalance(invoiceBalance);
		}
	}
	
	useEffect(() => {
		getBusinessDetails().then(()=>console.log("done getting business info"))
		getInvoiceDetails().then(()=>console.log("done fetching invoice data"))
		getCurrentEmail()
		getInvoicePayments().then(()=>console.log("payments fetched"))
	}, []);
	
	useEffect(() => {
		if(errorFetching){
			console.log("we had an error fetching")
			fetchBusiInvoiceData().then(()=>console.log("Fallback function used"))
		}
	}, [errorFetching]);
	
	useEffect(() => {
		calculateTotal();
	}, [invoiceData]);
	
	return(
		<>
			<div style={{ width: "80%" , marginLeft:"10%", fontFamily : 'Poppins'}}>
				<div>
					<div>
						<div style={{display:"flex", justifyContent:"space-between"}}>
							<div style={{paddingTop: 15, display:"flex"}}>
                                <span>
                                     <IconButton aria-label="back" onClick={()=>back()}>
                                        <KeyboardBackspaceOutlinedIcon sx={{color:"#dc3545", fontSize:"25px"}} />
                                    </IconButton>
                                </span>
								<span style={{"fontFamily":"Poppins","fontSize":"25px","fontWeight":"600","textAlign":"left","color":"#dc3545"}}>Preview Invoice</span>
							</div>
							
							<div style={{padding:10, display:"flex",}}>
								{/* {invoiceData?.invoiceStatus === "Unpaid" && invoiceData?.sentTo !== currentEmail &&(
									<Button style={{"width":"180px","height":"45px","borderRadius":"5px","backgroundColor":"transparent", color:"#032541", border:"solid 1px #032541", marginRight:2}} onClick={()=>setReceivePaymentOpen(true)}>
										Request Payment
									</Button>)} */}
								
								{(invoiceData?.invoiceStatus === "Unpaid" || invoiceData?.invoiceStatus === "Partially Paid") && invoiceData?.sentTo === currentEmail  &&(
									<div>
										<IconButton aria-label="refresh icon" style={{color:"#032541", height:"45px", width:"45px"}} onClick={()=>refresh()}>
											<SyncIcon className={`${isSpinning ? "sync-icon spinning" : ""}`} />
										</IconButton>
										<Button style={{"width":"180px","height":"45px","borderRadius":"5px","backgroundColor":"#032541", color:"white"}} onClick={() => {navigate('/tenantPay/'+invoiceNumber)}}>
											Pay
										</Button>
									</div>)}
{/* 								
								{invoiceData?.sentTo !== currentEmail &&(
									<Button style={{"width":"180px","height":"45px","borderRadius":"5px","backgroundColor":"#032541", color:"white"}}>
										Resend Invoice
									</Button>
								)} */}
								
								<span style={{backgroundColor: "rgba(3, 37, 65, 0.05)", width:"45px", height:"45px", display:"flex", justifyContent:"center", marginLeft:5, borderRadius:5}}>
                                    <IconButton aria-label="download" onClick={downloadPDF}>
                                        <FileDownloadOutlinedIcon sx={{color:"#032541", fontSize:"25px"}} />
                                    </IconButton>
                                </span>
							
							</div>
						</div>
						
						<div style={{display: "flex", flexDirection: "column", overflow: "visible", height: "auto"}} id="downloadable">
							<div className={classes.p3} style={{backgroundColor: `${invoiceData?.invoiceStatus === "Unpaid" ? "rgba(220,53,69,0.07)" : invoiceData?.invoiceStatus === "Paid" ? "rgba(23,174,123, 0.07)" : "rgba(247,137,41, 0.05)"}`, height: "150px", display: "flex", justifyContent: "space-between",}}>
								
								<div className={classes.p1} style={{display: "flex", flexDirection: "column",}}>
									<span style={{color: "black", fontSize: "40px", fontWeight: "700",}}>INVOICE</span>
									<span style={{color: "black", fontSize: "14px", fontWeight: "700",}}>Date:</span>
									<span style={{ fontSize: "14px" }}>{formatDate(invoiceData?.createdAt)}</span>
								</div>
								
								<div style={{display: "flex", flexDirection: "column",}}>
									<span style={{color: `${invoiceData?.invoiceStatus === 'Paid' ? '#17ae7b' : invoiceData?.invoiceStatus === "Unpaid" ? "#dc3545" : '#e07c28'}`, fontSize: "40px", fontWeight: "700",}}>
                                        {invoiceData?.invoiceStatus}
                                    </span>
									<span style={{color: "black", fontSize: "14px", fontWeight: "700",}}>Invoice No:</span>
									<span style={{ fontSize: "14px" }}>{invoiceData?.invoiceNumber}</span>
								</div>
								
							</div>
							
							<div className={classes.p3} style={{display: "flex", justifyContent: "space-between", flexDirection: "row",}}>
								<div style={{display: "flex", flexDirection: "column",}}>
									<span style={{fontSize: "18px",}}>From:</span>
									<span className={classes.address_text_common} style={{ fontWeight: "700" }}>{businessInfo?.businessName}</span>
									<span className={classes.address_text_common}>{businessInfo?.businessOwnerAddress}, {businessInfo?.country}</span>
									<span className={classes.address_text_common}>{businessInfo?.businessOwnerEmail}</span>
									<span className={classes.address_text_common}>{businessInfo?.businessOwnerPhone}</span>
								</div>
								<div className={classes.mt4} style={{display: "flex", flexDirection: "column", textAlign: "right",}}>
									<span className={classes.address_text_common} style={{ fontWeight: "700" }}>Billed to:</span>
									<span className={classes.address_text_common}>{invoiceData?.customerName}</span>
									<span className={classes.address_text_common}>{invoiceData?.sentTo}</span>
									<span className={classes.address_text_common}>{invoiceData?.customerPhoneNumber}</span>
								</div>
							</div>
							
							<div className={classes.m1} style={{ borderTop: "dotted 5px", color : "#b2b4bb" }}></div>
							
							<div>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell className={classes.textLeft} style={{fontWeight : "bold"}}>
												Description
											</TableCell>
											<TableCell className={classes.textCenter} style={{fontWeight : "bold"}}>
												Price (KES)
											</TableCell>
											<TableCell className={classes.textCenter} style={{fontWeight : "bold"}}>Qty</TableCell>
											<TableCell className={classes.textRight} style={{fontWeight : "bold", textAlign : 'right'}}>
												Total (KES)
											</TableCell>
										</TableRow>
									</TableHead>
									{invoiceData?.items?.map((product, index) => (
										<TableRow key={index} textAlign={'right'}>
											<TableCell className={classes.textLeft}>
												<span className={classes.table_body}>{product.productName}</span>
											</TableCell>
											<TableCell className={classes.textCenter}>
												<span className={classes.table_body}>{numberFormat(product.productPrice)}</span>
											</TableCell>
											<TableCell className={classes.textCenter}>
												<span className={classes.table_body}>{product.quantity}</span>
											</TableCell>
											<TableCell style={{textAlign : 'right'}} className={classes.textRight}>
												<span className={classes.table_body}>{numberFormat(product.productPrice * product.quantity)}</span>
											</TableCell>
										</TableRow>
									))}
								</Table>
							</div>
							
							<div className={classes.p3} style={{display: "flex", justifyContent: "end",}}>
								<div className={classes.p1} style={{backgroundColor: "#f7f7f7", width: "288px", height: "130px", display: "flex", flexDirection: "column", justifyContent: "center",}}>
									<div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
										<span>Subtotal</span>
										<span>{numberFormat(total)} </span>
									</div>
									<div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
										<span>Discount</span>
										<span>KES 0.00</span>
									</div>
									<div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
										<span style={{ color: `${invoiceData?.invoiceStatus === "Unpaid" ? "#dc3545": "#17ae7b"}`, fontWeight: "bold" }}>Total</span>
										<span style={{fontSize: "16px", color: `${invoiceData?.invoiceStatus === "Unpaid" ? "#dc3545": "#17ae7b"}`, fontWeight: "bold",}}>{numberFormat(total)}</span>
									</div>
								</div>
							</div>
							
							<br/>
							<div style={{ borderTop: "dotted 5px", color : "#b2b4bb"}}></div>
							<br/>
							
							{invoiceData?.invoiceStatus === "Partially Paid" || invoiceData?.invoiceStatus === "Paid" ?(
								<>
									<div>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell className={classes.textLeft} style={{fontWeight:"700"}}>
														Mode of Payment
													</TableCell>
													<TableCell className={classes.textCenter} style={{fontWeight:"700"}}>
														Date
													</TableCell>
													<TableCell className={classes.textRight} style={{fontWeight:"700"}}>
														Amount
													</TableCell>
												</TableRow>
											</TableHead>
											{invoicePayments?.map((payment, index) => (
												<TableRow key={index}>
													<TableCell className={classes.textLeft}>
														<span  className={classes.table_body}>{payment.modeOfPayment}: {payment.ReferenceNumber}</span>
													</TableCell>
													<TableCell className={classes.textCenter}>
														<span  className={classes.table_body}>{formatPaymentDate(payment.date)}</span>
													</TableCell>
													<TableCell className={classes.textRight}>
														<span  className={classes.table_body}>{numberFormat(payment.amount)}</span>
													</TableCell>
												</TableRow>
											))}
										</Table>
									</div>
									<br/>
									<div style={{display:"flex", justifyContent:"end"}}>
										<div style={{color:"#dc3545",fontWeight:"bold"}}>
											<span>Balance: {numberFormat(invoiceBalance)}</span>
										</div>
									</div>
									<br/>
									<div style={{ borderBottom: "dotted 5px", color : "#b2b4bb" }}></div>
									<br/>
								</>
							):(<></>)}
							
							<div className={classes.p3} style={{ display: "flex", flexDirection: "column" }}>
								<div className={classes.p1}>
									<span style={{color: "#032541", fontWeight: "700", fontSize: "16px",}}>Accepted Payment Methods</span>
								</div>
								
								<div style={{ display: "flex" }}>
									<div style={{display:"flex", flexDirection:"column"}}>
										<img className={classes.p2} src={mpesaIcon} alt="M-pesa"/>
										<div style={{display:"flex", justifyContent:"center", fontSize: "12px"}}>
											<span>M-Pesa</span>
										</div>
									</div>
									<div style={{display:"flex", flexDirection:"column",}}>
										<img className={classes.p2} src={cardIcon} alt="Visa"/>
										<div style={{display:"flex", justifyContent:"center", fontSize: "12px"}}>
											<span>Card</span>
										</div>
									</div>
									<div style={{display:"flex", flexDirection:"column",}}>
										<img className={classes.p2} src={creditIcon} alt="Credit"/>
										<div  style={{display:"flex", justifyContent:"center", fontSize: "12px"}}>
											<span>Credit Balance</span>
										</div>
									</div>
								</div>
							</div>
							
							<br/>
							<div style={{ borderTop: "dotted 5px", color : "#b2b4bb"}}></div>
							<br/>
							<div className={classes.p2} style={{ display: "flex", justifyContent: "center" }}>
								<div className={classes.textCenter} style={{ fontSize: "10px", fontWeight: "300" }}>
									This is a system-generated invoice is created without any alteration whatsoever.
									<br />
									Thank you for your business.
								</div>
							</div>
							<div className={classes.p2} style={{backgroundColor: "#f7f7f7", display: "flex", justifyContent: "center",}}>
								<span className={classes.footer_text}>Powered by <span style={{fontWeight:"700"}}>ZED Payments Limited</span></span>
								<span className={classes.footer_text}>.</span><span className={classes.footer_text}>info@zed.business</span>
								<span className={classes.footer_text}>.</span>
								<span className={classes.footer_text}>v1.0.2</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			{/*Received Payment Modal*/}
			<ReceivedPayment paymentOpen={receivePaymentOpen} onClose={() => setReceivePaymentOpen(false)} />
			
			{/*    Payment Methods*/}
			<PaymentMethods paymentMethodsOpen={paymentMethodsOpen} onClose={() => setPaymentMethodsOpen(false)} invoiceBalance={invoiceData?.invoiceBalance} invoiceNumber={invoiceNumber}
			/>
		</>
	)
}

export default TenantPayInvoice;
