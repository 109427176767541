import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Link} from "react-router-dom";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import creditNote from '../../common/images/credit.svg'
import { TextSnippet } from "@mui/icons-material"


const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(2),
	},
}));

export default function CreditNote () {
	const classes = useStyles();

	return (
		<div>
			<ListItem button className={classes.nested}>
				<div style={{marginRight : '5%'}}>
					<TextSnippet/>
				</div>
				<Link style={{textDecoration: 'none', color : '#fff'}} to="/creditnote">
					<ListItemText primary="Credit Note" />
				</Link>
			</ListItem>
		</div>
	)
}
