import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dash from "../../../common/dash";
import DeleteIcon from "@mui/icons-material/Delete";
import { Accordion, AccordionDetails, AccordionSummary, Button, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography, Tab, Box, styled, Breadcrumbs } from "@mui/material";
import noItems from "../../../common/images/no_selected_items.svg";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import user from "../../../common/images/user-icon.png";
import bell from "../../../common/images/bell-icon.png";
import logout from "../../../common/images/logout-icon.png";
import logoutuser from "../../logout/logout";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { ArrowBackIos } from "@material-ui/icons";
import { NavigateNext } from "@mui/icons-material";
import { customerstyles } from "./styles";
import CategoryTable from "./categoryTable";
import InvoicePreview from "./invoicePreview";
const baseUrl = process.env.REACT_APP_BASE_URL;
const getCustomerDetailsUrl = baseUrl + "/api/get_customer_by_number";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  leftColumn: {
    // backgroundColor: theme.palette.secondary.main,
    height: "100vh",
    // color: "black",
  },
  rightColumn: {
    // backgroundColor: theme.palette.primary.main,
    height: "100vh",
  },
  container: {
    maxHeight: 440,
  },
}));

// Custom Tab Styling
const AntTabs = styled(TabList)({
  borderBottom: "3px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#dc3545",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  fontSize: "13px",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "2.75",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#6e7074",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    color: "#032541",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "#dc3545",
    fontWeight: 600,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "blue",
  },
}));

const InvoiceItems = (props) => {
 

  const [itemsToAdd, setAddItems] = useState([])
  const [customerDetails, setCustomerDetails] = useState();
  const [customerInfo, setCustomerInfo] = useState({
    customerName: "",
    dayCreated: "",
    customerType: "",
    adress: "",
    paymentType: "",
    currentBalance: "",
    totalCredited: "",
    totalexpenses: "",
    email: "",
  });

  const getCustomerData = () => {
    customerDetails?.forEach((customer) => {
      const date = new Date(customer?.createdAt).toDateString();
      setCustomerInfo({
        ...customerInfo,
        customerName: customer?.firstName + " " + customer?.lastName,
        dayCreated: date,
        customerType: customer?.customerType,
        adress: customer?.customerAddress,
        paymentType: customer?.paymentType,
        currentBalance: !customer?.totalBalance ? 0 : customer?.paymentType === "Normal" ? "N/A" : customer?.totalBalance,
        totalCredited: customer?.paymentType === "Normal" ? "N/A" : customer?.totalCredits,
        totalexpenses: customer?.paymentType === "Normal" ? "N/A" : customer?.totalExpense,
        email: customer?.email,
      });
    });
  };

  useEffect(() => {
    getCustomerData();
  }, [customerDetails]);

 const customerId = localStorage.getItem("customerId");

  const getCustomerDetails = async () => {
    try {
      const customerDetailsResponse = await fetch(getCustomerDetailsUrl + `?customerId=${props.newCustomerId ? props.newCustomerId :  props.customerId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      if (customerDetailsResponse.status === 200) {
        const customerDetailsData = await customerDetailsResponse.json();
        setCustomerDetails(customerDetailsData.data);
      }
    } catch (error) {
      console.error("error fetching customer details", error.message);
    }
  };



  useEffect(() => {
    getCustomerDetails(props.customerId);
  }, [props.customerId]);

  // invoice array products;
  const [invoiceArrayProduct, setInvoiceArrayProducts] = useState([]);

  const [invoiceItemsShow, setInvoiceItemsShow] = useState(true);
  const [previewInvoiceItemsShow, setPreviewInvoiceItemsShow] = useState(false);


  useEffect(() => {

    if(previewInvoiceItemsShow){
      setInvoiceItemsShow(false);
    }

    if(!previewInvoiceItemsShow){
      setInvoiceItemsShow(true)
    }

  },[invoiceItemsShow, previewInvoiceItemsShow])




  return (
    <>
      <div>

        {
          invoiceItemsShow && <>
                  <Grid container direction="column" width={"100%"}>
          <Grid item style={{ margin: "0px 0px 10px 0px" }}>
            <ArrowBackIos onClick={() => props.setCreateInvoiceShow(true)} />
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: "600",
                color: "#032541",
              }}
            >
              Add Product/Service
            </span>
          </Grid>

          <Grid item style={{ margin: "0px 0px 10px 0px" }}>
            <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
              <span>Dashboard</span>
              <span>Customers</span>
              <span>Create Invoice</span>
              <span style={{ color: "red" }}>Add Product/Service </span>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Grid container direction={"row"} alignItems={"center"} width={"100%"} justifyContent={"space-between"} style={{ backgroundColor: "#f5f5f5", padding: "10px 10px 0px 10px", margin: "0px 0px 40px 0px" }}>
          <Grid item>
            <p style={customerstyles.customerDetailsPara}>Customer Type</p>
            <p style={customerstyles.customerDetailsParaNormal}>{customerInfo.customerType}</p>
          </Grid>

          <Grid item>
            <p style={customerstyles.customerDetailsPara}>Email</p>
            <p style={customerstyles.customerDetailsParaNormal}>{customerInfo.email}</p>
          </Grid>

          <Grid item>
            <p style={customerstyles.customerDetailsPara}>Physical Adress</p>
            <p style={customerstyles.customerDetailsParaNormal}>{customerInfo.adress}</p>
          </Grid>
          <Grid item>
            <p style={customerstyles.customerDetailsPara}>Payment Type</p>
            <p style={customerstyles.customerDetailsParaNormal}>{customerInfo.paymentType}</p>
          </Grid>

          <Grid item>
            <p style={customerstyles.customerDetailsParaBalance}>Current Balance</p>
            <p style={customerstyles.customerDetailsParaNormalBalance}>{customerInfo.currentBalance}</p>
          </Grid>

          <Grid item>
            <p style={customerstyles.customerDetailsPara}>Total Credited</p>
            <p style={customerstyles.customerDetailsParaNormal}>{customerInfo.totalCredited}</p>
          </Grid>

          <Grid item>
            <p style={customerstyles.customerDetailsParaExpense}>Total Spend</p>

            <p style={customerstyles.customerDetailsParaNormalExpense}>{customerInfo.totalexpenses}</p>
          </Grid>
        </Grid>
        

         <>
         <CategoryTable setInvoiceArrayProducts={setInvoiceArrayProducts} setPreviewInvoiceItemsShow={setPreviewInvoiceItemsShow} itemsToAdd={itemsToAdd} />
          </>   </>
        }


          {
            previewInvoiceItemsShow && <> <InvoicePreview setInvoiceItemsShow={setInvoiceItemsShow} setPreviewInvoiceItemsShow={setPreviewInvoiceItemsShow} invoiceArrayProduct={invoiceArrayProduct} customerId={props.customerId} invoiceType={props.invoiceType} frequency={props.frequency} customerInfo={customerInfo} newCustomerId={props?.newCustomerId} setAddItems={setAddItems} /> </>
          }
       
      </div>
    </>
  );
};

export default InvoiceItems;
