import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Sidebar from "../../common/sideBar";
import { NavBar } from "../../common/NavBar";
import { UserNavbar } from "../../common/userNavbar";
import UserSidebar from "../../common/userSidebar";
import SchoolSetup from "../School/SchoolSetup";
import RentalMultiSetup from "../Rental/rentalBusinessSetup";

const PrivateRoutes = () => {
  const { isLoggedIn } = useSelector((store) => store.user);
  let  businessCat = localStorage.getItem("businessCategory");

  // let auth = {'token':true}

  const group = localStorage.getItem("group");


  return (
    <>
      {isLoggedIn && (
        <>
          {" "}
          {group === "User" ||
          (group !== "User" && businessCat === "School") ||
          (group !== "User" && businessCat === "Rental") ? (
            <>
              {" "}
              <UserSidebar>
                {" "}
                <UserNavbar /> <Outlet />{" "}
              </UserSidebar>
            </>
          ) :
            businessCat !== "Rental" &&
            businessCat !== "School" ? (
            <Sidebar>
              {" "}
              <NavBar /> <Outlet />{" "}
            </Sidebar>
          ) : (
            // : isLoggedIn && group !== 'User' && group !== "Cashier" && businessCat === 'School' && (setup && setup != "PARENT") ?
            //       (<> <UserSidebar> <UserNavbar/> <SchoolSetup/> </UserSidebar></>)
            //       : isLoggedIn && group !== 'User' && group !== "Cashier" && businessCat === 'Rental' && (setup && setup != "TENANT") ?
            //           (<> <UserSidebar> <UserNavbar/> <RentalMultiSetup/> </UserSidebar></>)   :
            <>
              {" "}
              <NavBar /> <Outlet />{" "}
            </>
          )}{" "}
        </>
      )}

      {}
      {}
      {!isLoggedIn && <Navigate to="/" />}
    </>
  );
};

export default PrivateRoutes;

// import { Navigate, Outlet } from "react-router-dom";
// import { useSelector } from "react-redux";
// import Sidebar from "../../common/sideBar";
// import { NavBar } from "../../common/NavBar";

// const PrivateRoutes = () => {
//   const { isLoggedIn } = useSelector((store) => store.user);
//   const group = localStorage.getItem("group");
//   // let auth = {'token':true}

//
// };

// export default PrivateRoutes;
