import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ExpandLess } from "@material-ui/icons";
import { ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import List from "@material-ui/core/List";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InventoryIcon from '../../components/School/Images/configs-icon.svg';
import reportIcon from '../../components/School/Images/report.svg';

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(2.7),
    },
}));

export default function SchoolConfiguration(props) {
    const classes = useStyles();
    

    const userGroup = localStorage.getItem('group')
    const businessCat = localStorage.getItem('businessCategory')
    // useEffect(()=>{
    // 	console.log("category in inventory", props.businessCat)
    // },[props.businessCat])
    return (
        <div>

            {/*Users*/}
            {businessCat === 'School' ?
                <>

                    <div>
                        <ListItem button className={classes.nested}>
                            <div style={{ marginRight: '5%' }}>
                                <img src={InventoryIcon} />
                            </div>
                            <Link style={{ textDecoration: 'none', color: '#fff' }} to="/school/configuration">
                                <ListItemText primary="Configuration" />
                            </Link>
                        </ListItem>
                    </div>
                </> : null}
        </div>
    )
}
