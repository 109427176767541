import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Input, TextField, Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import loginLogo from "../../images/zedLogo.png";
import PhoneInput from "react-phone-input-2";
import "./phoneBootstrap.css";
import Button from "@mui/material/Button";
import { logInUser, logoutUser, changeToken } from "../../features/userSlice";
import { handleBusinessCategory } from "../../features/businessCategorySlice";
import { handleBranchDetails } from "../../features/branchSlice";
import { handleBusinessBranches } from "../../features/businessBranchesSlice";
import loginImage from "../../common/images/login_image.svg";
import googleIcon from "../../common/images/google-icn.svg";
import facebookIcon from "../../common/images/facebook-icn.svg";
import emailIcon from "../../common/images/email-icn.svg";
import TwitterIcon from "../../common/images/twitter-icon.svg";
import mobileIcon from "../../common/images/mobileicon.svg";
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, FacebookAuthProvider, GoogleAuthProvider, TwitterAuthProvider, getRedirectResult } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import Spinner from 'react-bootstrap/Spinner';
import Modal from "@mui/material/Modal";
import deactivateIcon from "../../common/images/deactivate.svg";



// Initialize Firebase
const firebaseConfig = {

    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);



const baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
    imgRes: {
        [theme.breakpoints.up("md")]: {},
        [theme.breakpoints.down("sm")]: {
            display: "none",
            alignItems: "center",
            width: "100%",
            objectFit: "contain",
            height: "100%",
        },
    },
    textf: {
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    gridLayout: {
        [theme.breakpoints.up("md")]: {
            paddingRight: "4rem",
        },
    },
}));
const style = {
    borderColor: "transparent",
    borderRadius: "36px",
    backgroundColor: "#fff",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    height: "350px",
    p: 4,
};
const SignUpOptions = () => {
    const [emailNotFound, setEmailNotFound] = useState(false)


    const dispatch = useDispatch();

    const [errorNotificationOpen, setErrorNotificationOpen] = React.useState(false);

    const handleErrorNotificationClick = () => {
        setErrorNotificationOpen(true);
    };

    const handleErrorNotificationClose = () => {
        setErrorNotificationOpen(false);
    };

    const [successNotificationOpen, setSuccessNotificationOpen] = React.useState(false);
    const handleSuccessNotificationClick = () => {
        setSuccessNotificationOpen(true);
    };
    // const [showspinner, setShowSpinner] = React.useState(true);
    // const handleShowSpinner = () => {setShowSpinner(true)}
    // const ClosehandleShowSpinner = () => {setShowSpinner(false)}


    const handleSuccessNotificationClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSuccessNotificationOpen(false);
    };
    // Notifications
    const [OpenFaceboolModal, setOpenFaceboolModal] = useState(false);
    const CloseFaceboolModal = () => setOpenFaceboolModal(false);

    const navigate = useNavigate();
    const classes = useStyles();

    const [dataavailable, setDataavailable] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [username, setUserName] = useState('')
    const [email, setEmail] = useState('')
    const [userPhone, setUserPhone] = useState('')
    const [message, setMessage] = useState('')
    const [token, setToken] = useState('')
    const [twitterAuth, setTwitterAuth] = useState(false)
    const [twitterEmail, setTwitterEmail] = useState('')
    const [providered, setProvidered] = useState('')






    const handleSignInWithFacebook = async () => {
        try {
            // Sign in with Facebook
            const provider = new FacebookAuthProvider();
            const auth = getAuth();
            signInWithPopup(auth, provider)
                .then((result) => {
                    console.log(result, 'result');
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = FacebookAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    console.log('Token', token);
                    // The signed-in user info.
                    const accessToken = result?._tokenResponse?.idToken;
                    // IdP data available using getAdditionalUserInfo(result)
                    AutheticateFirebaseAccessToken(accessToken)
                    setToken(accessToken)


                    // ...
                }).catch((error) => {

                    const credential = FacebookAuthProvider.credentialFromError(error);

                });

        } catch (error) {
            console.error('Error signing in with Facebook:', error);
        }
    };

    // const handleSignUpWithTwitter = async () => {
    //     // sign in with twitter 
    //      const provider = new TwitterAuthProvider();
    //     const auth = getAuth();
    //     signInWithPopup(auth, provider)
    //     .then((result) => {
    //         console.log(result, 'result');
    //         // This gives you a Google Access Token. You can use it to access the Google API.
    //         const credential = TwitterAuthProvider.credentialFromResult(result);
    //         const token = credential.accessToken;
    //         console.log('Token', token);
    //         // The signed-in user info.
    //         const accessToken = result?._tokenResponse?.idToken;
    //         // IdP data available using getAdditionalUserInfo(result)
    //         AutheticateFirebaseAccessToken(accessToken)
    //         setToken(accessToken)


    //         // ...
    //     }).catch((error) => {

    //         const credential = TwitterAuthProvider.credentialFromError(error);
    //         console.error('Error signing in with Twitter:', error);

    //     });



    // }

    const handleSignInWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        signInWithPopup(auth, provider)
            .then((result) => {
                console.log(result, 'result');
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                console.log('Token', token);
                // The signed-in user info.
                const accessToken = result._tokenResponse.idToken;
                // IdP data available using getAdditionalUserInfo(result)
                AutheticateFirebaseAccessToken(accessToken)
                setToken(accessToken)


                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
                console.error('Error signing in with Google:', error);

            });
    };

    const AutheticateFirebaseAccessToken = async (token) => {
        console.log(token, '==========Token================>');
        try {
            console.log(token, 'token here');
            const response = await fetch(`${baseUrl}/api/v1/users/firebase/signup`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },

                body: JSON.stringify({
                    firebaseIdToken: token
                }),

            });
            const data = await response.json()
                .then((data) => {
                    console.log(data, 'Here is the response');
                    if (data.Status == "SUCCESS") {


                        const SetemailAddress = data?.user?.email

                        console.log(SetemailAddress, 'email here');
                        let provider = data?.user?.provider
                        console.log(provider, 'provider here');
                        if (SetemailAddress ===undefined || SetemailAddress === null || SetemailAddress === '') {
                            console.log(provider, 'provider here');
                            console.log(SetemailAddress, 'SetemailAddress');
                            console.log('WE ARE HERE');
                           
                            if (provider == 'google.com') {
                                setEmailNotFound(true)
                            setDataavailable(false)
                            setMessage('Your email is not verified. Login to Google to verify your email.')
                            setOpenFaceboolModal(true)
                                
                            }else if(provider == 'facebook.com'){
                                setEmailNotFound(true)
                                setDataavailable(false)
                                setMessage('Your email is not verified. Go to facebook Profile Icon > Settings & Privacy > Settings >  General Account Settings > Edit Primary Conatact  to verify your email.')
                                setOpenFaceboolModal(true)
                            }
                            



                        } else {
                            setEmailNotFound(false)
                            setDataavailable(true)
                            handleSuccessNotificationClick();
                            setMessage('Almost there. Provide Required details below to complete sign up.')
                            setFirstName(data?.user.firstName)
                            setLastName(data?.user.otherName)
                            setUserName(data?.user.firstName)
                            setProvidered(data?.user.provider)
                            setEmail(data?.user.email)
                            
                        }

                    }
                    if (data?.Status == 'FAILED') {
                        handleErrorNotificationClick()
                        setMessage(data.message)
                    }

                })

        } catch (error) {
            console.error('Error with Authetication :', error);


        }
    }
    console.log(emailNotFound, 'emailNotFound');



    const SignUpUser = async () => {
        console.log('token', token);

        const addresponse = await fetch(baseUrl + "/api/addNewUser", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                firstName: firstName, secondName: lastName, userName: username, userEmail: email, userPhone: "+" + userPhone, firebaseIdToken: token, fcmToken: "xyzz", deviceId: "WEB"


            }),

        });
        let data = await addresponse.json();
        console.log('data========>', data);

        if (addresponse.status === 400) {
            setMessage(data.error)
            handleErrorNotificationClick(true)
            // console.log('we have an error');
            // window.location.href = "/logout"



        } else {
            if (addresponse.status === 200) {
                setMessage('Login Successfully')

                handleSuccessNotificationClick()
                // decode the jwt token 
                console.log('data', data)
                localStorage.setItem("X-Authorization", data?.token);
                localStorage.setItem("group", "User");
                localStorage.setItem("zedAccountingId", data?.zedAccountingId);
                localStorage.setItem("email", data?.email);
                localStorage.setItem("state", data?.state || "Modified");
                localStorage.setItem('workflow', 'null')
                localStorage.setItem("businessID", data?.defaultBusinessId);
                localStorage.setItem("username", data?.username);
                localStorage.setItem("businessName", data?.businessName || 'All');
                localStorage.setItem("businessId", data?.businessNumber);
                localStorage.setItem("userId", data?.userId);
                localStorage.setItem("BranchID", data?.branchId || 'null');
                localStorage.setItem("customerId", data?.customerId);
                localStorage.setItem("businessCategory", data?.businessCategory);
                //  navigate("/userDashboard");
                dispatch(logInUser({ isLoggedIn: true, ...data, branchId: null, businessName: "All", state: "Modified", group: "User" }));
                navigate("/userDashboard");

            }
        }

    }
    return (
        <Grid container justifyContent={"center"} style={{ fontFamily: "Poppins" }}>

            <Grid container direction={"row"} style={{ paddingLeft: "10%", paddingRight: "10%" }}>
                {/*Logo image*/}
                <Grid item>
                    <img src={loginLogo} alt="logo" style={{ marginTop: "10%" }} />
                </Grid>
            </Grid>


            <Grid container justifyContent={"space-between"} direction={"row"} spacing={1} column={1} alignContent={"flex-start"} style={{ marginTop: "5%", paddingLeft: "5%" }}>
                <Grid item sm={12} lg={6} md={12} xl={6} xs={12}>
                    <img src={loginImage} className={`img-fluid ${classes.imgRes}`} alt={"login image"} style={{ width: "50rem" }} />
                </Grid>

                {dataavailable ?
                    <>
                        {/*Signup Form*/}
                        <Grid item style={{ marginTop: "5%", width: "500px" }} className={classes.gridLayout} sm={12} lg={6} md={12} xl={6} xm={12}>
                            {/*Signup form*/}
                            <Grid container direction={"column"} justifyContent={"space-between"} spacing={2} alignContent={"center"}>
                                {/*login label*/}
                                <Grid item>
                                    <h1 style={{ fontSize: "20px", color: "#212b36", fontSize: "20px", fontWeight: "600", textAlign: "center" }}>Personal Details</h1>
                                    <span mb={4} className="mb-3" style={{ color: "#707070", fontSize: "12px", fontFamily: "Poppins", alignItems: "center" }}>We need a few details about you</span>
                                </Grid>
                            </Grid>
                            {/*First and Last name*/}

                            <Grid container className="mt-3" direction={"row"} mb={4} mt={3} pr={3} spacing={{ xl: 4, lg: 4, sm: 3, xs: 3 }} justifyContent={"space-between"}>
                                {/*First name*/}
                                <Grid item lg={6} xl={6} xs={12} sm={12} md={12}>
                                    <TextField
                                        fullWidth
                                        className={classes.textf}
                                        value={firstName}
                                        onChange={(e) => {
                                            setFirstName(e.target.value);
                                        }}
                                        type="text"
                                        InputProps={{ style: { fontFamily: "Poppins" } }}
                                        InputLabelProps={{ style: { fontFamily: "Poppins", fontSize: "13px", color: "#032541" } }}
                                        style={{ border: "solid 0px #e4e4e4", fontSize: "12px" }}
                                        id="outlined-basic"
                                        label="First Name"
                                        variant="outlined"
                                        required
                                    />
                                </Grid>

                                {/*Last name*/}
                                <Grid item lg={6} xl={6} sm={12} xs={12} md={12}>
                                    <TextField
                                        fullWidth
                                        className={classes.textf}
                                        value={lastName}
                                        onChange={(e) => {
                                            setLastName(e.target.value);
                                        }}
                                        type="text"
                                        InputProps={{ style: { fontFamily: "Poppins" } }}
                                        InputLabelProps={{ style: { fontFamily: "Poppins", fontSize: "13px", color: "#032541" } }}
                                        style={{ border: "solid 0px #e4e4e4", fontSize: "12px" }}
                                        id="outlined-basic"
                                        label="Last Name"
                                        variant="outlined"
                                        required
                                    />
                                </Grid>
                            </Grid>
                            {/*Username*/}
                            <Grid container direction={"column"} mt={10} spacing={{ xl: 4, lg: 4, sm: 3, xs: 3 }} pr={3}>
                                <Grid item lg={12} xl={12} sm={12} xs={12} md={12}>
                                    <TextField
                                        fullWidth
                                        value={username}
                                        className={classes.textf}
                                        onChange={(e) => {
                                            setUserName(e.target.value);
                                        }}
                                        type="text"
                                        InputProps={{ style: { fontFamily: "Poppins" } }}
                                        InputLabelProps={{ style: { fontFamily: "Poppins", fontSize: "13px", color: "#032541" } }}
                                        style={{ border: "solid 0px #e4e4e4", fontSize: "12px" }}
                                        id="outlined-basic"
                                        label="UserName"
                                        variant="outlined"
                                        required
                                    />
                                </Grid>

                                {/*Email*/}
                                <Grid item lg={12} xl={12} sm={12} xs={12} md={12}>
                                    <TextField
                                        fullWidth
                                        className={classes.textf}
                                        disabled
                                        value={email || twitterEmail}
                                        // onChange={(e) => {
                                        //     setEmail(e.target.value);
                                        // }}
                                        type="email"
                                        InputProps={{ style: { fontFamily: "Poppins" } }}
                                        InputLabelProps={{ style: { fontFamily: "Poppins", fontSize: "13px", color: "#032541" } }}
                                        style={{ border: "solid 0px #e4e4e4", fontSize: "12px" }}
                                        id="outlined-basic"
                                        label="Email"
                                        variant="outlined"
                                        required
                                    />
                                </Grid>

                                {/*phone number input  */}
                                <Grid item lg={12} xl={12} sm={12} xs={12} md={12}>
                                    <PhoneInput className={classes.textf} inputStyle={{ height: "55px" }} required country={"ke"} enableSearch={true} value={userPhone} onChange={(phone) => setUserPhone(phone)} />
                                </Grid>
                            </Grid>

                            {/*Terms & Condition link*/}
                            <Grid item lg={12} xl={12} sm={12} xs={12} md={12}>
                                <Grid container justifyContent={"center"}>
                                    <Grid item style={{ fontSize: "11px" }}>
                                        <span>By Proceeding, you agree to Zed Payment's Terms of Service and Privacy Policy.</span>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/*Login button*/}
                            <Grid container onClick={SignUpUser} justifyContent={"center"} alignContent={"center"} style={{ marginTop: "5%" }}>
                                <Button style={{ color: "white", backgroundColor: "#032541", height: "45px", width: "171px" }}>
                                    Sign In
                                </Button>
                            </Grid>


                        </Grid>
                    </> :
                    <>
                        <Grid item style={{ marginTop: "5%", width: "500px" }} className={classes.gridLayout} sm={12} lg={6} md={12} xl={6} xm={12}>

                            <Grid container direction={"column"} justifyContent={"space-between"} spacing={2} alignContent={"flex-start"}>

                                <Grid container justifyContent={"center"} alignContent={"center"} style={{ marginTop: "5%", fontSize: "20px", fontWeight: "bold", color: "#032541" }}>
                                    <span> Let’s Sign you up!  </span>
                                </Grid>
                            </Grid>

                            <Grid container direction={"column"} justifyContent={"space-between"} spacing={2} alignContent={"flex-start"} >
                                <Grid container justifyContent={"center"} alignContent={"center"} style={{ marginTop: "2%", fontSize: "15px", fontWeight: "normal", color: "#707070" }}>
                                    <div className="div" style={{ width: "400px" }}>
                                        <span className="text-center">Select your preferred method and create your account with us.</span>

                                    </div>
                                </Grid>
                            </Grid>

                            {/* onMouseEnter={(e) => { e.target.style.backgroundColor = "#032541", e.target.style.color = "#3E2836";  }} onMouseLeave={(e) => {    e.target.style.backgroundColor = "#fff" } } */}
                            <Grid style={{ display: "flex", alignContent: "center", justifyContent: "center", marginTop: "15px", cursor: "pointer" }} onClick={() => handleSignInWithGoogle()} >
                                <div style={{ display: "flex", width: "370px", padding: "15px", height: "60px", boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px' }} >
                                    <img src={googleIcon} className="pl-5" alt="" height={"30px"} />
                                    <span className="ml-2">Sign up with Google</span>
                                </div>
                            </Grid>
                            <Grid style={{ display: "flex", alignContent: "center", justifyContent: "center", marginTop: "10px", cursor: "pointer" }} onClick={() => handleSignInWithFacebook()}>
                                <div style={{ display: "flex", width: "370px", padding: "15px", boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px' }} >
                                    <img src={facebookIcon} className="pl-5" height={"30px"} alt="" />
                                    <span className="ml-2">Sign up with Facebook</span>
                                </div>
                                {/* <FacebookLogin
                            appId="1448486968888736"
                            autoLoad={false}
                            fields="name,email,picture"
                            callback={handleFacebookLogin}
                            onFailure={handleFacebookLoginFailure}
                        /> */}
                            </Grid>
                            {/* <Grid style={{ display: "flex", alignContent: "center", justifyContent: "center", marginTop: "10px" }} onClick={() => handleSignUpWithTwitter()} >
                                <div style={{ display: "flex", width: "370px", padding: "15px", boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px' }} >
                                    <img src={TwitterIcon} className="pl-5" height={"30px"} alt="" />
                                    <span className="ml-2">Sign up with Twitter</span>
                                </div>
                            </Grid> */}

                            <Grid style={{ display: "flex", alignContent: "center", justifyContent: "center", marginTop: "10px", cursor: "pointer" }} >
                                <Link to='/signup/email' style={{ textDecoration: "None" }}>
                                    <div style={{ display: "flex", width: "370px", padding: "15px", boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px' }} >
                                        <img src={emailIcon} className="pl-5" height={"30px"} alt="" />
                                        <span className="ml-2">Sign up with email</span>
                                    </div>
                                </Link>
                            </Grid>
                            {/*New user Signup*/}
                            <Grid container justifyContent={"center"} alignContent={"center"} style={{ marginTop: "1%", fontSize: "15px", color: "#032541" }}>
                                <span>
                                    Already have an account?{" "}
                                    <Link style={{ fontWeight: "bold", textDecoration: "none" }} to={"/"}>
                                        Login
                                    </Link>
                                </span>
                            </Grid>
                            {/* </Grid> */}
                        </Grid>
                    </>}

                {/*fake Columns*/}
                <Grid item></Grid>
                <Grid item></Grid>
                <Grid item></Grid>
                {/* </Grid> */}
                {/* <div  open={showspinner} onClose={ClosehandleShowSpinner}>
                <Spinner animation="grow"   variant="info" />
                <Spinner animation="grow"   />
                 <Spinner animation="grow"  />
            </div> */}
                <ErrorAlert message={message} open={errorNotificationOpen} onClose={handleErrorNotificationClose} horizontal={"right"} vertical={"top"} />
                <SuccessAlert message={message} open={successNotificationOpen} onClose={handleSuccessNotificationClose} horizontal={"right"} vertical={"top"} />
                <Modal
                    open={OpenFaceboolModal}
                    onClose={CloseFaceboolModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>

                        <div
                            style={{
                                width: "50px",
                                height: "50px",
                                marginLeft: "150px",
                                padding: "12px 30px 127px",
                                objectFit: "contain",
                            }}
                        >
                            <img
                                style={{ width: "95px", height: "95px", margin: "-24px 0px 0px -66px" }}
                                src={deactivateIcon}
                                alt="img"
                            />
                        </div>
                        <div
                            style={{
                                marginTop: "-30px",
                                fontFamily: "Poppins",
                                fontSize: "25px",
                                fontWeight: "bold",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "center",
                                color: "#323d52",
                                textTransform: "capitalize",
                            }}
                        >
                            <span>{providered} Verify</span>
                        </div>
                        <div>
                            <span
                                style={{
                                    textAlign: "center",
                                    fontSize: "12px"

                                }}
                            >
                                {message || 'Your email is not verified. Please verify your email to continue.'}
                            </span>
                        </div>
                        <div align="right">
                            <Button
                                onClick={CloseFaceboolModal}
                                variant="outlined"
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                    textAlign: "left",
                                    color: "#dc3545",
                                    width: "90px",
                                    height: "40px",
                                    margin: "40px 30px 0 0",
                                    padding: "9px 29px 8.1px 30px",
                                    border: "solid 1px #dc3545",
                                }}
                            >
                                Cancel
                            </Button>
                            {/*  save  */}
                           <a href={`https://www.${providered}/`} target="_blank">
                           <Button
                                type="submit"
                                variant="standard"
                                style={{color: "#fff",fontFamily: "Poppins",fontSize: "12px",width: "90px",height: "40px",margin: "40px 50px 0 30px",padding: "9px 32px 8.1px 31px",backgroundColor: "#032541",
                                }}
                            >
                                {'Verify'}
                            </Button>
                            </a>
                        </div>

                    </Box>
                </Modal>

            </Grid>






        </Grid>
    );
};

export default SignUpOptions;



