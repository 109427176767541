import Modal from "@mui/material/Modal";
import {Select} from "@mui/material";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@mui/material/Button";
import React, {useState} from "react";

function DeactivateRequest (props) {

    const [modalOpen , setModalOpen] = useState(props.modalOpen);
    const [reasonCode, setReasonCode] = useState(props.code)
    const [reasonDescription, setReasonDescription] = useState(props.description)

    const propData = props.data
    // console.log(props.modalOpen)
    return (
        <div>
            {/*Deactivation Request Group Modal*/}
            <Modal centered align={'center'} className='container container-fluid' open={props.modalOpen}
                   onClose={props.onClose} aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                <div style={{width : "665px" , height : "604px" , margin : "10% 47px 5px 96px" , padding : "0 0 9px" , borderRadius : "15px" , backgroundColor : "#fff"}}>
                    <div style={{width : "665px" , height : "90px" , margin : "0 0 3px" , padding : "35px 30px 34px" , borderRadius : "16px" , backgroundColor : "#dc3545"}}>
                        <label style={{height : "20px" , margin : "0 325px 0 0" , fontFamily : "Poppins" , fontSize : "14px" , fontWeight : "600" , fontStretch : "normal" , fontStyle : "normal" , lineHeight : "normal" , letterSpacing : "normal" , textAlign : "left" , color : "#fff"}}>
                            {props.action} {props.target}
                        </label>
                    </div>
                    <form onSubmit={props.formAction}>
                        <div>
                            <div>
                                <label style={{eight : "44px" , margin : "48px 106px 37px 84px" , fontFamily : "Poppins" , fontSize : "15px" , fontWeight : "normal" , fontStretch : "normal" , fontStyle : "normal" , lineHeight : "normal" , letterSpacing : "normal" , textAlign : "left" , color : "#032541"}}>
                                    To {props.action} {props.target} <b>{props.object}</b>, Select the Reason and
                                    describe why you are deactivating.
                                </label>
                            </div>
                            <label style={{width : "41px" , height : "16px" , margin : "0px 537px 10px 84px" , fontFamily : "Poppins" , fontSize : "11px" , fontWeight : "normal" , fontStretch : "normal" , fontStyle : "normal" , lineHeight : "4" , letterSpacing : "normal" , textAlign : "left" , color : "#6e7074"}}>
                                Reason
                            </label>
                            <Select value={reasonCode} onChange={(e) => setReasonCode(e.target.value)} defaultValue="" id="grouped-select" label="Grouping" sx={{width : "496px" , height : "39px" , margin : "10px 85px 20px 84px" , objectFit : "contain" , borderRadius : "4px" , border : "solid 1px #dfdede" , backgroundColor : "#fff"}}>
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {
                                    propData.map((code) => (
                                        <MenuItem key={code.reasonCode} value={code.reasonCode}>
                                            {code.reasonCode}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                            <label style={{width : "62px" , height : "16px" , margin : "0px 537px 10px 84px" , fontFamily : "Poppins" , fontSize : "11px" , fontWeight : "normal" , fontStretch : "normal" , fontStyle : "normal" , lineHeight : "4" , letterSpacing : "normal" , textAlign : "left" , color : "#6e7074"}}>
                                Description
                            </label>
                            <textarea value={reasonDescription} onChange={(e) => setReasonDescription(e.target.value)} id="w3review" name="w3review" rows="4" cols="50"
                                      placeholder='Enter Description' style={{width : "505px" , height : "106px" , margin : "10px 76px 43px 84px" , objectFit : "contain" , borderRadius : "4px" , border : "solid 1px #dfdede" , backgroundColor : "#fff"}}>
                                </textarea>
                            <div align='right'>
                                <Button onClick={props.onClose} variant='outlined' style={{fontSize : '11px' , color : "#032541" , width : "90px" , height : "33.1px" , margin : "0px 30px 62.9px 13px" , padding : "9px 29px 8.1px 30px" , border : "solid 1px #032541"}}>
                                    Cancel
                                </Button>
                                {/*  save  */}
                                <Button type='submit' variant='standard' style={{fontSize : '11px' , color : '#fff' , width : "90px" , height : "33.1px" , margin : "0px 76px 62.9px 30px" , padding : "9px 15px 8.1px 15px" , backgroundColor : "#dc3545"}}>
                                    {props.action}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    )
}
export default DeactivateRequest