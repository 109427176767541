import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Link} from "react-router-dom";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import vouchers from '../../common/images/whitevoucher.svg'
import { CardGiftcard } from "@mui/icons-material"

const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(2),
	},
}));

export default function Vouchers () {
	const classes = useStyles();

	return (
		<div>
			<ListItem button className={classes.nested}>
				<div style={{marginRight : '5%'}}>
					<CardGiftcard/>
				</div>
				<Link style={{textDecoration: 'none', color : '#fff'}} to="/vouchers">
					<ListItemText primary="Vouchers" />
				</Link>
			</ListItem>
		</div>
	)
}
