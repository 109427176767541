import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {Breadcrumbs, Button, Checkbox, Grid, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import {handleAddCharge, handleDeleteCharge} from "../../features/tenantsDataSlice";
import HttpComponent from "./MakeRequest";
import {NoRowsOverlay} from "../No Rows/noRowsOverlay"
import {
    addService,
    addToCurrentService,
    clearServicesToAdd, removeFromCurrentServices,
    removeFromServicesToAdd
} from "../../features/servicesSlice";


const numberFormat = (value) => new Intl.NumberFormat("en-US", {style: "currency", currency: "KES"}).format(value);

export default function EditSchoolInvoice(props) {
    const { invoiceNumber } = useParams();
    try {

        // navigation
        function handleCreateService () {
            props?.setFlagCreateService()
            console.log(`Props`, props)
        }

        //Redux Store
        const navigate = useNavigate()
        const dispatch = useDispatch()
        const service = useSelector((store) => store.services.currentServices)
        const newServices = useSelector((store) => store.services.servicesToAdd)
        // const {unitId, amount, itemNumber, billingDate, unitType} = useSelector((store) => store.tenantData.tenantHouseData)

        useEffect(() => {
           console.log("........newServices", newServices)
        }, [newServices]);

        useEffect(() => {
            dispatch(clearServicesToAdd())
        }, []);


        //Auth
        const { userId ,X_Authorization } = useSelector((store) => store.user);
        const [unitCharges, setUnitCharges] = useState([])

        //Handle Checkbox Change
        const handleCheckboxChange = (event, product) => {
            let data = {productId : product._id, productName: product.productName, quantity:1}
            console.log(`this is Data`, data)
            if (event.target.checked) {
                dispatch(addToCurrentService(data))
                dispatch(addService(data));
            }
            // here we will handle how to uncheck those that already in the invoice
            else {
                const index = service.findIndex((service) => service.productName === product.productName);
                if(index != -1){
                    dispatch(removeFromCurrentServices({index}))
                }
                const secondIndex = newServices.findIndex((service) => service.productName === product.productName);
                if(secondIndex != -1){
                    dispatch(removeFromServicesToAdd({index:secondIndex}))
                }
            }
        };

        const updateInvoice = () =>{
            let data =
                {
                    invoiceNumber,
                    products:newServices
                }
            HttpComponent({
                method : 'POST',
                url : `/api/add_products_to_invoice`,
                body:data,
                token : X_Authorization
            }).then((data)=>{
                console.log("here store is data",data);
                if(data.status === 200){
                    navigate(`/school/invoice/${invoiceNumber}`)
                }
                else{
                    console.error("Error setting info")
                }
            }).catch((error)=>{
                console.error(error.message);
            })
        }

        const servicesColumns = [
            {field : 'productName', flex : 1,  align : 'left', headerAlign : 'left', renderCell: (params) => (
                    <div>
                        <Checkbox
                            style={{color : '#dc3545'}}
                            checked={service.some(s => s.productId === params.row._id)}
                            onChange={(event) => {
                                handleCheckboxChange(event, params.row)
                                console.log(params.row)
                            }}/>
                        <span style={{color : '#707070', fontSize : '14px'}}>{params.value}</span>
                    </div>
                ) , renderHeader : () => {return (<strong style={{color : '#032541'}} >Service Name</strong>)}},
            {field : 'productCategory', flex : 1,  align : 'left', headerAlign : 'left', renderHeader : () => {return (<strong style={{color : '#032541'}}>Category</strong>)}},
            {field : 'productPrice', flex : 1,  align : 'left', headerAlign : 'left', renderHeader : () => {return (<strong style={{color : '#032541'}}>Amount</strong>)}, renderCell : (params) => {return (numberFormat(params.value))}}
        ]

        const breadcrumbs = [
            <Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">Dashboard</Typography>,
            <Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">Invoice Batches</Typography>,
            <Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">Invoices In Batch</Typography>,
            <Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">Invoice</Typography>,
            <Typography style={{color : '#dc3545', fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.error">Update Invoice</Typography>
        ];

        //Fetch services per unit
        function fetchServices() {
            HttpComponent({
                method : 'GET',
                url : `/api/listProducts/Active?search`,
                token : X_Authorization
            }).then((data)=>{
                if(data.status === 200){
                    setUnitCharges(data.response.data)
                }else{
                    console.log(`Error getting data`)
                }
            }).catch((error)=>{
                console.error(error.message);
            })
        }

        useEffect(() => {
            fetchServices()
        }, [])

        console.log(`charges`, unitCharges)
        return (
            <div style={{fontFamily : 'Poppins'}}>

                {/*BreadCrumb container*/}
                <Breadcrumbs style={{fontFamily : 'Poppins', fontSize : '14px'}} separator={<FiberManualRecordIcon style={{fontSize:"0.625rem", fontFamily : 'Poppins', color:"#e1e5e8"}} />} aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>

                {/*Top Container*/}
                <Grid container justifyContent={'space-between'} mt={7}>

                    {/*Header label*/}
                    <Grid item>
                        <span style={{color : '#707070', fontFamily : 'Poppins', fontSize : '16px'}}>List of Service</span>
                    </Grid>

                    {/*Button Create Service*/}
                    <Grid item>
                        <Button disabled onClick={handleCreateService} style={{textTransform : "none",textDecoration : "none", marginTop : '5px',background : '#032541', color : '#fff', fontSize : `14px`, fontWeight : 600, height : '35px'}} >Create Service</Button>
                    </Grid>
                </Grid>

                {/*Table Container*/}
                <DataGrid
                    components={{NoRowsOverlay : NoRowsOverlay}}
                    autoHeight
                    autoWidth
                    sx={{minHeight : '400px', maxHeight:"600px",width : 'auto', fontFamily : 'Poppins', fontSize: "14px", color : '#707070',boxShadow: 0, border: 0}}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    columns={servicesColumns}
                    rows={unitCharges}
                    getRowId={(row) => row._id}
                />

                {/*Update Button*/}
                <Grid container justifyContent={'flex-end'}>
                    <Grid item>
                        <Button onClick={updateInvoice} style={{textDecoration : "none", marginTop : '5px',background : '#17ae7b', color : '#fff', fontSize : `14px`, fontWeight : 600, height : '35px'}} >Update Invoice</Button>
                    </Grid>
                </Grid>
            </div>
        )
    } catch (e) {
        console.log(`Error Doing This`, e.message)
    }
}

