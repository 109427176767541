import { Grid, Breadcrumbs, Typography, Button, TextField } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useState } from "react";
import HttpComponent from "../School/MakeRequest";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PhoneInput from "react-phone-input-2";
import GeoLocation from "../../hooks/useLocation";

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Branches</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Add Branch</Typography>
]


export default function CreateBranch() {

    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })

    const [formData ,setFormData] = useState({branchName:"" ,branchEmail:""})
    const[userPhone , setUserPhone] = useState()

    const businessID = localStorage.getItem('businessID')

    const [selectedValue ,setSelectedValue] = useState('')

    //handle onChange

    const handleOnChange = (e) =>{
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = ()=>{
        try {
            HttpComponent({
                method:'POST',
                url:`/api/addBranch`,
                body:{
                    phone: userPhone,
                    businessid: businessID,
                    branchName: formData.branchName,
                    email: formData.branchEmail
                },
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{
                console.log(data , 'branch data')
                if(data.status === 201){
                    setSuccessShow({state:true , message:data?.response?.message})
                }else{
                    setErrorShow({state:true , message:data?.response?.message})
                }
                setTimeout(()=>{
                    navigate(-1)
                },[2000])
            })
            
        } catch (error) {
            setErrorShow({state:true , message:error})
        }

    }

    const navigate = useNavigate();

    return (
        <Grid direction={'column'} container>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Add Branch</Typography>
            </Grid>
            <Grid item display={'flex'} mt={2}>
                <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                    <Grid item>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={3}>
                <Typography style={{ fontWeight: 600, color: "#707070", fontSize: "18px" }}>Branch Details</Typography>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <TextField id="outlined-basic" onChange={handleOnChange} style={{ width: "100%" }}  name="branchName" value={formData.branchName} label="Branch Name" variant="outlined" />
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <TextField id="outlined-basic"  onChange={handleOnChange} style={{ width: "100%" }} name="branchEmail" value={formData.branchEmail} label="Branch Email" variant="outlined" />
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <PhoneInput   inputStyle={{ height: "55px" }} style={{ width: "100%" }}  required country={"ke"} enableSearch={true} value={userPhone} onChange={(phone)=>setUserPhone(phone)} />
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                {/* <TextField id="outlined-basic"   onChange={handleOnChange} style={{ width: "100%" }} value={formData.branchLocation} name="branchLocation" label="Branch Location" variant="outlined" /> */}
                <GeoLocation onValueChange={(location)=>setSelectedValue(location)} selectedValue={selectedValue}/>
            </Grid>
            <Grid mt={2} item style={{ width: "50%" }} display={'flex'} justifyContent={'flex-end'}>
                <Button onClick={()=>navigate(-1)} style={{ background: "#fff", color: "#DC3545", border: "1px solid #DC3545", width: "125px", marginRight: "5px", textTransform: "inherit" }}>Cancel</Button>
                <Button onClick={handleSubmit} style={!userPhone || !formData.branchEmail || !formData.branchName || !selectedValue ? {opacity:0.4 ,width: "125px",background: "#032541", textTransform: "inherit",}:{ background: "#032541", textTransform: "inherit", width: "125px", color: "#fff" , opacity:1 }}>Add</Button>
            </Grid>
        </Grid>
    )
}