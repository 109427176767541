import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    FormHelperText,
    Grid,
    InputLabel,
    Modal,
    Select,
    Stack,
    styled,
    Tab,
    TablePagination
} from "@mui/material";
import MenuItem from "@material-ui/core/MenuItem";
import {CheckCircle, Circle, Close} from "@mui/icons-material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {AddStudentModal} from "./addStudentModal";
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from "@mui/material/TextField";
let baseUrl = process.env.REACT_APP_BASE_URL

const AntTabs = styled(TabList)({borderBottom: "3px solid #e8e8e8", "& .MuiTabs-indicator": {backgroundColor: "#dc3545",},});
const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({textTransform: "none", minWidth: 0, [theme.breakpoints.up("sm")]:
            {minWidth: 0,}, fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1), fontSize: "13px", fontStretch: "normal",
        fontStyle: "normal", lineHeight: "2.75", letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","), "&:hover":
            {color: "#032541", opacity: 1,}, "&.Mui-selected": {color: "#dc3545", fontWeight: 600,}, "&.Mui-focusVisible": {backgroundColor: "blue",},}));

const wholeModal = {
    minWidth: '1000px',
    minHeight: '500px',
    backgroundColor: '#fff',
    borderColor:"transparent",
    borderRadius: "15px",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};
const headerSection = {
    backgroundColor: '#dc3545',
    width: '100%',
    borderTopLeftRadius: "7px",
    borderTopRightRadius: "7px",
    height: '109px',
    flexDirection: "row",
    alignContent: "center",
    justifyItems: "center",
    textAlign: "center",
}
const useStyles = makeStyles({
    table: {
        minWidth: 800,
    },
});

const AddParentChildren = (props) => {
    const classes = useStyles();
    const [parentChildrenOpen, setParentChildrenOpen] = useState(props.open);
    const [tabValue, setTabValue] = useState("PersonalDetails");
    const [customerDetails, setCustomerDetails] = useState({customerType: "", customerFirstName: "", customerLastName: "", companyName: "", companyAdress: "", companyCode: "", customerCode: "", companyPhoneNo: "", customerPhoneNo: "", customerEmail: "", companyEmail: "", paymentType: "Prepaid", creditPaymentInstruments: "", prepaidPaymentInstruments: "", limit: "", amountRecieved: "",role:"Select Role", customerAddress:""});
    const [showIndividualDetails, setShowIndividualDetails] = useState({individualName: true, individualLocation: true, individualAdress: true, individualPhone: true,});
    const [showCompanyDetails, setShowCompanyDetails] = useState({companyName: false, companyAdress: false, companyEmail: false, companyPhone: false,});
    const [paymentInstruments, setPaymentInstruments] = useState({prepaid: false, credit: false, limit: false, amountRecieved: false,})
    const [studentOpen, setStudentOpen] = useState(false);
    const [studentsToSave, setStudentsToSave] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [firstName, setFirstName] = useState("Student First Name")
    const [lastName, setLastName] = useState("Student Last Name")
    const [additionalInfo, setAdditionalInfo] = useState("Add additional student information")
    const [relationship, setRelationship] = useState("Select Relationship");
    const [studentId, setStudentId] = useState("Student ID")
    const [grade, setGrade] = useState("Select Grade");
    const [term, setTerm] = useState("Select Term")
    const [dob, setDob] = useState(Date.now())
    const [admission, setAdmission] =  useState(Date.now())
    const [isUpdate, setIsUpdate] = useState(false);
    const [studentServices, setStudentServices] = useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    const handleClose = props.onClose

    const formatDate = (dateString) =>{
        return new Date(dateString).toLocaleDateString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric"
        });
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const getCustomerPersonalDetailsInputValue = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setCustomerDetails({ ...customerDetails, [name]: value });
    };

    const removeStudent = (student) =>{
        let temporaryArray = [...studentsToSave]
        const index = temporaryArray.findIndex((object) => object.studentId === student.studentId);
        temporaryArray.splice(index , 1);
        setStudentsToSave(temporaryArray)
    }

    const editStudent = (student) =>{
        setFirstName(student.firstName);
        setLastName(student.lastName);
        setAdditionalInfo(student.additionalInfo);
        setRelationship(student.relationship);
        setStudentId(student.studentId);
        setGrade(student.grade);
        setTerm(student.term);
        setDob(new Date(student.dob));
        setIsUpdate(true)
        setStudentOpen(true);
    }

    const updateStudent = (studentId, updatedStudent) => {
        setStudentsToSave(prevState => {
            return prevState.map(student => {
                if (student.studentId === studentId) {
                    return updatedStudent;
                }
                return student;
            });
        });
    };

    const submitData = async () => {
        let billableItems = []
        studentsToSave.forEach(student => {
            billableItems.push({
                "itemNumber": student.studentId,
                "firstName": student.firstName,
                "lastName": student.lastName,
                "billingFrequency": "ThreeMonths",
                "role": customerDetails.role,
                "relationShip": student.relationship,
                "dateOfBirth": Date(student.dob.$d),
                "grade": student.grade,
                "term": student.term,
                "additionalInfo": student.additionalInfo,
                "dateOfAdmission":Date(student.admission.$d)
            });
        });
        let submitObject = {
            amountReceived:0,
            paymentType:customerDetails.paymentType,
            businessId: localStorage.getItem("businessId"),
            customerAddress: customerDetails.customerAddress,
            customerType: "Individual",
            email: customerDetails.customerEmail,
            firstName: customerDetails.customerFirstName,
            lastName: customerDetails.customerLastName,
            phone: customerDetails.customerPhoneNo,
            billableItems: billableItems,
            extraServices: studentServices,
        }
        console.log("submission", submitObject)
        let url = baseUrl + "/api/addCustomer"
        await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-Authorization': localStorage.getItem('X-Authorization')
            },
            body: JSON.stringify(submitObject)
        })
            .then(response => response.json())
            .then(data => {
                console.log("Success:", data);
                setParentChildrenOpen(false);
            })
            .catch(error => {
                console.error("Error:", error);
            });
    }

    const [allValid, setAllValid] = useState(true);


    useEffect(()=>{
        if(customerDetails.role
            && customerDetails.customerFirstName
            && customerDetails.customerLastName
            && customerDetails.customerAddress
            && customerDetails.customerEmail
            && validateEmail(customerDetails.customerEmail)
            && customerDetails.customerPhoneNo){
            setAllValid(false);
        }else{
            setTabValue("PersonalDetails")
        }
    },[customerDetails,tabValue])



    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    useEffect(() => {
       let cust = {...customerDetails}
        console.log("customer details", cust)
    }, [customerDetails]);

    useEffect(() => {
        setParentChildrenOpen(props.open);
    }, [props.open]);
    return (
        <Modal open={parentChildrenOpen}
               // onClose={props.onClose}
               disableBackdropClick={true}
               disableEscapeKeyDown={true}
               aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description">
            <Box sx={wholeModal}>
                <div style={headerSection}>
                    <div
                        style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 25,
                            paddingTop:"40px"
                        }}
                    >
                        Add Parent/Guardian
                        <Close onClick={handleClose} style={{color: "white",
                            position: "absolute",
                            top: "1.25rem",
                            right: "1.25rem",
                            cursor: "pointer"}}/>
                    </div>
                </div>
                <Grid container>
                    <Grid item style={{width: "100%"}}>
                        <Box sx={{"& .super-app-theme--header": {color: "#032541", fontWeight: "bold", fontSize: "10px", fontFamily: "Poppins", fontStretch: "normal", fontStyle: "normal",},}}>
                            <TabContext value={tabValue}>
                                <Box style={{width: "100%"}}>
                                    <AntTabs onChange={handleTabChange} variant="fullWidth" scrollButtons={false} aria-span="scrollable prevent tabs example">
                                        <AntTab icon={tabValue === "PersonalDetails" ? (<CheckCircle style={{color: "#447c69"}}/>) : (<Circle style={{color: "#b4bcc4"}}/>)} iconPosition="start" style={{fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.32", letterSpacing: "normal", color: "black",}} label="Personal Details" value="PersonalDetails" centered/>
                                        <AntTab icon={tabValue !== "AdditionalInfo" ? (<Circle style={{color: "#b4bcc4"}}/>) : (<CheckCircle style={{color: "#447c69"}}/>)} iconPosition="start" style={{fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.32", letterSpacing: "normal", color: "black",}} label="Student Details" value="AdditionalInfo" centered/>
                                    </AntTabs>
                                </Box>

                                {/*---- start personal details ----*/}

                                <TabPanel value="PersonalDetails">
                                    <Box>
                                        <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{margin: "0 0 20px 0"}}>
                                            {/*<Grid item xs={4}>*/}
                                            {/*    <Stack>*/}
											{/*		<span style={{width : '100%', fontFamily: "Poppins", fontSize: "15px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal", textAlign: "left", color: "#032541",}} >*/}
											{/*			Customer Type*/}
											{/*		</span>*/}
                                            {/*        <select style={{objectFit: "contain", borderRadius: "12px", border: "2px solid #f1f1f1", outline: "none", padding: "7px", width: "100%",}} name="customerType" id="customerType" onChange={getCustomerPersonalDetailsInputValue} value={customerDetails.customerType}>*/}
                                            {/*            <option>Select customer Type</option>*/}
                                            {/*            <option value="Company">Company</option>*/}
                                            {/*            <option value="Individual">Individual</option>*/}
                                            {/*        </select>*/}
                                            {/*    </Stack>*/}
                                            {/*</Grid>*/}
                                            <Grid item xs={4}>
                                                <Stack>
                                            		<InputLabel required style={{width : '100%', fontFamily: "Poppins", fontSize: "15px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal", textAlign: "left", color: "#032541",}} >
                                            			Role
                                            		</InputLabel>
                                                    {/*<select style={{objectFit: "contain", borderRadius: "12px", border: "2px solid #f1f1f1", outline: "none", padding: "7px", width: "100%",}} name="role" id="role" onChange={getCustomerPersonalDetailsInputValue} value={customerDetails.role}>*/}
                                                    {/*    <option>Select role</option>*/}
                                                    {/*    <option value="Parent">Company</option>*/}
                                                    {/*    <option value="Guardian">Individual</option>*/}
                                                    {/*</select>*/}
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        name="role"
                                                        id="role"
                                                        value={customerDetails.role}
                                                        label="Role"
                                                        required
                                                        onChange={getCustomerPersonalDetailsInputValue}
                                                        style={{
                                                            width:"100%"
                                                        }}
                                                    >
                                                        <MenuItem value={"Select Role"} disabled>Select Role</MenuItem>
                                                        <MenuItem value={"Parent"}>Parent</MenuItem>
                                                        <MenuItem value={"Guardian"}>Guardian</MenuItem>
                                                    </Select>
                                                    {!customerDetails.role && customerDetails.role !== "Select Role" && (
                                                        <FormHelperText error>This field is required</FormHelperText>
                                                    )}
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={7.5}>
                                                {/* individual name input */}

                                                {showIndividualDetails.individualName && (
                                                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                                                        <Stack style={{width:"50%"}}>
															<InputLabel required style={{fontFamily: "Poppins", fontSize: "15px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal", textAlign: "left", color: "#032541",}} >
																First Name
															</InputLabel>
                                                            <TextField required  placeholder={"First Name"} onChange={getCustomerPersonalDetailsInputValue} name="customerFirstName" id="customerFirstName" value={customerDetails.customerFirstName} variant="outlined" style={{width:"100%",height:"3.125rem"}} />
                                                            {!customerDetails.customerFirstName && (
                                                                <FormHelperText error>This field is required</FormHelperText>
                                                            )}
                                                        </Stack>

                                                        <Stack style={{width:"50%"}}>
															<InputLabel required style={{fontFamily: "Poppins", fontSize: "15px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal", textAlign: "left", color: "#032541",}} >
																Last Name
															</InputLabel>
                                                            <TextField required  placeholder={"Last Name"} onChange={getCustomerPersonalDetailsInputValue} name="customerLastName" id="customerLastName" value={customerDetails.customerLastName} variant="outlined" style={{width:"100%",height:"3.125rem"}} />
                                                            {!customerDetails.customerLastName && (
                                                                <FormHelperText error>This field is required</FormHelperText>
                                                            )}
                                                        </Stack>
                                                    </Stack>
                                                )}

                                                {/* company input */}
                                                {showCompanyDetails.companyName && (
                                                    <Stack>
														<span style={{fontFamily: "Poppins", fontSize: "15px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal", textAlign: "left", color: "#032541",}} >
															Company Name
														</span>
                                                        <input style={{padding: "7px", objectFit: "contain", borderRadius: "12px", border: "2px solid #f1f1f1", outline: "none", width: "100%",}} required type="text" placeholder="Enter company name here" onChange={getCustomerPersonalDetailsInputValue} name="companyName" id="companyName" value={customerDetails.companyName}/>
                                                    </Stack>
                                                )}
                                            </Grid>
                                        </Grid>

                                        <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{margin: "0 0 20px 0"}}>
                                            <Grid item xs={4}>
                                                <Grid item >
                                                    {/*<Stack>*/}
													{/*<span style={{fontFamily: "Poppins", fontSize: "15px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal", textAlign: "left", color: "#032541",}}>*/}
													{/*	Payment Type*/}
													{/*</span>*/}
                                                    {/*    /!*<select style={{padding: "7px", objectFit: "contain", borderRadius: "12px", border: "2px solid #f1f1f1", outline: "none", width: "100%",}} onChange={getCustomerPersonalDetailsInputValue} value={customerDetails.paymentType} name="paymentType" id="paymentType">*!/*/}
                                                    {/*    /!*    <option value="Prepaid">Prepaid</option>*!/*/}
                                                    {/*    /!*</select>*!/*/}
                                                    {/*    <Select*/}
                                                    {/*        labelId="demo-simple-select-label"*/}
                                                    {/*        id="demo-simple-select"*/}
                                                    {/*        value={customerDetails.paymentType}*/}
                                                    {/*        label="Payment Type"*/}
                                                    {/*        required*/}
                                                    {/*        onChange={getCustomerPersonalDetailsInputValue}*/}
                                                    {/*        style={{*/}
                                                    {/*            width:"100%"*/}
                                                    {/*        }}*/}
                                                    {/*        disabled*/}
                                                    {/*    >*/}
                                                    {/*        <MenuItem value={"Prepaid"} >Prepaid</MenuItem>*/}
                                                    {/*    </Select>*/}
                                                    {/*</Stack>*/}
                                                    <Stack>
													<InputLabel required style={{fontFamily: "Poppins", fontSize: "15px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal", textAlign: "left", color: "#032541",}}>
														Physical Address
													</InputLabel>
                                                        <TextField required  placeholder={"Physical Address"} onChange={getCustomerPersonalDetailsInputValue} name="customerAddress" id="customerAddress" value={customerDetails.customerAddress} variant="outlined" style={{width:"100%",height:"3.125rem"}} />
                                                        {!customerDetails.customerAddress && (
                                                            <FormHelperText error>This field is required</FormHelperText>
                                                        )}
                                                    </Stack>
                                                </Grid>

                                                <Grid item>
                                                    {showCompanyDetails.companyAdress && (<Stack>
													<span style={{fontFamily: "Poppins", fontSize: "15px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal", textAlign: "left", color: "#032541",}} >
														Physical Address
													</span>
                                                        <input style={{padding: "7px", objectFit: "contain", borderRadius: "12px", border: "2px solid #f1f1f1", outline: "none", width: "100%",}} required type="text" placeholder="Physical Adress" onChange={getCustomerPersonalDetailsInputValue} name="companyAdress" id="companyAdress" value={customerDetails.companyAdress}/>
                                                    </Stack>)}
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={7.5}>

                                                {/* individual phone number */}
                                                {showIndividualDetails.individualPhone && (
                                                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                                                        {showIndividualDetails.individualAdress && (
                                                            <Stack style={{
                                                                width:"50%"
                                                            }}>
																<InputLabel style={{fontFamily: "Poppins", fontSize: "15px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal", textAlign: "left", color: "#032541",}} required >
																	Email
																</InputLabel>
                                                                {/*<input style={{padding: "7px", objectFit: "contain", borderRadius: "12px", border: "2px solid #f1f1f1", outline: "none", width: "100%",}} required type="text" placeholder="Enter customer email" onChange={getCustomerPersonalDetailsInputValue} name="customerEmail" id="customerEmail" value={customerDetails.customerEmail}/>*/}
                                                                <TextField
                                                                    required
                                                                    type={"email"}
                                                                    placeholder={"Email"}
                                                                    onChange={getCustomerPersonalDetailsInputValue}
                                                                    name="customerEmail"
                                                                    id="customerEmail"
                                                                    value={customerDetails.customerEmail}
                                                                    variant="outlined"
                                                                    style={{width:"100%",height:"3.125rem"}}
                                                                />
                                                                {!customerDetails.customerEmail && (
                                                                    <FormHelperText error>This field is required</FormHelperText>
                                                                )}
                                                                {customerDetails.customerEmail && !validateEmail(customerDetails.customerEmail) && (
                                                                    <FormHelperText error>Enter a valid email</FormHelperText>
                                                                )}
                                                            </Stack>)}

                                                        {/* company email */}
                                                        {showCompanyDetails.companyEmail && (<Stack>
															<span style={{fontFamily: "Poppins", fontSize: "15px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal", textAlign: "left", color: "#032541",}} >
																Company Email
															</span>
                                                            <input style={{padding: "7px", objectFit: "contain", borderRadius: "12px", border: "2px solid #f1f1f1", outline: "none", width: "100%",}} required type="text" placeholder="Enter company email" onChange={getCustomerPersonalDetailsInputValue} name="companyEmail" id="companyEmail" value={customerDetails.companyEmail}/>
                                                        </Stack>)}

                                                        <Stack style={{
                                                            width:"50%"
                                                        }}>
															<InputLabel required style={{fontFamily: "Poppins", fontSize: "15px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal", textAlign: "left", color: "#032541",}} >
																Mobile Number
															</InputLabel>
                                                            {/*<input style={{padding: "7px", objectFit: "contain", borderRadius: "12px", border: "2px solid #f1f1f1", outline: "none", width: "100%",}} required type="text" placeholder="last name" onChange={getCustomerPersonalDetailsInputValue} name="customerPhoneNo" id="customerPhoneNo" value={customerDetails.customerPhoneNo}/>*/}
                                                            <TextField required  placeholder={"Mobile Number"} onChange={getCustomerPersonalDetailsInputValue} name="customerPhoneNo" id="customerPhoneNo" value={customerDetails.customerPhoneNo} variant="outlined" style={{width:"100%",height:"3.125rem"}} />
                                                            {!customerDetails.customerPhoneNo && (
                                                                <FormHelperText error>This field is required</FormHelperText>
                                                            )}
                                                        </Stack>

                                                        {showCompanyDetails.companyAdress && (<Stack>
															<span style={{fontFamily: "Poppins", fontSize: "15px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal", textAlign: "left", color: "#032541",}} >
																Physical Address
															</span>
                                                            <input style={{padding: "7px", objectFit: "contain", borderRadius: "12px", border: "2px solid #f1f1f1", outline: "none", width: "100%",}} required type="text" placeholder="Physical Adress" onChange={getCustomerPersonalDetailsInputValue} name="companyAdress" id="companyAdress" value={customerDetails.companyAdress}/>
                                                        </Stack>)}
                                                    </Stack>
                                                )}

                                                {/* company phoneNumber */}
                                                {showCompanyDetails.companyPhone && (<Stack>
													<span style={{fontFamily: "Poppins", fontSize: "15px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal", textAlign: "left", color: "#032541",}} >
														Company Phone Number
													</span>

                                                    <Stack>
														<span style={{fontFamily: "Poppins", fontSize: "15px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal", textAlign: "left", color: "#032541",}} >
															Phone Number
														</span>

                                                        <input style={{padding: "7px", borderRadius: "12px", border: "2px solid #f1f1f1", outline: "none", }} required type="text" placeholder="eg (07013458762)" onChange={getCustomerPersonalDetailsInputValue} name="customerPhoneNo" id="customerPhoneNo" value={customerDetails.customerPhoneNo}/>
                                                    </Stack>
                                                </Stack>)}
                                            </Grid>
                                        </Grid>

                                        <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{margin: "0 0 30px 0"}}>
                                            <Grid item xs={4}>

                                                {/* credit payment Instruments */}
                                                {paymentInstruments.credit && (<Stack>
													<span style={{fontFamily: "Poppins", fontSize: "15px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal", textAlign: "left", color: "#032541",}} >
														Payment Instruments
													</span>

                                                    <select style={{padding: "7px", objectFit: "contain", borderRadius: "12px", border: "2px solid #f1f1f1", outline: "none", width: "100%",}} onChange={getCustomerPersonalDetailsInputValue} name="creditPaymentInstruments" id="creditPaymentInstruments" value={customerDetails.creditPaymentInstruments}>
                                                        <option>Payment Instruments</option>
                                                        <option value="contract">Contract</option>
                                                        <option value="purchaseorder">
                                                            Purchase Order
                                                        </option>
                                                    </select>
                                                </Stack>)}

                                                {/* prepaid payment instruments */}
                                                {paymentInstruments.prepaid && (<Stack>
													<span style={{fontFamily: "Poppins", fontSize: "15px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal", textAlign: "left", color: "#032541",}} >
														Payment Instruments
													</span>
                                                    <select style={{padding: "7px", objectFit: "contain", borderRadius: "12px", border: "2px solid #f1f1f1", outline: "none", width: "100%",}} onChange={getCustomerPersonalDetailsInputValue} name="prepaidPaymentInstruments" id="prepaidPaymentInstruments" value={customerDetails.prepaidPaymentInstruments}>
                                                        <option>Payment Instruments</option>
                                                        <option value="mobilemoney">
                                                            Mobile Money
                                                        </option>
                                                        <option value="cheque">Cheque</option>
                                                        <option value="cash">Cash</option>
                                                    </select>
                                                </Stack>)}
                                            </Grid>

                                            <Grid item xs={7.5}>

                                                {/* LIMIT TO SPEND */}
                                                {paymentInstruments.limit && (<Stack>
													<span style={{fontFamily: "Poppins", fontSize: "15px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal", textAlign: "left", color: "#032541",}} >
														Limit
													</span>
                                                    <input type="text" name="limit" id="limit" placeholder="Limit to spend" style={{padding: "7px", objectFit: "contain", borderRadius: "12px", border: "2px solid #f1f1f1", outline: "none", width: "100%",}} required value={customerDetails.limit} onChange={getCustomerPersonalDetailsInputValue}/>
                                                </Stack>)}

                                                {/* AMT RECEIVED */}
                                                {paymentInstruments.amountRecieved && (<Stack>
													<span style={{fontFamily: "Poppins", fontSize: "15px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal", textAlign: "left", color: "#032541",}} >
														Deposit Received
													</span>
                                                    <input type="text" name="amountRecieved" id="amountRecieved" placeholder="amountrecieved" style={{padding: "7px", objectFit: "contain", borderRadius: "12px", border: "2px solid #f1f1f1", outline: "none", width: "100%",}} required onChange={getCustomerPersonalDetailsInputValue} value={customerDetails.amountRecieved}/>
                                                </Stack>)}
                                            </Grid>
                                        </Grid>

                                        <div style={{
                                            width:"100%",
                                            display:"flex",
                                            justifyContent:"center"
                                        }}>

                                            <Button style={{border: "solid 1px #0c253f", color: "#0c253f",margin:"1.25rem", marginTop:"0", width: "190px",height: "45px",borderRadius:"5px" }} onClick={handleClose}>
                                                Back
                                            </Button>
                                            <Button disabled={allValid} style={{backgroundColor: "#032541", color: "#ffffff", margin:"1.25rem",marginTop:"0", width: "190px",height: "45px", borderRadius:"5px"}} onClick={(e) => setTabValue("AdditionalInfo")}>
                                                Next
                                            </Button>
                                        </div>

                                        {/*<Grid container direction="row" justifyContent="center" alignItems="center" style={{margin: "0 0 10px 0"}}>*/}
                                        {/*    <Grid item xs="5">*/}
                                        {/*        <Button style={{border: "solid 1px #dc3545", color: "#dc3545", padding: "9px 28px 8.6px 29px",}} onClick={handleClose}>*/}
                                        {/*            Back*/}
                                        {/*        </Button>*/}
                                        {/*    </Grid>*/}

                                        {/*    <Grid item xs="5">*/}
                                        {/*        <Button style={{backgroundColor: "#032541", color: "#ffffff", padding: "9px 40px 8.6px 41px",}} onClick={(e) => setTabValue("AdditionalInfo")}>*/}
                                        {/*            Next*/}
                                        {/*        </Button>*/}
                                        {/*    </Grid>*/}
                                        {/*</Grid>*/}
                                    </Box>
                                </TabPanel>

                                {/*---- end personal details -----*/}

                                {/*---- start additional info*/}

                                <TabPanel value="AdditionalInfo">
                                    {studentsToSave && studentsToSave.length > 0?(
                                       <>
                                        <Table className={classes.table} aria-label="students data grid">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Student ID</TableCell>
                                                    <TableCell>Student Name</TableCell>
                                                    <TableCell>DOB</TableCell>
                                                    <TableCell>Relationship</TableCell>
                                                    <TableCell>Grade</TableCell>
                                                    <TableCell>Term</TableCell>
                                                    <TableCell>Additional Info</TableCell>
                                                    <TableCell>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {studentsToSave.map((student) => (
                                                    <TableRow key={student.studentId}>
                                                        <TableCell>{student.studentId}</TableCell>
                                                        <TableCell>{student.firstName + ' ' + student.lastName}</TableCell>
                                                        <TableCell>{formatDate(student.dob.$d)}</TableCell>
                                                        <TableCell>{student.relationship}</TableCell>
                                                        <TableCell>{student.grade}</TableCell>
                                                        <TableCell>{student.term}</TableCell>
                                                        <TableCell>{student.additionalInfo === "" ? "N/A" : "Yes"}</TableCell>
                                                        <TableCell>
                                                            <div style={{display:"flex"}}>
                                                                <IconButton onClick={() => editStudent(student)}>
                                                                    <EditIcon style={{color:"#032541"}} />
                                                                </IconButton>
                                                                <IconButton onClick={() => removeStudent(student)}>
                                                                    <DeleteIcon  style={{color:"#dc3545"}}/>
                                                                </IconButton>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                       <TablePagination
                                           rowsPerPageOptions={[10, 25, 100]}
                                           component="div"
                                           count={studentsToSave.length}
                                           rowsPerPage={rowsPerPage}
                                           page={page}
                                           onChangePage={handleChangePage}
                                           onChangeRowsPerPage={handleChangeRowsPerPage}
                                       />
                                        </>
                                    ):(

                                        <div style={{
                                            display:"flex",
                                            justifyContent:"center"
                                        }}>
                                            <div style={{
                                                "fontSize":"18px",
                                                color: "#b4bcc4",
                                                textAlign:"center",
                                                display:"flex",
                                                flexDirection:"column",
                                                justifyContent:"center"
                                            }}>
                                                <div>No students found.</div>
                                                <div> Add students to associate them with their parent/guardian.</div>
                                                <div style={{
                                                    "fontSize":"16px",
                                                    "fontWeight":"bold",
                                                    "color":"#032541",
                                                    marginTop:"10px",
                                                    cursor:"Pointer"
                                                }}
                                                     onClick={()=> {
                                                         setStudentOpen(true)
                                                     }}
                                                ><AddCircleOutlineIcon/> Add Student</div>
                                            </div>
                                        </div>

                                        )}


                                    <div style={{
                                        display:"flex",
                                        justifyContent:"end",
                                        flexDirection:"column"
                                    }}>
                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                            <Grid item>
                                                <Button style={{
                                                    border: "solid 1px #0c253f",
                                                    color: "#0c253f",
                                                    marginRight:"0.625rem",
                                                    marginLeft:"0.625rem",
                                                    marginTop:"6rem",
                                                    height: "45px",
                                                    width: "190px"
                                                }} onClick={(e) => setTabValue("PersonalDetails")}>
                                                    Back
                                                </Button>
                                            </Grid>

                                            <Grid item>
                                                <Button style={{
                                                    backgroundColor: "#032541",
                                                    color: "#ffffff",
                                                    marginRight:"0.625rem",
                                                    marginLeft:"0.625rem",
                                                    marginTop:"6rem",
                                                    height: "45px",
                                                    width: "190px"
                                                }} onClick={(e) => submitData()}
                                                >
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>

                                </TabPanel>

                                {/*---- end additional info----*/}

                            </TabContext>
                        </Box>
                    </Grid>
                </Grid>
                <>
                    <AddStudentModal
                        addStudentOpen={studentOpen}
                        onClose={()=>setStudentOpen(false)}
                        setStudentsToSave={setStudentsToSave}
                        studentsToSave={studentsToSave}
                        firstName={firstName}
                        setFirstName={setFirstName}
                        lastName={lastName}
                        setLastName={setLastName}
                        additionalInfo={additionalInfo}
                        setAdditionalInfo={setAdditionalInfo}
                        relationship={relationship}
                        setRelationship={setRelationship}
                        studentId={studentId}
                        setStudentId={setStudentId}
                        grade={grade}
                        setGrade={setGrade}
                        term={term}
                        setTerm={setTerm}
                        dob={dob}
                        setDob={setDob}
                        updateStudent={updateStudent}
                        isUpdate={isUpdate}
                        setIsUpdate={setIsUpdate}
                        studentServices={studentServices}
                        setStudentServices={setStudentServices}
                        admission={admission}
                        setAdmission={setAdmission}
                    />
                </>
            </Box>
        </Modal>
    )
}

export {AddParentChildren}
