import React, {useEffect, useState} from "react";
import {Box, Button, Grid, Typography} from "@mui/material";
import {SuccessAlert} from "../snackBar Alerts/successAlert";
import {ErrorAlert} from "../snackBar Alerts/errorAlert";
import MpesaIcon from "../School/Images/mpesaImage.svg";
import Switch from "@mui/material/Switch";
import CardIcon from "../School/Images/cardIcon.svg";
import BankIcon from "../School/Images/bankImage.svg";
import DarajaSetUp from "../School/DarajaSetUp";
import BankSetup from "../School/BankSetup";
import HttpComponent from "../School/MakeRequest";
import SetWorkFlow from "../Rental/Utility/updateSetupWorkFlow";
import {useSelector} from "react-redux";
import BankPayBill from "../School/Images/bank-paybill.svg";
import BankPayBillSetUp from "../School/bankPaybillSetup";
import cashIcon from "../School/Images/cashicon.svg";import { ShowChartSharp } from "@material-ui/icons";
import CardSetup from "../School/cardSetup";
;




// styling
const completeStyle = {
	"width": "7.813rem",
	"height": "2.813rem",
	"borderRadius": "4px",
	"backgroundColor": "#17ae7b",
	color:"white",
	'&:hover': {
		backgroundColor: '#17ae7b',
		color: 'white'
	}
}

export default function PaymentSetup (props) {
	try {

		const [showDarajaSetup, setShowDarajaSetup] = useState(false)
		const [showBankPaybillSetup, setShowBankPaybillSetup] = useState(false)
		const [showBankSetup, setShowBankSetup] = useState(false)
		const [showCardSetup, setCardSetup] = useState(false)

		const [mpesaChecked, setMpesaChecked] = useState(false);
		const [bankpayBillChecked, setbankpayBillChecked] = useState(false);
		const [cardChecked, setCardChecked] = useState(false);



		const [bankChecked, setBankChecked] = useState(false);
		const [bankpaybillStatus, sebankpaybillStatus] = useState(false)


		// status
		const [mpesaStatus, setmpesaStatus]= useState(false)
		const [cardStatus, setcardStatus]= useState(false)
		const [paybillStatus, setpaybillStatus]= useState(false)
		const [cashStatus, setcashStatus]= useState(false)
		const [banks, setbanks]= useState(false)

		// Notification
		const [errorShow, setErrorShow] = useState({state: false, message: ""})
		const [successShow, setSuccessShow] = useState({state: false, message: ""})

		function handleNext(){
			SetWorkFlow({flowName : 'TENANTS', token : X_Authorization} )
			setSuccessShow({state:false, message:""})
			props.next()
		}

		const handleCard = () =>{
			setCardChecked(!cardChecked)
			let state;
			if(!cardChecked){
				state = true;
			}else if(cardChecked){
				state = false;
			}
			HttpComponent({
				method: 'POST',
				url: `/api/activate_card?status=${state}`,
				body: null,
				token: X_Authorization
			}).then((data)=>{
				console.log("here store is data",data);
				if(data.status === 201){
					setSuccessShow({
						state:true,
						message:state == true ? "Card Added Successfully" : "Card Removed Successfully"
					})
				}else{
					console.error("Error setting info")
					setErrorShow({state:true, message:data.response.message})
				}
			}).catch((error)=>{
				console.error(error.message);
			})
		}

		const { userId ,X_Authorization } = useSelector((store) => store.user);
		const [fromBackend,setFromBackend] = useState(false);

		const checkStatus = () =>{
			HttpComponent({
				method: 'GET',
				url: `/api/get_payment_methods_with_status`,
				body: null,
				token: X_Authorization
			}).then((data)=>{
				console.log("here store is data",data);
				if(data.status === 201){
					const mpesaStatus = data.response.data.find(method => method.name === "Mpesa").status;
					const cardStatus = data.response.data.find(method => method.name === "Card").status;
					const paybillStatus = data.response.data.find(method => method.name === "Bank Paybill").status;
					// const banks = data.response.data.find(method => method.name === "Banks").status;



					setFromBackend(mpesaStatus)
					// setMpesaChecked(mpesaStatus)
					// setbankpayBillChecked(paybillStatus)
					setmpesaStatus(mpesaStatus)

					// setcardStatus(cardStatus)
					// setbankpayBillChecked(paybillStatus)
					sebankpaybillStatus(paybillStatus)
					setcardStatus(cardStatus)

				}else{
					console.error("Error setting info")
					setErrorShow({state:true, message:data.response.message})
				}
			}).catch((error)=>{
				console.error(error.message);
			})
		}

		useEffect(() => {
			checkStatus()
		}, []);


		// useEffects
		useEffect(() => {
			if(showDarajaSetup){
				setShowBankSetup(false)
			}
			if(showBankSetup){
				setShowDarajaSetup(false)
				
			}
			if(showBankPaybillSetup){
				setShowBankPaybillSetup(true)
				setShowDarajaSetup(false)
			}
			if(showCardSetup){
				setCardSetup(true)
				setShowDarajaSetup(false)
			}
		
		}, [showBankSetup,showCardSetup, showDarajaSetup]);

		useEffect(() => {
			if(mpesaChecked){
				if(bankpayBillChecked){
					setbankpayBillChecked(false)
					setMpesaChecked(false)
					setShowDarajaSetup(false)
					setCardSetup(false)
				}

				if(cardChecked){
					setbankpayBillChecked(false)
					setMpesaChecked(false)
					setShowDarajaSetup(false)
					setCardSetup(false)
					setCardChecked(false)
				}
			}
		},[mpesaChecked,cardChecked, bankpayBillChecked])

		useEffect(() => {
			if(mpesaChecked && !fromBackend){
				setShowDarajaSetup(true)
			}else{
				setShowDarajaSetup(false)
			}
		}, [mpesaChecked]);

		useEffect(() => {
			if(bankChecked){
				setShowBankSetup(true)
			}else{
				setShowBankSetup(false)
			}
		}, [bankChecked]);

		useEffect(() => {
			if(bankpayBillChecked){
				setShowBankPaybillSetup(true)
				setCardSetup(false)
				setbankpayBillChecked(true)


				setShowDarajaSetup(false)
				
			}else{
				setShowBankPaybillSetup(false)
				setbankpayBillChecked(false)


				
			}
		},[bankpayBillChecked])
		useEffect(() => {
			if(cardChecked){
				setCardSetup(true)
				setCardChecked(true)
				setShowDarajaSetup(false)
				setShowBankPaybillSetup(false)
				setbankpayBillChecked(false)


				
			}else{
				setCardSetup(false)
				setCardChecked(false)


				
			}
		},[cardChecked])

		useEffect(() => {
			if((showBankPaybillSetup && !showDarajaSetup) || (showBankPaybillSetup && !showCardSetup)  ){
				setShowBankPaybillSetup(false)
				// setShowDarajaSetup(true)
				setCardSetup(false)
			}

			// if((showCardSetup && !showDarajaSetup) && (!showBankPaybillSetup && showCardSetup)   ){
			// 	setShowBankSetup(false)
			// 	setCardSetup(true)
				

			// }
		},[mpesaChecked,cardChecked, bankpayBillChecked])


		console.log("showCardSetup", showCardSetup, "setCardChecked => ", cardChecked);

		return (
			<div style={{fontFamily : 'Poppins'}} >

				{/*Notifications*/}
				<SuccessAlert vertical="top" horizontal="right" onClose={()=>setSuccessShow({...successShow, state:false})} open={successShow.state} message={successShow.message}/>
				<ErrorAlert vertical="top" horizontal="right" onClose={()=>setErrorShow({...errorShow, state:false})} open={errorShow.state} message={errorShow.message}/>

				{/*Payment*/}
				<Grid container justifycontent={'space-between'} direction={'row'} spacing={5}>

					{/*payment options*/}
					<Grid item style={{width : '40%'}} ml={3}>
						<Grid container justifyContent={'space-between'} direction={'column'} spacing={4}>

							{/*mpesa*/}
							<Grid item style={{border:"solid 1px rgba(112, 112, 112, 0.15)", display : 'flex', borderRadius : '6px', margin : 5, padding : 0.5}}>
								<Grid container direction={'row'} justifyContent={'space-between'} style={{padding : 2, margin : 1}}>

									{/*Image and Name*/}
									<Grid item style={{padding : '5px'}}>
										<img style={{marginRight : '10px'}} src={MpesaIcon} alt=""/>
										<span style={{color : '#032541', fontSize : "14px"}}>M-Pesa</span>
									</Grid>

									{/*Switch*/}
									<Grid item style={{padding : '10px'}}>
										<div>
											<span style={{color:mpesaStatus ? "#17ae7b": "#707070"}}>{mpesaStatus ? "Active" : "Not Set"}</span>
											<Switch color="error" style={{color : '#fff'}}  checked={mpesaChecked} onChange={()=>{setMpesaChecked(!mpesaChecked); localStorage.setItem('exSetup', !mpesaChecked)}} inputProps={{ 'aria-label': 'controlled' }}/>
										</div>
									</Grid>
								</Grid>
							</Grid>
							{/*BankPayBill*/}
							<Grid item style={{border:"solid 1px rgba(112, 112, 112, 0.15)", display : 'flex', borderRadius : '6px', margin : 5, padding : 0.5}}>
								<Grid container direction={'row'} justifyContent={'space-between'} style={{padding : 2, margin : 1}}>

									{/*Image and Name*/}
									<Grid item style={{padding : '5px'}}>
										<img style={{marginRight : '10px'}} src={BankPayBill} alt=""/>
										<span style={{color : '#032541', fontSize : "14px"}}>Bank Paybill</span>
									</Grid>

									{/*Switch*/}
									<Grid item style={{padding : '10px'}}>
										<div>
										<span style={{color:bankpaybillStatus ? "#17ae7b": "#707070"}}>{bankpaybillStatus ? "Active" : "Not Set"}</span>
											<Switch color="error" style={{color : '#fff'}}  checked={bankpayBillChecked} onChange={()=>{setbankpayBillChecked(!bankpayBillChecked); localStorage.setItem('bankpayBillChecked', !bankpayBillChecked)}} inputProps={{ 'aria-label': 'controlled' }}/>
										</div>
									</Grid>
								</Grid>
							</Grid>



							{/*card*/}
							<Grid item style={{border:"solid 1px rgba(112, 112, 112, 0.15)", display : 'flex', borderRadius : '6px', margin : 5, padding : 0.5}}>
								<Grid container direction={'row'} justifyContent={'space-between'} style={{padding : 2, margin : 1}}>

									{/*Image and Name*/}
									<Grid item style={{padding : '5px'}}>
										<img style={{marginRight : '10px'}} src={CardIcon} alt=""/>
										<span style={{color : '#032541', fontSize : "14px"}}>Card </span>
									</Grid>

									{/*Switch*/}
									<Grid item style={{padding : '10px'}}>
										<div>
											<span style={{color:cardStatus ? "#17ae7b": "#707070"}}>{cardStatus ? "Active" : "Not Set"}</span>
											<Switch color="error" style={{color : '#fff'}}  checked={cardChecked} onChange={()=>{setCardChecked(!cardChecked); localStorage.setItem('cardChecked', !cardChecked)}} inputProps={{ 'aria-label': 'controlled' }}/>
										</div>
									</Grid>
								</Grid>
							</Grid>

							{/*bank*/}
							<Grid item style={{border:"solid 1px rgba(112, 112, 112, 0.15)", display : 'flex', borderRadius : '6px', margin : 5, padding : 0.5}}>
								<Grid container direction={'row'} justifyContent={'space-between'} style={{padding : 2, margin : 1}}>

									{/*Image and Name*/}
									<Grid item style={{padding : '5px'}}>
										<img style={{marginRight : '10px'}} src={BankIcon} alt=""/>
										<span style={{color : '#032541', fontSize : "14px"}}>Bank <span>coming soon</span></span>
									</Grid>

									{/*Switch*/}
									<Grid item style={{padding : '10px'}}>
										<div>
											<span>Inactive </span>
											<Switch disabled color="error" style={{color : '#fff'}} checked={bankChecked} onChange={()=>setBankChecked(!bankChecked)} inputProps={{ 'aria-label': 'controlled' }}/>
										</div>
									</Grid>
								</Grid>
							</Grid>

							{/* cash */}
							<Grid item style={{border:"solid 1px rgba(112, 112, 112, 0.15)", display : 'flex', borderRadius : '6px', margin : 5, padding : 0.5}}>
								<Grid container direction={'row'} justifyContent={'space-between'} style={{padding : 2, margin : 1}}>

									{/*Image and Name*/}
									<Grid item style={{padding : '5px'}}>
										<img style={{marginRight : '10px'}} src={cashIcon} alt=""/>
										<span style={{color : '#032541', fontSize : "14px"}}>Cash -<span>coming soon</span></span>
									</Grid>

									{/*Switch*/}
									<Grid item style={{padding : '10px'}}>
										<div>
										<span>Inactive  </span>
											<Switch disabled color="error" style={{color : '#fff'}} checked={bankChecked} onChange={()=>setBankChecked(!bankChecked)} inputProps={{ 'aria-label': 'controlled' }}/>
										</div>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					{/*Setup panel*/}
					<Grid item mt={-6} style={{width : '57%'}}>
						{showDarajaSetup && <DarajaSetUp setSuccessShow={setSuccessShow}  setErrorShow={setErrorShow} setShowDarajaSetup={setShowDarajaSetup}/>}
						{showBankPaybillSetup && <BankPayBillSetUp />}
						{showCardSetup && <CardSetup/>}

					</Grid>

				</Grid>
			</div>
		)
	} catch (e) {
		console.log(`Error on PaymentSetup`, e.message)
	}
}
