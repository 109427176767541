import { Grid, Breadcrumbs, Typography, Button, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HttpComponent from "../../../School/MakeRequest";
import jwt_decode from "jwt-decode";

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Zed-Payit</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Terminals</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Create Terminal</Typography>
]

export default function CreateNewTerminal() {

    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [outlets, setOutlets] = useState([])
    const [deviceModels, setDeviceModels] = useState([])

    const [formData, setFormData] = useState({ serialNo: "", simCardNo: "", deviceModel: "", outletName: "" })

    const handleOnChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const token = localStorage.getItem('X-Authorization')
    const decoded = jwt_decode(token);
    const branchId = decoded?.branchId

    //fetch outlets
    function getOutlets() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/list_all_stores_by_type_status?storeType=outlet&status=ACTIVE&page&limit=`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setOutlets(data?.response?.data)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getOutlets()
    }, [])

    //fetch outlets
    function getDeviceModel() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/getBusinessModels`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setDeviceModels(data?.response?.data)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getDeviceModel()
    }, [])

   

    // formdata to submit

    const submitData = {
        terminalSerialNumber: formData.serialNo,
        deviceModel: formData.deviceModel,
        simCardNumber: formData.simCardNo,
        storeId: formData.outletName,
        assignedTo: "outlet",
        branchId:branchId,
        till_paybill:""
    }

    //navigate user back after creating terminal

    const navigate = useNavigate()

    //create terminal

    function handleSubmit() {
        try {
            HttpComponent({
                method: "POST",
                body: submitData,
                url: `/api/createTerminal`,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data.status === 201) {
                    setSuccessShow({ state: true, message: "Terminal created successfully" })
                } else {
                    setErrorShow({ state: true, message: 'Error creating terminal' })
                }
                setTimeout(() => {
                    navigate(-1)
                }, [2000])
            })
        } catch (error) {

        }

    }

    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Create Terminal</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2}>
                <Typography style={{ fontWeight: 400, fontSize: "18px", color: "#032541" }}>Terminal Details</Typography>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='serialNo' onChange={handleOnChange} style={{ width: "100%" }} value={formData.serialNo} label="Serial No" variant="outlined" />
                    </Grid>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='simCardNo' onChange={handleOnChange} style={{ width: "100%" }} value={formData.simCardNo} label="Sim Card No" variant="outlined" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={3} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "50%" }}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">Device Model</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData?.deviceModel}
                                label="Device Model"
                                onChange={handleOnChange}
                                name="deviceModel"
                            >
                                {deviceModels?.map((item) => (
                                    <MenuItem name="outlet" key={item?._id} value={item?._id}>{item?.deviceName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item style={{ width: "50%" }}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">Outlet</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData?.outletName}
                                label="Outlet"
                                onChange={handleOnChange}
                                name="outletName"
                            >
                                {outlets?.map((item) => (
                                    <MenuItem name="outlet" key={item?._id} value={item?._id}>{item?.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid mt={3} spacing={2} item style={{ width: "49%" }} display={'flex'} justifyContent={'flex-end'}>
                <Button style={{ background: "#fff", color: "#DC3545", border: "1px solid #DC3545", width: "125px", marginRight: "5px", textTransform: "inherit" }}>Cancel</Button>
                <Button onClick={handleSubmit} style={!formData.serialNo || !formData.deviceModel || !formData.outletName || !formData.simCardNo ? { opacity: 0.4, width: "125px", background: "#032541", textTransform: "inherit", } : { background: "#032541", textTransform: "inherit", width: "125px", color: "#fff", opacity: 1 }}>Submit</Button>
            </Grid>
        </Grid>
    )
}