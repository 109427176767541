import { Box, Button, Select, TextField, Typography, MenuItem } from "@mui/material";
import React, { useEffect, useState } from 'react';
import happy from "./Images/happy-icn.svg"
import { useDispatch, useSelector } from "react-redux";
import { addService, clearServicesToAdd, setCategories } from "../../features/servicesSlice";
import HttpComponent from "./MakeRequest";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import CustomInputLabel from "./CustomInputLabel";
import CustomTextField from "./CustomTextField";
import CustomSelectField from "./CustomSelectField";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
let baseUrl = process.env.REACT_APP_BASE_URL;

const SchoolAddService = () => {
    const buttonWidthHeight = {
        width: "9.063rem",
        height: "3.125rem",
        '&:hover': {
            backgroundColor: '#032541',
            color: 'white'
        }
    }
    const [gradeOptions, setGradeOptions] = useState([])

    const [streams, setStreames] = useState([]);
    const [termOptions, setTermOptions] = useState([]);

    const { schoolTypeName, schoolTypeId } = useSelector((store) => store.schoolType.schoolTypeDetail)

    console.log('School Type Id', schoolTypeId);
    const GetGrades = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeId}`, {
            headers: { 
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data, "Grades");

        if (response.status === 201) {
            setGradeOptions(data.data.map((itemGrade) => {
                return { value: itemGrade.schoolGrades, label: itemGrade.schoolGrades }
            }
            ))
        }
    };
    const [coursesOptions, setCoursesOptions] = useState([]);

    const getCourses = async () => {
        const response = await fetch(`${baseUrl}/api/v1/get_school_courses`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
            method: "GET",
        });
        const data = await response.json();
        console.log(data, "Get Courses");
        if (response.status === 200) {
            // setCoursesOptions(data.data);
            setCoursesOptions(data.data.map((courses) => {
                return { value: courses.courseName, label: courses.courseName }
            }
            ))
        }
    };

    useEffect(() => {
        getCourses();
    }, []);


    const GetStreamsTerms = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_terms?schoolType_id=${schoolTypeId}`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data, "Streams");
        if (response.status === 201) {
            setTermOptions(data.data.map((terms) => {
                return { value: terms.schoolGrades, label: terms.schoolGrades }
            }
            ))
        }
    };
    useEffect(() => {
        if (schoolTypeId) {
            GetGrades();
            GetStreamsTerms();
        }

    }, [schoolTypeId]);



    const cancelButtonStyles =
    {
        "width": "7.813rem",
        "height": "2.813rem",
        "padding": "12.5px 36.7px 12.5px 38.3px",
        "borderRadius": "4px",
        "border": "solid 1px #dc3545",
        "color": "#dc3545"
    }

    const buttonStyles =
    {
        "width": "7.813rem",
        "height": "2.813rem",
        "borderRadius": "4px",
    }

    const [formData, setFormData] = useState({
        serviceName: "",
        priceType: "Fixed",
        categoryId: "",
        amount: "",
        description: "",
        grade: "",
        term: ""
    })
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const dispatch = useDispatch();

    // const addToList = () =>{
    //     dispatch(addService(formData))
    //     setFormData({
    //         serviceName: "",
    //         categoryId:"",
    //         amount:"",
    //         description:"",
    //         priceType:"Fixed",
    //     })
    //     setSuccessShow({state:true, message:"Service Added"})
    // }

    const navigate = useNavigate()

    const submit = () => {
        HttpComponent({
            method: 'POST',
            url: '/api/add_services',
            body: { services: [formData] },
            token: X_Authorization
        }).then((data) => {
            console.log("here store is data", data);
            if (data.status === 201) {
                navigate("/products")
            } else {
                console.error("Error setting info")
                setErrorShow({ state: true, message: data.response.message })
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }


    const { userId, X_Authorization } = useSelector((store) => store.user);

    const [errorShow, setErrorShow] = useState({ state: false, message: "" })

    const [options, setOptions] = useState([]);

    const fetchCategories = () => {
        HttpComponent({
            method: 'GET',
            url: '/api/listCategories/Active',
            body: null,
            token: X_Authorization
        }).then((data) => {
            console.log("here store is data", data);
            if (data.status === 200) {
                const mappedData = data.response.data.map(({ categoryName, _id }) => ({ label: categoryName, value: _id }));
                console.log("mapped complete", mappedData)
                setOptions(mappedData)
                dispatch(setCategories(mappedData));
            } else {
                console.error("Error setting info")
                setErrorShow({ state: true, message: data.response.message })
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }

    useEffect(() => {
        fetchCategories()
    }, []);
    return (
        <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 1 }}>
            <ErrorAlert
                vertical="top"
                horizontal="right"
                onClose={() => setErrorShow({ ...errorShow, state: false })}
                open={errorShow.state}
                message={errorShow.message} />
            <SuccessAlert
                vertical="top"
                horizontal="right"
                onClose={() => setSuccessShow({ ...successShow, state: false })}
                open={successShow.state}
                message={successShow.message}
            />
            <Box sx={{ marginBottom: 4, display: "flex" }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <ArrowBackIosIcon sx={{ cursor: "pointer" }} onClick={() => navigate("/products")} />
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography sx={{ color: "#032541", fontSize: "1.563rem", fontWeight: 700 }}>
                        Services
                    </Typography>
                </Box>

            </Box>


            <Box component="div" sx={{ display: "flex" }}>
                <Box>
                    <CustomInputLabel required={true} label={"Service Name"} />
                    <CustomTextField value={formData.serviceName} onChange={handleInputChange} name={"serviceName"} placeholder={"Service Name"} />

                    <CustomInputLabel required={true} label={"Amount Type"} />
                    {/*maybe be a disabled select or text field we will see*/}
                    <CustomTextField value={formData.priceType} name={"priceType"} />
                </Box>


                <Box component="div" sx={{ marginLeft: 1 }}>
                    <CustomInputLabel required={true} label={"Service Category"} />
                    {/*this now will have to be a select*/}
                    <CustomSelectField value={formData.categoryId} onChange={handleInputChange} name={"categoryId"} placeholder={"Select Service Category"} options={options} medium />
                    {/*<CustomTextField value={formData.serviceCategory} onChange={handleInputChange} name={"serviceCategory"} placeholder={"Service Category"} />*/}

                    <CustomInputLabel required={true} label={"Amount"} />
                    <CustomTextField value={formData.amount} onChange={handleInputChange} name={"amount"} placeholder={"Amount"} />
                </Box>
            </Box>

            <Box sx={{ display: "flex" }}>
                <Box component="div" sx={{}}>
                    <CustomInputLabel required={true} label={schoolTypeName.includes("University") ? "Course" :"Grade"} />
                    {schoolTypeName.includes("University") ? (
                        <CustomSelectField medium value={formData.grade} onChange={handleInputChange} name={"grade"} placeholder={"Select Course"} options={coursesOptions} />
                    ) :
                        <CustomSelectField value={formData.grade} onChange={handleInputChange} name={"grade"} placeholder={"Select Grade"} options={gradeOptions} />
                    }

                </Box>
                <Box component="div" sx={{ marginLeft: 1 }}>
                    <CustomInputLabel required={true} label={schoolTypeName.includes("University") ? "Semester" : "Term"} />
                    <CustomSelectField medium value={formData.term} onChange={handleInputChange} name={"term"} placeholder={schoolTypeName.includes("University") ? "Select Semester" : "Select Term"} options={termOptions} />
                </Box>
            </Box>

            <Box>
                <CustomInputLabel required={false} label={"Service Description"} />
                <TextField
                    name={"description"}
                    value={formData.description}
                    onChange={handleInputChange}
                    placeholder={"Service Description"}
                    variant="outlined"
                    margin="dense"
                    sx={{ marginBottom: 2 }}
                    multiline
                    minRows={3}
                    InputProps={{
                        sx: {
                            width: '51rem',
                            fontSize: "0.875rem",
                            "& fieldset": {
                                borderColor: "#bec5d1"
                            }
                        }
                    }}
                />
            </Box>

            <Box component="div" sx={{ width: "70%", display: "flex", justifyContent: "end" }}>
                <Box component="div">

                    <Button sx={{
                        ...buttonStyles,
                        border: " solid 1px #002543",
                        color: "#002543",
                        '&:hover': {
                            backgroundColor: "transparent",
                            color: '#002543'
                        }
                    }}
                        onClick={submit}
                        disabled={!formData.serviceName.trim() || !formData.categoryId.trim() || !formData.amount.trim()}
                    >
                        Save
                    </Button>

                </Box>

            </Box>
        </Box>
    );
};

export default SchoolAddService;

