import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
// import Dash from "../../common/dash";
// import user from "../../common/images/user-icon.png";
// import bell from "../../common/images/bell-icon.png";
// import logout from "../../common/images/logout-icon.png";
// import logoutuser from "../logout/logout";
import {
    Grid,
    Button,
    Tab,
    styled,
    Box,
    TextField,
    InputAdornment, Breadcrumbs,
} from "@mui/material";
import { Add, Search } from "@mui/icons-material";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const AntTabs = styled(TabList)({
    borderBottom: "0px solid #e8e8e8",
    "& .MuiTabs-indicator": {
        backgroundColor: "#ffffff00",
    },
});

const breadcrumbs = [
    <Typography key="X" sx={{color:"#707070",fontSize:"0.875rem"}}>
        Dashboard
    </Typography>,
    <Typography key={"add"} sx={{color:"#dc3545", fontSize:"0.875rem"}}>
        Receipts
    </Typography>
];

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        // textTransform: "none",
        minWidth: 0,
        [theme.breakpoints.up("sm")]: {
            minWidth: 0,
        },
        // fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        // fontSize: "13px",
        // fontStretch: "normal",
        // fontStyle: "normal",
        lineHeight: "2.75",
        // letterSpacing: "normal",
        // textAlign: "left",
        color: "#6e7074",
        fontFamily: ["Poppins"].join(","),
        "&:hover": {
            color: "#032541",
            opacity: 1,
        },
        "&.Mui-selected": {
            color: "#dc3545",
            // fontWeight: 600,
        },
        "&.Mui-focusVisible": {
            backgroundColor: "blue",
        },
    })
);

const Receipts = () => {
    // tabs controls;
    const {group} = useSelector((store) => store.user);
    const [tabvalue, setTabValue] = useState(group && group !== "Customer" ? "Generated":"Received");
    const [generatedRows, setGeneratedRows] = useState([]);
    const [receivedRows, setReceivedRows] = useState([]);
    const [generatedPageSize, setGeneratedPageSize] = useState(5);
    const [receivedPageSize, setReceivedPageSize] = useState(5);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    let navigate = useNavigate();
    const fetchGenerated = async () => {
        try {
            const headers = {method : 'GET' , 'X-Authorization': localStorage.getItem('X-Authorization')};
            const response  = await fetch(baseUrl+"/api/get_business_receipts", {headers});
            if (response.status === 201) {
                await response.json()
                    .then((data)=>{
                        console.log("generated receipts", data)
                        const rowsWithIds = data?.data?.map(row => {
                            const issuedDate = new Date(row.issuedDate)?.toLocaleDateString("de-DE", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric"
                            });
                            const amount = numberFormat(row?.amount)
                            return { ...row, id: row?.transactionId, issuedDate, amount }
                        });

                        setGeneratedRows(rowsWithIds);
                    })
            }
        } catch (error) {
            console.error("error fetching generated invoices",error.message);
        }
    };
    const fetchReceived = async () =>{
        try{
            const headers = {method : 'GET' , 'X-Authorization': localStorage.getItem('X-Authorization')};
            const response  = await fetch(baseUrl+"/api/get_user_receipts_invoice_only", {headers});
            if (response.status === 201) {
                await response.json()
                    .then((data)=>{
                        console.log("received receipts", data)
                        const rowsWithIds = data.data.map(row => {
                            const issuedDate = new Date(row.issuedDate).toLocaleDateString("de-DE", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric"
                            });
                            const amount = numberFormat(row.amount)
                            return { ...row, id: row.transactionId, issuedDate, amount }
                        });
                        setReceivedRows(rowsWithIds);
                    })
            }
        }catch (e) {
            console.error("error fetching recived invoices",error.message);
        }
    }

    const numberFormat = (value) =>
        new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "KES",
        }).format(value);


    useEffect(() => {
        fetchGenerated()
        fetchReceived()
    }, []);

    const setNewValue = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <div>
            <div>
            {/*    <Grid*/}
            {/*        container*/}
            {/*        justifyContent="space-between"*/}
            {/*        alignItems="center"*/}
            {/*        marginBottom="10px"*/}
            {/*    >*/}
            {/*        <Grid item>*/}
            {/*<span*/}
            {/*    style={{*/}
            {/*        fontFamily: "Poppins",*/}
            {/*        fontSize: "25px",*/}
            {/*        fontWeight: "600",*/}
            {/*        fontStretch: "normal",*/}
            {/*        fontStyle: "normal",*/}
            {/*        lineHeight: "1.32",*/}
            {/*        letterSpacing: "normal",*/}
            {/*        textAlign: "left",*/}
            {/*        color: "#dc3545",*/}
            {/*    }}*/}
            {/*>*/}
            {/*  Receipts*/}
            {/*</span>*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}

              {/*  <Grid container>*/}
              {/*      <Grid item>*/}
              {/*          <p*/}
              {/*              style={{*/}
              {/*                  fontFamily: "Poppins",*/}
              {/*                  fontSize: "20px",*/}
              {/*                  fontWeight: "600",*/}
              {/*                  fontStretch: "normal",*/}
              {/*                  fontStyle: "normal",*/}
              {/*                  lineHeight: "1.65",*/}
              {/*                  letterSpacing: "normal",*/}
              {/*                  textAlign: "left",*/}
              {/*                  color: "#b4bcc4",*/}
              {/*              }}*/}
              {/*          >*/}
              {/*              Dashboard{" "}*/}
              {/*              <span*/}
              {/*                  style={{*/}
              {/*                      fontFamily: "Poppins",*/}
              {/*                      fontSize: "20px",*/}
              {/*                      fontWeight: "600",*/}
              {/*                      fontStretch: "normal",*/}
              {/*                      fontStyle: "normal",*/}
              {/*                      lineHeight: "1.65",*/}
              {/*                      letterSpacing: "normal",*/}
              {/*                      textAlign: "left",*/}
              {/*                      color: "#b4bcc4",*/}
              {/*                  }}*/}
              {/*              >*/}
              {/*  \*/}
              {/*</span>*/}
              {/*              <span*/}
              {/*                  style={{*/}
              {/*                      fontFamily: "Poppins",*/}
              {/*                      fontSize: "20px",*/}
              {/*                      fontWeight: "600",*/}
              {/*                      fontStretch: "normal",*/}
              {/*                      fontStyle: "normal",*/}
              {/*                      lineHeight: "1.65",*/}
              {/*                      letterSpacing: "normal",*/}
              {/*                      textAlign: "left",*/}
              {/*                      color: "#032541",*/}
              {/*                  }}*/}
              {/*              >*/}
              {/*  Receipts*/}
              {/*</span>{" "}*/}
              {/*          </p>*/}
              {/*      </Grid>*/}
              {/*  </Grid>*/}
                <Box component="div" sx={{marginLeft:2}}>
                    <Box component="div" >
                        <Typography sx={{color:"#032541", fontSize:"1.563rem", fontWeight:700}}>
                            Receipts
                        </Typography>
                    </Box>
                    <Box component="div" sx={{}}>
                        <Breadcrumbs
                            separator={<FiberManualRecordIcon sx={{fontSize:"0.625rem", fontFamily : 'Poppins', color:"#e1e5e8"}} />}
                            aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Box>
                </Box>

                <Grid container marginBottom="10px" >
                    <Grid item style={{width:"100%"}}>
                        <TabContext value={tabvalue}>
                            <Box>
                                <AntTabs onChange={setNewValue}>
                                    {group && group !== "Customer" &&(
                                        <AntTab value="Generated" label="Out-going" />
                                    )}
                                    <AntTab value="Received" label="Incoming" />

                                </AntTabs>
                            </Box>

                            {group && group !== "Customer" &&(


                            <TabPanel value="Generated">
                                <div style={{
                                    height:"350px",
                                    minWidth:"1000px"
                                }}>
                                    <DataGrid
                                        pagination
                                        rows={generatedRows}
                                        width="100%"
                                        autoHeight
                                        minHeight={"500px"}
                                        pageSize={generatedPageSize}
                                        onPageSizeChange={(newPageSize) => setGeneratedPageSize(newPageSize)}
                                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                        columns={[
                                            {field: 'receiptNo', headerName: 'Receipt Number',flex:1, renderCell : (params) => {return (<Link to={`/receipt/${params.value}/${params.row.invoiceNumber}`}>{params.value}</Link> )}},
                                            {field: 'from', headerName: 'From',flex:1},
                                            {field: 'invoiceNumber', headerName: 'For',flex:1},
                                            {field: 'issuedDate', headerName: 'Date Issued',flex:1},
                                            {field: 'amount', headerName: 'Amount',flex:1},
                                        ]}
                                        getRowId={row => row.id}
                                        // onRowClick={console.log}
                                    />
                                </div>
                            </TabPanel>
                            )}


                            <TabPanel value="Received">
                                <div style={{
                                    height:"350px",
                                    minWidth:"1000px"
                                }}>
                                    <DataGrid
                                        pagination
                                        rows={receivedRows}
                                        width="100%"
                                        autoHeight
                                        minHeight={"500px"}
                                        pageSize={receivedPageSize}
                                        onPageSizeChange={(newPageSize) => setReceivedPageSize(newPageSize)}
                                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                        columns={[
                                            {field: 'receiptNo', headerName: 'Receipt Number', flex:1, renderCell : (params) => {return (<Link to={`/receipt/${params?.value}/${params?.row?.invoiceNumber}`}>{params?.value}</Link> )}},
                                            {field: 'from', headerName: 'From',flex:1},
                                            {field: 'invoiceNumber', headerName: 'For',flex:1},
                                            {field: 'issuedDate', headerName: 'Date Issued',flex:1},
                                            {field: 'amount', headerName: 'Amount',flex:1},
                                        ]}
                                        getRowId={row => row.id}
                                        // onRowClick={console.log}
                                    />
                                </div>

                            </TabPanel>

                        </TabContext>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default Receipts;
