import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, FormControl, MenuItem, Select } from "@mui/material";
import CustomInputLabel from "./CustomInputLabel";
import CustomTextField from "./CustomTextField";
import CustomTextFieldSmall from "./CustomTextFieldSmall";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CustomSelectField from "./CustomSelectField";
import { useDispatch, useSelector } from "react-redux";
import { addStudent, clearAdditions, storeStudent } from "../../features/customerSlice";
import HttpComponent from "./MakeRequest";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import PhoneInput from "react-phone-input-2";
import { handleSchoolTypeName, removeSchoolTypeName } from '../../features/schoolType';

let baseUrl = process.env.REACT_APP_BASE_URL;

const SchoolForm = (props) => {
    let { formData, setFormData } = props
    console.log("formData hapa wewe", formData)

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const [gradeOptions, setGradeOptions] = useState([])
    const [streamsOptions, setStreamesOptions] = useState([]);
    const [termOptions, setTermOptions] = useState([]);
    const [scooltypeId, setSchoolTypeId] = useState('')
    const [SchooltypeName, setSchoolTypeName] = useState('')
    const { schoolTypeName, schoolTypeId } = useSelector((store) => store.schoolType.schoolTypeDetail)
    // const  getSchoolTypeId =async () => {
    //     dispatch(removeSchoolTypeName())
    //   try {
    //     HttpComponent({
    //       method: 'GET',
    //       // /api/get_school_type_by_businesss
    //       url: "/api/get_school_type_by_businesss",
    //       body: null,
    //       token: localStorage.getItem("X-Authorization"),
    //     }).then((data) => {
    //     //  console.log('heeeeeeeeeeeeee', data.response.data.schoolType, data.response.data._id);
    //       if (data.status === 201) {
    //         setSchoolTypeName(data.response.data.schoolType);
    //         setSchoolTypeId(data.response.data._id);
    
    //         const schoolDetails = {
    //           schoolTypeName: data.response.data.schoolType,
    //           schoolTypeId: data.response.data._id
    //         }
            
    //         // console.log("inside if ", schoolDetails);
    //         dispatch(handleSchoolTypeName(schoolDetails))
    
    //       }else {
    //         console.error("Error setting info")
    //         setErrorShow({ state: true, message: data.response.message })
    //       }
  
    //     })
    //   } catch (error) {
    //     console.error(error.message);
    //   }
    // }
    // useEffect(() => {
    //     getSchoolTypeId()
    // }, [])
   
    console.log('School Type Id in student form', schoolTypeId);
    const GetGrades = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeId}`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data, "Grades");

        setGradeOptions(data.data.map((itemGrade) => {
            return { value: itemGrade.schoolGrades, label: itemGrade.schoolGrades }
        }
        ))
    };
    const GetStreams = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_streams`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data, "Streams");
        if (response.status === 201) {
            setStreamesOptions(data.data.map((itemStream) => {
                return { value: itemStream.streamName, label: itemStream.streamName }
            }
            ))
        }
    };

    const GetStreamsTerms = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_terms?schoolType_id=${schoolTypeId}`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data, "Streams");
        if (response.status === 201) {
            setTermOptions(data.data.map((terms) => {
                return { value: terms.schoolGrades, label: terms.schoolGrades }
            }
            ))
        }
    };
    useEffect(() => {
        if (schoolTypeId) {
            GetGrades();
            GetStreams();
            GetStreamsTerms();
        }

    }, [schoolTypeId]);
    const [boardingStatus, setBoardingStatus] = useState([])
    const getBoardingStatus = async () => {
        const response = await fetch(`${baseUrl}/api/getboardingStatus`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        // console.log("here store is data",data);
        if (data.status === "SUCCESS") {
            console.log("here Boarding Status", data);
            setBoardingStatus(data.data.map((boardingStatus) => {
                return { value: boardingStatus, label: boardingStatus }
            }
            ))
        } else {
            console.error("Error setting info")
            setErrorShow({ state: true, message: "Error setting info" })
        }

    }
    useEffect(() => {
        getBoardingStatus()
    }, [])


    // courses here

    const [coursesOptions, setCoursesOptions] = useState([]);

    const getCourses = async () => {
        const response = await fetch(`${baseUrl}/api/v1/get_school_courses`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
            method: "GET",
        });
        const data = await response.json();
        console.log(data, "Get Courses");
        if (response.status === 200) {
            // setCoursesOptions(data.data);
            setCoursesOptions(data.data.map((courses) => {
                return { value: courses._id, label: courses.courseName }
            }
            ))
        }
    };

    useEffect(() => {
        getCourses();
    }, []);
    console.log(coursesOptions, "coursesOptions");
    console.log(schoolTypeName, "schoolTypeName");







    // const termOptions = [
    //   { value: "Term 1", label: "Term 1" },
    //   { value: "Term 2", label: "Term 2" },
    //   { value: "Term 3", label: "Term 3" },
    // ];
    const genderOptions = [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
    ]

    const handleAdmissionChange = (newValue) => {
        console.log("datae", newValue)
        setFormData({
            ...formData,
            admissionDate: newValue
        })
    }
    const handleDobChange = (newValue) => {
        console.log("datae", newValue)
        setFormData({
            ...formData,
            dob: newValue
        })
    }
    const dispatch = useDispatch();
    const { userId, X_Authorization } = useSelector((store) => store.user);
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [courseName, setCourseName] = useState("")
    useEffect(() => {
        if (formData.courseId) {
            if (schoolTypeName?.includes('University')) {
                let courseNameData = coursesOptions.filter((item) => item.value === formData.courseId)
                console.log(courseNameData, 'courseNameData');
                setCourseName(courseNameData[0].label)
            }
        }
    }, [formData?.courseId])
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const [validEmail, setValidEmail] = useState(true);

    useEffect(() => {
        if (formData.email != "") {
            let result = validateEmail(formData.email);
            setValidEmail(result);
            // console.log("email valid", result)
        } else {
            setValidEmail(true);
        }
    }, [formData.email]);



    const nextHandler = async () => {
        HttpComponent({
            method: 'GET',
            url: `/api/student_id_exists?studentNumber=${formData.admissionNumber}`,
            body: null,
            token: X_Authorization
        }).then((data) => {
            let dataObj
            console.log("here store is data", data);
            if (data.status === 201) {
                if (data.response.data.studentExists) {
                    setErrorShow({
                        state: true,
                        message: "Student with this Admission Number exists"
                    })
                } else if (schoolTypeName?.includes('University')) {
                    dataObj = {
                        admissionDate: formData.admissionDate,
                        admissionNo: formData.admissionNumber,
                        amountReceived: 0.0,
                        businessId: "",
                        courseId: formData.courseId,
                        courseName: courseName,
                        year: formData.year,
                        customerAddress: formData.info,
                        customerType: "individual",
                        billableItems: [],
                        dateofbirth: new Date(formData.dateOfBirth),
                        email: formData.email,
                        extraServices: [],
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        paymentType: "Prepaid",
                        phone: formData.phone,
                        gender: formData.gender,
                        term: formData.term,
                        studentAsParent: true,

                    }
                    console.log(dataObj, "dataObj University");
                    dispatch(addStudent(dataObj))
                    dispatch(storeStudent(dataObj))
                    props.setDisabled(false)
                    props.setParentSetUp(false)
                    console.log("form data hapa hapa", dataObj)
                    localStorage.removeItem("schoolTypeState")
                } else {
                    dataObj = {
                        additionalInfo: formData.info,
                        billingFrequency: "ThreeMonths",
                        dateOfAdmission: Date(formData.admissionDate),
                        itemNumber: formData.admissionNumber,
                        boardingStatus: formData.boardingStatus,
                        stream: formData.stream,
                        grade: formData.grade,
                        term: formData.term,
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        gender: formData.gender
                    }
                    dispatch(addStudent(dataObj))
                    dispatch(storeStudent(dataObj))
                    props.setDisabled(false)
                    props.setParentSetUp(false)
                    console.log("form data", dataObj)
                    localStorage.removeItem("schoolTypeState")


                }
            }
        }).catch((error) => {
            console.error(error.message);
        })

    }
    const cancelButtonStyles =
    {
        "width": "7.813rem",
        "height": "2.813rem",
        "padding": "12.5px 36.7px 12.5px 38.3px",
        "borderRadius": "4px",
        "border": "solid 1px #dc3545",
        "color": "#dc3545"
    }

    const doneButtonStyles = {
        "width": "7.813rem",
        "height": "2.813rem",
        "padding": "12.5px 43.7px 12.5px 45.3px",
        "borderRadius": "4px",
        "backgroundColor": "#032541",
        "color": "white",
        "marginLeft": 2,
        '&:hover': {
            backgroundColor: '#032541',
            color: 'white'
        }
    }

    console.log(formData.courseId, "courseId");
    console.log(formData.courseName, "courseName");


    return (
        <Box component="div" sx={{ display: "flex", margin: 2, justifyContent: "center" }}>
            <Box component="div" sx={{ display: "flex", flexDirection: "column" }}>
                {/*title*/}
                <Box component="div" sx={{ marginBottom: 2 }}>
                    <Typography sx={{ fontSize: "1rem", color: "#707070" }}>
                        Student Details
                    </Typography>
                </Box>
                {/*    end of title*/}

                <ErrorAlert
                    vertical="top"
                    horizontal="right"
                    onClose={() => setErrorShow({ ...errorShow, state: false })}
                    open={errorShow.state}
                    message={errorShow.message} />

                {/*begin of form*/}
                <Box compnent="div" sx={{ display: "flex" }}>
                    <Box component="div" sx={{ marginX: 1 }}>
                        <CustomInputLabel required={true} label={"Admission Number"} />
                        <CustomTextField value={formData.admissionNumber} onChange={handleInputChange} name={"admissionNumber"} placeholder={"Admission Number"} />
                    </Box>

                    <Box component="div" sx={{ marginX: 1 }}>
                        <CustomInputLabel required={false} label={"Admission Date"} />
                        <LocalizationProvider dateAdapter={AdapterDayjs} style={{}}>
                            <DesktopDatePicker
                                inputFormat="DD/MM/YYYY"
                                value={formData.admissionDate}
                                required={false}
                                onChange={handleAdmissionChange}
                                renderInput={(params) =>
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        sx={{ marginBottom: 2 }}
                                        InputProps={{
                                            sx: {
                                                width: '17rem',
                                                height: '3.438rem',
                                                fontSize: "0.875rem",
                                                "& fieldset": {
                                                    borderColor: "#bec5d1"
                                                }
                                            }
                                        }}
                                        {...params}
                                    />}
                            />
                        </LocalizationProvider>
                        {/*<CustomTextFieldSmall value={formData.admissionDate} onChange={handleInputChange} name={"admissionDate"} placeholder={"Admission Date"} />*/}
                    </Box>
                </Box>

                <Box compnent="div" sx={{ display: "flex" }}>
                    <Box component="div" sx={{ marginX: 1 }}>
                        <CustomInputLabel required={true} label={"First Name"} />
                        <CustomTextField value={formData.firstName} onChange={handleInputChange} name={"firstName"} placeholder={"First Name"} />
                    </Box>

                    <Box component="div" sx={{ marginX: 1 }}>
                        <CustomInputLabel required={true} label={"Last Name"} />
                        <CustomTextField value={formData.lastName} onChange={handleInputChange} name={"lastName"} placeholder={"Last Name"} />
                    </Box>
                </Box>
                {schoolTypeName?.includes('University') ?

                    <Box compnent="div" sx={{ display: "flex" }}>
                        <Box component="div" sx={{ marginX: 1 }}>
                            <CustomInputLabel required={true} label={"Phone"} />
                            <PhoneInput inputStyle={{ height: '3.438rem', }} style={{ width: "400px" }} required country={"ke"} enableSearch={true} value={formData.phone} name={"phone"} onChange={(phone) => formData.phone = phone} />

                            {/* <CustomTextField value={formData.phone} onChange={handleInputChange} name={"phone"} placeholder={"phone"} /> */}
                        </Box>

                        <Box component="div" sx={{ marginX: 1 }}>
                            <CustomInputLabel required={true} label={"Email"} />
                            {!validEmail ?
                                <Typography sx={{ color: "#dc3545", fontSize: "0.75rem" }}>
                                    Please enter a valid email
                                </Typography>
                                :
                                null
                            }
                            <CustomTextField value={formData.email} onChange={handleInputChange} name={"email"} placeholder={"Email"} />
                        </Box>
                    </Box>
                    : null}

                <Box compnent="div" sx={{ display: "flex" }}>
                    <Box component="div" sx={{ marginX: 1 }}>
                        <CustomInputLabel required={true} label={"Gender"} />
                        <CustomSelectField value={formData.gender} onChange={handleInputChange} name={"gender"} placeholder={"Select Gender"} options={genderOptions} />
                        {/*<CustomTextFieldSmall value={formData.gender} onChange={handleInputChange} name={"gender"} placeholder={"Gender"} />*/}
                    </Box>

                    {schoolTypeName?.includes('University') ?

                        <Box component="div" sx={{ marginX: 1 }} >
                            <CustomInputLabel required={true} label={"Course & Programs"} />
                            <CustomSelectField value={formData.courseId} onChange={handleInputChange} name={"courseId"} placeholder={"Select course /Program"} options={coursesOptions} />


                        </Box> :
                        <Box component="div" sx={{ marginX: 1 }} >
                            <CustomInputLabel required={true} label={"Grade"} />
                            <CustomSelectField value={formData.grade} onChange={handleInputChange} name={"grade"} placeholder={"Select Grade"} options={gradeOptions} />

                        </Box>
                    }
                </Box>

                <Box compnent="div" sx={{ display: "flex" }}>
                    <Box component="div" sx={{ marginX: 1 }}>
                        <CustomInputLabel required={true} label={schoolTypeName.includes('University') ? "Semesters" : "Term"} />
                        <CustomSelectField value={formData.term} onChange={handleInputChange} name={"term"} placeholder={"Select Term"} options={termOptions} />
                        {/*<CustomTextFieldSmall value={formData.term} onChange={handleInputChange} name={"term"} placeholder={"Term"} />*/}
                    </Box>
                    <Box component="div" sx={{ marginX: 1 }}>
                        {schoolTypeName?.includes('University') ?
                            <div>  <CustomInputLabel required={true} label={"Date of Birth"} />
                            <LocalizationProvider dateAdapter={AdapterDayjs} style={{}}>
                            <DesktopDatePicker
                                inputFormat="DD/MM/YYYY"
                                value={formData.dob}
                                required={false}
                                onChange={handleDobChange}
                                renderInput={(params) =>
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        sx={{ marginBottom: 2 }}
                                        InputProps={{
                                            sx: {
                                                width: '30rem',
                                                height: '3.438rem',
                                                fontSize: "0.875rem",
                                                "& fieldset": {
                                                    borderColor: "#bec5d1"
                                                }
                                            }
                                        }}
                                        {...params}
                                    />}
                            />
                        </LocalizationProvider>  
                            </div> :
                            <div>
                                <CustomInputLabel required={true} label={"Boarding Status"} />
                                <CustomSelectField value={formData.boardingStatus} onChange={handleInputChange} name={"boardingStatus"} placeholder={"Select Boarding Status"} options={boardingStatus} />
                            </div>
                        }
                        {/*<CustomTextFieldSmall value={formData.term} onChange={handleInputChange} name={"term"} placeholder={"Term"} />*/}
                    </Box>


                </Box>
                <Box compnent="div" sx={{ display: "flex" }}>
                    {schoolTypeName?.includes('University') ?

                        <Box component="div" sx={{ marginX: 1 }} >
                            <CustomInputLabel required={true} label={"Year"} />
                            <CustomSelectField value={formData.year} onChange={handleInputChange} name={"Semesters"} placeholder={"Select Grade"} options={gradeOptions} />

                        </Box> :


                        <Box component="div" sx={{ marginX: 1 }}>
                            <CustomInputLabel required={true} label={"Streams"} />
                            <CustomSelectField value={formData.stream} onChange={handleInputChange} name={"stream"} placeholder={"Select Streams"} options={streamsOptions} />
                            {/*<CustomTextFieldSmall value={formData.term} onChange={handleInputChange} name={"term"} placeholder={"Term"} />*/}
                        </Box>

                    }



                    <Box component="div" sx={{ marginX: 1 }}>
                        <CustomInputLabel label={schoolTypeName.includes("University") ? "Address" : "Additional Info"} />
                        <CustomTextField value={formData.info} onChange={handleInputChange} name={"info"} placeholder={schoolTypeName.includes("University") ? "Address" : "Additional Info"} />
                    </Box>
                </Box>
                {/*    end of form */}
                <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
                    <Box component="div">
                        <Button sx={cancelButtonStyles} onClick={() => props.setView("done")}>
                            Cancel
                        </Button>

                        <Button sx={doneButtonStyles} onClick={nextHandler} disabled={
                            // !formData?.admissionNumber?.trim()
                            // || !formData?.firstName?.trim()
                            // || !formData?.lastName?.trim()
                            // || !formData.gender?.trim()
                            // || !formData.grade?.trim()
                            // || !formData?.term?.trim()
                            // || !formData?.boardingStatus.trim()
                            // || !formData.stream.trim()
                            // ||formData?.info?.trim()
                            (schoolTypeName?.includes('University') && !formData?.phone?.trim()) || (schoolTypeName?.includes('University') && !formData?.email?.trim()) || (schoolTypeName?.includes('University') && !formData?.courseId?.trim()) || !formData?.admissionNumber?.trim() ||
                            schoolTypeName?.includes('University') && !validEmail 

                            

                        } >
                            Next
                        </Button>

                    </Box>

                </Box>
            </Box>
        </Box>
    )
}

export default SchoolForm;
