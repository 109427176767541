import React, { useEffect, useState } from "react";
import Dash from "../../common/dash";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Form from "react-bootstrap/Form";
import moment from "moment-timezone";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Modal from "@mui/material/Modal";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./react-bootstrap-table2.min.css";
import "./bootstrap.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { SessionModal } from "../Modals/SessionExpiredModal/sessionModal";
import axios from "axios";
import lodash from "lodash";
import DatePicker from "react-datepicker";
import DateTimeRangePicker from "@wojtekmaj/react-daterange-picker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const baseUrl = process.env.REACT_APP_BASE_URL;

// Number Format
const numberFormat = (value) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "KES",
  }).format(value);

// OpenTable Modal Styling
const modalTableStyle = {
  transform: "translate(-50%, -50%)",
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "900px",
  height: "350px",
  padding: "0 0 73.9px",
  borderRadius: "15px",
  backgroundColor: "#fff",
};

const columns = [
  {
    field: "productName",
    headerName: "Product Name",
    flex: 1,
    align: "left",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"Product Name"}
      </strong>
    ),
  },
  {
    field: "salesCount",
    headerName: "Sales Count",
    flex: 1,
    align: "left",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"SalesCount"}
      </strong>
    ),
  },
  {
    field: "salesTotal",
    headerName: "Sales Total",
    flex: 1,
    align: "left",
    valueGetter: (params) => {
      return numberFormat(params.row.salesTotal);
    },
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"SalesTotal"}
      </strong>
    ),
  },
];

const ReportSalesTotalByProduct = () => {
  // page size setter and getter
  const [pageSize, setPageSize] = React.useState(5);

  //add days to current date
  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  // session Modal Config
  const [sessionOpen, setSessionOpen] = React.useState(false);
  const handleSessionClose = () => setSessionOpen(false);
  const handleSessionOpen = () => setSessionOpen(true);

  //date range Filter setters and Getters
  const [dValue, onDValueChange] = useState([
    moment(new Date()).format("YYYY-MM-DD HH:MM:ss"),
    moment(addDays(new Date(), 1)).format("YYYY-MM-DD HH:MM:ss"),
  ]);

  // Transactions Setters and Getters
  const [transactions, setTransactions] = useState([]);

  //const dp open state
  const [isOpen, setIsOpen] = useState(false);

  //chart dropDown
  const [dropDown, setDropDown] = useState("7days");

  // Transaction typesetters and Getters
  const [transactionType, setTransactionType] = useState([]);
  const [cashiers, setCashiers] = useState([]);
  const [productSalesList, setProductSalesList] = useState([]);
  const [salesTotal, setSalesTotal] = useState([]);
  const [salesCount, setSalesCount] = useState([]);

  // payment Type setter and getter
  const [paymentType, setPaymentType] = useState("All");
  const [cashierSelected, setCashierSelected] = useState("All Cashiers");

  // Filter data grid by transaction type using select input
  const [filteredData, setFilteredData] = useState([]);

  //const
  const [searchValue, setSearchValue] = useState("");

  //"DD-MM-YYYY HH:MM:ss"
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  let totalsList = [];

  const handleEndDateChange = (value) => {
    setEndDate(value);
  };

  const handleStartDateChange = (value) => {
    setStartDate(value);
  };

  //Get Transactions by cashier
  //get_transactions_by_cashier
  useEffect(() => {
    const fetchSalesItems = async (theCashier, startDate, endDate) => {
      try {
        //new Date().toLocaleString("en-US", {timeZone: "America/New_York"})
        startDate = new Date(new Date(startDate).toUTCString());
        //.toLocaleString("en-US", {timeZone: "Africa/Nairobi"})
        endDate = new Date(new Date(endDate).toUTCString());
        //.toLocaleString("en-US", {timeZone: "Africa/Nairobi"})
        console.log(`startDatestartDate ${startDate}`);
        console.log(`endDateendDate ${endDate}`);
        const res = await axios.get(
          baseUrl +
            `/api/get_total_sales_by_item?cashier=${theCashier}&startDate=${startDate}&endDate=${endDate}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          }
        );
        console.log(
          `fetchSoldItems.data.dataH ${JSON.stringify(res.data.data)}`
        );
        if (res.status === 200) {
          setProductSalesList(res.data.data);
          setSalesTotal(res.data.total);
          setSalesCount(res.data.count);
        } else if (res.status === 401) {
          handleSessionOpen();
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchSalesItems(cashierSelected, startDate, endDate);
  }, [cashierSelected, startDate, endDate]);

  // Currency Formatter
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "KES",
    }).format(value);

  let count = 0;
  const rowData = productSalesList.map((order) => {
    return {
      id: ++count,
      productName: order.productName,
      salesCount: order.salesCount,
      salesTotal: order.salesTotal,
    };
  });

  // console.log(rowData, 'Row Data')

  // Fetch Payment Types from DB
  const fetchTransactionTypes = async () => {
    try {
      const response = await fetch(baseUrl + "/api/transactionType", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      const data = await response.json();
      console.log(`datadatatt ${JSON.stringify(data)}`);
      if (response.status === 200 && data.Status === "SUCCESS") {
        setTransactionType(data.data);
      } else if (response.status === 401) {
        handleSessionOpen();
      }
    } catch (e) {
      console.log(e);
    }
  };

  //get_cashiers_by_business
  const fetchCashiers = async () => {
    try {
      const response = await fetch(baseUrl + "/api/get_cashiers_by_business", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      const data = await response.json();
      console.log(`datadata ${JSON.stringify(data)}`);
      if (response.status === 200 && data.Status === "SUCCESS") {
        setCashiers(data.data);
      } else if (response.status === 401) {
        handleSessionOpen();
      }
    } catch (e) {
      console.log(e);
    }
  };

  // //fetch cashiers
  // useEffect(() => {
  //     const fetchCashiers = async() => {
  //         try {
  //             const res = await axios.get(baseUrl + `/get_cashiers_by_business`,{headers:{"Content-Type" : "application/json","Accept" : "application/json","X-Authorization" : localStorage.getItem('X-Authorization')}})
  //             if  (res.status === 200){setCashierList(res.data.data)}
  //             else if (res.status === 401) {handleSessionOpen()}
  //         } catch (e) {console.log(e)}
  //     }
  //     fetchCashiers();
  // },[])

  useEffect(() => {
    const doFetchTransactionTypes = async () => {
      await fetchTransactionTypes();
    };
    doFetchTransactionTypes().then(() => console.log(transactionType, "check"));
  }, []);

  useEffect(() => {
    const doFetchCashiers = async () => {
      await fetchCashiers();
    };

    doFetchCashiers().then(() => console.log(cashiers, "check cashiers"));
  }, []);

  // const paymnentTypes = transactionType.map(type => {
  //     return type._id.transactionType
  // })

  const handleChange = (event) => {
    setPaymentType(event.target.value);
  };

  //
  const handleCashierChange = (event) => {
    setCashierSelected(event.target.value);
  };

  const handleSearchBox = (event) => {
    setSearchValue(event.target.value);
  };

  // filters
  // date range filter
  const filterByDateRange = (data) => {
    return data.filter((item) =>
      dropDown === "range"
        ? item.transtime >=
            moment(new Date(dValue[0]).toDateString()).format("YYYY-MM-DD") &&
          item.transtime <=
            moment(new Date(dValue[1]).toDateString()).format("YYYY-MM-DD")
        : item.transtime >= dValue[0] && item.transtime <= dValue[1]
    );
  };

  const filterByPaymentType = (data) => {
    return data.filter((item) => {
      if (paymentType === "All") {
        return item;
      } else if (item.transactionType === paymentType.includes(paymnentTypes)) {
        return item;
      } else if (item.transactionType === paymentType) {
        return item;
      }
    });
  };

  const filterBySearchBox = (data) => {
    return data.filter((item) => {
      if (
        item.businessNo.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.businessName.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.transactionID.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.serialNo.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        return item;
      }
    });
  };

  useEffect(() => {
    let filteredData = rowData;

    filteredData = filterByDateRange(filteredData);
    filteredData = filterByPaymentType(filteredData);
    filteredData = filterBySearchBox(filteredData);
    setFilteredData(filteredData);
  }, [transactionType]);

  useEffect(() => {
    let filteredData = rowData;

    filteredData = filterByDateRange(filteredData);
    filteredData = filterByPaymentType(filteredData);
    filteredData = filterBySearchBox(filteredData);
    setFilteredData(filteredData);
  }, [dValue, paymentType, searchValue]);

  //use Effect
  useEffect(() => {
    if (dropDown === "Today") {
      onDValueChange([
        moment(new Date()).format("YYYY-MM-DD HH:MM:ss"),
        moment(addDays(new Date(), 1)).format("YYYY-MM-DD HH:MM:ss"),
      ]);
    } else if (dropDown === "YesterDay") {
      onDValueChange([
        moment(addDays(new Date(), -1)).format("YYYY-MM-DD HH:MM:ss"),
        moment(addDays(new Date(), 0)).format("YYYY-MM-DD HH:MM:ss"),
      ]);
    } else if (dropDown === "7days") {
      onDValueChange([
        moment(addDays(new Date(Date.now()), -7)).format("YYYY-MM-DD HH:MM:ss"),
        moment(addDays(new Date(Date.now()), +1)).format("YYYY-MM-DD HH:MM:ss"),
      ]);
    } else if (dropDown === "this Month") {
      onDValueChange([
        moment(
          new Date(new Date().getFullYear(), new Date().getMonth())
        ).format("YYYY-MM-DD HH:MM:ss"),
        moment(addDays(new Date(Date.now()), +1)).format("YYYY-MM-DD HH:MM:ss"),
      ]);
    } else if (dropDown === "last Month") {
      onDValueChange([
        moment(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1)
        ).format("YYYY-MM-DD HH:MM:ss"),
        moment(
          new Date(new Date().getFullYear(), new Date().getMonth())
        ).format("YYYY-MM-DD HH:MM:ss"),
      ]);
    } else if (dropDown === "range") {
      setIsOpen(true);
      handleRange();
      onDValueChange([
        moment(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1)
        ).format("YYYY-MM-DD HH:MM:ss"),
        moment(
          new Date(new Date().getFullYear(), new Date().getMonth())
        ).format("YYYY-MM-DD HH:MM:ss"),
      ]);
    }
  }, [dropDown]);

  // handle delay
  function delayChange() {
    setDropDown("Today");
  }

  // handle drop down change
  const dropChange = (event) => {
    setDropDown(event.target.value);
  };

  // handle custom range
  const handleRange = () => {
    const x = document.getElementById("rangeDiv");
    if (x.style.display === "none") {
      x.style.display = "flex";

      // work on open calendar
    } else if (x.style.display === "flex") {
      x.style.display = "none";
      setIsOpen(false);
      setTimeout(delayChange, 5000000);
    } else {
      x.style.display = "none";
    }
  };

  // CSV Export Format
  const csvColumns = [
    { label: "Product", key: "productName" },
    // {label:"Product Category", key:"productCategory"},
    // {label:"Product Name", key:"productName"},
    { label: "Number Sold", key: "salesCount" },
    { label: "Total Sold", key: "salesTotal" },
  ];

  // CSV Export
  const csvReport = {
    data: rowData,
    headers: csvColumns,
    filename:
      cashierSelected.toLowerCase() + `_${dValue[0]}-${dValue[1]}_report.csv`,
  };

  // columns selection for Excel Export
  const pdfData = rowData.map((order) => {
    return {
      productName: order.productName,
      // productName : transaction.productName,
      // productCategory : transaction.productCategory,
      salesCount: order.salesCount,
      salesTotal: order.salesTotal,
    };
  });
  console.log(pdfData, "pdfData");
  //Excel Export Format
  const xlsxReport = (e, data) => {
    console.log(data);
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

    /* generate XLSX file and send to client */
    XLSX.writeFile(
      wb,
      cashierSelected.toLowerCase() + `_${dValue[0]}-${dValue[1]}_report.xlsx`
    );
  };

  const pdfTotal = [];
  const data = pdfData.map((data) => [
    data.productName,
    data.salesCount,
    numberFormat(data.salesTotal),
  ]);
  pdfData.map((data) => {
    const x = data.salesTotal;
    pdfTotal.push(x);
  });

  //PDF export Format
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    const title =
      cashierSelected.toUpperCase() +
      ` Sale Report from ${dValue[0]} to ${
        dValue[1]
      } For Business ${localStorage.getItem(
        "businessName"
      )} TOTAL AMOUNT ${numberFormat(salesTotal)}`;
    const headers = [
      [
        "productName",
        // "Product Category",
        // "Product Name",
        "salesCount",
        "salesTotal",
      ],
    ];

    console.log(data);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(
      cashierSelected.toLowerCase() + `_${dValue[0]}-${dValue[1]}_report.pdf`
    );
  };

  return (
    <div>
      <div>
        <div>
          <div>
            {/*sessionModal*/}
            <SessionModal
              onClose={handleSessionClose}
              sessionOpen={sessionOpen}
            />

            <Grid container sx={{ flex: 1 }}>
              <Grid item>
                <label
                  style={{
                    width: "100%",
                    height: "35px",
                    margin: "10px 32px 21px 12px",
                    fontFamily: "Poppins",
                    fontSize: "25px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.32",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#dc3545",
                  }}
                >
                  Product Sales
                </label>
              </Grid>
            </Grid>
            <Grid container sx={{ margin: "0px 0px 20px 0px" }}>
              <Grid item>
                <span
                  style={{
                    width: "265px",
                    height: "28px",
                    margin: "21px 0px 39px 12px",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.65",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#6e7074",
                  }}
                >
                  Dashboard
                </span>
                <span
                  style={{
                    width: "265px",
                    height: "28px",
                    margin: "21px 0px 39px 12px",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.65",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#6e7074",
                  }}
                >
                  \
                </span>
                <span
                  style={{
                    width: "265px",
                    height: "28px",
                    margin: "21px 0px 39px 12px",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.65",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#032541",
                  }}
                >
                  Product Sales By Cashier
                </span>
              </Grid>
            </Grid>
            <Grid container>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3} columns={8}>
                  <Grid item sx={{ margin: "0px 0px 0px 12px" }}>
                    <Form.Select
                      value={paymentType}
                      onChange={handleChange}
                      aria-label="Default select example"
                      style={{
                        borderRadius: "6px",
                        fontSize: "12px",
                        color: "#272d3b",
                        width: "220px",
                        height: "36px",
                        padding: "0px 0px 0px 17px",
                        border: "solid 1px #b4bcc4",
                        backgroundColor: "#fff",
                      }}
                    >
                      <option value="All">All</option>
                      {transactionType.map((type) => (
                        <option
                          sx={{ fontFamily: "Poppins", fontSize: "12px" }}
                          value={type._id.transactionType}
                        >
                          {type._id.transactionType}
                        </option>
                      ))}
                    </Form.Select>
                  </Grid>
                  <label
                    style={{
                      width: "20px",
                      height: "15px",
                      margin: "35px 0px 12px 20px",
                      fontFamily: "Poppins",
                      fontSize: "10px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    Cashiers
                  </label>
                  <Grid item sx={{ margin: "0px 0px 0px 12px" }}>
                    <Form.Select
                      value={cashierSelected}
                      onChange={handleCashierChange}
                      aria-label="Default select example"
                      style={{
                        borderRadius: "6px",
                        fontSize: "12px",
                        color: "#272d3b",
                        width: "220px",
                        height: "36px",
                        padding: "0px 0px 0px 17px",
                        border: "solid 1px #b4bcc4",
                        backgroundColor: "#fff",
                      }}
                    >
                      <option value="All Cashiers">All Cashiers</option>
                      {cashiers.map((cashierItem) => (
                        <option
                          sx={{ fontFamily: "Poppins", fontSize: "12px" }}
                          value={cashierItem._id.cashier}
                        >
                          {cashierItem._id.cashier}
                        </option>
                      ))}
                      {/* {cashierList.map((cashierItem) => (<option sx={{fontFamily:'Poppins',fontSize:'12px'}} value={cashierItem._id.cashier}>{cashierItem._id.cashier}</option>))}
                                        </Form.Select> */}
                    </Form.Select>
                  </Grid>

                  <label
                    style={{
                      width: "60px",
                      height: "15px",
                      margin: "35px 0px 12px 20px",
                      fontFamily: "Poppins",
                      fontSize: "10px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    Date Range
                  </label>
                  <Grid item xs={4.65}>
                    <Grid item>
                    <Form.Select size='sm' aria-label="Default select example" style={{borderRadius:"6px", fontSize:'12px',color:'#272d3b',width: "220px", height: "36px",  padding: "0px 0px 0px 17px", border: "solid 1px #b4bcc4", backgroundColor: "#fff"}} value={dropDown} onChange={dropChange}>
                      <option value="Today">Today</option>
                      <option value="YesterDay">Yesterday</option>
                      <option value="7days">Last 7 Days</option>
                      <option value="this Month">This Month</option>
                      <option value="last Month">Last Month</option>
                      <option value="range">Custom range</option>
                    </Form.Select>
                    <Grid value={dropDown} onChange={dropChange} style={{display:'none'}} id="rangeDiv"  >
                        <DateTimeRangePicker viewMode="time" dateFormat='YYYY-MM-DD HH:MM:ss' timeFormat='YYYY-MM-DD HH:MM:ss' maxDate={new Date()} isOpen={isOpen} onCalendarClose={handleRange} value={dValue} onChange={onDValueChange} />
                    </Grid>
                    </Grid>
                    {/* <DatePicker
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        showTimeSelect
                        //showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="yyyy-MM-dd h:mm aa"
                        /> */}
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            label="From"
                            value={startDate}
                            onChange={handleStartDateChange}
                            renderInput={(params) => <TextField {...params} />}
                            // format="YYYY-MM-DD HH:MM:ss"
                            inputFormat="DD-MM-YYYY HH:mm:ss"
                            format="DD-MM-YYYY HH:MM:ss"
                          />

                          <DateTimePicker
                            label="To"
                            value={endDate}
                            onChange={handleEndDateChange}
                            renderInput={(params) => <TextField {...params} />}
                            // format="YYYY-MM-DD HH:MM:ss"
                            inputFormat="DD-MM-YYYY HH:mm:ss"
                            format="DD-MM-YYYY HH:MM:ss"
                          />
                        </LocalizationProvider> */}
                  </Grid>

                  {/* <label style={{width: "61px", height: "15px", margin: "35px 0px 12px 20px", fontFamily: "Poppins", fontSize: "10px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >Cashiers</label>
                      <Grid item  sx={{margin: "0px 0px 0px 12px"}}>
                          <Form.Select value={paymentType} onChange={handleChange} aria-label="Default select example" style={{borderRadius:"6px", fontSize:'12px',color:'#272d3b',width: "220px", height: "36px", padding: "0px 0px 0px 17px", border: "solid 1px #b4bcc4", backgroundColor: "#fff"}}>
                              <option value='All'>All</option>
                              {transactionType.map((type) => (<option sx={{fontFamily:'Poppins',fontSize:'12px'}} value={type._id.transactionType}>{type._id.transactionType}</option>))}
                          </Form.Select>
                      </Grid> */}

                  <Grid
                    item
                    alignContent={"right"}
                    sx={{ margin: "0px 0px 0px 12px" }}
                  >
                    <CSVLink
                      style={{
                        borderRadius: "6px",
                        fontWeight: 600,
                        color: "#fff",
                        width: "50px",
                        height: "37px",
                        margin: "0 12px 0 0",
                        padding: "9px 10px 8px 11px",
                        backgroundColor: "#05d8e1",
                      }}
                      {...csvReport}
                    >
                      {" "}
                      CSV{" "}
                    </CSVLink>
                    <Button
                      onClick={(e) => {
                        xlsxReport(e, pdfData);
                      }}
                      sx={{
                        fontWeight: 600,
                        color: "#fff",
                        width: "50px",
                        height: "37px",
                        margin: "0 12px",
                        padding: "9px 7px 8px",
                        backgroundColor: "#032541",
                      }}
                    >
                      Excel
                    </Button>
                    <Button
                      onClick={() => {
                        exportPDF();
                      }}
                      sx={{
                        fontWeight: 600,
                        color: "#fff",
                        width: "50px",
                        height: "37px",
                        margin: "0 0 0 12px",
                        padding: "9px 12px 8px",
                        backgroundColor: "#dc3545",
                      }}
                    >
                      PDF
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2} columns={8}>
                  <Grid item xs={6}></Grid>
                  <Grid
                    alignItems={"right"}
                    item
                    sx={{ margin: "20px 0px 0px 12px" }}
                  >
                    <label
                      style={{
                        width: "35px",
                        height: "12px",
                        fontFamily: "Poppins",
                        fontSize: "9px",
                        fontWeight: "bold",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "2.78",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#032541",
                      }}
                    >
                      Search:
                    </label>
                    <input
                      value={searchValue}
                      onChange={handleSearchBox}
                      style={{
                        fontSize: "12px",
                        color: "#272d3b",
                        borderRadius: "6px",
                        width: "238px",
                        height: "36px",
                        margin: "0 0 0 20.4px",
                        padding: "7px 107.7px 6.4px 11.1px",
                        objectFit: "contain",
                        border: "solid 1px #032541",
                      }}
                      placeholder="search here"
                    ></input>
                  </Grid>
                </Grid>

                {/*datagrid grid*/}
                <Grid
                  sx={{
                    height: "400px",
                    margin: "50px 0 0 0",
                    border: "solid 0px transparent",
                    backgroundColor: "#fff",
                  }}
                >
                  <DataGrid
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "10px",
                      color: "#272d3b",
                    }}
                    rows={rowData}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    pagination
                  ></DataGrid>

                  <Grid
                    alignItems={"right"}
                    item
                    sx={{ margin: "20px 0px 0px 12px" }}
                  >
                    <label
                      style={{
                        width: "35px",
                        height: "12px",
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: "bold",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "2.78",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#032541",
                      }}
                    >
                      Total:
                    </label>
                    <label
                      style={{
                        width: "35px",
                        height: "12px",
                        fontFamily: "Poppins",
                        fontSize: "20px",
                        fontWeight: "bold",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "2.78",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#032541",
                      }}
                    >
                      {numberFormat(salesTotal)}
                    </label>
                  </Grid>
                </Grid>

                {/* <Grid container spacing={2} columns={8}>
                                    <Grid item xs={6}  ></Grid>
                                    <Grid alignItems={'right'} item sx={{margin:"20px 0px 0px 12px"}}>
                                        <label style={{width: "35px", height: "12px", fontFamily: "Poppins", fontSize: "9px", fontWeight: "bold", fontStretch: "normal", fontStyle: "normal", lineHeight: "2.78", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >Total:</label>
                                        <label style={{width: "35px", height: "12px", fontFamily: "Poppins", fontSize: "9px", fontWeight: "bold", fontStretch: "normal", fontStyle: "normal", lineHeight: "2.78", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >0.00</label>
                                    </Grid>
                                </Grid> */}
              </Box>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportSalesTotalByProduct;
