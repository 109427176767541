import React, { useState, useEffect } from "react";
import Breadcrumbcomponent from "./breadcrumb";
import { styled } from "@mui/material/styles";
import { stockStyles } from "./stockStyling";
import { Button, Grid, Tab } from "@mui/material";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { useNavigate } from "react-router-dom";
import AddstockReport from "./stockModals/addStockReport";
import StockTakeReport from "./stockModals/stockTakeReport";
import TransferStockReport from "./stockModals/stockTransferRequest";
import { Exports } from "../../customerAccounts/customerComponents/exports";
import SearchFilter from "../../customerAccounts/customerComponents/searchFilter";
import { useSelector } from "react-redux";
// import 

// Custom Tab Styling
const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#dc3545" } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({ textTransform: "none", minWidth: 0, [theme.breakpoints.up("sm")]: { minWidth: 0 }, fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1), fontSize: "14px", fontStretch: "normal", fontStyle: "normal", lineHeight: "2.75", letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","), "&:hover": { color: "#6e7074", opacity: 1 }, "&.Mui-selected": { color: "#dc3545", fontWeight: 600 }, "&.Mui-focusVisible": { backgroundColor: "blue" } }));

const Allreports = () => {
  const navigate = useNavigate();
  const breadcrumb = [
    <span key={1}>Dashboard</span>,
    <span key={2}>Stock</span>,
    <span key={3} style={{ color: "red" }}>
      Stock Order
    </span>,
  ];

  const [tabValue, setTabValue] = useState("addStock");

  const handleChange = (prev, newValue) => {
    setTabValue(newValue);
  };


  const [exportData, setExportData] = useState({ headers: [], data: [] });
  // csv data exports;
  const [csvExport, setCsvExport] = useState({ headers: [], data: [] });

  const [inputValue, setInputValue] = useState("");

  const { wareHouseState } = useSelector((store) => store.sidebar)

  return (
    <div style={{ width: "100%" }}>

      <Grid container width={'100%'} justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
        <Grid item width={'30%'}>
          <SearchFilter placeholder={'Search by batch No'} setInputValue={setInputValue} />
        </Grid>
        <Grid item>
          <Exports exportData={exportData} activeTabs={tabValue} csvExport={csvExport} />
        </Grid>
      </Grid>
      <Grid container width={"100%"} margin={"0px 0px 2% 0px"}>

        <Grid item width={"100%"}>
          <TabContext value={tabValue}>
          {/* variant="fullWidth" */}
            <AntTabs onChange={handleChange}>
              <AntTab value="addStock" label="Add Stock" />
              <AntTab value="stockTake" label="Stock Take" />
              {wareHouseState && <AntTab value="stockTransfer" label="Stock Transfer" />}
              
            </AntTabs>

            <TabPanel value="addStock">
                <AddstockReport inputValue={inputValue} setExportData={setExportData} setCsvExport={setCsvExport} /> 
            </TabPanel>

            <TabPanel value="stockTake">
                <StockTakeReport inputValue={inputValue} setExportData={setExportData} setCsvExport={setCsvExport} />
            </TabPanel>
            
            {wareHouseState &&  <TabPanel value="stockTransfer">
                <TransferStockReport inputValue={inputValue} setExportData={setExportData} setCsvExport={setCsvExport} />
            </TabPanel>}

          </TabContext>
        </Grid>
      </Grid>
    </div>
  );
};

export default Allreports;
