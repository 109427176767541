import { Grid, List, ListItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import Dash from "../../common/dash";
import { redirect, useNavigate } from "react-router-dom";
import user from "../../common/images/user-icon.png";
import bell from "../../common/images/bell-icon.png";
import logout from "../../common/images/logout-icon.png";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Switch from "@mui/material/Switch";
import HttpComponent from "../School/MakeRequest";
import { SuccessAlertRightAligned } from "../bills/startorderModals/modals";
import { Deactivatewarehouseandstoresmodal, Warehousesetup } from "./configsmodals/modal";
import { useSelector, useDispatch } from "react-redux";
import { switchWarehouseState } from "../../features/sideBarSlice";
import CreditExpiry from "../creditnote/creditnotecomponents/creditnoteexpirydate";
import { CreditnoteexpiryModal } from "../creditnote/creditnotemodals/modals";
import { CheckBox } from "@mui/icons-material";

// Base Url
const baseUrl = process.env.REACT_APP_BASE_URL;

const BusinessConfig = (props) => {

  const dispatch = useDispatch();
  const crediturl = `/api/get_credit_note_expiry`;
  const { returnData } = CreditExpiry(crediturl);

  const nav = useNavigate();
  const [stockConfigMap, setStockConfigMap] = useState([]);
  const [switchState, setSwitchState] = useState();
  const handleSwitchClick = (index) => {
    const newItems = [...stockConfigMap];
    // newItems[index].isSelected === true ? setSwitchState(true) : setSwitchState(false)
    newItems[index].isSelected = !newItems[index].isSelected;
    // console.log(`before state`, newItems[index].isSelected);
    if (newItems[index].isSelected === true) {
      setSwitchState(true);
    } else if (newItems[index].isSelected === false) {
      setSwitchState(false);
    }
    setStockConfigMap(newItems);
  };

  const handleBarManConfigUpdate = async (index) => {
    try {
      const response = await fetch(baseUrl + `/api/enable_bar_and_kitchen`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({ status: switchState }),
      });

      await response.json().then((data) => {
        // console.log(`Updating localStorage`, switchState);
        if (response.status === 200) {
          localStorage.setItem("barMan", switchState);
        }
        fetchBarManConfigs();
      });
    } catch (err) {
      console.error(err);
    }
  };

  const fetchBarManConfigs = async () => {
    try {
      const response = await fetch(baseUrl + "/api/get_enable_bar_and_kitchen", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      await response
        .json()
        .then((data) => {
          setStockConfigMap([
            {
              id: 1,
              configName: "Enable Bar & Kitchen in Zed",
              isSelected: data.status,
            },
          ]);
        })
        .catch((err) => {
          console.log("error getting stocks", err.message);
        });
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    fetchBarManConfigs()
      .then(() => {
        console.log("Stock Config Found");
      })
      .catch((e) => {
        console.log(`Error on Getting Stock Config ${e.message}`);
      });
  }, [switchState]);

  // get expiry state;
  const [selectExpiry, setExpiry] = useState(false);
  const [expiryDate, setExpiryDate] = useState("");
  const [customDate, setCustomDate] = useState("");
  const [sucess, setSucess] = useState({ state: false, message: "" });

  const setCreditExpiry = () => {
    HttpComponent({
      method: "POST",
      url: `/api/set_credit_note_expiry`,
      body: { periodInDays: expiryDate },
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        console.log(data);
        if (data.status === 200) {
          setSucess({ state: true, message: "credit not configuration sucessfully set" });
          setExpiry(false);
          setTimeout(() => {
            setSucess({ state: false });
            window.location.reload();
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // warehouses;
  const [enableWareHouse, setEnableWareHouse] = useState();
  const [openWareHouseModal, setOpenWareHouseModal] = useState(false);
  // const [wareHouseDbState, setWarehouseDbState] = useState(false)

  const toggleWarehouseState = () => {
    if(enableWareHouse){
      // setSucess({state:true, message:"Warehouse has already been set"})
      // setTimeout(() => {
      //   setSucess({state:false})
      // }, 1000);

      setOpenDeactivateModal(true)
    }
    if(!enableWareHouse){
      setEnableWareHouse(true)
      setOpenWareHouseModal(true)
    }
  }

  // useEffect(() => {
  //   if (enableWareHouse) {
  //     setOpenWareHouseModal(false);
  //   }
  // }, [enableWareHouse]);
  
  // check warehouse status;
  const getWarehouseStatus = () => {
    HttpComponent({
      method:"GET",
      url:`/api/v1/stores/warehouse_status`,
      body:null,
      token:localStorage.getItem('X-Authorization')
    }).then((data) => {
      
      if(data.status === 201 ){
        setEnableWareHouse(data.response.data.warehouseOn);
        dispatch(switchWarehouseState(data?.response?.data?.warehouseOn))
      }
      
    } ).catch((error) => {
      console.log(error);
    })
  }
  useEffect(() => {
    getWarehouseStatus()
  },[enableWareHouse])

  // activateWareHouse;
  const activateWarehouse = () => {
    HttpComponent({
      method: "POST",
      url: `/api/enable_warehouse_config`,
      body: { warehouseOn: true, mainStoreOn: true, secondaryStoreOn: true },
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        
        if(data.status === 201 ){
          setSucess({ state: true, message:"Warehouse and stores activated sucessfully" });
          getWarehouseStatus()
          localStorage.setItem("warehouseState", true)

          dispatch(switchWarehouseState(true))
        }

        setTimeout(() => {
          setSucess({ state: false});
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [opendeactivatemodal, setOpenDeactivateModal] = useState(false)

  
  const deactivateWareHouse = () => {
    HttpComponent({
      method: "POST",
      url: `/api/enable_warehouse_config`,
      body: { warehouseOn: false, mainStoreOn: false, secondaryStoreOn: false },
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        
        if(data.status === 201 ){
          setSucess({ state: true, message:"Warehouse and stores deactivated sucessfully" });
          getWarehouseStatus()
          localStorage.setItem("warehouseState", false)
          dispatch(switchWarehouseState(false))
        }

        setTimeout(() => {
          setSucess({ state: false});
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const { wareHouseState } = useSelector((store) => store.sidebar);
  console.log("wareHouseState =====<><>", wareHouseState);

  return (
    <div>
      <Grid container item className={"container-fluid"}>
        {/*Page Title Container*/}
        <Grid container item justifyContent="flex-start" alignItems="left" style={{ width: "86%" }}>
          <label style={{ height: "35px", margin: "24px 718px 24px 0", fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.32", letterSpacing: "normal", textAlign: "left", color: "#dc3545" }}>Configuration</label>
        </Grid>

        {/*Page Sub title and Search */}
        <Grid container item justifyContent="space-between" alignItems="left" style={{ width: "86%" }}>
          <Grid item>
            <div style={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: "600" }}>
              <span style={{ color: "#6e7074" }}>Dashboard \ </span>
              <span style={{ color: "#032541" }}>Business Configs</span>
            </div>
          </Grid>

          {/*Search input*/}
          <Grid item>
            <span style={{ marginRight: "10px", fontFamily: "Poppins", fontSize: "15px", fontWeight: "bold", color: "#032541" }}>Search :</span>
            <TextField
              size="small"
              placeholder={"search"}
              style={{ height: "1px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ width: "15px", height: "15px" }} />
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </Grid>
        </Grid>

        {/*Headers*/}
        <Grid container item justifyContent={"space-between"} alignContent={"center"} style={{ width: "86%", marginTop: "30px" }}>
          <Grid item style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: "600", color: "#032541" }}>
            Configuration
          </Grid>
          <Grid item style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: "600", color: "#032541" }}>
            Action
          </Grid>
        </Grid>

        {/*Configurations*/}
        <Grid container item style={{ width: "86%", marginTop: "30px" }}>
          <Grid item style={{ width: "100%" }}>
            <List>
              {stockConfigMap.map((stock, index) => {
                return (
                  <ListItem key={stock.id} style={{ marginBottom: "10px", fontFamily: "Poppins", fontSize: "14px", borderRadius: "6px", border: "solid 1px rgba(193,193,193, .3)" }}>
                    <Grid container justifyContent={"space-between"} style={{ display: "flex", width: "100%" }}>
                      <Grid item style={{ paddingTop: "10px", fontFamily: "Poppins", fontSize: "15px", color: "#032541" }}>
                        {stock.configName}
                      </Grid>
                      <Grid item>
                        {" "}
                        <Switch
                          checked={stock.isSelected}
                          onClick={() => {
                            handleSwitchClick(index);
                            handleBarManConfigUpdate(index);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })}

              <ListItem style={{ marginBottom: "10px", fontFamily: "Poppins", fontSize: "14px", borderRadius: "6px", border: "solid 1px rgba(193,193,193, .3)" }}>
                <Grid container justifyContent={"space-between"} style={{ display: "flex", width: "100%" }}>
                  <Grid item style={{ paddingTop: "10px", fontFamily: "Poppins", fontSize: "15px", color: "#032541" }}>
                    Credit Note Expiry
                  </Grid>
                  <Grid item style={{ cursor: "pointer" }} onClick={() => setExpiry(true)}>
                    {" "}
                    {returnData?.periodInDays ? ` ${returnData?.periodInDays} days` : "Not Set"}{" "}
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem style={{ marginBottom: "10px", fontFamily: "Poppins", fontSize: "14px", borderRadius: "6px", border: "solid 1px rgba(193,193,193, .3)" }}>
                <Grid container justifyContent={"space-between"} style={{ display: "flex", width: "100%" }}>
                  <Grid item style={{ paddingTop: "10px", fontFamily: "Poppins", fontSize: "15px", color: "#032541" }}>
                    Enable Warehouses & stores ?
                  </Grid>
                  <Grid item style={{ cursor: "pointer" }}>
                    <Switch disabled={wareHouseState ? true : false} color={enableWareHouse ? "success" : "primary"} checked={enableWareHouse} onClick={toggleWarehouseState} />

                    {enableWareHouse ? "Active" : "Set warehouse" }
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>

      {selectExpiry && <CreditnoteexpiryModal selectExpiry={selectExpiry} setExpiry={setExpiry} setExpiryDate={setExpiryDate} expiryDate={expiryDate} setCustomDate={setCustomDate} customDate={customDate} setCreditExpiry={setCreditExpiry} />}

      {sucess && <SuccessAlertRightAligned sucess={sucess.state} message={sucess.message} />}

      {openWareHouseModal && <Warehousesetup openWareHouseModal={openWareHouseModal} setOpenWareHouseModal={setOpenWareHouseModal} activateWarehouse={activateWarehouse} getWarehouseStatus={getWarehouseStatus} />}

      {opendeactivatemodal &&  <Deactivatewarehouseandstoresmodal opendeactivatemodal={opendeactivatemodal} setOpenDeactivateModal={setOpenDeactivateModal} deactivateWareHouse={deactivateWareHouse} /> }
    </div>
  );
};

export { BusinessConfig };
