import React, { useState, useEffect } from "react";
import { ErrorAlertRightAligned, SuccessAlertRightAligned } from "../../../bills/startorderModals/modals";
import CustomDataGrid from "../customDataGrid";
import StockFunctions from "../stockEndpoints";
import { Done } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { addStockTakeItems } from "../../../../features/startorderSlice";

const StockTakeProducts = ({ categoryId, wareHouseId, productvalue }) => {
  const dispatch = useDispatch();
  
  //DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  useEffect(() => {
    const stockFunctions = new StockFunctions();
    if (categoryId && productvalue === "")
      stockFunctions.getStockTakeProducts(pageState?.page, dataGridPageSize, categoryId, wareHouseId).then((data) => {
        setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count });
      });
  }, [categoryId, wareHouseId, dataGridPageSize, pageState.page, productvalue]);

  //   getStockPerStore = (storeId, page, pagesize)

  useEffect(() => {
    const stockFunctions = new StockFunctions();
    if (productvalue)
      stockFunctions.searchStockTakeProducts(pageState.page, dataGridPageSize, categoryId, productvalue, wareHouseId).then((data) => {
        if (data.status === 200) {
          setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count });
        }
      });
  }, [categoryId, wareHouseId, dataGridPageSize, pageState.page, productvalue]);



  const transferColumn = [
    { field: "productName", headerName: "Product Name", flex: 1 },
    { field: "expectedQuantity", headerName: "Expected", flex: 1 },
    {
      field: "actualQuantity",
      headerName: "Actual",
      flex: 1,
      renderCell: (params) => {
        return <input id="" name="" type="number" style={{ width: "100%", outline: "none" }} onChange={(e) => updateStock(params?.row, e)} />;
      },
    },
    {
      field: "Action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return <Done style={params.row?.actualQuantity > 0 ? { color: "green", cursor: "pointer" } : { color: "black", cursor: "pointer" }} />;
      },
    },
    {
      field: "variance",
      headerName: "Variance",
      flex: 1,
      renderCell: (params) => {
        return <input id="" name="" disabled type="number" value={params?.row?.variance} style={{ width: "100%", outline: "none" }} />;
      },
    },
  ];

  const stockTakeRows = pageState?.data?.map((stock) => ({
    id: stock._id,
    productName: stock?.productName,
    expectedQuantity: stock?.expectedQuantity,
    actualQuantity: stock?.actualQuantity,
    variance: stock?.expectedQuantity - stock?.actualQuantity,
    changed: false,
  }));

  //   update the actual stock;

  const updateStock = (stock, e) => {
    setTimeout(() => {
      const value = Number(e.target.value);
      const stockId = stock?.id;

      const stockItems = [...pageState?.data];
      // get index;
      const currentIndex = stockItems.findIndex((stock) => stock._id === stockId);
      stockItems[currentIndex].actualQuantity = value * 1;
      stockItems[currentIndex].changed = true;

      setPageState({ ...pageState, data: stockItems });
      // check and update local storage;

      const localItems = JSON.parse(localStorage.getItem("stockTakeItems"));
      if (localItems) {
        const newLocalItems = [...localItems];

        // get Index of Changing item;
        const newIndex = newLocalItems.findIndex((stock) => stock._id === stockId);

        if (newIndex === -1) {
          newLocalItems.push(stockItems[currentIndex]);
        } else {
          newLocalItems[newIndex].actualQuantity = stockItems[currentIndex].actualQuantity;
          newLocalItems[newIndex].changed = stockItems[currentIndex].changed;
        }
        localStorage.setItem("stockTakeItems", JSON.stringify(newLocalItems));
      } else {
        localStorage.setItem("stockTakeItems", JSON.stringify(stockItems));
      }
    }, 1000);
  };

  const [isError, setError] = useState({ state: false, message: "" });
  const [isMissingQuan, setMissingQuan] = useState({ state: false, message: "" });
  const [isSuccess, setSuccess] = useState({ state: false, message: "" });

  const batchExists = <ErrorAlertRightAligned error={isError.state} message={"Batch already exists!"} />;
  const batchAdded = <SuccessAlertRightAligned sucess={isSuccess.state} message={"Batch added succesfully!"} />;
  const missingQuantity = <ErrorAlertRightAligned error={isMissingQuan.state} message={"Quantity missing!"} />;

  useEffect(() => {
    if (isMissingQuan.state) {
      setTimeout(() => {
        setMissingQuan({ state: false });
      }, 2000);
    }
  }, [isMissingQuan.state]);

  useEffect(() => {
    if (isSuccess.state) {
      setTimeout(() => {
        setSuccess({ state: false });
      }, 2000);
    }
  }, [isSuccess.state]);

  useEffect(() => {
    if (isError.state) {
      setTimeout(() => {
        setError({ state: false });
      }, 2000);
    }
  }, [isError.state]);

  return (
    <div style={{ width: "100%" }}>
      {isError.state && batchExists}
      {isMissingQuan.state && missingQuantity}
      {isSuccess.state && batchAdded}
      <CustomDataGrid customStyles={{height:"480px"}} column={transferColumn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={stockTakeRows} />
    </div>
  );
};

export default StockTakeProducts;
