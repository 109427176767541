import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Link} from "react-router-dom";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(2),
	},
}));

export default function Bills (props) {
	console.log('props ', props)
	const classes = useStyles();
	const [active, setActive] = React.useState(false);
	const handleClick = () => {setActive(!active)};
	return (
		<div>
			<Link style={{textDecoration: 'none', color : '#fff'}} to="/orders/startorder">
				<ListItem button className={classes.nested} onClick={handleClick} style={{ color: active ? '#04f2fc' : '#fff'  }}>
					<div style={{marginRight : '5%'}}>
						<ReceiptLongIcon />
					</div>
					<div>
						<ListItemText primary= "POS" />
					</div>
				</ListItem>
			</Link>
		</div>
	)
}