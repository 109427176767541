import React, { useEffect, useRef, useState } from "react";
import Switch from "@mui/material/Switch";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import titleCon from "../../../common/images/accounting-config.png";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import {LinearProgress} from "@mui/material";

const AccountingModal = (props) => {
  let navigate = useNavigate();
  const accountingData = props.accountingData;
  // console.log("accounting  data", props.accountingData)
  // console.log("accounting  data", accountingData)

  const [accountingOpen, setAccountingOpen] = useState(props.accountingOpen);
  const [radioValue, setRadioValue] = useState("Online");
  const [softwares, setSoftwares] = useState([]);
  const [menuLoading, setMenuLoading] = useState(true);
  const [system, setSystem] = useState("None");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const accountingUrl = `${baseUrl}/api/get_accounting_software/`;
  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };
  let businessId = localStorage.getItem("businessId");
  const handleAccountingConnection = async () => {
    window.close();
    window.open(
      `${baseUrl}/api/connect_to_quickbooks?bId=${businessId}`,
      "_blank",
      "noopener,noreferrer"
    );
  };
  const handleAccountingDisconnection = async () => {
    let disconnectionUrl = `${baseUrl}/api/disconnect_from_quickbooks?bId=${businessId}`;
    await axios.get(disconnectionUrl);
    props.onClose();
    window.location.replace("/Dashboard");
  };

  const [syncLoading, setSyncLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const handleSync = async () => {
    setSyncLoading(true);
    let data = { business_id: businessId };
    let syncUrl = `${baseUrl}/api/sync_products_from_quickbooks`;
    await axios.post(syncUrl, data).then((res) => {
      console.log("sync response", res.data);
      setSyncLoading(false);
      setTrigger(false)
    });
  };

  const handleExport = async () => {
    setExportLoading(true);
    let data = { business_id: businessId };
    let exportUrl = `${baseUrl}/api/products_to_qb`;
    await axios.post(exportUrl, data).then((res) => {
      console.log("export response", res.data);
      setExportLoading(false);
    });
  };

  useEffect(() => {
    setAccountingOpen(props.accountingOpen);
    console.log(props);
  }, [props.accountingOpen]);

  useEffect(() => {
    const caller = async () => {
      return await axios.get(accountingUrl);
    };
    caller().then((res) => {
      setSoftwares(res.data.data);
      setMenuLoading(false);
      setSystem("Quickbooks");
    });
  }, []);

  const wholeModal = {
    borderColor: "transparent",
    borderRadius: "25px",
    backgroundColor: "#fff",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "600px;",
    height: "500px",
  };

  const button = {
    borderColor: "#dc3545",
    borderRadius: "5px",
    backgroundColor: "#dc3545",
    color: "#fff",
    m: 0.5,
  };

  const primButtons = {
    borderColor: "#033341",
    borderRadius: "5px",
    backgroundColor: "#033341",
    color: "#fff",
    m: 0.5,
  };

  const titleLabels = {
    fontWeight: "bold",
  };

  const color = {
    color: "#032541",
  };

  const title = {
    fontWeight: "bold",
    fontSize: 25,
  };

  const [min, setMin] = useState(0);
  const [max, setMax] = useState(100);
  const [current, setCurrent] = useState(0);
  const [trigger, setTrigger] = useState('')

  const makeAsyncCall = async () => {
    try {
      const response = await fetch('https://my-api.com/endpoint');
      // Update the current progress state to the maximum value when the call is complete
      setCurrent(max);
    } catch (error) {
      // Handle any errors that occurred during the call
    }
  }

  // Use the useEffect hook to slowly update the current progress value over time
  useEffect(() => {
    // Set an interval to update the current progress value every 100 milliseconds
    const interval = setInterval(() => {
      // Increment the current progress value by 1, up to the maximum value
      setCurrent(current => Math.min(current + 1, max));
    }, 100);
    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, [max]);

  return (
    <div>
      <Modal
        open={accountingOpen}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={wholeModal}>
          <div
            style={{
              backgroundColor: "#dc3545",
              width: "100%",
              height: 80,
              borderTopLeftRadius: 25,
              borderTopRightRadius: 25,
              marginBottom: 5,
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              justifyItems: "center",
              textAlign: "center",
            }}
          >
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                color: "white",
                fontWeight: "bold",
                fontSize: 25,
                marginTop: 25,
              }}
            >
              Accounting Config
            </div>
          </div>
          <Grid container spacing={1} sx={{ p: 5 }}>
            {/*<Grid container*/}
            {/*      marginBottom={4}*/}
            {/*      direction="column"*/}
            {/*      alignItems="center"*/}
            {/*      justifyContent="center" xs={12}*/}
            {/*>*/}
            {/*    /!*<span style={title}>Accounting Config</span>*!/*/}
            {/*</Grid>*/}

            <Grid item xs={6}>
              <span style={titleLabels}>Accounting</span>
            </Grid>

            <Grid item xs={6}>
              <span>On/Off</span>
              <Switch
                color="success"
                checked={accountingData?.accountingState}
              />
            </Grid>

            <Grid item xs={6}>
              <span style={titleLabels}>Types of Accounting</span>
            </Grid>

            <Grid item xs={6}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={radioValue}
                color="success"
                onChange={handleRadioChange}
                disabled={accountingData?.accountingState}
              >
                <FormControlLabel
                  value="Online"
                  control={<Radio color="success" sx={{ fontSize: 18 }} />}
                  label="Online"
                  sx={color}
                />
                <FormControlLabel
                  value="Desktop"
                  control={<Radio color="success" sx={{ fontSize: 18 }} />}
                  label="Desktop"
                  disabled={true}
                  sx={color}
                />
              </RadioGroup>
            </Grid>

            <Grid item xs={6}>
              <span style={titleLabels}>Accounting System</span>
            </Grid>

            <Grid item xs={6}>
              {/*<InputLabel id="demo-simple-select-autowidth-label">Select System</InputLabel>*/}

              {menuLoading ? (
                <Select
                  id="demo-simple-select"
                  value={system}
                  onChange={(event) => {
                    setSystem(event.target.value);
                  }}
                  label="Select System"
                >
                  <MenuItem value="None">
                    <em>None</em>
                  </MenuItem>
                </Select>
              ) : (
                <Select
                  id="demo-simple-select"
                  value={system}
                  onChange={(event) => {
                    setSystem(event.target.value);
                  }}
                  label="Select System"
                  sx={{
                    borderRadius: 2,
                    width: 200,
                    height: 45,
                    color: "#032541",
                    fontSize: 15,
                  }}
                  disabled={accountingData?.accountingState}
                >
                  <MenuItem value="None">
                    <em>None</em>
                  </MenuItem>
                  {softwares.map((software) => {
                    return (
                      <MenuItem
                        value={software.accountingSoftware}
                        key={software._id}
                      >
                        {software.accountingSoftware}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            </Grid>

            {trigger ? (
            <>
              <Grid item xs={6} mt={1}>
                <span style={titleLabels}>Progress Status</span>
              </Grid>

              <Grid item xs={6} mt={2}>
                <LinearProgress
                  variant="determinate"
                  value={current}
                  min={min}
                  max={max}
                />
              </Grid>
            </>
            ):(
              <>
              </>
            )}

            {accountingData?.accountingState ? (
              <Grid
              item
                xs={12}
                marginTop={6}
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                {exportLoading ? (
                  <LoadingButton
                    sx={primButtons}
                    loading={exportLoading}
                    disabled={exportLoading}
                  >
                    Sync QB Details
                  </LoadingButton>
                ) : (
                  <Button
                    variant="contained"
                    sx={primButtons}
                    onClick={() => {
                      console.log("EXPORT ZED PRODUCTS")
                      handleExport()
                      setTrigger(true)
                    }}
                  >
                    Export Zed Products
                  </Button>
                )}
                {syncLoading ? (
                  <LoadingButton
                    sx={primButtons}
                    loading={syncLoading}
                    disabled={syncLoading}
                  >
                    Sync QB Details
                  </LoadingButton>
                ) : (
                  <Button
                    variant="contained"
                    sx={primButtons}
                    onClick={() => {
                      console.log("SYNC QB DETAILS")
                      handleSync()
                      setTrigger(true)
                    }}
                  >
                    Sync QB Details
                  </Button>
                )}
                <Button
                  variant="contained"
                  sx={button}
                  onClick={() => {
                    console.log("DISCONNECT")
                    handleAccountingDisconnection(),
                    setTrigger(true)
                  }}
                >
                  Disconnect
                </Button>
              </Grid>
            ) : (
              <Grid
              item
                xs={12}
                marginTop={6}
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  sx={button}
                  onClick={() => handleAccountingConnection()}
                >
                  Connect
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
export { AccountingModal };
