import React, { useState, useEffect } from "react";
import { warehousestyles } from "../styles";
import { Grid, Button, Breadcrumbs, Tab, styled, List, ListItem } from "@mui/material";
import HttpComponent from "../../School/MakeRequest";
import CustomDataGrid from "../../products/stockComponents/customDataGrid";
import { Edit } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";

const Activemainstore = () => {
  const navigate = useNavigate();

  const [mainStores, setMainStores] = useState([]);
  // DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], total: 0, page: 1, pageSize: dataGridPageSize });


  const getMainStores = () => {
    HttpComponent({
      method: "GET",
      url: `/api/list_all_stores_by_type_status?storeType=MainStore&status=ACTIVE&page=1&limit=1000`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        console.log(data);

        if (data.status === 200) {
          setPageState({ ...pageState, data: data.response.data, isLoading: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getMainStores();
  }, []);

  // console.log(pageState.data);

  const mainStoresColumn = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "branchName", headerName: "Branch Name", flex: 1 },
    {
      field: "Action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return <Edit style={{ cursor: "pointer" }} onClick={() => navigate(`/mainstores/${'editUser'}/?${params?.row?.storeId}?${params?.row?.branchName}`)} />;
      },
    },
  ];

  const mainStoreData = pageState.data.map((store, index) => ({
    id: index,
    name: store?.name,
    branchName: store?.branchName,
    storeId: store._id,
  }));

  return (
    <div>
      <Grid container width={"100%"}>
        <CustomDataGrid column={mainStoresColumn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={mainStoreData} />
      </Grid>
    </div>
  );
};

export default Activemainstore;
