import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ExpandLess } from "@material-ui/icons";
import { ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import List from "@material-ui/core/List";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { OtherHouses } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function Warehouse(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const userGroup = localStorage.getItem("group");

  return (
    <div>
      <ListItem button onClick={handleClick} style={{ color: open ? '#04f2fc' : '#fff' }}>
        <div style={{ marginRight: "5%" }}>
          <OtherHouses />
        </div>

        <ListItemText primary="Warehouses & Stores " style={{ fontFamily: "Poppins" }} />

        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout={"auto"} unmountOnExit>
        <List component={"div"}>
          <div>
            <ListItem className={classes.nested}>
              <Link style={{ textDecoration: "none", color: "#fff" }} to="/suppliers">
                <ListItemText primary="Suppliers" />
              </Link>
            </ListItem>

            <ListItem className={classes.nested} >
              <Link  style={{ textDecoration: "none", color: "#fff" }} to="/warehouses">
                <ListItemText primary="Warehouses" />
              </Link>
            </ListItem>

            <ListItem className={classes.nested}>
              <Link style={{ textDecoration: "none", color: "#fff" }} to="/mainstores">
                <ListItemText primary="Main stores" />
              </Link>
            </ListItem>

            <ListItem className={classes.nested}>
              <Link style={{ textDecoration: "none", color: "#fff" }} to="/substores">
                <ListItemText primary="Sub stores" />
              </Link>
            </ListItem>
          </div>
        </List>
      </Collapse>
    </div>
  );
}
