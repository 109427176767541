import React, { useState, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import { stockStyles } from "../stockStyling";
import CustomDataGrid from "../customDataGrid";
import { SuccessAlertRightAligned } from "../../../bills/startorderModals/modals";
import { VerticalAlignBottom } from "@material-ui/icons";
import StockFunctions from "../stockEndpoints";
import { useNavigate } from "react-router-dom";

const PurchaseOrderPreview = ({ storeId, supplierId }) => {
  const navigate = useNavigate();
  const stockfunctions = new StockFunctions();
  //DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  const stockOrderColumn = [
    {
      field: "productName",
      headerName: "Product Name",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontWeight: "600" }}>{"Product Name"}</span>;
      },
    },
    {
      field: "requestedQuantity",
      headerName: "Qty Requested",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontWeight: "600" }}>{"Qty Requested"}</span>;
      },
    },
  ];

  const stockOrderRow = pageState?.data?.map((stock) => ({
    id: stock?._id || stock.id,
    requestedQuantity: stock?.qtyRequested,
    productName: stock?.productName,
    productId: stock?._id || stock.id,
  }));

  useEffect(() => {
    const stockOrderproducts = JSON.parse(localStorage.getItem("stockOrderItems"));
    // console.log("stockOrderItems ===<>", stockOrderproducts);
    if (stockOrderproducts) {
      const stockProductsToView = stockOrderproducts.filter((stockItem) => stockItem.changed === true);
      setPageState({ ...pageState, data: stockProductsToView, count: stockProductsToView?.length });
    }
  }, [pageState?.page, dataGridPageSize]);

  const [showDownloadBtn, setShowDownloadBtn] = useState(false);
  const [isSuccess, setSuccess] = useState({ state: false, message: "" });
  const batchAdded = <SuccessAlertRightAligned sucess={isSuccess.state} message={"Purchase order created successfully and email sent!"} />;

  useEffect(() => {
    if (isSuccess.state) {
      setTimeout(() => {
        setSuccess({ state: false });
      }, 3000);
    }
  }, [isSuccess.state]);

  const sendPurchaseOrder = () => {
    const stockfunctions = new StockFunctions();
    if (storeId && supplierId && stockOrderRow)
      stockfunctions.sendPurchaseOrder(supplierId, storeId, stockOrderRow).then((data) => {
        if (data?.status === 200) {
          localStorage.removeItem("stockOrderItems");
          setShowDownloadBtn(true);
          setSuccess({ state: true });
          navigate(`/stockOrder`);
        }
      });
  };

  const downloadPurchaseOrder = () => {
    var win = window.open("", "print", 'height=720,width="100%"');

    win.document.write(document.getElementById("creditNote").innerHTML);

    win.document.close();
    win.focus();
    win.print();
  };

  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState({email:"", name:"", phone:""})

  // getPurchaseOrderDetails;
  useEffect(() => {
    stockfunctions
      .getSuppliers(1, 1000, 'ACTIVE')
      .then((data) => {
        if (data?.status === 201) {
          const suppliers = data?.response?.data?.filter((supplier) => supplier?._id  === supplierId)
          const { email, name, phone,  } = suppliers[0]
          setPurchaseOrderDetails({ ...purchaseOrderDetails, email : email, name: name, phone: phone });
        }
      })
      .catch((e) => {
        // console.log(e.message);
      });
  }, [supplierId]);

  return (
    <div style={{ width: "100%", fontFamily: "Poppins" }}>
      {isSuccess.state && batchAdded}
      <Grid container direction={"row"} justifyContent={"flex-end"} margin={"0px 0px 1% 0px"}>
      <Grid item>
          {showDownloadBtn === false && (
            <Button onClick={() => sendPurchaseOrder()} style={stockStyles.nextButtonActive}>
              {" "}
              Send
            </Button>
          )}
        </Grid>
        <Grid item>{showDownloadBtn && <VerticalAlignBottom fontSize="large" style={{ backgroundColor: "rgba(3, 37, 65, 0.05)", cursor: "pointer" }} onClick={() => downloadPurchaseOrder()} />}</Grid>
      </Grid>
      <div id="creditNote">
        <Grid container direction={"column"} style={{ backgroundColor: "rgba(3, 37, 65, 0.05)" }} padding={"1%"}>
          <Grid item>
            <span style={stockStyles.purchaseOrderText}>PURCHASE ORDER</span>
          </Grid>

          <Grid item>
            <p style={stockStyles.purchaseOrderDateHolder}>Date</p>
            <p style={stockStyles.purchaseOrderDate}>{new Date().toDateString()}</p>
          </Grid>
        </Grid>

        <Grid container width={"100% "} padding={"1%"}>
          <Grid item>
            <p style={stockStyles.purchaseOrderDateHolder}>To:</p>
            <p style={stockStyles.purchaseOrderDate}>{purchaseOrderDetails?.name}</p>
            <p style={stockStyles.purchaseOrderDate}>{purchaseOrderDetails?.email}</p>
            {/* <p style={stockStyles.purchaseOrderDate}>Malibu, Warehouse</p> */}
            <p style={stockStyles.purchaseOrderDate}>{purchaseOrderDetails?.phone}</p>
          </Grid>
        </Grid>

        <Grid container width={"100% "} padding={"1%"} style={{ borderBottom: "1px solid grey" }} margin={"0px 0px 1% 0px"}>
          <Grid item width={"100%"}>
            <CustomDataGrid customStyles={{ height: "300px", width: "100%", margin: "0% 0% 1% 0%", border: "none" }} column={stockOrderColumn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={stockOrderRow} />
          </Grid>
        </Grid>

        <Grid container direction={"row"} alignContent={"center"} justifyContent={"center"} margin={"0px 0px 1% 0px"}>
          <Grid item>
            <p>This is a system-generated invoice is created without any altercation whatsoever</p>
          </Grid>
        </Grid>

        <Grid container direction={"row"} alignContent={"center"} justifyContent={"center"} margin={"0px 0px 1% 0px"}>
          <Grid item>
            <p>
              Powered by <span style={stockStyles.purchaseOrderDateHolder}>ZED payments Limited.</span> info@zed.business . v1.0.2
            </p>
          </Grid>
        </Grid>
      </div>

      {/* <Grid container direction={"row"} alignContent={"center"} justifyContent={"center"}>
        <Grid item>
          {showDownloadBtn === false && (
            <Button onClick={() => sendPurchaseOrder()} style={stockStyles.nextButtonActive}>
              {" "}
              Send
            </Button>
          )}
        </Grid>
      </Grid> */}
    </div>
  );
};

export default PurchaseOrderPreview;
