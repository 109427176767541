import React, { useEffect, useState } from "react";
import HttpComponent from "../School/MakeRequest";
import { DataGrid } from "@mui/x-data-grid";
import { customerstyles } from "../customerAccounts/customerComponents/styles";
import { useNavigate } from "react-router-dom";
import DateFormatter from "../../utils/dateFormatter";

const UnpaidPartialInvoices = () => {
  const navigate = useNavigate();
  // DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [customerId, setCustomerId] = useState("");
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  // Base Url
  const baseUrl = process.env.REACT_APP_BASE_URL;

  // get partial and Unpaid invoices
const fetchAllPartialAndUnpaidInvoices = async () => {
  // console.log("fetch branch Token =======>", token);
  try {
      const response = await fetch(baseUrl + `/api/v1/invoice/partial_and_unpaid?limit=${dataGridPageSize}&page=${pageState.page}`, {
          method: "GET",
          headers: {
              "X-Authorization": localStorage.getItem("X-Authorization"),
              "Content-Type": "application/json",
              Accept: "application/json",
          },
      });
      if (response.status === 200) {
        const data = await response.json();
        setPageState({ ...pageState, isLoading: false, data: data.data, count: data.count });
        console.log('partial and unpaid invoices data here', data.data);
      }
  } catch (e) {
      console.log(e);
  }
}
useEffect(() =>{
  fetchAllPartialAndUnpaidInvoices();
}, [pageState.pageSize, pageState.page, dataGridPageSize])

  return (
    <div>
      <DataGrid
        style={customerstyles.dataGridStyling}
        rows={pageState?.data?.map((invoice) => ({
          id: invoice?._id,
          invoiceNumber: invoice?.invoiceNumber,
          sendToFirstName: invoice?.sendToFirstName,
          createdAt: DateFormatter(invoice?.createdAt),
          invoiceAmount: invoice?.invoiceAmount,
          invoiceType: invoice?.invoiceType,
          customerId: invoice?.customerId,
          invoiceStatus: invoice?.invoiceStatus,
          invoiceBalance: invoice?.invoiceBalance,
          paidAmount: invoice?.invoiceAmount - invoice?.invoiceBalance,
        }))}
        columns={[
          {
            field: "invoiceNumber",
            headerName: "Invoice No",
            flex: 1,
            renderCell: (params) => {
              return (
                <span style={{ color: "red", cursor: "pointer" }} onClick={() =>  navigate(`/invoice/${params.row.invoiceNumber}`)}>
                  {params.row.invoiceNumber}
                </span>
              );
            },
          },
          { field: "sendToFirstName", headerName: "To", flex: 1 },
          { field: "createdAt", headerName: "Date & Time", flex: 1 },
          { field: "invoiceAmount", headerName: "Invoice Amount", flex: 1 },
          { field: "paidAmount", headerName: "Paid Amount", flex: 1 },
          { field: "invoiceBalance", headerName: "Balance", flex: 1 },
          { field: "invoiceType", headerName: "Invoice Type", flex: 1 },
          { field: "invoiceStatus", headerName: "Status", flex: 1, renderCell:(params) => {
              return <span style={params?.row?.invoiceStatus === "Paid" ? {color:"green"} : params?.row?.invoiceStatus  === "Unpaid" ? {color:"red"} : {color:"orange"}} >{params?.row.invoiceStatus}</span>
          } },
        ]}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        rowCount={pageState?.count}
        loading={pageState.isLoading}
        pagination
        page={pageState.page - 1}
        pageSize={dataGridPageSize}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize }));
        }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        // checkboxSelection
        // disableRowSelectionOnClick={true}
        onSelectionModelChange={(name) => {
          //   setSupplierToBeSuspendedObj(name)
        }}
      />
    </div>
  );
}

export default UnpaidPartialInvoices;