import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Input, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import loginLogo from "../../images/zedLogo.png";
import jwt from "jwt-decode";
import PhoneInput from "react-phone-input-2";
import "./phoneBootstrap.css";
import Button from "@mui/material/Button";
import { logInUser, logoutUser, changeToken } from "../../features/userSlice";
import { handleBusinessCategory } from "../../features/businessCategorySlice";
import { handleBranchDetails } from "../../features/branchSlice";
import { handleBusinessBranches } from "../../features/businessBranchesSlice";
import loginImage from "../../common/images/login_image.svg";
import googleIcon from "../../common/images/google-icn.svg";
import facebookIcon from "../../common/images/facebook-icn.svg";
import emailIcon from "../../common/images/email-icn.svg";
import mobileIcon from "../../common/images/mobileicon.svg";
import TwitterIcon from "../../common/images/twitter-icon.svg";
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, FacebookAuthProvider, GoogleAuthProvider, TwitterAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { handleBusinessUnitConfigId, handleUpdateBusinessData } from "../../features/tenantBusinessSlice";
import HttpComponent from "../School/MakeRequest";

// Initialize Firebase
const firebaseConfig = {

    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

const baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
    imgRes: {
        [theme.breakpoints.up("md")]: {},
        [theme.breakpoints.down("sm")]: {
            display: "none",
            alignItems: "center",
            width: "100%",
            objectFit: "contain",
            height: "100%",
        },
    },
    textf: {
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    gridLayout: {
        [theme.breakpoints.up("md")]: {
            paddingRight: "4rem",
        },
    },
}));

const SignInOptions = () => {
    const dispatch = useDispatch();

    const [errorNotificationOpen, setErrorNotificationOpen] = React.useState(false);
    const handleErrorNotificationClick = () => { setErrorNotificationOpen(true); };
    const handleErrorNotificationClose = () => { setErrorNotificationOpen(false); };

    const [successNotificationOpen, setSuccessNotificationOpen] = React.useState(false);
    const handleSuccessNotificationClick = () => { setSuccessNotificationOpen(true); };
    const navigate = useNavigate();
    const classes = useStyles();

    const handleSuccessNotificationClose = (event, reason) => { if (reason === "clickaway") { return; } setSuccessNotificationOpen(false); };


    const [openSuccess, setSuccessLogin] = useState(false);
    const [openInvalid, setInvalidPassword] = useState(false);
    const [openSuspended, setUserSuspended] = useState(false);
    const [openNotFound, setNonExistingUser] = useState(false);
    const [token, setToken] = useState('')
    // Get Bar Man Status
    const fetchBarManConfigs = async () => {
        try {
            const response = await fetch(baseUrl + "/api/get_enable_bar_and_kitchen", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            });
            await response
                .json()
                .then((data) => {
                    setBarManState(data.status);
                    // localStorage.setItem('barMan', data.status)
                    localStorage.setItem("barMan", "undefined");
                })
                .catch((err) => {
                    console.log("error getting stocks", err.message);
                });
        } catch (e) {
            console.log(e.message);
        }
    };

    function getBusinessUnitConfig(token, businessName) {
        HttpComponent({
            method: "GET",
            url: "/api/get_business_unit_config_list",
            token: token,
        })
            .then((data) => {
                console.log();
                if (data.status === 201) {
                    localStorage.setItem("businessUnitConfigId", data.response.data[0]?._id);
                    console.log(data.response.data[0]?._id, `fsbfbdbndfndfndfbn`);
                    dispatch(handleBusinessUnitConfigId(data.response.data[0]?._id));
                    dispatch(handleUpdateBusinessData(businessName));
                } else {
                    console.log(`Error Getting BusinessUnitConfigList!`);
                }
            })
            .catch((error) => {
                console.error(error.message);
            });
    }


    // get the google info
    const handleSignInWithGoogle = async () => {
        try {
            // Sign in with Google
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);

            // Access the user object
            const user = result.user;
            const accessToken = result.user.accessToken

            if (accessToken) {
                // console.log(accessToken, 'accesstoken');
                await handleFirebaseAuthetication(accessToken)
                setToken(accessToken)
            }
            console.log('Successfully signed in with Google:', user);
        } catch (error) {
            console.error('Error signing in with Google:', error);
        }
    };

    const handleSignInWithFacebook = async () => {
        try {
            // Sign in with Facebook
            const provider = new FacebookAuthProvider();
            const result = await signInWithPopup(auth, provider);
            console.log('results', result);

            // Get the Facebook access token
            // Access the user object
            const user = result.user;
            const accessToken = result.user.accessToken
            if (accessToken) {
                // console.log(accessToken, 'accesstoken');
                await handleFirebaseAuthetication(accessToken)
                setToken(accessToken)
            }

        } catch (error) {
            console.error('Error signing in with Facebook:', error);
        }
    };

    const handleSignInWithTwitter = async () => {
        // sign in with twitter 
        const provider = new TwitterAuthProvider();
        const result = await signInWithPopup(auth, provider)
        console.log('results', result);
        const user = result.user;
        const accessToken = result.user.accessToken
        console.log('accessToken Twitter', accessToken);

        if (accessToken) {
            // console.log(accessToken, 'accesstoken');
            await handleFirebaseAuthetication(accessToken)
            setToken(accessToken)
        }


    }


    const handleFirebaseAuthetication = async (accessToken) => {
        // console.log('We are here', accessToken);
        try {
            const response = await fetch(`${baseUrl}/api/v1/users/firebase/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({ firebaseIdToken: accessToken, fcmToken: "xyzz", deviceId: "WEB" }),
            });
            const data = await response.json();

            if (response.status == 401) {
                console.log('We here banah');
                setErrorNotificationOpen(true)

            }

            console.log(data, 'Data here');


            const switchBranch = async (branch_id, oldToken, business_id) => {
                // console.log('Tumefika switch business');

                try {
                    const response = await fetch(baseUrl + `/api/get_business_token`, {
                        method: "POST",
                        headers: {
                            "X-Authorization": oldToken,
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                        body: JSON.stringify({
                            branch_id,
                            oldToken,
                            business_id,
                        }),
                    });
                    if (response.status === 401) return (window.location.href = "/logout");
                    const result = await response.json();
                    console.log('Tumefika switch business', result);

                    dispatch(changeToken({ token: result.data.newToken }));
                    localStorage.removeItem("X-Authorization");
                    localStorage.setItem("X-Authorization", result.data.newToken);
                    fetchBranches(result.data.newToken);
                } catch (e) {
                    console.log("Error Switching branch", e.message);
                }
            };


            const fetchBranches = async (token) => {
                try {
                    const response = await fetch(baseUrl + "/api/getBusinessBranchesByBid", {
                        method: "GET",
                        headers: {
                            "X-Authorization": token,
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                    });
                    const data = await response.json();
                    console.log('Tumefika switch business', data);

                    if (response.status === 401) {
                        return navigate("/logout");
                    }
                    if (response.status === 200) {
                        dispatch(handleBusinessBranches({ branchesArr: data.data }));
                    }
                } catch (e) {
                    console.log(e);
                }
            };

            handleClose();
            switch (data.message !== undefined ? data.message : data.error) {

                case "Invalid Password":
                    setInvalidPassword(true);
                    handleErrorNotificationClick();
                    break;
                case "Account Suspended for Numerous Login Attempts":
                    setUserSuspended(true);
                    handleErrorNotificationClick();
                    break;
                case "User not found ...":
                    setNonExistingUser(true);
                    handleErrorNotificationClick();
                    break;
                case "User Logged in Successfully!":
                    if (data.businessCategory == "Rental" || localStorage.getItem("businessCategory") == "Rental") {
                        getBusinessUnitConfig(data.token, data.businessName);
                    }
                    // if (data?.state.toLowerCase().includes("new")) {
                    //     localStorage.setItem("email", data?.email);
                    //     return navigate("/forgotReset");
                    // }
                    if (data?.group === undefined) {
                        localStorage.setItem("X-Authorization", data?.token);
                        localStorage.setItem("group", "User");
                        localStorage.setItem("zedAccountingId", data.zedAccountingId);
                        localStorage.setItem("email", data?.email);
                        localStorage.setItem("state", data?.state || "Modified");
                        localStorage.setItem('workflow', 'null')
                        localStorage.setItem("businessID", data?.defaultBusinessId);
                        localStorage.setItem("username", data?.username);
                        localStorage.setItem("businessName", data?.businessName || 'All');
                        localStorage.setItem("businessId", data?.businessNumber);
                        localStorage.setItem("userId", data?.userId);
                        localStorage.setItem("BranchID", data?.branchId || 'null');
                        localStorage.setItem("customerId", data?.customerId);
                        localStorage.setItem("businessCategory", data?.businessCategory);
                        dispatch(logInUser({ isLoggedIn: true, ...data, branchId: null, businessName: "All", state: "Modified", group: "User" }));
                        return navigate("/userDashboard");
                    }
                    setSuccessLogin(true);
                    handleSuccessNotificationClick();
                    const { branchId } = jwt(data?.token) || null;
                    console.log('Branch id', branchId);
                    dispatch(logInUser({ isLoggedIn: true, ...data, branchId: branchId }));
                    dispatch(handleBranchDetails({ _id: branchId }));
                    checkFormStatus(data?.token);
                    switchBranch(branchId, data.token, data.defaultBusinessId);
                    localStorage.setItem("X-Authorization", data.token);
                    localStorage.setItem("group", data.group);
                    localStorage.setItem("zedAccountingId", data.zedAccountingId);
                    localStorage.setItem("email", data.email);
                    localStorage.setItem("state", data.state);
                    localStorage.setItem("businessID", data.defaultBusinessId);
                    localStorage.setItem("username", data.username);
                    localStorage.setItem("businessName", data.businessName);
                    localStorage.setItem("businessId", data.businessNumber);
                    localStorage.setItem("userId", data.userId);
                    localStorage.setItem("BranchID", branchId);
                    localStorage.setItem("customerId", data.customerId);
                    localStorage.setItem("businessCategory", data.businessCategory);
                    await fetchBarManConfigs();
                    await fetchBusinessCategory();
                    // checkSetupStatus(data.token)
                    if (data.businessCategory) {
                        if (data.businessCategory.toLowerCase()?.includes("rental") && localStorage.getItem("workflow")?.toLowerCase()?.includes("complete")) {
                            return navigate("/dashboard");
                        } else if (data.businessCategory.toLowerCase()?.includes("rental") && !localStorage.getItem("workflow")?.toLowerCase()?.includes("complete")) {
                            return navigate("/rentalSetup");
                        }
                    }

                    if (data.group === "Cashier") {
                        navigate("/orders");
                    }
                    if (data.group == "User") {
                        // navigate("/userDashboard");
                        return null;
                    }

                    navigate("/dashboard", { state: { fromLogin: true } });
                    break;


            }


        } catch (error) {

        }

    }
    const checkFormStatus = (token) => {
        console.log("workflow has been called");
        HttpComponent({
            method: "GET",
            url: "/api/get_setup_status",
            body: null,
            token: token,
        })
            .then((data) => {
                console.log("here store is data", data);
                if (data.status === 201) {
                    console.log("state", data.response.data.workflowState);
                    let state = data.response.data.workflowState;
                    localStorage.setItem("workflow", state);
                } else {
                }
            })
            .catch((error) => {
                console.error(error.message);
            });
    };

    const fetchBusinessCategory = async () => {
        const response = await fetch(baseUrl + "/api/get_business_category", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-Authorization": localStorage.getItem("X-Authorization"),
            },
        });
        const { businessCategory } = await response.json();
        dispatch(handleBusinessCategory(businessCategory));
    };
    const handleClose = () => {
        dispatch(logoutUser());
        setSuccessLogin(false);
        setInvalidPassword(false);
        setUserSuspended(false);
        setNonExistingUser(false);
    };
    // Alerts
    let alertType;
    if (openInvalid === true) {
        alertType = <ErrorAlert message={"Invalid Credentials!"} open={errorNotificationOpen} onClose={handleErrorNotificationClose} horizontal={"right"} vertical={"top"} />;
    } else if (openNotFound === true) {
        alertType = <ErrorAlert message={`User Doesn't Exist! kindly sign up`} open={errorNotificationOpen} onClose={handleErrorNotificationClose} horizontal={"right"} vertical={"top"} />;
    } else if (openSuspended === true) {
        alertType = <ErrorAlert message={"User Suspended!"} open={errorNotificationOpen} onClose={handleErrorNotificationClose} horizontal={"right"} vertical={"top"} />;
    } else if (openSuccess === true) {
        alertType = <SuccessAlert message={"Login Success"} open={successNotificationOpen} onClose={handleSuccessNotificationClose} horizontal={"right"} vertical={"top"} />;
    }

    return (
        <Grid container justifyContent={"center"} style={{ fontFamily: "Poppins" }}>

            <Grid container direction={"row"} style={{ paddingLeft: "10%", paddingRight: "10%" }}>
                {/*Logo image*/}
                <Grid item>
                    <img src={loginLogo} alt="logo" style={{ marginTop: "10%" }} />
                </Grid>
            </Grid>


            <Grid container justifyContent={"space-between"} direction={"row"} spacing={1} column={1} alignContent={"flex-start"} style={{ marginTop: "5%", paddingLeft: "5%" }}>
                <Grid item sm={12} lg={6} md={12} xl={6} xs={12}>
                    <img src={loginImage} className={`img-fluid ${classes.imgRes}`} alt={"login image"} style={{ width: "50rem" }} />
                </Grid>


                <Grid item style={{ marginTop: "5%", width: "500px" }} className={classes.gridLayout} sm={12} lg={6} md={12} xl={6} xm={12}>

                    <Grid container direction={"column"} justifyContent={"space-between"} spacing={2} alignContent={"flex-start"}>

                        <Grid container justifyContent={"center"} alignContent={"center"} style={{ marginTop: "5%", fontSize: "20px", fontWeight: "bold", color: "#032541" }}>
                            <span> Let’s Sign you in!  </span>
                        </Grid>
                    </Grid>

                    <Grid container direction={"column"} justifyContent={"space-between"} spacing={2} alignContent={"flex-start"} >
                        <Grid container justifyContent={"center"} alignContent={"center"} style={{ marginTop: "2%", fontSize: "15px", fontWeight: "normal", color: "#707070" }}>
                            <div className="div" style={{ width: "400px" }}>
                                <span className="text-center">Select your preferred method and create your account with us.</span>

                            </div>
                        </Grid>
                    </Grid>


                    <Grid style={{ display: "flex", alignContent: "center", justifyContent: "center", marginTop: "15px" , cursor:"pointer"}} onClick={handleSignInWithGoogle}>
                        <div style={{ display: "flex", width: "370px", padding: "15px", height: "60px", boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px' }} >
                            <img src={googleIcon} className="pl-5" alt="" height={"30px"} />
                            <span className="ml-2">Sign in with Google</span>
                        </div>
                    </Grid>
                    <Grid style={{ display: "flex", alignContent: "center", justifyContent: "center", marginTop: "10px", cursor:"pointer" }} onClick={handleSignInWithFacebook}>
                        <div style={{ display: "flex", width: "370px", padding: "15px", boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px' }} >
                            <img src={facebookIcon} className="pl-5" height={"30px"} alt="" />
                            <span className="ml-2">Sign in with Facebook</span>
                        </div>
                    </Grid>
                    {/* handleSignUpWithTwitter */}
                    {/* <Grid style={{ display: "flex", alignContent: "center", justifyContent: "center", marginTop: "10px" }} onClick={handleSignInWithTwitter}>
                        <div style={{ display: "flex", width: "370px", padding: "15px", boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px' }} >
                            <img src={TwitterIcon} className="pl-5" height={"30px"} alt="" />
                            <span className="ml-2">Sign in with Twitter</span>
                        </div>
                    </Grid> */}
                    <Grid style={{ display: "flex", alignContent: "center", justifyContent: "center", marginTop: "10px", cursor:"pointer" }} >
                        <Link to='/login/phone' style={{ textDecoration: "None" }}>
                            <div style={{ display: "flex", width: "370px", padding: "15px", boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px' }} >
                                <img src={mobileIcon} className="pl-5" height={"30px"} alt="" />
                                <span className="ml-2">Sign in with phone</span>
                            </div>
                        </Link>
                    </Grid>

                    <Grid style={{ display: "flex", alignContent: "center", justifyContent: "center", marginTop: "10px", cursor:"pointer" }} >
                        <Link to='/login/email' style={{ textDecoration: "None" }}>
                            <div style={{ display: "flex", width: "370px", padding: "15px", boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px' }} >
                                <img src={emailIcon} className="pl-5" height={"30px"} alt="" />
                                <span className="ml-2">Sign in with email</span>
                            </div>
                        </Link>
                    </Grid>
                    {/*New user Signup*/}
                    <Grid container justifyContent={"center"} alignContent={"center"} style={{ marginTop: "3%", fontSize: "15px", color: "#032541" }}>
                        <span>
                            Don't have an account ?

                            <Link style={{ fontWeight: "bold", textDecoration: "none" }} to={"/signup"}>
                                Sign Up.
                            </Link>
                        </span>
                    </Grid>
                    {/* </Grid> */}
                </Grid>

                {/*fake Columns*/}
                <Grid item> <ErrorAlert message={`User Doesn't Exist! kindly sign up`} open={errorNotificationOpen} onClose={handleErrorNotificationClose} horizontal={"right"} vertical={"top"} ></ErrorAlert></Grid>
                <Grid item></Grid>
                <Grid item></Grid>
                {/* </Grid> */}
            </Grid>
        </Grid>
    );
};

export default SignInOptions;
