import React, { useEffect, useState } from "react";
import { Box, Breadcrumbs, Button, Tab, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CustomSelectField from "./CustomSelectField";
import CustomSearchInput from "./CustomSearchInput";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import HttpComponent from "./MakeRequest";
import { useSelector, useDispatch } from "react-redux";
import CustomTable from "./CustomTable";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ReplayIcon from "@mui/icons-material/Replay";
import { useLocation, useNavigate } from "react-router-dom";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import ExportMenu from "./ExportMenu";
import { changeTab } from "../../features/tabSlice";

const breadcrumbs = [
  <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
    Dashboard
  </Typography>,
  <Typography key={"Invoices"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
    Invoices
  </Typography>,
];

const SchoolInvoiceBatches = () => {
  const dispatch = useDispatch();
  const { tabValue } = useSelector((store) => store.tab);
  // const [tabValue, setTabValue] = useState("APPROVED");
  const [tableData, setTableData] = useState([]);

  const [formData, setFormData] = useState({
    search: "",
    filter: "batchList",
  });

  const handleTabChange = (event, newValue) => {
    dispatch(changeTab(newValue));
  };
  const filterOption = [
    { value: "batchList", label: "Batch List" },
    { value: "invoiceList", label: "Invoice List" },
  ];

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const { userId, X_Authorization } = useSelector((store) => store.user);
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });
  const [dataToShow, setDataToShow] = useState([]);
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });
  const [gridLoading, setGridLoading] = useState(false);

  const fetchBatches = () => {
    setGridLoading(true);
    HttpComponent({
      method: "GET",
      url: `/api/get_invoice_batches?status=${tabValue}`,
      body: null,
      token: X_Authorization,
    })
      .then((data) => {
        console.log("here store is data", data);
        if (data.status === 201) {
          setTableData(data.response.data);
          setGridLoading(false);
        } else {
          console.error("Error setting info");
          setErrorShow({ state: true, message: data.response.message });
          setGridLoading(false);
        }
      })
      .catch((error) => {
        console.error(error.message);
        setGridLoading(false);
      });
  };

  const fetchInvoices = () => {
    setGridLoading(true);
    console.log("fetching invoices");
    HttpComponent({
      method: "GET",
      url: `/api/get_student_invoices_by_batch_status?page=1&limit=10000000&status=${tabValue}`,
      body: null,
      token: X_Authorization,
    })
      .then((data) => {
        console.log("here store is data", data);
        if (data.status === 200) {
          setTableData(data.response.data);
          console.log("working here");
          setGridLoading(false);
        } else {
          console.error("Error setting info");
          setErrorShow({ state: true, message: data.response.message });
          setGridLoading(false);
        }
      })
      .catch((error) => {
        console.error(error.message);
        setGridLoading(false);
      });
  };

  const setData = () => {
    let searchable = [...tableData];
    let result;
    console.log(formData.filter);
    if (formData.search !== "") {
      if (formData.filter == "batchList") {
        result = searchable.filter((batch) =>
          batch.batchNo.toLowerCase().includes(formData.search)
        );
      } else if (formData.filter == "invoiceList") {
        result = searchable.filter((invoice) =>
          invoice.invoiceNumber.toLowerCase().includes(formData.search)
        );
      }
      console.log("search", result);
      setDataToShow(result);
    } else {
      setDataToShow(tableData);
    }
  };

  function formatDate(inputDate) {
    const dateObj = new Date(inputDate);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return `${dateObj.toLocaleDateString(
      "en-US",
      options
    )} ${dateObj.toLocaleTimeString()}`;
  }

  const pendingColumns = [
    {
      headerName: "Batch No",
      field: "batchNo",
      flex: 1,
      renderCell: (params) => (
        <Typography
          onClick={() => navigate(`/school/invoices/batch/${params.row._id}`)}
          sx={{ color: "#33699d", textDecoration: "underline" }}
        >
          {params.row.batchNo}
        </Typography>
      ),
    },
    {
      headerName: "Created On",
      field: "createdAt",
      flex: 1,
      renderCell: (params) => (
        <Typography>{formatDate(params.row.createdAt)}</Typography>
      ),
    },
    { headerName: "Total Invoices ", field: "totalInvoices", flex: 1 },
    { headerName: "Total Amount", field: "totalAmount", flex: 1 },
    {
      field: "Action",
      headerName: "Action",
      renderCell: (params) => (
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              width: "1.313rem",
              height: "1.313rem",
              borderRadius: "50%",
              border: "1px solid #17ae7b",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginX: 0.5,
              cursor: "pointer",
            }}
            onClick={() => actionBatch("approve", params.row._id)}
          >
            <DoneIcon
              sx={{ color: "#17ae7b", alignSelf: "center", fontSize: "1rem" }}
            />
          </Box>
          <Box
            sx={{
              width: "1.313rem",
              height: "1.313rem",
              borderRadius: "50%",
              border: "1px solid #dc3545",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginX: 0.5,
              cursor: "pointer",
            }}
            onClick={() => actionBatch("decline", params.row._id)}
          >
            <CloseIcon
              sx={{ color: "#dc3545", alignSelf: "center", fontSize: "1rem" }}
            />
          </Box>
        </Box>
      ),
    },
  ];

  const approvedColumns = [
    {
      headerName: "Batch No",
      field: "batchNo",
      flex: 1,
      renderCell: (params) => (
        <Typography
          onClick={() => navigate(`/school/invoices/batch/${params.row._id}`)}
          sx={{ color: "#33699d", textDecoration: "underline" }}
        >
          {params.row.batchNo}
        </Typography>
      ),
    },
    {
      headerName: "Approved On",
      field: "updatedAt",
      flex: 1,
      renderCell: (params) => (
        <Typography>{formatDate(params.row.updatedAt)}</Typography>
      ),
    },
    { headerName: "Total Invoices ", field: "totalInvoices", flex: 1 },
    { headerName: "Total Amount", field: "totalAmount", flex: 1 },
    { headerName: "Paid Invoices", field: "paidInvoices", flex: 1 },
    {
      headerName: "Paid Amount",
      field: "amountPaid",
      flex: 1,
      renderCell: (params) => (
        <Typography>KES {params.row.amountPaid}</Typography>
      ),
    },
    { headerName: "Invoices Due", field: "invoicesDue", flex: 1 },
    {
      headerName: "Amount Due",
      field: "amountDue",
      flex: 1,
      renderCell: (params) => (
        <Typography>KES {params.row.amountDue}</Typography>
      ),
    },
  ];

  const declinedColumns = [
    {
      headerName: "Batch No",
      field: "batchNo",
      flex: 1,
      renderCell: (params) => (
        <Typography
          onClick={() => navigate(`/school/invoices/batch/${params.row._id}`)}
          sx={{ color: "#33699d", textDecoration: "underline" }}
        >
          {params.row.batchNo}
        </Typography>
      ),
    },
    {
      headerName: "Created On",
      field: "createdAt",
      flex: 1,
      renderCell: (params) => (
        <Typography>{formatDate(params.row.createdAt)}</Typography>
      ),
    },
    {
      headerName: "Declined On",
      field: "updatedAt",
      flex: 1,
      renderCell: (params) => (
        <Typography>{formatDate(params.row.updatedAt)}</Typography>
      ),
    },
    { headerName: "Total Invoices ", field: "totalInvoices", flex: 1 },
    { headerName: "Total Amount", field: "totalAmount", flex: 1 },
    {
      field: "Action",
      headerName: "Action",
      renderCell: (params) => (
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              width: "1.313rem",
              height: "1.313rem",
              borderRadius: "50%",
              border: "1px solid #17ae7b",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginX: 0.5,
              cursor: "pointer",
            }}
            onClick={() => actionBatch("restore", params.row._id)}
          >
            <ReplayIcon
              sx={{ color: "#17ae7b", alignSelf: "center", fontSize: "1rem" }}
            />
          </Box>
        </Box>
      ),
    },
  ];

  const approvedInvoiceColumns = [
    {
      headerName: "Invoice No",
      field: "invoiceNumber",
      flex: 1,
      renderCell: (params) => (
        // onClick={()=>navigate(`/school/invoices/batch/${params.row._id}`)}
        <Typography sx={{ color: "#33699d", textDecoration: "underline" }}>
          {params.row.invoiceNumber}
        </Typography>
      ),
    },
    { headerName: "Admission No", field: "studentNumber", flex: 1 },
    { headerName: "Student Name", field: "studentName", flex: 1 },
    { headerName: "Grade", field: "grade", flex: 1 },
    { headerName: "Term", field: "term", flex: 1 },
    {
      headerName: "Invoice Amount",
      field: "invoiceAmount",
      flex: 1,
      renderCell: (params) => (
        <Typography>KES {params.row.invoiceAmount}</Typography>
      ),
    },
    {
      headerName: "Amount Paid",
      field: "amountPaid",
      flex: 1,
      renderCell: (params) => (
        <Typography>KES {params.row.amountPaid}</Typography>
      ),
    },
    {
      headerName: "Amount Due",
      field: "amountDue",
      flex: 1,
      renderCell: (params) => (
        <Typography>KES {params.row.amountDue}</Typography>
      ),
    },
    {
      headerName: "Delivery Status",
      field: "status",
      renderCell: (params) => <Typography>Sent</Typography>,
    },
  ];

  const declinedInvoiceColumns = [
    {
      headerName: "Invoice No",
      field: "invoiceNumber",
      flex: 1,
      renderCell: (params) => (
        // onClick={()=>navigate(`/school/invoices/batch/${params.row._id}`)}
        <Typography sx={{ color: "#33699d", textDecoration: "underline" }}>
          {params.row.invoiceNumber}
        </Typography>
      ),
    },
    { headerName: "Admission No", field: "studentNumber", flex: 0.5 },
    { headerName: "Student Name", field: "studentName", flex: 1 },
    { headerName: "Grade", field: "grade", flex: 1 },
    { headerName: "Term", field: "term", flex: 1 },
    {
      headerName: "Invoice Amount",
      field: "invoiceAmount",
      flex: 1,
      renderCell: (params) => (
        <Typography>KES {params.row.invoiceAmount}</Typography>
      ),
    },
  ];

  useEffect(() => {
    setData();
    console.log("search", formData.search);
  }, [formData.search, tableData]);

  const actionBatch = (action, batchId) => {
    let url;
    if (action == "approve") {
      url = "/api/approve_invoice_batch";
    } else if (action == "decline") {
      url = "/api/decline_invoice_batch";
    } else if (action == "restore") {
      url = "/api/restore_invoice_batch";
    }
    HttpComponent({
      method: "POST",
      url: url,
      body: { batchId },
      token: X_Authorization,
    })
      .then((data) => {
        console.log("here store is data", data);
        if (data.status === 202) {
          fetchBatches();
          if (action == "approve") {
            setSuccessShow({
              state: true,
              message: "Batch Approved Successfully, Invoice(s) Sent",
            });
          } else if (action == "decline") {
            setSuccessShow({
              state: true,
              message: "Batch Declined Successfully",
            });
          } else if (action == "restore") {
            setSuccessShow({
              state: true,
              message: "Batch Restored Successfully",
            });
          }
        } else {
          console.error("Error setting info");
          setErrorShow({ state: true, message: data.response.message });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const navigate = useNavigate();
  const location = useLocation();

  const checkSuccess = () => {
    const data = location?.state?.data;
    if (data == "created") {
      setSuccessShow({ state: true, message: "Batch Created successfully" });
    }
  };

  useEffect(() => {
    checkSuccess();
  }, []);

  // renderHeader : () => {return (<strong style={{fontSize : '14px', fontWeight : '600'}}

  const [fileHeaders, setFileHeaders] = useState([]);
  const [csvColumns, setCsvColumns] = useState([]);
  // formData.filter

  const setDownloadData = () => {
    let baseColumns;
    let baseHeaders;
    if (formData.filter == "batchList") {
      baseColumns = [
        { label: "Batch No", key: "Batch No" },
        { label: "Created On", key: "Created On" },
        { label: "Total Invoices", key: "Total Invoices" },
        { label: "Total Amount", key: "Total Amount" },
      ];
      baseHeaders = [
        ["Batch No", "Created On", "Total Invoices", "Total Amount"],
      ];
      if (tabValue == "DECLINED") {
        baseColumns.push({
          label: "Declined On",
          key: "Declined On",
        });
        baseHeaders[0].push("Declined On");
      } else if (tabValue == "APPROVED") {
        baseColumns.push(
          { label: "Approved On", key: "Approved On" },
          { label: "Paid Invoices", key: "Paid Invoices" },
          { label: "Paid Amount", key: "Paid Amount" },
          { label: "Invoices Due", key: "Invoices Due" },
          { label: "Amount Due", key: "Amount Due" }
        );
        baseHeaders[0].push(
          "Approved On",
          "Paid Invoices",
          "Paid Amount",
          "Invoices Due",
          "Amount Due"
        );
      }
    } else if (formData.filter == "invoiceList") {
      baseColumns = [
        { label: "Invoice No", key: "Invoice No" },
        { label: "Admission No", key: "Admission No" },
        { label: "Student Name", key: "Student Name" },
        { label: "Grade", key: "Grade" },
        { label: "Term", key: "Term" },
        { label: "Invoice Amount", key: "Invoice Amount" },
        { label: "Amount Paid", key: "Amount Paid" },
        { label: "Amount Due", key: "Amount Due" },
      ];
      baseHeaders = [
        [
          "Invoice No",
          "Admission No",
          "Student Name",
          "Grade",
          "Term",
          "Invoice Amount",
          "Amount Paid",
          "Amount Due",
        ],
      ];
    }
    setCsvColumns(baseColumns);
    setFileHeaders(baseHeaders);
  };

  const fileData = dataToShow.map((data) => {
    let obj;
    if (formData.filter == "batchList") {
      obj = {
        "Batch No": data?.batchNo,
        "Created On": data?.createdAt,
        "Total Invoices": data?.totalInvoices,
        "Total Amount": data?.totalAmount,
      };
      if (tabValue == "DECLINED") {
        obj["Declined On"] = data?.updatedAt;
      } else if (tabValue == "APPROVED") {
        obj["Approved On"] = data?.updatedAt;
        obj["Paid Invoices"] = data?.paidInvoices;
        obj["Paid Amount"] = data?.amountPaid;
        obj["Invoices Due"] = data?.invoicesDue;
        obj["Amount Due"] = data?.amountDue;
      }
    } else if (formData.filter == "invoiceList") {
      obj = {
        "Invoice No": data?.invoiceNumber,
        "Admission No": data?.studentNumber,
        "Student Name": data?.studentName,
        Grade: data?.grade,
        Term: data?.term,
        "Invoice Amount": data?.invoiceAmount,
        "Amount Paid": data?.amountPaid,
        "Amount Due": data?.amountDue,
      };
    }
    return obj;
  });

  const fileDataPDF = dataToShow.map((data) => {
    let theData;
    if (formData.filter == "batchList") {
      theData = [
        data?.batchNo,
        data?.createdAt,
        data?.totalInvoices,
        data?.totalAmount,
      ];
      if (tabValue == "DECLINED") {
        theData.push(data?.updatedAt);
      } else if (tabValue == "APPROVED") {
        theData.push(
          data?.updatedAt,
          data?.paidInvoices,
          data?.amountPaid,
          data?.invoicesDue,
          data?.amountDue
        );
      }
    } else if (formData.filter == "invoiceList") {
      theData = [
        data?.invoiceNumber,
        data?.studentNumber,
        data?.studentName,
        data?.grade,
        data?.term,
        data?.invoiceAmount,
        data?.amountPaid,
        data?.amountDue,
      ];
    }
    return theData;
  });

  useEffect(() => {
    if (formData.filter == "batchList" || tabValue == "PENDING") {
      fetchBatches();
    } else if (formData.filter == "invoiceList") {
      fetchInvoices();
    }
    setDownloadData();
  }, [tabValue, formData.filter]);

  return (
    <Box component="div">
      <SuccessAlert
        vertical="top"
        horizontal="right"
        onClose={() => setSuccessShow({ ...successShow, state: false })}
        open={successShow.state}
        message={successShow.message}
      />
      <ErrorAlert
        vertical="top"
        horizontal="right"
        onClose={() => setErrorShow({ ...errorShow, state: false })}
        open={errorShow.state}
        message={errorShow.message}
      />
      <Box sx={{ display: "flex", margin: 1, justifyContent: "space-between" }}>
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{ color: "#032541", fontSize: "1.563rem", fontWeight: 600 }}
          >
            Invoices
          </Typography>
        </Box>
        <Box component="div" sx={{ display: "flex",  justifyContent: "center", }} >
          <Button sx={{ backgroundColor: "#f5f6f7", marginRight:"10px", height: "45px", width: "155px", color: "#032541", "&:hover": { backgroundColor: "#032541", color: "white", }, }} onClick={() => navigate("/school/upload/studentbalance")} >Upload Balances</Button>
          <Button sx={{ backgroundColor: "#032541", height: "45px", width: "155px", color: "white", "&:hover": { backgroundColor: "#032541", color: "white", }, }} onClick={() => navigate("/school/generate/invoice")} > Create Invoice    </Button>
        </Box>
      </Box>
      <Box component="div" sx={{ margin: 1 }}>
        <Breadcrumbs
          separator={
            <FiberManualRecordIcon
              sx={{ fontSize: "0.625rem", color: "#e1e5e8" }}
            />
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>

      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleTabChange}
            TabIndicatorProps={{
              style: { display: "none" },
            }}
          >
            <Tab
              label="Approved"
              value="APPROVED"
              centered
              sx={{
                color: "#6e7074",
                "&.Mui-selected": {
                  color: "#dc3545",
                },
              }}
            />
            <Tab
              label="Pending Approval"
              value="PENDING"
              centered
              sx={{
                color: "#6e7074",
                "&.Mui-selected": {
                  color: "#dc3545",
                },
              }}
            />
            <Tab
              label="Declined"
              value="DECLINED"
              centered
              sx={{
                color: "#6e7074",
                "&.Mui-selected": {
                  color: "#dc3545",
                },
              }}
            />
          </TabList>
        </Box>

        {/*approved starts*/}

        <TabPanel value="APPROVED">
          <Box
            component="div"
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Box
              component="div"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box component="div" sx={{ display: "flex" }}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <CustomSelectField
                    name={"filter"}
                    value={formData.filter}
                    onChange={handleInputChange}
                    options={filterOption}
                  />
                </Box>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: 0.5,
                  }}
                >
                  <CustomSearchInput
                    name={"search"}
                    value={formData.search}
                    onChange={handleInputChange}
                    placeholder="Search:"
                  />
                </Box>
              </Box>
              <ExportMenu
                csvColumns={csvColumns}
                fileData={fileData}
                fileHeaders={fileHeaders}
                fileDataPDF={fileDataPDF}
                title={`Approved ${formData.filter == "batchList" ? "Batches" : "Invoices"
                  }`}
                fileName={`Approved-${formData.filter == "batchList" ? "Batches" : "Invoices"
                  }`}
              />
            </Box>
            {formData.filter == "batchList" ? (
              <CustomTable
                loading={gridLoading}
                data={dataToShow}
                columns={approvedColumns}
                rowsPerPage={5}
              />
            ) : formData.filter == "invoiceList" ? (
              <CustomTable
                loading={gridLoading}
                data={dataToShow}
                columns={approvedInvoiceColumns}
                rowsPerPage={5}
              />
            ) : null}
          </Box>
        </TabPanel>

        {/*pending starts*/}
        <TabPanel value="PENDING">
          <Box
            component="div"
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Box
              component="div"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box
                component="div"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <CustomSearchInput
                  name={"search"}
                  value={formData.search}
                  onChange={handleInputChange}
                  placeholder="Search:"
                />
              </Box>
              <ExportMenu
                csvColumns={csvColumns}
                fileData={fileData}
                fileHeaders={fileHeaders}
                fileDataPDF={fileDataPDF}
                title={`Pending ${formData.filter == "batchList" ? "Batches" : "Invoices"
                  }`}
                fileName={`Pending-${formData.filter == "batchList" ? "Batches" : "Invoices"
                  }`}
              />
            </Box>
            <CustomTable
              loading={gridLoading}
              data={dataToShow}
              columns={pendingColumns}
              rowsPerPage={5}
            />
          </Box>
        </TabPanel>

        {/*declined starts*/}

        <TabPanel value="DECLINED">
          <Box
            component="div"
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Box
              component="div"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box component="div" sx={{ display: "flex" }}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <CustomSelectField
                    name={"filter"}
                    value={formData.filter}
                    onChange={handleInputChange}
                    options={filterOption}
                  />
                </Box>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: 0.5,
                  }}
                >
                  <CustomSearchInput
                    name={"search"}
                    value={formData.search}
                    onChange={handleInputChange}
                    placeholder="Search:"
                  />
                </Box>
              </Box>
              <ExportMenu
                csvColumns={csvColumns}
                fileData={fileData}
                fileHeaders={fileHeaders}
                fileDataPDF={fileDataPDF}
                title={`Declined ${formData.filter == "batchList" ? "Batches" : "Invoices"
                  }`}
                fileName={`Declined-${formData.filter == "batchList" ? "Batches" : "Invoices"
                  }`}
              />
            </Box>
            {/*declinedColumns*/}
            {/*<CustomTable loading={gridLoading} data={dataToShow} columns={declinedColumns} rowsPerPage={5}/>*/}
            {formData.filter == "batchList" ? (
              <CustomTable
                loading={gridLoading}
                data={dataToShow}
                columns={declinedColumns}
                rowsPerPage={5}
              />
            ) : formData.filter == "invoiceList" ? (
              <CustomTable
                loading={gridLoading}
                data={dataToShow}
                columns={declinedInvoiceColumns}
                rowsPerPage={5}
              />
            ) : null}
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default SchoolInvoiceBatches;
