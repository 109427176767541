import {makeStyles} from "@material-ui/core/styles";
import {DeleteOutline} from "@material-ui/icons";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import {Breadcrumbs, Button, ButtonBase, Grid, IconButton, Table, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {format} from "date-fns";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import cardIcon from "../../../common/images/card-icn.svg";
import creditIcon from "../../../common/images/creditbal-icn.svg";
import mpesaIcon from "../../../common/images/mpesa-icon.svg"
import {handleDeleteCharge, handleTenantInvoiceNumber} from "../../../features/tenantsDataSlice";
import backImg from "../../School/Images/back-icn.svg";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import lodash from 'lodash'
import HttpComponent from "../../School/MakeRequest";
import {SuccessAlert} from "../../snackBar Alerts/successAlert";


const baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
	address_text_common: {
		fontSize: "13px",
		fontWeight: "300",
	},
	table_titles: {
		fontSize: "14px",
		fontWeight: "700",
	},
	table_body: {
		fontSize: "13px",
		fontWeight: "300",
		padding: theme.spacing(0.25),
	},
	footer_text: {
		fontSize: "10px",
		fontWeight: "300",
		padding: theme.spacing(0.25),
	},
	totals: {
		fontSize: "14px",
		padding: theme.spacing(0.25),
	},
	p1: {
		padding: theme.spacing(0.25),
	},
	p2: {
		padding: theme.spacing(0.5),
	},
	p3: {
		padding: theme.spacing(1),
	},
	mt4: {
		marginTop: theme.spacing(4),
	},
	m1: {
		margin: theme.spacing(1),
	},
	textLeft: {
		textAlign: "left",
	},
	textCenter: {
		textAlign: "center",
	},
	textRight: {
		textAlign: "right",
	},
}));

export default function PreviewNewTenantInvoice(props){
	const classes = useStyles();
	const date = new Date();
	const formattedDate = format(date, "EEEE, MMMM dd, yyyy");
	//const [businessName, setBusinessName] = useState(localStorage.getItem("businessName"));
	const [invoiceNumber, setInvoiceNumber] = useState('')
	
	//show Complete
	const [showComplete, setShowComplete] = useState(false)
	
	//RTK
	const dispatch = useDispatch()
	
	//tenant bio Data
	const {firstName, lastName, email, phone, customerType, tenantId} = useSelector((store) => store.tenantData.tenantBioData)
	
	//Unit Data
	const {unitType, itemNumber, billingDate, amount, ssss} = useSelector((store) => store.tenantData.tenantHouseData)
	
	//Charges Data
	const services = useSelector((store) => store.tenantData.tenantChargesData)
	
	// business Data
	const {businessName, businessOwnerEmail, businessOwnerPhone, businessOwnerAddress} = useSelector((store) => store.tenantBusinessData.businessData)
	const [sendState, setSentState] = useState(false)
	const [successShow, setSuccessShow] = useState({state: false, message: ""})
	//const [serviceData, setServiceData] = useState(services)
	
	//total amount
	const total = lodash.sumBy(services, charge => {return charge.amount})
	
	const handleDeleteClick = (productId) => {
		console.log('handling delete of a service',productId)
		dispatch(handleDeleteCharge(productId));
	};
	
	console.log('Redux ToolKit Servicess', services)
	
	const downloadPDF = () => {
		const input = document.getElementById('downloadable');
		html2canvas(input)
			.then((canvas) => {
				const imgData = canvas.toDataURL('image/png', 1.0);
				const inputWidthPx = input.clientWidth;
				const inputHeightPx = input.clientHeight;
				const dpi = window.devicePixelRatio * 96;
				let scaleFactor = 1;
				if( window.innerWidth > 1512){
					scaleFactor = 1.4;
				}
				const inputWidthMm = (inputWidthPx / dpi) * 25.4 / scaleFactor;
				const inputHeightMm = (inputHeightPx / dpi) * 25.4;
				const pdf = new jsPDF("p", "mm", [inputWidthMm, inputHeightMm]);
				pdf.addImage(imgData, 'JPEG', 5, 5, inputWidthMm - 10, inputHeightMm - 10);
				pdf.save(`tenantInvoice.pdf`);
			})
	}
	const back = () =>{navigate(-1)}
	const tenantInvoice = {
		itemNumber : unitType,
		customerId: tenantId,
		type: `one-off`,
		frequency: `once`,
		products: services,
		billableItemId: ssss
	}
	
	const saveInvoice = async () => {
		HttpComponent({
			method : 'POST',
			url : '/api/generateInvoice',
			body : tenantInvoice,
			token : X_Authorization
		}).then((data) => {
			console.log('invoice Data', data)
			if (data.status === 200) {
				setInvoiceNumber(data.response.data.invoiceNumber)
				dispatch(handleTenantInvoiceNumber(data.response.data.invoiceNumber))
				setSentState(true)
				setShowComplete(true)
				setSuccessShow({state : true, message : 'Invoice Sent Succesfully'})
			} else {
				console.log('Error creating invoice!')
			}
		}).catch((e) => {
			console.log(`Error creating Invoice`, e.message)
		})
	};
	const numberFormat = (value) => new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "KES",
	}).format(value);
	
	//useEffect(() => {getBusinessDetails()}, []);
	
	const steps = ['Preview invoice', 'Create Units', 'Create Services', 'Payment Options', 'Create tenant'];
	const [activeStep, setActiveStep] = useState(0);
	const { userId ,X_Authorization } = useSelector((store) => store.user);
	
	const breadcrumbs = [
		<Typography key="X" sx={{color:"#707070",fontSize:"0.875rem"}}>
			{/*Breadcrumb*/}
		</Typography>,
		<Typography key={steps[activeStep]} sx={{color:"#dc3545", fontSize:"0.875rem"}}>
			{steps[activeStep]}
		</Typography>
	];
	
	const navigate = useNavigate();
	return (
		<div>
			
			{/*Success Invoice Sent*/}
			<SuccessAlert vertical="top" horizontal="right" onClose={()=>setSuccessShow({...successShow, state:false})} open={successShow.state} message={successShow.message}/>
			
			{/*Header Stuff*/}
			<Grid container justifyContent={'space-between'} direction={'row'}>
				
				{/*Header title*/}
				<Grid item>
					<Grid container justifyContent={'flex-start'} style={{marginBottom : '20px'}} direction={'column'} spacing={2}>
						<Grid item style={{fontSize : '25px', fontWeight : 600, color : '#032541'}}>
							<img alt="" style={{marginRight : '10px'}} src={backImg} onClick={()=>back()} /><span>Preview invoice</span>
						</Grid>
						<Grid item>
							<Breadcrumbs
								separator={<FiberManualRecordIcon sx={{fontSize:"0.625rem", fontFamily : 'Poppins', color:"#e1e5e8"}} />}
								aria-label="breadcrumb">
								{breadcrumbs}
							</Breadcrumbs>
						</Grid>
					</Grid>
				</Grid>
				
				{/*Button Download/Send*/}
				<Grid item>
					<Grid container spacing={2}>
						<Grid item>
							<Button onClick={downloadPDF}>
								<FileDownloadOutlinedIcon style={{width : '55px', height : '35px', color : '#032541', background : 'rgba(3, 37, 65, 0.05)', borderRadius : '5px'}} />
							</Button>
						</Grid>
						<Grid item>
							{
								sendState ? <Button style={{marginTop : '5px',background : '#032541', color : '#fff', fontSize : `14px`, fontWeight : 600, height : '35px'}} >Resend invoice</Button> :
									<Button onClick={saveInvoice} style={{marginTop : '5px',background : '#032541', color : '#fff', fontSize : `14px`, fontWeight : 600, height : '35px'}} >send invoice</Button>
							}
						</Grid>
						{showComplete ?
						<Grid item>
							<Button onClick={() => {navigate("/dashboard")}} style={{marginTop : '5px',background : '#17ae7b', color : '#fff', fontSize : `14px`, fontWeight : 600, height : '35px'}} >Complete Setup</Button>
						</Grid> : null
						}
					</Grid>
				</Grid>
			
			</Grid>
			
			<div style={{ width: "80%", marginLeft:"10%"}}>
				<div>
					<div>
						<div style={{display:"flex", justifyContent:"space-between"}}>
							{/*<div style={{paddingTop: 15, display:"flex"}}>*/}
							{/*    <span>*/}
							{/*         <IconButton aria-label="back" onClick={()=>back()}>*/}
							{/*            <KeyboardBackspaceOutlinedIcon sx={{color:"#dc3545", fontSize:"25px"}} />*/}
							{/*        </IconButton>*/}
							{/*    </span>*/}
							{/*	<span style={{"fontFamily":"Poppins","fontSize":"25px","fontWeight":"600","textAlign":"left","color":"#dc3545"}}>Preview Invoice</span>*/}
							{/*</div>*/}
							{/*<div style={{padding:10, display:"flex",}}>*/}
							{/*	{responseData && responseData.status === "SUCCESS" ?(*/}
							{/*		<>*/}
							{/*			<Button style={{"width":"180px","height":"45px","borderRadius":"5px","backgroundColor":"transparent", color:"#032541", border:"solid 1px #032541", marginRight:2}}*/}
							{/*			        onClick={()=>setReceivePaymentOpen(true)}>Received Payment</Button>*/}
							{/*			<Button style={{"width":"180px","height":"45px","borderRadius":"5px","backgroundColor":"#032541", color:"white"}}>Resend Invoice</Button>*/}
							{/*		</>*/}
							{/*	):(*/}
							{/*		<Button style={{"width":"180px","height":"45px","borderRadius":"5px","backgroundColor":"#032541", color:"white"}}*/}
							{/*		        onClick={()=>saveInvoice()}>Send Invoice</Button>*/}
							{/*	)}*/}
							{/*	<span style={{backgroundColor: "rgba(3, 37, 65, 0.05)", width:"45px", height:"45px", display:"flex", justifyContent:"center", marginLeft:5, borderRadius:5}}>*/}
							{/*        <IconButton aria-label="download" onClick={downloadPDF}>*/}
							{/*            <FileDownloadOutlinedIcon sx={{color:"#032541", fontSize:"25px"}} />*/}
							{/*        </IconButton>*/}
							{/*    </span>*/}
							
							{/*</div>*/}
						</div>
						<div style={{display: "flex", flexDirection: "column", overflow: "visible", height: "auto"}} id="downloadable">
							<div className={classes.p3} style={{backgroundColor: "rgba(220,53,69,0.05)", height: "177px", display: "flex", justifyContent: "space-between",}}>
								<div className={classes.p1} style={{display: "flex", flexDirection: "column", margin : '2%'}}>
									<span style={{color: "black", fontSize: "40px", fontWeight: "bolder",}}>INVOICE</span>
									<span style={{color: "black", fontSize: "14px", fontWeight: "700", marginTop : '17px'}}>Date:</span>
									<span style={{ fontSize: "14px" }}>{formattedDate}</span>
								</div>
								<div style={{display: "flex", flexDirection: "column",  margin : '2%'}}>
									<span style={{color: "#dc3545", fontSize: "40px", fontWeight: "700", textAlign : 'right'}}>Unpaid</span>
									<span style={{color: "black", fontSize: "14px", fontWeight: "700", marginTop : '17px', textAlign : 'right'}}>Invoice No:</span>
									{invoiceNumber ? (<span style={{ fontSize: "14px",textAlign : 'right' }}>{invoiceNumber}</span>) :
										<span style={{ fontSize: "14px", fontWeight : 'bold', textAlign : 'right'}} >--------</span>}
								</div>
							</div>
							<div className={classes.p3} style={{display: "flex", justifyContent: "space-between", flexDirection: "row", marginTop : '21px', width: "95%", marginLeft: '2%'}}>
								<div style={{display: "flex", flexDirection: "column",}}>
									<span style={{fontSize: "18px",marginBottom : '5.7px'}}>From:</span>
									<span className={classes.address_text_common} style={{ fontWeight: "700"}}>{businessName}</span>
									<span className={classes.address_text_common}>{businessOwnerAddress}</span>
									<span className={classes.address_text_common}>{businessOwnerEmail}</span>
									<span className={classes.address_text_common}>{businessOwnerPhone}</span>
								</div>
								<div className={classes.mt4} style={{display: "flex", flexDirection: "column", textAlign: "right",}}>
									<span className={classes.address_text_common} style={{ fontWeight: "700" }}>Billed to:</span>
									<span className={classes.address_text_common}>{firstName + " " + lastName}</span>
									<span className={classes.address_text_common}>{email}</span>
									<span className={classes.address_text_common}>{phone}</span>
								</div>
							</div>
							
							<Grid container justifyContent={'flex-start'} direction={'row'} style={{marginTop : '1%', marginBottom : '1%', border: "dotted 2px #bfbfbf", height : "45px"}}>
								<Grid item style={{marginRight : "15%", padding : '0.5%'}}>
									<span style={{marginLeft : '22px'}}>HOUSE NUMBER: </span>
									<span style={{fontWeight : "bold"}}>{itemNumber}</span>
								</Grid>
								<Grid item style={{padding : '0.5%'}} >
									<span>HOUSE TYPE: </span>
									<span style={{fontWeight : "bold"}} >{unitType}</span>
								</Grid>
							</Grid>
							
							<Table style={{marginLeft : '22px'}}>
								<TableHead >
									<TableRow>
										<TableCell style={{fontWeight : 'bold', fontFamily : 'Poppins', fontSize : '14px'}} className={classes.textLeft}>Description</TableCell>
										<TableCell style={{fontWeight : 'bold', fontFamily : 'Poppins', fontSize : '14px'}} className={classes.textCenter}>Price (KES)</TableCell>
										<TableCell style={{fontWeight : 'bold', fontFamily : 'Poppins', fontSize : '14px'}} className={classes.textCenter}>Qty</TableCell>
										<TableCell style={{fontWeight : 'bold', fontFamily : 'Poppins', fontSize : '14px', textAlign : 'right'}} className={classes.textRight}>Total (KES)</TableCell>
									</TableRow>
								</TableHead>
								{services?.map((service, index) => (
									<TableRow key={index} align={'right'}>
										
										<TableCell className={classes.textLeft}><span className={classes.table_body}>{service?.name}</span></TableCell>
										<TableCell className={classes.textCenter}><span className={classes.table_body}>{numberFormat(service?.amount)}</span></TableCell>
										<TableCell className={classes.textCenter}><span className={classes.table_body}>{1}</span></TableCell>
										<TableCell style={{textAlign : 'right'}} className={classes.textRight}><span className={classes.table_body} style={{textAlign : 'right'}}>{numberFormat(service?.amount)}</span></TableCell>
										<TableCell className={classes.textRight}>
											<ButtonBase onClick={() => {handleDeleteClick(service.productId)}}>
												<DeleteOutline style={{color : '#dc3545'}} />
											</ButtonBase>
										</TableCell>
									
									</TableRow>
								))}
							</Table>
							
							<Grid container justifyContent={'space-between'} alignContent={'center'} direction={'row'} mt={1} spacing={2}>
								
								{/*<Grid item>*/}
								{/*<Grid item>*/}
								<Button onClick={() => {navigate('/addServiceFromInvoice')}} style={{border: 'solid 1px rgba(3, 37, 65, 0.1)',fontWeight : 'bold', width : '651px',color : '#032541',borderRadius : '2px', alignContent : 'center', background : '#fff', fontSize : '14px'}}>
									<AddCircleOutlineIcon style={{fontSize : '18px', marginRight : '12px'}} /> Add Service</Button>
								{/*</Grid>*/}
								{/*</Grid>*/}
								<Grid item style={{backgroundColor : 'rgba(3, 37, 65, 0.1)', width : '40%', textAlign : 'right', borderRadius : '2px'}}>
									<span style={{color : '#032541',  marginRight : '2%'}}>Total :</span>
									<span style={{color : '#032541', fontWeight : 'bold', marginRight : '10%'}}>{numberFormat(total)}</span>
								</Grid>
							</Grid>
							
							<div className={classes.p3} style={{display: "flex", justifyContent: "end",}}>
								<div className={classes.p1} style={{backgroundColor: 'rgba(220, 53, 69, 0.05)', width: "288px", height: "130px", display: "flex", flexDirection: "column", justifyContent: "center",}}>
									<div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
										<span>Subtotal</span>
										<span>{numberFormat(total)}</span>
									</div>
									<div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
										<span>Discount</span>
										<span>KES 0.00</span>
									</div>
									<div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
										<span style={{ color: "#dc3545", fontWeight: "bold" }}>Total</span>
										<span style={{fontSize: "16px", color: "#dc3545", fontWeight: "bold",}}>{numberFormat(total)}</span>
									</div>
								</div>
							</div>
							
							<div style={{ borderTop: "dotted 2px #bfbfbf" }}></div>
							<div className={classes.p3} style={{ display: "flex", flexDirection: "column", justifyContent : 'center', alignContent : 'center'}}>
								<div className={classes.p1}>
									<span style={{color: "#032541", fontWeight: "700", fontSize: "16px",}}>Accepted Payment Methods</span>
								</div>
								<div style={{ display: "flex" }}>
									<div style={{display:"flex", flexDirection:"column"}}>
										<img className={classes.p2} src={mpesaIcon} alt="M-pesa"/>
										<div style={{display:"flex", justifyContent:"center", fontSize: "12px"}}>
											<span>M-Pesa</span>
										</div>
									</div>
									<div style={{display:"flex", flexDirection:"column",}}>
										<img className={classes.p2} src={cardIcon} alt="Visa"/>
										<div style={{display:"flex", justifyContent:"center", fontSize: "12px"}}>
											<span>Card</span>
										</div>
									</div>
									<div style={{display:"flex", flexDirection:"column",}}>
										<img className={classes.p2} src={creditIcon} alt="Credit"/>
										<div  style={{display:"flex", justifyContent:"center", fontSize: "12px"}}>
											<span>Credit Balance</span>
										</div>
									
									</div>
								</div>
							</div>
							
							<div style={{ border: "dotted 1px" }}></div>
							
							<div className={classes.p2} style={{ display: "flex", justifyContent: "center" }}>
								<div className={classes.textCenter} style={{ fontSize: "10px", fontWeight: "300" }}>
									This is a system-generated invoice is created without any
									alteration whatsoever.
									<br />
									Thank you for your business.
								</div>
							</div>
							
							<div className={classes.p2} style={{backgroundColor: "#f7f7f7", display: "flex", justifyContent: "center",}}>
								<span className={classes.footer_text}>Powered by <span style={{fontWeight:"700"}}>ZED Payments Limited</span></span>
								<span className={classes.footer_text}>.</span>
								<span className={classes.footer_text}>info@zed.business</span>
								<span className={classes.footer_text}>.</span>
								<span className={classes.footer_text}>v1.0.2</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}