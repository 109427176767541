import React, { useState, useEffect } from "react";
import { Grid, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import HttpComponent from "../../School/MakeRequest";
import { warehousestyles } from "../styles";
import suspendcustomer from "../../../common/images/suspendedcustomer.svg";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { Suspendsuppliermodal } from "../modals";
import { SuccessAlertRightAligned } from "../../bills/startorderModals/modals";

const SuppliersList = (props) => {
  const searchValue = props?.searchValue;
  const navigate = useNavigate();
  // DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], total: 0, page: 1, pageSize: dataGridPageSize });

  // suppliers list
  const getAllsupliers = () => {
    HttpComponent({
      method: "GET",
      url: `/api/v1/supplier/all?page=${pageState.page}&limit=${dataGridPageSize}&status=ACTIVE`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 201) {
          setPageState({ ...pageState, isLoading: false, data: data.response.data, total:  data.response.count});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAllsupliers();
  }, [pageState.page, dataGridPageSize]);

  // search suppliers

  const searchSuppliers = () => {
    HttpComponent({
      method: "POST",
      url: `/api/v1/searchsupplier?page=1&limit=5000`,
      body: { supplierName: searchValue, status: "ACTIVE" },
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 200) {
          setPageState({ ...pageState, isLoading: false, data: data.response.data });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if(searchValue)
    searchSuppliers();
  }, [searchValue]);
  // suspend suppliers;
  const [openSuspendModal, setSuspendModal] = useState(false);
  const [supplierToSuspendName, setSupplierToSupsendName] = useState("");
  const [supplierToSuspendId, setSupplierToSupsendId] = useState("");
  const [sucess, setSuccess] = useState({ state: false, message: "" });
  const [supplierToBeSuspendedObj, setSupplierToBeSuspendedObj] = useState([]);

  const getsupplierdetails = (supplierData) => {
    const { userId, supplierName } = supplierData;
    console.log(userId);
    setSupplierToSupsendName(supplierName);
    setSupplierToSupsendId(userId);
    setSuspendModal(true);
  };

  // suspend multiple suppliers;
  const suspendMultiplySuppliers = () => {
    if (supplierToBeSuspendedObj.length > 1) {
      setSuspendModal(true);
    }
  };

  const suspendSupplier = () => {
    HttpComponent({
      method: "POST",
      url: `/api/v1/supplier/activate?status=SUSPENDED`,
      body: { supplierIds: supplierToSuspendId ? [supplierToSuspendId] : supplierToBeSuspendedObj },
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 200) {
          setSuccess({ state: true, message: "Supplier has been suspended successfully!" });
          getAllsupliers();
          setSuspendModal(false);
        }

        setTimeout(() => {
          setSuccess({ state: false });
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <DataGrid
        style={warehousestyles.dataGridStyling}
        rows={pageState?.data?.map((supplier) => ({
          id: supplier?._id,
          username: supplier?.name,
          phone: supplier?.phone,
          email: supplier?.email,
          location: supplier?.location,
        }))}
        columns={[
          { field: "username", headerName: "Name", flex: 1 },
          { field: "phone", headerName: "Mobile Phone", flex: 1 },
          { field: "email", headerName: "Email", flex: 1 },
          { field: "location", headerName: "Location", flex: 1 },
          {
            field: "Actions",
            headerName: "Actions",
            renderCell: (params) => {
              return (
                <>
                  <div style={warehousestyles.activesupplierActions}>
                    <img onClick={() => getsupplierdetails({ userId: params.row.id, supplierName: params.row.username })} src={suspendcustomer} alt="" />
                    <Edit onClick={() => navigate(`/addsupplier/newSupplier?${params.row.id}`)} />
                  </div>
                </>
              );
            },
            flex: 1,
          },
        ]}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        rowCount={pageState.total}
        loading={pageState.isLoading}
        pagination
        page={pageState.page - 1}
        pageSize={dataGridPageSize}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize }));
        }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        checkboxSelection
        disableRowSelectionOnClick={true}
        onSelectionModelChange={(name) => {
          setSupplierToBeSuspendedObj(name);
        }}
      />

      <Grid container width={"100%"}>
        <Grid item width={"100%"}>
          <div style={warehousestyles.suspendBtnRightAligned}>
            {supplierToBeSuspendedObj?.length > 1 && (
              <Button style={warehousestyles.suspendSupplier} onClick={() => (supplierToBeSuspendedObj.length > 1 ? suspendMultiplySuppliers() : null)}>
                Suspend {supplierToBeSuspendedObj.length}{" "}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>

      {openSuspendModal && <Suspendsuppliermodal openSuspendModal={openSuspendModal} setSuspendModal={setSuspendModal} supplierToSuspendName={supplierToSuspendName} suspendSupplier={suspendSupplier} />}
      {sucess && <SuccessAlertRightAligned sucess={sucess.state} message={sucess.message} />}
    </div>
  );
};

export default SuppliersList;
