import {useSelector} from "react-redux";
import emptyInvoice from '../../../common/images/invoice-empty.svg'
import {makeStyles} from "@material-ui/core/styles";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import AddIcon from "@mui/icons-material/Add";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {DataGrid} from "@mui/x-data-grid";
import moment from "moment-timezone/moment-timezone-utils";
import {Link, useParams} from "react-router-dom";
import customerDetailssvg from "../../../common/images/customeerdetailssvg.svg";
import {Box, Button, Card, Grid, styled, Tab, Typography} from "@mui/material";
import React, {useState, useEffect} from "react";
import {Modal} from '@mui/material'
import {CreateInvoiceModal} from "../../Modals/Invoice/createInvoice";
import lodash from 'lodash'
import {MpesaStk} from "../../Modals/Invoice/mpesaStk";
import {TenantMpesaStk} from "../../Modals/Invoice/tenantMpesaStk";
import {NoRowsOverlay} from "../../No Rows/noRowsOverlay";
import HttpComponent from "../../School/MakeRequest";
import {CreateTenantInvoice} from "../Modals/tenantModals/createInvoice";
import WarningImg from '../../../images/warning.png'

//custom styles
const mystyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	display:"flex",
	flexDirection:"column",
	alignItems:"center",
	bgcolor: 'background.paper',
	justifyContent:"center",
	p: 4,
  };

//Urls
const baseUrl = process.env.REACT_APP_BASE_URL;
const getTenantDetailsUrl = baseUrl + "/api/get_customer_by_number";
const getTenantActivities = baseUrl + "/api/customerTransactions";
const getTenantActivityUrl = baseUrl + "/api/CustomersActivities";
const filterTranactionUrl = baseUrl + "/api/filterCustomerTransactionByDate";

//Styling
const tenantStyles = makeStyles(() => ({
	tenantName : {"fontSize": "26px", "fontWeight": "bold", "textAlign": "left", "color": "#032541"},
	dateAdded : {"fontSize": "17px", "textAlign": "left", "color": "#666f76"},
	customerType : {"fontSize": "19px", "fontWeight": "bold", "textAlign": "left", "color": "#032541"},
	unitData : {"fontFamily": "Poppins", "fontSize": "15px", "fontWeight": "bold", "color": "#032541"},
	balance : {"fontFamily": "Poppins", "fontSize": "15px", "fontWeight": "bold", "color": "#dc3545"},
	balanceData : {"fontSize": "17px", "textAlign": "left", "color": "#dc3545"}}))

//Custom Tab Styling
const AntTabs = styled(TabList)({borderBottom: "3px solid #e8e8e8", "& .MuiTabs-indicator": {backgroundColor: "#dc3545",},});
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({textTransform: "none", minWidth: 0,
	[theme.breakpoints.up("sm")]: {minWidth: 0,}, fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1),
	fontSize: "16px",
	textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","), "&:hover": {color: "#032541", opacity: 1,},
	"&.Mui-selected": {color: "#032541", fontWeight: 'bold',}, "&.Mui-focusVisible": {backgroundColor: "blue",},}));

//Currency Formatter
const numberFormat = (value) => new Intl.NumberFormat("en-US", {style: "currency", currency: "KES"}).format(value);

export default function TenantDashBoard () {

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	
	const { customerId } = useSelector((store) => store.user);
	
	const id = customerId || localStorage.getItem('customerId')
	const classes = tenantStyles()
	
	//Create Invoice Modal
	const [createInvoiceModal, setCreateInvoiceModal] = useState(false)
	const [selectedRows, setSelectedRows] = React.useState([]);
	
	
	// Utility Columns
	const utilityColumns = [
		{field : 'name', flex : 1, headerAlign : 'left', align : 'left', renderHeader : () => {return <strong className={classes.unitData} >Utility Name</strong>}},
		{field : 'unitOfMeasure', flex : 1, headerAlign : 'left', align : 'left', renderHeader : () => {return <strong className={classes.unitData} >Unity Of Measure</strong>}},
		{field : 'amount', flex : 1, headerAlign : 'left', align : 'left', renderHeader : () => {return <strong className={classes.unitData} >Amount</strong>}, renderCell : (params) => {return (numberFormat(params.value))}},
		{field : 'createdAt', flex : 1, headerAlign : 'left', align : 'left', renderHeader : () => {return <strong className={classes.unitData} >Created On</strong>}, renderCell : (params) => {return (moment(params.value).format('llll'))}},
		{field : 'updatedAt', flex : 1, headerAlign : 'left', align : 'left', renderHeader : () => {return <strong className={classes.unitData} >Updated On</strong>}, renderCell : (params) => {return (moment(params.value).format('llll'))}}
	]
	
	// Transactions Columns
	const tenantTransactionColumns = [
		{ field: "date", headerName: "Date", flex: 1, headerAlign : 'left', align: 'left',renderHeader : () => {return <strong className={classes.unitData} >Date</strong>},renderCell : (params) => {return (moment(params.value).format('llll'))} },
		{ field: "itemNo", headerName: "No. of Items", flex: 1, headerAlign : 'left', align: 'left',renderHeader : () => {return <strong className={classes.unitData} >No. of Items</strong>} },
		{ field: "amount", headerName: "Amount(KES)", flex: 1, headerAlign : 'left', align: 'left',renderHeader : () => {return <strong className={classes.unitData} >Amount(KES)</strong>}, renderCell : (params) => {return (numberFormat(params.value))}},
		{ field: "transactionNo", headerName: "Served By", flex: 1, headerAlign : 'left', align: 'left',renderHeader : () => {return <strong className={classes.unitData} >Ref.</strong>} },
		{ field: "paidBy", headerName: "Served By", flex: 1, headerAlign : 'left', align: 'left',renderHeader : () => {return <strong className={classes.unitData} >Paid By</strong>} },
	];
	
	// Invoice Columns
	const tenantInvoiceColumns = [
		{field: 'invoiceNumber', headerName: 'Invoice No', flex : 1, align : 'left', headerAlign: 'left', renderHeader : () => {return <strong className={classes.unitData} >Invoice No</strong>}, renderCell : (params) => {return (<Link to={`/tenantInvoice/${params.value}`}>{params.value}</Link> )}},
		{field: 'createdAt', headerName: 'Date Issued', flex : 1, align : 'left', headerAlign: 'left', renderHeader : () => {return <strong className={classes.unitData} >Date Issued</strong>}, renderCell : (params) => {return (moment(params.value).format('llll'))} },
		{field: 'period', headerName: 'Period', flex : 1, align : 'left', headerAlign: 'left', renderHeader : () => {return <strong className={classes.unitData} >Period</strong>}, renderCell : (params) => {return (moment(params.value).format('MMMM yyy'))}},
		{field: 'invoiceAmount', headerName: 'Amount', flex : 1, align : 'left', headerAlign: 'left', renderHeader : () => {return <strong className={classes.unitData} >Amount</strong>}, renderCell : (params) => {return (numberFormat(params.value))}},
		{field: 'amountPaid', headerName: 'Amount', flex : 1, align : 'left', headerAlign: 'left', renderHeader : () => {return <strong className={classes.unitData} >Amount Paid</strong>}, renderCell : (params) => {return (numberFormat(params.row.invoiceAmount - parseInt(params.row.invoiceBalance)))}},
		{field: 'invoiceBalance', headerName: 'Amount', flex : 1, align : 'left', headerAlign: 'left', renderHeader : () => {return <strong className={classes.unitData} >Amount Balance</strong>}, renderCell : (params) => {return (numberFormat(params.value))}},
		{field: 'invoiceType', headerName: 'Type', flex : 1, align : 'left', headerAlign: 'left', renderHeader : () => {return <strong className={classes.unitData} >Type</strong>},},
		{field: 'invoiceStatus', headerName: 'Status', flex : 1, align : 'left', headerAlign: 'left', renderHeader : () => {return <strong className={classes.unitData} >Status</strong>}, renderCell : (params) => {return (<span style={{fontSize : "12px", color : params.value === 'Unpaid' ? '#dc3545' : params.value === 'Paid' ? '#17ae7b' : "#ff8503"}} >{params.value}</span>)}},
	]
	
	// Receipts Columns
	const tenantReceiptsColumns = [
		{field: 'receiptNo', headerName: 'Receipt No', flex : 1, align : 'left', headerAlign: 'left', renderHeader : () => {return <strong className={classes.unitData} >Receipt No</strong>}, renderCell : (params) => {return (<Link to={`/receipt/${params.value}/${params.row.invoiceNumber}`}>{params.value}</Link> )}},
		{field: 'invoiceNumber', headerName: 'Invoice No', flex : 1, align : 'left', headerAlign: 'left', renderHeader : () => {return <strong className={classes.unitData} >Invoice No</strong>}, renderCell : (params) => {return (<Link to={`/tenantInvoice/${params.value}`}>{params.value}</Link> )}},
		{field: 'issuedDate', headerName: 'Date Issued', flex : 1, align : 'left', headerAlign: 'left', renderHeader : () => {return <strong className={classes.unitData} >Date Issued</strong>}, renderCell : (params) => {return (moment(params.value).format('llll'))} },
		{field: 'period', headerName: 'Period', flex : 1, align : 'left', headerAlign: 'left', renderHeader : () => {return <strong className={classes.unitData} >Period</strong>}, renderCell : (params) => {return (moment(params.value).format('MMMM yyy'))}},
		{field: 'amount', headerName: 'Amount', flex : 1, align : 'left', headerAlign: 'left', renderHeader : () => {return <strong className={classes.unitData} >Amount</strong>}, renderCell : (params) => {return (numberFormat(params.value))}},
	]
	
	// Houses Columns
	const tenantHousesColumns = [
		{field : 'itemNumber', flex : 1, align : 'left', headerAlign : 'left', renderHeader : () => {return <strong className={classes.unitData} >House No.</strong>}},
		{field : 'unitType', flex : 1, align : 'left', headerAlign : 'left', renderHeader : () => {return <strong className={classes.unitData} >House Type</strong>}},
		{field : 'createdAt', flex : 1, align : 'left', headerAlign : 'left', renderHeader : () => {return <strong className={classes.unitData} >Created On</strong>}, renderCell : (params) => {return (moment(params.value).format('llll'))} },
		{field : 'amount', flex : 1, align : 'left', headerAlign : 'left', renderHeader : () => {return <strong className={classes.unitData} >Amount(KES)</strong>}, renderCell : (params) => {return (numberFormat(params.value))}}
	]
	
	const {X_Authorization} = useSelector((store) => store.user)
	
	// set tab values;
	const [tabValue, setChangeTabValue] = useState("Invoice");
	const handleChangeTabValue = (e, newValue) => {setChangeTabValue(newValue);};
	
	//Data, setters & Getters
	const [tenantDetails, setTenantDetails] = useState([])
	const [tenantInfo, setTenantInfo] = useState({tenantName: "", dayCreated: "", tenantType: "",noticeNumber:"" , houseNumber : "", rent : "", houseType : "", balance : "", utilities : [], billableItems : []});
	const [tenantTransactions, setTenantTransactions] = useState([]);
	const [tenantDetailsData, setTenantDetailsData] = useState([]);
	const [invoiceRows, setInvoiceRows] = useState([]);
	const [tenantReceipts, setTenantsReceipts] = useState([])
	
	
	//Tenant Details
	const fetchTenantDetails = async () => {
		try {
			const tenantDetailsResponse = await fetch(
				getTenantDetailsUrl + `?customerId=${id}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),},
				}
			);
			await tenantDetailsResponse.json()
				.then((data) => {console.log(`Tenant Data`, data); setTenantDetails(data.data)})
				.catch((e) => {console.log(`Error Getting Tenant Data`, e.message)})
		} catch (error) {
			console.log(error.message);
		}
	};
	
	//Tenant Transactions
	const fetchTenantTransactions = async () => {
		try {
			const getTransactionsResponse = await fetch(
				getTenantActivities + `?customerId=${id}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
				}
			);
			await getTransactionsResponse.json()
				.then((data) => {console.log(`Tenant Data`, data); setTenantTransactions(data.transaction)})
				.catch((e) => {console.log(`Error Getting Tenant Data`, e.message)})
		} catch (error) {
			console.log(error.message);
		}
	};
	
	//Tenant Activities
	const fetchTenantActivity = async () => {
		try {
			const tenantActivityResponse = await fetch(
				getTenantActivityUrl + `?customerId=${id}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
				}
			);
			await tenantActivityResponse.json()
				.then((data) => {console.log(`Tenant Data`, data); setTenantDetailsData(data.data)})
				.catch((e) => {console.log(`Error Getting Tenant Data`, e.message)})
		} catch (error) {
			console.log(error.message);
		}
	};
	
	//Tenant invoice
	const fetchInvoiceDetails = async () => {
		try {
			const invoiceDetailsResponse = await fetch(baseUrl+`/api/get_invoice_by_customerId/${id}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
				}
			);
			if (invoiceDetailsResponse.status === 200) {
				const invoiceDetails = await invoiceDetailsResponse.json();
				console.log("invoice data ", invoiceDetails.data)
				console.log("rows", invoiceDetails.data)
				setInvoiceRows(invoiceDetails.data)
			}
		} catch (error) {
			console.error("error fetching invoice details",error.message);
		}
	};
	
	function fetchTenantsReceipts () {
		try {
			HttpComponent({
				method : 'GET',
				url : '/api/get_business_receipts_by_customer?limit=1000&page=1',
				token : X_Authorization
			}).then((data) => {
				if (data.status === 201) {
					setTenantsReceipts(data.response.data)
				} else {
					console.log(`Error Getting tenants Receipts`)
				}
			}).catch((e) => {
				console.log(`Error Getting tenants Receipts`, e.message)
			})
		} catch (e) {
			console.log(`Error Getting tenants Receipts`, e.message)
		}
	}
	
	//Tenant Data
	const getTenantData = () => {
		tenantDetails.length > 0 &&
		tenantDetails.forEach((tenant) => {
			setTenantInfo({
				...tenantInfo,
				customerId : tenant._id,
				tenantName: tenant.firstName +" " +tenant.lastName,
				dayCreated: tenant.createdAt,
				tenantType: tenant.customerType,
				noticeNumber:tenant?.noticeNumber,
				houseNumber: tenant.billableItems[0]?.itemNumber,
				rent : tenant.billableItems[0]?.amount,
				houseType: tenant.billableItems[0]?.facilitySize,
				balance: !tenant.totalBalance ? 0:tenant.totalBalance,
				billableItems : tenant?.billableItems,
				utilities : tenant?.billableItems[0]?.extraServices,
			});
		});
	}
	
	//Tenant Transaction
	const tenantTransaction = tenantTransactions.length > 0 ? tenantTransactions.map((customer) => ({
		id: customer?.transactionId,
		date: moment(customer.transactionTime).format('lll'),
		itemNo: customer?.noOfItems,
		servedBy: customer.approvedBy,
		amount: customer.amount,
		balance: customer.customerBalance,
		transactionNo : customer.transactionNo,
		paidBy : customer.servedBy
	})): []
	
	// Data useEffects
	useEffect(() => {
		fetchTenantTransactions()
			.then(() => {console.log(`Tenant Data usEffect`)})
			.catch((e) => {console.log(`Error Getting Tenant Data`, e.message)})
		fetchTenantDetails()
			.then(() => {console.log(`Tenant Data usEffect`)})
			.catch((e) => {console.log(`Error Getting Tenant Data`, e.message)})
		fetchInvoiceDetails()
			.then(() => {console.log(`Tenant Data usEffect`)})
			.catch((e) => {console.log(`Error Getting Tenant Data`, e.message)})
		fetchTenantsReceipts()
	}, [id, tabValue])
	
	useEffect(() => {
		getTenantData();
	}, [tenantDetails]);
	
	const invoicesString = selectedRows.map(invoice => invoice.invoiceNumber).join('__')
	const totalInvoiceAmount = lodash.sumBy(selectedRows, row => {return row.invoiceAmount})
	
	const [mpesaPaymentOpen, setMpesaPaymentOpen] = useState(false);
	
	return (
		<>
			<Grid container justifyContent={'flex-end'} position={'relative'}>
				{!tenantInfo?.noticeNumber === '' ? <Grid item position={'absolute'} marginTop={'-55px'} marginRight={'200px'}>
					<Typography onClick={handleOpen} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>Send Exit Notice <AddIcon style={{ marginLeft: "20px" }} /> </Typography>
				</Grid> : <Grid item position={'absolute'} marginTop={'-55px'} marginRight={'200px'}>
					<Typography onClick={handleOpen} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}> View Notice <PersonRemoveIcon style={{ marginLeft: "20px" }} /> </Typography>
				</Grid>}
			</Grid>
		
		<div style={{fontFamily : 'Poppins'}}>
			{/*Tenant Display Card*/}
			<Grid container justifyContent={'flex-start'} column={1}>
				<Grid item width={'100%'}>
					<Card style={{width:"100%",height:"186px",margin:"14px 43px 19px 0",padding:"33px 125px 33px 30px","borderRadius":"18px",boxShadow:"0 4px 9px 0 rgba(0, 0, 0, 0.16)",backgroundColor:"#fff"}} >
						<Grid container justifyContent={'space-between'} direction={'row'}>
							
							{/*Image and Bio Data*/}
							<Grid item>
								<Grid container direction={'row'} justifyContent={'space-between'} spacing={3}>
									<Grid item>
										<img style={{objectFit: "contain", width: "120px", height: "120px", margin: "0px 10px 0px 0px",}} src={customerDetailssvg} alt="customerdetails"/>
									</Grid>
									
									{/*Bio Data*/}
									<Grid item>
										<Grid container justifyContent={'space-between'} direction={'column'} spacing={1}>
											
											{/*name*/}
											<Grid item>
												<span className={classes.tenantName} >{tenantInfo.tenantName}</span>
											</Grid>
											
											{/*Date Added*/}
											<Grid item>
												<span className={classes.dateAdded} >{moment(tenantInfo.dayCreated).format('lll')}</span>
											</Grid>
											
											{/*Customer type*/}
											<Grid item>
												<span className={classes.customerType} >Tenant Type: </span>
												<span className={classes.dateAdded} >{tenantInfo.tenantType}</span>
											</Grid>
										
										</Grid>
									</Grid>
								</Grid>
							
							</Grid>
							
							{/*Unit Data*/}
							<Grid item>
								<Grid container justifyContent={'space-between'} direction={'column'} spacing={2.5}>
									
									<Grid item mb={2.5}></Grid>
									
									{/*House No.*/}
									<Grid item>
										<span className={classes.unitData}>House No: </span>
										<span className={classes.dateAdded}>{tenantInfo.houseNumber}</span>
									</Grid>
									
									{/*Rent*/}
									<Grid item>
										<span className={classes.unitData}>Rent: </span>
										<span className={classes.dateAdded}>{numberFormat(tenantInfo.rent)}</span>
									</Grid>
								
								</Grid>
							</Grid>
							
							{/*Utility Data*/}
							<Grid item>
								<Grid container justifyContent={'space-between'} direction={'column'} spacing={2.5}>
									
									<Grid item mb={2.5}></Grid>
									
									{/*House Type.*/}
									<Grid item>
										<span className={classes.unitData}>House Type: </span>
										<span className={classes.dateAdded}>{tenantInfo.houseType}</span>
									</Grid>
									
									{/*Balance*/}
									<Grid item>
										<span className={classes.balance}>Balance : </span>
										<span className={classes.balanceData}>{numberFormat(lodash.sumBy(invoiceRows, invoice => {return invoice.invoiceBalance}))}</span>
									</Grid>
								</Grid>
							</Grid>
						
						</Grid>
					</Card>
				</Grid>
			</Grid>
			
			{/*Tenants DataGrids and More Data*/}
			<Grid Container justifyContent={'space-between'}>
				{
					selectedRows.length > 0 ?
						<Grid item>
							<Button style={{textTransform : 'none', background : '#032541FF', color : '#fff', fontWeight : '600'}} onClick={() => {setMpesaPaymentOpen(true)}}>Pay Invoices</Button>
						</Grid> : null
				}
				
				{/*Tabs*/}
				<Grid item>
					<TabContext value={tabValue}>
						
						{/*Tabs*/}
						<Box style={{ width: "100%" }}>
							<AntTabs onChange={handleChangeTabValue} variant={'fullWidth'}>
								<AntTab label="Invoice" value="Invoice" />
								<AntTab label="Transactions" value="Transactions" />
								<AntTab label="Receipts" value="Receipts" />
								<AntTab label="Services" value="Services" />
								<AntTab label="Houses" value="Houses" />
							</AntTabs>
						</Box>
						
						{/*Houses*/}
						<TabPanel value="Houses">
							<DataGrid
								components={{NoRowsOverlay : NoRowsOverlay}}
								sx={{height : '400px', width : 'auto', fontFamily : 'Poppins', fontSize: "10px", color : '#272d3d',boxShadow: 0, border: 0}}
								columns={tenantHousesColumns}
								rows={tenantInfo.billableItems}
								getRowId={row => row._id}
							/>
						</TabPanel>
						
						{/*Transactions*/}
						<TabPanel value="Transactions">
							<DataGrid
								components={{NoRowsOverlay : NoRowsOverlay}}
								sx={{height : '400px', width : 'auto', fontFamily : 'Poppins', fontSize: "10px", color : '#272d3d',boxShadow: 0, border: 0}}
								columns={tenantTransactionColumns}
								rows={tenantTransaction || []}
								getRowId={row => row.id}
							/>
						</TabPanel>
						
						{/*Invoice*/}
						<TabPanel value="Invoice">
							{/*{invoiceRows.length > 0 ?*/}
								<DataGrid
									keepNonExistentRowsSelected
									checkboxSelection
									onSelectionModelChange={(ids) => {const selectedIDs = new Set(ids);const selectedRows = invoiceRows.filter((row) => selectedIDs.has(row._id));setSelectedRows(selectedRows)}}
									getRowId={row => row._id}
									pageSize={5}
									rowsPerPageOptions={[5,10,25]}
									components={{NoRowsOverlay : NoRowsOverlay}}
									sx={{height : '400px', width : 'auto', fontFamily : 'Poppins', fontSize: "10px", color : '#272d3d',boxShadow: 0, border: 0}}
									columns={tenantInvoiceColumns}
									rows={invoiceRows}
								/>
						</TabPanel>
						
						{/*Receipt*/}
						<TabPanel value="Receipts">
							{/*{invoiceRows.length > 0 ?*/}
							<DataGrid
								keepNonExistentRowsSelected
								checkboxSelection
								onSelectionModelChange={(ids) => {const selectedIDs = new Set(ids);const selectedRows = invoiceRows.filter((row) => selectedIDs.has(row._id));setSelectedRows(selectedRows)}}
								getRowId={row => row.transactionId}
								pageSize={5}
								rowsPerPageOptions={[5,10,25]}
								components={{NoRowsOverlay : NoRowsOverlay}}
								sx={{height : '400px', width : 'auto', fontFamily : 'Poppins', fontSize: "10px", color : '#272d3d',boxShadow: 0, border: 0}}
								columns={tenantReceiptsColumns}
								rows={tenantReceipts}
							/>
						</TabPanel>
						
						{/*Services*/}
						<TabPanel value="Services">
							<DataGrid
								components={{NoRowsOverlay : NoRowsOverlay}}
								sx={{height : '400px', width : 'auto', fontFamily : 'Poppins', fontSize: "10px", color : '#272d3d',boxShadow: 0, border: 0}}
								columns={utilityColumns}
								rows={tenantInfo.utilities}
								getRowId={row => row._id}
							/>
						</TabPanel>
					
					</TabContext>
				</Grid>
			</Grid><div className="pop-ups">
					<Modal
						open={open}
						onClose={handleClose}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<Box sx={mystyle}>
							<img src={WarningImg} alt='warning'/>
							<Typography fontWeight={'bold'} id="modal-modal-title" variant="h6" component="h2" sx={{color:"#032541"}}>
								Confirm Exit
							</Typography>
							<Typography id="modal-modal-description" sx={{ mt: 2 }}>
								Are you sure you want to exit <span style={{ color:"#032541" }}>{tenantInfo.houseNumber}</span> 
							</Typography>
							<Typography  variant="h6" component="h2"sx={{ mt: 2 ,color:"#032541" }}>
							{tenantInfo.businessName}
							</Typography>
							<Typography component={'div'} sx={{display:"flex" ,marginTop:'20px' }}>
								<Button onClick={handleClose}  style={{border:"2px solid #032541" ,color:"#032541" ,textTransform:"inherit",marginRight:"10px" ,padding:"0px 11px"}}>Cancel</Button>
								<Button style={{background:"#032541" ,color:"#fff" ,textTransform:"inherit" ,padding:"0px 11px"}}>
									<Link to={`/tenants/notice/${tenantInfo.noticeNumber}`}> continue</Link></Button>
							</Typography>
						</Box>
					</Modal>
				</div>

			
			{/*Create invoice*/}
			<CreateTenantInvoice customerId={tenantInfo.customerId} billableItems={tenantInfo.billableItems} tenantName={tenantInfo.tenantName} paymentOpen={createInvoiceModal} onClose={() => setCreateInvoiceModal(false)} />
			
			{/*Multiple Mpesa Stk*/}
			<TenantMpesaStk number_of_invoices={selectedRows.length + " " + "invoices"} mpesaStkOpen={mpesaPaymentOpen} onClose={() => setMpesaPaymentOpen(false)} invoiceBalance={totalInvoiceAmount} invoiceNumber={invoicesString}/>
		</div>
		</>
	)
}