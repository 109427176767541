import React, {useState, useEffect, useRef} from "react";
import { Modal, Grid, Box, Button } from "@mui/material";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import upload from "../../../common/images/upload.png"
import categoryTemplate from "../../../common/images/productTemplateTwo.png";

const useStyles = makeStyles((theme) => ({
    dropzone: {
        border: `1px dashed #032541`,
        padding: theme.spacing(2),
        textAlign: "center",
        height: '326px',
        width: '485px',
    },

}));

const button = {
    height: '93px',
    width: '485px',
    backgroundColor: '#032541',
    color:'#fff',
    marginTop:"0.313rem",
    "&:hover": {
        backgroundColor: '#032541',
        color:'#fff',
    },
}

const buttonTwo = {
    height: '38.8px',
    width: '122px',
    backgroundColor: '#032541',
    color:'#fff',
    "&:hover": {
        backgroundColor: '#032541',
        color:'#fff',
    },
}

const ProductUploadModal = (props) => {
    const fileInputRef = useRef(null);

    const classes = useStyles();
    const [productOpen, setProductOpen] = useState(props.productOpen);
    const [file, setFile] = useState(null);
    const [selectedFile,setSelectedFile] = useState()
    const [uploadProgress, setUploadProgress] = useState(0);
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const wholeModal = {
        width: '561px',
        height: '577px',
        backgroundColor: '#fff',
        borderColor:"transparent",
        borderRadius: "7px",
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        p: 5,
    };

    const bar = {
        height: '15px',
    };

    const firstText = {
        color: '#041920',
        fontSize: '20px',
        fontWeight: 600
    }

    const secondText = {
        color: '#6e7074',
        fontSize: '15px'
    }

    useEffect(() => {
        setProductOpen(props.productOpen);
    }, [props.productOpen]);


    const handleFileUpload = (e) => {
        const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        const selectedFile = e.dataTransfer ? e.dataTransfer.files[0].name : e.target.files[0].name;
        setFile(file);
        setSelectedFile(selectedFile);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        handleFileUpload(e);
    };
    //
    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("file", file);

        const config = {
            headers: {
                "content-type": "multipart/form-data",
                "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            onUploadProgress: (progressEvent) => {
                setUploadProgress(
                    Math.round((progressEvent.loaded * 100) / progressEvent.total)
                );
            },
        };

        axios
            .post(`${baseUrl}/api/upload_products`, formData, config)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div>
            <Modal
                open={productOpen}
                onClose={props.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={wholeModal}>
                    <img style={{width:"480px", height:"50px", marginBottom:"0.625rem"}} src={categoryTemplate} alt='Categories Template'/>
                    <br/>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div
                                className={classes.dropzone}
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                            >
                                <input
                                    type="file"
                                    onChange={handleFileUpload}
                                    style={{display: "none"}}
                                    ref={fileInputRef}
                                />
                                <img src={upload} style={{width:"60px", height:"80px", marginBottom:"3.125rem"}}  alt={"File upload image"}/>
                                <p style={secondText}>Selected file: {selectedFile}</p>
                                <p style={firstText}>Drag and Drop .CSV file here</p>
                                <p style={secondText}>The file should not exceed 1 MB in size</p>
                                <Button
                                    onClick={() => fileInputRef.current.click()}
                                    sx={buttonTwo}
                                >
                                    Select File
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <LinearProgress variant="determinate" value={uploadProgress} sx={bar} />
                            <Button type="submit"  onClick={handleSubmit}  sx={button}>
                                Upload
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
};
export { ProductUploadModal };
