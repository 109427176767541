import React, { useEffect, useState } from "react";
import { Grid, Box, Button, Tab, styled, Stack, MenuItem, alpha, Menu } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import { CSVLink } from "react-csv";

// MENU;
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export const Exports = (props) => {
  // handleMenu;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //   userData;
  const exportData = props?.exportData;
  const activeTabs = props?.activeTabs;
  const csvExport = props?.csvExport;
  // console.log(csvExport);

  // export pdf

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    let content = {
      startY: 50,
      head: exportData?.headers,
      body: exportData?.data,
    };

    doc.text(`${activeTabs}-${new Date().toDateString()}.pdf`, marginLeft, 40);
    doc.autoTable(content);
    doc.save(`${activeTabs}-${new Date().toDateString()}.pdf`);
  };

  const doPrintPdf = () => {
    handleClose();
    exportPDF();
  };

  // csv exports;

  const csvExports = {
    data: csvExport?.data,
    headers: csvExport?.headers,
    filename: `${activeTabs}- ${new Date().toDateString()}.csv`,
  };



  // Excel exports;
  const excelExports = () => {
    const ws = XLSX.utils.json_to_sheet(exportData?.data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

    /* generate XLSX file and send to client */
    XLSX.writeFile(
        wb,
        `${activeTabs}-${new Date().toDateString()}.xlsx`
    );
};

const doPrintExcel = () => {

    handleClose();
    excelExports();
 
}

  return (
    <div>
      <Button style={{ fontFamily: "Poppins", fontWeight: "500", margin: "0 12px 0 0", padding: "9px 10px 8px 11px", backgroundColor: "#f5f6f7", color: "#032541" }} endIcon={<MoreVert />} id="demo-customized-button" aria-controls={open ? "demo-customized-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} variant="contained" disableElevation onClick={handleClick}>
        Export
      </Button>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={doPrintPdf} disableRipple style={{}}>
          Export as PDF
        </MenuItem>
        <MenuItem onClick={doPrintExcel} disableRipple>
          Export as Excel
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <CSVLink
            style={{
              color: "#707070",
              fontsize: "14px",
              textDecoration: "none",
              "&:hover": {
                color: "#032541",
                textDecoration: "underline",
              },
            }}
            {...csvExports}
          >Export as CSV</CSVLink>
          
        </MenuItem>
      </StyledMenu>
    </div>
  );
};
