import {TextField} from "@mui/material";
import React from 'react';

const CustomTextField = ({value, onChange,name, placeholder,disabled,large,xlarge,fw, sm, ...rest }) => {
    return (
        <TextField
            disabled={disabled}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            variant="outlined"
            margin="dense"
            fontSize="0.875rem"
            fontWeight= {fw ? "bold" : "normal"}
            sx={{marginBottom:2}}
            InputProps={{
                sx: {
                    width: large ? '31rem': sm ? '266px' : xlarge ? '41.5rem' :'25.438rem',
                    height: '3.438rem',
                    fontSize:"0.875rem",
                    "& fieldset": {
                        borderColor: "#bec5d1"
                    }
                }
            }}
            {...rest}
        />
    );
};

export default CustomTextField;

