import React, { useState } from "react";
import { Grid, Input } from "@mui/material";
import { useNavigate } from "react-router-dom";
import loginLogo from "../../images/zedLogo.png";
import "./phoneBootstrap.css";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import Button from "@mui/material/Button";
import loginImage from "../../common/images/login_image.svg";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import HttpComponent from "../School/MakeRequest";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  imgRes: {
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      width: "100%",
      objectFit: "contain",
      height: "100%",
      display: "inline-block",
    },
    [theme.breakpoints.up("md")]: {
      alignItems: "center",
      justifyContent: "center",
      width: "40rem",
      paddingLeft: "5rem",
      objectFit: "contain",
      height: "100%",
      display: "inline-block",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
      alignItems: "center",
      width: "100%",
      objectFit: "contain",
      height: "100%",
    },
  },
  gridLayout: {
    [theme.breakpoints.up("md")]: {
      paddingRight: "10rem",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  },
}));

const Login = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  //Notification
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });

  // Toggle eye
  const [values, setValues] = React.useState({
    newPin: "",
    confirmPin: "",
    oneTimePin: "",
    showNewPin: false,
    showConfirmPin: false,
    showOneTimePin: false,
  });

  const handleMouseNewDownPassword = (event) => {
    event.preventDefault();
  };

  // Eye toggle stuff
  const handleClickNewShowPin = () => {
    setValues({ ...values, showNewPin: !values.showNewPin });
  };

  const handleClickOneTimePin = () => {
    setValues({ ...values, showOneTimePin: !values.showOneTimePin });
  };

  const handleClickConfirmPin = () => {
    setValues({ ...values, showConfirmPin: !values.showConfirmPin });
  };

  // setters and Getters
  const [userPin, setUserPin] = useState("");
  const [oneTimePin, setOneTimePin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");

  function submitHandler() {
    if (userPin !== confirmPin) {
      return setErrorShow({ state: true, message: "New Pin Doesn't Match Pin Confirmation!" });
    }

    const resetBody = {
      userEmail: localStorage.getItem("email"),
      userPin: oneTimePin,
      userNewPin: userPin,
    };

    HttpComponent({
      method: "PUT",
      url: "/api/resetPinVersion2",
      body: resetBody,
    })
      .then((data) => {
        if (data.status === 200) {
          setSuccessShow({ state: true, message: "Pin reset successful" });
          navigate("/");
        } else if (data.response.message.includes("Error wrong pin!")) {
          setErrorShow({ state: true, message: data.response.message });
        } else if (data.status === 401) {
          setErrorShow({ state: true, message: data.response.message });
        }
      })
      .catch((e) => {
        console.log(`Error resetting Pin`, e.message);
      });
  }

  return (
    <Grid container style={{ fontFamily: "Poppins" }}>
      {/*snack bars*/}
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />

      {/*Header logo Container*/}
      <Grid container direction={"row"} style={{ paddingLeft: "10%", paddingRight: "10%" }}>
        {/*Logo image*/}
        <Grid item>
          <img src={loginLogo} alt="logo" style={{ marginTop: "10%" }} />
        </Grid>
      </Grid>

      {/*login image and Form container*/}
      <Grid container justifyContent={"center"} alignContent={"center"}>
        <Grid container justifyContent={"space-between"} direction={"row"} spacing={1} column={1} alignContent={"flex-start"} style={{ marginTop: "5%", paddingLeft: "5%" }}>
          {/*login image*/}
          <Grid item lg={6} xl={6} md={6} sm={12} xs={12}>
            <img src={loginImage} className={`img-fluid ${classes.imgRes}`} alt={"login image"} style={{ width: "50rem" }} />
          </Grid>

          {/*Login Form*/}
          <Grid item className={classes.gridLayout} style={{ marginTop: "5%" }} lg={6} xl={6} md={6} sm={12} xs={12}>
            {/*Login form*/}
            <Grid container direction={"column"} justifyContent={"space-between"} spacing={2} alignContent={"flex-start"}>
              {/*login label*/}
              <Grid item>
                <span style={{ fontSize: "20px", color: "#032541", fontWeight: "600" }}>Reset Pin</span>
              </Grid>
            </Grid>

            {/*Email Pin*/}
            <Grid item>
              {/*login input label*/}
              <Grid item style={{ marginTop: "2%" }}>
                <span style={{ fontSize: "15px", color: "#032541" }}>
                  {" "}
                  OneTime Pin
                  <span style={{ color: "red" }}>*</span>
                </span>
              </Grid>

              {/*pin input*/}
              <Grid item style={{ marginTop: "5%" }}>
                <Input
                  fullWidth
                  required
                  style={{ borderRadius: "6px", paddingLeft: "10px", height: "55px", fontSize: "15px", border: "solid 1px #e4e4e4" }}
                  disableUnderline
                  placeholder={"Enter OneTime Pin"}
                  type={values.showOneTimePin ? "text" : "password"}
                  value={oneTimePin}
                  onChange={(e) => setOneTimePin(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickOneTimePin} onMouseDown={handleMouseNewDownPassword}>
                        {values.showOneTimePin ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>

            {/*Phone*/}
            <Grid item>
              {/*login input label*/}
              <Grid item style={{ marginTop: "2%" }}>
                <span style={{ fontSize: "15px", color: "#032541" }}>
                  {" "}
                  New Pin
                  <span style={{ color: "red" }}>*</span>
                </span>
              </Grid>

              {/* New pin input*/}
              <Grid item style={{ marginTop: "5%" }}>
                <Input
                  required
                  fullWidth
                  style={{ borderRadius: "6px", paddingLeft: "10px", height: "55px", fontSize: "15px", border: "solid 1px #e4e4e4" }}
                  disableUnderline
                  placeholder={"Enter New Pin"}
                  type={values.showNewPin ? "text" : "password"}
                  value={userPin}
                  onChange={(e) => setUserPin(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickNewShowPin} onMouseDown={handleMouseNewDownPassword}>
                        {values.showNewPin ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>

            {/*pin*/}
            <Grid item>
              {/*pin label*/}
              <Grid item style={{ marginTop: "5%" }}>
                <span style={{ fontSize: "15px", color: "#032541" }}>
                  Confirm Pin
                  <span style={{ color: "red" }}>*</span>
                </span>
              </Grid>
            </Grid>

            {/*Confirm pin input*/}
            <Grid item style={{ marginTop: "5%" }}>
              <Input
                required
                fullWidth
                style={{ borderRadius: "6px", paddingLeft: "10px", height: "55px", fontSize: "15px", border: "solid 1px #e4e4e4" }}
                disableUnderline
                placeholder={"Enter Confirmation Pin"}
                type={values.showConfirmPin ? "text" : "password"}
                value={confirmPin}
                onChange={(e) => setConfirmPin(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickConfirmPin} onMouseDown={handleMouseNewDownPassword}>
                      {values.showConfirmPin ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>

            {/*Login button*/}
            <Grid container justifyContent={"center"} alignContent={"center"} style={{ marginTop: "10%" }}>
              <Button onClick={submitHandler} style={{ color: "white", backgroundColor: "#032541", height: "45px", width: "171px" }}>
                Reset
              </Button>
            </Grid>
            {/*New user Signup*/}
            <Grid container justifyContent={"center"} alignContent={"center"} style={{ marginTop: "5%", fontSize: "15px", color: "#032541" }}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
