import { Box, Breadcrumbs, Button, ButtonBase, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import React, { useEffect, useState } from "react";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ExportMenu from "./ExportMenu";
import HttpComponent from "./MakeRequest";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment-timezone";
import { Link, useNavigate } from "react-router-dom";
import CustomSearchInput from "./CustomSearchInput";
import { NoRowsOverlay } from "../No Rows/noRowsOverlay";
import CustomTable from "./CustomTable";
import CustomSelectField from "./CustomSelectField";
import CustomInputLabel from "./CustomInputLabel";

let baseUrl = process.env.REACT_APP_BASE_URL;


const breadcrumbs = [
  <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
    Dashboard
  </Typography>,
  <Typography key={"Invoices"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
    Student Report
  </Typography>
];

const createButton = {
  // "width": "15.313rem",
  // "height": "2.813rem",
  "width": "125px",
  "height": "45px",
  "borderRadius": "5px",
  "border": "solid 1px #002543",
  "backgroundColor": "#fff",
  "color": "#032541",
  '&:hover': {
    backgroundColor: '#fff',
    color: '#032541'
  }
}
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);

const StudentReports = () => {

  let navigate = useNavigate();


  const [year, setYear] = useState('');

  const years = Array.from({ length: 5 }, (_, index) => `${new Date().getFullYear() - index}`);

  const handleChange = (event) => {
    setYear(event.target.value);

  };
  const [pageSize, setPageSize] = React.useState(10);
  const businessName = localStorage.getItem('businessName')

  const [grade, setGrade] = useState('')
  const [stream, setStream] = useState('')
  const [courseName, setCourseName] = useState('')
  const [selectedYear, setSelectedYear] = useState('')

  const [term, setTerm] = useState('')
  const [boardingStatus, setBoardingStatus] = useState('')

  const [invoiceStatus, setinvoiceStatus] = useState('')
  const [searchValue, setSearchValue] = useState('')



  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
    // search : searchValue
  });
  const [dataStudent, setDataStudentDetails] = useState([])

  const columns = [
    {
      field: "admisisonNumber",
      headerName: "Admission No",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",

      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541", }}>Admission Number</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              <Link to={`${`/school/studentpayments/${params.value}`}`}>
                {params.value}
              </Link>
            </Typography>
          </div>
        );
      }
    },
    {
      field: "studentName",
      headerName: "student Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Student Name</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },

    {
      field: "grade",
      headerName: "Grade",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Grade</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "stream",
      headerName: "Stream",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Stream</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "boardingStatus",
      headerName: "Boarding Status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Boarding Status</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },

    {
      field: "term",
      headerName: "Term",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Term</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "parentName",
      headerName: "parentName",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Parent Name</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "phone",
      headerName: "Mobile No",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Mobile No</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "dateOfAdmission",
      headerName: "Date Of Admission",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Date Of Admission</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {moment(params.value).format('DD-MM-YYYY')}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "balance",
      headerName: "Balance",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Balance</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },



  ]
  const columnsCollege = [
    {
      field: "admisisonNumber",
      headerName: "Admission No",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",

      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541", }}>Admission Number</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              <Link to={`${`/school/studentpayments/${params.value}`}`}>
                {params.value}
              </Link>
            </Typography>
          </div>
        );
      }
    },
    {
      field: "studentName",
      headerName: "student Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Student Name</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },

    {
      field: "grade",
      headerName: "Course",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Course</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },

    {
      field: "term",
      headerName: "Semester",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Semester</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },

    {
      field: "phone",
      headerName: "Mobile No",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Mobile No</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "dateOfAdmission",
      headerName: "Date Of Admission",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Date Of Admission</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {moment(params.value).format('DD-MM-YYYY')}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "balance",
      headerName: "Balance",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Balance</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },
  ]

  const { userId, X_Authorization } = useSelector((store) => store.user);

  const getStudentDetails = async () => {
    try {
      HttpComponent({
        method: "GET",
        url: `/api/get_student_report?page=${pageState.page}&limit=10000000`,
        token: X_Authorization,
      }).then((data) => {
        setDataStudentDetails(data.response.data)
        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: data.response.data,
          total: data.response.data.count,
        }));

      })



    } catch (error) {
      console.log(error, 'Error data');

    }
  }

  useEffect(() => {
    getStudentDetails()

  }, [])

  console.log('dataStudent', dataStudent)


  const navigateStudentPayments = (id) => {
    navigate(`/school/studentpayments/${id}`);
  }


console.log('rows', courseName, grade, term, invoiceStatus)
  const rows = dataStudent.filter((item) => {
    return courseName === '' ? item : courseName === 'ALL' ? item : item?.grade.includes(courseName)

  })
  .filter((item) => {
    return grade === '' ? item : grade === 'ALL' ? item : item?.grade.includes(grade)

  })
    .filter((item) => {
      return term === '' ? item : term === 'ALL' ? item : item?.term.includes(term)

    })
    .filter((item) => {
      return invoiceStatus == '' ? item : invoiceStatus === 'ALL' ? item : item?.invoiceStatus.includes(invoiceStatus)

      // new Date(item.dateOfAdmission).getFullYear() === year
    }).filter((item) => {
      console.log("new Date(item.dateOfAdmission)", new Date(item.dateOfAdmission).getFullYear());
      let filterDate = new Date(item?.dateOfAdmission).getFullYear().toString();
      return year === '' ? item : filterDate === year;
    })
    .filter((item) => {
      return searchValue?.toLowerCase() === "" ? item : item?.admisisonNumber?.toLowerCase().includes(searchValue?.toLowerCase())

    })
    .map((item, index) => {
      return {
        id: index,
        parentId: item.parentId,
        admisisonNumber: item.admisisonNumber,
        studentName: item.studentName,
        grade: item.grade,
        term: item.term,
        stream: item.stream,
        boardingStatus: item.boardingStatus,
        parentName: item.parentName,
        phone: item.phone,
        balance: item.balance,
        invoiceStatus: item.invoiceStatus,
        dateOfAdmission: item.dateOfAdmission,
        invoiceCount: item.invoiceCount,

      }
    })


  console.log('rows:', rows);
  console.log('Year...........:', year);
  const csvStudentColumns = [
    { label: "Admission No", key: "Admission No" },
    { label: "Student Name", key: "Student Name" },
    { label: "Grade", key: "Grade" },
    { label: "Term", key: "Term" },
    { label: "Stream", key: "Stream" },
    { label: "Boarding Status", key: "Boarding Status" },
    { label: "Parent Name", key: "Parent Name" },
    { label: "Balance", key: "Balance" },
    // { label: "Invoice Status", key: "Invoice Status" },
    { label: "Date Of Admission", key: "Date Of Admission" },
    { label: "Phone", key: "Phone" },

  ]

  const newstudentFileData = dataStudent.map((item) => {
    return {
      "Admission No": item.admisisonNumber,
      "Student Name": item.studentName,
      "Grade": item.grade,
      "Term": item.term,
      "Stream": item.stream,
      "Boarding Status": item.boardingStatus,
      "Parent Name": item.parentName,
      "Phone": item.phone,
      "Balance": item.balance,
      // "Invoice Status": item.invoiceStatus,
      "Date Of Admission": item.dateOfAdmission,

    }
  })

  const studentFileData = dataStudent.map((item) => {
    return {
      admisisonNumber: item.admisisonNumber,
      studentName: item.studentName,
      grade: item.grade,
      term: item.term,
      stream: item.stream,
      boardingStatus: item.boardingStatus,
      parentName: item.parentName,
      phone: item.phone,
      balance: item.balance,
      // invoiceStatus: item.invoiceStatus,
      dateOfAdmission: item.dateOfAdmission,

    }
  })

  const fileDataStudentHeaders = [["Admission No", "Student Name", "Grade", "Term", "stream", "boardingStatus", "Parent Name", "Balance", "Date Of Admission"]]

  const fileDataStudentPDF = dataStudent.map((item) => [item?.admisisonNumber, item?.studentName, item?.grade, item?.term, item.stream, item.boardingStatus, item?.parentName, item?.balance, new Date(item?.dateOfAdmission).toLocaleDateString()])
  const [gradeOptions, setGradeOptions] = useState([])
  const [courseOptions, setCourseOptions] = useState([]);
  const [streamsOptions, setStreamesOptions] = useState([]);
  const [termOptions, setTermOptions] = useState([]);
  const { schoolTypeName, schoolTypeId } = useSelector((store) => store.schoolType.schoolTypeDetail)
  const [coursesOptions, setCoursesOptions] = useState([]);


  const getCourses = async () => {
    const response = await fetch(`${baseUrl}/api/v1/get_school_courses`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
      method: "GET",
    });
    const data = await response.json();
    console.log(data, "Get Courses");
    if (response.status === 200) {
      // setCoursesOptions(data.data);
      setCoursesOptions(data.data.map((courses) => {
        return { value: courses.courseName, label: courses.courseName }
      }
      ))
    }
  };

  useEffect(() => {
    getCourses();
  }, []);




  console.log('School Type Id', schoolTypeId);
  const GetGrades = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeId}`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    console.log(data, "Grades");

    if (response.status === 201) {
      setGradeOptions(data.data.map((itemGrade) => {
        return { value: itemGrade.schoolGrades, label: itemGrade.schoolGrades }
      }
      ))
    }
  };
  const GetStreams = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_streams`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    console.log(data, "Streams");
    if (response.status === 201) {
      setStreamesOptions(data.data.map((itemStream) => {
        return { value: itemStream.streamName, label: itemStream.streamName }
      }
      ))
    }
  };

  const GetStreamsTerms = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_terms?schoolType_id=${schoolTypeId}`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    console.log(data, "Streams");
    if (response.status === 201) {
      setTermOptions(data.data.map((terms) => {
        return { value: terms.schoolGrades, label: terms.schoolGrades }
      }
      ))
    }
  };
  useEffect(() => {
    if (schoolTypeId) {
      GetGrades();
      GetStreams();
      GetStreamsTerms();
    }

  }, [schoolTypeId]);
  const [boardingStatusOptions, setBoardingStatusOptions] = useState([])
  const getBoardingStatus = async () => {
    const response = await fetch(`${baseUrl}/api/getboardingStatus`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    // console.log("here store is data",data);
    if (data.status === "SUCCESS") {
      console.log("here Boarding Status", data);
      setBoardingStatusOptions(data.data.map((boardingStatus) => {
        return { value: boardingStatus, label: boardingStatus }
      }
      ))
    } else {
      console.error("Error setting info")
      setErrorShow({ state: true, message: "Error setting info" })
    }

  }
  useEffect(() => {
    getBoardingStatus()
  }, [])

  return (
    <>
      <Box component="div">
        <Box component="div" sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box component="div">
            <Box component="div" sx={{}}>
              <Typography sx={{ color: "#032541", fontSize: "1.563rem", fontWeight: 700 }}>
                Reports/Student Reports
              </Typography>
            </Box>
            <Box component="div" sx={{}}>
              <Breadcrumbs
                separator={
                  <FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: "Poppins", color: "#e1e5e8" }} />
                }
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Box>
          </Box>




        </Box>
        <Box component='div' className="mt-3">
          <div className="" style={{ display: "flex", justifyContent: "space-between", }}>
            {/* <div className="">
                <Paper
                  component="form"
                  sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 250 }}
                >

                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search ....."
                    inputProps={{ 'aria-label': 'search .....' }}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                  </IconButton>

                </Paper>
              </div> */}
            <CustomSearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={'Search by Admission...'} />
            <div className="">
              <ExportMenu
                csvColumns={csvStudentColumns}
                fileData={newstudentFileData}
                fileHeaders={fileDataStudentHeaders}
                fileDataPDF={fileDataStudentPDF}
                title={"BusinessName :" + businessName + "Report Type: " + "   Students Report  " + "Print Date : " + new Date().toLocaleDateString()}
                fileName={'studentreport'}
              />
            </div>
          </div>
          <Box sx={{ display: "flex" }}>
            <div className="">
              <div className="" style={{ display: "flex" }}>
                <div className="" >
                  <Box component="div" sm={{ marginX: 1, maxWidth: "200px", }}  >
                    {schoolTypeName.includes("University") ?              
                    <CustomSelectField value={courseName} onChange={(e) => setCourseName(e.target.value)} name={"courseName"} placeholder={"select CourseName"} options={coursesOptions} sx={{ marginRight: "20px" }} />
 : (
                    <CustomSelectField value={grade} onChange={(e) => setGrade(e.target.value)} name={"grade"} placeholder={"Select Grade"} options={gradeOptions} sm={{ maxWidth: "200px" }} />
                    )}
                  </Box>
                </div>
                {schoolTypeName.includes("University") ? null : (
                  <div className="ml-2">
                    <Box component="div" sm={{ marginX: 1, maxWidth: "200px", }}>
                      <CustomSelectField value={stream} onChange={(e) => setStream(e.target.value)} name={"Stream"} placeholder={"Select Streams"} options={streamsOptions} sm={{ maxWidth: "200px" }} />
                      {/*<CustomTextFieldSmall value={formData.term} onChange={handleInputChange} name={"term"} placeholder={"Term"} />*/}
                    </Box>
                  </div>
                )}

                <div className="ml-2">
                  {/* <FormControl style={{ width: "180px", height: "45px", border: "solid 1px #cdd39d9", color: "#fff" }}>
                    <InputLabel id="Select Term">Select Term</InputLabel>
                    <Select
                      labelId="Term"
                      id="year-select"

                      label="Year"
                      value={term}
                      onChange={(e) => setTerm(e.target.value)}
                    >
                      <MenuItem value={"Select Term"} disabled>
                        Select Term
                      </MenuItem>
                      <MenuItem value={"ALL"}>All</MenuItem>
                      <MenuItem value={"Term 1"}>Term 1</MenuItem>
                      <MenuItem value={"Term 2"}>Term 2</MenuItem>
                      <MenuItem value={"Term 3"}>Term 3</MenuItem>
                    </Select>
                  </FormControl> */}
                  <Box component="div" sm={{ marginX: 1, maxWidth: "200px", }}>
                    <CustomSelectField value={term} onChange={(e) => setTerm(e.target.value)} name={"Term"} placeholder={schoolTypeName.includes("University") ? "Select Semesters" : "Select Term"} options={termOptions} sm={{ maxWidth: "200px" }} />
                    {/*<CustomTextFieldSmall value={formData.term} onChange={handleInputChange} name={"term"} placeholder={"Term"} />*/}
                  </Box>
                </div>
                {schoolTypeName.includes("University") ? null : (
                  <div className="ml-2" >

                    <Box component="div" sm={{ marginX: 1, }}>
                      <CustomSelectField value={boardingStatus} onChange={(e) => setBoardingStatus(e.target.value)} name={"boardingStatus"} placeholder={"Select Boarding Status"} options={boardingStatusOptions} sm={{ maxWidth: "200px" }} />
                      {/*<CustomTextFieldSmall value={formData.term} onChange={handleInputChange} name={"term"} placeholder={"Term"} />*/}
                    </Box>
                  </div>
                )}
                {schoolTypeName.includes("University") ?
                  <div className="ml-2" >
                    <Box component="div" sm={{ marginX: 1, maxWidth: "200px", }}  >
                      <CustomSelectField value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} name={"selectedYear"} placeholder={"Select Year"} options={gradeOptions} sm={{ maxWidth: "200px" }} />

                    </Box>
                  </div>
                  : (
                    <div className="ml-2 mt-2">
                      <FormControl style={{ width: "200px", height: "45px", border: "solid 1px #cdd39d9", color: "#fff" }}>
                        <InputLabel id="year-select-label">Select YEAR</InputLabel>
                        <Select
                          labelId="year-select-label"
                          id="year-select"
                          value={year}
                          label="Year"
                          onChange={handleChange}
                        >
                          <MenuItem value={"Select Year"} disabled>
                            Select Year
                          </MenuItem>
                          {years.map((year) => (
                            <MenuItem key={year} value={year}>
                              {year}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                <div className="ml-2 mt-2">
                  <FormControl style={{ width: "200px", height: "45px", border: "solid 1px #cdd39d9", color: "#fff" }}>
                    <InputLabel id="">Date</InputLabel>
                    <Select
                      labelId="This Month"
                      id="date"

                      label="Date"
                      value=""

                    >
                      <MenuItem value={"today"} >Today</MenuItem>
                      <MenuItem value={"week"} >This Week</MenuItem>
                      <MenuItem value={"month"} >This  Month</MenuItem>
                      <MenuItem value={"last_month"} >Last Month </MenuItem>
                      <MenuItem value={"last_3_month"} >Last Three Months </MenuItem>
                      <MenuItem value={"year"} >This Year </MenuItem>
                      <MenuItem value={"custom_range"} >Custom Range </MenuItem>
                    </Select>
                  </FormControl>

                </div>
              </div>

            </div>

          </Box>
        </Box>

        <Box component={'div'} className="mt-5">
          {/* <DataGrid style={{ height: "500px", width: "auto", overflow: "visible", }}
            rows={rows}
            components={{ NoRowsOverlay: NoRowsOverlay }}


            onRowClick={(row) => {
              // handle row click event here
              console.log("Clicked row:", row.row.parentId);
              navigateStudentPayments(row.row?.admisisonNumber);
            }}
            rowCount={pageState.total}
            loading={pageState.isLoading}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            page={pageState.page - 1}
            pageSize={pageState.pageSize}
            paginationMode="server"
            columns={columns}
            onPageChange={(newPage) => {
              setPageState((old) => ({
                ...old,
                page: newPage + 1,
                pageSize: pageSize,
              }));
            }}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}

          /> */}
          <CustomTable data={dataStudent} columns={schoolTypeName.includes("University") ? columnsCollege : columns} rowsPerPage={pageSize} />

          {/* <Box sx={{ display: 'flex', width: '60%', height: "50px", marginTop: '20px', justifyContent: 'space-between', position: 'absolute', top: 764, right: 440, }}>
            <div style={{display:"flex" , justifyContent:"space-evenly" }}>
              <Typography style={{ fontWeight: 900, fontSize: '21px', marginLeft: '10px', }}>Total:</Typography>
              <Typography style={{ fontWeight: 900, fontSize: '21px', marginLeft: '80px'}}>100 Student </Typography>
            </div>
            <div>
              <Typography style={{ marginLeft: '35px', fontWeight: 900, fontSize: '21px' }}>Total Balance: {numberFormat(0.00)}</Typography>
            </div>
          </Box> */}

        </Box>
      </Box>
      <></>
    </>
  );
};

export default StudentReports;
