import { Breadcrumbs, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, Menu, MenuItem, OutlinedInput, Typography } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";
import ExportMenu from "../../../School/ExportMenu";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../../No Rows/noRowsOverlay";
import CreateNewDevice from "./createDevice";
import EditIcon from '@mui/icons-material/Edit';
import HttpComponent from "../../../School/MakeRequest";
import DateFormatter from "../../../../utils/dateFormatter";
import EditDeviceModel from "./editDevice";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { alpha, styled } from "@mui/material/styles";

//breadcrumbs
const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Zed-Payit</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Device Models</Typography>
]

//styled menu

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
            frontFamily: "Poppins",
        }}
        {...props}
    />
))(({ theme }) => ({ "& .MuiPaper-root": { borderRadius: 6, marginTop: theme.spacing(1), minWidth: 18, frontFamily: "Poppins", color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300], boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px", "& .MuiMenu-list": { padding: "4px 0" }, "& .MuiMenuItem-root": { "& .MuiSvgIcon-root": { frontFamily: "Poppins", fontSize: 18, color: theme.palette.text.secondary, marginRight: theme.spacing(1.5), }, "&:active": { backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity), }, }, }, }));


export default function TerminalDevices(props) {

    const navigate = useNavigate()

    const [deviceId, setDeviceId] = useState(null)
    const [searchValue , setSearchValue] = useState('')

    //datagrid pagination

    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })


    const RenderCell = (params) => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const ActionOpen = Boolean(anchorEl);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget)
        };

        const handleActionClose = () => {
            setAnchorEl(null);
        };
        return (
            <div style={{ display: "flex", alignContent: "center" }}>
                <MoreVertIcon onClick={handleClick} />
                <StyledMenu anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
                    <MenuItem onClick={() => { navigate(`/terminal/devices?edit`); setDeviceId(params?.row?._id) }} spacing={2} style={{ display: "flex", alignContent: "center", alignItems: "center" }} disableRipple >
                        <EditIcon style={{ color: "#032541" }} />
                        <span style={{ marginLeft: "10px" }}>Edit Model</span>
                    </MenuItem>
                </StyledMenu>
            </div>
        )

    }

    const devicemodelcolumn = [
        { headerName: "Model", field: 'deviceName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Model</strong>) } },
        { headerName: "Device Type", field: 'deviceType', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Device Type</strong>) } },
        {
            headerName: "last Modified", field: 'createdAt', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Last Modified</strong>) }, renderCell: (params) => {
                return (
                    <span>{DateFormatter(params?.row?.createdAt)}</span>
                )
            }
        },
        { headerName: "Suppoerted Version", field: 'version', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Supported Version</strong>) } },
        { headerName: "Action", field: 'action', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Actions</strong>) }, renderCell: RenderCell },

    ]

    const queryParams = decodeURIComponent(window.location.search)
    const state = queryParams.slice(1)

    function fetchAllDevices() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/getBusinessModels?page=${pageState.page}&limit=${dataGridPageSize}`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        if(!searchValue)
        fetchAllDevices()
    }, [state,dataGridPageSize , pageState.page ,searchValue])


     //handle search

     const handleSearch = ()=>{
        try {
            HttpComponent({
                method:'GET',
                url:`/api/v1/searchdeviceModels?searchValue=${searchValue}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if (data.status === 200) {
                      setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {
            
        }
    }

    useEffect(()=>{
        if(searchValue)
        handleSearch()
    },[searchValue , dataGridPageSize, pageState.page ,])



    //export data

    const filedata = pageState?.data?.map((data) => {
        return {
            "Model": data?.deviceName,
            "Device Type": data?.deviceType,
            "last Modified": data?.createdAt,
            "Suppoerted Version": data?.version,
        }
    })
    const fileHeaders = [["Model", "Device Type", "last Modified", "Suppoerted Version",]]
    const csvColumns = [
        { label: "Model", key: "Model" },
        { label: "Device", key: "Device" },
        { label: "last", key: "last" },
        { label: "Suppoerted Version", key: "Suppoerted Version" },
        
    ];
    const fileDataPDF = pageState?.data?.map((data) => [data?.deviceName, data?.deviceType, data?.createdAt, data?.version]);

    return (
        <Grid containter direction={'column'}>
            {state === 'createnewdevicemodel' ? <CreateNewDevice /> : state === 'edit' ? <EditDeviceModel deviceId={deviceId} /> :
                <>
                    <Grid item>
                        <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Device Models</Typography>
                    </Grid>
                    <Grid item display={'flex'} mt={2}>
                        <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                            <Grid item>
                                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Grid>
                            <Grid item>
                                <Button onClick={() => navigate('/terminal/devices?createnewdevicemodel')} style={{ background: "#032541", textTransform: "inherit", width: "125px", color: "#fff" }}>Create Type</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item mt={3}>
                        <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                            <Grid item>
                                <FormControl sx={{ m: 1, width: '30ch' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={'text'}
                                        value={searchValue}
                                        onChange={(e)=>setSearchValue(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    edge="end"
                                                >
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Search"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={'Device Model'} title={'Device Model'} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item mt={3}>
                        <DataGrid
                            components={{ NoRowsOverlay: NoRowsOverlay }}
                            sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                            rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                            rowCount={pageState.total}
                            loading={pageState.isLoading}
                            pagination
                            page={pageState.page - 1}
                            pageSize={dataGridPageSize}
                            paginationMode="server"
                            onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            columns={devicemodelcolumn}
                            rows={pageState?.data}
                            getRowId={row => row._id} />
                    </Grid>
                </>}
        </Grid>
    )
}