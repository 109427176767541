import { Box, Breadcrumbs, Button, Grid, TextField, Typography } from "@mui/material";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { useEffect, useRef, useState } from "react";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HttpComponent from "../School/MakeRequest";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
const baseurl = process.env.REACT_APP_BASE_URL
const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Business Info</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Edit Business Info</Typography>
]

export default function EditbS() {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [Logo ,setLogo] = useState('')
    const [businessNumber ,setBusinessNumber] = useState()
    const [file, setFile] = useState(null);
    const [selectedFile, setSelectedFile] = useState()
    const imageUploader = useRef(null);
    const uploadedImage = useRef(null);
    const [img, setImg] = useState(null);

    const handleDrop = (e) => {
        e.preventDefault();
        handleFileInputChange(e);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const { id } = useParams()
    

    const [formData, setFormData] = useState({ bsType: "", bsName: '', bsOwner: "", bsLogo: '', bsPhone: '', bsEmail: '', bsAddress: '', country: '', currency: '' ,bsDescription:''})

    const handleOnChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    //get business info

    function getBusinessInfo() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/get_business_info_byId?id=${id}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setFormData({
                        ...formData,
                        bsType: data?.response?.data?.businessCategory,
                        bsName: data?.response?.data?.businessName,
                        bsLogo: data?.response?.data?.businessLogo,
                        bsPhone: data?.response?.data?.businessOwnerPhone,
                        bsEmail: data?.response?.data?.businessOwnerEmail,
                        bsAddress: data?.response?.data?.businessOwnerAddress,
                        country: data?.response?.data?.country,
                        currency: data?.response?.data?.localCurrency,
                        bsOwner: data?.response?.data?.businessOwnerName,
                        bsDescription:data?.response?.data?.description,
                    })
                    setLogo(data?.response?.data?.businessLogo)
                    setBusinessNumber(data?.response?.data?.businessNumber)
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        getBusinessInfo()
    }, [])

    const navigate = useNavigate()


    //const newImage = process.env.NODE_ENV === 'development' ? `https://dev.zed.business/staticimages/logos/${Logo}` : `https://dev.zed.business/staticimages/logos/${Logo}`

    //update details
    const updateBody = {
        businessName:formData.bsName,
        businessOwnerName:formData.bsOwner,
        businessOwnerPhone:formData.bsPhone,
        businessOwnerAddress:formData.bsAddress,
        businessOwnerEmail:formData.bsEmail,
        description:formData.bsDescription
    }

    const updatebsDetails = ()=>{
        try {
            HttpComponent({
                method:'PUT',
                url:`/api/updateBusiness?businessId=${businessNumber}`,
                body:updateBody,
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if(data.status === 202){
                    setSuccessShow({state:true , message:'Updated business details' })
                }else{
                    setErrorShow({state:true , message:'Error updating'})
                }
            })

            setTimeout(()=>{
                navigate('/shops')
            },1000)
            
        } catch (error) {
            
        }
    }

    const handleFileInputChange = (e) => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            const { current } = uploadedImage;
            current.file = file;
            const selectedFile = file;
            reader.onload = (e) => {
                current.src = e.target.result;
            }
            reader.readAsDataURL(file);
            setFile(file);
            setSelectedFile(selectedFile);
        }
    };

    const baseUrl  = process.env.REACT_APP_BASE_URL
    const  uploadFile = (file)  => { 
        const url = `${baseUrl}/api/edit_business_logo`;
        const xhr = new XMLHttpRequest();
        const formData = new FormData();
      
        formData.append('businessLogo', file);
      
        xhr.open('POST', url, true);
        xhr.setRequestHeader("X-Authorization", localStorage.getItem('X-Authorization'));
        xhr.onload = function() {
            if (xhr.status === 200) {
              let data = JSON.parse(xhr.responseText);
              setSuccessShow({state:true ,message:'Logo updated successfully'})
              setSelectedFile(null)
              setFile(null)
            } else {
                setErrorShow({state:true ,message:'Logo updated successfully'})
                setSelectedFile(null)
                setFile(null)
            }
            getBusinessInfo()
          };
        
          xhr.onerror = function() {
            console.error('An error occurred while uploading the file');
          };
        
          xhr.send(formData);
    }

    // api/updateBusiness?businessId



    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item>
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Edit Business Details</Typography>
            </Grid>
            <Grid item display={'flex'} mt={2}>
                <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                    <Grid item>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2}>
                <Typography style={{ fontWeight: 400, fontSize: "18px", color: "#032541" }}>Edit Business Details</Typography>
            </Grid>
            <Grid item mt={2} mb={3} style={{ width: "50%" }} display={'flex'} direction={'column'} alignContent={'center'} justifyContent={'center'}>
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", width: "198px", height: "102px", padding: "35px", border: "dashed 2px #c2cdda", position: 'relative', cursor: 'pointer' }} onDrop={handleDrop} onDragOver={handleDragOver}>
                    <input type="file" accept="image/*" multiple="false" onChange={handleFileInputChange} style={{ display: "none" }} ref={imageUploader} />
                    <img style={{ width: "150px", height: "90px", position: "absolute", }}
                        onClick={() => imageUploader.current.click()}
                        alt="Upload" src={`${baseurl}/staticimages/logos/${Logo}`} ref={uploadedImage} />
                         <Box sx={{ display:'flex', flexDirection:'column', position:'absolute', top: 30, left: 163, zIndex: 99, width:'30px' }}>
                        <Button style={{ color:'#032541', width:'5%', height:'30px' }} onClick={() => imageUploader.current.click()}><EditIcon /></Button>
                                        </Box>
                </Box>
                    {selectedFile && <Button  style={{ background:'#17ae7b', marginTop:"10px", textTransform:'inherit', color:"#fff", width:'125px', height:'40px' }} onClick={()=>uploadFile(file)}>Update Logo</Button>}
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" disabled readOnly name='bsType' onChange={handleOnChange} style={{ width: "100%" }} value={formData.bsType} label="Business Type" variant="outlined" />
                    </Grid>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='bsName'  style={{ width: "100%" }} onChange={handleOnChange} value={formData.bsName} label="Business Name" variant="outlined" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='bsOwner' onChange={handleOnChange} style={{ width: "100%" }} value={formData.bsOwner} label="Business Owner" variant="outlined" />
                    </Grid>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='bsPhone' readOnly style={{ width: "100%" }} onChange={handleOnChange} value={formData.bsPhone} label="Business Phone" variant="outlined" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='bsEmail' onChange={handleOnChange} style={{ width: "100%" }} value={formData.bsEmail} label="Business Email" variant="outlined" />
                    </Grid>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='bsAddress' onChange={handleOnChange} style={{ width: "100%" }} value={formData.bsAddress} label="Physical Address" variant="outlined" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "50%" }}>
                        <TextField  disabled readOnly id="outlined-basic" name='country' onChange={handleOnChange} style={{ width: "100%" }} value={formData.country} label="Country" variant="outlined" />
                    </Grid>
                    <Grid item style={{ width: "50%" }}>
                        <TextField  disabled readOnly id="outlined-basic" name='currency' style={{ width: "100%" }} value={formData.currency} label="Currency" variant="outlined" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                    <TextField id="outlined-basic" name='bsDescription' onChange={handleOnChange} style={{ width: "99%" }} value={formData.bsDescription} label="description" variant="outlined" />   
            </Grid>
            <Grid mt={2} item style={{ width: "50%" }} display={'flex'} justifyContent={'flex-end'}>
                <Button style={{ background: "#fff", color: "#DC3545", border: "1px solid #DC3545", width: "125px", marginRight: "5px", textTransform: "inherit" }}>Cancel</Button>
                <Button onClick={updatebsDetails} style={{ background: "#032541", textTransform: "inherit", width: "125px", color: "#fff", opacity: 1 }}>Update</Button>
            </Grid>
        </Grid>
    )
}