import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Link } from "react-router-dom";
import React from 'react';
import moment from "moment-timezone/moment-timezone-utils";
import { Typography, Grid, Breadcrumbs, Button, Tab, TextField } from "@mui/material"
import InputAdornment from "@mui/material/InputAdornment";
import { alpha, styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from "@material-ui/core/MenuItem";
import TabList from "@mui/lab/TabList";
import { TabContext, TabPanel } from "@mui/lab";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Menu, { MenuProps } from "@mui/material/Menu";
import MoveUpIcon from '@mui/icons-material/MoveUp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HttpComponent from '../../../School/MakeRequest';
import { NoRowsOverlay } from '../../../No Rows/noRowsOverlay';
import { handleTenantVacationData, handleUpdateTenantBioData } from '../../../../features/tenantsDataSlice';
import { ErrorAlert } from '../../../snackBar Alerts/errorAlert';
import { SuccessAlert } from '../../../snackBar Alerts/successAlert';
import NoticePdf from './NoticePdf';
import NoticePdfPreview from './NoticePreviewpdf';



const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
            frontFamily: "Poppins",
        }}
        {...props}
    />
))(({ theme }) => ({ "& .MuiPaper-root": { borderRadius: 6, marginTop: theme.spacing(1), minWidth: 18, frontFamily: "Poppins", color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300], boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px", "& .MuiMenu-list": { padding: "4px 0" }, "& .MuiMenuItem-root": { "& .MuiSvgIcon-root": { frontFamily: "Poppins", fontSize: 18, color: theme.palette.text.secondary, marginRight: theme.spacing(1.5), }, "&:active": { backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity), }, }, }, }));



//breadcrumps notice
const breadcrumbs = [
    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Dashboard</Typography>,
    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Tenants</Typography>,
    <Typography style={{ color: '#dc3545', fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.error">Give notice</Typography>
]

const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#dc3545" } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none", minWidth: 0, [theme.breakpoints.up("sm")]: { minWidth: 0 },
    fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1),
    fontSize: "18px", fontStretch: "normal", fontStyle: "normal", lineHeight: "2.75",
    letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","),
    "&:hover": { color: "#032541", opacity: 1 }, "&.Mui-selected": { color: "#dc3545", fontWeight: 600 },
    "&.Mui-focusVisible": { backgroundColor: "blue" },
}));

//Number Format
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);
//styled Link
const LinkName = styled(Link)({ textDecoration: "none", color: "#dc3545" });
const ExitNotice = (props) => {
    const { X_Authorization } = useSelector((store) => store.user)
    const [tabValue, setTabValue] = useState("Active");
    const [tenantData, setTenantData] = useState([])
    const [existRequest, setExitRequest] = useState([])
    const [vacatedTenants, setVacatedTenants] = useState([])
    const [noticNum, setNoticeNum] = useState('')
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedTenantInfo, setSelectedTenantInfo] = useState([])
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const ActionOpen = Boolean(anchorEl);
    const dispatch = useDispatch()




    //Handle Tab Change
    const handleTabChange = (event, newValue) => { setTabValue(newValue) };
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const handleActionClose = () => { setAnchorEl(null); };
  
    function updateStoreTenantId(id) { dispatch(handleUpdateTenantBioData(id)) }

    // DataGrid Pagination
    const [dataGridPageSize, setPageSize] = useState(5);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })



    // Get Tenants
    function fetchTenants() {
        try {
            setPageState((old) => ({ ...old, isLoading: true }));
            HttpComponent({
                method: 'GET',
                url: `/api/customers_list?limit=${dataGridPageSize}&page=${pageState.page}&customerType=&status=ACTIVE`,
                token: X_Authorization
            })
                .then((data) => {
                    console.log(data, 'all tenats')
                    if (data.status === 200) {
                        setTenantData(data.response.data)
                        setPageState((old) => ({
                            ...old,
                            isLoading: false,
                            data: data.response.data,
                            total: data.response.count,
                        }));
                    }
                    fetchExitRequest()
                })
                .catch((e) => {
                    console.log(`Error Getting Tenants`, e.message)
                })
        } catch (e) {
            console.log(`Error Getting Tenants`, e.message)
        }
    }

    // useffect for fetching data

    useEffect(() => {
        fetchTenants();
    }, [])

    //fetch exit requests

    //4.GET https://dev.zed.business/api/get_business_eviction_notices?noticeStatus=PENDING&search=&limit=1000&page=1

    function fetchExitRequest() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/get_business_eviction_notices?noticeStatus=PENDING&search=&limit=1000&page=1`,
                token: X_Authorization
            })
                .then((data) => {
                    console.log(data, 'exit request');
                    setExitRequest(data.response.data)
                    //dispatch(handleTenantVacationData(data.response.data))

                })
                .catch((e) => { console.log(`Error Getting Exit Request Data`, e.message) })
        } catch (error) {
            console.log(error.message);
        }

    }

    useEffect(() => {
        fetchExitRequest()
    }, [])


    // Get vacated tenants

    // GET https://dev.zed.business/api/get_business_eviction_notices?noticeStatus=VACATED&search=&limit=1000&page=1 

    function getVacatedTenants() {
        try {
            setPageState((old) => ({ ...old, isLoading: true }));
            HttpComponent({
                method: 'GET',
                url: `/api/get_business_eviction_notices?noticeStatus=VACATED&search=&limit=${dataGridPageSize}&page=${pageState.page}`,
                token: X_Authorization
            })
                .then((data) => {
                    console.log(data, 'vacated tenants')
                    if (data.status === 200) {
                        setVacatedTenants(data.response.data)
                        setPageState((old) => ({
                            ...old,
                            isLoading: false,
                            data: data.response.data,
                            total: data.response.count,
                        }));
                    }
                })
                .catch((e) => {
                    console.log(`Error Getting Tenants`, e.message)
                })
        } catch (e) {
            console.log(`Error Getting Tenants`, e.message)
        }
    }

    useEffect(() => {
        getVacatedTenants()

    }, [])

   


    //post eviction notice
  // POST https://dev.zed.business/api/get_eviction_notice_by_noticeId 

//   function postEvictionNoticeById() {
//     const obj = { noticeNumber: noticNum }
//     try {
//         HttpComponent({
//             method: "POST",
//             url: `/api/get_eviction_notice_by_noticeId`,
//             body: obj,
//             token: X_Authorization,
//           }).then((data) => {
//             setSuccessShow({state:true , message:"notice to exit send successfully"})
//           });
//     } catch (error) {

//     }
//   }

//   useEffect(()=>{
//     postEvictionNoticeById()
//   },[])


  

    //colums for data grid



    const tenantsColumns = [
        { field: 'name', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Names</strong>) }, renderCell: (params) => { return (<><LinkName style={{ marginLeft: "10px" }} onClick={() => { updateStoreTenantId(params.row._id); }} to={`/tenantDetails/${params.row._id}`}>{params.row.name}</LinkName></>) } },
        { field: 'customerType', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Type</strong>) } },
        { field: 'houseNo', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >House No.</strong>) } },
        { field: 'houseType', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >House Type</strong>) } },
        { field: 'mobileNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Mobile No.</strong>) } },
        { field: 'email', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Email</strong>) } },
        { field: 'createdOn', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Date Created</strong>) }, renderCell: (params) => { return (<span>{moment(params.value).format('MM/DD/yyyy')}</span>) } },
        { field: 'pendingInvoices', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Pending Invoices</strong>) }, renderCell: (params) => { return (<span style={{ color: params.value > 0 ? '#dc3545' : null }}>{params.value}</span>) } },
        { field: 'pendingAmount', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Pending Amount</strong>) }, renderCell: (params) => { return (<span style={{ color: params.value > 0 ? '#dc3545' : '#17ae7b' }}>{numberFormat(params.value)}</span>) } },
        {
            field: 'action', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Action</strong>) }, renderCell: (params) => {
                return (
                    <div>
                        <MoreVertIcon onClick={handleClick} />
                        <StyledMenu anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>

                            {/* <MenuItem disableRipple onClick={() => { updateStoreTenantId(params.row._id) }}>
                                <DeleteForeverIcon style={{ color: "#DC3545FF" }} /> Remove
                            </MenuItem> */}
                            <MenuItem disableRipple onClick={()=>fetchMySelectedTenant(params.row._id)}>
									<DeleteForeverIcon style={{ color: "#DC3545FF" }} /> GiveNotice
							</MenuItem>

                            <MenuItem disableRipple onClick={() => { updateStoreTenantId(params.row._id); handleFlagState('unoccupied') }}>
                                <MoveUpIcon style={{ color: '#17ae7b' }} /> Transfer
                            </MenuItem>
                        </StyledMenu>
                    </div>
                )
            }
        },
    ]

    //Exit request  columns
    const tenantsExitColumns = [
        { field: 'noticeTo', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Names</strong>) }, renderCell: (params) => { return (<><LinkName to={`/tenants/notice/${params.row.noticeNumber}`}><span style={{ marginLeft: "10px" }}>{params.row.noticeTo.name}</span></LinkName></>) } },
        { field: 'house', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >House No</strong>) }, renderCell: (params) => { return (<span>{params.row.house.unitName}</span>) } },
        { field: 'noticeFrom', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Request From</strong>) }, renderCell: (params) => { return (<span>{params.row.noticeFrom.name}</span>) } },
        { field: 'dateForEviction', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Exit Date</strong>) }, renderCell: (params) => { return (<span>{moment(params.value).format('MM/DD/yyyy')}</span>) } },
        {
            field: 'action', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Action</strong>) }, renderCell: (params) => {
                const [anchorEl, setAnchorEl] = React.useState(null);
						const ActionOpen = Boolean(anchorEl);
						const handleClick = (event) => {
							setAnchorEl(event.currentTarget)
						};
						const handleActionClose = () => { setAnchorEl(null); };

						//confirm  exit

						function confirmExitRequest(noticeNumber) {
							try {
								try {
									HttpComponent({
										method: 'POST',
										body: {noticeNumber:noticeNumber},
										url: "/api/action_tenant_vacation?action=VACATED",
										token: X_Authorization
									}).then((data) => {
										console.log(data ,'confirm exit request')
										if(data.status === 200){
											setSuccessShow({state:true , message:"successfully vacated "})
										}else{
											setErrorShow({state:true, message:data.message})
										}
										fetchExitRequest()
									})
				
								} catch (error) {
									console.log(error)
				
								}
				
							} catch (error) {
				
							}
				
						}

						//cancel exit request
						function cancelExitRequest(noticeNumber) {
							try {
								HttpComponent({
									method: 'POST',
									body: {noticeNumber:noticeNumber},
									url: "/api/action_tenant_vacation?action=CANCELLED",
									token: X_Authorization
								}).then((data) => {
									if(data.status === 200){
										setSuccessShow({state:true , message:"successfully cancelled exit requests "})
									}else{
										setErrorShow({state:true ,message:data.message})
									}
									fetchExitRequest()
								})
							} catch (error) {
				
							}
				
						}
                return (
                    <div>
                        <MoreVertIcon onClick={handleClick} />
                        <StyledMenu anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>

                            <MenuItem style={{ color: "#dc3545" }} disableRipple onClick={()=>confirmExitRequest(params.row.noticeNumber)} >
                                Confirm EXit
                            </MenuItem>

                            <MenuItem style={{ color: "#687c8d" }} disableRipple onClick={()=>cancelExitRequest(params.row.noticeNumber)}>
                                Cancel Exit
                            </MenuItem>
                        </StyledMenu>
                    </div>
                )
            }
        },

    ]

    //vacated tenants

    const tenantsVacatedColumns = [
        { field: 'noticeTo', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Names</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params.row.noticeTo.name}</span></>) } },
        { field: 'house', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >House No</strong>) }, renderCell: (params) => { return (<span>{params.row.house.unitName}</span>) } },
        { field: 'noticeFrom', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Request From</strong>) }, renderCell: (params) => { return (<span>{params.row.noticeFrom.name}</span>) } },
        { field: 'dateForEviction', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Exit Date</strong>) }, renderCell: (params) => { return (<span>{moment(params.value).format('MM/DD/yyyy')}</span>) } },
    ]


    return (
        <div>
            <Grid container justifyContent={'space-between'} mt={-3}>
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <Grid item mt={3}>
                    <Grid item>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container justifyContent={'flex-end'} spacing={2} direction={'row'}>
                        <Grid item mb={2}>
                            <Button style={{ textTransform: "none", textDecoration: "none", marginTop: '5px', border: '2px solid #032541', color: '#032541', fontSize: `14px`, fontWeight: 600, height: '45px', width: '125px' }} >Save Notice</Button>
                        </Grid>
                        <Grid item mb={2}>
                            <Button style={{ textTransform: "none", textDecoration: "none", marginTop: '5px', background: '#032541', color: '#fff', fontSize: `14px`, fontWeight: 600, height: '45px', width: '125px' }} >Send Notice</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <TabContext value={tabValue}>
                <AntTabs onChange={handleTabChange} textColor="primary" TabIndicatorProps={{ hidden: true }}>
                    <AntTab label="Active" value="Active" />
                    <AntTab label="Exit Request" value="ExitRequest" />
                    <AntTab label="Vacated" value="Vacated" />
                </AntTabs>

                {/*Active tenants*/}
                <TabPanel value="Active">
                    <Grid container justifyContent={'space-between'} direction={'row'} style={{ marginTop: "-25px", marginBottom: '15px', }}>
                        {/*SearchBox*/}
                        {/* <Grid item>
                            <TextField style={{ fontSize: '10px', fontFamily: 'Poppins', "width": "450px", "height": "45px", "borderRadius": "6px" }}
                                size="small"
                                variant="outlined"
                                placeholder={'Search:'}
                                InputProps={{ startAdornment: (<InputAdornment position="start"><SearchIcon style={{ width: '15px', height: '15px' }} /></InputAdornment>), }}
                            />
                        </Grid> */}

                        {/*Export button*/}
                        {/* <Grid item mb={2}>
                            <Button style={{ textTransform: "none", textDecoration: "none", marginTop: '5px', background: '#f5f6f7', color: '#032541', fontSize: `14px`, fontWeight: 600, height: '45px', width: '125px' }} >Export</Button>
                        </Grid> */}
                    </Grid>
                    {/* <DataGrid
                        components={{ NoRowsOverlay: NoRowsOverlay }}
                        sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                        rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                        rowCount={pageState.total}
                        loading={pageState.isLoading}
                        pagination
                        page={pageState.page - 1}
                        pageSize={dataGridPageSize}
                        paginationMode="server"
                        onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        columns={tenantsColumns}
                        rows={tenantData}
                        getRowId={row => row._id} /> */}
                        <NoticePdfPreview />
                </TabPanel>

                {/*Inactive tenants*/}
                <TabPanel value="ExitRequest">
                    <DataGrid
                        components={{ NoRowsOverlay: NoRowsOverlay }}
                        sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                        rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                        rowCount={pageState.total}
                        loading={pageState.isLoading}
                        pagination
                        page={pageState.page - 1}
                        pageSize={dataGridPageSize}
                        paginationMode="server"
                        onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        columns={tenantsExitColumns}
                        rows={existRequest}
                        getRowId={row => row._id} />

                </TabPanel>

                {/*Deactivated tenants*/}
                <TabPanel value="Vacated">
                    <DataGrid
                        components={{ NoRowsOverlay: NoRowsOverlay }}
                        sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                        rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                        rowCount={pageState.total}
                        loading={pageState.isLoading}
                        pagination
                        page={pageState.page - 1}
                        pageSize={dataGridPageSize}
                        paginationMode="server"
                        onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        columns={tenantsVacatedColumns}
                        rows={vacatedTenants}
                        getRowId={row => row._id} />

                </TabPanel>
            </TabContext>
        </div>
    )

}
export default ExitNotice