import React, { useState, useEffect } from "react";
import CustomDataGrid from "../customDataGrid";
import StockFunctions from "../stockEndpoints";
import DateFormatter from "../../../../utils/dateFormatter";
import { CheckCircle, CancelOutlined } from "@mui/icons-material";
import { Checkbox, Grid, Button } from "@material-ui/core";
import { stockStyles } from "../stockStyling";
import { ApproveStockModal, DeclineStockModal } from "./approvalsModal";
import { SuccessAlertRightAligned } from "../../../bills/startorderModals/modals";
import { useNavigate } from "react-router-dom";

const PendingBatchApprovalStockTake = () => {
    const navigate = useNavigate()
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize, mainstores: [], warehouseLoading: true, mainstorestock: [] });

  const getPendingStockAddStockData = () => {
    const stockfunctions = new StockFunctions();
    stockfunctions.getPendingStockStockTake(pageState.page, dataGridPageSize).then((data) => {
      setPageState({ ...pageState, data: data?.response?.data, count: data?.response?.count, isLoading: false });
    });
  };

  useEffect(() => {
    getPendingStockAddStockData();
  }, []);

  const [batchIds, sendBatchId] = useState([]);
  const [batchId, setBatchId] = useState("");
  const [declinedId, setDeclineId] = useState("")

  const [isSuccess, setSuccess] = useState({ state: false, message: "" });

  useEffect(() => {
    if (isSuccess.state) {
      setTimeout(() => {
        setSuccess({ state: false });
      }, 2000);
    }
  }, [isSuccess.state]);

  //   addBatchIds;
  const addBatchIds = (stock) => {
    const newBatchIds = [...batchIds];

    // get index
    const currentIndex = newBatchIds.indexOf(stock?.id);

    if (currentIndex === -1) {
      newBatchIds.push(stock?.id);
    } else {
      newBatchIds.splice(currentIndex, 1);
    }
    sendBatchId(newBatchIds);
  };


  
  const pendingBatchColumn = [
    {
      field: "batchNumber",
      headerName: "Batch NO",
      flex: 1,
      renderCell: (params) => {
        return (
          <div sx={{ display: "flex", alignItems: "center", cursor:"pointer" }}>
            <Checkbox sx={{ color: "red" }} onClick={() => addBatchIds(params?.row)} />
            <span style={{color:"red", cursor:"pointer"}} onClick={() => navigate(`/viewmoreStock/pending/${params?.row?.batchId}`)}>{params?.row?.batchNumber}</span>
          </div>
        );
      },
    },
    { field: "dateCreated", headerName: "Date Created", flex: 1 },
    {
      field: "Action",
      headerName: "Batch NO",
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <CheckCircle
              onClick={() => {
                setApproveStock(true);
                setBatchId(params?.row?.id);
              }}
              sx={{ color: "green", cursor: "pointer" }}
            />
            <CancelOutlined onClick={() => {
                setDeclineId(params?.row?.id)
                setOpenDeclineModal(true)
            }} sx={{ color: "red", cursor: "pointer" }} />
          </div>
        );
      },
    },
  ];

  const pendingBatchRows = pageState?.data?.map((stock) => ({
    id: stock?._id,
    batchNumber: stock?.batchNumber,
    dateCreated: DateFormatter(stock?.dateCreated),
    batchId:stock?.batchId
  }));

  console.log(pendingBatchRows);
  const [approveStockModal, setApproveStock] = useState(false);

  const approveStock = () => {
    const stockfunctions = new StockFunctions();
    const sendIds = batchIds.length > 0 ? batchIds : [batchId];
    const listBatchIds = { listBatchIds: sendIds };
    stockfunctions.approveAddStockBatch(listBatchIds).then((data) => {
      if (data.status === 200) {
        setSuccess({ state: true , message:"Batch approved succesfully!"});
        setApproveStock(false);
        sendBatchId([])
        setBatchId("")
      }
      setTimeout(() => {
        setSuccess({ state: false });
        getPendingStockAddStockData();
      }, 2000);
    });
  };


  const [openDeclineModal, setOpenDeclineModal] = useState(false);


  const declineStockRequest = () => {

    const stockfunctions = new StockFunctions();
    const sendIds = declinedId 
    const listBatchIds = { stockId : sendIds };
    stockfunctions.declineAddstockBatch(listBatchIds).then((data) => {
      if (data.status === 201) {
        setSuccess({ state: true , message:"Batch request declined successfully!" });
        setOpenDeclineModal(false);
        setDeclineId("")
      }
      setTimeout(() => {
        setSuccess({ state: false });
        getPendingStockAddStockData();
      }, 2000);
    });
  }


  const batchAdded = <SuccessAlertRightAligned sucess={isSuccess.state} message={isSuccess.message} />;


  return (
    <div style={{ width: "100%" }}>
      {isSuccess.state && batchAdded}
      <Grid container width={"100%"} margin={"0% 0% 1% 0%"}>
        <CustomDataGrid column={pendingBatchColumn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={pendingBatchRows} />
      </Grid>

      {batchIds && batchIds.length > 0 && (
        <Grid container direction="row" alignItems="center" justifyContent="flex-end" spacing={2} width={"100%"}>
          <Grid item>
            {" "}
            {/* <Button style={stockStyles?.cancelBatchButton}>Decline</Button> */}
          </Grid>
          <Grid item>
            {" "}
            <Button style={stockStyles?.approveButton} onClick={() => setApproveStock(true)}>
              Approve
            </Button>
          </Grid>
        </Grid>
      )}

      <ApproveStockModal approveStockModal={approveStockModal} setApproveStock={setApproveStock} approveStock={approveStock} />
      <DeclineStockModal openDeclineModal={openDeclineModal} setOpenDeclineModal={setOpenDeclineModal}  declineStockRequest={declineStockRequest} />
    </div>
  );
};

export default PendingBatchApprovalStockTake;
