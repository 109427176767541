import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Grid, Input, MenuItem, Select, Snackbar, Typography } from "@mui/material";
import backIcon from "./Images/back-icn.svg"
import HttpComponent from "./MakeRequest";
import { useSelector, useDispatch, } from "react-redux";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { ArrowBack, CheckBox } from "@mui/icons-material";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, OutlinedInput } from '@material-ui/core';
import { handleIsCardSetup, setCardPaymentData, setCardStatus } from '../../features/paymentOptionsSlice';


const baseUrl = process.env.REACT_APP_BASE_URL;

const EditCard = ({setSuccessShow,setErrorShow}) => {
    const { cardPaymentData } = useSelector((store) => store.payment);
    const [merchantId, setMerchantId] = useState('')
    const [merchantKey, setMerchantKey] = useState('')
    const [merchantSecret, setMerchantSecret] = useState('')
    const [openSnack, setOpenSnack] = useState(false);
    const [message, setmessage] = useState('')
    const [ifError, setError] = useState(false)
    const dispatch = useDispatch();

    const [formState, setFormState] = useState(false)
    const [showPassword, setShowPassword] = React.useState(false);
    const newCardDetails = { merchantId, merchantKey, merchantSecret};

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [showPassword1, setShowPassword1] = React.useState(false);

    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

    const handleMouseDownPassword1 = (event) => {
        event.preventDefault();
    };

    const { isCardSetup } = useSelector((store) => store.payment);
    const { userId ,X_Authorization } = useSelector((store) => store.user);

    const token = localStorage.getItem('X-Authorization');


    // get configured card details
    const getCardConfigDetails = () =>{
        HttpComponent({
            method: 'GET',
            url: `/api/v1/business_config/card/get-merchant-config`,
            body: null,
            token: X_Authorization
        }).then((data)=>{
            console.log("here is card details data",data);
            if(data.status === 201){
                setSuccessShow({
                    state:true,
                    message:"Card Configurations Fetched Successfully"
                })
                console.log('card payment config  data', data.response.data);
                dispatch(setCardPaymentData(data.response.data));
                
            }else{
                console.error("Error setting info")
                setErrorShow({state:true, message:data.response.message})
            }
        }).catch((error)=>{
            console.error(error.message);
            setErrorShow({state:true, message:error.message})
        })
     }

    //  UPDATE CARD DETAILS
    const updateCardDetails = async (id) => {
        const businessName = localStorage.getItem('businessName')
        console.log('businessName',merchantId,merchantKey, merchantSecret)
        if (merchantId === ' ') {
            // alert('Kindly select the bankname')
            setOpenSnack(true)
            setError(true)
            setmessage('merchantId is required')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);
        } else if (merchantKey === '') {
            setOpenSnack(true)
            setError(true)
            setmessage('merchantKey is required')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);

        } else if (merchantSecret === '') {
            setOpenSnack(true)
            setError(true)

            setmessage('merchantSecret is required')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);
        } else {

            try {
                const response = await fetch(`${baseUrl}/api/v1/business/card-merchant-config`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                    body: JSON.stringify({
                        merchantId: merchantId,
                        merchantKey: merchantKey,
                        merchantSecretKey: merchantSecret,
                    }),
                })
                await response.json().then((data) => {
                    console.log('card update response data here', data)
                    setOpenSnack(true)
                    setError(false)
                    setmessage('Successfully added card details')
                    dispatch(setCardStatus(true));
                    dispatch(handleIsCardSetup(true));
                    setTimeout(() => {
                        setOpenSnack(false);
                        window.location.reload();
                    }, 2000);
                })
            } catch (error) {

            }
        }
    }

    const buttonStyles =
        {"width":"7.813rem",
            "height":"2.813rem",
            "borderRadius":"4px",
        }


    const changeFormState = () => {
        if (formState) {
            setFormState(false)

        } if (!formState) {
            setFormState(true)
        }

    }

    useEffect((token) => {
        getCardConfigDetails(token);
    },[token])

    // getting existing card payment data
    useEffect(() => {
      if (cardPaymentData) {
        cardPaymentData?.map((cardData) =>{
          setMerchantId(cardData.merchantId);
          setMerchantKey(cardData.merchantKey);
          setMerchantSecret(cardData.merchantSecretKey);
        })
      }
    }, [cardPaymentData]);

    console.log(cardPaymentData)
  
  return (
    <div>
      <form>
                       <FormControl style={{ width:'100%' }}>
                       <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Merchant ID</InputLabel>
                       <TextField style={{ width: "95%", marginTop: "30px"}}
                            id="outlined-multiline-flexible"
                            value={merchantId}    
                            onChange={(e) => setMerchantId(e.target.value)}
                            multiline
                            required
                        />
                       </FormControl>
                        <FormControl style={{ width: "100%", marginTop: '-15px'}}>
                            <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Merchant Key</InputLabel>
                            <OutlinedInput
                                style={{ width: "95%", marginTop: "45px", marginLeft:'20px'}}
                                placeholder='Key'
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                value={merchantKey}
                                onChange={(e) => setMerchantKey(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <FormControl style={{ width: "100%"}}>
                        <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Merchant Secrete Key</InputLabel>
                            <OutlinedInput
                                style={{ width: "95%", marginTop: "45px",  marginLeft:"20px" }}
                                id="outlined-adornment-password"
                                placeholder='Secret Key'
                                type={showPassword1 ? 'text' : 'password'}
                                value={merchantSecret}
                                onChange={(e) => setMerchantSecret(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword1}
                                            onMouseDown={handleMouseDownPassword1}
                                            edge="end"
                                        >
                                            {showPassword1 ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>

                        <div className="foooter d-flex" style={{ display: "flex", justifyContent: "end", paddingRight: '20px', paddingBottom: '20px'}}>
                            <Box component="div" sx={{width:"95%", display:"flex", justifyContent:"end", marginTop: '30px'}}>
                                <Box>
                                <Button sx={{
                               ...buttonStyles,
                                marginLeft:1,
                                backgroundColor:" #032541",
                                color:"white",
                                '&:hover': {
                                    backgroundColor: '#032541',
                                    color: 'white'
                                }
                                }}
                                onClick={updateCardDetails}
                                >
                                Update
                                </Button>
                                </Box>
                            </Box>
                        </div>
                        </form>
    </div>
  )
}

export default EditCard