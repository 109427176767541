import React, {useState, useEffect, useRef} from "react";
import {Modal, Grid, Box, Button, Select} from "@mui/material";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import {useNavigate, useParams} from 'react-router-dom';
import {SelectStudentsModal} from "./studentParent";
const baseUrl = process.env.REACT_APP_BASE_URL;

const CreateSchoolInvoiceModal = (props) => {

    const [invoiceOpen, setInvoiceOpen] = useState(props.modalOpen);
    const [invoiceType, setInvoiceType] = useState("Invoice Type");
    const [repeatType, setRepeatType] = useState("Select Schedule")
    const [selectedCustomerId, setSelectedCustomerId] = useState("Select Parent and Students");
    const [customerData, setCustomerData] = useState([])
    const [selectedStudents, setSelectedStudents] = useState([])
    const [selectStudentOpen, setSelectStudentOpen] = useState(false);
    const [selectedCustomer, setSetlectedCustomer] = useState()
    const [parentName, setParentName] = useState()
    const [checkboxData, setCheckboxData] = useState([]);
    // const []
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate();
    const { customerId } = useParams();

    const fetchCustomers = async () => {
        const productResponse = await fetch(
            baseUrl + "/api/customers_list?status=Active",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            }
        );

        if (productResponse.status === 200) {
            const theCustomerData = await productResponse.json();
            console.log("customer data", theCustomerData.data)
            if (theCustomerData && theCustomerData.data) {
                setCustomerData(theCustomerData.data)
                // const selectedCustomer = theCustomerData.data.find(
                //     (customer) => customer._id === customerId
                // );
                // if (selectedCustomer) {
                //     setSelectedCustomerId(selectedCustomer._id);
                // }
            }
        }
    }

    const handleClick = () =>{
        let invoiceObject = {};
        invoiceObject.customerId = customerId;
        if(invoiceType === "one-off"){
            invoiceObject.invoiceType = invoiceType;
            invoiceObject.invoiceFrequency = "once";
            saveAndRoute(invoiceObject);
        }else if(invoiceType === "recurring"){
            if(repeatType !== 'Select Schedule'){
                invoiceObject.invoiceType = invoiceType;
                invoiceObject.invoiceFrequency = repeatType;
                saveAndRoute(invoiceObject);
            }else{
                console.error("Select a repeat schedule to proceed")
            }
        }else{
            console.error("Select an Invoice Type to proceed")
        }
    }

    const saveAndRoute = (object) => {
        object.customerName = parentName
        object.customerEmail = selectedCustomer.email
        object.customerPhone = selectedCustomer.mobileNumber
        object.numberOfStudents = selectedStudents.length;
        object.total = getTotal(selectedStudents);
        object.billableItemId = selectedStudents[0]._id
        object.itemNumber = selectedStudents[0].itemNumber
        object.products = formatProducts(selectedStudents[0])
        localStorage.setItem("invoice_data", JSON.stringify(object));
        console.log("head to preview invoice", object)
        navigate("/sc_preview_invoice");
        props.onClose()
    }

    const formatProducts = (object) => {
        let productArray = [];
        object.servicesForThisStudent.forEach(service => {
            productArray.push({
                productId: service.productId,
                productName: service.name,
                quantity: 1,
                sellingPrice: service.amount,
                total: service.amount * 1
            });
        });
        return productArray;
    };

    const getTotal = (array) => {
        return array.reduce((sum, student) => {
            return sum + student.servicesForThisStudent.reduce((sum, service) => sum + service.amount, 0);
        }, 0);
    }
    const handleSelectChange = (event) =>{
        setSelectedCustomerId(event.target.value)
        const theSelectedCustomer = customerData.find(customer => customer._id === event.target.value);
        setSetlectedCustomer(theSelectedCustomer)
        setParentName(theSelectedCustomer?.customerName || "")
        setSelectStudentOpen(true)
    }

    useEffect(()=>{
        console.log("this is the selected customer", selectedCustomer)
    },[selectedCustomer])

    const wholeModal = {
        width: '500px',
        height: '477px',
        backgroundColor: '#fff',
        borderColor:"transparent",
        borderRadius: "7px",
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };
    const headerSection = {
        backgroundColor: 'red',
        width: '100%',
        borderTopLeftRadius: "7px",
        borderTopRightRadius: "7px",
        height: '109px',
        flexDirection: "row",
        alignContent: "center",
        justifyItems: "center",
        textAlign: "center",
    }

    const secondaryInvoice = {
        border: "solid 1px #dc3545",
        backgroundColor:"transparent",
        color:"#dc3545",
        width: "75px",
        height: "45px",
        margin:"5px"
    }

    const primaryInvoice = {
        backgroundColor:"#032541",
        color:"white",
        width: "225px",
        height: "45px",
        margin:"5px"
    }

    useEffect(() => {
        setInvoiceOpen(props.modalOpen);
    }, [props.modalOpen]);

    useEffect(()=>{
        fetchCustomers()
    },[])

    return (
        <div>
            <Modal
                open={invoiceOpen}
                onClose={props.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={wholeModal}>
                    <div style={headerSection}>
                        <div
                            style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                color: "white",
                                fontWeight: "bold",
                                fontSize: 25,
                                paddingTop:"40px"
                            }}
                        >
                            Create Invoice
                        </div>
                    </div>
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <div style={{
                            marginTop:"20px"
                        }}>
                            <InputLabel id="customer-select-label">Parent/Guardian</InputLabel>
                            <Select
                                labelId="customer-select-label"
                                id="customer-select"
                                value={selectedCustomerId}
                                onChange={(event) => handleSelectChange(event)}
                                style={{
                                    width:"415px"
                                }}
                            >
                                <MenuItem value={"Select Parent and Students"}>
                                    Select Parent and Students
                                </MenuItem>
                                {customerData?.map((customer) => (
                                    <MenuItem key={customer._id} value={customer._id}>
                                        {customer.customerName}
                                    </MenuItem>
                                ))}
                            </Select>
                            <InputLabel id="invoice-type-label">Invoice Type<span style={{color:"#dc3545"}}>*</span></InputLabel>
                            <Select
                                labelId="invoice-type-label"
                                id="invoice-type-select"
                                value={invoiceType}
                                onChange={e => {
                                    if (e.target.value !== "Invoice Type") {
                                        setInvoiceType(e.target.value);
                                    }}}
                                style={{
                                    width:"415px"
                                }}
                            >
                                <MenuItem value="Invoice Type" disabled>Invoice Type</MenuItem>
                                <MenuItem value="one-off">One-Off</MenuItem>
                                <MenuItem value="recurring">Recurring</MenuItem>
                            </Select>
                            {invoiceType == 'recurring' &&(
                                <>
                                    <InputLabel id="invoice-type-label">Repeat<span style={{color:"#dc3545"}}>*</span></InputLabel>
                                    <Select
                                        labelId="invoice-type-label"
                                        id="invoice-type-select"
                                        value={repeatType}
                                        onChange={e => {
                                            if (e.target.value !== "Select Schedule") {
                                                setRepeatType(e.target.value);
                                            }}}
                                        style={{
                                            width:"415px"
                                        }}
                                    >
                                        <MenuItem value="Select Schedule" disabled>Select Schedule</MenuItem>
                                        <MenuItem value="daily">Daily</MenuItem>
                                        <MenuItem value="weekly">Weekly</MenuItem>
                                        <MenuItem value="monthly">Monthly</MenuItem>
                                        <MenuItem value="quarterly">Quarterly</MenuItem>
                                        <MenuItem value="yearly">Yearly</MenuItem>

                                    </Select>
                                </>
                            )}
                            <div style={{
                                display:"flex",
                                marginTop:"20%",
                                justifyContent:"center"
                            }}>
                                <Button style={secondaryInvoice} onClick={props.onClose}>Close</Button>
                                <Button style={primaryInvoice} onClick={()=>handleClick()}>Preview Invoice</Button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
            <>
                <SelectStudentsModal
                    modalOpen={selectStudentOpen}
                    onClose={() => setSelectStudentOpen(false)}
                    parentName={parentName}
                    selectedStudents={selectedStudents}
                    setSelectedStudents={setSelectedStudents}
                />
            </>
        </div>
    );
};
export { CreateSchoolInvoiceModal };
