import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GradesAndStreamsIcon from '../../components/School/Images/grade-streams-icn.svg';
let baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(2.7),
    },
}));

export default function GradesAndStreams() {
    const classes = useStyles();
    const { userId, X_Authorization } = useSelector((store) => store.user);
    const { schoolTypeDetail } = useSelector((store) => store?.schoolType)
    
    useEffect(() => {
        const { schoolTypeName, schoolTypeId } = schoolTypeDetail
        setSchoolTypeName(schoolTypeName)
        setSchoolTypeId(schoolTypeId)
    },[schoolTypeDetail])
    
    const [schoolTypeName, setSchoolTypeName] = useState('')
    const [schoolTypeId, setSchoolTypeId] = useState('')
   
    console.log(schoolTypeDetail , "sschoolTypeDetail");
    const userGroup = localStorage.getItem('group')
    const businessCat = localStorage.getItem('businessCategory')
    // console.log(schoolTypeName,schoolTypeId , "schoolTypeName redux")
    // const [schoolTypeName, setSchoolTypeName] = useState('')
    // const GetSchoolTypeIdForBusiness = async () => {
    //     const response = await fetch(`${baseUrl}/api/get_school_type_by_businesss`, {
    //       headers: {
    //         "Content-Type": "application/json",
    //         "X-Authorization": X_Authorization,
    //       }
    //     });
    //     const data = await response.json();
    //     // console.log(data, 'School Type Id');
    //     if (response.status === 201) {
    //       setSchoolTypeName(data.data?.schoolType)
    
    
    //     }
    //   }
    //   useEffect(() => {
    //     GetSchoolTypeIdForBusiness();
    //   }, []);
    

    //   console.log('schoolTypeName  schoolTypeName schoolTypeName', schoolTypeName)
    
    return (
        <div>

            {/*Users*/}
            {businessCat === 'School' ?
                <>

                    <div>
                        <ListItem button className={classes.nested}>
                            <div style={{ marginRight: '5%' }}>
                                <img src={GradesAndStreamsIcon} />
                            </div>
                            <Link style={{ textDecoration: 'none', color: '#fff' }} to="/school/grades">
                                <ListItemText primary={schoolTypeName?.includes("University") ? "Courses & Programs" : "Grades & Streams"} />
                            </Link>
                        </ListItem>
                    </div>
                </> : null}
        </div>
    )
}
