import React, { useEffect, useRef, useState, useLayoutEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Dash from "../../common/dash";
import Form from "react-bootstrap/Form";
import { Grid, TableFooter } from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment-timezone";
import Button from "@mui/material/Button";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "./Calender/DateRangePicker.css";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import { SessionModal } from "../Modals/SessionExpiredModal/sessionModal";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import axios from "axios";
import { handleTransactionDate } from '../../features/transactionDateSlice';

// session Expired
const style = {
	borderRadius: "36px",
	boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
	backgroundColor: "#fff",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "660px",
	height: "570px",
	p: 4,
};

const baseUrl = process.env.REACT_APP_BASE_URL;

const columns = [
	{
		field: "businessNo",
		headerName: "Business No",
		flex: 1,
		headerAlign: "left",
		align: "left",
		renderHeader: () => (
			<strong
				style={{
					fontFamily: "Poppins",
					fontSize: "10px",
					fontWeight: "600",
					color: "#032541",
				}}
			>
				{"Business No"}
			</strong>
		),
	},
	{
		field: "businessName",
		headerName: "Business Name",
		flex: 1,
		align: "left",
		headerAlign: "left",
		renderHeader: () => (
			<strong
				style={{
					fontFamily: "Poppins",
					fontSize: "10px",
					fontWeight: "600",
					color: "#032541",
				}}
			>
				{"Business Name"}
			</strong>
		),
	},
	{
		field: "transactionID",
		headerName: "TXID",
		flex: 1,
		filter: "text",
		align: "left",
		headerAlign: "left",
		renderHeader: () => (
			<strong
				style={{
					fontFamily: "Poppins",
					fontSize: "10px",
					fontWeight: "600",
					color: "#032541",
				}}
			>
				{"TXID"}
			</strong>
		),
	},
	{
		field: "transactionType",
		headerName: "Type",
		flex: 1,
		filter: "text",
		align: "left",
		headerAlign: "left",
		renderHeader: () => (
			<strong
				style={{
					fontFamily: "Poppins",
					fontSize: "10px",
					fontWeight: "600",
					color: "#032541",
				}}
			>
				{"Type"}
			</strong>
		),
	},
	{
		field: "transtime",
		headerName: "Time",
		flex: 1,
		renderHeader: () => (
			<strong
				style={{
					fontFamily: "Poppins",
					fontSize: "10px",
					fontWeight: "600",
					color: "#032541",
				}}
			>
				{"Time"}
			</strong>
		),
		width: 200,
		valueGetter: (params) => {
			const transTime = params.row.transtime;
			return moment(transTime).tz("Africa/Nairobi").format("llll");
		},
	},
	{
		field: "uploadTime",
		headerName: "Upload Time",
		renderHeader: () => (
			<strong
				style={{
					fontFamily: "Poppins",
					fontSize: "10px",
					fontWeight: "600",
					color: "#032541",
				}}
			>
				{"Upload Time"}
			</strong>
		),
		flex: 1,
		width: 200,
		headerAlign: "left",
		align: "left",
		valueGetter: (params) => {
			return moment(params.row.uploadTime).format("llll");
		},
	},
	{
		field: "customerFirstName",
		headerName: "Name",
		headerAlign: "left",
		renderHeader: () => (
			<strong
				style={{
					fontFamily: "Poppins",
					fontSize: "10px",
					fontWeight: "600",
					color: "#032541",
				}}
			>
				{"Name"}
			</strong>
		),
		align: "left",
		flex: 1,
	},
	{
		field: "customerPhone",
		headerName: "Phone",
		flex: 1,
		width: 200,
		align: "left",
		headerAlign: "left",
		renderHeader: () => (
			<strong
				style={{
					fontFamily: "Poppins",
					fontSize: "10px",
					fontWeight: "600",
					color: "#032541",
				}}
			>
				{"Phone"}
			</strong>
		),
		valueGetter: (params) => {
			const phone = params.row.customerPhone || "";
			return phone.replace(/(\d{5})\d{3}(\d{4})/, "$1***$2");
		},
	},
	{
		field: "transamount",
		flex: 1,
		headerName: "Amount",
		type: "number",
		width: 200,
		align: "left",
		headerAlign: "left",
		renderHeader: () => (
			<strong
				style={{
					fontFamily: "Poppins",
					fontSize: "10px",
					fontWeight: "600",
					color: "#032541",
				}}
			>
				{"Amount"}
			</strong>
		),
		valueGetter: (params) => {
			const amount = params.row.transamount || "";
			return Intl.NumberFormat("en-US", {
				style: "currency",
				currency: "KES",
			}).format(amount);
		},
	},
	{
		field: "serialNo",
		headerName: "Serial",
		type: "string",
		flex: 1,
		align: "left",
		headerAlign: "left",
		renderHeader: () => (
			<strong
				style={{
					fontFamily: "Poppins",
					fontSize: "10px",
					fontWeight: "600",
					color: "#032541",
				}}
			>
				{"Serial"}
			</strong>
		),
	},
];

export default function Transaction() {
	// page size setter and getter
	const [pageSize, setPageSize] = React.useState(5);
	const {	date: dateState } = useSelector((store) => store.transactionDate);
	const dispatch = useDispatch();

	//add days to current date
	function addDays(date, days) {
		const result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	}

	// session Modal Config
	const [sessionOpen, setSessionOpen] = React.useState(false);
	const handleSessionOpen = () => setSessionOpen(true);
	const handleSessionClose = () => setSessionOpen(false);
	const handleLogin = () => {
		window.location.href = "/logout";
	};

	//date range Filter setters and Getters
	const [dValue, onDValueChange] = useState([
		moment(new Date()).format("YYYY-MM-DD"),
		moment(addDays(new Date(), 1)).format("YYYY-MM-DD"),
	]);

	// Transactions Setters and Getters
	const [transactions, setTransactions] = useState([]);

	//const dp open state
	const [isOpen, setIsOpen] = useState(false);

	//chart dropDown
	const [dropDown, setDropDown] = useState("7days");

	// BusinessFilter DropDown
	const [businessName, setBusinessName] = useState('');

	const [businessNames, setBusinessNames] = useState([])

	// Transaction typesetters and Getters
	const [transactionType, setTransactionType] = useState([]);

	// payment Type setter and getter
	const [paymentType, setPaymentType] = useState("All");

	// Filter data grid by transaction type using select input
	const [filteredData, setFilteredData] = useState([]);

	//const
	const [searchValue, setSearchValue] = useState("");

	// console.log('This is Business FIlter', businessName)

	//fetch transactions
			const fetchTransactions = async () => {
        try {
					let res;
					if(dateState){
						const dataObj = {
							dateFrom : dateState,
							dateTo: dateState,
						};
						const config = {
							headers: {
								"Content-Type": "application/json",
								Accept: "application/json",
								"X-Authorization": localStorage.getItem("X-Authorization"),
							},
						};
						res = await axios.post(baseUrl + `/fetchTransactions/dateRange`, dataObj, config);
					}
					if(!dateState){
						res = await axios.get(baseUrl + `/viewAllTransactions`, {
							headers: {
								"Content-Type": "application/json",
								Accept: "application/json",
								"X-Authorization": localStorage.getItem("X-Authorization"),
							},
						});
					}
					if (res.status === 200) {
						setTransactions(res.data.data);
						dispatch(handleTransactionDate(null));
					} else if (res.status === 401) {
						handleSessionOpen();
					}
        } catch (e) {
          console.log(e);
        }
      };
	useEffect(() => {
		fetchTransactions();
	}, []);

	// Currency Formatter
	const numberFormat = (value) =>
		new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "KES",
		}).format(value);

	const rowData = transactions.map((transaction) => {
		return {
			id: transaction._id,
			businessNo: transaction.businessNo,
			businessName: transaction.businessName,
			businessShortCode: transaction.businessShortCode,
			transactionID: transaction.transactionID,
			transactionType: transaction.transactionType,
			transtime: transaction.transtime,
			uploadTime: transaction.uploadTime,
			customerFirstName: transaction.customerFirstName,
			customerMiddleName: transaction.customerMiddleName,
			customerPhone: transaction.customerPhone,
			transamount: transaction.transamount,
			serialNo: transaction.serialNo,
			cashier: transaction.cashier,
			paymentChanel: transaction.paymentChanel,
		};
	});

	// Fetch Payment Types from DB
	const fetchTransactionTypes = async () => {
		try {
			const response = await fetch(baseUrl + "/api/transactionType", {
				method: "GET",
				headers: {
					Accept: "application/json",
					"X-Authorization": localStorage.getItem("X-Authorization"),
				},
			});
			const data = await response.json();
			if (response.status === 200 && data.Status === "SUCCESS") {
				setTransactionType(data.data);
				console.log(transactionType, "done");
			} else if (response.status === 401) {
				handleSessionOpen();
			}
		} catch (e) {
			console.log(e);
		}
	};

	const fetchBusinessNames = async () => {
		try {
			const response = await fetch(baseUrl + `/api/get_my_businesses?page=1&limit=100&status=Active`, {
				method : "GET",
				headers : {
					Accept: "application/json",
					"X-Authorization": localStorage.getItem("X-Authorization"),
				},
			})
			const data = await response.json();
			console.log('BusinessName Data', data.data)
			if (data.Status === "SUCCESS") {
				setBusinessNames(data.data);
			} else if (response.status === 401) {
				handleSessionOpen();
			}
		} catch (e) {console.log(e.message)}
	}
	useEffect(() => {
		fetchBusinessNames()

	}, [])

	useEffect(() => {
		const doFetchTransactionTypes = async () => {
			await fetchTransactionTypes();
		};
		doFetchTransactionTypes().then(() => console.log(transactionType, "check"));
	}, []);

	const paymnentTypes = transactionType.map((type) => {
		return type._id.transactionType;
	});

	const handleChange = (event) => {
		setPaymentType(event.target.value);
	};

	const handleSearchBox = (event) => {
		setSearchValue(event.target.value);
	};

	const handleBusinessChange = (event) => {
		setBusinessName(event.target.value)
	}

	// filters
	// date range filter
	const filterByDateRange = (data) => {
		return data.filter((item) =>
			dropDown === "range"
				? item.transtime >=
				moment(new Date(dValue[0]).toDateString()).format("YYYY-MM-DD") &&
				item.transtime <=
				moment(new Date(dValue[1]).toDateString()).format("YYYY-MM-DD")
				: item.transtime >= dValue[0] && item.transtime <= dValue[1]
		);
	};
	// Filter by Payment Type
	const filterByPaymentType = (data) => {
		return data.filter((item) => {
			if (paymentType === "All") {
				return item;
			} else if (item.transactionType === paymentType.includes(paymnentTypes)) {
				return item;
			} else if (item.transactionType === paymentType) {
				return item;
			}
		});
	};

	// Filter by business
	const filterByBusiness = (data) => {
		return data.filter((item) => {
			if (businessName === '') {
				return item;
			} else if (item.businessName === businessName.includes(businessName)) {
				// console.log('This is item', item.businessName)
				return item;
			} else if (item.businessName === businessName) {
				// console.log('This is item', item.businessName)
				return item;
			}
		});
	};

	// Filter by SearchBox
	const filterBySearchBox = (data) => {
		return data.filter((item) => {
			if (
				item.businessNo.toLowerCase().includes(searchValue.toLowerCase()) ||
				item.businessName.toLowerCase().includes(searchValue.toLowerCase()) ||
				item.transactionID.toLowerCase().includes(searchValue.toLowerCase()) ||
				item.serialNo.toLowerCase().includes(searchValue.toLowerCase())
			) {
				return item;
			}
		});
	};


	useEffect(() => {
		let filteredData = rowData;

		filteredData = filterByDateRange(filteredData);
		filteredData = filterByPaymentType(filteredData);
		filteredData = filterBySearchBox(filteredData);
		filteredData = filterByBusiness(filteredData);
		setFilteredData(filteredData);
	}, [transactionType]);

	useEffect(() => {

		let filteredData = rowData;
		filteredData = filterByDateRange(filteredData);
		filteredData = filterByPaymentType(filteredData);
		filteredData = filterBySearchBox(filteredData);
		filteredData = filterByBusiness(filteredData);
		setFilteredData(filteredData);
	}, [dValue, paymentType, searchValue, businessName]);


	//use Effect
	useEffect(() => {
		if (dropDown === "Today") {
			onDValueChange([
				moment(new Date()).format("YYYY-MM-DD"),
				moment(addDays(new Date(), 1)).format("YYYY-MM-DD"),
			]);
		} else if (dropDown === "YesterDay") {
			onDValueChange([
				moment(addDays(new Date(), -1)).format("YYYY-MM-DD"),
				moment(addDays(new Date(), 0)).format("YYYY-MM-DD"),
			]);
		} else if (dropDown === "7days") {
			onDValueChange([
				moment(addDays(new Date(Date.now()), -7)).format("YYYY-MM-DD"),
				moment(addDays(new Date(Date.now()), +1)).format("YYYY-MM-DD"),
			]);
		} else if (dropDown === "this Month") {
			onDValueChange([
				moment(
					new Date(new Date().getFullYear(), new Date().getMonth())
				).format("YYYY-MM-DD"),
				moment(addDays(new Date(Date.now()), +1)).format("YYYY-MM-DD"),
			]);
		} else if (dropDown === "last Month") {
			onDValueChange([
				moment(
					new Date(new Date().getFullYear(), new Date().getMonth() - 1)
				).format("YYYY-MM-DD"),
				moment(
					new Date(new Date().getFullYear(), new Date().getMonth())
				).format("YYYY-MM-DD"),
			]);
		} else if (dropDown === "range") {
			setIsOpen(true);
			handleRange();
			onDValueChange([
				moment(
					new Date(new Date().getFullYear(), new Date().getMonth() - 1)
				).format("YYYY-MM-DD"),
				moment(
					new Date(new Date().getFullYear(), new Date().getMonth())
				).format("YYYY-MM-DD"),
			]);
		}
	}, [dValue, dropDown]);

	// handle delay
	function delayChange() {
		setDropDown("Today");
	}

	// handle drop down change
	const dropChange = (event) => {
		setDropDown(event.target.value);
	};

	// handle businessFilterDropDown
	const businessDropChange = (event) => {
		setBusinessDropDown(event.target.value);
	};

	// handle custom range
	const handleRange = () => {
		const x = document.getElementById("rangeDiv");
		if (x.style.display === "none") {
			x.style.display = "flex";

			// work on open calendar
		} else if (x.style.display === "flex") {
			x.style.display = "none";
			setIsOpen(false);
			setTimeout(delayChange, 5000000);

		} else {
			x.style.display = "none";
		}
	};

	// CSV Export Format
	const csvColumns = [
		{ label: "Receipt No.", key: "transactionID" },
		{ label: "Transaction Type", key: "transactionType" },
		{ label: "Business Name", key: "businessName" },
		{ label: "Payment Mode", key: "paymentChanel" },
		{ label: "Terminal User", key: "cashier" },
		{ label: "Date", key: "transtime" },
		{ label: "Amount", key: "transamount" },
	];

	// CSV Export
	const csvReport = {
		data: filteredData,
		headers: csvColumns,
		filename:
			paymentType.toLowerCase() + `_${dValue[0]}-${dValue[1]}_report.csv`,
	};

	// columns selection for Excel Export
	const pdfData = filteredData.map((transaction) => {
		return {
			ReceiptNo: transaction.transactionID,
			transactionType: transaction.transactionType,
			BusinessName: transaction.businessName,
			PaymentMode: transaction.paymentChanel,
			TerminalUser: transaction.cashier,
			Date: transaction.transtime,
			Amount: transaction.transamount,
		};
	});

	//Excel Export Format
	const xlsxReport = (e, data) => {
		console.log(data);
		e.preventDefault();
		const ws = XLSX.utils.json_to_sheet(data);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

		/* generate XLSX file and send to client */
		XLSX.writeFile(
			wb,
			paymentType.toLowerCase() + `_${dValue[0]}-${dValue[1]}_report.xlsx`
		);
	};

	//PDF export Format
	const exportPDF = () => {
		const unit = "pt";
		const size = "A4"; // Use A1, A2, A3 or A4
		const orientation = "landscape"; // portrait or landscape

		const marginLeft = 40;
		const doc = new jsPDF(orientation, unit, size);

		doc.setFontSize(12);

		const title =
			paymentType.toUpperCase() +
			` SALE REPORT FROM ${dValue[0]} TO ${dValue[1]}`;
		const headers = [
			[
				"Receipt No",
				"Business Name",
				"Transaction Type",
				"Payment Mode",
				"Terminal User",
				"Date",
				"Amount",
			],
		];

		const data = pdfData.map((data) => [
			data.ReceiptNo,
			data.BusinessName,
			data.transactionType,
			data.PaymentMode,
			data.TerminalUser,
			moment(data.Date).format("llll"),
			numberFormat(data.Amount),
		]);

		let content = {
			startY: 50,
			head: headers,
			body: data,
		};

		doc.text(title, marginLeft, 40);
		doc.autoTable(content);
		doc.save(
			paymentType.toLowerCase() + `_${dValue[0]}-${dValue[1]}_report.pdf`
		);
	};

	return (
		<div style={{ fontFamily: "Poppins" }}>
			<Box>
				<div>
					<div>
						<div>
							{/*sessionModal*/}
							<SessionModal
								onClose={handleSessionClose}
								sessionOpen={sessionOpen}
							/>

							<Grid>
								{/*header Name*/}
								<Grid>
									<label
										style={{
											width: "100%",
											height: "35px",
											margin: "22px 26px 25px 9px",
											fontFamily: "Poppins",
											fontSize: "25px",
											fontWeight: "600",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "1.32",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#dc3545",
										}}
									>
										All Transactions
									</label>
								</Grid>
							</Grid>

							<Grid container sx={{ margin: "0px 0px 20px 0px" }}>
								<Grid item>
                  <span
	                  style={{
		                  width: "265px",
		                  height: "28px",
		                  margin: "21px 0px 39px 12px",
		                  fontFamily: "Poppins",
		                  fontSize: "20px",
		                  fontWeight: "600",
		                  fontStretch: "normal",
		                  fontStyle: "normal",
		                  lineHeight: "1.65",
		                  letterSpacing: "normal",
		                  textAlign: "left",
		                  color: "#6e7074",
	                  }}
                  >
                    Dashboard
                  </span>
									<span
										style={{
											width: "265px",
											height: "28px",
											margin: "21px 0px 39px 12px",
											fontFamily: "Poppins",
											fontSize: "20px",
											fontWeight: "600",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "1.65",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#6e7074",
										}}
									>
                    \
                  </span>
									<span
										style={{
											width: "265px",
											height: "28px",
											margin: "21px 0px 39px 12px",
											fontFamily: "Poppins",
											fontSize: "20px",
											fontWeight: "600",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "1.65",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#032541",
										}}
									>
                    Transactions
                  </span>
								</Grid>
							</Grid>

							<Grid container>
								<Box sx={{ flexGrow: 1 }}>
									<Grid container spacing={3} columns={8}>
										<Grid item sx={{ margin: "0px 0px 0px 12px" }}>
											<Form.Select
												value={paymentType}
												onChange={handleChange}
												aria-label="Default select example"
												style={{
													borderRadius: "6px",
													fontSize: "12px",
													color: "#272d3b",
													width: "220px",
													height: "36px",
													padding: "0px 0px 0px 17px",
													border: "solid 1px #b4bcc4",
													backgroundColor: "#fff",
												}}
											>
												<option value="All">All</option>
												{transactionType.map((type) => (
													<option
														sx={{ fontFamily: "Poppins", fontSize: "12px" }}
														value={type._id.transactionType}
													>
														{type._id.transactionType}
													</option>
												))}
											</Form.Select>
										</Grid>
										<label
											style={{
												width: "61px",
												height: "15px",
												margin: "35px 0px 12px 20px",
												fontFamily: "Poppins",
												fontSize: "10px",
												fontWeight: "normal",
												fontStretch: "normal",
												fontStyle: "normal",
												lineHeight: "normal",
												letterSpacing: "normal",
												textAlign: "left",
												color: "#032541",
											}}
										>
											Date Range
										</label>

										<Grid item xs={1.2}>
											{/*<Grid item>*/}

											<Form.Select
												size="sm"
												aria-label="Default select example"
												style={{
													borderRadius: "6px",
													fontSize: "12px",
													color: "#272d3b",
													width: "220px",
													height: "36px",
													padding: "0px 0px 0px 17px",
													border: "solid 1px #b4bcc4",
													backgroundColor: "#fff",
												}}
												value={dropDown}
												onChange={dropChange}
											>
												<option value="Today">Today</option>
												<option value="YesterDay">Yesterday</option>
												<option value="7days">Last 7 Days</option>
												<option value="this Month">This Month</option>
												<option value="last Month">Last Month</option>
												<option value="range">Custom range</option>
											</Form.Select>

											<Grid
												value={dropDown}
												onChange={dropChange}
												style={{ display: "none" }}
												id="rangeDiv"
											>
												<DateRangePicker
													maxDate={new Date()}
													isOpen={isOpen}
													onCalendarClose={handleRange}
													value={dValue}
													onChange={onDValueChange}
												/>
											</Grid>

											{/*</Grid>*/}
										</Grid>
										<lable
											style={{
												height: "15px",
												margin: "35px 0px 12px 20px",
												fontFamily: "Poppins",
												fontSize: "10px",
												fontWeight: "normal",
												fontStretch: "normal",
												fontStyle: "normal",
												lineHeight: "normal",
												letterSpacing: "normal",
												textAlign: "left",
												color: "#032541",
											}}
										>
											Business Filter
										</lable>
										<Grid item>
											<Form.Select
												size="sm"
												aria-label="Default select example"
												style={{
													borderRadius: "6px",
													fontSize: "12px",
													color: "#272d3b",
													width: "220px",
													height: "36px",
													padding: "0px 0px 0px 17px",
													border: "solid 1px #b4bcc4",
													backgroundColor: "#fff",
												}}
												value={businessName}
												onChange={handleBusinessChange}>
												<option value=''>All Business</option>
												{
													businessNames.map((business) => (
														<option sx={{ fontFamily: "Poppins", fontSize: "12px" }} value={business.businessName}>
															{business.businessName}
														</option>
													))
												}
											</Form.Select>
										</Grid>
										{/* <Grid
                      item
                      alignContent={"right"}
                      sx={{ margin: "0px 0px 0px 12px" }}
                    >
                      <CSVLink
                        style={{
                          borderRadius: "6px",
                          fontWeight: 600,
                          color: "#fff",
                          width: "50px",
                          height: "37px",
                          margin: "0 12px 0 0",
                          padding: "9px 10px 8px 11px",
                          backgroundColor: "#05d8e1",
                        }}
                        {...csvReport}
                      >
                        {" "}
                        CSV{" "}
                      </CSVLink>
                      <Button
                        onClick={(e) => {
                          xlsxReport(e, pdfData);
                        }}
                        sx={{
                          fontWeight: 600,
                          color: "#fff",
                          width: "50px",
                          height: "37px",
                          margin: "0 12px",
                          padding: "9px 7px 8px",
                          backgroundColor: "#032541",
                        }}
                      >
                        Excel
                      </Button>
                      <Button
                        onClick={() => {
                          exportPDF();
                        }}
                        sx={{
                          fontWeight: 600,
                          color: "#fff",
                          width: "50px",
                          height: "37px",
                          margin: "0 0 0 12px",
                          padding: "9px 12px 8px",
                          backgroundColor: "#dc3545",
                        }}
                      >
                        PDF
                      </Button>
                    </Grid> */}

									</Grid>

									<Grid container columns={1} marginTop='18px'>
										<Grid
											item
											alignContent={"left"}
											sx={{ margin: "0px 0px 0px 12px" }}
										>
											<CSVLink
												style={{
													borderRadius: "6px",
													fontWeight: 600,
													color: "#fff",
													width: "50px",
													height: "37px",
													margin: "0 12px 0 0",
													padding: "9px 10px 8px 11px",
													backgroundColor: "#05d8e1",
												}}
												{...csvReport}
											>
												{" "}
												CSV{" "}
											</CSVLink>
											<Button
												onClick={(e) => {
													xlsxReport(e, pdfData);
												}}
												sx={{
													fontWeight: 600,
													color: "#fff",
													width: "50px",
													height: "37px",
													margin: "0 12px",
													padding: "9px 7px 8px",
													backgroundColor: "#032541",
												}}
											>
												Excel
											</Button>
											<Button
												onClick={() => {
													exportPDF();
												}}
												sx={{
													fontWeight: 600,
													color: "#fff",
													width: "50px",
													height: "37px",
													margin: "0 0 0 12px",
													padding: "9px 12px 8px",
													backgroundColor: "#dc3545",
												}}
											>
												PDF
											</Button>
										</Grid>
									</Grid>

									<Grid container columns={1} sx={{ marginTop: "18px" }}>
										<Grid item xs={0.9} md={0.755}></Grid>
										<Grid item>
											<label
												style={{
													width: "35px",
													height: "12px",
													fontFamily: "Poppins",
													fontSize: "9px",
													fontWeight: "bold",
													fontStretch: "normal",
													fontStyle: "normal",
													lineHeight: "2.78",
													letterSpacing: "normal",
													textAlign: "left",
													color: "#032541",
												}}
											>
												Search:
											</label>
											<input
												value={searchValue}
												onChange={handleSearchBox}
												style={{
													fontSize: "12px",
													color: "#272d3b",
													borderRadius: "6px",
													width: "238px",
													height: "36px",
													margin: "0 0 0 20.4px",
													padding: "7px 107.7px 6.4px 11.1px",
													objectFit: "contain",
													border: "solid 1px #032541",
												}}
												placeholder="search here"
											></input>
										</Grid>
									</Grid>

									{/* <Grid container spacing={2} columns={8}>

                    <Grid
                      item
                      alignContent={"right"}
                      sx={{ margin: "0px 0px 0px 12px" }}
                    >
                      <CSVLink
                        style={{
                          borderRadius: "6px",
                          fontWeight: 600,
                          color: "#fff",
                          width: "50px",
                          height: "37px",
                          margin: "0 12px 0 0",
                          padding: "9px 10px 8px 11px",
                          backgroundColor: "#05d8e1",
                        }}
                        {...csvReport}
                      >
                        {" "}
                        CSV{" "}
                      </CSVLink>
                      <Button
                        onClick={(e) => {
                          xlsxReport(e, pdfData);
                        }}
                        sx={{
                          fontWeight: 600,
                          color: "#fff",
                          width: "50px",
                          height: "37px",
                          margin: "0 12px",
                          padding: "9px 7px 8px",
                          backgroundColor: "#032541",
                        }}
                      >
                        Excel
                      </Button>
                      <Button
                        onClick={() => {
                          exportPDF();
                        }}
                        sx={{
                          fontWeight: 600,
                          color: "#fff",
                          width: "50px",
                          height: "37px",
                          margin: "0 0 0 12px",
                          padding: "9px 12px 8px",
                          backgroundColor: "#dc3545",
                        }}
                      >
                        PDF
                      </Button>
                    </Grid>

                    <Grid item xs={6}></Grid>

                    <Grid
                      alignItems={"right"}
                      item
                      sx={{ margin: "20px 0px 0px 12px" }}
                    >
                      <label
                        style={{
                          width: "35px",
                          height: "12px",
                          fontFamily: "Poppins",
                          fontSize: "9px",
                          fontWeight: "bold",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "2.78",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#032541",
                        }}
                      >
                        Search:
                      </label>
                      <input
                        value={searchValue}
                        onChange={handleSearchBox}
                        style={{
                          fontSize: "12px",
                          color: "#272d3b",
                          borderRadius: "6px",
                          width: "238px",
                          height: "36px",
                          margin: "0 0 0 20.4px",
                          padding: "7px 107.7px 6.4px 11.1px",
                          objectFit: "contain",
                          border: "solid 1px #032541",
                        }}
                        placeholder="search here"
                      ></input>
                    </Grid>
                  </Grid> */}

									{/*datagrid grid*/}
									<Grid
										sx={{
											height: "500px",
											margin: "50px 0 0 0",
											border: "solid 0px transparent",
											backgroundColor: "#fff",
										}}
									>
										<DataGrid
											sx={{
												fontFamily: "Poppins",
												fontSize: "10px",
												color: "#272d3b",
											}}
											rows={filteredData}
											columns={columns}
											pageSize={pageSize}
											onPageSizeChange={(newPageSize) =>
												setPageSize(newPageSize)
											}
											rowsPerPageOptions={[5, 10, 20, 50, 100]}
											pagination
										></DataGrid>
									</Grid>

								</Box>
							</Grid>
						</div>
					</div>
				</div>
			</Box>
		</div>
	);
}
