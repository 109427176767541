import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography, Select, MenuItem } from "@mui/material";
import makeStyles from "@material-ui/core/styles";
import CustomInputLabel from "./CustomInputLabel";
import CustomTextField from "./CustomTextField";
import CustomSelectField from "./CustomSelectField";
import { useDispatch, useSelector } from "react-redux";
import HttpComponent from "./MakeRequest";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { addCategoriesToAdd, removeFromCategoriesToAdd } from "../../features/businessCategorySlice";
import { addService, setCategories } from "../../features/servicesSlice";
import { addStudentsServices } from '../../features/customerSlice'
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { useNavigate } from "react-router-dom";

let baseUrl = process.env.REACT_APP_BASE_URL;


const ServiceForm = (props) => {
  const { servicesToAdd, categories } = useSelector((store) => store.services);
  const { userId, X_Authorization } = useSelector((store) => store.user);
  const [gradeOptions, setGradeOptions] = useState([])
 
  const [streams, setStreames] = useState([]);
  const [termOptions, setTermOptions] = useState([]);
  const [schoolTypeId, setSchoolTypeId] = useState('')
  const [schoolTypeName, setSchoolTypeName] = useState("");
  
  const GetSchoolTypeIdForBusiness = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_type_by_businesss`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      }
    });
    const data = await response.json();
    console.log(data, 'School Type Id');
    if (response.status === 201) {
      setSchoolTypeId(data.data?._id);
      setSchoolTypeName(data.data?.schoolType);

    }
  }
  useEffect(() => {
    GetSchoolTypeIdForBusiness();
  }, [])
  // const getSchoolTypeId = () => {
  //   HttpComponent({
  //     method: 'GET',
  //     url: "/api/get_school_type",
  //     body: null,
  //     token: X_Authorization
  //   }).then((data) => {
  //     console.log("get_school_type_id", data);
  //     if (data.status === 200) {
  //       setSchoolTypeId(data?.response?.data?._id)
  //     } else {
  //       console.error("Error setting info")
  //       setErrorShow({ state: true, message: data.response.message })
  //     }
  //   }
  //   ).catch((error) => {
  //     console.error(error.message);
  //   }
  //   )
  // }
  // useEffect(() => {
  //   getSchoolTypeId()
  // }, []);
  console.log('School Type Id', schoolTypeId);
  const GetGrades = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeId}`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    console.log(data, "Grades");

    if (response.status === 201) {
      setGradeOptions(data.data);
    }
  };
  // const GetStreams = async () => {
  //     const response = await fetch(`${baseUrl}/api/get_school_streams`, {
  //         headers: {
  //             "Content-Type": "application/json",
  //             "X-Authorization": X_Authorization,
  //         },
  //     });
  //     const data = await response.json();
  //     console.log(data, "Streams");
  //     if (response.status === 201) {
  //         setStreames(data.data);
  //     }
  // };
 
  const GetStreamsTerms = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_terms?schoolType_id=${schoolTypeId}`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    console.log(data, "Streams");
    if (response.status === 201) {
      setTermOptions(data.data.map((terms) => {
        return {value: terms.schoolGrades, label: terms.schoolGrades}
    }
    ))
    }
  };
  useEffect(() => {
    if (schoolTypeId) {
      GetGrades();
      GetStreamsTerms();
    }

  }, [schoolTypeId]);



  // const termOptions = [
  //   { value: "Term 1", label: "Term 1" },
  //   { value: "Term 2", label: "Term 2" },
  //   { value: "Term 3", label: "Term 3" },
  // ];

  const cancelButtonStyles = { width: "7.813rem", height: "2.813rem", padding: "12.5px 36.7px 12.5px 38.3px", borderRadius: "4px", border: "solid 1px #dc3545", color: "#dc3545" };

  const buttonStyles = { width: "7.813rem", height: "2.813rem", borderRadius: "4px" };

  const [formData, setFormData] = useState({
    serviceName: "",
    priceType: "Fixed",
    categoryId: "",
    amount: "",
    description: "",
    grade: "",
    term: "",
  });
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });
  const navigate = useNavigate()

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  const dispatch = useDispatch();
  // const { additionServices } = useSelector((store) => store.customers);
  // console.log(additionServices ,'heeeeer')

  const addToList = () => {

    console.log("we are adding data to redux store", formData);
    dispatch(addService(formData));
    // dispatch(addStudentsServices(formData))
    setFormData({
      serviceName: "",
      categoryId: "",
      amount: "",
      description: "",
      priceType: "Fixed",
    });
    // addServiceToDB()
    setSuccessShow({ state: true, message: "Service Added" });
  };

  // const addServiceToDB = async () => {
  //   console.log("services", formData);
  //   try {
  //     const serviceData = await fetch(`${baseUrl}/api/add_services`, {
  //       method: "POST",
  //       body: JSON.stringify({
  //         services: [formData]
  //       }),
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //         "X-Authorization": localStorage.getItem("X-Authorization"),
  //       },
  //     });
  //     await serviceData.json().then((data) => {
  //       if (data.Status === "SUCCESS") {
  //         setTimeout(() => {
  //           navigate("/school/parent/add")
  //         }, 2000);

  //       }
  //     })
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const saveHandler = () => {
    addToList();
    props.setView("student");

  };



  const [errorShow, setErrorShow] = useState({ state: false, message: "" });

  const [options, setOptions] = useState([]);

  const fetchCategories = () => {
    HttpComponent({
      method: "GET",
      url: "/api/listCategories/Active",
      body: null,
      token: X_Authorization,
    })
      .then((data) => {
        console.log("here store is data", data);
        if (data.status === 200) {
          const mappedData = data.response.data.map(({ categoryName, _id }) => ({ label: categoryName, value: _id }));
          console.log("mapped complete", mappedData);
          setOptions(mappedData);
          dispatch(setCategories(mappedData));
        } else {
          console.error("Error setting info");
          setErrorShow({ state: true, message: data.response.message });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const [coursesOptions, setCoursesOptions] = useState([]);

    const getCourses = async () => {
        const response = await fetch(`${baseUrl}/api/v1/get_school_courses`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
            method: "GET",
        });
        const data = await response.json();
        console.log(data, "Get Courses");
        if (response.status === 200) {
            // setCoursesOptions(data.data);
            setCoursesOptions(data.data.map((courses) => {
                return { value: courses.courseName, label: courses.courseName }
            }
            ))
        }
    };

    useEffect(() => {
        getCourses();
    }, []);
console.log(coursesOptions, "coursesOptions");


 

  return (
    <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 1 }}>
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
      <Box component="div" sx={{ display: "flex" }}>
        <Box>
          <CustomInputLabel required={true} label={"Service Name"} />
          <CustomTextField value={formData.serviceName} onChange={handleInputChange} name={"serviceName"} placeholder={"Service Name"} />

          <CustomInputLabel required={true} label={"Amount Type"} />
          {/*maybe be a disabled select or text field we will see*/}
          <CustomTextField value={formData.priceType} name={"priceType"} />
        </Box>

        <Box component="div" sx={{ marginLeft: 1 }}>
          <CustomInputLabel required={true} label={"Service Category"} />
          {/*this now will have to be a select*/}
          <CustomSelectField value={formData.categoryId} onChange={handleInputChange} name={"categoryId"} placeholder={"Select Service Category"} options={options} medium />
          {/*<CustomTextField value={formData.serviceCategory} onChange={handleInputChange} name={"serviceCategory"} placeholder={"Service Category"} />*/}

          <CustomInputLabel required={true} label={"Amount"} />
          <CustomTextField value={formData.amount} onChange={handleInputChange} name={"amount"} placeholder={"Amount"} />
        </Box>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box component="div" sx={{}}>
          <CustomInputLabel required={true} label={ schoolTypeName.includes("University") ? "Select Course" : "Select Grade"} />
          {/* <CustomSelectField medium value={formData.grade} onChange={handleInputChange} name={"grade"} placeholder={"Select Grade"} options={gradeOptions} /> */}
          {schoolTypeName.includes("University") ? (
            <CustomSelectField medium value={formData.grade} onChange={handleInputChange} name={"grade"} placeholder={"Select Course"} options={coursesOptions} />
          ) : (
          <Select labelId="Invoice Status" id="year-select" value={formData.grade === '' ? "Select Grade" : formData.grade} onChange={handleInputChange} name={"grade"} placeholder={"Select Grade"} style={{ width: "400px" }} label="Year" >
            <MenuItem value={"Select Grade"} disabled>
              { "Select Grade"}
            </MenuItem>


            {/* <MenuItem value={"PP 1"}>PP 1</MenuItem>
                      <MenuItem value={"PP 2"}>PP 2</MenuItem>
                      <MenuItem value={"Grade 1"}>Grade 1</MenuItem>
                      <MenuItem value={"Grade 2"}>Grade 2</MenuItem>
                      <MenuItem value={"Grade 3"}>Grade 3</MenuItem>
                      <MenuItem value={"Grade 4"}>Grade 4</MenuItem>
                      <MenuItem value={"Grade 5"}>Grade 5</MenuItem>
                      <MenuItem value={"Grade 6"}>Grade 6</MenuItem>
                      <MenuItem value={"Grade 7"}>Grade 7</MenuItem>
                      <MenuItem value={"Grade 8"}>Grade 8</MenuItem>
                      <MenuItem value={"Grade 9"}>Grade 9</MenuItem>
                      <MenuItem value={"Grade 10"}>Grade 10</MenuItem>
                      <MenuItem value={"Grade 11"}>Grade 11</MenuItem>
                      <MenuItem value={"Grade 12"}>Grade 12</MenuItem> */}
            
            {gradeOptions.map((item) => {
              return (
                <MenuItem value={item.schoolGrades}>{item.schoolGrades}</MenuItem>
              )
            })}

          </Select>
          )}

        </Box>
        <Box component="div" sx={{ marginLeft: 1 }}>
          <CustomInputLabel required={true} label={schoolTypeName.includes("University") ? "Semesters" : "Term"} />
          <CustomSelectField medium value={formData.term} onChange={handleInputChange} name={"term"} placeholder={schoolTypeName.includes("University") ? "Semesters" : "Term"} options={termOptions} />
        </Box>
      </Box>

      <Box>
        <CustomInputLabel required={false} label={"Service Description"} />
        <TextField
          name={"description"}
          value={formData.description}
          onChange={handleInputChange}
          placeholder={"Service Description"}
          variant="outlined"
          margin="dense"
          sx={{ marginBottom: 2 }}
          multiline
          minRows={3}
          InputProps={{
            sx: {
              width: "51rem",
              fontSize: "0.875rem",
              "& fieldset": {
                borderColor: "#bec5d1",
              },
            },
          }}
        />
      </Box>

      <Box component="div" sx={{ width: "100%", display: "flex", marginLeft: "55%" }}>
        <Box component="div">
          {/*<Button sx={{*/}
          {/*    ...buttonStyles,*/}
          {/*    marginRight:1,*/}
          {/*    border:" solid 1px #002543",*/}
          {/*    color:"#002543",*/}
          {/*    '&:hover': {*/}
          {/*        backgroundColor:"transparent",*/}
          {/*        color: '#002543'*/}
          {/*    }*/}
          {/*}}*/}
          {/*        onClick={()=>props.setView("done")}*/}
          {/*        disabled={servicesToAdd?.length < 1}*/}
          {/*>*/}
          {/*   View*/}
          {/*</Button>*/}


          <Button
            sx={{
              ...buttonStyles,
              border: " solid 1px #002543",
              color: "#002543",
              "&:hover": {
                backgroundColor: "transparent",
                color: "#002543",
              },
            }}
            onClick={saveHandler}
            disabled={!formData.serviceName.trim() || !formData.categoryId.trim() || !formData.amount.trim()}
          >
            Save
          </Button>

          {/*<Button sx={{*/}
          {/*    ...buttonStyles,*/}
          {/*    marginLeft:1,*/}
          {/*    backgroundColor:" #032541",*/}
          {/*    color:"white",*/}
          {/*    '&:hover': {*/}
          {/*        backgroundColor: '#032541',*/}
          {/*        color: 'white'*/}
          {/*    }*/}
          {/*}}*/}
          {/*onClick={addToList}*/}
          {/*disabled={!formData.serviceName.trim() || !formData.categoryId.trim() || !formData.amount.trim()}*/}
          {/*>*/}
          {/*    Add Another*/}
          {/*</Button>*/}
        </Box>
      </Box>
    </Box>
  );
};

export default ServiceForm;
