import React, { useState } from 'react'
import { Grid, Button, Tab, styled, Box } from "@mui/material"
import { Add, FilterList } from "@mui/icons-material"
import { creditnotestyles } from './creditNoteStyling/styless'
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Activecreditnote from './creditnotecomponents/activecreditnote';
import Pendingcreditnote from './creditnotecomponents/pendingcreditnote';
import Declinedcreditnote from './creditnotecomponents/declinedcreditnotes';
import { useNavigate } from 'react-router-dom';
const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#f9f9f8" } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: { minWidth: 0 },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  fontSize: "13px",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "2.75",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#6e7074",
  fontFamily: ["Poppins"].join(","),
  "&:hover": { color: "#032541", opacity: 1 },
  "&.Mui-selected": { color: "#dc3545", fontWeight: 600 },
  "&.Mui-focusVisible": { backgroundColor: "blue" },
}));


const CreditNotePage = () => {

  const navigate = useNavigate();
  // tabs components
  const [creditTabs, setCreditTabs] = useState("Active");
  // set tabChange;
  const creditTabChange = (event, newTabValue) => {
    setCreditTabs(newTabValue)
  }
  return (
    <div style={creditnotestyles.universalFont}>
      <Grid container direction={"row"} alignItems={"center"} justifyContent={"space-between"} width="100%"  style={{ padding: "0px 20px 0px 20px", margin:"0px 0px 10px 0px" }}>
        <span style={creditnotestyles.creditNoteText}>Credit Note</span>
        <Button style={creditnotestyles.addcreditNoteButton} startIcon={<Add />} onClick={() => navigate("/createcreditnote")} >Create Credit Note</Button>
      </Grid>

      <Grid container direction={"row"} alignItems={"center"} style={{ padding: "0px 20px 0px 20px", margin:"0px 0px 10px 0px" }}>
        <span style={creditnotestyles.dashboardText}>Dashboard /</span>
        <span style={creditnotestyles.creditNoteTextMini}>Credit Note</span>
      </Grid>

      {/* TABS */}
      <Grid container  style={{padding:"0px 0px 0px 5px", margin:"0px 0px 10px 0px"}}>

        <Grid item  width={"100%"}>
          <TabContext  value={creditTabs}>
            <AntTabs  onChange={creditTabChange} variant="fullWidth">
              <AntTab label="Active" value="Active" />
              <AntTab label="Pending Approval" value="Pending Approval" />
              <AntTab label="Declined" value="Declined" />
              {/* <AntTab label="Expired" value="Expired" /> */}
            </AntTabs>

            {/* search , filter,  */}

            <Box style={creditnotestyles.creditNoteSearchHolder}>
              <input style={creditnotestyles.inputstyling} type="text" placeholder='search' />

              <Box style={creditnotestyles.filterItemsHolder}>
                <span style={creditnotestyles.filterText}>Filter</span>
                <FilterList style={creditnotestyles.filterList}/>
              </Box>
            </Box>

            {/* active tab panel display */}
            <TabPanel value={"Active"} >
            <Activecreditnote/>
            </TabPanel>

            {/* pending approvals */}
            <TabPanel value={"Pending Approval"}>
            <Pendingcreditnote/>
            </TabPanel>

            <TabPanel value={"Declined"}>
              <Declinedcreditnote/>
            </TabPanel>
           
          </TabContext>
        </Grid>
      </Grid>
    </div>
  )
}

export default CreditNotePage