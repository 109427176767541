import React, {useEffect, useState} from 'react';
import {Box, Button, Divider, Typography} from "@mui/material";
import CustomInputLabel from "../../School/CustomInputLabel";
import CustomTextField from "../../School/CustomTextField";
import HttpComponent from "../../School/MakeRequest";
import CustomSelectField from "../../School/CustomSelectField";
import PhoneInput from "react-phone-input-2";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {ErrorAlert} from "../../snackBar Alerts/errorAlert";
import {changeToken} from "../../../features/userSlice";
import GeoLocation from '../../../hooks/useLocation';
const GeneralBusinessDetails = (props) =>{
    const { category } = useSelector((store) => store.category);
//   const [businessType, setBussinessType] = useState([]);
  const [formData, setFormData] = useState({
    businessType: category,
    businessName:"",
    businessOwner:"",
    phoneNumber:"",
    email:"",
    location:"",
    country:"Kenya",
    currency:""
})

// get business types
const getBusinessTypes =() =>{
    HttpComponent({
        method: 'GET',
        url: '/api/listBusinessCategory',
        body: null,
        token: X_Authorization,
    }).then((data)=>{
        console.log("here store is data",data);
    }).catch((error)=>{
        console.error(error.message);
    })
}

useEffect(() =>{
    getBusinessTypes()
},[])

const handleInputChange = (e) => {
    setFormData({
        ...formData,
        [e.target.name]: e.target.value,
    });
};

const cancelButtonStyles =
        {"width":"7.813rem",
            "height":"2.813rem",
            "padding":"12.5px 36.7px 12.5px 38.3px",
            "borderRadius":"4px",
            "border":"solid 1px #dc3545",
            "color":"#dc3545"
        }

const doneButtonStyles ={
    "width":"7.813rem",
    "height":"2.813rem",
    "padding":"12.5px 43.7px 12.5px 45.3px",
    "borderRadius":"4px",
    "backgroundColor":"#17ae7b",
    "color":"white",
    "marginLeft":2,
    '&:hover': {
        backgroundColor: '#17ae7b',
        color: 'white'
    }
}

const distributorOptions = [
    {value:"Distributor", label:"Distributor"},
]

const countryOptions = [
    {value:"Kenya", label:"Kenya"}
]

const currencyOptions = [
    
    {value:"KES", label:"KES"},
]

const navigate = useNavigate();
const dispatch = useDispatch();

const { userId ,X_Authorization } = useSelector((store) => store.user);

const [errorShow, setErrorShow] = useState({state: false, message: ""})


const [showBusinessDetails, setShowBusinessDetails] = useState(false);
const [selectedValue ,setSelectedValue] = useState('')

const submitBusinessDetails = () =>{
   let submitObject ={
       businessName:formData.businessName,
       businessCategory:"Eatery",
       businessOwnerName:formData.businessOwner,
       businessOwnerUserName:formData.businessOwner.split(" ").join(""),
       businessOwnerPhone:formData.phoneNumber,
       businessOwnerEmail:formData.email,
       businessOwnerAddress: selectedValue?.description,
       //businessOwnerAddress: formData.location,
       country:formData.country,
       localCurrency:formData.currency,
       deviceCreatedOn:"Web",
       userId:userId,
       Paybill:"",
       Vooma:"",
       Equitel:"",
       Till:""
   }

//    console.log("data", submitObject)

    HttpComponent({
        method: 'POST',
        url: '/postBusiness',
        body: submitObject,
        token: X_Authorization
    }).then((data)=>{
        console.log("here store is data",data);
        if(data.status === 201){
            dispatch(changeToken({token: data.response.data.token}))
            localStorage.setItem("X-Authorization", data.response.data.token);
            localStorage.setItem("businessCategory", "Eatery, Bar and Restaurant")
            localStorage.setItem("businessId", data.response.businessNumber)
            localStorage.setItem("businessID", data.response.businessId)
            localStorage.setItem("businessName", data.response.data.businessName)
            localStorage.setItem("BranchID", data.response.data.branchId)
            
            window.location.reload()
            props.next()
           
           
        }else{
            console.error("Error setting info")
            setErrorShow({state:true, message:data.response.message})
        }
    }).catch((error)=>{
        console.error(error.message);
    })
}

const [validEmail, setValidEmail] = useState(true);

const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

useEffect(() => {
    if(formData.email != ""){
        let result = validateEmail(formData.email);
        setValidEmail(result);
        // console.log("email valid", result)
    }else{
        setValidEmail(true);
    }
}, [formData.email]);
  return(
    <Box component="div" sx={{display:"flex", justifyContent:"center", margin:2, width:"100%"}}>
    <Box component="div" sx={{display:"flex", flexDirection:"column", width:"100%", alignSelf:"center"}}>

    <ErrorAlert
        vertical="top"
        horizontal="right"
        onClose={()=>setErrorShow({...errorShow, state:false})}
        open={errorShow.state}
        message={errorShow.message}/>


    {!showBusinessDetails ?
        <Box sx={{alignSelf:"center"}}>
            {/*title*/}
            <Box component="div" sx={{alignSelf:"center", marginY:2}}>
                <Typography sx={{fontSize:"1rem", color:"#707070", justifyContent:'center', display:'center'}}>
                    Set-up your business details.
                </Typography>
            </Box>
            {/*    end of title*/}

            {/*begin of form*/}
            <Box compnent="div" sx={{display:"flex"}}>

                {/*column one*/}
                <Box component="div" sx={{display:"flex", flexDirection:"column", marginX:1}}>
                    {/* <CustomInputLabel required={true} label={"Business Type"}/> */}
                    {/*below will change to select*/}
                    {/*<CustomTextField value={formData.businessType} onChange={handleInputChange} name={"businessType"} placeholder={"Select Business Type"} />*/}
                    {/* <CustomSelectField value={formData.businessType} onChange={handleInputChange} name={"businessType"} placeholder={"Select Business Type"} options={distributorOptions} /> */}



                    <CustomInputLabel required={true} label={"Business Name"}/>
                    <CustomTextField value={formData.businessName} onChange={handleInputChange} name={"businessName"} placeholder={"Business Name"} />

                    <CustomInputLabel required={true} label={"Owners"}/>
                    <CustomTextField value={formData.businessOwner} onChange={handleInputChange} name={"businessOwner"} placeholder={"Directors"} />

                    <CustomInputLabel required={true} label={"Phone Number"}/>
                    {/*<CustomTextField value={formData.phoneNumber} onChange={handleInputChange} name={"phoneNumber"} placeholder={"Phone Number"} />*/}
                    <Box component="div" sx={{marginTop:1}}>
                        <PhoneInput inputStyle={{height : '3.438rem'}} required country={"ke"} enableSearch={true} value={formData.phoneNumber} name={"phoneNumber"}  onChange={(phone) => formData.phoneNumber=phone}/>
                    </Box>
                </Box>

                {/*colum two*/}
                <Box component="div" sx={{display:"flex", flexDirection:"column", marginX:1}}>

                    <CustomInputLabel  required={true} label={"Email"}/>
                    {!validEmail ?
                        <Typography sx={{color:"#dc3545", fontSize: "0.75rem"}}>
                            Please enter a valid email
                        </Typography>
                        :
                        null
                    }
                    <CustomTextField value={formData.email} onChange={handleInputChange} name={"email"} placeholder={"Email"} />

                    <CustomInputLabel required={true} label={"Location"}/>
                    <GeoLocation sx={{marginBottom:"10px" , marginTop:"10px"}}  selectedValue={selectedValue} onValueChange={(location)=>setSelectedValue(location)}/>
                    {/* <CustomTextField value={formData.location} onChange={handleInputChange} name={"location"} placeholder={"Location"} /> */}

                    <Box style={{ display:'flex' }}>
                    <Box>
                    <CustomInputLabel required={true} label={"Country"}/>
                    {/*this will change to select*/}
                    {/*<CustomTextField value={formData.country} onChange={handleInputChange} name={"country"} placeholder={"Select Country"} />*/}
                    <CustomSelectField style={{width : '12.1rem'}} value={formData.country} onChange={handleInputChange} name={"country"} options={countryOptions} />
                    </Box>
                    <Box style={{ marginLeft:'20px' }}>
                    <CustomInputLabel required={true} label={"Currency"} />
                    {/*this will change to select*/}
                    {/*<CustomTextField value={formData.currency} onChange={handleInputChange} name={"currency"} placeholder={"Select Currency"} />*/}
                    <CustomSelectField style={{width : '12.1rem'}} value={formData.currency} onChange={handleInputChange} name={"currency"} placeholder={"Select Currency Type"} options={currencyOptions}/>
                    </Box>
                    </Box>

                </Box>

            </Box>

            {console.log(formData.phoneNumber.trim(), 'formData.phoneNumber.trim()', formData)}
            {/*    end of form */}
            <Box component="div" sx={{width:"100%", display:"flex", justifyContent:"end"}}>
                <Box component="div">
                    <Button sx={cancelButtonStyles} onClick={()=>navigate("/createBusiness")}>
                        Cancel
                    </Button>

                    <Button
                        disabled={
                            !formData.email.trim() ||
                            !formData.businessName.trim() ||
                            !formData.phoneNumber.trim() ||
                            !selectedValue||
                            !formData.businessOwner.trim() 
                            
                    }
                        sx={doneButtonStyles} onClick={()=>setShowBusinessDetails(true)}>
                        Done
                    </Button>
                </Box>
            </Box>
        </Box>
    :
        <Box sx={{alignSelf:"center", width:"100%"}}>
            {/*title*/}
            <Box component="div" sx={{display:"flex", width:"100%", justifyContent:"center",alignSelf:"center",marginY:2}}>
                <Typography sx={{alignSelf:"center",fontSize:"1rem", color:"#17ae7b", fontWeight:700}}>
                    Business Summary
                </Typography>
            </Box>
            {/*    end of title*/}


            {/*begin of form*/}
            <Box compnent="div" sx={{display:"flex", width:"100%", justifyContent:"center",alignSelf:"center"}}>
                {/*column one*/}
                <Box component="div" sx={{display:"flex", flexDirection:"column", marginX:2, width:"35%"}}>
                    <Box component="div" sx={{display:"flex", flexDirection:"column", width:"100%"}}>
                        <Typography sx={{color:"#707070", fontSize:"0.875rem", marginY:0.5}}>
                            Business Type
                        </Typography>
                        <Typography sx={{color:"#032541", fontSize:"0.875rem",marginY:0.5}}>
                            {formData.businessType}
                        </Typography>
                        <Divider sx={{backgroundColor:"#707070", marginY:1}}/>
                    </Box>

                    <Box component="div" sx={{display:"flex", flexDirection:"column", width:"100%"}}>
                        <Typography sx={{color:"#707070", fontSize:"0.875rem",marginY:0.5}}>
                            Business Name
                        </Typography>
                        <Typography sx={{color:"#032541", fontSize:"0.875rem",marginY:0.5}}>
                            {formData.businessName}
                        </Typography>
                        <Divider sx={{backgroundColor:"#707070", marginY:1}}/>
                    </Box>

                    <Box component="div" sx={{display:"flex", flexDirection:"column", width:"100%"}}>
                        <Typography sx={{color:"#707070", fontSize:"0.875rem",marginY:0.5}}>
                            Director
                        </Typography>
                        <Typography sx={{color:"#032541", fontSize:"0.875rem",marginY:0.5}}>
                            {formData.businessOwner}
                        </Typography>
                        <Divider sx={{backgroundColor:"#707070", marginY:1}}/>
                    </Box>

                    <Box component="div" sx={{display:"flex", flexDirection:"column", width:"100%"}}>
                        <Typography sx={{color:"#707070", fontSize:"0.875rem",marginY:0.5}}>
                            Phone Number
                        </Typography>
                        <Typography sx={{color:"#032541", fontSize:"0.875rem",marginY:0.5}}>
                            {"+" + formData.phoneNumber}
                        </Typography>
                        <Divider sx={{backgroundColor:"#707070", marginY:1}}/>
                    </Box>

                </Box>
                {/*end column onw*/}

                {/*column two*/}
                <Box component="div" sx={{display:"flex", flexDirection:"column", marginX:2, width:"35%"}}>
                    <Box component="div" sx={{display:"flex", flexDirection:"column", width:"100%"}}>
                        <Typography sx={{color:"#707070", fontSize:"0.875rem",marginY:0.5}}>
                            Email
                        </Typography>
                        <Typography sx={{color:"#032541", fontSize:"0.875rem",marginY:0.5}}>
                        {formData.email}
                        </Typography>
                        <Divider sx={{backgroundColor:"#707070", marginY:1}}/>
                    </Box>

                    <Box component="div" sx={{display:"flex", flexDirection:"column", width:"100%"}}>
                        <Typography sx={{color:"#707070", fontSize:"0.875rem",marginY:0.5}}>
                            Location
                        </Typography>
                        <Typography sx={{color:"#032541", fontSize:"0.875rem",marginY:0.5}}>
                        {selectedValue?.description}
                        </Typography>
                        <Divider sx={{backgroundColor:"#707070", marginY:1}}/>
                    </Box>

                    <Box component="div" sx={{display:"flex", flexDirection:"column", width:"100%"}}>
                        <Typography sx={{color:"#707070", fontSize:"0.875rem",marginY:0.5}}>
                            Country
                        </Typography>
                        <Typography sx={{color:"#032541", fontSize:"0.875rem",marginY:0.5}}>
                        {formData.country}
                        </Typography>
                        <Divider sx={{backgroundColor:"#707070", marginY:1}}/>
                    </Box>

                    <Box component="div" sx={{display:"flex", flexDirection:"column", width:"100%"}}>
                        <Typography sx={{color:"#707070", fontSize:"0.875rem",marginY:0.5}}>
                            Currency
                        </Typography>
                        <Typography sx={{color:"#032541", fontSize:"0.875rem",marginY:0.5}}>
                         {formData.currency}
                        </Typography>
                        <Divider sx={{backgroundColor:"#707070", marginY:1}}/>

                    </Box>


                </Box>
                {/*end column two*/}

            </Box>
            <Box component="div" sx={{width:"86%", display:"flex", justifyContent:"end", marginTop:1}}>
                <Box component="div">
                    <Button sx={
                    {"width":"7.813rem",
                        "height":"2.813rem",
                        "padding":"12.5px 36.7px 12.5px 38.3px",
                        "borderRadius":"4px",
                        "border":"solid 1px #032541",
                        "color":"#032541"
                    }} onClick={()=>setShowBusinessDetails(false)}>
                        Edit
                    </Button>

                    <Button sx={doneButtonStyles} onClick={submitBusinessDetails}>
                       Next
                    </Button>
                </Box>
            </Box>

        </Box>
    }


    </Box>
</Box>
  )
}

export default GeneralBusinessDetails;