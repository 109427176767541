import { Breadcrumbs, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HttpComponent from "../School/MakeRequest";

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Join Requests</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Assign Role</Typography>,
]

export default function AddRoles(props) {
    const [role, setRole] = useState('')
    const bsCategory = localStorage.getItem('businessCategory')
    const [allbranches,setAllBranches] = useState([])
    const [selectedBranch ,setSelectedBranch] = useState()
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const navigate = useNavigate()

    console.log(props)

    ///Director//Accountant//Teacher//Cashier

    const normalBsRole = [
        {
            key: 'Merchant',
            role: "Merchant"
        },
        {
            key: 'Owner',
            role: "Owner"
        },
        {
            key: 'Cashier',
            role: "Cashier"
        },
        {
            key: 'Supervisor',
            role: "Supervisor"
        },
        {
            key: 'StoreOperator',
            role: 'StoreOperator'
        }
    ]
    const RentalBsRoles = [
        {
            key: 'Landlord',
            role: "Landlord"
        },
        {
            key: 'Tenant',
            role: "Tenant"
        },
        {
            key: 'Caretaker',
            role: "Caretaker"
        }
    ]

    const SchoolBsRoles = [
        {
            key: 'Principal',
            role: "Principal"
        },
        {
            key: 'Teacher',
            role: "Teacher"
        },
        {
            key: 'Cashier',
            role: "Cashier"
        },
        {
            key: 'Director',
            role: "Director"
        }
    ]

    const options = bsCategory === 'Rental' ? RentalBsRoles : bsCategory === 'School' ? SchoolBsRoles : normalBsRole

    function getAllBranches() {
        try {
            HttpComponent({
                method:'POST',
                url:`/api/getBusinessBranches`,
                body:{
                    userId:localStorage.getItem('userId'),
                    businessId:localStorage.getItem('businessID')
                },
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if(data.status === 201){
                    setAllBranches(data?.response?.data)
                }
            })

        } catch (error) {

        }
    }

    useEffect(()=>{
        getAllBranches()
    },[])

    //assign branch to a given user

    function assignUserToBusiness(){
        try{
            HttpComponent({
                method:'POST',
                url:`/api/update_join_business`,
                body:{
                    userId:localStorage.getItem('userId'),
                    businessId:localStorage.getItem('businessID'),
                    branchId:selectedBranch,
                    requestId:props?.approveData?.requestId,
                    userGroup:role,
                    status:"ACCEPTED"
                },
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{
               if(data.status === 201){
                setSuccessShow({state:true , message:'Successfully Approved join request'})
                setTimeout(()=>{
                    navigate(-1)
                },1500)
               }else{
                setErrorShow({state:true , message:data?.response?.message})
                setTimeout(()=>{
                    navigate(-1)
                },1500)
               }
            })

        }catch(error){
            console.log(error)
        }
    }

    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item>
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Assign Roles</Typography>
            </Grid>
            <Grid item display={'flex'} mt={2}>
                <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                    <Grid item>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2}>
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>What will be {props?.approveData?.userName}  Role</Typography>
            </Grid>
            <Grid item mt={2} width={'50%'}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={role}
                        label="Role"
                        onChange={(e) => setRole(e.target.value)}
                    >
                        {options?.map((option) => (
                            <MenuItem key={option?.key} value={option?.role}>{option?.role}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item mt={2} width={'50%'}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Branches</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedBranch}
                        label="Branches"
                        onChange={(e) => setSelectedBranch(e.target.value)}
                    >
                        {allbranches?.map((branch)=>(
                            <MenuItem value={branch?._id}>{branch?.branchName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item mt={2} width={'50%'} display={'flex'} alignItems={'center'} alignContent={'flex-end'} justifyContent={'flex-end'}>
                 <Button style={{color:"#dc3545" , border:"1px solid #dc3545" ,width:"125px" , marginRight:"10px"}} onClick={()=>navigate(-1)}>Cancel</Button>
                <Button style={!role || !selectedBranch ? {textTransform:"inherit" ,width:"125px", background:"#f5f6f7" , color:'#032541'}:{textTransform:"inherit" ,width:"125px" ,color:"#fff", background:"#0d243f"}} onClick={assignUserToBusiness}>Assign</Button>
            </Grid>
        </Grid>
    )
}