import React, {useEffect, useState} from 'react';
import {Box, Button, Typography} from "@mui/material";
import CustomInputLabel from "./CustomInputLabel";
import CustomSelectField from "./CustomSelectField";
import HttpComponent from "./MakeRequest";
import {setCategories} from "../../features/servicesSlice";
import {useSelector} from "react-redux";
import {ErrorAlert} from "../snackBar Alerts/errorAlert";
import CustomSearchInput from "./CustomSearchInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CustomTextFieldSmall from "./CustomTextFieldSmall";
import CustomTable from "./CustomTable";


const ServicesInvoiceForm = (props) =>{
    const [options, setOptions] = useState([]);
    const [checkboxData, setCheckboxData] = useState([]);
    const { userId ,X_Authorization } = useSelector((store) => store.user);
    const [showData, setShowData] = useState([])
    const [errorShow, setErrorShow] = useState({state: false, message: ""})
    const {schoolTypeName, schoolTypeId} = useSelector((store) => store.schoolType.schoolTypeDetail)

    const [formData, setFormData] = useState({
        search:"",
        categoryName:""
    })

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const fetchCategories = () =>{
        HttpComponent({
            method: 'GET',
            url: '/api/listCategories/Active',
            body: null,
            token: X_Authorization
        }).then((data)=>{
            console.log("here store is data",data);
            if(data.status === 200){
                const mappedData = data.response.data.map(({ categoryName }) => ({ label: categoryName, value: categoryName }));
                console.log("mapped complete", mappedData)
                setOptions(mappedData)
            }else{
                console.error("Error setting info")
                setErrorShow({state:true, message:data.response.message})
            }
        }).catch((error)=>{
            console.error(error.message);
        })
    }

    const fetchServices = () =>{
        let url;
        if(formData.categoryName == ""){
            url = '/api/listProducts/Active?search'
        }else{
            url = `/api/listByProducts?search=${formData.categoryName}&page=1&limit=1000`
        }
        HttpComponent({
            method: 'GET',
            url:url,
            body: null,
            token: X_Authorization
        }).then((data)=>{
            console.log("here store is data",data);
            if(data.status === 200){
                if(formData.categoryName == ""){
                    setCheckboxData(data.response.data)
                    setData()
                }else{
                    setCheckboxData(data.response.products)
                    setData()
                }
            }else{
                console.error("Error setting info")
                setErrorShow({state:true, message:data.response.message})
            }
        }).catch((error)=>{
            console.error(error.message);
        })
    }

    useEffect(() => {
        fetchCategories()
    }, []);

    useEffect(() => {
        fetchServices()
    }, [formData.categoryName]);


    const setData = () =>{
        if(formData.search !== ""){
            const searchTerm = formData.search.toLowerCase();
            const filteredData = checkboxData.filter(obj => obj.productName.toLowerCase().includes(searchTerm));
            setShowData(filteredData);
        }else{
            setShowData(checkboxData)
        }
    }

    useEffect(() => {
        setData()
    }, [formData.search, checkboxData]);


    const handleCheckboxChange = (event, productId) =>{
        // setServices
        if (event.target.checked) {
            props.setServices([...props.services, {productId, quantity:1}]);
        } else {
            props.setServices(props.services.filter((obj) => obj.productId !== productId));
        }
    }

    useEffect(() => {
       console.log("services", [...props.services])
    }, [props.services]);

    const columns = [
        { headerName: "Service Name", field: "checked", flex:1,
            renderCell: (params) => (
                <FormControlLabel
                    control={
                    <Checkbox onChange={(event)=>
                        handleCheckboxChange(event, params.row._id)} />
                } label={params.row.productName} />
            ),
        },
        { headerName: "Description", field: "productDescription" ,flex:1,},

        { headerName: "Grade", field: "grade" ,flex:1,},
        { headerName: "Term", field: "term" ,flex:1},
        { headerName: "Amount", field: "productPrice" ,flex:1,
            renderCell:(params) =>(
                <Typography>
                    {params.value}
                </Typography>
            )},
    ];
    //                   

    const columnsCollege = [
        { headerName: "Service Name", field: "checked", flex:1,
            renderCell: (params) => (
                <FormControlLabel
                    control={
                    <Checkbox onChange={(event)=>
                        handleCheckboxChange(event, params.row._id)} />
                } label={params.row.productName} />
            ),
        },
        { headerName: "Description", field: "productDescription" ,flex:1,},

        { headerName: "Course", field: "grade" ,flex:1,},
        { headerName: "Semester", field: "term" ,flex:1},
        { headerName: "Amount", field: "productPrice" ,flex:1,
            renderCell:(params) =>(
                <Typography>
                    {params.value}
                </Typography>
            )},
    ];

    return(
        <Box component="div" sx={{width:"100%"}}>
            <ErrorAlert
                vertical="top"
                horizontal="right"
                onClose={()=>setErrorShow({...errorShow, state:false})}
                open={errorShow.state}
                message={errorShow.message}/>

            <Box component="div" sx={{display:"flex",  margin:1}}>
                <Box component="div">
                    <CustomSearchInput sm  name={"search"} value={formData.search} placeholder={"Search:"} onChange={handleInputChange}/>
                </Box>
                <Box component="div" sx={{marginLeft:1}}>
                    <CustomSelectField sm value={formData.categoryName} onChange={handleInputChange} name={"categoryName"} placeholder={"All"} options={options} />
                </Box>
                <Box component="div" className='ml-auto' sx={{marginLeft:1, display:"flex", flexDirection:"column", justifyContent:"center"}}>
                    <Button sx={{
                        backgroundColor:"#032541",
                        color: 'white',
                        "width":"8.813rem",
                        "height":"3.25rem",
                        marginBottom:1,
                        '&:hover': {
                            backgroundColor: '#032541',
                            color: 'white'
                        },
                    }}
                            onClick={()=>props.generateInvoices()}
                    >
                        Generate
                    </Button>
                </Box>
            </Box>



            {/*<Box component="div" sx={{}}>*/}
            {/*    <B*/}
            {/*</Box>*/}

            <Box sx={{width:"100%"}}>
                {/*{showData?.length ?*/}
                {/*    <>*/}
                {/*        {showData?.map(data => (*/}
                {/*            <Box component="div" key={data._id} style={{ display: "flex", justifyContent: "space-between", padding: "0.5rem" }}>*/}
                {/*                <FormControlLabel control={<Checkbox onChange={(event)=>handleCheckboxChange(event, data._id)} />} label={data.productName} />*/}
                {/*                <CustomTextFieldSmall required label="Amount" defaultValue={`KES ${data.productPrice}`} disabled={true}/>*/}
                {/*                /!*<TextField id="outlined-basic"  variant="outlined" style={{ width: "60%", height: "2.188rem" }} />*!/*/}
                {/*            </Box>*/}
                {/*        ))}*/}
                {/*    </>*/}
                {/*    :*/}
                {/*    "No Services Found"*/}
                {/*}*/}
                <CustomTable fontSize={'10px'} data={showData} columns={schoolTypeName.includes("University") ? columnsCollege :  columns} rowsPerPage={5} />

            </Box>


        </Box>
    )
}

export default ServicesInvoiceForm;
