import {Box, Breadcrumbs, Button, TextField, Typography} from "@mui/material";
import React, {useState,useEffect} from 'react';
import CustomSearchInput from "./CustomSearchInput";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {useNavigate, useParams} from "react-router-dom";
import HttpComponent from "./MakeRequest";
import {useSelector} from "react-redux";
import {ErrorAlert} from "../snackBar Alerts/errorAlert";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CustomTable from "./CustomTable";
import ExportMenu from "./ExportMenu";



const InvoicesInBatch = () => {
    const { batchId } = useParams();
    const navigate = useNavigate();

    const breadcrumbs = [
        <Typography key="X" sx={{color:"#707070",fontSize:"0.875rem"}}>
            Dashboard
        </Typography>,
        <Typography onClick={()=>navigate("/school/invoices/batch")} key={"Invoices"} sx={{color:"#707070", fontSize:"0.875rem", cursor:"pointer"}}>
            Invoices
        </Typography>,
        <Typography key={"InvoiceB"} sx={{color:"#dc3545", fontSize:"0.875rem"}}>
            Invoice Batch
        </Typography>
    ];
    const columns = [
        { headerName: "Invoice No", field: "invoiceNumber", flex:1,
            renderCell: (params) => (
                <Typography onClick={()=>navigate(`/school/invoice/${params.row.invoiceNumber}`)} sx={{color:"#33699d",textDecoration: "underline"}}>
                    {params.row.invoiceNumber}
                </Typography>
            ),
        },
        { headerName: "Admission No", field: "studentNumber" ,flex:1,},
        { headerName: "Student Name", field: "studentName" ,flex:1},
        { headerName: "Grade", field: "grade" ,flex:1},
        { headerName: "Term", field: "term" ,flex:1},
        { headerName: "Invoice Amount", field: "invoiceAmount" ,flex:1,
            renderCell: (params) => (
                <Typography>
                    KES {params.row.invoiceAmount}
                </Typography>
            ),
        },
        { headerName: "Amount Paid", field: "amountPaid" ,flex:1,
            renderCell: (params) => (
                <Typography>
                    KES {params.row.amountPaid}
                </Typography>
            ),
        },
        { headerName: "Amount Due", field: "amountDue" ,flex:1,
            renderCell: (params) => (
                <Typography>
                    KES {params.row.amountDue}
                </Typography>
            ),
        },
        // status
        { headerName: "Delivery Status", field: "deliveryStatus" ,
            renderCell: (params) => (
                <Typography>
                    {titleData.status == "PENDING" ? "PENDING" :  titleData.status == "DECLINED" ? "N/A" : params.value}
                </Typography>
            ),
        }
    ];

    const { userId ,X_Authorization } = useSelector((store) => store.user);
    const [errorShow, setErrorShow] = useState({state: false, message: ""})
    const [tableData, setTableData] = useState([]);
    const [dataToShow, setDataToShow] = useState([]);
    const [titleData, setTitleData] = useState({})

    const [formData, setFormData] = useState(
        {
            search:"",
        }
    )

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const fetchInvoicesInBatch = () =>{
        HttpComponent({
            method: 'GET',
            url: `/api/get_student_invoices_by_batch?page=1&limit=10000000&batchId=${batchId}`,
            body: null,
            token: X_Authorization
        }).then((data)=>{
            console.log("here store is data",data);
            if(data.status === 200){
                setTableData(data.response.data)
                setTitleData({
                    count:data.response.count,
                    status:data.response.batchStatus,
                    batchNumber:data.response.batchNumber,
                    totalAmount:data.response.totalAmount,
                    totalAmountPaid:data.response.totalAmountPaid,
                    totalPendingAmount:data.response.totalPendingAmount
                })
            }else{
                console.error("Error setting info")
                setErrorShow({state:true, message:data.response.message})
            }
        }).catch((error)=>{
            console.error(error.message);
        })
    }

    useEffect(() => {
        fetchInvoicesInBatch()
    }, [batchId]);

    const setData = () =>{
        let searchable = [...tableData]
        if(formData.search !== ""){
            const result = searchable.find(invoice =>
                invoice.invoiceNumber.toLowerCase().includes(formData.search) ||
                invoice.studentName.toLowerCase().includes(formData.search)
            );
            console.log("search", result)
            setDataToShow([result]);
        }else{
            setDataToShow(tableData)
        }
    }

    useEffect(() => {
        setData()
        console.log("search", formData.search)
    }, [formData.search, tableData]);


    const columnItems = [
        {
            textOne:"Batch No:",
            textTwo:titleData.batchNumber
        },
        {
            textOne:"Status:",
            textTwo:titleData.status
        },
        {
            textOne:"Invoices:",
            textTwo:titleData.count
        },
        {
            textOne:"Total Amount:",
            textTwo:`KES ${titleData.totalAmount}`
        },
        {
            textOne:"Amount Paid:",
            textTwo:`KES ${titleData.totalAmountPaid}`
        },
        {
            textOne:"Amount Due:",
            textTwo:`KES ${titleData.totalPendingAmount}`
        },
    ]

    const csvColumns  = [
        { label: "Invoice No", key: "Invoice No" },
        { label: "Admission No", key: "Admission No" },
        { label: "Student Name", key: "Student Name" },
        { label: "Grade", key: "Grade" },
        { label: "Term", key: "Term" },
        { label: "Invoice Amount", key: "Invoice Amount" },
        { label: "Amount Paid", key: "Amount Paid" },
        { label: "Amount Due", key: "Amount Due" },
    ]

    const fileHeaders = [[
        "Invoice No",
        "Admission No",
        "Student Name",
        "Grade",
        "Term",
        "Invoice Amount",
        "Amount Paid",
        "Amount Due"
    ]]

    const fileData = dataToShow.map((data) => {
        let obj;
        obj = {
            "Invoice No": data?.invoiceNumber,
            "Admission No": data?.studentNumber,
            "Student Name": data?.studentName,
            "Grade":data?.grade,
            "Term":data?.term,
            "Invoice Amount":data?.invoiceAmount,
            "Amount Paid":data?.amountPaid,
            "Amount Due":data?.amountDue
        }
        return obj;
    })

    const fileDataPDF = dataToShow.map((data) => {
        let theData
        theData =[
            data?.invoiceNumber,
            data?.studentNumber,
            data?.studentName,
            data?.grade,
            data?.term,
            data?.invoiceAmount,
            data?.amountPaid,
            data?.amountDue
        ]
        return theData;
    })


    return (
        <Box component="div" sx={{}}>

            <Box sx={{display:"flex", margin:1, justifyContent:"space-between"}}>
                <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                    <ErrorAlert
                        vertical="top"
                        horizontal="right"
                        onClose={()=>setErrorShow({...errorShow, state:false})}
                        open={errorShow.state}
                        message={errorShow.message}/>
                    <Typography sx={{color:"#032541", fontSize:"1.563rem", fontWeight:600}}>
                        Invoice Batch
                    </Typography>
                </Box>
            </Box>
            <Box component="div" sx={{margin:1,marginBottom:2,display:"flex", justifyContent:"space-between"}}>
                <Breadcrumbs
                    separator={<FiberManualRecordIcon sx={{fontSize:"0.625rem", color:"#e1e5e8"}} />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
                {/*{titleData.status === "PENDING" ?*/}
                {/*    <Box component={"div"} sx={{gap:2, display:"flex"}}>*/}
                {/*        <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center"}}>*/}
                {/*            <Button sx={{}}>*/}
                {/*                Approve*/}
                {/*            </Button>*/}
                {/*        </Box>*/}
                {/*        <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center"}}>*/}
                {/*            <Button>*/}
                {/*                Decline*/}
                {/*            </Button>*/}
                {/*        </Box>*/}
                {/*    </Box>*/}
                {/*    :null*/}
                {/*}*/}
            </Box>

            <Box component="div" sx={{display:"flex", backgroundColor:"#fafafa", justifyContent:"space-between", marginX:5,borderRadius:"10px"}}>
                {columnItems.map((column,index)=>
                    <Box key={index} component="div" sx={{display:"flex", flexDirection:"column", margin:2}}>
                        <Typography sx={{fontColor:"#032541"}}>
                            {column.textOne}
                        </Typography>
                        <Typography sx={{fontWeight:700, fontColor:"#032541"}}>
                            {column.textTwo}
                        </Typography>
                    </Box>
                )}
            </Box>

            <Box component="div" sx={{display:"flex", flexDirection:"column",margin:1}}>
                <Box component="div" sx={{display:"flex", justifyContent:"space-between"}}>
                    <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                        <CustomSearchInput name={"search"} value={formData.search} onChange={handleInputChange} placeholder="Search:"/>
                    </Box>
                    <ExportMenu
                        csvColumns={csvColumns}
                        fileData={fileData}
                        fileHeaders={fileHeaders}
                        fileDataPDF={fileDataPDF}
                        title={`Invoices For batch ${titleData.batchNumber}`}
                        fileName={`Invoices-${titleData.batchNumber}`}
                    />
                </Box>
            </Box>

            <CustomTable data={dataToShow} columns={columns} rowsPerPage={5}/>




        </Box>
    );
};

export default InvoicesInBatch;

