import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    FormHelperText,
    Grid,
    InputLabel,
    Modal,
    Select,
    Stack,
    styled,
    Tab,
    TablePagination
} from "@mui/material";
import MenuItem from "@material-ui/core/MenuItem";
import {CheckCircle, Circle, Close} from "@mui/icons-material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
let baseUrl = process.env.REACT_APP_BASE_URL
const AntTabs = styled(TabList)({
    borderBottom: "3px solid #e8e8e8",
    "& .MuiTabs-indicator": {
        backgroundColor: "#dc3545",
    },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: "none",
        minWidth: 0,
        [theme.breakpoints.up("sm")]: {
            minWidth: 0,
        },
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        fontSize: "13px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "2.75",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#6e7074",
        fontFamily: ["Poppins"].join(","),
        "&:hover": {
            color: "#032541",
            opacity: 1,
        },
        "&.Mui-selected": {
            color: "#dc3545",
            fontWeight: 600,
        },
        "&.Mui-focusVisible": {
            backgroundColor: "blue",
        },
    })
);

const wholeModal = {
    minWidth: '500px',
    minHeight: '500px',
    backgroundColor: '#fff',
    borderColor:"transparent",
    borderRadius: "15px",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};
const headerSection = {
    backgroundColor: '#dc3545',
    width: '100%',
    borderTopLeftRadius: "7px",
    borderTopRightRadius: "7px",
    height: '109px',
    flexDirection: "row",
    alignContent: "center",
    justifyItems: "center",
    textAlign: "center",
}

const AddStudentModal = (props) => {
    const [addStudentOpen, setAddStudentOpen] = useState(props.addStudentOpen);
    const [buttonsDisabled, setButtonsDisabled] = useState(true);
    const [checkboxData, setCheckboxData] = useState([]);
    const [tabValue, setTabValue] = useState("StudentDetails");
    let {firstName,
        setFirstName,
        lastName,
        setLastName,
        additionalInfo,
        setAdditionalInfo,
        relationship,
        setRelationship,
        studentId,
        setStudentId,
        grade,
        setGrade,
        term,
        setTerm,
        dob,
        setDob,
        updateStudent,
        isUpdate,
        studentServices,
        setStudentServices,
        setIsUpdate,
        admission,
        setAdmission
    } = props

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleCheckboxChange = (event,label,amount,id) => {
        if (event.target.checked) {
            setStudentServices([...studentServices, {
                name: label,
                amount: amount,
                frequency: "Monthly",
                itemNumber: studentId,
                productId:id
            }]);
        } else {
            setStudentServices(studentServices.filter(service => service.name !== label));
        }
    };


    useEffect(() => {
        let ns = [...studentServices]
        console.log("ns", ns)
    }, [studentServices]);

    useEffect(() => {
        if(firstName
            && firstName !== "Student First Name"
            && lastName
            && lastName !== "Student Last Name"
            && relationship
            && relationship !== "Select Relationship"
            && grade
            && grade !== "Select Grade"
            && term
            && term !== "Select Term"
            && dob
            && dob  !== Date.now()
            && studentId
            && studentId !== "Student ID"
            && admission
            && admission !== Date.now())
        {
            setButtonsDisabled(false)
        }else{
            setTabValue("StudentDetails")
        }

    }, [relationship,grade,term,firstName,lastName,studentId,admission,tabValue]);

    const fetchProducts = async () => {
        const customerDetailsResponse = await fetch(
            baseUrl + `/api/listByProducts?search=Fees`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            }
        );
        if (customerDetailsResponse.status === 200) {
            const customerDetailsData = await customerDetailsResponse.json();
            console.log("init", customerDetailsData.products)
            setCheckboxData(customerDetailsData.products)
        }
    }

    useEffect(()=>{
        fetchProducts()
    },[])

    const addStudents = () => {
        if(firstName
            && firstName !== "Student First Name"
            && lastName
            && lastName !== "Student Last Name"
            && relationship
            && relationship !== "Select Relationship"
            && grade
            && grade !== "Select Grade"
            && term
            && term !== "Select Term"
            && dob
            && dob  !== Date.now()
            && studentId
            && studentId !== "Student ID"
            && admission
            && admission !== Date.now())
        {
            let studentObject = {
                studentId,
                firstName,
                lastName,
                relationship,
                grade,
                term,
                dob,
                admission,
                additionalInfo: additionalInfo !== "Add additional student information" ? additionalInfo : ""
            }
            if(isUpdate){
                updateStudent(studentObject.studentId, studentObject);
                setIsUpdate(false)
                return clearFields()
            }else{
                if(props.studentsToSave.some(student => student.studentId === studentId)){
                    console.error("Student id exists")
                }else{
                    props.setStudentsToSave([...props.studentsToSave, studentObject])
                    return clearFields()
                }
            }
        }else{
            console.error("Fill in the details properly")
        }
    }

    const numberFormat = (value) =>
        new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "KES",
        }).format(value);

    const addMore = async (e) => {
        e.preventDefault();
        if(studentServices.some(service => service.itemNumber === studentId)){
            await addStudents()
            setTabValue("StudentDetails")
        }else{
            console.error("add services")
        }

    }

    const save = async (e) => {
        e.preventDefault();
        if(studentServices.some(service => service.itemNumber === studentId)){
            await addStudents()
            props.onClose()
        }else{
            console.error("add services")
        }
    }

    const clearFields = async () =>{
        setFirstName("Student First Name")
        setLastName("Student Last Name")
        setAdditionalInfo("Add additional student information")
        setRelationship("Select Relationship")
        setGrade("Select Grade")
        setTerm("Select Term")
        setStudentId("Student ID")
        setDob(Date.now())
    }

    const handleDobChange = (newValue) =>{
        setDob(newValue)
    }

    const handleAdmissionChange = (newValue) =>{
        setAdmission(newValue)
    }

    useEffect(() => {
        let sts = [...props.studentsToSave]
        console.log("students to save", sts)
    }, [props.studentsToSave]);

    useEffect(() => {
        setAddStudentOpen(props.addStudentOpen);
    }, [props.addStudentOpen]);

    const [ Fetchedgrades, setFetchedGrades] = useState([])
    const fetchGrades = async () => {
      try {
        HttpComponent({
          method: "GET",
          url: `/api/get_school_grades`,
          token: X_Authorization,
        }).then((data) => {
          console.log('data wewe wewwe', data.response.data);
          setFetchedGrades(data.response.data)
        });
      } catch (error) {
        console.log(error);
      }
    }
  
    useEffect(() => {
      fetchGrades()
    }, [])
    return (
        <Modal open={addStudentOpen}
               // onClose={props.onClose}
               disableEscapeKeyDown={true}
               aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description">
            <Box sx={wholeModal}>
                <div style={headerSection}>
                    <div
                        style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 25,
                            paddingTop:"40px"
                        }}
                    >
                        Add Student
                        <Close onClick={props.onClose} style={{color: "white",
                            position: "absolute",
                            top: "1.25rem",
                            right: "1.25rem",
                            cursor: "pointer"}}/>
                    </div>
                </div>

                <TabContext value={tabValue}>
                    <Box style={{width: "100%"}}>
                        <AntTabs onChange={handleTabChange} variant="fullWidth" scrollButtons={false} aria-span="scrollable prevent tabs example">
                            <AntTab icon={tabValue === "StudentDetails" ? (<CheckCircle style={{color: "#447c69"}}/>) : (<Circle style={{color: "#b4bcc4"}}/>)} iconPosition="start" style={{fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.32", letterSpacing: "normal", color: "black",}} label="Student Details" value="StudentDetails" centered/>
                            <AntTab icon={tabValue !== "Services" ? (<Circle style={{color: "#b4bcc4"}}/>) : (<CheckCircle style={{color: "#447c69"}}/>)} iconPosition="start" style={{fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.32", letterSpacing: "normal", color: "black",}} label="Services" value="Services" centered/>
                        </AntTabs>
                    </Box>

                    {/*---- start student details ----*/}

                    <TabPanel value="StudentDetails">

                        <Grid container>
                            <Grid item style={{width: "100%"}}>
                                <Box sx={{"& .super-app-theme--header": {color: "#032541", fontWeight: "bold", fontSize: "10px", fontFamily: "Poppins"}}}>
                                    <div style={{
                                        display:"flex",
                                        paddingTop:"2.5rem",
                                        padding:"1.25rem",
                                        justifyContent:"space-between",
                                    }}>
                                        <div style={{
                                            display:"flex",
                                            flexDirection:"column",
                                            width:"45%"
                                        }}>
                                            <InputLabel required>Student ID</InputLabel>
                                            <TextField id="outlined-basic" required placeholder={studentId} onChange={(e)=>{setStudentId(e.target.value)}} variant="outlined" style={{width:"100%",height:"3.125rem"}} />
                                            {!studentId
                                                || studentId === "Student ID" && (
                                                    <FormHelperText error>This field is required</FormHelperText>
                                                )}
                                        </div>
                                        <div style={{
                                            width:"45%",
                                            display:"flex", flexDirection:"column"
                                        }}>
                                            <InputLabel required>Student Admission Date</InputLabel>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} style={{}}>
                                                <DesktopDatePicker
                                                    inputFormat="DD/MM/YYYY"
                                                    value={admission}
                                                    required
                                                    onChange={handleAdmissionChange}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                    </div>
                                    <div style={{
                                        display:"flex",
                                        justifyContent:"space-between",
                                        paddingTop:"2.5rem",
                                        padding:"1.25rem",
                                    }}>
                                        <div style={{
                                            display:"flex",
                                            flexDirection:"column",
                                            width:"45%"
                                        }}>
                                            <InputLabel required>Student First Name</InputLabel>
                                            <TextField id="outlined-basic" required placeholder={firstName} onChange={(e)=>{setFirstName(e.target.value)}} variant="outlined" style={{width:"100%", height:"3.125rem"}}/>
                                            {!firstName
                                                || firstName === "Student First Name" && (
                                                <FormHelperText error>This field is required</FormHelperText>
                                            )}
                                        </div>
                                        <div style={{
                                            display:"flex",
                                            flexDirection:"column",
                                            width:"45%"
                                        }}>
                                            <InputLabel required>Student Last Name</InputLabel>
                                            <TextField id="outlined-basic" required placeholder={lastName} onChange={(e)=>{setLastName(e.target.value)}} variant="outlined" style={{width:"100%",height:"3.125rem"}} />
                                            {!lastName
                                                || lastName === "Student Last Name" && (
                                                    <FormHelperText error>This field is required</FormHelperText>
                                                )}
                                        </div>
                                    </div>
                                    <div style={{
                                        display:"flex",
                                        justifyContent:"space-between",
                                        marginTop:"2.5rem",
                                        margin:"1.25rem",
                                    }}>
                                        {/*<TextField id="outlined-basic" required label="Date of Birth" defaultValue="Date of Birth" variant="outlined" style={{width:"45%",height:"3.125rem"}} />*/}
                                        <div style={{display:"flex", flexDirection:"column", width:"45%"}}>
                                            <InputLabel required>Date Of Birth</InputLabel>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DesktopDatePicker
                                                    inputFormat="DD/MM/YYYY"
                                                    value={dob}
                                                    required
                                                    onChange={handleDobChange}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                        <div style={{
                                            height:"3.125rem",
                                            width:"45%",
                                            marginTop:"4%"
                                        }}>

                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={relationship}
                                                label="Relationship"
                                                required
                                                onChange={(e)=>setRelationship(e.target.value)}
                                                style={{
                                                    width:"100%",
                                                }}
                                            >
                                                <MenuItem value={"Select Relationship"} disabled>Select Relationship</MenuItem>
                                                <MenuItem value={"Son"}>Son</MenuItem>
                                                <MenuItem value={"Daughter"}>Daughter</MenuItem>
                                                <MenuItem value={"Niece"}>Niece</MenuItem>
                                                <MenuItem value={"Nephew"}>Nephew</MenuItem>
                                            </Select>

                                        </div>


                                        {/*<TextField id="outlined-basic" required label="Relationship" defaultValue="Relationship" variant="outlined" style={{width:"45%",height:"3.125rem"}}/>*/}
                                    </div>

                                    <div style={{
                                        display:"flex",
                                        justifyContent:"space-between",
                                        paddingTop:"2.5rem",
                                        padding:"1.25rem",
                                    }}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={grade}
                                            label="Grade"
                                            required
                                            onChange={(e)=>setGrade(e.target.value)}
                                            style={{
                                                width:"45%"
                                            }}
                                        >
                                            <MenuItem value={"Select Grade"} disabled>Select Grade</MenuItem>
                                            {Fetchedgrades.map((item) => {
                        return (
                          <MenuItem value={item}>{item}</MenuItem>
                        )
                      })}

                                            {/* <MenuItem value={"PP 1"}>PP 1</MenuItem>
                                            <MenuItem value={"PP 2"}>PP 2</MenuItem>
                                            <MenuItem value={"Grade 1"}>Grade 1</MenuItem>
                                            <MenuItem value={"Grade 2"}>Grade 2</MenuItem>
                                            <MenuItem value={"Grade 3"}>Grade 3</MenuItem>
                                            <MenuItem value={"Grade 4"}>Grade 4</MenuItem>
                                            <MenuItem value={"Grade 5"}>Grade 5</MenuItem>
                                            <MenuItem value={"Grade 6"}>Grade 6</MenuItem>
                                            <MenuItem value={"Grade 7"}>Grade 7</MenuItem>
                                            <MenuItem value={"Grade 8"}>Grade 8</MenuItem>
                                            <MenuItem value={"Grade 9"}>Grade 9</MenuItem>
                                            <MenuItem value={"Grade 10"}>Grade 10</MenuItem>
                                            <MenuItem value={"Grade 11"}>Grade 11</MenuItem>
                                            <MenuItem value={"Grade 12"}>Grade 12</MenuItem> */}
                                        </Select>
                                        {/*<TextField id="outlined-basic" required label="Grade" defaultValue="Grade" variant="outlined" style={{width:"45%",height:"3.125rem"}} />*/}
                                        {/*<TextField id="outlined-basic" required label="Term" defaultValue="Term" variant="outlined" style={{width:"45%",height:"3.125rem"}}/>*/}
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={term}
                                            label="Term"
                                            required
                                            onChange={(e)=>setTerm(e.target.value)}
                                            style={{
                                                width:"45%"
                                            }}
                                        >
                                            <MenuItem value={"Select Term"} disabled>Select Term</MenuItem>
                                            <MenuItem value={"Term 1"}>Term 1</MenuItem>
                                            <MenuItem value={"Term 2"}>Term 2</MenuItem>
                                            <MenuItem value={"Term 3"}>Term 3</MenuItem>
                                        </Select>
                                    </div>

                                    <div style={{
                                        display:"flex",
                                        paddingTop:"2.5rem",
                                        padding:"1.25rem",
                                        flexDirection:"column"
                                    }}>

                                        <span>Additional Info</span>
                                        <TextField id="outlined-basic" placeholder={additionalInfo} onChange={(e)=>{setAdditionalInfo(e.target.value)}} variant="outlined" style={{width:"100%",height:"3.125rem"}} />
                                    </div>

                                </Box>
                            </Grid>
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Grid item>
                                    <Button style={{
                                        border: "solid 1px #0c253f",
                                        color: "#0c253f",
                                        margin:"0.625rem",
                                        height: "45px",
                                        width: "190px"
                                    }} onClick={(e) => props.onClose()}
                                    >
                                        Back
                                    </Button>
                                </Grid>

                                <Grid item>
                                    <Button style={{
                                        backgroundColor: "#032541",
                                        color: "#ffffff",
                                        margin:"0.625rem",
                                        height: "45px",
                                        width: "190px"
                                    }} onClick={(e) => setTabValue("Services")}
                                            disabled={buttonsDisabled}
                                    >
                                        Next
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                    </TabPanel>

                    {/*---- end student details -----*/}

                    {/*---- start services info*/}

                    <TabPanel value="Services">
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", minWidth:"45rem" }}>
                            {checkboxData?.map(data => (
                                <div key={data._id} style={{ display: "flex", justifyContent: "space-between", padding: "1rem" }}>
                                    <FormControlLabel control={<Checkbox onChange={(event)=>handleCheckboxChange(event, data.productName,data.productPrice, data._id)} />} label={data.productName} />
                                    <TextField id="outlined-basic" required label="Amount" defaultValue={`KES ${data.productPrice}`} disabled={true} variant="outlined" style={{ width: "60%", height: "2.188rem" }} />
                                </div>
                            ))}
                            {/*<div style={{ display: "flex", justifyContent: "space-between", padding: "1.875rem" }}>*/}
                            {/*    <FormControlLabel control={<Checkbox onChange={(event)=>handleCheckboxChange(event, "Tuition",25000)}  />} label="Tuition" />*/}
                            {/*    <TextField id="outlined-basic" required label="Amount" defaultValue="KES 25,000" disabled={true} variant="outlined" style={{ width: "60%", height: "2.188rem" }} />*/}
                            {/*</div>*/}

                            {/*<div style={{ display: "flex", justifyContent: "space-between", padding: "1.875rem" }}>*/}
                            {/*    <FormControlLabel control={<Checkbox onChange={(event)=>handleCheckboxChange(event, "Transport",5000)} />} label="Transport" />*/}
                            {/*    <TextField id="outlined-basic" required label="Amount" defaultValue="KES 5,000" disabled={true} variant="outlined" style={{ width: "60%", height: "2.188rem" }} />*/}
                            {/*</div>*/}

                            {/*<div style={{ display: "flex", justifyContent: "space-between", padding: "1.875rem" }}>*/}
                            {/*    <FormControlLabel control={<Checkbox onChange={(event)=>handleCheckboxChange(event, "Lunch",10000)} />} label="Lunch" />*/}
                            {/*    <TextField id="outlined-basic" required label="Amount" defaultValue="KES 10,000" disabled={true} variant="outlined" style={{ width: "60%", height: "2.188rem" }} />*/}
                            {/*</div>*/}

                            {/*<div style={{ display: "flex", justifyContent: "space-between", padding: "1.875rem" }}>*/}
                            {/*    <FormControlLabel control={<Checkbox onChange={(event)=>handleCheckboxChange(event, "Karate Classes",5000)} />} label="Karate Classes" />*/}
                            {/*    <TextField id="outlined-basic" required label="Amount" defaultValue="KES 5,000" disabled={true} variant="outlined" style={{ width: "60%", height: "2.188rem" }} />*/}
                            {/*</div>*/}
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Grid item>
                                    <Button style={{
                                        border: "solid 1px #0c253f",
                                        color: "#0c253f",
                                        margin:"0.625rem",
                                        height: "45px",
                                        width: "190px"
                                    }} onClick={(e) => save(e)}
                                            disabled={buttonsDisabled}
                                    >
                                        Save
                                    </Button>
                                </Grid>

                                <Grid item>
                                    <Button style={{
                                        backgroundColor: "#032541",
                                        color: "#ffffff",
                                        margin:"0.625rem",
                                        height: "45px",
                                        width: "200px"
                                    }} onClick={(e) => addMore(e)}
                                            disabled={buttonsDisabled}
                                    >
                                        Add Another Student
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </TabPanel>

                    {/*---- end services info----*/}

                </TabContext>
            </Box>
        </Modal>
    )
}

export {AddStudentModal}
