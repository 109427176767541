import React, { useEffect, useState } from "react";
import Dash from "../../common/dash";
import {
	DataGrid,
	gridPageCountSelector,
	gridPageSelector,
	useGridApiContext,
	useGridSelector,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import {Breadcrumbs, Button, FormControl, Grid, Modal, Select, Tab} from "@mui/material";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ElIf, If } from "rc-if-else";
import Menu, { MenuProps } from "@mui/material/Menu";
import deactivateGroup from "../../common/images/deactivate.svg";
import deactivate from "../../common/images/deactivate.svg";
import success from "../../common/images/success.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@material-ui/core/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import activateGroup from "../../common/images/activate.svg";
import { SessionModal } from "../Modals/SessionExpiredModal/sessionModal";
import { SuccessModal } from "../Modals/SuccessModal/successModal";
import { QuestionModal } from "../Modals/QuestionModal/questionModal";
import Pagination from "@mui/material/Pagination";
import moment from "moment-timezone";
import {ProductCategoryUploadModal} from "../Modals/Upload/ProductCategoryUploadModal";
import {ProductUploadModal} from "../Modals/Upload/ProductUploadModal";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CustomSearchInput from "../School/CustomSearchInput";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import AddTenantService from "../customerAccounts/tenantComponents/addServiceDetails";



const breadcrumbs = [
	<Typography key="X" sx={{color:"#707070",fontSize:"0.875rem"}}>
		Dashboard
	</Typography>,
	<Typography key={"add"} sx={{color:"#dc3545", fontSize:"0.875rem"}}>
		Products & Services
	</Typography>
];

// session Expired
const sessionStyle = {
	borderRadius: "36px",
	boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
	backgroundColor: "#fff",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "660px",
	height: "570px",
	p: 4,
};

const baseUrl = process.env.REACT_APP_BASE_URL;

// Currency Formatter
const numberFormat = (value) =>
	new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "KES",
	}).format(value);

// Grid Items
const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
		transformOrigin={{
			vertical: "top",
			horizontal: "right",
		}}
		{...props}
	/>
))(({ theme }) => ({
	"& .MuiPaper-root": {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color:
			theme.palette.mode === "light"
				? "rgb(55, 65, 81)"
				: theme.palette.grey[300],
		boxShadow:
			"rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
		"& .MuiMenu-list": { padding: "4px 0" },
		"& .MuiMenuItem-root": {
			"& .MuiSvgIcon-root": {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			"&:active": {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				),
			},
		},
	},
}));

const style = {
	width: "665px",
	height: "900px",
	margin: "20px Auto 0 Auto",
	padding: "0 0 26.8px",
	borderRadius: "15px",
	backgroundColor: "#fff",
	textAlign: "right",
};
const SuccessModalStyle = {
	width: "506px",
	height: "506px",
	margin: "10% Auto 0 Auto",
	padding: "86px 24px 97.7px 61px",
	borderRadius: "36px",
	boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
	backgroundColor: "#fff",
};

// Custom Tab Styling
const AntTabs = styled(TabList)({
	borderBottom: "0px solid #e8e8e8",
	"& .MuiTabs-indicator": {
		backgroundColor: "#ffffff00",
	},
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
	({ theme }) => ({
		minWidth: 0,
		[theme.breakpoints.up("sm")]: {
			minWidth: 0,
		},
		// fontWeight: theme.typography.fontWeightRegular,
		marginRight: theme.spacing(1),
		fontSize: "13px",
		// fontStretch: "normal",
		// fontStyle: "normal",
		lineHeight: "2.75",
		// letterSpacing: "normal",
		textAlign: "left",
		color: "#6e7074",
		fontFamily: ["Poppins"].join(","),
		"&:hover": {
			color: "#032541",
			opacity: 1,
		},
		"&.Mui-selected": {
			color: "#dc3545",
			// fontWeight: 600,
		},
		"&.Mui-focusVisible": {
			backgroundColor: "blue",
		},
	})
);

const AddProductModalstyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	borderRadius: "15px",
	backgroundColor: "#fff",
	width: "665px",
	height: "750px",
};

//Active Columns
const activeColumns = [
	{
		field: "productName",
		headerName: "Product/Service Name",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "productCategory",
		headerName: "Product/Service Category",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
		headerClassName: "super-app-theme--header",
	},
	{
		field: 'priceStatus',
		headerName: 'Product Type',
		flex : 1,
		headerAlign: 'center',
		align : 'center'
	},
	{
		field: "productDescription",
		headerName: "Description",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "productPrice",
		headerName: "Product/Service Price",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
		headerClassName: "super-app-theme--header",
		valueFormatter: (params) => {
			// console.log("params", params);
			return numberFormat(params.value);
		},
	},
	{
		field: "createdAt",
		headerName: "Created At",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
		headerClassName: "super-app-theme--header",
		valueFormatter: (params) => {
			// console.log("params", params);
			return moment(params.value);
		},
	},
];

if (localStorage.getItem("group") === "Merchant") {
	activeColumns.push({
		field: "Actions",
		headerName: "Actions",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
		headerClassName: "super-app-theme--header",
		renderCell: (params, event) => {
			const { row } = params;
			console.log(row);
			// session Modal Config
			const [sessionOpen, setSessionOpen] = React.useState(false);
			const handleSessionOpen = () => setSessionOpen(true);
			const handleSessionClose = () => setSessionOpen(false);
			const handleLogin = () => {
				window.location.href = "/logout";
			};

			//DropDown Handler
			const [anchorEl, setAnchorEl] = React.useState(null);
			const ActionOpen = Boolean(anchorEl);
			const handleClick = (event) => {
				setAnchorEl(event.currentTarget);
			};
			const handleActionClose = () => {
				setAnchorEl(null);
			};

			// Edit  Modal Config
			const [open, setOpen] = useState(false);
			const handleClickOpen = () => setOpen(true);
			const handleClose = () => setOpen(false);

			// Deactivate  Modal Config
			const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
			const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
			const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

			// Success Deactivation Modal Config
			const [successDeactivationModalOpen, setSuccessDeactivationModalOpen] =
				useState(false);
			const successDeactivationModalHandleOpen = () =>
				setSuccessDeactivationModalOpen(true);
			const successDeactivationModalHandleClose = () =>
				setSuccessDeactivationModalOpen(false);

			// Success Edit Modal Control
			const [successOpen, setSuccessOpen] = React.useState(false);
			const handleSuccessOpen = () => setSuccessOpen(true);
			const handleSuccessClose = () => setSuccessOpen(false);

			// Product Categories Creation setters and getters
			// Business Creation Setters and Getters
			const [productName, setProductName] = useState(row.productName);
			const [productCategory, setProductCategory] = useState(
				row.productCategory
			);
			const [productDescription, setProductDescription] = useState(
				row?.productDescription
			);
			const [productPrice, setProductPrice] = useState(row.productPrice);
			const [productBarCode, setProductBarCode] = useState(row.barcode);

			const [createdAt, setCreatedAt] = useState(row.createdAt);


			// product Category setters and Getters
			const [category, setCategory] = useState([]);

			// Getting Product Categories for the DropDown.
			const getProductCategories = async () => {
				try {
					const categoryResponse = await fetch(
						baseUrl + "/api/listCategories/Active",
						{
							method: "GET",
							headers: {
								"Content-Type": "application/json",
								Accept: "application/json",
								"X-Authorization": localStorage.getItem("X-Authorization"),
							},
						}
					);
					if (categoryResponse.status === 200) {
						const categoryData = await categoryResponse.json();
						setCategory(categoryData.data);
					} else if (categoryResponse.status === 401) {
						handleSessionOpen();
					}
				} catch (e) {
					console.log(e);
				}
			};

			useEffect(() => {
				getProductCategories();
			}, []);

			//Category Dropdown Mapping
			const CategoryOptions = category.map((cat) => {
				return {
					categoryName: cat.categoryName,
				};
			});

			//Group Update Url
			const updateUrl = baseUrl + "/api/updateProduct/" + row.id;
			const deleteUrl = baseUrl + "/api/updateProduct/" + row.id;

			//Update Function
			const update = async (e) => {
				e.preventDefault();
				const editResponse = await fetch(updateUrl, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
					body: JSON.stringify({
						productName: productName,
						productCategory: productCategory,
						productDescription: productDescription,
						productPrice: productPrice,
						modifiedAtBy: localStorage.getItem("username"),
						modifiedAt: Date.now(),
						barcode:productBarCode
					}),
				});
				if (editResponse.status === 202) {
					handleClose();
					handleSuccessOpen();
				} else if (editResponse.status === 401) {
					handleSessionOpen();
				}
			};

			// Deactivate Update Function
			const deactivateUpdate = async (e) => {
				e.preventDefault();
				try {
					const deactivateResponse = await fetch(updateUrl, {
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
						body: JSON.stringify({
							productState: "Deactivation",
							requestedBy: localStorage.getItem("username"),
							deactivatedAt: Date.now(),
						}),
					});
					if (deactivateResponse.status === 202) {
						DeactivateModalHandleClose();
						successDeactivationModalHandleOpen();
					} else if (deactivateResponse.status === 401) {
						handleSessionOpen();
					}
				} catch (e) {
					console.log(e);
				}
			};

			return (
				<div>
					{/*session expired modal*/}
					<SessionModal
						onClose={handleSessionClose}
						sessionOpen={sessionOpen}
					/>

					{/*Edit Group Modal*/}
					<Modal
						open={open}
						onClose={handleClose}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<Box sx={AddProductModalstyle}>
							<Box
								sx={{
									width: "665px",
									height: "90px",
									margin: "0 0 9px",
									padding: "35px 30px 34px",
									borderRadius: "16px",
									backgroundColor: "#dc3545",
								}}
							>
								<Typography
									style={{
										width: "260px",
										height: "21px",
										margin: "0 423px 0 0",
										fontFamily: "Poppins",
										fontSize: "15px",
										fontWeight: "normal",
										fontStretch: "normal",
										fontStyle: "normal",
										lineHeight: "normal",
										letterSpacing: "normal",
										textAlign: "left",
										color: "#fff",
									}}
								>
									Add a Product/Service
								</Typography>
							</Box>
							<Box
								sx={{
									width: "605.2px",
									height: "494.5px",
									margin: "18.2px 29.4px 6.2px 30.4px",
									padding: "33.8px 55.6px 0.8px 43.6px",
									backgroundColor: "#f5f7ff",
								}}
							>
								<form onSubmit={update}>
									<FormControl variant="standard">
										<label
											style={{
												width: "107px",
												height: "20px",
												margin: "0 389px 10px 0",
												fontFamily: "Poppins",
												fontSize: "14px",
												fontWeight: "normal",
												fontStretch: "normal",
												fontStyle: "normal",
												lineHeight: "3.14",
												letterSpacing: "normal",
												textAlign: "left",
												color: "#6e7074",
											}}
										>
											Product Name
										</label>
										<input
											required
											style={{
												width: "496px",
												fontFamily: "Poppins",
												height: "39px",
												margin: "10px 0 0",
												objectFit: "contain",
												borderRadius: "4px",
												border: "solid 1px #dfdede",
												backgroundColor: "#fff",
											}}
											type="text"
											id="productName"
											name="productName"
											placeholder="Product/Service Name"
											value={productName}
											onChange={(e) => setProductName(e.target.value)}
										/>
										<label
											style={{
												width: "156px",
												height: "20px",
												margin: "0 389px 10px 0",
												fontFamily: "Poppins",
												fontSize: "14px",
												fontWeight: "normal",
												fontStretch: "normal",
												fontStyle: "normal",
												lineHeight: "3.14",
												letterSpacing: "normal",
												textAlign: "left",
												color: "#6e7074",
											}}
										>
											Product category
										</label>
										<select
											value={productCategory}
											onChange={(e) => setProductCategory(e.target.value)}
											maxLength="25"
											style={{
												width: "496px",
												height: "39px",
												margin: "10px 0 0",
												objectFit: "contain",
												borderRadius: "4px",
												border: "solid 1px #dfdede",
												backgroundColor: "#fff",
												fontFamily: "Poppins",
											}}
											type="text"
										>
											<option value="" style={{ fontFamily: "Poppins" }}>
												Select Category
											</option>
											{CategoryOptions.map((categoryData) => (
												<option category={categoryData._id}>
													{categoryData.categoryName}
												</option>
											))}
										</select>
										<label
											style={{
												width: "107px",
												height: "20px",
												margin: "0 389px 10px 0",
												fontFamily: "Poppins",
												fontSize: "14px",
												fontWeight: "normal",
												fontStretch: "normal",
												fontStyle: "normal",
												lineHeight: "3.14",
												letterSpacing: "normal",
												textAlign: "left",
												color: "#6e7074",
											}}
										>
											Description
										</label>
										<input
											type="text"
											required
											style={{
												width: "496px",
												height: "39px",
												fontFamily: "Poppins",
												margin: "10px 0 0",
												objectFit: "contain",
												borderRadius: "4px",
												border: "solid 1px #dfdede",
												backgroundColor: "#fff",
											}}
											
											id="productName"
											name="productName"
											placeholder="Product Description"
											value={productDescription}
											onChange={(e) => setProductDescription(e.target.value)}
										/>
										<label
											style={{
												width: "107px",
												height: "20px",
												margin: "0 389px 10px 0",
												fontFamily: "Poppins",
												fontSize: "14px",
												fontWeight: "normal",
												fontStretch: "normal",
												fontStyle: "normal",
												lineHeight: "3.14",
												letterSpacing: "normal",
												textAlign: "left",
												color: "#6e7074",
											}}
										>
											Price
										</label>type="text"
										<input
											type="number"
											required
											style={{
												width: "496px",
												height: "39px",
												fontFamily: "Poppins",
												margin: "10px 0 0",
												objectFit: "contain",
												borderRadius: "4px",
												border: "solid 1px #dfdede",
												backgroundColor: "#fff",
											}}
											
											id="productName"
											name="productName"
											placeholder="Product price"
											value={productPrice}
											onChange={(e) => setProductPrice(e.target.value)}
										/>

<label
											style={{
												width: "107px",
												height: "20px",
												margin: "0 389px 10px 0",
												fontFamily: "Poppins",
												fontSize: "14px",
												fontWeight: "normal",
												fontStretch: "normal",
												fontStyle: "normal",
												lineHeight: "3.14",
												letterSpacing: "normal",
												textAlign: "left",
												color: "#6e7074",
											}}
										>
											 Barcode
										</label>

										<input
											type="number"
											// required
											style={{
												width: "496px",
												height: "39px",
												fontFamily: "Poppins",
												margin: "10px 0 0",
												objectFit: "contain",
												borderRadius: "4px",
												border: "solid 1px #dfdede",
												backgroundColor: "#fff",
											}}
											
											id="productBarCode"
											name="productBarCode"
											placeholder="Product Barcode"
											value={productBarCode}
											onChange={(e) => setProductBarCode(e.target.value)}
										/>

										<Grid container spacing={3} sx={{ marginTop: 5 }}>
											<Grid item xs={3}>
												<Button
													type="submit"
													variant="contained"
													color="primary"
													style={{
														alignSelf: "right",
														width: "120px",
														height: "39px",
														margin: "10px 230px 0",
														borderRadius: "4px",
														backgroundColor: "#dc3545",
														fontFamily: "Poppins",
														fontSize: "14px",
														fontWeight: "normal",
														fontStretch: "normal",
														fontStyle: "normal",
														lineHeight: "3.14",
														letterSpacing: "normal",
														textAlign: "left",
														color: "#fff",
													}}
												>
													Save
												</Button>
											</Grid>
											<Grid item xs={3}>
												<Button
													onClick={handleClose}
													variant="contained"
													color="primary"
													style={{
														width: "120px",
														height: "39px",
														margin: "10px 230px 0",
														borderRadius: "4px",
														backgroundColor: "#032541",
														fontFamily: "Poppins",
														fontSize: "14px",
														fontWeight: "normal",
														fontStretch: "normal",
														fontStyle: "normal",
														lineHeight: "3.14",
														letterSpacing: "normal",
														textAlign: "left",
														color: "#fff",
													}}
												>
													Close
												</Button>
											</Grid>
										</Grid>
									</FormControl>
								</form>
							</Box>
						</Box>
					</Modal>

					{/*Deactivate Group Modal*/}
					<QuestionModal
						deactivateModalOpen={deactivateModalOpen}
						onClose={DeactivateModalHandleClose}
						formAction={deactivateUpdate}
						actionImage={deactivateGroup}
						action="Deactivate"
						item={row.productName}
						processName={"Deactivate"}
					/>

					{/*Status Update Modals*/}
					{/*Success Deactivation Modal*/}
					<SuccessModal
						onClose={successDeactivationModalHandleClose}
						editConfigOpen={successDeactivationModalOpen}
						modalRow={row.productName}
						successObject={"Product"}
						successType={"Deactivated"}
					/>

					{/*Success Edit Groups*/}
					<SuccessModal
						onClose={handleSuccessClose}
						editConfigOpen={successOpen}
						modalRow={row.productName}
						successObject={"Product"}
						successType={"Updated"}
					/>

					{/*Action Button Menu*/}
					<Button
						sx={{ backgroundColor: "#dc3545" }}
						id="demo-customized-button"
						aria-controls={ActionOpen ? "demo-customized-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={ActionOpen ? "true" : undefined}
						variant="contained"
						disableElevation
						onClick={handleClick}
						endIcon={<KeyboardArrowDownIcon />}
					>
						Action
					</Button>
					<StyledMenu
						id="demo-customized-menu"
						MenuListProps={{ "aria-labelledby": "demo-customized-button" }}
						anchorEl={anchorEl}
						open={ActionOpen}
						onClose={handleActionClose}
					>
						{/*Conditional Modal Popups*/}
						{/*Check if is on Active Tab*/}
						<MenuItem
							disableRipple
							onClick={() => {
								handleClickOpen();
								handleActionClose();
							}}
						>
							<EditIcon sx={{ color: "black" }} />
							Edit
						</MenuItem>
						<MenuItem
							onClick={() => {
								DeactivateModalHandleOpen();
								handleActionClose();
							}}
							disableRipple
						>
							<PersonRemoveIcon />
							Deactivate
						</MenuItem>
					</StyledMenu>
				</div>
			);
		},
	});
}

// New Columns
const newColumns = [
	// { field: 'id', headerName: 'ID' },
	{
		field: "productName",
		headerName: "Product/Service Name",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "productCategory",
		headerName: "Category Code",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: 'priceStatus',
		headerName: 'Product Type',
		flex : 1,
		headerAlign: 'center',
		align : 'center'
	},
	{
		field: "productDescription",
		headerName: "Product/Service Description",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "productPrice",
		headerName: "Product/Service Price",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "createdAt",
		headerName: "Created At",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "createdBy",
		headerName: "Created By",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},

	// Actions Button
];

if (localStorage.getItem("group") === "Merchant") {
	newColumns.push({
		field: "Actions",
		headerName: "Actions",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
		renderCell: (params, event) => {
			//DropDown Handler
			const [anchorEl, setAnchorEl] = React.useState(null);
			const ActionOpen = Boolean(anchorEl);
			const handleClick = (event) => {
				setAnchorEl(event.currentTarget);
			};
			const handleActionClose = () => {
				setAnchorEl(null);
			};

			const { row } = params;

			// session Modal Config
			const [sessionOpen, setSessionOpen] = React.useState(false);
			const handleSessionOpen = () => setSessionOpen(true);
			const handleSessionClose = () => setSessionOpen(false);
			const handleLogin = () => {
				window.location.href = "/logout";
			};

			// Activate Group Modal Config
			const [ActivateModalOpen, setActivateModalOpen] = useState(false);
			const ActivateModalHandleOpen = () => setActivateModalOpen(true);
			const ActivateModalHandleClose = () => setActivateModalOpen(false);

			// Deactivate Group Modal Config
			const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
			const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
			const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

			// Status Updates Modals
			// Success Activation Modal Config
			const [successActivationModalOpen, setSuccessActivationModalOpen] =
				useState(false);
			const successActivationModalHandleOpen = () =>
				setSuccessActivationModalOpen(true);
			const successActivationModalHandleClose = () =>
				setSuccessActivationModalOpen(false);

			// Success Deactivation Modal Config
			const [successDeactivationModalOpen, setSuccessDeactivationModalOpen] =
				useState(false);
			const successDeactivationModalHandleOpen = () =>
				setSuccessDeactivationModalOpen(true);
			const successDeactivationModalHandleClose = () =>
				setSuccessDeactivationModalOpen(false);

			// Product Categories Creation setters and getters
			// Business Creation Setters and Getters
			const [productName, setProductName] = useState(row.productName);
			const [productCategory, setProductCategory] = useState(
				row.productCategory
			);
			const [productDescription, setProductDescription] = useState(
				row?.productDescription
			);
			const [productPrice, setProductPrice] = useState(row.productPrice);
			const [createdAt, setCreatedAt] = useState(row.createdAt);

			//Group Update Url
			const updateUrl = baseUrl + "/api/updateProduct/" + row.id;

			// Activate Groups Update Function
			const activateUpdate = async (e) => {
				e.preventDefault();
				try {
					const activateResponse = await fetch(updateUrl, {
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
						body: JSON.stringify({
							productState: "Active",
							modifiedAt: Date.now(),
							requestedBy: localStorage.getItem("productCategory"),
						}),
					});
					if (activateResponse.status === 202) {
						ActivateModalHandleClose();
						successActivationModalHandleOpen();
					} else if (activateResponse.status === 401) {
						handleSessionOpen();
					}
				} catch (e) {
					console.log(e);
				}
			};

			// Deactivate Groups Update Function
			const deactivateUpdate = async (e) => {
				e.preventDefault();
				try {
					const deactivateResponse = await fetch(updateUrl, {
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
						body: JSON.stringify({
							productState: "Deactivation",
							requestedBy: localStorage.getItem("username"),
							deactivatedAt: Date.now(),
							approvedBy: localStorage.getItem("productCategory"),
						}),
					});
					if (deactivateResponse.status === 202) {
						DeactivateModalHandleClose();
						successDeactivationModalHandleOpen();
					} else if (deactivateResponse.status === 401) {
						handleSessionOpen();
					}
				} catch (e) {
					console.log(e);
				}
			};

			return (
				<div>
					{/*session expired modal*/}
					<SessionModal
						onClose={handleSessionClose}
						sessionOpen={sessionOpen}
					/>

					{/*Activate Group Modal*/}
					<QuestionModal
						deactivateModalOpen={ActivateModalOpen}
						onClose={ActivateModalHandleClose}
						formAction={activateUpdate}
						actionImage={activateGroup}
						action="Activate"
						item={row.productName}
						processName={"Activate"}
					/>

					{/*Deactivate Group Modal*/}
					<QuestionModal
						deactivateModalOpen={deactivateModalOpen}
						onClose={DeactivateModalHandleClose}
						formAction={deactivateUpdate}
						actionImage={deactivateGroup}
						action="Deactivate"
						item={row.productName}
						processName={"Deactivate"}
					/>

					{/*Status Update Modals*/}
					{/*Success Deactivation Modal*/}
					<SuccessModal
						onClose={successDeactivationModalHandleClose}
						editConfigOpen={successDeactivationModalOpen}
						modalRow={row.productName}
						successObject={"Product"}
						successType={"Deactivated"}
					/>

					{/*Success Activation Modal*/}
					<SuccessModal
						onClose={successActivationModalHandleClose}
						editConfigOpen={successActivationModalOpen}
						modalRow={row.productName}
						successObject={"Product"}
						successType={"Activated"}
					/>

					{/*Action Button Menu*/}
					<Button
						sx={{ backgroundColor: "#dc3545" }}
						id="demo-customized-button"
						aria-controls={ActionOpen ? "demo-customized-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={ActionOpen ? "true" : undefined}
						variant="contained"
						disableElevation
						onClick={handleClick}
						endIcon={<KeyboardArrowDownIcon />}
					>
						Action
					</Button>

					<StyledMenu
						id="demo-customized-menu"
						MenuListProps={{ "aria-labelledby": "demo-customized-button" }}
						anchorEl={anchorEl}
						open={ActionOpen}
						onClose={handleActionClose}
					>
						<MenuItem
							disableRipple
							onClick={() => {
								ActivateModalHandleOpen();
								handleActionClose();
							}}
						>
							<EditIcon sx={{ color: "black" }} />
							Approve
						</MenuItem>
						<MenuItem
							onClick={() => {
								DeactivateModalHandleOpen();
								handleActionClose();
							}}
							disableRipple
						>
							<PersonRemoveIcon />
							Cancel
						</MenuItem>
					</StyledMenu>
				</div>
			);
		},
	});
}

// Edited Columns
const editedColumns = [
	// { field: 'id', headerName: 'ID' },
	{
		field: "productName",
		headerName: "Product/Service Name",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "productCategory",
		headerName: "product/Service Category",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "productDescription",
		headerName: "product/Service Description",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "productPrice",
		headerName: "product/Service Price",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "modifiedBy",
		headerName: "Edited By",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "modifiedAt",
		headerName: "Date Modified",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},

	// Action Button.
];

if (localStorage.getItem("group") === "Merchant") {
	editedColumns.push({
		field: "Actions",
		headerName: "Actions",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
		renderCell: (params, event) => {
			//DropDown Handler
			const [anchorEl, setAnchorEl] = React.useState(null);
			const ActionOpen = Boolean(anchorEl);
			const handleClick = (event) => {
				setAnchorEl(event.currentTarget);
			};
			const handleActionClose = () => {
				setAnchorEl(null);
			};

			const { row } = params;

			// session Modal Config
			const [sessionOpen, setSessionOpen] = React.useState(false);
			const handleSessionOpen = () => setSessionOpen(true);
			const handleSessionClose = () => setSessionOpen(false);
			const handleLogin = () => {
				window.location.href = "/logout";
			};

			// Activate Group Modal Config
			const [ActivateModalOpen, setActivateModalOpen] = useState(false);
			const ActivateModalHandleOpen = () => setActivateModalOpen(true);
			const ActivateModalHandleClose = () => setActivateModalOpen(false);

			// Edit Group Modal Config
			const [open, setOpen] = useState(false);
			const handleClickOpen = () => setOpen(true);
			const handleClose = () => setOpen(false);

			// Deactivate Group Modal Config
			const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
			const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
			const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

			// DeactivateRequest Group Modal Config
			const [deactivateReqModalOpen, setDeactivateReqModalOpen] =
				useState(false);
			const DeactivateReqModalHandleOpen = () =>
				setDeactivateReqModalOpen(true);
			const DeactivateReqModalHandleClose = () =>
				setDeactivateReqModalOpen(false);

			// Delete Group Modal Config
			const [deleteModalOpen, setDeleteModalOpen] = useState(false);
			const DeleteModalHandleOpen = () => setDeleteModalOpen(true);
			const DeleteModalHandleClose = () => setDeleteModalOpen(false);

			// Status Updates Modals
			// Success Activation Modal Config
			const [successActivationModalOpen, setSuccessActivationModalOpen] =
				useState(false);
			const successActivationModalHandleOpen = () =>
				setSuccessActivationModalOpen(true);
			const successActivationModalHandleClose = () =>
				setSuccessActivationModalOpen(false);

			// Success Deactivation Modal Config
			const [successDeactivationModalOpen, setSuccessDeactivationModalOpen] =
				useState(false);
			const successDeactivationModalHandleOpen = () =>
				setSuccessDeactivationModalOpen(true);
			const successDeactivationModalHandleClose = () =>
				setSuccessDeactivationModalOpen(false);

			// Success Deletion Modal Config
			const [successDeletionModalOpen, setSuccessDeletionModalOpen] =
				useState(false);
			const successDeletionModalHandleOpen = () =>
				setSuccessDeletionModalOpen(true);
			const successDeletionModalHandleClose = () =>
				setSuccessDeletionModalOpen(false);

			// Data Getters and Setters
			const [groupName, setGroupName] = useState(row.productCategory);
			const [createdAt, setCreatedAt] = useState(row.createdAt);
			const [productCategory, setProductCategory] = useState(
				row.productCategory
			);
			const [productDescription, setProductDescription] = useState(
				row?.productDescription
			);

			// Product Categories Creation setters and getters
			// Business Creation Setters and Getters
			const [productName, setProductName] = useState(row.productName);
			const [productPrice, setProductPrice] = useState(row.productPrice);

			//Group Update Url
			const updateUrl = baseUrl + "/api/updateProduct/" + row.id;
			const deleteUrl = baseUrl + "/api/deleteBusiness/" + row.id;

			//Update Group Function
			//Update Group Function
			const update = async () => {
				const response = await fetch(updateUrl, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
					body: JSON.stringify({
						groupName: groupName,
						productCategory: productCategory,
						productDescription: productDescription,
						createdAt: createdAt,
						requestedBy: localStorage.getItem("username"),
						modifiedAt: Date.now(),
					}),
				});
				const data = await response.json();
				if (response.status === 202) {
					console.log("Group Updated");
				} else if (response.status === 401) {
					alert("No Token Available");
				} else {
					alert("Error Updating Group");
				}
			};

			// Activate Groups Update Function
			const activateUpdate = async () => {
				try {
					const activateResponse = await fetch(updateUrl, {
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
						body: JSON.stringify({
							productState: "Active",
							modifiedAt: Date.now(),
							requestedBy: localStorage.getItem("productCategory"),
						}),
					});
				} catch (e) {
					console.log(e);
				}
			};

			// Deactivate Groups Update Function
			const deactivateUpdate = async () => {
				try {
					const deactivateResponse = await fetch(updateUrl, {
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
						body: JSON.stringify({
							productState: "Deactivation",
							requestedBy: localStorage.getItem("username"),
							deactivatedAt: Date.now(),
							approvedBy: localStorage.getItem("productCategory"),
						}),
					});
				} catch (e) {
					console.log(e);
				}
			};

			// Deactivate Request Groups Update Function
			const deactivateReqUpdate = async () => {
				try {
					const deactivateResponse = await fetch(updateUrl, {
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
						body: JSON.stringify({
							productState: "Inactive",
							deactivatedAt: Date.now(),
							approvedBy: localStorage.getItem("productCategory"),
						}),
					});
				} catch (e) {
					console.log(e);
				}
			};

			// Delete Groups Update Function
			const deleteUpdate = async () => {
				try {
					const deleteResponse = await fetch(deleteUrl, {
						method: "DELETE",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
					});
				} catch (e) {
					console.log(e);
				}
			};

			return (
				<div>
					{/*sessionModal*/}
					<SessionModal
						onClose={handleSessionClose}
						sessionOpen={sessionOpen}
					/>

					{/*Edit Group Modal*/}
					<Modal
						className="container container-fluid"
						open={open}
						onClose={handleClose}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<div
							style={{
								width: "553px",
								height: "618px",
								margin: "9% auto 0px auto",
								borderRadius: "15px",
								backgroundColor: "#fff",
							}}
						>
							<div
								style={{
									width: "553x",
									height: "90px",
									margin: "0 0 9px",
									padding: "36px 30px 33px",
									borderRadius: "16px",
									backgroundColor: "#dc3545",
								}}
							>
								<label>Edit a Group</label>
							</div>
							{/*Content Group and Form*/}
							<div
								align="center"
								style={{
									width: "404px",
									height: "414.1px",
									margin: "29px 60px 41.9px 70px",
								}}
							>
								<form onSubmit={update}>
									{/*Serial No.*/}
									<label
										style={{
											height: "20px",
											margin: "0 340px 10px 0",
											fontFamily: "Poppins",
											fontSize: "14px",
											fontWeight: "normal",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "3.14",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#6e7074",
										}}
									>
										Serial No.
									</label>
									<input
										value={groupName}
										onChange={(e) => setGroupName(e.target.value)}
										placeholder="Serial"
										style={{
											width: "404px",
											height: "39px",
											margin: "10px 0 0",
											objectFit: "contain",
											borderRadius: "4px",
											border: "solid 1px #dfdede",
											backgroundColor: "#fff",
										}}
									/>

									{/*POS createdAt*/}
									<label
										style={{
											width: "100px",
											height: "20px",
											margin: "0 328px 10px 0",
											fontFamily: "Poppins",
											fontSize: "14px",
											fontWeight: "normal",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "3.14",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#6e7074",
										}}
									>
										POS createdAt.
									</label>
									<input
										value={createdAt}
										onChange={(e) => setCreatedAt(e.target.value)}
										placeholder="createdAt"
										style={{
											width: "404px",
											height: "39px",
											margin: "10px 0 0",
											objectFit: "contain",
											borderRadius: "4px",
											border: "solid 1px #dfdede",
											backgroundColor: "#fff",
										}}
									/>

									{/*PayBill*/}
									<label
										style={{
											width: "110px",
											height: "20px",
											margin: "0 304px 10px 0",
											fontFamily: "Poppins",
											fontSize: "14px",
											fontWeight: "normal",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "3.14",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#6e7074",
										}}
									>
										Pay Bill/Till No.
									</label>
									<input
										value={productCategory}
										onChange={(e) => setProductCategory(e.target.value)}
										placeholder="productCategory"
										style={{
											width: "404px",
											height: "39px",
											margin: "10px 0 0",
											objectFit: "contain",
											borderRadius: "4px",
											border: "solid 1px #dfdede",
											backgroundColor: "#fff",
										}}
									/>

									{/*Description*/}
									<label
										style={{
											width: "78px",
											height: "20px",
											margin: "0 324px 10px 0",
											fontFamily: "Poppins",
											fontSize: "14px",
											fontWeight: "normal",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "3.14",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#6e7074",
										}}
									>
										Description
									</label>
									<input
										value={productDescription}
										onChange={(e) => setProductDescription(e.target.value)}
										placeholder="Description"
										style={{
											width: "404px",
											height: "39px",
											margin: "10px 0 0",
											objectFit: "contain",
											borderRadius: "4px",
											border: "solid 1px #dfdede",
											backgroundColor: "#fff",
										}}
									/>

									{/*Buttons*/}
									<div align="right">
										{/*  Close  */}
										<Button
											onClick={handleClose}
											variant="outlined"
											style={{
												fontFamily: "Poppins",
												fontSize: "11px",
												textAlign: "left",
												color: "#032541",
												width: "90px",
												height: "33.1px",
												margin: "30px 30px 0 0",
												padding: "9px 29px 8.1px 30px",
												border: "solid 1px #032541",
											}}
										>
											Close
										</Button>

										{/*  save  */}
										<Button
											type="submit"
											variant="standard"
											style={{
												color: "#fff",
												fontFamily: "Poppins",
												fontSize: "11px",
												width: "90px",
												height: "33.1px",
												margin: "30px 0 0 30px",
												padding: "9px 32px 8.1px 31px",
												backgroundColor: "#032541",
											}}
										>
											Save
										</Button>
									</div>
								</form>
							</div>
						</div>
					</Modal>

					{/*Activate Group Modal*/}
					<Modal
						centered
						align={"center"}
						className="container container-fluid"
						open={ActivateModalOpen}
						onClose={ActivateModalHandleClose}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<div
							style={{
								width: "553px",
								height: "306px",
								margin: "10% 23px 8px 39px",
								padding: "0 0 57.9px",
								borderRadius: "15px",
								backgroundColor: "#fff",
							}}
						>
							<div
								style={{
									width: "553px",
									height: "90px",
									margin: "0 0 50px",
									padding: "35px 36px",
									borderRadius: "16px",
									backgroundColor: "#dc3545",
								}}
							>
								<label
									style={{
										width: "140px",
										height: "20px",
										margin: "0 325px 0 0",
										fontFamily: "Poppins",
										fontSize: "14px",
										fontWeight: "600",
										fontStretch: "normal",
										fontStyle: "normal",
										lineHeight: "normal",
										letterSpacing: "normal",
										textAlign: "left",
										color: "#fff",
									}}
								>
									Activate Group
								</label>
							</div>
							<div>
								<form onSubmit={activateUpdate}>
									<img
										style={{
											width: "70.4px",
											height: "70.4px",
											objectFit: "contain",
										}}
										src={activateGroup}
										alt={"activate Group"}
									/>
									<label
										style={{
											height: "24px",
											margin: "0 1.1px 0 14px",
											fontFamily: "Poppins",
											fontSize: "17px",
											fontWeight: "500",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "1.47",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#032541",
										}}
									>
										Do you want to Activate <b>{row.productName}?</b>
									</label>
									<div align="right">
										<Button
											onClick={ActivateModalHandleClose}
											variant="outlined"
											style={{
												fontFamily: "Poppins",
												fontSize: "11px",
												textAlign: "left",
												color: "#dc3545",
												width: "90px",
												height: "33.1px",
												margin: "30px 30px 0 0",
												padding: "9px 29px 8.1px 30px",
												border: "solid 1px #dc3545",
											}}
										>
											Cancel
										</Button>
										{/*  save  */}
										<Button
											type="submit"
											variant="standard"
											style={{
												color: "#fff",
												fontFamily: "Poppins",
												fontSize: "11px",
												width: "90px",
												height: "33.1px",
												margin: "30px 16px 0 30px",
												padding: "9px 32px 8.1px 31px",
												backgroundColor: "#032541",
											}}
										>
											Activate
										</Button>
									</div>
								</form>
							</div>
						</div>
					</Modal>

					{/*Deactivate Group Modal*/}
					<Modal
						centered
						align={"center"}
						className="container container-fluid"
						open={deactivateModalOpen}
						onClose={DeactivateModalHandleClose}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<div
							style={{
								width: "553px",
								height: "306px",
								margin: "10% 23px 8px 39px",
								padding: "0 0 57.9px",
								borderRadius: "15px",
								backgroundColor: "#fff",
							}}
						>
							<div
								style={{
									width: "553px",
									height: "90px",
									margin: "0 0 50px",
									padding: "35px 36px",
									borderRadius: "16px",
									backgroundColor: "#dc3545",
								}}
							>
								<label
									style={{
										height: "20px",
										margin: "0 325px 0 0",
										fontFamily: "Poppins",
										fontSize: "14px",
										fontWeight: "600",
										fontStretch: "normal",
										fontStyle: "normal",
										lineHeight: "normal",
										letterSpacing: "normal",
										textAlign: "left",
										color: "#fff",
									}}
								>
									Deactivate Group
								</label>
							</div>
							<div>
								<form onSubmit={deactivateUpdate}>
									<img
										style={{
											width: "70.4px",
											height: "70.4px",
											objectFit: "contain",
										}}
										src={deactivateGroup}
										alt={"activate Group"}
									/>
									<label
										style={{
											height: "24px",
											margin: "0 1.1px 0 14px",
											fontFamily: "Poppins",
											fontSize: "17px",
											fontWeight: "500",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "1.47",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#032541",
										}}
									>
										Do you want to Deactivate <b>{row.productName}?</b>
									</label>
									<div align="right">
										<Button
											onClick={DeactivateModalHandleClose}
											variant="outlined"
											style={{
												fontFamily: "Poppins",
												fontSize: "11px",
												textAlign: "left",
												color: "#dc3545",
												width: "90px",
												height: "33.1px",
												margin: "30px 30px 0 0",
												padding: "9px 29px 8.1px 30px",
												border: "solid 1px #dc3545",
											}}
										>
											Cancel
										</Button>
										{/*  save  */}
										<Button
											type="submit"
											variant="standard"
											style={{
												color: "#fff",
												fontFamily: "Poppins",
												fontSize: "11px",
												width: "90px",
												height: "33.1px",
												margin: "30px 16px 0 30px",
												padding: "9px 32px 8.1px 31px",
												backgroundColor: "#032541",
											}}
										>
											Deactivate
										</Button>
									</div>
								</form>
							</div>
						</div>
					</Modal>

					{/*Deactivation Request Group Modal*/}
					<Modal
						centered
						align={"center"}
						className="container container-fluid"
						open={deactivateReqModalOpen}
						onClose={DeactivateReqModalHandleClose}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<div
							style={{
								width: "665px",
								height: "604px",
								margin: "10% 47px 5px 96px",
								padding: "0 0 9px",
								borderRadius: "15px",
								backgroundColor: "#fff",
							}}
						>
							<div
								style={{
									width: "665px",
									height: "90px",
									margin: "0 0 3px",
									padding: "35px 30px 34px",
									borderRadius: "16px",
									backgroundColor: "#dc3545",
								}}
							>
								<label
									style={{
										height: "20px",
										margin: "0 325px 0 0",
										fontFamily: "Poppins",
										fontSize: "14px",
										fontWeight: "600",
										fontStretch: "normal",
										fontStyle: "normal",
										lineHeight: "normal",
										letterSpacing: "normal",
										textAlign: "left",
										color: "#fff",
									}}
								>
									Deactivate Group Product Category Group
								</label>
							</div>
							<form onSubmit={deactivateReqUpdate}>
								<div>
									<div>
										<label
											style={{
												eight: "44px",
												margin: "48px 106px 37px 84px",
												fontFamily: "Poppins",
												fontSize: "15px",
												fontWeight: "normal",
												fontStretch: "normal",
												fontStyle: "normal",
												lineHeight: "normal",
												letterSpacing: "normal",
												textAlign: "left",
												color: "#032541",
											}}
										>
											To deactivate Group <b>{row.productName}</b>, Select the
											Reason and describe why you are deactivating.
										</label>
									</div>
									<label
										style={{
											width: "41px",
											height: "16px",
											margin: "0px 537px 10px 84px",
											fontFamily: "Poppins",
											fontSize: "11px",
											fontWeight: "normal",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "4",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#6e7074",
										}}
									>
										Reason
									</label>
									<Select
										value={reasonCode}
										onChange={(e) => setReasonCode(e.target.value)}
										defaultValue=""
										id="grouped-select"
										label="Grouping"
										sx={{
											fontFamily: "Poppins",
											fontSize: "12px",
											textAlign: "left",
											width: "496px",
											height: "39px",
											margin: "10px 85px 20px 84px",
											objectFit: "contain",
											borderRadius: "4px",
											border: "solid 1px #dfdede",
											backgroundColor: "#fff",
										}}
									>
										<MenuItem value="">
											<em>None</em>
										</MenuItem>
										{reasonData.map((reason) => (
											<MenuItem
												sx={{
													fontFamily: "Poppins",
													fontSize: "12px",
													textAlign: "left",
												}}
												key={reason.code}
												value={reason.code}
											>
												{reason.code} {reason.name}
											</MenuItem>
										))}
									</Select>
									<label
										style={{
											width: "62px",
											height: "16px",
											margin: "0px 537px 10px 84px",
											fontFamily: "Poppins",
											fontSize: "11px",
											fontWeight: "normal",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "4",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#6e7074",
										}}
									>
										Description
									</label>
									<textarea
										id="w3review"
										name="w3review"
										rows="4"
										cols="50"
										placeholder="Enter Description"
										style={{
											width: "505px",
											height: "106px",
											margin: "10px 76px 43px 84px",
											objectFit: "contain",
											borderRadius: "4px",
											border: "solid 1px #dfdede",
											backgroundColor: "#fff",
										}}
									></textarea>
									<div align="right">
										<Button
											onClick={DeactivateReqModalHandleClose}
											variant="outlined"
											style={{
												fontSize: "11px",
												color: "#032541",
												width: "90px",
												height: "33.1px",
												margin: "0px 30px 62.9px 13px",
												padding: "9px 29px 8.1px 30px",
												border: "solid 1px #032541",
											}}
										>
											Cancel
										</Button>
										{/*  save  */}
										<Button
											type="submit"
											variant="standard"
											style={{
												fontSize: "11px",
												color: "#fff",
												width: "90px",
												height: "33.1px",
												margin: "0px 76px 62.9px 30px",
												padding: "9px 15px 8.1px 15px",
												backgroundColor: "#dc3545",
											}}
										>
											Deactivate
										</Button>
									</div>
								</div>
							</form>
						</div>
					</Modal>

					{/*Delete Group Modal*/}
					<Modal
						centered
						align={"center"}
						className="container container-fluid"
						open={deleteModalOpen}
						onClose={DeleteModalHandleClose}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<div
							style={{
								width: "553px",
								height: "306px",
								margin: "10% 23px 8px 39px",
								padding: "0 0 57.9px",
								borderRadius: "15px",
								backgroundColor: "#fff",
							}}
						>
							<div
								style={{
									width: "553px",
									height: "90px",
									margin: "0 0 50px",
									padding: "35px 36px",
									borderRadius: "16px",
									backgroundColor: "#dc3545",
								}}
							>
								<label
									style={{
										height: "20px",
										margin: "0 325px 0 0",
										fontFamily: "Poppins",
										fontSize: "14px",
										fontWeight: "600",
										fontStretch: "normal",
										fontStyle: "normal",
										lineHeight: "normal",
										letterSpacing: "normal",
										textAlign: "left",
										color: "#fff",
									}}
								>
									Delete Group
								</label>
							</div>
							<div>
								<form onSubmit={deleteUpdate}>
									<img
										style={{
											width: "70.4px",
											height: "70.4px",
											objectFit: "contain",
										}}
										src={deactivateGroup}
										alt={"activate Group"}
									/>
									<label
										style={{
											height: "24px",
											margin: "0 1.1px 0 14px",
											fontFamily: "Poppins",
											fontSize: "17px",
											fontWeight: "500",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "1.47",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#032541",
										}}
									>
										Do you want to delete <b>{row.productName}?</b>
									</label>
									<div align="right">
										<Button
											onClick={DeleteModalHandleClose}
											variant="outlined"
											style={{
												fontFamily: "Poppins",
												fontSize: "11px",
												textAlign: "left",
												color: "#032541",
												width: "90px",
												height: "33.1px",
												margin: "30px 30px 0 0",
												padding: "9px 29px 8.1px 30px",
												border: "solid 1px #032541",
											}}
										>
											Cancel
										</Button>
										{/*  save  */}
										<Button
											type="submit"
											variant="standard"
											style={{
												color: "#fff",
												fontFamily: "Poppins",
												fontSize: "11px",
												width: "90px",
												height: "33.1px",
												margin: "30px 16px 0 30px",
												padding: "9px 32px 8.1px 31px",
												backgroundColor: "#dc3545",
											}}
										>
											Delete
										</Button>
									</div>
								</form>
							</div>
						</div>
					</Modal>

					{/*Status Update Modals*/}

					<SuccessModal
						onClose={successDeactivationModalHandleClose}
						editConfigOpen={successDeactivationModalOpen}
						modalRow={row.productName}
						successObject={"Product"}
						successType={"Deactivated"}
					/>

					{/*Success Deletion Modal*/}
					<SuccessModal
						onClose={successDeactivationModalHandleClose}
						editConfigOpen={successDeactivationModalOpen}
						modalRow={row.productName}
						successObject={"Product"}
						successType={"Deactivated"}
					/>

					{/*Success Activation Modal*/}
					<Modal
						centered
						align={"center"}
						className="container container-fluid"
						open={successActivationModalOpen}
						onClose={successActivationModalHandleClose}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<div
							style={{
								width: "506px",
								height: "506px",
								margin: "10% 16px 23px 45px",
								padding: "87px 75px 92.7px 34px",
								borderRadius: "36px",
								boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
								backgroundColor: "#fff",
							}}
						>
							<img src={success} alt={"success deletion"} />
							<label
								style={{
									width: "356px",
									height: "65px",
									margin: "37.8px 0 0",
									fontFamily: "Poppins",
									fontSize: "25px",
									fontWeight: "normal",
									fontStretch: "normal",
									fontStyle: "normal",
									lineHeight: "1.2",
									letterSpacing: "normal",
									textAlign: "center",
									color: "#6e7074",
								}}
							>
								<b>{row.productName}</b> Activated successfully.
							</label>
							<Button
								onClick={successActivationModalHandleClose}
								style={{
									fontFamily: "Poppins",
									fontSize: "25px",
									fontWeight: "600",
									color: "#fff",
									width: "124.5px",
									height: "52.3px",
									padding: "8px 44.5px 9.3px 35px",
									backgroundColor: "#032541",
								}}
							>
								OK
							</Button>
						</div>
					</Modal>

					{/*Action Button Menu*/}
					<Button
						sx={{ backgroundColor: "#dc3545" }}
						id="demo-customized-button"
						aria-controls={ActionOpen ? "demo-customized-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={ActionOpen ? "true" : undefined}
						variant="contained"
						disableElevation
						onClick={handleClick}
						endIcon={<KeyboardArrowDownIcon />}
					>
						Action
					</Button>

					<StyledMenu
						id="demo-customized-menu"
						MenuListProps={{ "aria-labelledby": "demo-customized-button" }}
						anchorEl={anchorEl}
						open={ActionOpen}
						onClose={handleActionClose}
					>
						{/*Conditional Modal Popups*/}
						{/*Check if is on Active Tab*/}
						<If condition={localStorage.getItem("tabValue") === "Active"}>
							<MenuItem
								disableRipple
								onClick={() => {
									handleClickOpen();
									handleActionClose();
								}}
							>
								<EditIcon sx={{ color: "black" }} />
								Edit
							</MenuItem>
							<MenuItem
								onClick={() => {
									DeactivateModalHandleOpen();
									handleActionClose();
								}}
								disableRipple
							>
								<PersonRemoveIcon />
								Deactivate
							</MenuItem>

							{/*Check if is on New Tab*/}
							<ElIf condition={localStorage.getItem("tabValue") === "New"}>
								<MenuItem
									disableRipple
									onClick={() => {
										ActivateModalHandleOpen();
										handleActionClose();
									}}
								>
									<EditIcon sx={{ color: "black" }} />
									Approve
								</MenuItem>
								<MenuItem
									onClick={() => {
										DeactivateModalHandleOpen();
										handleActionClose();
									}}
									disableRipple
								>
									<PersonRemoveIcon />
									Decline
								</MenuItem>
							</ElIf>

							{/*Check if is on Inactive Tab*/}
							<ElIf condition={localStorage.getItem("tabValue") === "Inactive"}>
								<MenuItem
									disableRipple
									onClick={() => {
										ActivateModalHandleOpen();
										handleActionClose();
									}}
								>
									<EditIcon sx={{ color: "black" }} />
									Activate
								</MenuItem>
								<MenuItem
									onClick={() => {
										DeleteModalHandleOpen();
										handleActionClose();
									}}
									disableRipple
								>
									<PersonRemoveIcon />
									Delete
								</MenuItem>
							</ElIf>

							{/*Check if is on Pending Tab*/}
							<ElIf condition={localStorage.getItem("tabValue") === "Pending"}>
								<MenuItem
									disableRipple
									onClick={() => {
										handleClickOpen();
										handleActionClose();
									}}
								>
									<EditIcon sx={{ color: "black" }} />
									Activate
								</MenuItem>
								<MenuItem
									onClick={() => {
										handleActionClose();
									}}
									disableRipple
								>
									<PersonRemoveIcon />
									Delete
								</MenuItem>
							</ElIf>

							{/*Check if is on Deactivation Tab*/}
							<ElIf
								condition={localStorage.getItem("tabValue") === "Deactivation"}
							>
								<MenuItem
									disableRipple
									onClick={() => {
										DeactivateReqModalHandleOpen();
										handleActionClose();
									}}
								>
									<EditIcon sx={{ color: "black" }} />
									Approve
								</MenuItem>
								<MenuItem
									onClick={() => {
										ActivateModalHandleOpen();
										handleActionClose();
									}}
									disableRipple
								>
									<PersonRemoveIcon />
									Decline
								</MenuItem>
							</ElIf>
						</If>
					</StyledMenu>
				</div>
			);
		},
	});
}

// Deactivation request Columns
const deactivationColumns = [
	{
		field: "productName",
		headerName: "Product/Service Name",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "productCategory",
		headerName: "Product/Service Category",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "productDescription",
		headerName: "Product/Service Description",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "productPrice",
		headerName: "Product/Service Price",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "requestedBy",
		headerName: "Requested By",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "deactivatedAt",
		headerName: "Date Deactivated",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
];

if (localStorage.getItem("group") === "Merchant") {
	deactivationColumns.push({
		field: "Actions",
		headerName: "Actions",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
		renderCell: (params, event) => {
			//DropDown Handler
			const [anchorEl, setAnchorEl] = React.useState(null);
			const ActionOpen = Boolean(anchorEl);
			const handleClick = (event) => {
				setAnchorEl(event.currentTarget);
			};
			const handleActionClose = () => {
				setAnchorEl(null);
			};

			const { row } = params;

			// session Modal Config
			const [sessionOpen, setSessionOpen] = React.useState(false);
			const handleSessionOpen = () => setSessionOpen(true);
			const handleSessionClose = () => setSessionOpen(false);
			const handleLogin = () => {
				window.location.href = "/logout";
			};

			// Activate Group Modal Config
			const [ActivateModalOpen, setActivateModalOpen] = useState(false);
			const ActivateModalHandleOpen = () => setActivateModalOpen(true);
			const ActivateModalHandleClose = () => setActivateModalOpen(false);

			// Deactivate Group Modal Config
			const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
			const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
			const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

			// DeactivateRequest Group Modal Config
			const [deactivateReqModalOpen, setDeactivateReqModalOpen] =
				useState(false);
			const DeactivateReqModalHandleOpen = () =>
				setDeactivateReqModalOpen(true);
			const DeactivateReqModalHandleClose = () =>
				setDeactivateReqModalOpen(false);

			// Status Updates Modals
			// Success Activation Modal Config
			const [successActivationModalOpen, setSuccessActivationModalOpen] =
				useState(false);
			const successActivationModalHandleOpen = () =>
				setSuccessActivationModalOpen(true);
			const successActivationModalHandleClose = () =>
				setSuccessActivationModalOpen(false);

			// Success Deactivation Modal Config
			const [successDeactivationModalOpen, setSuccessDeactivationModalOpen] =
				useState(false);
			const successDeactivationModalHandleOpen = () =>
				setSuccessDeactivationModalOpen(true);
			const successDeactivationModalHandleClose = () =>
				setSuccessDeactivationModalOpen(false);

			const [productCategory, setProductCategory] = useState(
				row.productCategory
			);
			const [productDescription, setProductDescription] = useState(
				row?.productDescription
			);

			// Product Categories Creation setters and getters
			// Business Creation Setters and Getters
			const [productName, setProductName] = useState(row.productName);

			//Group Update Url
			const updateUrl = baseUrl + "/api/updateProduct/" + row.id;

			//value Setters and Getters
			const [reasonData, setReasonData] = useState([]);
			const [reasonCode, setReasonCode] = useState("");
			const [reasonDescription, setReasonDescription] = useState("");

			//Reason Update Url
			const reasonUrl = baseUrl + "/api/reasons";

			// Getting Reason Codes
			const getReasonCodes = async () => {
				try {
					const response = await fetch(reasonUrl, {
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
					});
					const data = await response.json();
					if (response.status === 200) {
						setReasonData(data.reasons);
					} else if (response.status === 401) {
						handleSessionOpen();
					}
				} catch (e) {
					console.log(e);
				}
			};
			useEffect(() => {
				getReasonCodes();
			}, []);

			// Activate Groups Update Function
			const activateUpdate = async (e) => {
				e.preventDefault();
				try {
					const activateResponse = await fetch(updateUrl, {
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
						body: JSON.stringify({
							productState: "Active",
							modifiedAt: Date.now(),
							requestedBy: localStorage.getItem("productCategory"),
						}),
					});
					if (activateResponse.status === 202) {
						ActivateModalHandleClose();
						successActivationModalHandleOpen();
					} else if (activateResponse.status === 401) {
						handleSessionOpen();
					}
				} catch (e) {
					console.log(e);
				}
			};

			// Deactivate Groups Update Function
			const deactivateUpdate = async (e) => {
				e.preventDefault();
				try {
					const deactivateResponse = await fetch(updateUrl, {
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
						body: JSON.stringify({
							productState: "Deactivation",
							requestedBy: localStorage.getItem("username"),
							deactivatedAt: Date.now(),
							approvedBy: localStorage.getItem("productCategory"),
						}),
					});
					if (deactivateResponse.status === 202) {
						DeactivateModalHandleClose();
						successDeactivationModalHandleOpen();
					} else if (deactivateResponse.status === 401) {
						handleSessionOpen();
					}
				} catch (e) {
					console.log(e);
				}
			};

			// Deactivate Request Groups Update Function
			const deactivateReqUpdate = async (e) => {
				e.preventDefault();
				try {
					const deactivateResponse = await fetch(updateUrl, {
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
						body: JSON.stringify({
							productState: "Inactive",
							deactivationCode: reasonCode,
							deactivationDescription: reasonDescription,
							deactivatedAt: Date.now(),
							approvedBy: localStorage.getItem("productCategory"),
						}),
					});
					if (deactivateResponse.status === 202) {
						DeactivateReqModalHandleClose();
						successDeactivationModalHandleOpen();
					} else if (deactivateResponse.status === 401) {
						handleSessionOpen();
					}
				} catch (e) {
					console.log(e);
				}
			};

			return (
				<div>
					{/*sessionModal*/}
					<SessionModal
						onClose={handleSessionClose}
						sessionOpen={sessionOpen}
					/>

					{/*Activate Group Modal*/}
					<QuestionModal
						deactivateModalOpen={ActivateModalOpen}
						onClose={ActivateModalHandleClose}
						formAction={activateUpdate}
						actionImage={activateGroup}
						action="Activate"
						item={row.productName}
						processName={"Activate"}
					/>

					{/*Deactivate Group Modal*/}
					<QuestionModal
						deactivateModalOpen={deactivateModalOpen}
						onClose={DeactivateModalHandleClose}
						formAction={deactivateUpdate}
						actionImage={deactivateGroup}
						action="Deactivate"
						item={row.productName}
						processName={"Deactivate"}
					/>

					{/*Deactivation Request Group Modal*/}
					<Modal
						centered
						align={"center"}
						className="container container-fluid"
						open={deactivateReqModalOpen}
						onClose={DeactivateReqModalHandleClose}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<div
							style={{
								width: "665px",
								height: "604px",
								margin: "10% 47px 5px 96px",
								padding: "0 0 9px",
								borderRadius: "15px",
								backgroundColor: "#fff",
							}}
						>
							<div
								style={{
									width: "665px",
									height: "90px",
									margin: "0 0 3px",
									padding: "35px 30px 34px",
									borderRadius: "16px",
									backgroundColor: "#dc3545",
								}}
							>
								<label
									style={{
										height: "20px",
										margin: "0 325px 0 0",
										fontFamily: "Poppins",
										fontSize: "14px",
										fontWeight: "600",
										fontStretch: "normal",
										fontStyle: "normal",
										lineHeight: "normal",
										letterSpacing: "normal",
										textAlign: "left",
										color: "#fff",
									}}
								>
									Deactivate Group Product Category Group
								</label>
							</div>
							<form onSubmit={deactivateReqUpdate}>
								<div>
									<div>
										<label
											style={{
												eight: "44px",
												margin: "48px 106px 37px 84px",
												fontFamily: "Poppins",
												fontSize: "15px",
												fontWeight: "normal",
												fontStretch: "normal",
												fontStyle: "normal",
												lineHeight: "normal",
												letterSpacing: "normal",
												textAlign: "left",
												color: "#032541",
											}}
										>
											To deactivate Group <b>{row.productName}</b>, Select the
											Reason and describe why you are deactivating.
										</label>
									</div>
									<label
										style={{
											width: "41px",
											height: "16px",
											margin: "0px 537px 10px 84px",
											fontFamily: "Poppins",
											fontSize: "11px",
											fontWeight: "normal",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "4",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#6e7074",
										}}
									>
										Reason
									</label>
									<Select
										value={reasonCode}
										onChange={(e) => setReasonCode(e.target.value)}
										defaultValue=""
										id="grouped-select"
										label="Grouping"
										sx={{
											fontFamily: "Poppins",
											fontSize: "12px",
											textAlign: "left",
											width: "496px",
											height: "39px",
											margin: "10px 85px 20px 84px",
											objectFit: "contain",
											borderRadius: "4px",
											border: "solid 1px #dfdede",
											backgroundColor: "#fff",
										}}
									>
										<MenuItem value="">
											<em>None</em>
										</MenuItem>
										{reasonData.map((reason) => (
											<MenuItem
												sx={{
													fontFamily: "Poppins",
													fontSize: "12px",
													textAlign: "left",
												}}
												key={reason.code}
												value={reason.code}
											>
												{reason.code} {reason.name}
											</MenuItem>
										))}
									</Select>
									<label
										style={{
											width: "62px",
											height: "16px",
											margin: "0px 537px 10px 84px",
											fontFamily: "Poppins",
											fontSize: "11px",
											fontWeight: "normal",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "4",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#6e7074",
										}}
									>
										Description
									</label>
									<textarea
										value={reasonDescription}
										onChange={(e) => setReasonDescription(e.target.value)}
										id="w3review"
										name="w3review"
										rows="4"
										cols="50"
										placeholder="Enter Description"
										style={{
											padding: "10px",
											fontFamily: "Poppins",
											fontSize: "12px",
											textAlign: "left",
											width: "505px",
											height: "106px",
											margin: "10px 76px 43px 84px",
											objectFit: "contain",
											borderRadius: "4px",
											border: "solid 1px #dfdede",
											backgroundColor: "#fff",
										}}
									></textarea>
									<div align="right">
										<Button
											onClick={DeactivateReqModalHandleClose}
											variant="outlined"
											style={{
												fontSize: "11px",
												color: "#032541",
												width: "90px",
												height: "33.1px",
												margin: "0px 30px 62.9px 13px",
												padding: "9px 29px 8.1px 30px",
												border: "solid 1px #032541",
											}}
										>
											Cancel
										</Button>
										{/*  save  */}
										<Button
											type="submit"
											variant="standard"
											style={{
												fontSize: "11px",
												color: "#fff",
												width: "90px",
												height: "33.1px",
												margin: "0px 76px 62.9px 30px",
												padding: "9px 15px 8.1px 15px",
												backgroundColor: "#dc3545",
											}}
										>
											Deactivate
										</Button>
									</div>
								</div>
							</form>
						</div>
					</Modal>

					{/*Status Update Modals*/}
					{/*Success Deactivation Modal*/}
					<SuccessModal
						onClose={successDeactivationModalHandleClose}
						editConfigOpen={successDeactivationModalOpen}
						modalRow={row.productName}
						successObject={"Product"}
						successType={"Deactivated"}
					/>

					{/*Success Activation Modal*/}
					<SuccessModal
						onClose={successActivationModalHandleClose}
						editConfigOpen={successActivationModalOpen}
						modalRow={row.productName}
						successObject={"Product"}
						successType={"Activated"}
					/>

					{/*Action Button Menu*/}
					<Button
						sx={{ backgroundColor: "#dc3545" }}
						id="demo-customized-button"
						aria-controls={ActionOpen ? "demo-customized-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={ActionOpen ? "true" : undefined}
						variant="contained"
						disableElevation
						onClick={handleClick}
						endIcon={<KeyboardArrowDownIcon />}
					>
						Action
					</Button>
					<StyledMenu
						id="demo-customized-menu"
						MenuListProps={{ "aria-labelledby": "demo-customized-button" }}
						anchorEl={anchorEl}
						open={ActionOpen}
						onClose={handleActionClose}
					>
						<MenuItem
							disableRipple
							onClick={() => {
								DeactivateReqModalHandleOpen();
								handleActionClose();
							}}
						>
							<EditIcon sx={{ color: "black" }} />
							Deactivate
						</MenuItem>
						<MenuItem
							onClick={() => {
								ActivateModalHandleOpen();
								handleActionClose();
							}}
							disableRipple
						>
							<PersonRemoveIcon />
							Cancel
						</MenuItem>
					</StyledMenu>
				</div>
			);
		},
	});
}

// Inactive Columns
const inactiveColumns = [
	// { field: 'id', headerName: 'ID' },
	{
		field: "productName",
		headerName: "Product/Service Name",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "productCategory",
		headerName: "product/Service Category",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "productDescription",
		headerName: "product/Service Description",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "productPrice",
		headerName: "product/Service Price",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "deactivatedBy",
		headerName: "Requested By",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "requestedBy",
		headerName: "Requested By",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "deactivatedAt",
		headerName: "Date Deactivated",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "approvedBy",
		headerName: "Approved By",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
];

if (localStorage.getItem("group") === "Merchant") {
	inactiveColumns.push({
		field: "Actions",
		headerName: "Actions",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
		renderCell: (params, event) => {
			//DropDown Handler
			const [anchorEl, setAnchorEl] = React.useState(null);
			const ActionOpen = Boolean(anchorEl);
			const handleClick = (event) => {
				setAnchorEl(event.currentTarget);
			};
			const handleActionClose = () => {
				setAnchorEl(null);
			};

			const { row } = params;

			// session Modal Config
			const [sessionOpen, setSessionOpen] = React.useState(false);
			const handleSessionOpen = () => setSessionOpen(true);
			const handleSessionClose = () => setSessionOpen(false);
			const handleLogin = () => {
				window.location.href = "/logout";
			};

			// Activate Group Modal Config
			const [ActivateModalOpen, setActivateModalOpen] = useState(false);
			const ActivateModalHandleOpen = () => setActivateModalOpen(true);
			const ActivateModalHandleClose = () => setActivateModalOpen(false);

			// Delete Group Modal Config
			const [deleteModalOpen, setDeleteModalOpen] = useState(false);
			const DeleteModalHandleOpen = () => setDeleteModalOpen(true);
			const DeleteModalHandleClose = () => setDeleteModalOpen(false);

			// Status Updates Modals
			// Success Activation Modal Config
			const [successActivationModalOpen, setSuccessActivationModalOpen] =
				useState(false);
			const successActivationModalHandleOpen = () =>
				setSuccessActivationModalOpen(true);
			const successActivationModalHandleClose = () =>
				setSuccessActivationModalOpen(false);

			// Success Deletion Modal Config
			const [successDeletionModalOpen, setSuccessDeletionModalOpen] =
				useState(false);
			const successDeletionModalHandleOpen = () =>
				setSuccessDeletionModalOpen(true);
			const successDeletionModalHandleClose = () =>
				setSuccessDeletionModalOpen(false);

			const [productName, setProductName] = useState(row.productName);
			const [productCategory, setProductCategory] = useState(
				row.productCategory
			);
			const [productDescription, setProductDescription] = useState(
				row?.productDescription
			);

			//Group Update Url
			const updateUrl = baseUrl + "/api/updateProduct/" + row.id;
			const deleteUrl = baseUrl + "/api/deleteBusiness/" + row.id;

			// Activate Groups Update Function
			const activateUpdate = async (e) => {
				e.preventDefault();
				try {
					const activateResponse = await fetch(updateUrl, {
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
						body: JSON.stringify({
							productState: "Active",
							modifiedAt: Date.now(),
							requestedBy: localStorage.getItem("productCategory"),
						}),
					});
					if (activateResponse.status === 202) {
						ActivateModalHandleClose();
						successActivationModalHandleOpen();
					} else if (activateResponse.status === 401) {
						handleSessionOpen();
					}
				} catch (e) {
					console.log(e);
				}
			};

			// Delete Groups Update Function
			const deleteUpdate = async (e) => {
				e.preventDefault();
				try {
					const deleteResponse = await fetch(deleteUrl, {
						method: "DELETE",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
					});
					if (deleteResponse.status === 202) {
						DeleteModalHandleClose();
						successDeletionModalHandleOpen();
					} else if (deleteResponse.status === 401) {
						handleSessionOpen();
					}
				} catch (e) {
					console.log(e);
				}
			};

			return (
				<div>
					{/*sessionModal*/}
					<SessionModal
						onClose={handleSessionClose}
						sessionOpen={sessionOpen}
					/>

					{/*Activate Group Modal*/}
					<QuestionModal
						deactivateModalOpen={ActivateModalOpen}
						onClose={ActivateModalHandleClose}
						formAction={activateUpdate}
						actionImage={activateGroup}
						action="Activate"
						item={row.productName}
						processName={"Activate"}
					/>

					{/*Delete Group Modal*/}
					<QuestionModal
						deactivateModalOpen={deleteModalOpen}
						onClose={DeleteModalHandleClose}
						formAction={deleteUpdate}
						actionImage={deactivateGroup}
						action="Delete"
						item={row.productName}
						processName={"Delete"}
					/>

					{/*Success Deletion Modal*/}
					<SuccessModal
						onClose={successDeletionModalHandleClose}
						editConfigOpen={successDeletionModalOpen}
						modalRow={row.productName}
						successObject={"Product"}
						successType={"Deleted"}
					/>

					{/*Success Activation Modal*/}
					<SuccessModal
						onClose={successActivationModalHandleClose}
						editConfigOpen={successActivationModalOpen}
						modalRow={row.productName}
						successObject={"Product"}
						successType={"Activated"}
					/>

					{/*Action Button Menu*/}
					<Button
						sx={{ backgroundColor: "#dc3545" }}
						id="demo-customized-button"
						aria-controls={ActionOpen ? "demo-customized-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={ActionOpen ? "true" : undefined}
						variant="contained"
						disableElevation
						onClick={handleClick}
						endIcon={<KeyboardArrowDownIcon />}
					>
						Action
					</Button>
					<StyledMenu
						id="demo-customized-menu"
						MenuListProps={{ "aria-labelledby": "demo-customized-button" }}
						anchorEl={anchorEl}
						open={ActionOpen}
						onClose={handleActionClose}
					>
						{/*Check if is on Inactive Tab*/}
						<MenuItem
							disableRipple
							onClick={() => {
								ActivateModalHandleOpen();
								handleActionClose();
							}}
						>
							<EditIcon sx={{ color: "black" }} />
							Activate
						</MenuItem>
						<MenuItem
							onClick={() => {
								DeleteModalHandleOpen();
								handleActionClose();
							}}
							disableRipple
						>
							<PersonRemoveIcon />
							Delete
						</MenuItem>
					</StyledMenu>
				</div>
			);
		},
	});
}

const Product = (props) => {
	// session Modal Config
	let { businessCat } = useSelector((store) => store.businessCategory);
	if(!businessCat) businessCat = localStorage.getItem("businessCategory");
	const userGroup = localStorage.getItem('group')
	const [sessionOpen, setSessionOpen] = React.useState(false);
	const [productModalOpen, setProductModalOpen] = useState(false)
	const handleProductClose = () => {
		setProductModalOpen(false);
	}
	const handleSessionOpen = () => setSessionOpen(true);
	const handleSessionClose = () => setSessionOpen(false);
	const handleLogin = () => {
		window.location.href = "/logout";
	};

	// handle tab Changes
	const [tabValue, setTabValue] = React.useState("Active");
	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	// Modal
	const [open, setOpen] = React.useState(false);
	const navigate = useNavigate()
	const handleOpen = () => {
		if(businessCat == "School"){
			navigate("/school/services/add")
		}else{
			setOpen(true)
		}

	};

	function newAddservice () {
		props.AddTenantService()
	}

	const handleClose = () => setOpen(false);

	// Success Modal Control
	const [successOpen, setSuccessOpen] = React.useState(false);
	const handleSuccessOpen = () => setSuccessOpen(true);
	const handleSuccessClose = () => setSuccessOpen(false);

	// Tabs Setters and Getters
	const [activeTab, setActiveTabs] = useState([]);
	const [newTab, setNewTabs] = useState([]);
	const [inactiveTab, setInactiveTab] = useState([]);
	const [pendingTab, setPendingTab] = useState([]);
	const [deactivationTab, setDeactivationTabs] = useState([]);

	// Existing terminal Modal config
	const [existingUserModal, setExistingUserModalOpen] = useState(false);
	const existingUserModalHandleOpen = () => setExistingUserModalOpen(true);
	const existingUserModalHandleClose = () => setExistingUserModalOpen(false);

	// Product Setters and Getters
	const [productName, setProductName] = useState("");
	const [productCategory, setProductCategory] = useState("");
	const [productDescription, setProductDescription] = useState("");
	const [productPrice, setProductPrice] = useState(0);
	const [productBarCode, setProductBarCode] = useState("");


	// product Category setters and Getters
	const [category, setCategory] = useState([]);
	const  [productType, setProductType] = useState([])
	const [firstLoad, setFirstLoad] = useState(true);

	// Urls
	const fetchUrl = baseUrl + "/api/listProducts";
	const postUrl = baseUrl + "/api/createProduct";

	// page size setter and getter
	const [pageSize, setPageSize] = React.useState(10);

	//Filtering and Search
	const [searchValue, setSearchValue] = useState("");
	const [filteredData, setFilteredData] = useState([]);

	const [pType, setPtype] = useState('')
	const [pageState, setPageState] = useState({
		isLoading: false,
		data: [],
		total: 0,
		page: 1,
		pageSize: 10,
		// search : searchValue
	});
	const [addService, setAddService] = useState(false)

	// Check if Tab is on Active
	if (tabValue === "Active") {
		// Fetch Active Tabs
		const ActiveTab = async () => {
			try {
				// console.log("ON");
				setPageState((old) => ({ ...old, isLoading: true }));
				const ActiveResponse = await fetch(
					fetchUrl +
					`/Active/?page=${pageState.page}&limit=${pageState.pageSize}&search=${searchValue}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
					}
				);
				const data = await ActiveResponse.json().then((response) => {

					setActiveTabs(response.data);
					setPageState((old) => ({
						...old,
						isLoading: false,
						data: response.data,
						total: response.count,
					}));
				});
				if (ActiveResponse.status === 200) {
					// setActiveTabs(data.data)
					localStorage.setItem("tabValue", "Active");
				} else if (ActiveResponse.status === 401) {
					handleSessionOpen();
				}
			} catch (e) {
				console.log(e);
			}
		};
		useEffect(() => {
			ActiveTab();
		}, [tabValue, pageState.page, pageState.pageSize, searchValue]);
	}

	// Check if tab is on New
	else if (tabValue === "New") {
		// Fetch New Tabs yet to be Approved
		const NewTabs = async () => {
			try {
				const newResponse = await fetch(fetchUrl +
					`/New/?page=${pageState.page}&limit=${pageState.pageSize}&search=${searchValue}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
					});
				console.log(">>>>>>>>>>>>>>NR",newResponse)

				const data = await newResponse.json();
				console.log(">>>>>>>>>>>>>> data",data)
				if (newResponse.status === 200) {
					console.log(">>>>>>>>>>>>>> data200",data)

					setNewTabs(data.data);
					localStorage.setItem("tabValue", "New");
				} else if (newResponse.status === 401) {
					handleSessionOpen();
				}
			} catch (e) {
				console.log(e);
			}
		};
		useEffect(() => {
			NewTabs();
		}, [tabValue]);
	}

	// Check if tab is on Pending
	else if (tabValue === "Pending") {
		// Fetch Edits Approval Tabs
		const PendingTabs = async () => {
			try {
				const inactiveResponse = await fetch(fetchUrl +
					`/Pending/?page=${pageState.page}&limit=${pageState.pageSize}&search=${searchValue}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
					});
				const data = await inactiveResponse.json();
				if (inactiveResponse.status === 200) {
					setPendingTab(data.data);
					localStorage.setItem("tabValue", "Pending");
				} else if (inactiveResponse.status === 401) {
					handleSessionOpen();
				}
			} catch (e) {
				console.log(e);
			}
		};
		useEffect(() => {
			PendingTabs();
		}, [tabValue]);
	}

	// Check if tab is on Deactivation
	else if (tabValue === "Deactivation") {
		// Fetch Pending Deactivation Tabs
		const DeactivationTabs = async () => {
			try {
				const inactiveResponse = await fetch(fetchUrl +
					`/Deactivation/?page=${pageState.page}&limit=${pageState.pageSize}&search=${searchValue}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
					});
				const data = await inactiveResponse.json();
				if (inactiveResponse.status === 200) {
					setDeactivationTabs(data.data);
					localStorage.setItem("tabValue", "Deactivation");
				} else if (inactiveResponse.status === 401) {
					handleSessionOpen();
				}
			} catch (e) {
				console.log(e);
			}
		};
		useEffect(() => {
			DeactivationTabs();
		}, [tabValue]);
	}

	// Check if tab is on Inactive
	else if (tabValue === "Inactive") {
		// Fetch Deactivated Tabs
		const InactiveTabs = async () => {
			try {
				const inactiveResponse = await fetch(fetchUrl +
					`/Inactive/?page=${pageState.page}&limit=${pageState.pageSize}&search=${searchValue}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
					});
				const data = await inactiveResponse.json();
				if (inactiveResponse.status === 200) {
					setInactiveTab(data.data);
					localStorage.setItem("tabValue", "Inactive");
				} else if (inactiveResponse.status === 401) {
					handleSessionOpen();
				}
			} catch (e) {
				console.log(e);
			}
		};
		useEffect(() => {
			InactiveTabs();
		}, [tabValue]);
	}

	// Create Product Request
	const createProduct = async (e) => {
		e.preventDefault();
		try {
			const response = await fetch(postUrl, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					"X-Authorization": localStorage.getItem("X-Authorization"),
				},
				body: JSON.stringify({
					productName: productName,
					productCategory: productCategory,
					productDescription: productDescription,
					productPrice: productPrice,
					priceStatus : pType,
					barcode:productBarCode
				}),
			});
			const data = await response.json();
			console.log(data);
			if (data.Status === "SUCCESS") {
				setProductName("");
				setProductCategory("");
				setProductDescription("");
				setProductPrice("");
				setProductBarCode("")
				handleClose();
				handleSuccessOpen();
			} else if (response.status === 401) {
				handleSessionOpen();
			} else if (data.message === "Product already exists") {
				handleClose();
				existingUserModalHandleOpen();
			}
		} catch (e) {
			console.log(e);
		}
	};

	// Getting Product Categories for the DropDown.
	const getProductCategories = async () => {
		try {
			const categoryResponse = await fetch(
				baseUrl + "/api/listCategories/Active",
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
				}
			);
			if (categoryResponse.status === 200) {
				const categoryData = await categoryResponse.json();
				setCategory(categoryData.data);
			} else if (categoryResponse.status === 401) {
				handleSessionOpen();
			}
		} catch (e) {
			console.log(e);
		}
	};

	// Fetch Product type
	const fetchProductTypes = async() => {
		const response = await fetch(baseUrl + '/api/getVariablePriceStatus', {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				"X-Authorization": localStorage.getItem("X-Authorization"),
			},
		});
		await response.json()
			.then((data) => {setProductType(data.data)})
			.catch((e) => {console.log('Error getting product types', e.message)})
	}

	useEffect(() => {
		getProductCategories()
			.then(() => {console.log(`Found Product Categories`)})
			.catch((e) => {console.log(`Error on getting Product Categories`, e.message)})
		fetchProductTypes()
			.then(() => {console.log(`Found Product Types`)})
			.catch((e) => {console.log(`Error on getting Product types`, e.message)})
	}, []);

	//Category Dropdown Mapping
	const CategoryOptions = category.map((cat) => {
		return {
			categoryName: cat.categoryName,
		};
	});

	// Active Tabs Row Data
	const activeRowData = activeTab.map((activeTabMap) => {
		return {
			id: activeTabMap._id,
			productName: activeTabMap?.productName,
			productCategory: activeTabMap?.productCategory,
			productDescription: activeTabMap?.productDescription,
			priceStatus : activeTabMap?.priceStatus,
			productPrice: activeTabMap?.productPrice,
			createdAt: activeTabMap?.createdAt,
			barcode:activeTabMap?.barcode
		};
	});

	// New Tabs Row Data
	const newRowData = newTab.map((newTabMap) => {
		return {
			id: newTabMap._id,
			productName: newTabMap?.productName,
			productCategory: newTabMap?.productCategory,
			productDescription: newTabMap?.productDescription,
			productPrice: numberFormat(newTabMap?.productPrice),
			priceStatus : newTabMap?.priceStatus,
			createdAt: newTabMap?.createdAt,
			createdBy: newTabMap?.createdBy,
		};
	});

	// Inactive Tabs Row Data
	const inactiveRowData = inactiveTab.map((inactiveTabMap) => {
		return {
			id: inactiveTabMap._id,
			productName: inactiveTabMap?.productName,
			productCategory: inactiveTabMap?.productCategory,
			productDescription: inactiveTabMap?.productDescription,
			productPrice: numberFormat(inactiveTabMap?.productPrice),
			createdAt: inactiveTabMap?.createdAt,
			deactivatedAt: inactiveTabMap?.deactivatedAt,
			requestedBy: inactiveTabMap?.requestedBy,
			approvedBy: inactiveTabMap?.approvedBy,
		};
	});

	// Pending Tabs Row Data
	const pendingRowData = pendingTab.map((pendingTabMap) => {
		return {
			id: pendingTabMap?._id,
			productName: pendingTabMap?.productName,
			productCategory: pendingTabMap?.productCategory,
			productDescription: pendingTabMap?.productDescription,
			productPrice: numberFormat(pendingTabMap?.productPrice),
			createdAt: pendingTabMap?.createdAt,
			modifiedAt: pendingTabMap?.modifiedAt,
			modifiedBy: pendingTabMap?.modifiedBy,
		};
	});

	// Deactivation Tabs Row Data
	const deactivationRowData = deactivationTab.map((deactivateTabMap) => {
		return {
			id: deactivateTabMap._id,
			productName: deactivateTabMap?.productName,
			productCategory: deactivateTabMap?.productCategory,
			productDescription: deactivateTabMap?.productDescription,
			productPrice: numberFormat(deactivateTabMap?.productPrice),
			createdAt: deactivateTabMap?.createdAt,
			deactivatedAt: deactivateTabMap?.deactivatedAt,
			requestedBy: deactivateTabMap?.requestedBy,
		};
	});

	// Hiding elements  handler
	const [showButton, setShowButton] = useState(true);
	const handleButtonVisibility = () => {
		if (localStorage.group.getItem === "Admin") {
			setShowButton(false);
		}
	};

	useEffect(() => {
		let filteredData = activeRowData;

		setFilteredData(activeRowData);
		filteredData = filterBySearchBox(filteredData);

		// console.log("uE", filteredData)
	}, [activeTab, searchValue]);

	useEffect(() => {
		let filteredData = activeRowData;
		filteredData = filterBySearchBox(filteredData);

		setFilteredData(filteredData);
	}, [tabValue, searchValue]);

	const handleSearchBox = (event) => {
		setSearchValue(event.target.value);
	};

	const filterBySearchBox = (data) => {
		return data.filter((item) => {
			if (
				item?.productName?.toLowerCase().includes(searchValue?.toLowerCase()) ||
				item?.productCategory?.toLowerCase().includes(searchValue?.toLowerCase()) ||
				item?.productDescription?.toLowerCase().includes(searchValue?.toLowerCase())
			) {
				return item;
			}
		});
	};

	const isRental = (userGroup === 'Merchant' && businessCat === 'Rental')

	function handleAddService() {
		setAddService(true)
	}

	return (
		<div style={{ fontFamily: "Poppins" }}>
			<div>
				<div>
					{addService ? <AddTenantService isFromProducts={true} doneSaving={() => {setAddService(false)}} /> :
					<div>
						<Box>
							<Box component="div" sx={{marginLeft:2}}>
								<Box component="div" >
									<Typography sx={{color:"#032541", fontSize:"1.563rem", fontWeight:700}}>
										Products & Services
									</Typography>
								</Box>
								<Box component="div" sx={{}}>
									<Breadcrumbs
										separator={<FiberManualRecordIcon sx={{fontSize:"0.625rem", fontFamily : 'Poppins', color:"#e1e5e8"}} />}
										aria-label="breadcrumb">
										{breadcrumbs}
									</Breadcrumbs>
								</Box>
							</Box>
							<Grid container columns={1}>
								<Grid item xs={0.9} md={0.85}></Grid>
								{localStorage.getItem("group") !== "Admin" ? (
									<Grid container justifyContent={"flex-end"}>
										<Grid item>
											<Button
												style={{
													color: "#032541",
													border:"1px solid #032541",
													marginRight:5,
													fontSize:"10px",
													width: "136px",
													height: "37px",
													fontWeight:700

												}}
												onClick={() => setProductModalOpen(true)}
												startIcon={<UploadFileIcon/>}
											>
												Upload File
											</Button>
										</Grid>
										<Grid item>

											<Button sx={{
													fontFamily: "Poppins", fontSize: "10px", fontWeight: "500", color: "#f5f5f5", width: "136px", height: "37px",
													backgroundColor: "#032541", '&:hover': {backgroundColor:"#032541", color: '#f5f5f5'}}} onClick={() => {isRental ? handleAddService() : handleOpen()}} startIcon={<AddIcon />}>
												{businessCat == "School" || businessCat == "Rental" ? "Add Service" :"Add Product"}
											</Button>

										</Grid>
									</Grid>

								) : (
									""
								)}
							</Grid>

							{/*Search*/}
							<Grid container columns={1} sx={{ marginTop: "22px" }}>
								<Grid item xs={0.9} md={0.755}></Grid>
								<Grid item>

									{/*<label*/}
									<Box sx={{marginLeft:2}}>
										<CustomSearchInput name={"search"} value={searchValue} placeholder={"Search:"} onChange={handleSearchBox}/>
									</Box>

								</Grid>
							</Grid>

							{/*Tabbed Data Grid*/}
							<Box sx={{
									"& .super-app-theme--header": {
										color: "#032541",
										fontWeight: "600",
										fontSize: "10px",
									},
								}}>
								<TabContext value={tabValue}>
									<Box>
										<AntTabs
											onChange={handleTabChange}
											aria-label="Product Category tabs"
											TabIndicatorProps={{ hidden: false }}
											textColor="primary"
										>
											<AntTab label="Active Product/Service " value="Active" />
											<AntTab label="New Product/Service " value="New" />
											<AntTab label="Edited Product/Service " value="Pending" />
											<AntTab
												label="Deactivation request"
												value="Deactivation"
											/>
											<AntTab
												label="Inactive Product/Service "
												value="Inactive"
											/>
										</AntTabs>
									</Box>

									<TabPanel value="Active">
										<DataGrid
											style={{
												minHeight: "500px",
												width: "100%",
												overflow: "visible",
											}}
											autoHeight
											//   rows={filteredData}
											//   columns={activeColumns}
											//   pageSize={pageSize}
											//   onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
											//   rowsPerPageOptions={[5, 10, 20, 50, 100]}
											//   pagination
											//   sx={{fontFamily : 'Poppins' , fontSize : '10px' , color : '#272d3b'}}>
											// localeText={{footerRowSelected: CustomPagination}}
											rows={filteredData}
											rowCount={pageState.total}
											loading={pageState.isLoading}
											rowsPerPageOptions={[5, 10, 20, 50, 100]}
											pagination
											page={pageState.page - 1}
											pageSize={pageState.pageSize}
											paginationMode="server"
											onPageChange={(newPage) => {
												setPageState((old) => ({
													...old,
													page: newPage + 1,
													pageSize: pageSize,
												}));
											}}
											onPageSizeChange={(newPageSize) =>
												setPageSize(newPageSize)
											}
											columns={activeColumns}
											getRowId={(row) => row.id}
											sx={{
												fontFamily: "Poppins",
												fontSize: "10px",
												color: "#272d3b",
											}}
										></DataGrid>
									</TabPanel>

									<TabPanel value="New">
										<DataGrid
											style={{ height: "500px", width: "auto" }}
											rows={newRowData}
											columns={newColumns}
											pageSize={pageSize}
											onPageSizeChange={(newPageSize) =>
												setPageSize(newPageSize)
											}
											rowsPerPageOptions={[5, 10, 20, 50, 100]}
											pagination
											sx={{
												fontFamily: "Poppins",
												fontSize: "10px",
												color: "#272d3b",
											}}
										></DataGrid>
									</TabPanel>

									<TabPanel value="Pending">
										<DataGrid
											style={{ height: "500px", width: "auto" }}
											rows={pendingRowData}
											columns={editedColumns}
											pageSize={pageSize}
											onPageSizeChange={(newPageSize) =>
												setPageSize(newPageSize)
											}
											rowsPerPageOptions={[5, 10, 20, 50, 100]}
											pagination
											sx={{
												fontFamily: "Poppins",
												fontSize: "10px",
												color: "#272d3b",
											}}
										></DataGrid>
									</TabPanel>

									<TabPanel value="Deactivation">
										<DataGrid
											style={{ height: "500px", width: "auto" }}
											rows={deactivationRowData}
											columns={deactivationColumns}
											pageSize={pageSize}
											onPageSizeChange={(newPageSize) =>
												setPageSize(newPageSize)
											}
											rowsPerPageOptions={[5, 10, 20, 50, 100]}
											pagination
											sx={{
												fontFamily: "Poppins",
												fontSize: "10px",
												color: "#272d3b",
											}}
										></DataGrid>
									</TabPanel>

									<TabPanel value="Inactive">
										<DataGrid
											style={{ height: "500px", width: "auto" }}
											rows={inactiveRowData}
											columns={inactiveColumns}
											pageSize={pageSize}
											onPageSizeChange={(newPageSize) =>
												setPageSize(newPageSize)
											}
											rowsPerPageOptions={[5, 10, 20, 50, 100]}
											pagination
											sx={{
												fontFamily: "Poppins",
												fontSize: "10px",
												color: "#272d3b",
											}}
										></DataGrid>
									</TabPanel>
								</TabContext>
							</Box>
						</Box>

						{/*group addition success modal*/}
						<SuccessModal onClose={handleSuccessClose} editConfigOpen={successOpen} modalRow={productName === null ? "New!" : productName} successObject={"Product"} successType={"Created"}/>

						{/*sessionModal*/}
						<SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen}/>

						{/*Existing user Modal*/}
						<Modal centered align={"center"} className="container container-fluid" open={existingUserModal} onClose={existingUserModalHandleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
							<div
								style={{
									width: "506px",
									height: "506px",
									margin: "10% 16px 23px 45px",
									padding: "87px 75px 92.7px 34px",
									borderRadius: "36px",
									boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
									backgroundColor: "#fff",
								}}
							>
								<img src={deactivate} alt={"success deactivation"} />
								<label
									style={{
										width: "356px",
										height: "65px",
										margin: "37.8px 0 0",
										fontFamily: "Poppins",
										fontSize: "25px",
										fontWeight: "normal",
										fontStretch: "normal",
										fontStyle: "normal",
										lineHeight: "1.2",
										letterSpacing: "normal",
										textAlign: "center",
										color: "#6e7074",
									}}
								>
									Product is Existing!
								</label>
								<Button
									onClick={() => {
										existingUserModalHandleClose();
										window.location.reload(true);
									}}
									style={{
										fontFamily: "Poppins",
										fontSize: "25px",
										fontWeight: "600",
										color: "#fff",
										width: "124.5px",
										height: "52.3px",
										padding: "8px 44.5px 9.3px 35px",
										backgroundColor: "#032541",
									}}
								>
									OK
								</Button>
							</div>
						</Modal>

						{/*<div style={{height : '100%'}}>*/}
						<Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
							<Box sx={AddProductModalstyle}>
								<Box
									sx={{
										width: "665px",
										height: "90px",
										margin: "0 0 9px",
										padding: "35px 30px 34px",
										borderRadius: "16px",
										backgroundColor: "#dc3545",
									}}
								>
									<Typography
										style={{
											width: "260px",
											height: "21px",
											margin: "0 423px 0 0",
											fontFamily: "Poppins",
											fontSize: "15px",
											fontWeight: "normal",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "normal",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#fff",
										}}
									>
										Add a Product/Service
									</Typography>
								</Box>
								<Box
									sx={{
										width: "605.2px",
										height: "510.5px",
										margin: "18.2px 29.4px 6.2px 30.4px",
										padding: "33.8px 55.6px 0.8px 43.6px",
										backgroundColor: "#f5f7ff",
									}}
								>
									<form onSubmit={createProduct}>
										<FormControl variant="standard">
											<label
												style={{
													width: "107px",
													height: "20px",
													margin: "0 389px 10px 0",
													fontFamily: "Poppins",
													fontSize: "14px",
													fontWeight: "normal",
													fontStretch: "normal",
													fontStyle: "normal",
													lineHeight: "3.14",
													letterSpacing: "normal",
													textAlign: "left",
													color: "#6e7074",
												}}
											>
												Product Name
											</label>
											<input
												required
												style={{
													width: "496px",
													fontFamily: "Poppins",
													height: "39px",
													margin: "10px 0 0",
													objectFit: "contain",
													borderRadius: "4px",
													border: "solid 1px #dfdede",
													backgroundColor: "#fff",
												}}
												type="text"
												id="productName"
												name="productName"
												placeholder="Product/Service Name"
												value={productName}
												onChange={(e) => setProductName(e.target.value)}
											/>
											<label style={{width: "156px", height: "20px", margin: "0 389px 10px 0", fontFamily: "Poppins", fontSize: "14px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: "3.14", letterSpacing: "normal", textAlign: "left", color: "#6e7074",}}>
												Product category
											</label>
											<select value={productCategory} onChange={(e) => setProductCategory(e.target.value)} maxLength="25" style={{width: "496px", height: "39px", margin: "10px 0 0", objectFit: "contain", borderRadius: "4px", border: "solid 1px #dfdede", backgroundColor: "#fff", fontFamily: "Poppins", required: true}} type="text">
												<option value="" style={{ fontFamily: "Poppins" }}>
													Select Category
												</option>
												{CategoryOptions.map((categoryData) => (
													<option category={categoryData._id}>
														{categoryData.categoryName}
													</option>
												))}
											</select>

											<label style={{width: "156px", height: "20px", margin: "0 389px 10px 0", fontFamily: "Poppins", fontSize: "14px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: "3.14", letterSpacing: "normal", textAlign: "left", color: "#6e7074",}}>
												Product Type
											</label>
											<select value={pType} onChange={(e) => setPtype(e.target.value)} maxLength="25" style={{width: "496px", height: "39px", margin: "10px 0 0", objectFit: "contain", borderRadius: "4px", border: "solid 1px #dfdede", backgroundColor: "#fff", fontFamily: "Poppins", required: true}} type="text">
												<option value="" style={{ fontFamily: "Poppins" }}>
													Select Type
												</option>
												{productType.map((data) => (
													<option key={data._id}>
														{data.priceStatusName}
													</option>
												))}
											</select>

											<label
												style={{
													width: "107px",
													height: "20px",
													margin: "0 389px 10px 0",
													fontFamily: "Poppins",
													fontSize: "14px",
													fontWeight: "normal",
													fontStretch: "normal",
													fontStyle: "normal",
													lineHeight: "3.14",
													letterSpacing: "normal",
													textAlign: "left",
													color: "#6e7074",
												}}
											>
												Description
											</label>
											<input
												type="text"
												required
												style={{
													width: "496px",
													height: "39px",
													fontFamily: "Poppins",
													margin: "10px 0 0",
													objectFit: "contain",
													borderRadius: "4px",
													border: "solid 1px #dfdede",
													backgroundColor: "#fff",
												}}
												id="productName"
												name="productName"
												placeholder="Product Description"
												value={productDescription}
												onChange={(e) => setProductDescription(e.target.value)}
											/>
											<label
												style={{
													width: "107px",
													height: "20px",
													margin: "0 389px 10px 0",
													fontFamily: "Poppins",
													fontSize: "14px",
													fontWeight: "normal",
													fontStretch: "normal",
													fontStyle: "normal",
													lineHeight: "3.14",
													letterSpacing: "normal",
													textAlign: "left",
													color: "#6e7074",
												}}
											>
												Price
											</label>
											<input
												type="number"
												required
												style={{
													width: "496px",
													height: "39px",
													fontFamily: "Poppins",
													margin: "10px 0 0",
													objectFit: "contain",
													borderRadius: "4px",
													border: "solid 1px #dfdede",
													backgroundColor: "#fff",
												}}
												id="productName"
												name="productName"
												placeholder="Product price"
												value={productPrice}
												onChange={(e) => setProductPrice(e.target.value)}
											/>

<label
												style={{
													width: "107px",
													height: "20px",
													margin: "0 389px 10px 0",
													fontFamily: "Poppins",
													fontSize: "14px",
													fontWeight: "normal",
													fontStretch: "normal",
													fontStyle: "normal",
													lineHeight: "3.14",
													letterSpacing: "normal",
													textAlign: "left",
													color: "#6e7074",
												}}
											>
												 Barcode
											</label>

											<input
												type="number"
												required
												style={{
													width: "496px",
													height: "39px",
													fontFamily: "Poppins",
													margin: "10px 0 0",
													objectFit: "contain",
													borderRadius: "4px",
													border: "solid 1px #dfdede",
													backgroundColor: "#fff",
												}}
												id="productBarCode"
												name="productName"
												placeholder="Product Barcode"
												value={productBarCode}
												onChange={(e) => setProductBarCode(e.target.value)}
											/>
											<Grid container spacing={3} sx={{ marginTop: 0 }}>
												<Grid item xs={3}>
													<Button
														type="submit"
														variant="contained"
														color="primary"
														style={{
															alignSelf: "right",
															width: "120px",
															height: "39px",
															margin: "10px 230px 0",
															borderRadius: "4px",
															backgroundColor: "#dc3545",
															fontFamily: "Poppins",
															fontSize: "14px",
															fontWeight: "normal",
															fontStretch: "normal",
															fontStyle: "normal",
															lineHeight: "3.14",
															letterSpacing: "normal",
															textAlign: "left",
															color: "#fff",
														}}
													>
														Save
													</Button>
												</Grid>
												<Grid item xs={3}>
													<Button
														onClick={handleClose}
														variant="contained"
														color="primary"
														style={{
															width: "120px",
															height: "39px",
															margin: "10px 230px 0",
															borderRadius: "4px",
															backgroundColor: "#032541",
															fontFamily: "Poppins",
															fontSize: "14px",
															fontWeight: "normal",
															fontStretch: "normal",
															fontStyle: "normal",
															lineHeight: "3.14",
															letterSpacing: "normal",
															textAlign: "left",
															color: "#fff",
														}}
													>
														Close
													</Button>
												</Grid>
											</Grid>
										</FormControl>
									</form>
								</Box>
							</Box>
						</Modal>

						{/*</div>*/}
					</div>
					}
				</div>
			</div>
			<ProductUploadModal productOpen={productModalOpen} onClose={handleProductClose}/>
		</div>
	);
};
export default Product;
