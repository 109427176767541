import React, { useState, useEffect} from "react";
import { DataGrid } from "@mui/x-data-grid";

const CustomDataGrid = (props) => {

  const column = props?.column
  const row = props?.row
  const customStyles = props?.customStyles
  // DataGrid Pagination

  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], total: 0, page: 1, pageSize: dataGridPageSize });
  
  return (
      <DataGrid
        sx={customStyles ? customStyles :{height:'400px', width:"100%", margin:"0% 0% 1% 0%"}}
        rows={row}
        columns={column}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        rowCount={props?.pageState?.count}
        loading={props?.pageState.isLoading}
        pagination
        page={props?.pageState.page - 1}
        pageSize={props?.dataGridPageSize}
        paginationMode="server"
        onPageChange={(newPage) => {
          props?.setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize }));
        }}
        onPageSizeChange={(newPageSize) => props?.setPageSize(newPageSize)}
        disableRowSelectionOnClick={true}
        onSelectionModelChange={(ids) => {
        }}
      />
  );
};

export default CustomDataGrid;
