import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import lodash from 'lodash'
import { Grid, Select,ListSubheader,TextField, InputAdornment } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import SearchIcon from "@mui/icons-material/Search";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import NativeSelect from "@mui/material/NativeSelect";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import moment from "moment";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import { handleTransactionDate } from "../../features/transactionDateSlice";
import { handleBranchDetails } from "../../features/branchSlice";
import { changeToken } from "../../features/userSlice";
import { handlePageReload } from "../../features/autorefreshonpageload";
import { handleBusinessCategory } from "../../features/businessCategorySlice";
import jsondecode from "jwt-decode";
import { logInUser, logoutUser } from "../../features/userSlice";
import { handleBusinessBranches } from "../../features/businessBranchesSlice";
import { reversePageLoadState } from "../../features/autorefreshonpageload";



// image imports
import todaySales from "../../common/images/todaySales.svg";
import totalSales from "../../common/images/sales-icon.svg";
import totalPOS from "../../common/images/pos-icon.svg";
import totalInvoice from "../../common/images/invoice-icon.svg";
import totalBranches from "../../common/images/branches-icon1.svg";
import totalTrans from '../../common/images/trans-icon.svg'
import netSale from "../../common/images/netSale.svg";
import House from "../../common/images/bar.svg";

//Calender Imports
import "./Calender/DateRangePicker.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Calender/Calendar.css";
import { LandLordDash } from "../customerAccounts/landLordComponents/landlordDash";
import TenantDashBoard from "../customerAccounts/tenantComponents/tenantDash";
import Button from "@mui/material/Button";
import { AddCircleOutline, AssignmentTurnedInOutlined, SyncAlt } from "@material-ui/icons";
import Carousel from "nuka-carousel";
import { AddKitchen } from "./modals/addKitchen";
import { AddBar } from "./modals/addBar";
import CustomerAccountDash from "../customerAccounts/customerAccountDash";
import { StudentDash } from "../customerAccounts/studentDash";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import { Line } from 'rc-progress';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { makeStyles } from '@material-ui/core/styles';
import CustomSelectField from "../School/CustomSelectField";
import CustomSearchInput from "../School/CustomSearchInput";
import HttpComponent from "../School/MakeRequest";
// import { useSelector, useDispatch } from "react-redux";
import CustomTable from "../School/CustomTable";
import ExportMenu from "../School/ExportMenu";
import UnpaidPartialInvoices from "./UnpaidPartialInvoices";
import PaidInvoices from "./PaidInvoices";
import SavedInvoices from "../customerAccounts/customerComponents/savedInvoice";
import { token } from "stylis";
import { customerstyles } from "../../components/customerAccounts/customerComponents/styles";
import SearchFilter from "../../components/customerAccounts/customerComponents/searchFilter";
import { CustomDate } from "../../components/customerAccounts/customerComponents/customDate";
import { ErrorAlertRightAligned } from "../bills/startorderModals/modals";



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  selected: {
    backgroundColor: 'red', // Replace with your desired background color
  },
}));




// Base Url
const baseUrl = process.env.REACT_APP_BASE_URL;

// Number Format for Currency
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);

// Add Date to sort Calendar
const addDate = (value) => {
  return new Date(value.replace(/-/g, "/"));
};
function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

//Date Processing
const todayDate = new Date();
const day = todayDate.getDate();
const month = todayDate.getMonth() + 1;
const year = todayDate.getFullYear();
const fullDate = year + "-" + month + "-" + day;


export default function DashBoard(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { X_Authorization, defaultBusinessId } = useSelector((store) => store.user);
  const { branchId } = useSelector((store) => store.branch);
  const { branchesArr } = useSelector((store) => store.businessBranches);
  const [selectedBusinessName, setSelectedBusinessName] = useState("");
  const [customerBusinessData, setCustomerBusinessData] = useState([])
  // const [transactionData, setTransactionData] = useState([]);
  // let { businessCat } = useSelector((store) => store.businessCategory);
  const  businessCat = localStorage.getItem("businessCategory");
  const  group = localStorage.getItem("group");

  useEffect(() => {
    if(group && group === "Cashier"){
      navigate('/orders/startorder')
    }else if(group && group?.toLocaleLowerCase()?.includes("storemanager")){
      navigate("/stock");
    }else if(group && group === "Supervisor"){
      navigate("/orders/startorder")
    }
    else if(group && group?.toLocaleLowerCase()?.includes("storeoperator") ){
      navigate("/orders/startorder")
    }
    else{
      navigate('/dashboard')
    }
  },[group])
  // < ------------- NEW DASHBOARD UI LOGIN--------------->
  // state
  const [searchItem, setSearchItem] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [fileHeaders, setFileHeaders] = useState([]);
  const [csvColumns, setCsvColumns] = useState([]);
  const[dataToShow, setDataToShow] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [dateFrom, setDateFrom] = React.useState(null);
  const [dateTo, setDateTo] = React.useState(null);
  const [businessTransactionsByDate, setBusinessTransactionsByDate] = useState([]);
  const [totalBusinessTransactionData, setTotalBusinessTransactionData] = useState([]);
  const [businessBranchStoreData, setBusinessBranchStoreData] = useState({totalSales:{}, branch:{}});

  // < ------------- END OF NEW DASHBOARD UI LOGIN--------------->


  let barManState = localStorage.getItem("barMan") === "true";
  const { isPageLoaded } = useSelector((store) => store.pageload);

  // // run auto refresh

  // const handleForceUpdate = useForceUpdate();
  const autoRefreshOnPageload = () => {
    setTimeout(() => {
      window.location.reload(false);
    }, 1000);
    // handleForceUpdate()
    dispatch(handlePageReload());
  };
  useEffect(() => {
    isPageLoaded === false && branchesArr.length > 0 ? autoRefreshOnPageload() : null;
  }, [branchesArr.length > 0, group, businessCat]);
  

  // console.log("BRANCH ARRAY LENGHT =========> ", branchesArr.length);
  // Accounting Stuff
  const { search, state } = useLocation();
  const { fromLogin } = state || false;
  const [accountingSuccessModal, setAccountingSuccessModal] = useState(false);
  const [accountingErrorModal, setAccountingErrorModal] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyId, setCompanyId] = useState("");

  useEffect(() => {
    const name = new URLSearchParams(search).get("cN");
    const realmId = new URLSearchParams(search).get("rI");
    const state = new URLSearchParams(search).get("state");

    if (state === "success" && name && realmId) {
      setCompanyName(name);
      setCompanyId(realmId);
      setAccountingSuccessModal(true);
    } else if (state === "error") {
      setAccountingSuccessModal(false);
      setAccountingErrorModal(true);
    }
  }, []);

  // calender setter and Getter config
  const calendarDateRef = useRef(new Date());
  const [totals, setTotals] = useState(0);
  const [dailyExpenses, setDailyExpenses] = useState(0);
  const [calenderData, setCalenderData] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  const[businessTransactions, setBusinessTransactions] = useState([]);
  const[branchTransactions, setBranchTransactions] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [cashiers, setCashiers] = useState([]);
  const [topSellers, setTopSellers] = useState([]);
  const [branches, setBranches] = useState([]);
  const [color, setColor] = useState('');
  const [status, setStatus] = useState('');
  const [activeSubstores, setActiveSubstores] = useState([]);
  const [businessBranchSummary, setBusinessBranchSummary] = useState({totalSales:{},branch:{}});
  const [branchName, setBranchName] = useState(localStorage.getItem("branch"));
  const [posData, setPOSData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [businessSellers, setBusinessSellers] = useState([]);

  // Bar Setters and Getters
  const [barOrKitchen, setBarOrKitchen] = useState("");
  const [barsAndKitchens, setBarsAndKitchen] = useState([]);

  //dropDown functions
  const [dropDown, setDropDown] = useState("7days");
  const [branchDropDown, setBranchDropDown] = useState("allBranches");
  const [transactionOption, setTransactionOption] = useState('ALL');
  const [dValue, onDValueChange] = useState([moment(addDays(new Date(), -7)).format("YYYY-MM-DD"), moment(addDays(new Date(), 1)).format("YYYY-MM-DD")]);

  //const dropDown open state
  const [isOpen, setIsOpen] = useState(false);

  // Open Kitchen handler
  const [openAddKitchen, setOpenAddKitchen] = useState(false);
  const handleOpenKitchen = () => setOpenAddKitchen(true);
  const handleCloseKitchen = () => {
    setOpenAddKitchen(false);
  };

  // Open Bar handler
  const [openAddBar, setOpenAddBar] = useState(false);
  const handleOpenAddBar = () => setOpenAddBar(true);
  const handleCloseAddBar = () => {
    setOpenAddBar(false);
  };

  // Fetch Total transactions per day
  const fetchTotals = async (token = localStorage.getItem("X-Authorization")) => {
    try {
      const response = await fetch(baseUrl + "/api/getTotals", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": token,
        },
      });
      const data = await response.json();
      if (response.status === 200) {
        setCalenderData(data.totals);
        for (const dayTotal of data.totals) {
          const dayDate = addDate(dayTotal._id.Time);
          const newDate = addDate(fullDate);
          if (dayDate.toDateString() === newDate.toDateString()) {
            setTotals(dayTotal.amount);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };



  const getDailyExpenses = async (token = X_Authorization) => {
    try {
      const response = await fetch(baseUrl + "/api/getDailyExpenses", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": token,
        },
      });
      const data = await response.json();
      if (response.status === 200) {
        if (data.totals.length > 0) {
          setDailyExpenses(data.totals[0].totalAmount);
        } else {
          setDailyExpenses(0);
        }
        /* for (const dayTotal of data.totals) {
					const dayDate = addDate(dayTotal._id.Time);
					const newDate = addDate(fullDate);
					if (dayDate.toDateString() === newDate.toDateString()) { setTotals(dayTotal.amount);}
				} */
      }
    } catch (error) {}
  };

  // fetch top 10 transactions
  const fetchTopTransactions = async () => {
    try {
      const response = await fetch(baseUrl + "/api/topTenTransactions", {
        method: "GET",
        headers: {
          "X-Authorization": localStorage.getItem("X-Authorization"),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const data = await response.json();

      if (response.status === 401) {
        return (window.location.href = "/logout");
      }
      if (response.status === 200) {
        setTransactionData(data.totals);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Fetch the payment data
  const paymentData = async (token = X_Authorization) => {
    const donutResponse = await fetch(baseUrl + "/api/donutRange", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": token,
      },
      body: JSON.stringify({ dateList: dValue }),
    });

    const data = await donutResponse.json();
    if (donutResponse.status === 401) {
      return (window.location.href = "/logout");
    }
    if (donutResponse.status === 200) {
      setChartData(data.totals);
    }
  };

  // Fetch Cashiers
  const fetchCashiers = async (dates, token = X_Authorization) => {
    try {
      // const [startDate, endDate] = dValue;
      const response = await fetch(baseUrl + `/api/get_business_cashiers?page=1&limit=10`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": token,
        },
        body: JSON.stringify({
              "endDate":dValue[1],
              "startDate":dValue[0],
              "business_id": defaultBusinessId,
        }),
      });
      const data = await response.json();
      if (response.status === 401) {
        return (window.location.href = "/logout");
      }
      if (response.status === 200) {
        setCashiers(data.data);
        // const sortedCashiers = cashiers.sort((a, b) => a-b);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const sortedCashiers = cashiers.sort((a, b) => a.totalSales - b.totalSales);

  // Fetch branches
  const fetchBranches = async (dValue, token = X_Authorization) => {
    // console.log( token);
    const [startDate, endDate] = dValue;
    try {
      const response = await fetch(baseUrl + `/api/getBusinessBranchesPerformance?startDate=${startDate}&endDate=${endDate}`, {
        method: "GET",
        headers: {
          "X-Authorization": token,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const data = await response.json();
      if (response.status === 401) {
        return (window.location.href = "/logout");
      }
      if (response.status === 200) {
        setBranches(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // fetch business transactions
  // const fetchBusinessTransactions = async(dValue, token = X_Authorization) =>{
  //   try {
  //     const response = await fetch(baseUrl + `/api/get_business_transaction_by_date?limit=1000&page=1`, {
  //       method: "POST",
  //       headers: {
  //         "X-Authorization":localStorage.getItem("X-Authorization"),
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //       },
  //         body:JSON.stringify(
  //           {
  //             "endDate": "2023-05-09T18:21:45+03:00",
  //             "business_id": "63574ddf220473a1da9753b6",
  //             "startDate": "2023-05-09T00:00:00+03:00",
  //             "type":"ALL"
  //           }
  //         )
  //     });
  //     const data = await response.json();
  //     console.log(localStorage.getItem("X-Authorization"), "TOKEN HERE")
  //     console.log("all business transactions", data);
  //     if (response.status === 401) {
  //       // return (window.location.href = "/logout");
  //     }
  //     if (response.status === 200) {
  //       setBusinessTransactions(data);
  //       // console.log(data.total);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }


  // fetch branch transactions
  // const fetchBranchTransactions = async(dValue, token = X_Authorization) =>{
  //   try {
  //     const response = await fetch(baseUrl + `/api/get_branch_transaction_by_date?&limit=1000&page=1`, {
  //       method: "POST",
  //       headers: {
  //         "X-Authorization": token,
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //       },
  //         body:JSON.stringify(
  //           {
  //             "branch_id": "637e81ecec4376c32ddfe0a0",
  //             "endDate": "2023-05-09T19:03:30+03:00",
  //             "startDate": "2023-05-09T00:00:00+03:00",
  //             "type":"POS?INVOICE?ALL"
  //           }
  //         )
  //     });
  //     const data = await response.json();
  //     console.log("all branch transactions", data);
  //     if (response.status === 401) {
  //       // return (window.location.href = "/logout");
  //     }
  //     if (response.status === 200) {
  //       setBranchTransactions(data);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  // console.log(businessBranchSummary);
  // console.log(businessBranchSummary.totalSales.amount);
  // console.log(businessBranchSummary.totalSales.numberOfTransactions);
  // console.log(businessBranchSummary.branch);

   // fetch business branch transactions
   const fetchBusinessBranchSummary = async(dValue, token = X_Authorization) =>{
    try {
      const response = await fetch(baseUrl + `/api/v1/business/business-branch-summary`, {
        method: "POST",
        headers: {
          // "X-Authorization": token,
          "X-Authorization": localStorage.getItem("X-Authorization"),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
          body:JSON.stringify(
            {
              "endDate":dValue[1],
              "branchID": localStorage.getItem('BranchID'),
              "startDate":dValue[0],
              "businessId": localStorage.getItem('businessID'),
            }
          )
      });
      const data = await response.json();
      if (response.status === 401) {
        // return (window.location.href = "/logout");
      }
      if (response.status === 200) {
        setBusinessBranchSummary({...businessBranchSummary, totalSales:data.data.totalSales, branch:data.data.branch})
      }
    } catch (e) {
      console.log(e);
    }
  }

  // fetch business branch transactions
  const fetchBusinessBranchStoreSummary = async(dValue, token = X_Authorization) =>{
    try {
      const response = await fetch(baseUrl + `/api/v1/business/branch-store-summary`, {
        method: "POST",
        headers: {
          // "X-Authorization": token,
          "X-Authorization": localStorage.getItem("X-Authorization"),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
          body:JSON.stringify(
            {
              "endDate":dValue[1],
              "branchId": localStorage.getItem('BranchID'),
              "startDate":dValue[0],
            }
          )
      });
      const data = await response.json();
      if (response.status === 401) {
        // return (window.location.href = "/logout");
      }
      if (response.status === 200) {
        setBusinessBranchStoreData({...businessBranchStoreData, totalSales: data.data.totalSales, branch: data.data.branch});
      }
    } catch (e) {
      console.log(e);
    }
  }

  
   // fetch business by date  transactions
   const fetchBusinessTransactionsByDate = async(dValue, token = X_Authorization) =>{
    try {
      const response = await fetch(baseUrl + `/api/get_business_transaction_by_date?limit=1000&page=1`, {
        method: "POST",
        headers: {
          "X-Authorization": token,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
          body:JSON.stringify(
            {
              "endDate": dValue[1],
              "type": transactionOption,
              "business_id": localStorage.getItem('businessID'),
              "startDate": dValue[0]
          }
          )
      });
      const data = await response.json();
      if (response.status === 401) {
        // return (window.location.href = "/logout");
      }
      if (response.status === 200) {
        setBusinessTransactionsByDate(data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  // get business totals
  const fetchTotalBusinessTransactionData = async(dValue, token = X_Authorization) =>{
    try {
      const response = await fetch(baseUrl + `/api/get_business_transaction_by_date?limit=1000&page=1`, {
        method: "POST",
        headers: {
          "X-Authorization": token,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
          body:JSON.stringify(
            {
              "endDate": dValue[1],
              "type": "ALL",
              "business_id": localStorage.getItem('businessID'),
              "startDate": dValue[0]
          }
          )
      });
      const data = await response.json();
      if (response.status === 401) {
        // return (window.location.href = "/logout");
      }
      if (response.status === 200) {
        setTotalBusinessTransactionData(data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  // fetch INVOICE business by date  transactions
  const fetchInvoiceBusinessTransactionsByDate = async(dValue, token = X_Authorization) =>{
    try {
      const response = await fetch(baseUrl + `/api/get_business_transaction_by_date?limit=1000&page=1`, {
        method: "POST",
        headers: {
          "X-Authorization": token,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
          body:JSON.stringify(
            {
              "endDate": dValue[1],
              "type": "INVOICE",
              "business_id": localStorage.getItem('businessID'),
              "startDate": dValue[0]
          }
          )
      });
      const data = await response.json();
      if (response.status === 401) {
        // return (window.location.href = "/logout");
      }
      if (response.status === 200) {
        setInvoiceData(data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  // fetch POS business by date  transactions
  const fetchPOSBusinessTransactionsByDate = async(dValue, token = X_Authorization) =>{
    try {
      const response = await fetch(baseUrl + `/api/get_business_transaction_by_date?limit=1000&page=1`, {
        
        method: "POST",
        headers: {
          "X-Authorization": token,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
          body:JSON.stringify(
            { 
                "business_id":localStorage.getItem('businessID'),
                "startDate":dValue[0],
                "endDate": dValue[1],
                "type" : "POS"
          }
          )
      });
      const data = await response.json();
      if (response.status === 401) {
        // return (window.location.href = "/logout");
      }
      if (response.status === 200) {
        setPOSData(data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  // fetch business top sellers data
  const fetchBusinessSellers = async(dValue, token = X_Authorization) =>{
    try {
      const response = await fetch(baseUrl + `/api/v1/get_business_sellers?page=1&limit=10`, {
        
        method: "POST",
        headers: {
          "X-Authorization": token,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
          body:JSON.stringify(
            { 
                "startDate":dValue[0],
                "endDate": dValue[1],
          }
          )
      });
      const data = await response.json();
      if (response.status === 401) {
        // return (window.location.href = "/logout");
      }
      if (response.status === 200) {
        setBusinessSellers(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  //Switch Branch
  const switchBranch = async (branchId) => {
    try {
      const response = await fetch(baseUrl + `/api/get_business_token`, {
        method: "POST",
        headers: {
          "X-Authorization": X_Authorization,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          branch_id: branchId,
          oldToken: X_Authorization,
          business_id: defaultBusinessId,
        }),
      });
      if (response.status === 401) {
        return (window.location.href = "/logout");
      }
      const result = await response.json();
      const token = result.data.newToken;
      dispatch(changeToken({ token }));

      /* ---- Gets Branch (s) Today's Sales, Today's Expenditure & Net Sales from API -------- */
      fetchTotals(token);

      /* ---- Gets Branch (s) oday's Expenditure from API -------- */
      getDailyExpenses(token);

      /* ---- Gets Branch (s) Performance Data from API -------- */
      fetchBranches(dValue, token);

      /* ---- Gets Branch (s) Transactions based on their payment modes eg. Cash, M-Pesa, Card, Vooma, Voucher etc. from API  --------*/
      paymentData(token);

      /* ---- Gets Branch (s) Cashier Summaries Data from API -------- */
      fetchCashiers(dValue, token);

       /* ---- Gets Top Transactions Data from API -------- */
      fetchTopTransactions();

       /* ---- Gets All Business Transactions Data from API -------- */
      // fetchBusinessTransactions(dValue, token);

       /* ---- Gets Current Branch Transactions Data from API -------- */
      // fetchBranchTransactions(dValue, token);

       /* ---- Gets Current Branch Business Summary Data from API -------- */
      // fetchBusinessBranchSummary(dValue, token);

       /* ---- Gets Current Branch Transactions Data from API by Date -------- */
      fetchBusinessTransactionsByDate(dValue, token);

       /* ---- Gets Current Branch POS Transactions Data from API by Date -------- */
      fetchPOSBusinessTransactionsByDate(dValue, token);

       /* ---- Gets Current Branch Invoice Transactions Data from API by Date -------- */
      fetchInvoiceBusinessTransactionsByDate(dValue,  token);

       /* ---- Gets Current Branch total Transactions Data from API by Date -------- */
      fetchTotalBusinessTransactionData(dValue,  token);

      fetchBusinessBranchStoreSummary(dValue, token, localStorage.getItem('BranchID'));

      fetchBusinessSellers(dValue, token, localStorage.getItem('businessID'))

      localStorage.removeItem("X-Authorization");
      localStorage.setItem("X-Authorization", token);
    } catch (e) {
      console.log("Error Switching branch", e.message);
    }
  };

  // check if branch name is available then fetch branches;
  const fetchBranchesOnStorageChange = () => {
    fetchBranches(dValue, X_Authorization);
    switchBranch(branchId);
    fetchCashiers(dValue, X_Authorization);
    // fetchBusinessTransactions(dValue, X_Authorization);
    // fetchBranchTransactions(dValue, X_Authorization);
    fetchBusinessBranchSummary(dValue, X_Authorization);
    fetchBusinessTransactionsByDate(dValue, X_Authorization);
    fetchPOSBusinessTransactionsByDate(dValue, X_Authorization);
    fetchInvoiceBusinessTransactionsByDate(dValue, X_Authorization)
    fetchTotalBusinessTransactionData(dValue, X_Authorization)
    fetchBusinessSellers(dValue, X_Authorization)
  };
  useEffect(() => {
    localStorage.getItem("branch") === " " ? fetchBranchesOnStorageChange() : null;
  }, []);

  //Fetch bars
  const fetchBarsAndKitchens = async () => {
    try {
      const response = await fetch(baseUrl + "/api/list_branch_bars_kitchens", {
        method: "GET",
        headers: {
          "X-Authorization": localStorage.getItem("X-Authorization"),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      await response
        .json()
        .then((data) => {
          setBarsAndKitchen(data.stores);
        })
        .catch((e) => {
          console.log(`Error on Getting Bars!`, e.message);
        });
    } catch (e) {
      console.log(`Error on Getting Bars!`, e.message);
    }
  };

  // Add bar/Kitchen
  const handleBarOrKitchenDropDownSelect = (shopName) => {
    setBarOrKitchen(shopName);
    if (shopName === "Kitchen") {
      handleOpenKitchen();
    } else if (shopName === "Bar") {
      handleOpenAddBar();
    }
  };

  // timeline Drop Down use Effect
  useEffect(() => {
    if (dropDown === "Today") {
      onDValueChange([moment(new Date()).format("YYYY-MM-DD"), moment(addDays(new Date(), 1)).format("YYYY-MM-DD")]);
    } else if (dropDown === "YesterDay") {
      onDValueChange([moment(addDays(new Date(), -1)).format("YYYY-MM-DD"), moment(addDays(new Date(), -1)).format("YYYY-MM-DD")]);
    } else if (dropDown === "7days") {
      onDValueChange([moment(addDays(new Date(Date.now()), -7)).format("YYYY-MM-DD"), moment(addDays(new Date(Date.now()), +1)).format("YYYY-MM-DD")]);
    } else if (dropDown === "this Month") {
      onDValueChange([moment(new Date(new Date().getFullYear(), new Date().getMonth())).format("YYYY-MM-DD"), moment(addDays(new Date(Date.now()), +1)).format("YYYY-MM-DD")]);
    } else if (dropDown === "last Month") {
      onDValueChange([moment(new Date(new Date().getFullYear(), new Date().getMonth() - 1)).format("YYYY-MM-DD"), moment(new Date(new Date().getFullYear(), new Date().getMonth())).format("YYYY-MM-DD")]);
    }
  }, [dropDown]);

  // switch transactions data
  // useEffect(() => {
  //  const handlePaymentTypeChange = () =>{
  //     if (transactionOption === "All") {
  //       fetchTotalBusinessTransactionData()
  //     } else if (transactionOption === "POS") {
  //       fetchPOSBusinessTransactionsByDate();
  //     } else if (transactionOption === "Invoices") {
  //       fetchInvoiceBusinessTransactionsByDate()
  //     }
  //  }
  //  handlePaymentTypeChange();
  // }, [transactionOption, branchDropDown, selectedBusinessName]);

  const handlePaymentTypeChange = (e) =>{
    e.preventDefault();
    setTransactionOption(e.target.value);
    e.target.value === "All" ? fetchTotalBusinessTransactionData() : 
    e.target.value === "POS" ? fetchPOSBusinessTransactionsByDate() :
    e.target.value === "Invoices" ? fetchInvoiceBusinessTransactionsByDate() : null
 }

  // check default branch
  useEffect(() => {
    if (branchDropDown === "allBranches") {
      fetchBusinessBranchSummary();
    } else{
      fetchBusinessBranchStoreSummary();
    }
  }, [branchDropDown]);


  // Donut Data useEffect
  useEffect(() => {
    paymentData()
      .then(() => {
        // console.log("Branch totals Found");
      })
      .catch((e) => {
        console.log(`Error getting totals`, e.message);
      });
  }, [dValue]);

  // Fetch UseEffects
  useEffect(() => {
    fetchBranches(dValue);
    fetchCashiers(dValue);
    fetchTopTransactions();
    // fetchBusinessTransactions(dValue);
    // fetchBranchTransactions(dValue);
    fetchBusinessBranchSummary(dValue);
    fetchBusinessTransactionsByDate(dValue);
    fetchPOSBusinessTransactionsByDate(dValue);
    fetchInvoiceBusinessTransactionsByDate(dValue)
    fetchTotalBusinessTransactionData(dValue);
    // fetchBusinessBranchStoreSummary(dValue, token)
    fetchBusinessSellers(dValue);
    fetchTotals();
    getDailyExpenses();
    // getAvailableBusinesses(dValue)
  }, [dValue, transactionOption, selectedBusinessName]);

  useEffect(() =>{
    getAvailableBusinesses();
  },[localStorage.getItem('businessName')])

  const calendarOnChangeHandler = (date = calendarDateRef.current) => {
    calendarDateRef.current = moment(date).format("YYYY-MM-DD");
    dispatch(handleTransactionDate(moment(date).format("YYYY-MM-DD")));
    navigate("/transactions");
  };

  // Fetch Bars and Kitchens
  useEffect(() => {
    fetchBarsAndKitchens()
      .then(() => {
        // console.log("Bars And Kitchen Found");
      })
      .catch((e) => {
        console.log(`Error getting Bars And Kitchen`, e.message);
      });
  }, [localStorage.getItem("branch")]);

  useEffect(() => {
    // console.log("group", group)
  }, []);

  // console.log(`Dashboard BarMan State`, barManState)

  const handleSchoolNonCustomerDropdown = (e) => {
    e.preventDefault();
    const bch = JSON.parse(e.target.value);
    switchBranch(bch?._id);
    dispatch(handleBranchDetails(bch));
    // dispatch(changeToken({ token: result.data.newToken }));
    localStorage.setItem("branch", bch?.branchName);
    // localStorage.setItem('BranchId', bch?._id )
    localStorage.setItem("BranchID", bch?._id);
  };

  // handle businessName change dropdown
  const handleDateDropdown = (e) => {
    e.preventDefault();
    const business_name = JSON.parse(e.target.value);
    switchBranch(business_name?._id);
    // dispatch(handleBranchDetails(bch));
    // dispatch(changeToken({ token: result.data.newToken }));
    localStorage.setItem("businessName", business_name?.businessName);
    // localStorage.setItem('BranchId', bch?._id )
    localStorage.setItem("businessID", business_name?._id);
  };

  // handle branch change dropdown
  const handleNonAdminDropdown = (e) => {
    e.preventDefault();
    setBranchDropDown(e.target.value);
    if (e.target.value !== "allBranches") {
      const branch = JSON.parse(e.target.value);
      switchBranch(branch?._id);
      dispatch(handleBranchDetails(branch));
      // console.log("=========<>", branch);
      localStorage.setItem("branch", branch?.branchName);
      // localStorage.setItem('BranchId', branch?._id )
      localStorage.setItem("BranchID", branch?._id);
      fetchBranches(dValue);
      fetchCashiers(branchDropDown);
      fetchTopTransactions();
      // fetchBusinessTransactions(branchDropDown);
      // fetchBranchTransactions(branchDropDown);
      fetchBusinessBranchStoreSummary(localStorage.getItem('BranchID'));
      fetchBusinessTransactionsByDate(branchDropDown);
      fetchPOSBusinessTransactionsByDate(branchDropDown);
      fetchInvoiceBusinessTransactionsByDate(branchDropDown)
      fetchTotalBusinessTransactionData(branchDropDown);
    }
    if (e.target.value === "allBranches") {
      fetchBusinessBranchSummary();
      switchBranch(e.target.value);
      localStorage.setItem("branch", "All Branches");
    }
  };

  // handle tab state and logic
  const [value, setValue] = React.useState('unpaid');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() =>{
    const getUserBusinesses = async () => {
      // get_customer_businesses
      const customerDetailsResponse = await fetch(
          baseUrl + `/api/get_customer_businesses`,
          {
              method: "GET",
              headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  "X-Authorization": localStorage.getItem("X-Authorization"),
              },
          }
      );
      if (customerDetailsResponse.status === 200) {
          const customerDetailsData = await customerDetailsResponse.json();
          setCustomerBusinessData(customerDetailsData?.data?.businesses)
          setSelectedBusinessName(customerDetailsData?.data?.businessName)
      }
  }
  getUserBusinesses();
  },[])

  // get active substores

  const getActiveSubstores = () => {
    HttpComponent({
      method: "GET",
      url: `/api/list_all_stores_by_type_status?storeType=Substore&status=ACTIVE`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 200) {
          setActiveSubstores(data.response.data)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getActiveSubstores();
  }, []);

  const [isError, setIsError] = useState({state:false , message:""})
  const newToken = jsondecode(localStorage.getItem("X-Authorization"))
  const branchID = newToken?.branchId
  const ISERROR = <ErrorAlertRightAligned  message={isError.message} error={isError.state} />
  // switch to POS
  const handleSwitch = () =>{
    if(branchID) return navigate('/orders/startorder');
    if(!branchID) {
      setIsError({state:true, message : 'Please select branch to continue!'})
    }
  }

  useEffect(() => {
    if(isError.state){
      setTimeout(() => {
        setIsError({state:false})  
      }, 3000);
      
    }
  },[isError.state])

  // total invoice transactions
  const totalInvTrans =  lodash.sumBy(invoiceData.data, data=>{
    return data.numberOfTransactions
  })

  // total POS transactions
  const totalPOSTransactions =  lodash.sumBy(posData.data, data=>{
    return data.numberOfTransactions
  })

  // total number of transactions
  const totalTransactions = lodash.sumBy(totalBusinessTransactionData.data, data =>{
    return data.numberOfTransactions
  })



  const [token, setToken] = useState(localStorage.getItem("X-Authorization"))
  const [searchText, setSearchText] = useState('');

  // SWitching business
  const [userGroup, setUserGroup] = useState("");
  const [category, setUserCategory] = useState("");
  const [branchIdw, setDefaultBranchId] = useState("");

  // get all available businesses
  const getAvailableBusinesses = async() =>{
     // get_customer_businesses
     const customerDetailsResponse = await fetch(
      baseUrl + `/api/get_available_businesses`,
      {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
          },
      }
  );
  if (customerDetailsResponse.status === 200) {
      const customerDetailsData = await customerDetailsResponse.json();
      setCustomerBusinessData(customerDetailsData?.data);
      // DisplayFetchedDataBusinessData()
      setSelectedBusinessName(localStorage.getItem('businessName'))
  }
}

// handle business change
  const handleBusinessChange = async (id) => {
    try {
      const switchDefaultBusinessResp = await fetch(baseUrl + "/api/set_user_default_business ", {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
          },
          body: JSON.stringify({
              defaultBusinessId: id,
          }),
      });

      if (switchDefaultBusinessResp.status === 200) {
          switchBusiness();
      }
  } catch (error) {
      console.log(error);
  }
};
var sucess = false;

const switchBusiness = async () => {
  // console.log(branchId);
  try {
      const switchBusinessResp = await fetch(baseUrl + "/api/get_token_after_invite", {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
          },
          body: JSON.stringify({}),
      });

      if (switchBusinessResp.status === 200) {
          const switchRespData = await switchBusinessResp.json();
          setUserGroup(switchRespData.data.group);
          setUserCategory(switchRespData.data.businessCategory);

          dispatch(handleBusinessCategory(switchRespData.data.businessCategory));
          fetchBranchesAll(switchRespData.data.token).then(() => decodeTokenAndSetNewBusiness(switchRespData.data.token, switchRespData.data));
          sucess = true;
      }
  } catch (error) {
      console.log(error);
  }
};

// // decode token and update bsuniess details;
const decodeTokenAndSetNewBusiness = (newToken, data) => {
  const decodedToken = jsondecode(newToken);

  localStorage.setItem("X-Authorization", newToken);
  localStorage.setItem("group", decodedToken.group);
  localStorage.setItem("zedAccountingId", decodedToken.zedAccountingId);
  setUserGroup(decodedToken.group)
  localStorage.setItem("email", decodedToken.email);
  localStorage.setItem("state", decodedToken.state);
  localStorage.setItem("businessID", decodedToken.defaultBusinessId);
  localStorage.setItem("username", decodedToken.userName);
  localStorage.setItem("businessName", decodedToken.businessName);
  localStorage.setItem("businessId", decodedToken.businessNumber);
  localStorage.setItem("userId", decodedToken.userId);
  localStorage.setItem("branch", " ");
  if (decodedToken.branchId) localStorage.setItem("BranchID", decodedToken.branchId);

  localStorage.setItem("customerId", decodedToken.customerId);

  localStorage.setItem("businessCategory", decodedToken.businessCategory);

  branchId && dispatch(logInUser({
      isLoggedIn: true,
      X_Authorization: newToken,
      userId: decodedToken.userId,
      email: decodedToken.email,
      username: decodedToken.username,
      group: decodedToken.group,
      state: decodedToken.state,
      defaultBusinessId: decodedToken.defaultBusinessId,
      businessNumber: decodedToken.businessNumber,
      businessName: decodedToken.businessName,
      businessCategory: decodedToken.businessCategory,
      branchId: branchId,
      customerId: decodedToken.customerId
  }));
};

const fetchBranchesAll = async (token) => {
  // console.log("fetch branch Token =======>", token);
  try {
      const response = await fetch(baseUrl + "/api/getBusinessBranchesByBid", {
          method: "GET",
          headers: {
              "X-Authorization": token,
              "Content-Type": "application/json",
              Accept: "application/json",
          },
      });
      const branchData = await response.json();

      // get default branch Id;
      const defaultBranchId = branchData.data[0]._id;
      setDefaultBranchId(defaultBranchId);
      // if (response.status === 401) {
      //     return navigate("/logout");
      // }
      if (response.status === 200) {
          dispatch(handleBusinessBranches({
              branchesArr: branchData.data
          }));
          dispatch(handleBranchDetails({
              _id: defaultBranchId
          }));
          dispatch(reversePageLoadState());
          localStorage.setItem('BranchID', branchId)
          // window.location.reload()


          if (userGroup && userGroup === 'Cashier') {
              navigate("/orders/startorder");
          } else {
              navigate("/dashboard");
          }


      }
  } catch (e) {
      console.log(e);
  }
};


  return (
    <div style={{ fontFamily: "Poppins", width:'100%' }}>
      {isError.state && ISERROR}
      {businessCat && businessCat == "School" && group !== "Customer" ? (
        <>
          <Grid container justifyContent={"flex-start"}>
            <Grid item sx={{ marginLeft: 2 }}>
              {/*_id*/}
              <span style={{ fontSize: "1.063rem", fontWeight: "bold" }}>Branch:</span>
              <Select value={localStorage.getItem("BranchID")} sx={{ m: 1, mt: 1, fontSize: "17px", fontWeight: "bold", }} onChange={handleSchoolNonCustomerDropdown} displayEmpty>
                {branchesArr.map((bch) => (
                  <MenuItem key={bch.branchCode} value={bch._id} style={{ fontSize: "12px" }} selected={bch._id === localStorage.getItem("BranchID")}>
                    {bch.branchName}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <StudentDash />
        </>
      ) : businessCat && businessCat == "Rental" && group !== "Customer" ? (
        <LandLordDash />
      ) : (
        <>
          {group && group === "Customer" && businessCat && businessCat === "Rental" ? (
            <TenantDashBoard />
          ) : group && group === "Customer" ? (
            <CustomerAccountDash />
          ) : (
            //:
            //(group && group === 'Merchant') && (businessCat && businessCat === "Rental") ?
            //	(<LandLordDash />)
            <>
              {/*Branch Name Drop Down*/}
              {group && group !== "Admin" ? (
                <Grid container justifyContent={"space-between"} sx={{ width:'98.5%', alignItems:'center' }}>
                <Grid container justifyContent={"space-between"} sx={{ width:'100%', alignItems:'center' }}>
                  <FormControl style={{ width:'auto', position:'absolute', top: 35 }}>
                      <Select
                        labelId="business-select-label"
                        MenuProps={{ autoFocus: false }}
                        id="business-select"
                        value={localStorage.getItem('businessName')}
                        onChange={(e) => setSelectedBusinessName(e.target.value)}
                        onClose={() => setSearchText("")}
                        style={{ border:'none', borderBottom:'none' }}
                        renderValue={() => selectedBusinessName}
                      >
                        <ListSubheader>
                          <TextField
                            size="small"
                            // Autofocus on textfield
                            autoFocus
                            placeholder="Search Business..."
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              )
                            }}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        {customerBusinessData?.filter((item) => { 
                        return searchText.toLowerCase() === '' ? item : item?.businessName.toLowerCase().includes(searchText.toLowerCase())
                        }).map(business => {
                        return (
                          <MenuItem onClick={() => handleBusinessChange(business._id)} key={business._id} value={business.businessName} selected={business.businessName === localStorage.getItem("businessName")}>
                              {business.businessName}
                          </MenuItem>
                        )})}
                      </Select>
                  </FormControl>
                  <Grid item sx={{ width:'50%', display:'flex', alignItems:'center' }}>
                    <span style={{ fontSize: "17px", fontWeight: "bold" }}>Branch:</span>
                    <Form.Select size="sm" aria-label="Default select example" style={{ borderWidth: "0px", backgroundColor: "#fff", color: "#032541", fontSize: "14px", fontWeight: "bold", marginLeft:'10px', alignItems:'center', width:'auto' }} value={branchDropDown} onChange={handleNonAdminDropdown}>
                      {/* <option value={JSON.stringify(branchesArr[0])}>{branchesArr[0]?.branchName ? branchesArr[0].branchName : "Loading branches..."}</option> */}
                      <option value="allBranches">All Branches</option>
                      {branchesArr?.map((bch) => (
                        <option key={bch.branchCode} value={JSON.stringify(bch)}>
                          {bch.branchName}
                        </option>
                      ))}
                    </Form.Select>
                  </Grid>                   
                  <Grid item sx={{ display:'flex' }}> 
                    <Form.Select size="sm" aria-label="Default select example" style={{ borderWidth: "0px", backgroundColor: "#fff", color: "#032541", width:'auto', fontSize: "14px", fontWeight: "bold", marginRight:'12px', alignItems:'center' }} value={dropDown} onChange={(event) => setDropDown(event.target.value)}>
                      <option value="Today">Today</option>
                      <option value="YesterDay">YesterDay</option>
                      <option value="7days">Last 7 Days</option>
                      <option value="this Month">This Month</option>
                      <option value="last Month">Last Month</option>
                    </Form.Select>
                    <Grid item style={{ marginTop: "17px", width:'90px', height:'45px', border: 'solid 1px #bec5d1', borderRadius:'5px' }}>
                     <Button style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }} onClick={handleSwitch}>
                       <SyncAlt style={{ marginLeft: "5px", color: "#707070" }} />
                       POS
                     </Button>
                   </Grid>
                  </Grid>
                </Grid>
                </Grid>
              ) : (
                <></>
              )}

              {/*if  bar man switch is on*/}
              {/*branch kitchen/bar switch business get stock*/}
              {barManState !== true ? null : (
                <Grid container justifyContent={"space-between"} columns={2}>
                  <Grid item xs={1.38}>
                    <Grid container justifyContent={"space-between"} direction={"row"}>
                      {/*Branch*/}
                      <Grid item style={{ marginTop: "17px" }}>
                        <span style={{ fontFamily: "Poppins", fontSize: "17px", fontWeight: "600", textAlign: "left", color: "#032541" }}>{localStorage.getItem("branch")}</span>
                      </Grid>

                      {/*Add Kitchen*/}
                      <Grid item>
                        <AddCircleOutline style={{ color: "#dc3545" }} />
                        <Select value={barOrKitchen} displayEmpty size="small" sx={{ width: "150px", mt: 1, color: "#144166", fontSize: "12px", fontWeight: "bold", ".MuiOutlinedInput-notchedOutline": { border: 0 } }}>
                          <MenuItem
                            onClick={() => {
                              handleBarOrKitchenDropDownSelect("Kitchen");
                            }}
                            style={{ fontSize: "12px", color: "#144166" }}
                          >
                            Kitchen
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleBarOrKitchenDropDownSelect("Bar");
                            }}
                            style={{ fontSize: "12px", color: "#144166" }}
                          >
                            Bar
                          </MenuItem>
                        </Select>
                      </Grid>

                      {/*Switch Business*/}
                      <Grid item style={{ marginTop: "17px" }}>
                        <Button style={{ color: "#144166", fontSize: "14px", fontFamily: "Poppins" }}>
                          Switch Business
                          <SyncAlt style={{ marginLeft: "5px", color: "#144166", fontWeight: "bold" }} />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/*Get Stock*/}
                  <Grid item style={{ marginTop: "17px" }}>
                    <Button style={{ color: "#144166", fontSize: "14px" }}>
                      <AssignmentTurnedInOutlined style={{ marginRight: "5px", color: "#144166", fontWeight: "bold" }} />
                      Get Stock
                    </Button>
                  </Grid>
                </Grid>
              )}

              {/*Sale information Cards adn Top 2 Branches*/}
              <Grid container justifyContent={"flex-start"} direction={"row"} sx={{ mt: 5 }} columns={1}>
                {/*Sales Stats cards*/}
                <Grid item xs={1.4}>
                  {/*Branch carousel*/}
                  {barManState !== true ? null : (
                    <Grid item>
                      <Carousel wrapAround={true} slidesToShow={3} style={{ height: "150px" }}>
                        {barsAndKitchens.map((item, index) => (
                          <div align={"center"} style={{ height: "130px", width: "90%", border: "solid 1px #17ae7b", padding: "17px 36px", borderRadius: "16px", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)", backgroundColor: "#fff" }} key={index}>
                            <img alt="Today's Expenditure" style={{ width: "66px", height: "66px", objectFit: "contain" }} src={House} />
                            <Grid item>
                              <span style={{ fontSize: "19px", color: "#17ae7b", fontWeight: 600 }}>{item.storeName}</span>
                            </Grid>
                          </div>
                        ))}
                      </Carousel>
                    </Grid>
                  )}

                  <Grid container direction={"row"} style={{ width:'100%', gridGap:'12px' }}>
                    {/*Total Sales*/}
                    <Grid item style={{ width: '24%' }}>
                      <div style={{ height: "100%", width:'100%', padding: "15px", borderRadius: "12px", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)", backgroundColor: "#fff" }}>
                        <Box style={{ display:'flex', justifyContent:'space-between', alignItems:"center" }}>
                          <Typography variant="p">Total <br /> Sales</Typography>
                          <img alt="Total Sales" style={{ width: "40px", height: "40px", objectFit: "contain" }} src={totalSales} />
                        </Box>
                        <Box style={{ marginTop:'18px' }}>
                          {
                          branchDropDown !== "allBranches" ? <Typography style={{ color: '#17ae7b', fontWeight:"bold", lineHeight: '1.2', margin:'9px 0px' }}>{numberFormat(businessBranchStoreData.totalSales.amount + 0)}</Typography> :
                          <Typography style={{ color: '#17ae7b', fontWeight:"bold", lineHeight: '1.2', margin:'9px 0px' }}>{numberFormat(businessBranchSummary.totalSales.amount + 0)}</Typography>
                          }
                        </Box>
                        <Box style={{ display:'flex' }}>
                          {branchDropDown !== "allBranches" ? <Typography style={{ fontWeight:'bold' }}>{businessBranchStoreData.totalSales.numberOfTransactions}</Typography> : <Typography style={{ fontWeight:'bold' }}>{businessBranchSummary.totalSales.numberOfTransactions}</Typography>}
                          <Typography style={{ marginLeft:'10px', opacity:'0.6' }}>transactions</Typography>
                        </Box>
                      </div>
                    </Grid>
                    {/*Total POS amount*/}
                    <Grid item style={{ width: '24%' }}>
                      <div style={{  height: "100%", width:'100%', padding: "15px", borderRadius: "12px", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)", backgroundColor: "#fff" }}>
                        <Box style={{ display:'flex', justifyContent:'space-between', alignItems:"center" }}>
                          <Typography variant="p">Total Amount: <br /> POS</Typography>
                          <img alt="Total POS Amount" style={{ width: "40px", height: "40px", objectFit: "contain" }} src={totalPOS} />
                        </Box>
                        <Box style={{ marginTop:'18px' }}>
                          <Typography style={{ color: '#3da2f8', fontWeight:"bold", lineHeight: '1.2', margin:'9px 0px' }}>{numberFormat(posData.total + 0)}</Typography>
                        </Box>
                        <Box style={{ display:'flex' }}>
                          <Typography style={{ fontWeight:'bold' }}>{totalPOSTransactions}</Typography>
                          <Typography style={{ marginLeft:'10px', opacity:'0.6' }}>Transactions</Typography>
                        </Box>
                      </div>
                    </Grid>

                    {/*Total invoice amount*/}
                    <Grid item style={{ width: '24%' }}>
                      <div style={{ height: "100%", width:'100%', padding: "15px", borderRadius: "12px", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)", backgroundColor: "#fff" }}>
                        <Box style={{ display:'flex', justifyContent:'space-between', alignItems:"center" }}>
                          <Typography variant="p">Total Amount: <br /> Invoices</Typography>
                          <img alt="Total Invoices Amount" style={{ width: "40px", height: "40px", objectFit: "contain" }} src={totalInvoice} />
                        </Box>
                        <Box style={{ marginTop:'18px' }}>
                          <Typography style={{ color: '#f79009', fontWeight:"bold", lineHeight: '1.2', margin:'9px 0px' }}>{numberFormat(invoiceData.total + 0)}</Typography>
                        </Box>
                        <Box style={{ display:'flex' }}>
                          <Typography style={{ fontWeight:'bold' }}>{totalInvTrans}</Typography>
                          <Typography style={{ marginLeft:'10px', opacity:'0.6' }}>Transactions</Typography>
                        </Box>
                      </div>
                    </Grid>
                    {/* businessBranchStoreData */}
                    {/*Total branches*/}
                    <Grid item style={{ width: '24%' }}>
                      <div style={{ height: "100%", width:'100%', padding: "15px", borderRadius: "12px", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)", backgroundColor: "#fff" }}>
                        <Box style={{ display:'flex', justifyContent:'space-between', alignItems:"center" }}>
                          <Typography variant="p">Total <br /> Branches</Typography>
                          <img alt="Total Branches" style={{ width: "40px", height: "40px", objectFit: "contain" }} src={totalBranches} />
                        </Box>
                        <Box style={{ marginTop:'18px' }}>
                          {/* <Typography style={{ color: '#5e0ac4', fontWeight:"bold", lineHeight: '1.2', margin:'9px 0px' }}>{businessBranchStoreData.branch.numberOfBranches}</Typography> */}
                          <Typography style={{ color: '#5e0ac4', fontWeight:"bold", lineHeight: '1.2', margin:'9px 0px' }}>{businessBranchSummary.branch.numberOfBranches}</Typography>
                        </Box>
                        <Box style={{ display:'flex', color:'#032541' }}>
                          {branchDropDown !== "allBranches" ? <Typography style={{ fontWeight:'bold' }}>{businessBranchStoreData.branch.numberOfStores}</Typography> :
                          <Typography style={{ fontWeight:'bold' }}>{businessBranchSummary.branch.numberOfStores}</Typography>}
                          <Typography style={{ marginLeft:'10px', opacity:'0.6' }}>Sub-Stores</Typography>
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                {/* transactions container */}
                <Box sx={{ 
                  display:'flex', 
                  width:'98.5%', 
                  marginTop:'20px', 
                  flexDirection:'column', 
                  boxShadow: 3, 
                  padding: '14px 10px 23px 15px', 
                  borderRadius:'10px',
                  backgroundColor: '#fff'
                  }}>
                  {/* top container contents */}
                     <Box sx={{ display:'flex', justifyContent:'space-between', width:'100%' }}>
                        <Box sx={{ display:'flex', alignItems:'center' }}>
                          <Typography>Transactions:</Typography>
                          <Form.Select size="sm" aria-label="Default select example" style={{ borderWidth: "0px", backgroundColor: "#fff", color: "#032541", fontSize: "14px", fontWeight: "bold", marginRight:'12px', alignItems:'center' }} value={transactionOption} onChange={handlePaymentTypeChange}>
                            <option value="ALL">All</option>
                            <option value="POS">POS</option>
                            <option value="INVOICE">Invoices</option>
                          </Form.Select>
                        </Box>
                     </Box>

                     {/* total transaction value */}

                     <Box sx={{ display: 'flex', alignItems:'center', width:'100%', marginTop:'30px' }}>
                        <Box sx={{ display: 'flex', alignItems:'center', width:'100%', justifyContent:'space-between' }}>
                        <Box sx={{ 

                         }}>
                          <Typography>Total Amount:</Typography>
                          <Typography style={{ 
                            fontSize: '16px',
                            color:'#009447',
                            fontWeight: 'bold'
                           }}>{numberFormat( businessTransactionsByDate?.total + 0)}</Typography>
                        </Box>
                        <Box>
                          <img alt="Total Sales" style={{ width: "40px", height: "40px", objectFit: "contain" }} src={totalTrans} />
                        </Box>
                        </Box>
                     </Box>

                     {/* transaction breakdown */}
                      {
                        businessTransactionsByDate?.data?.map((transaction, index) =>{
                          const {transationType} = transaction
                          return(
                            <Box sx={{ display:'flex', flexDirection:'column', width:'100%' }} key={index}>
                            <Box sx={{ display:'flex', marginTop:'15px', width:'100%', alignItems:'center' }}>
                              <Box sx={{ width:'10%' }}>
                                <Typography style={{ fontSize:'16px', fontWeight:500 }} id="t-type">{transaction.transationType}</Typography>
                                <Typography style={{
                                  marginTop:'8px', 
                                  color: transationType === 'MPESA' ? '#17ae7b' :
                                  transationType === 'CARD' ? '#22409a' : 
                                  transationType === 'CASH' ? '#53365c' : 
                                  transationType === 'BANK' ? '#c70686' : 
                                  transationType === 'VOOMA' ? '#c70686' :
                                  transationType === 'DTB' ? 'red' : 
                                  transationType === 'PAYBILL' ? '#f87f05' : 
                                  transationType === 'MOMO' ? '#c70686' : 
                                  transationType === 'EQUITEL' ? 'orange' : '',
                                  fontWeight:'bold' }}>{numberFormat(transaction.amount)}</Typography>
                              </Box>
                              <Box sx={{ display: 'flex', flexDirection:'column', marginLeft:'87px', width:'80%' }}>
                                <Box>
                                  <Typography color="text.secondary">{transaction.numberOfTransactions} Transactions</Typography>
                                </Box>
                                <Box sx={{ borderRadius: '2.5px', height: '5px', width:'98.5%' }}>
                                  <Progress percent={transaction.percentageOfTotal} 
                                  status={
                                    transationType === 'MPESA' ? 'mpesa':
                                    transationType === 'CARD' ? 'card' : 
                                    transationType === 'CASH' ? 'cash' : 
                                    transationType === 'BANK' ? 'bank' : 
                                    transationType === 'VOOMA'? 'vooma': 
                                    transationType === 'DTB' ? 'dtb' : 
                                    transationType === 'PAYBILL' ? 'paybill' : 
                                    transationType === 'MOMO' ? 'momo' : 
                                    transationType === 'EQUITEL' ? 'equitel' : '' 
                                  }
                                  theme={{ cash: {color: '#53365c'},mpesa:{color:"#17ae7b"} ,
                                  card:{color:'#22409a'},bank:{color:'#c70686'},
                                  vooma:{color:'#c70686'},dtb:{color:"red"},paybill:{color:"f87f05"},momo:{color:"#c70686"} ,equitel:{color:"orange"}}}
                                  /> 
                                </Box>
                              </Box>
                            </Box>
                            </Box>
                          )
                        })
                      }
                </Box>

                
                {/* dashboard summary */}

                <Box sx={{ display:'flex', width:'98.5%', justifyContent:'space-between', marginTop:'10px' }}>
                      {/* top branches */}
                      <Box sx={{ 
                        display:'flex', 
                        flexDirection:'column',
                        width:'50%' ,
                        height: '275px',
                        margin: '10px 10px 10px 0',
                        padding: '15px 15px 40px 11px',
                        borderRadius: '10px',
                        boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)',
                        backgroundColor: '#fff'
                        }}>
                        {/* card header */}
                        <Box sx={{ display:'flex', width:'100%', justifyContent:'space-between', alignItems:'center' }}>
                            <Typography>Top Branches</Typography>
                            
                        </Box>
                        <Grid container style={{ display:'flex', flexDirection:'column', width:'100%', alignItems:"center"  }}>
                          {branchesArr.length < 1 ? (
                            <div style={{ fontSize: "12px", fontWeight: "500", justifyContent:'center', marginTop:'50px' }}>No Branches Available</div>
                          ) : (
                            <>
                            <Table responsive borderless>
                              <thead style={{ fontFamily: "Poppins", fontSize: "12px", fontWeight: "600", textAlign: "left", color: "#dc3545" }}>
                                <tr>
                                  <th style={{ fontWeight: 'bold', marginTop:'22px', fontSize:'14px', height:'20px', color: '#000' }}  colSpan={3}>Name</th>
                                  <th style={{ fontWeight: 'bold', marginTop:'22px', fontSize:'14px', height:'20px', color: '#000' }}>Total Amount</th>
                                </tr>
                              </thead>
                              <tbody style={{ fontFamily: "Poppins", color: "#032541" }}>
                                {branchesArr.slice(0, 3).map((branch, index) => (
                                  <tr key={index}>
                                    <td style={{ marginTop:'20px',  color: '#707070', fontSize:'14px' }}  colSpan={3} width={580}>
                                      <Link to={`/cashier/${branch?.branchId}`} style={{ textDecoration:'none', color: '#707070' }}>{branch?.branchName}</Link>
                                    </td>
                                    <td style={{ marginTop:'20px',  color: '#707070', fontSize:'14px' }}>{numberFormat(branch?.totalSales)}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                            <Typography style={{ display:'flex', position:'absolute', marginLeft:'580px', marginTop:'180px'}}><Link to="/branches" style={{ textDecoration:'none', color: '#707070' }}>View More</Link></Typography>
                            </>
                          )}
                        </Grid>
                      </Box>

                      {/* top 3 sellers */}
                      <Box sx={{ 
                         display:'flex', 
                         flexDirection:'column',
                         width:'50%',
                         height: '275px',
                         margin: '10px 2px 10px 10px',
                         padding: '15px 15px 40px 11px',
                         borderRadius: '10px',
                         boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)',
                         backgroundColor: '#fff'
                       }}>
                        {/* card header */}
                        <Box sx={{ display:'flex', width:'100%',justifyContent:'space-between', alignItems:'center' }}>
                            <Typography>Top Sellers</Typography>
      
                        </Box>
                        <Grid container style={{ display:'flex', flexDirection:'column', width:'100%', alignItems:"center"  }}>
                          {businessSellers.length < 1 ? (
                            <div style={{ fontSize: "12px", fontWeight: "500", justifyContent:'center', marginTop:'50px' }}>No Sellers Available</div>
                          ) : (
                            <>
                            <Table responsive borderless>
                              <thead style={{ fontFamily: "Poppins", fontSize: "12px", fontWeight: "600", textAlign: "left", color: "#dc3545" }}>
                              <tr>
                                  <th style={{ fontWeight: 'bold', marginTop:'22px', fontSize:'14px', height:'20px', color: '#000' }} colSpan={2}>Name</th>
                                  <th style={{ fontWeight: 'bold', marginTop:'22px', fontSize:'14px', height:'20px', color: '#000' }} colSpan={2}>Branch</th>
                                  <th style={{ fontWeight: 'bold', marginTop:'22px', fontSize:'14px', height:'20px', color: '#000' }}>Amount</th>
                                </tr>
                              </thead>
                              <tbody style={{ fontFamily: "Poppins", color: "#032541" }}>
                                {businessSellers.slice(0, 3).map((seller, index) => (
                                  <tr key={index}>
                                    <td style={{ marginTop:'20px',  color: '#707070', fontSize:'14px' }} colSpan={2}>
                                      <Link to={`/cashier/${seller?.userId}`} style={{ textDecoration:'none', color: '#707070' }}>{seller?.fullName}</Link>
                                    </td>
                                    <td style={{ marginTop:'20px',  color: '#707070',fontSize:'14px' }} colSpan={2}>{seller?.branchName}</td>
                                    <td style={{ marginTop:'20px',  color: '#707070', fontSize:'14px' }}>{numberFormat((seller?.total + 0))}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                            <Typography style={{ display:'flex', position:'absolute', marginLeft:'550px', marginTop:'180px'}}><Link to="/users" style={{ textDecoration:'none', color: '#707070' }}>View More</Link></Typography>
                            </>
                          )}
                        </Grid>
                      </Box>
                     </Box>

                     {/* invoices container */}
                     <Box sx={{ 
                      width: '98.5%',
                      height: '678px',
                      margin: '0 0 50px',
                      padding: '20px 0 494px 15px',
                      borderRadius: '10px',
                      boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)',
                      backgroundColor: '#fff'
                      }}>
                          <Typography>Invoices</Typography>

                            {/* tabs */}
                          <Box sx={{ width: '100%', marginTop:'27px', marginLeft:'-15px' }}>
                            <TabContext value={value}>
                              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Unpaid" value="unpaid" className="tab" sx={{
                                      color:'red',
                                      "&.Mui-selected": {
                                        backgroundColor: "#dc3545",
                                      },
                                    }}/>
                                    <Tab label="Paid" value="paid" className="tab" />
                                    <Tab label="Saved" value="saved" className="tab"/>
                                </TabList>
                              </Box>
                              <TabPanel value="unpaid">
                                <Box component="div" sx={{ display: "flex", flexDirection: "column" }}>
                                    <Grid container direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} style={{margin:"10px 0px 0px 0px"}} >
                                      <Grid item style={customerstyles.searchDateFilter}  >
                                        <SearchFilter placeholder={'Search Invoice'} />
                                        <CustomDate setDateFrom={setDateFrom} setDateTo={setDateTo} />
                                      
                                      </Grid>

                                      <Grid item>
                                            {/* <Exports/> */}
                                      </Grid>
                                    </Grid>
                                    <UnpaidPartialInvoices />
                                </Box>
                              </TabPanel>
                              <TabPanel value="paid">
                              <Box component="div" sx={{ display: "flex", flexDirection: "column" }}>
                                <Grid container direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} style={{margin:"10px 0px 0px 0px"}} >
                                  <Grid item style={customerstyles.searchDateFilter}  >
                                    <SearchFilter placeholder={'Search Invoice'} />
                                    <CustomDate setDateFrom={setDateFrom} setDateTo={setDateTo} />
                                  </Grid>

                                  <Grid item>
                                        {/* <Exports/> */}
                                  </Grid>
                                </Grid>
                                <PaidInvoices />
                                </Box>
                              </TabPanel>
                              <TabPanel value="saved">
                              <Box component="div" sx={{ display: "flex", flexDirection: "column" }}>
                                    <Grid container direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} style={{margin:"10px 0px 0px 0px"}} >
                                      <Grid item style={customerstyles.searchDateFilter}  >
                                        <SearchFilter placeholder={'Search Invoice'} />
                                        <CustomDate setDateFrom={setDateFrom} setDateTo={setDateTo} />
                                      
                                      </Grid>

                                      <Grid item>
                                            {/* <Exports/> */}
                                      </Grid>
                                    </Grid>
                                    <SavedInvoices/>
                                </Box>
                              </TabPanel>
                            </TabContext>
                        </Box>
                     </Box>

                {/* end of transactions container */}

                {/*Branch performance*/}
                {/* {group && group != "Admin" ? (
                  <Grid item xs={0.6}>
                    <Grid container direction={"row"} justifyContent={"space-between"} style={{ marginTop: "26px" }}>
                      <Grid container justifyContent={"space-between"} direction={"row"} style={{ width: "100%", height: "150px", padding: "15px 12.6px 14px 29px", borderRadius: "12px", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)", backgroundColor: "#fff" }}>
                        
                        <Grid item>
                          <span style={{ color: "#dc3545", fontSize: "18px", fontWeight: "600" }}>Branch Performance</span>
                        </Grid>

                       
                        <Grid item>
                          
                          <Form.Select size="sm" aria-label="Default select example" style={{ borderWidth: "0px", backgroundColor: "#fff", color: "#032541", fontSize: "14px", fontWeight: "bold" }} value={dropDown} onChange={(event) => setDropDown(event.target.value)}>
                            <option value="Today">Today</option>
                            <option value="YesterDay">YesterDay</option>
                            <option value="7days">Last 7 Days</option>
                            <option value="this Month">This Month</option>
                            <option value="last Month">Last Month</option>
                          </Form.Select>
                        </Grid>

                       
                        <Grid container justifyContent={"space-between"} direction={"column"} style={{ color: "#032541", fontSize: "12px", fontWeight: "500" }}>
                         
                          {typeof branches != "object" ? <div>No Branch Data</div> : null}

                          
                          {typeof branches == "object" && Array.isArray(branches) == false ? (
                            <Grid key={branches.branchCode} item>
                              <Grid container justifyContent={"space-between"}>
                                <Grid item>
                                  <FiberManualRecordIcon style={{ fontSize: "10px" }} />
                                  {branches.branchName}
                                </Grid>
                                <Grid item>{numberFormat(branches?.totalSales)}</Grid>
                              </Grid>
                            </Grid>
                          ) : null} */}

                          {/* -------- Branch Performance for All Branches --------------------*/}
                          {/* {branches.length > 1 && Array.isArray(branches) == true
                            ? branches.slice(0, 3).map((branch) => (
                                <Grid key={branch.branchCode} item>
                                  <Grid container justifyContent={"space-between"}>
                                    <Grid item>
                                      <FiberManualRecordIcon style={{ fontSize: "10px" }} /> {branch.branchName}
                                    </Grid>
                                    <Grid item>{numberFormat(branch?.totalSales)}</Grid>
                                  </Grid>
                                </Grid>
                              ))
                            : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid> */}
                {/* ) : (
                  <></>
                )} */}
              {/* </Grid> */}

              {/*Calendar and Today's transaction and Cashier*/}

              {/*Calender label*/}
              {/* <Grid item style={{ marginTop: "20px" }}>
                {" "}
                <span style={{ fontSize: "17px", color: "#dc3545", fontWeight: 600 }}> Calender</span>
              </Grid> */}

              {/* <Grid container justifyContent={"flex-start"} direction={"row"} columns={2}>
                calender
                <Grid item xs={1.4}>
                  <Calendar
                    onChange={calendarOnChangeHandler}
                    value={calendarDateRef.current}
                    next2Label=""
                    prev2Label=""
                    tileContent={({ date, view }) => {
                      if (calenderData.find((x) => x._id.Time === moment(date).format("YYYY-MM-DD"))) {
                        const totalDailySale = calenderData.map((y) => {
                          if (y._id.Time === moment(date).format("YYYY-MM-DD")) {
                            return numberFormat(y.amount);
                          }
                        });
                        return (
                          <div>
                            <span style={{ height: "9px", fontFamily: "Poppins", fontSize: "12px", fontWeight: "bold", textAlign: "center", color: "#dc3545" }}>{totalDailySale}</span>
                          </div>
                        );
                      }
                    }}
                  />
                </Grid> */}

                {/*Transactions and Cashier*/}
                {/* <Grid item xs={0.6}> */}
                  {/* <Grid container justifyContent={"flex-start"} direction={"column"}> */}
                    {/*Transaction Headers and Date Filter*/}
                    {/* <Grid container justifyContent={"space-between"} direction={"row"} style={{ width: "100%", height: "256px", padding: "15px 12.6px 14px 29px", borderRadius: "12px", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)", backgroundColor: "#fff" }}> */}
                      {/*Name*/}
                      {/* <Grid item>
                        <span style={{ color: "#dc3545", fontSize: "18px", fontWeight: "600" }}>
                          {dropDown === "Today" ? `Today's  Transactions` : null}
                          {dropDown === "YesterDay" ? `Yesterday's Transactions` : null}
                          {dropDown === "7days" ? `Last 7 days' Transactions` : null}
                          {dropDown === "this Month" ? `This Month's Transactions` : null}
                          {dropDown === "last Month" ? `Last Month's Transactions` : null}
                        </span>
                      </Grid> */}

                      {/*Payment BreakDown*/}
                      {/* <Grid container justifyContent={"space-between"} style={{ color: "#032541", fontSize: "12px", fontWeight: "500" }}>
                        {chartData.length < 1 ? (
                          <div>No Payment Data</div>
                        ) : (
                          chartData.map((lvalues, index) => (
                            <Grid container key={index} justifyContent={"space-between"} direction={"row"}>
                              <Grid item>
                                <FiberManualRecordIcon style={{ fontSize: "10px" }} />
                                {lvalues._id}
                              </Grid>
                              <Grid item>{numberFormat(lvalues.amount)}</Grid>
                            </Grid>
                          ))
                        )}
                      </Grid> */}

                      {/*All transaction Link*/}
                      {/* <Grid container justifyContent={"flex-end"}>
                        <Grid item>
                          {localStorage.getItem("group") !== "Merchant" || localStorage.getItem("group") !== "Admin" ? null : (
                            <a href="/transactions" style={{ margin: "0 6px 0 0px", fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", textAlign: "left", color: "#17ae7b" }}>
                              View All transactions
                            </a>
                          )}
                        </Grid>
                      </Grid>
                    </Grid> */}

                    {/*Cashiers*/}
                    {/* <Grid item sx={{ mt: 5 }}> */}
                      {/* <Grid container justifyContent={"space-between"} style={{ width: "100%", height: "224px", padding: "15px 12.6px 14px 29px", borderRadius: "12px", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)", backgroundColor: "#fff" }}> */}
                        {/*Cashier name*/}
                        {/* <Grid item>
                          <u style={{ textDecorationStyle: "solid", color: "#dc3545" }}>
                            <span style={{ color: "#032541", fontSize: "18px", fontWeight: "600" }}>Cashiers</span>
                          </u>
                        </Grid> */}

                        {/*Cashier table*/}
                        {/* <Grid container justifyContent={"space-between"}>
                          {cashiers.length < 1 ? (
                            <div style={{ fontSize: "12px", fontWeight: "500" }}>No Cashier Data</div>
                          ) : (
                            <Table responsive size="sm">
                              <thead style={{ fontFamily: "Poppins", fontSize: "12px", fontWeight: "600", textAlign: "left", color: "#dc3545" }}>
                                <tr>
                                  <th>Name</th>
                                  <th>Amount</th>
                                  <th>Branch</th>
                                </tr>
                              </thead>
                              <tbody style={{ fontFamily: "Poppins", fontSize: "9.5px", textAlign: "left", color: "#032541" }}>
                                {cashiers.slice(0, 4).map((cashier, index) => (
                                  <tr key={index}>
                                    <td>
                                      <Link to={`/cashier/${cashier?.cashierId}`}>{cashier?.cashierFullName}</Link>
                                    </td>
                                    <td>{numberFormat(cashier?.totalSales)}</td>
                                    <td>{cashier?.branchName}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          )}
                        </Grid> */}

                        {/*Cashier Link*/}
                        {/* <Grid container justifyContent={"flex-end"}>
                          <Grid item>
                            {localStorage.getItem("group") !== "Merchant" || localStorage.getItem("group") !== "Admin" ? null : (
                              <a href="/users" style={{ margin: "0 6px 0 0px", fontFamily: "Poppins", fontSize: "10px", fontWeight: "bold", textAlign: "left", color: "#17ae7b" }}>
                                View All Cashiers
                              </a>
                            )}
                          </Grid>
                        </Grid> */}
                      {/* </Grid> */}
                    {/* </Grid> */}
                  {/* </Grid> */}
                {/* </Grid> */}
              </Grid>

              {/*Add kitchen*/}
              {/* <AddKitchen open={openAddKitchen} onClose={handleCloseKitchen} /> */}

              {/*Add bar*/}
              {/* <AddBar open={openAddBar} onClose={handleCloseAddBar} /> */}
              {/*</div>*/}
            </>
          )}
        </>
      )}
      {/*Main Container*/}
      {/*<div >*/}
    </div>
  );
}
