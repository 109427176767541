import React, { useEffect, useState } from 'react';
import { Box, Breadcrumbs, Button, Tab, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import CustomSearchInput from "./CustomSearchInput";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CustomTable from "./CustomTable";
import { useSelector } from "react-redux";
import HttpComponent from "./MakeRequest";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import DeactivateIcon from "./Images/deactivate-icn.svg"
import ReplayIcon from "@mui/icons-material/Replay";
import ExportMenu from "./ExportMenu";

const breadcrumbs = [
    <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Dashboard
    </Typography>,
    <Typography key={"Invoices"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
        Students
    </Typography>
];


const SchoolListStudents = () => {
    const [tabValue, setTabValue] = useState("ACTIVE");
    const [tableData, setTableData] = useState([])
    const [dataToShow, setDataToShow] = useState([]);
    const {schoolTypeName, schoolTypeId} = useSelector((store) => store.schoolType.schoolTypeDetail)

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const [formData, setFormData] = useState(
        {
            search: "",
        }
    )
    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    const { userId, X_Authorization } = useSelector((store) => store.user);
    const [gridLoading, setGridLoading] = useState(false)
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })

    const fetchParents = () => {
        setGridLoading(true)
        HttpComponent({
            method: 'GET',
            url: `/api/get_students_in_school?page=1&limit=1000000000`,
            body: null,
            token: X_Authorization
        }).then((data) => {
            console.log("here store is data", data);
            if (data.status === 200) {
                setTableData(data.response.data)
                setGridLoading(false)
            } else {
                console.error("Error setting info")
                setErrorShow({ state: true, message: data.response.message })
                setGridLoading(false)
            }
        }).catch((error) => {
            console.error(error.message);
            setGridLoading(false)
        })
    }

    const setData = () => {
        let searchable = [...tableData]
        console.log(formData.filter)
        if (formData.search !== "") {
            let result = searchable.filter(student =>
                (student.firstName && student.firstName.toLowerCase().includes(formData.search)) ||
                (student.lastName && student.lastName.toLowerCase().includes(formData.search)) ||
                (student.parentName && student.parentName.toLowerCase().includes(formData.search)) ||
                (student.grade && student.grade.toLowerCase().includes(formData.search)) ||
                (student.stream && student.stream.toLowerCase().includes(formData.search)) ||
                (student.boardingStatus && student.boardingStatus.toLowerCase().includes(formData.search)) ||
                (student.itemNumber && student.itemNumber.toLowerCase().includes(formData.search))
            );
            console.log("search", result)
            setDataToShow(result);
        } else {
            setDataToShow(tableData)
        }
    }

    useEffect(() => {
        setData()
        console.log("search", formData.search)
    }, [formData.search, tableData]);

    useEffect(() => {
        fetchParents()
    }, [tabValue]);
    const navigate = useNavigate();

    const createButton = {
        // "width": "15.313rem",
        // "height": "2.813rem",
        "borderRadius": "5px",
        "border": "solid 1px #002543",
        "backgroundColor": "#fff",
        "color": "#032541",
        '&:hover': {
            backgroundColor: '#fff',
            color: '#032541'
        }
    }

    const activeColumns = [
        {
            headerName: "Admission No", field: "itemNumber", flex: 1,
            renderCell: params => (
                <Typography sx={{ color: "#33699d", textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate(`/school/student/${params.row.customerId}/${params.value}`)}>
                    {params.value}
                </Typography>
            )
        },
        {
            headerName: "Student Name", field: "studentName", flex: 1,
            renderCell: params => (
                <Typography>
                    {params.row.firstName} {params.row.lastName}
                </Typography>
            )
        },
        { headerName: "Grade", field: "grade", flex: 1 },
        { headerName: "Stream", field: "stream", flex: 1 },
        { headerName: "Boarding Status", field: "boardingStatus", flex: 1 },
        { headerName: "Parent Name", field: "parentName", flex: 1 },
        { headerName: "Mobile Number", field: "parentPhone", flex: 1 },
        { headerName: "Pending Invoices", field: "pendingInvoices", flex: 1 },
        { headerName: "Pending Amount", field: "pendingAmount", flex: 1 },
        {
            headerName: "Action", field: "action",
            renderCell: params => (
                <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <MoreVertIcon />
                </Box>
            )
        },
    ];
    const activeColumnsColleges = [
        {
            headerName: "Admission No", field: "itemNumber", flex: 1,
            renderCell: params => (
                <Typography sx={{ color: "#33699d", textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate(`/school/student/${params.row.customerId}/${params.value}`)}>
                    {params.value}
                </Typography>
            )
        },
        {
            headerName: "Student Name", field: "studentName", flex: 1,
            renderCell: params => (
                <Typography>
                    {params.row.firstName} {params.row.lastName}
                </Typography>
            )
        },
        { headerName: "Course", field: "grade", flex: 1 },
        { headerName: "Mobile Number", field: "parentPhone", flex: 1 },
        { headerName: "Pending Invoices", field: "pendingInvoices", flex: 1 },
        { headerName: "Pending Amount", field: "pendingAmount", flex: 1 },
        {
            headerName: "Action", field: "action",
            renderCell: params => (
                <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <MoreVertIcon />
                </Box>
            )
        },
    ];


    function formatDate(inputDate) {
        const dateObj = new Date(inputDate);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return dateObj.toLocaleDateString('en-US', options);
    }

    const suspendedColumns = [
        {
            headerName: "Parent Name", field: "customerName", flex: 1,
            renderCell: params => (
                <Typography>
                    {params.value}
                </Typography>
            )
        },
        { headerName: "Mobile No", field: "mobileNumber", flex: 1, },
        { headerName: "Email", field: "email", flex: 1 },
        {
            headerName: "Students", field: "billableItems", flex: 1,
            renderCell: params => (
                <Typography>
                    {params.value?.length}
                </Typography>
            )
        },
        {
            headerName: "Deactivated On", field: "createdOn", flex: 1,
            renderCell: params => (
                <Typography>
                    {formatDate(params.value)}
                </Typography>
            )
        },
        {
            headerName: "Action", field: "action", flex: 1,
            renderCell: params => (
                <Box sx={{ display: "flex" }}>
                    <Box sx={{
                        width: "1.313rem",
                        height: "1.313rem",
                        borderRadius: "50%",
                        border: "1px solid #17ae7b",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        marginX: 0.5,
                        cursor: "pointer"
                    }}
                    >
                        <ReplayIcon sx={{ color: "#17ae7b", alignSelf: "center", fontSize: "1rem" }} />
                    </Box>
                </Box>
            )
        },
    ];

    const csvColumns = [
        { label: "Admission No", key: "Admission No" },
        { label: "Student Name", key: "Student Name" },
        { label: "Grade", key: "Grade" },
        { label: "Stream", key: "Stream" },
        { label: "Boarding Status", key: "Boarding Status" },
        { label: "Parent Name", key: "Parent Name" },
        { label: "Mobile Number", key: "Mobile Number" },
        { label: "Pending Invoices", key: "Pending Invoices" },
        { label: "Pending Amount", key: "Pending Amount" },
    ];
    const csvColumnsColleges = [
        { label: "Admission No", key: "Admission No" },
        { label: "Student Name", key: "Student Name" },
        { label: "Course", key: "Grade" },
        { label: "Mobile Number", key: "Mobile Number" },
        { label: "Pending Invoices", key: "Pending Invoices" },
        { label: "Pending Amount", key: "Pending Amount" },
    ];

    const fileHeaders = [[
        "Admission No",
        "Student Name",
        "Grade",
        "Stream",
        "Boarding Status",
        "Parent Name",
        "Mobile Number",
        "Pending Invoices",
        "Pending Amount"
    ]]
    const fileHeadersColleges = [[
        "Admission No",
        "Student Name",
        "Course",
        "Mobile Number",
        "Pending Invoices",
        "Pending Amount"
    ]]

    const fileDataPDF = dataToShow.map((student) => [
        student?.itemNumber,
        `${student?.firstName} ${student?.lastName}`,
        student?.grade,
        student?.stream,
        student?.boardingStatus,
        student?.parentName,
        student?.parentPhone,
        student?.pendingInvoices,
        student?.pendingAmount,
    ]);
    const fileDataPDFColleges = dataToShow.map((student) => [
        student?.itemNumber,
        `${student?.firstName} ${student?.lastName}`,
        student?.grade,
        student?.parentPhone,
        student?.pendingInvoices,
        student?.pendingAmount,
    ]);


    const fileData = dataToShow.map((student) => {
        return {
            "Admission No": student?.itemNumber,
            "Student Name": `${student?.firstName} ${student?.lastName}`,
            "Grade": student?.grade,
            "Stream": student?.stream,
            "Boarding Status": student?.boardingStatus,
            "Parent Name": student?.parentName,
            "Mobile Number": student?.parentPhone,
            "Pending Invoices": student?.pendingInvoices,
            "Pending Amount": student?.pendingAmount,
        };
    });
    const fileDataColleges = dataToShow.map((student) => {
        return {
            "Admission No": student?.itemNumber,
            "Student Name": `${student?.firstName} ${student?.lastName}`,
            "Course": student?.grade,
            "Mobile Number": student?.parentPhone,
            "Pending Invoices": student?.pendingInvoices,
            "Pending Amount": student?.pendingAmount,
        };
    });
    const businessName = localStorage.getItem('businessName')


    return (
        <Box component="div">
            <Box component="div" sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box component="div">
                    <Box component="div" sx={{}}>
                        <Typography sx={{ color: "#032541", fontSize: "1.563rem", fontWeight: 700 }}>
                            Students
                        </Typography>
                    </Box>
                    <Box component="div" sx={{}}>
                        <Breadcrumbs
                            separator={<FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}
                            aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Box>
                </Box>
                {schoolTypeName.includes("University") ? 
                <Box component="div" sx={{ gap: 2 }}>

                    <Button sx={{
                        "width": "125px",
                        "height": "45px",
                        "borderRadius": "4px",
                        "backgroundColor": "#f5f6f7",
                        marginX: 1,
                        color: "#032541",
                        "&:hover": {
                            color: "#032541",
                            "backgroundColor": "#f5f6f7",
                        },
                    }} onClick={() => navigate("/school/parent/add/upload")}>
                        Upload File
                    </Button>

                    <Button sx={createButton} onClick={() => navigate("/school/parent/add")}>
                        Add
                    </Button>
                </Box>
                : null}

            </Box>



            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                        onChange={handleTabChange}
                        TabIndicatorProps={{
                            style: { display: 'none' },
                        }}
                    >
                        <Tab label="Active" value="ACTIVE" centered sx={{
                            color: '#6e7074',
                            '&.Mui-selected': {
                                color: '#dc3545',
                            }
                        }} />
                        <Tab label="Transferred" value="Transferred" centered sx={{
                            color: '#6e7074',
                            '&.Mui-selected': {
                                color: '#dc3545',
                            }
                        }} />

                        <Tab label="Suspended" value="Suspended" centered sx={{
                            color: '#6e7074',
                            '&.Mui-selected': {
                                color: '#dc3545',
                            }
                        }} />

                        <Tab label="Expelled" value="Expelled" centered sx={{
                            color: '#6e7074',
                            '&.Mui-selected': {
                                color: '#dc3545',
                            }
                        }} />

                    </TabList>
                </Box>

                <TabPanel value="ACTIVE">

                    <Box component="div" sx={{ display: "flex", flexDirection: "column" }}>
                        <Box component="div" sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <CustomSearchInput name={"search"} value={formData.search} onChange={handleInputChange} placeholder="Search:" />
                            </Box>
                            <ExportMenu
                                csvColumns={schoolTypeName.includes("University") ? csvColumnsColleges  : csvColumns}
                                fileData={schoolTypeName.includes("University") ? fileDataColleges : fileData}
                                fileHeaders={schoolTypeName.includes("University") ? fileHeadersColleges : fileHeaders}
                                fileDataPDF={schoolTypeName.includes("University") ? fileDataPDFColleges : fileDataPDF}
                                title={"BusinessName :" + businessName + "Report Type: " + "   Active Students Report  " + "Print Date : " + new Date().toLocaleDateString()}
                                fileName={"ActiveStudents"}
                            />
                        </Box>
                        <CustomTable loading={gridLoading} data={dataToShow} columns={schoolTypeName.includes("University") ?activeColumnsColleges  : activeColumns} rowsPerPage={5} />
                    </Box>

                </TabPanel>

                <TabPanel value="Transferred">


                </TabPanel>

                <TabPanel value="Suspended">


                </TabPanel>

                <TabPanel value="Expelled">


                </TabPanel>

            </TabContext>
        </Box>
    )
}

export default SchoolListStudents;
