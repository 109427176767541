import React, { useState, useEffect } from "react";
import  { Grid } from "@mui/material"
import { ArrowBackIos } from "@material-ui/icons";
import { useNavigate, useParams } from "react-router-dom";
import { stockStyles } from "./stockStyling";
import Breadcrumbcomponent from "./breadcrumb";
import Stockordersetup from "./stockModals/stockOrderSetup";
import StockOrderProductsAndCategory from "./stockModals/stockOrderProductsAndCategory";
import StockOrderViewBatch from "./stockModals/stockOrderViewBatch";
import PurchaseOrderPreview from "./stockModals/purchaseOrderPreview";

const StockOrder = () => {

    const navigate = useNavigate();
    const breadcrumb = [<span key={1}>Dashboard</span>, <span key={2}>Stock</span>, <span key={3} style={{color:"red"}}>Stock Order</span>];

    const { state } = useParams();

    const queryParams = decodeURIComponent(window.location.search)
    const queryParamsData = queryParams.slice(1)
    const storeId = queryParamsData.split("?")[0]
    const supplierId = queryParamsData.split("?")[1]

    const componentToMount = () => {
        if(state === "newOrder"){
            return <Stockordersetup/>
        }else if(state === "categories"){
            return <StockOrderProductsAndCategory storeId={storeId} supplierId={supplierId} />
        }else if(state === "viewBatch"){
            return <StockOrderViewBatch storeId={storeId} supplierId={supplierId} />
        }else if(state === "purchaseOrderPreview"){
            return <PurchaseOrderPreview storeId={storeId} supplierId={supplierId} />
        }
    }
  return (
    <div>
      <Grid container direction={"column"} margin={"0px 0px 2% 0px"}>
        <Grid item width={"100%"} margin={"0px 0px 1% 0px"} style={{ display: "flex", alignItems: "center" }}>
          <span>
            <ArrowBackIos style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
          </span>
          <span style={stockStyles.mediumIntroText}>Stock Management</span>
        </Grid>

        <Grid item width={"100%"} alignItems={"center"} justifyContent={"space-between"}  margin={"0px 0px 0% 0px"}>
          <Breadcrumbcomponent breadcrumbs={breadcrumb} />
        </Grid>
      </Grid>

      <Grid container width={"100%"}>
        {componentToMount()}
      </Grid>
    </div>
  );
};

export default StockOrder;
