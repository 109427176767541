import {Button, Grid} from "@mui/material";
import React, {useEffect, useState, useRef} from "react";
import {useNavigate} from "react-router-dom";
import welcomeEmoji from '../../common/images/welcomeEmoji.svg'
import {clearAdditions} from "../../features/customerSlice";
import {useDispatch, useSelector} from "react-redux";
import { handlePageReload } from "../../features/autorefreshonpageload";
// Base Url
const baseUrl = process.env.REACT_APP_BASE_URL;


export default function NewUserDashboard() {

	const navigate = useNavigate()
	const username = localStorage.getItem('username')
	const dispatch = useDispatch();

	

	const { isPageLoaded } = useSelector((store) => store.pageload);

	
	// const handleForceUpdate = useForceUpdate();
	// // run auto refresh 
	const autoRefreshOnPageload = () => {
		setTimeout(() => {
			window.location.reload(false)
		}, 1000);
		handleForceUpdate()
		
			dispatch(handlePageReload())
	}
	useEffect(() => {
		isPageLoaded === false  ? autoRefreshOnPageload() : null
	},[])


	return (
		<div style={{fontFamily : 'Poppins'}}>

			{/*Emoji*/}
			<Grid container justifyContent={'center'} style={{marginTop : '15px'}}>

				<Grid item>
					<img src={welcomeEmoji} alt='welcome emoji' />
				</Grid>

			</Grid>

			{/*Welcome User*/}
			<Grid container justifyContent={'center'} style={{marginTop : '30px'}}>
				<Grid item style={{color : '#707070', fontSize : '25px', fontWeight : 500}} >
					<span>Welcome <span>{username}</span></span>
				</Grid>
			</Grid>


			{/*Welcome Statement*/}
			<Grid container justifyContent={'center'} style={{marginTop : '15px', alignText : 'center', textAlign : 'center'}} alignContent={'center'}>
				<Grid item style={{color : '#032541', fontSize : '25px', fontWeight : 600, width : '600px'}}>
					<span>We are here to help you create a business and accept payments easily.</span>
				</Grid>
			</Grid>

			{/*Post Welcome Statement*/}
			<Grid container justifyContent={'center'} style={{marginTop : '15px'}} >
				<Grid item style={{color : '#707070', fontSize : '16px', fontWeight : 500}} >
					<span>Create Business Today or Request to Join one</span>
				</Grid>
			</Grid>

			{/*Buttons*/}
			<Grid container justifyContent={'center'} direction={'column'} alignContent={'center'} spacing={5} style={{marginTop : '15px'}} >

				{/*Join Business*/}
				<Grid item>
					<Button style={{padding : '5px', borderRadius : '5px', border: 'solid 1px #002543', textTransform : 'none', fontSize : '17px', fontWeight : 500, height : '61px', width : '191px', color : '#032541'}}>Join Business</Button>
				</Grid>

				{/*Create business*/}
				<Grid item>
					<Button onClick={() => {
						dispatch(clearAdditions())
						navigate('/createBusiness')
					}} style={{padding : '5px', borderRadius : '5px', border: 'solid 1px #002543', textTransform : 'none', fontSize : '17px', fontWeight : 500, height : '61px', width : '191px', color : '#fff', background : '#032541'}}> Create Business</Button>
				</Grid>

			</Grid>
		</div>
	);
}
