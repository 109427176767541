import React, { useState, useEffect } from "react";
import CustomDataGrid from "./customDataGrid";
import Breadcrumbcomponent from "./breadcrumb";
import { Button, Grid } from "@mui/material";
import { ArrowBackIos } from "@material-ui/icons";
import { stockStyles } from "./stockStyling";
import SearchFilter from "../../customerAccounts/customerComponents/searchFilter";
import StockFunctions from "./stockEndpoints";
import { useParams, useNavigate } from "react-router-dom";
import { ApproveStockModal, DeclineStockModal } from "./stockModals/approvalsModal";
import { SuccessAlertRightAligned } from "../../bills/startorderModals/modals";
const ViewMoreStock = () => {
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const { id, status } = useParams();

  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize, mainstores: [], warehouseLoading: true, mainstorestock: [] });

  const productsColumn = [
    { field: "productName", headerName: "Product Name", flex: 1 },
    { field: "inStockQuantity", headerName: "In Stock", flex: 1 },
    { field: "newQuantity", headerName: "New Quantity", flex: 1 },
    { field: "buyingPrice", headerName: "Buying Price", flex: 1 },
  ];

  useEffect(() => {
    const batchId = { batchId: id };
    const stockfunctions = new StockFunctions();
    if(status === "pending" || status === "approved")
    stockfunctions?.getBatchProductsById(batchId, pageState?.page, dataGridPageSize, batchId).then((data) => {
      setPageState({ ...pageState, data: data?.response?.data, count: data?.response?.count, isLoading: false });
    });
  }, [status]);

  const productRows = pageState?.data?.map((product, index) => ({
    id: product?.productId || product._id,
    productName: product?.productName,
    inStockQuantity: product?.inStockQuantity,
    newQuantity: product?.newQuantity || product?.quantity,
    buyingPrice: product?.buyingPrice,
  }));
  

  const breadcrumb = [<span>Dashboard</span>, <span>Stock</span>, <span>Approvals</span>, <span style={{ color: "red" }}>View Batch</span>];

  const [approveStockModal , setApproveStock ] = useState(false);
  const [isSuccess, setSuccess] = useState({ state: false, message: "" });


  const approveStock = () => {
    const stockfunctions = new StockFunctions();
    const listBatchIds = { listBatchIds: [id] };
    stockfunctions.approveAddStockBatch(listBatchIds).then((data) => {
      if (data.status === 200) {
        setSuccess({ state: true , message:"Batch approved succesfully!"});
        setApproveStock(false);
      }
      setTimeout(() => {
        setSuccess({ state: false });
        navigate(`/viewmoreStock/approved/${id}`)
      }, 2000);
    });
  };

  
  const [openDeclineModal, setOpenDeclineModal] = useState(false);

  const declineStockRequest = () => {

    const stockfunctions = new StockFunctions();
    const listBatchIds = { stockId : id };
    stockfunctions.declineAddstockBatch(listBatchIds).then((data) => {
      if (data.status === 201) {
        setSuccess({ state: true , message:"Batch request declined successfully!" });
        setOpenDeclineModal(false);
      }
      setTimeout(() => {
        setSuccess({ state: false });
        navigate(`/viewmoreStock/approved/${id}`)
      }, 2000);
    });
  }

  // get stockTransfer products
  useEffect(() => {
    const stockfunctions = new StockFunctions();
    if(status === "transferPending"){
      const batch = {batchId: id}
      stockfunctions.getStockTransferProductsById(1, 1000, batch).then((data) => {
        setPageState({ ...pageState, data: data?.response?.data, count: data?.response?.count, isLoading: false });
      })
    }

  }, [status])


  const declineTransferStock = () => {
    const stockfunctions = new StockFunctions();
    const batch = {batchIds : id}
    stockfunctions.declineStockTransfer(batch).then((data) => {
      if (data.status === 200) {
        setSuccess({ state: true , message:"Batch request declined successfully!" });
        setOpenDeclineModal(false);
      }
      setTimeout(() => {
        setSuccess({ state: false });
        navigate(`/viewmoreStock/approved/${id}`)
      }, 2000);
    })
  }


  const approveStockTransfer = () => {
    const stockfunctions = new StockFunctions();
    const listBatchIds = { batchIds: [id] };
    stockfunctions.approveStockTransfer(listBatchIds).then((data) => {
      if (data.status === 200) {
        setSuccess({ state: true , message:"Batch approved succesfully!"});
        setApproveStock(false);
      }
      setTimeout(() => {
        setSuccess({ state: false });
        navigate(`/viewmoreStock/approved/${id}`)
      }, 2000);
    });
  };

  const batchAdded = <SuccessAlertRightAligned sucess={isSuccess.state} message={isSuccess.message} />;

  return (
    <div style={{ width: "100%" }}>


      {isSuccess.state && batchAdded}

      <Grid container direction={"column"} width={"100%"} alignItems={"center"} margin={"0% 0% 3% 0%"}>
        <Grid item width={"100%"} sx={{ display: "flex", alignItems: "center", cursor: "pointer", margin: "0% 0% 1% 0%" }}>
          <ArrowBackIos onClick={() => navigate(-1)} />
          <span style={stockStyles?.mediumIntroText}>Stock Management</span>
        </Grid>
        <Grid item width={"100%"}>
          <Breadcrumbcomponent breadcrumbs={breadcrumb} />
        </Grid>
      </Grid>

      {/* <Grid container direction={"row"} width={"100%"} alignItems={"center"} justifyContent={"space-between"} sx={{ backgroundColor: " rgba(23, 174, 123, 0.05)", padding: "10px", margin: "0% 0% 1% 0%" }}>
        <Grid item>
          <span style={{margin:"0px 10px 0px 0px"}}>Batch No</span>
          <span></span>
        </Grid>
        <Grid item>
          <span>Business Name</span>
          <span></span>
        </Grid>
        <Grid item>
          <span>From</span>
          <span></span>
        </Grid>
        <Grid item>
          <span>To</span>
          <span></span>
        </Grid>
        <Grid item>
          <span>Approved By</span>
          <span></span>
        </Grid>
      </Grid> */}

      <Grid container direction={"row"} margin={"0% 0% 1% 0%"}>
        <Grid item>
          <SearchFilter placeholder={"Search"} setInputValue={setInputValue} />
        </Grid>
      </Grid>

      <Grid container sx={{ width: "100%" }}>
        <Grid item width={"100%"}>
          <CustomDataGrid column={productsColumn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={productRows} />
        </Grid>
      </Grid>

      {status && status === "pending" || status === "transferPending" && (
        <Grid container direction="row" alignItems="center" justifyContent="flex-end" spacing={2} width={"100%"}>
          <Grid item>
            {" "}
            <Button style={stockStyles?.cancelBatchButton} onClick={() => setOpenDeclineModal(true)} >Decline</Button>
          </Grid>
          <Grid item>
            {" "}
            <Button style={stockStyles?.approveButton} onClick={() => setApproveStock(true)} >Approve</Button>
          </Grid>
        </Grid>
      )}


      <ApproveStockModal  approveStockModal={approveStockModal} approveStock={approveStock} setApproveStock={setApproveStock} status={status} approveStockTransfer={approveStockTransfer} />
      <DeclineStockModal openDeclineModal={openDeclineModal} setOpenDeclineModal={setOpenDeclineModal} declineStockRequest={declineStockRequest} status={status} declineTransferStock={declineTransferStock} />
    </div>
  );
};

export default ViewMoreStock;
