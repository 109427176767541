import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import Dash from "../../common/dash";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import { Grid, Select, Tab } from "@mui/material";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ElIf, If } from "rc-if-else";
import Menu, { MenuProps } from "@mui/material/Menu";
import { alpha, styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@material-ui/core/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import deactivateTerminal from "../../common/images/deactivate.svg";
import success from "../../common/images/success.png";
import deactivate from "../../common/images/deactivate.svg";
import activateGroup from "../../common/images/activate.svg";
import deactivateGroup from "../../common/images/deactivate.svg";
import session from "../../common/images/session.svg";
import { SessionModal } from "../Modals/SessionExpiredModal/sessionModal";
import { SuccessModal } from "../Modals/SuccessModal/successModal";
import { QuestionModal } from "../Modals/QuestionModal/questionModal";

// session Expired
const style = {
  borderRadius: "36px",
  boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
  backgroundColor: "#fff",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "660px",
  height: "570px",
  p: 4,
};

const baseUrl = process.env.REACT_APP_BASE_URL;
//const baseUrl = "https://zed.swerri.io"

const AddTerminalModalStyle = {
  width: "553px",
  height: "448px",
  margin: "9% auto 0px auto",
  padding: "0 0 30.8px",
  borderRadius: "15px",
  backgroundColor: "#fff",
  align: "center",
};

const SuccessModalStyle = {
  width: "506px",
  height: "506px",
  margin: "10% Auto 0 Auto",
  padding: "86px 24px 97.7px 61px",
  borderRadius: "36px",
  boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
  backgroundColor: "#fff",
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": { padding: "4px 0" },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const columns = [
  {
    field: "serialNo",
    headerName: "Serial No",
    flex: 1,
    headerAlign: "left",
    align: "left",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"Serial No"}
      </strong>
    ),
  },
  {
    field: "branch",
    headerName: "Branch",
    flex: 1,
    align: "left",
    headerAlign: "left",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"Branch"}
      </strong>
    ),
  },
  {
    field: "alias",
    headerName: "Alias",
    flex: 1,
    align: "left",
    headerAlign: "left",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"Alias"}
      </strong>
    ),
  },
  {
    field: "action",
    headerName: "Action",
    flex: 1,
    filter: "text",
    align: "left",
    headerAlign: "left",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"Action"}
      </strong>
    ),
  },
];

const BusinessTerminals = () => {
  // session Modal Config
  const [sessionOpen, setSessionOpen] = React.useState(false);
  const handleSessionOpen = () => setSessionOpen(true);
  const handleSessionClose = () => setSessionOpen(false);
  const handleLogin = () => {
    window.location.href = "/logout";
  };

  //Add terminal Modal config
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Existing terminal Modal config
  const [existingUserModal, setExistingUserModalOpen] = useState(false);
  const existingUserModalHandleOpen = () => setExistingUserModalOpen(true);
  const existingUserModalHandleClose = () => setExistingUserModalOpen(false);

  // Success Modal Open
  // Success Edit Modal Control
  const [successOpen, setSuccessOpen] = React.useState(false);
  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleSuccessClose = () => setSuccessOpen(false);

  // Post Data to API
  const [serialNumber, setTerminalNumber] = useState("");
  const [transactions, setTransactions] = useState([]);


  const fetchUrl = baseUrl + "/api/allTerminals";
  const postUrl = baseUrl + "/api/createTerminal";
  const shopsUrl = baseUrl + "/api/listBusiness";

  // Add a New terminal
  async function addNewTerminalHandler(event) {
    event.preventDefault();
    try {
      const response = await fetch(postUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({
          terminalSerialNumber: serialNumber,
          deviceModel: deviceModel,
          createdBy: localStorage.getItem("username"),
        }),
      });
      const data = await response.json();
      if (response.status === 201 && data.Status === "SUCCESS") {
        setTerminalNumber("");
        setDeviceModel("");
        handleClose();
        handleSuccessOpen();
      } else if (response.status === 401) {
        handleSessionOpen();
      } else if (data.message === "Terminal already exists") {
        handleClose();
        existingUserModalHandleOpen();
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Fetch all Active shops Created
  const [businessData, setBusinessData] = useState([]);

  const fetchActiveShops = async () => {
    try {
      const response = await fetch(shopsUrl + "/Active", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      const data = await response.json();
      if (response.status === 200) {
        setBusinessData(data.data);
      } else if (response.status === 401) {
        handleSessionOpen();
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchActiveShops();
  }, []);

  //Business Terminals data
  const rowData = transactions.map((transaction) => {
    return {
      id: transaction?._id,
      businessName: transaction?.businessName,
      serialNo: transaction?.serialNo,
      alias: transaction?.alias
    };
  });

  // page size setter and getter
  const [pageSize, setPageSize] = React.useState(5);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const handleSearchBox = (event) => {
    setSearchValue(event.target.value);
  };

  const filterBySearchBox = (data) => {
    return data.filter((item) => {
      if (
        item.serialNumber.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.businessName.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.alias.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        return item;
      }
    });
  };

  useEffect(() => {
    let filteredData = rowData;

    filteredData = filterBySearchBox(filteredData);
    setFilteredData(filteredData);
  }, [searchValue]);

  // useEffect(() => {
  //   setFilteredData(activeRowData);
  // }, []);

  // Search for tab in which data exists
  // useEffect(() => {
  //   let filteredData = activeRowData;
  //   filteredData = setFilteredData(filteredData);
  // }, [tabValue, searchValue]);

  // Search for data in which current tab
  // useEffect(() => {
  //   let filteredData = activeRowData;
  //   filteredData = filterBySearchBox(filteredData);
  //   setFilteredData(filteredData);
  // }, [activeTerminal, searchValue]);

  return (
    <div style={{ fontFamily: "Poppins" }}>
      <div>
        <div>
          <div>
            <Box>
              <Grid container>
                <Grid item marginTop="22px" marginBottom="22px">
                  <span
                    style={{
                      width: "78px",
                      height: "35px",
                      margin: "0px 41px 22px 12px",
                      fontFamily: "Poppins",
                      fontSize: "25px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.32",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#dc3545",
                    }}
                  >
                    Businesses
                  </span>
                </Grid>
              </Grid>

              <Grid container columns={2} marginBottom="22px">
                <Grid item xs={1}>
                  <span
                    style={{
                      width: "265px",
                      height: "28px",
                      margin: "21px 0px 39px 12px",
                      fontFamily: "Poppins",
                      fontSize: "20px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.65",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Dashboard
                  </span>
                  <span
                    style={{
                      width: "265px",
                      height: "28px",
                      margin: "21px 0px 39px 12px",
                      fontFamily: "Poppins",
                      fontSize: "20px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.65",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    \
                  </span>
                  <span
                    style={{
                      width: "265px",
                      height: "28px",
                      margin: "21px 0px 39px 12px",
                      fontFamily: "Poppins",
                      fontSize: "20px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.65",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    Business Terminals
                  </span>
                </Grid>

                <Grid item xs={0.68}></Grid>

                {/* <Grid item>
                  {localStorage.getItem("group") === "Admin" ? (
                    <Button
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "9px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "2.78",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#f5f5f5",
                        width: "136px",
                        height: "37px",
                        padding: "12px 8.1px 12px 11px",
                        borderRadius: "8px",
                        backgroundColor: "#032541",
                      }}
                      onClick={handleOpen}
                      startIcon={<AddIcon />}
                    >
                      Add Terminal
                    </Button>
                  ) : (
                    ""
                  )}
                </Grid> */}
              </Grid>

              <Grid container columns={1} sx={{ marginTop: "22px" }}>
                <Grid item xs={0.9} md={0.755}></Grid>

                <Grid item>
                  <label
                    style={{
                      width: "35px",
                      height: "12px",
                      fontFamily: "Poppins",
                      fontSize: "9px",
                      fontWeight: "bold",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "2.78",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    Search:
                  </label>
                  <input
                    value={searchValue}
                    onChange={handleSearchBox}
                    style={{
                      fontSize: "12px",
                      color: "#272d3b",
                      borderRadius: "6px",
                      width: "238px",
                      height: "36px",
                      margin: "0 0 0 20.4px",
                      padding: "7px 107.7px 6.4px 11.1px",
                      objectFit: "contain",
                      border: "solid 1px #032541",
                    }}
                    placeholder="search here"
                  ></input>
                </Grid>
              </Grid>

              <Grid
                sx={{
                  height: "500px",
                  margin: "50px 0 0 0",
                  border: "solid 0px transparent",
                  backgroundColor: "#fff",
                }}
              >
                <DataGrid
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "10px",
                    color: "#272d3b",
                  }}
                  rows={filteredData}
                  columns={columns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  pagination
                ></DataGrid>
              </Grid>

            </Box>

            {/*sessionModal*/}
            <SessionModal
              onClose={handleSessionClose}
              sessionOpen={sessionOpen}
            />

            {/*success terminal creation*/}
            <SuccessModal
              onClose={handleSuccessClose}
              editConfigOpen={successOpen}
              modalRow={serialNumber}
              successObject={"Terminal"}
              successType={"Created"}
            />

            {/*Existing Terminal Modal*/}
            <Modal
              centered
              align={"center"}
              className="container container-fluid"
              open={existingUserModal}
              onClose={existingUserModalHandleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div
                style={{
                  width: "506px",
                  height: "506px",
                  margin: "10% 16px 23px 45px",
                  padding: "87px 75px 92.7px 34px",
                  borderRadius: "36px",
                  boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
                  backgroundColor: "#fff",
                }}
              >
                <img src={deactivate} alt={"success deactivation"} />
                <label
                  style={{
                    width: "356px",
                    height: "65px",
                    margin: "37.8px 0 0",
                    fontFamily: "Poppins",
                    fontSize: "25px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.2",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#6e7074",
                  }}
                >
                  Terminal is Existing!.
                </label>
                <Button
                  onClick={() => {
                    existingUserModalHandleClose();
                    window.location.reload(true);
                  }}
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "25px",
                    fontWeight: "600",
                    color: "#fff",
                    width: "124.5px",
                    height: "52.3px",
                    padding: "8px 44.5px 9.3px 35px",
                    backgroundColor: "#032541",
                  }}
                >
                  OK
                </Button>
              </div>
            </Modal>

            {/*Add Terminal Modal*/}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="container-fluid"
            >
              <div style={AddTerminalModalStyle}>
                <div
                  style={{
                    width: "553px",
                    height: "90px",
                    margin: "0 0 9px",
                    padding: "35px 30px 34px",
                    borderRadius: "16px",
                    backgroundColor: "#dc3545",
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    style={{
                      width: "156px",
                      height: "21px",
                      margin: "0 433px 0 0",
                      fontFamily: "Poppins",
                      fontSize: "15px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#fff",
                    }}
                  >
                    Add Terminal
                  </Typography>
                </div>
                <div
                  style={{
                    width: "498.2px",
                    height: "309.2px",
                    margin: "18px 27.8px 0 27px",
                    padding: "37px 47.2px 37px 47px",
                    backgroundColor: "#f5f7ff",
                  }}
                >
                  <form onSubmit={addNewTerminalHandler}>
                    {/*Serial No*/}
                    <label
                      style={{
                        width: "80px",
                        height: "20px",
                        margin: "0 335px 10px 0",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "3.14",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#6e7074",
                      }}
                    >
                      Serial No.
                    </label>
                    <input
                      value={serialNumber}
                      onChange={(e) => setTerminalNumber(e.target.value)}
                      maxLength="50"
                      required
                      style={{
                        fontFamily: "Poppins",
                        width: "404px",
                        height: "39px",
                        margin: "10px 0 0",
                        objectFit: "contain",
                        borderRadius: "4px",
                        border: "solid 1px #dfdede",
                        backgroundColor: "#fff",
                      }}
                      type="text"
                    />

                    {/*Pos Model*/}
                    {/* <label
                      style={{
                        width: "80px",
                        height: "20px",
                        margin: "0 335px 10px 0",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "3.14",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#6e7074",
                      }}
                    >
                      POS Model
                    </label>
                    <input
                      value={deviceModel}
                      onChange={(e) => setDeviceModel(e.target.value)}
                      maxLength="50"
                      required
                      style={{
                        padding: "16px 131px 8px 0",
                        fontFamily: "Poppins",
                        width: "404px",
                        height: "39px",
                        margin: "10px 0 0",
                        objectFit: "contain",
                        borderRadius: "4px",
                        border: "solid 1px #dfdede",
                        backgroundColor: "#fff",
                      }}
                      type="text"
                    /> */}

                    <div
                      align={"right"}
                      style={{ height: "33.1px", margin: "40px 0 0 0px" }}
                    >
                      <Button
                        type="close"
                        onClick={handleClose}
                        style={{
                          width: "90px",
                          height: "33.1px",
                          margin: "0 30px 0 0",
                          padding: "9px 30px 8.1px 29px",
                          backgroundColor: "#dc3545",
                          color: "#fff",
                        }}
                      >
                        Close
                      </Button>
                      <Button
                        type="submit"
                        style={{
                          width: "90px",
                          height: "33.1px",
                          margin: "0 0 0 30px",
                          padding: "9px 32px 8.1px 31px",
                          backgroundColor: "#032541",
                          color: "#fff",
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </Modal>

          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessTerminals;