import { Link, useParams } from "react-router-dom"
import HttpComponent from "../../../School/MakeRequest";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material"
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@mui/material";
import DateFormatter from "../../../../utils/dateFormatter";
import CurrencyFormatter from "../../../../utils/currencyFormatter";

const useStyles = makeStyles((theme) => ({
    address_text_common: {
        fontSize: "13px",
        fontWeight: "300",
    },
    table_titles: {
        fontSize: "14px",
        fontWeight: "700",
    },
    table_body: {
        fontSize: "13px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
    },
    footer_text: {
        fontSize: "10px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
    },
    totals: {
        fontSize: "14px",
        padding: theme.spacing(0.25),
    },
    p1: {
        padding: theme.spacing(0.25),
    },
    p2: {
        padding: theme.spacing(0.5),
    },
    p3: {
        padding: theme.spacing(1),
    },
    mt4: {
        marginTop: theme.spacing(4),
    },
    m1: {
        margin: theme.spacing(1),
    },
    textLeft: {
        textAlign: "left",
    },
    textCenter: {
        textAlign: "center",
    },
    textRight: {
        textAlign: "right",
    },
}));

const NoticePdf = (props) => {
    const { noticeNumber } = useParams();
    const { X_Authorization } = useSelector((store) => store.user)
    const [noticeDataDetails, setNoticeDataDetails] = useState({})
    const classes = useStyles();

    //fetch by notice id

    function postEvictionNoticeById() {
        const obj = { noticeNumber: noticeNumber }
        try {
            HttpComponent({
                method: "POST",
                url: `/api/get_eviction_notice_by_noticeId`,
                body: obj,
                token: X_Authorization,
            }).then((data) => {
                console.log(data, 'notice pdf')
                setNoticeDataDetails(data.response.data)

            });

        } catch (error) {

        }
    }

    useEffect(() => {
        postEvictionNoticeById();
    }, [])

    //download pdf

    const downloadPDF = () => {
        const input = document.getElementById('downloadable');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png', 1.0);
                const inputWidthPx = input.clientWidth;
                const inputHeightPx = input.clientHeight;
                const dpi = window.devicePixelRatio * 96;
                let scaleFactor = 1;
                if (window.innerWidth > 1512) {
                    scaleFactor = 1.4;
                }
                const inputWidthMm = (inputWidthPx / dpi) * 25.4 / scaleFactor;
                const inputHeightMm = (inputHeightPx / dpi) * 25.4;
                const pdf = new jsPDF("p", "mm", [inputWidthMm, inputHeightMm]);
                pdf.addImage(imgData, 'JPEG', 5, 5, inputWidthMm - 10, inputHeightMm - 10);
                pdf.save(`Exit notice.pdf`);
            })
    }



    return (
        <>
            <div style={{ width: "80%", marginLeft: "10%" }}>
                <div>
                    <div className="notice-header" style={{ background: '#fafafa', height: "177px" }}>
                        <div className="px-2">
                        <Typography style={{ fontSize: "30px", fontWeight: "bolder"  }}  py={3} variant={'h1'}>NOTICE TO VACATE</Typography>
                        </div>
                        <div className="d-flex justify-content-between px-2">
                            <div className="date-notice ">
                                <Typography  style={{ fontWeight: "900"  }}>Date</Typography>
                                <span>{DateFormatter(noticeDataDetails?.createdAt)}</span>
                            </div>
                            <div>
                                <Typography  style={{ fontWeight: "900" ,justifyContent:'flex-end' ,textAlign:"end"  }}>Notice No</Typography>
                                <span style={{ alignItems: 'flex-end', display: "flex", flexDirection: "column" }}>{noticeDataDetails?.noticeNumber}</span>
                            </div>
                        </div>
                    </div>
                    <div className="notice-from mt-4 d-flex justify-content-between">
                        <Typography variant={'body2'} fontSize={20}>From</Typography>
                        <Typography variant={'body2'} fontSize={20}>To</Typography>
                     </div>
                        <div className="d-flex justify-content-between">
                            <div className="notice-from py-3">
                                <Typography  style={{ fontWeight: "900"  }}>{noticeDataDetails?.noticeFrom?.name}</Typography>
                                <Typography>{noticeDataDetails?.noticeFrom?.location}</Typography>
                                <Typography>{noticeDataDetails?.noticeFrom?.email}</Typography>
                                <Typography>{noticeDataDetails?.noticeFrom?.phoneNumber}</Typography>

                            </div>
                            <div className="notice-to py-3" style={{ alignItems: 'flex-end', display: "flex", flexDirection: "column" }}>
                                <Typography  style={{ fontWeight: "900"  }}>{noticeDataDetails?.noticeTo?.name}</Typography>
                                <Typography>{noticeDataDetails?.noticeTo?.location}</Typography>
                                <Typography>{noticeDataDetails?.noticeTo?.email}</Typography>
                                <Typography>{noticeDataDetails?.noticeTo?.phoneNumber}</Typography>
                            </div>
                        </div>
                    
                    <div className="vacation-date py-3">
                        <Typography style={{ fontWeight: "900"  }}>Vacation Date</Typography>
                            <span>{DateFormatter(noticeDataDetails?.dateForEviction) }</span>
                     
                    </div>
                    <div className="vacation-invoice py-3">
                        {/* <div className="d-flex justify-content-between">
                            <div className="notice-from">
                                <Typography  style={{ fontWeight: "900"  }}>Invoice No:</Typography>
                            </div>
                            <div className="notice-to" style={{ alignItems: 'flex-end', display: "flex", flexDirection: "column" }}>
                                <Typography  style={{ fontWeight: "900"  }}>Amount Due (KES)</Typography>
                            </div>
                        </div> */}
                      <Table style={{border:'2px dotted #bfbfbf'}}>
							<TableHead>
								<TableRow>
									<TableCell style={{borderBottom:"none", fontSize : '14px', fontWeight : 'bold', fontFamily : 'Poppins'}}>Invoice No:</TableCell>
									<TableCell style={{borderBottom:"none", fontSize : '14px', fontWeight : 'bold', fontFamily : 'Poppins'}}>Amount Due (KES)</TableCell>
								</TableRow>
							</TableHead>
							<TableBody className={classes.table_body}>
								{noticeDataDetails?.invoices?.map((items, index) => (
                                    <>
									<TableRow key={index}>
										<TableCell style={{borderBottom:"none", fontSize : '14px', fontFamily : 'Poppins'}}><Link  to={`/invoice/${items.invoiceNumber}`}>{items.invoiceNumber}</Link></TableCell>
										<TableCell style={{borderBottom:"none", fontSize : '14px', fontFamily : 'Poppins'}}>{CurrencyFormatter(items.invoiceAmount, 'KES')}</TableCell>
									</TableRow>
                                        {/* <div className="py-3" style={{ background: '#fafafa ' }}>
                                            <Typography>Total KES: {CurrencyFormatter(items.invoiceAmount)} </Typography>
                                        </div> */}
                                    </>
								))}
							</TableBody>
						</Table>
                    </div>
                  
                    <div className="py-3" style={{borderBottom:"2px dotted #bfbfbf"}}>
                        <Typography> {noticeDataDetails?.noticeMessage}</Typography>
                        <span>Thank you for your business</span>
                    </div>
                    <div>
                      <Typography>{noticeDataDetails?.noticeSignature}</Typography>  
                    
                    </div>
                </div>
            </div>
        </>

    )
}

export default NoticePdf