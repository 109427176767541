import React, { useState, useEffect } from "react";
import { Breadcrumbs, Grid, TextField, Button } from "@mui/material";
import { customerstyles } from "./styles";
import { NavigateNext } from "@material-ui/icons";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import HttpComponent from "../../School/MakeRequest";
import { useParams } from "react-router-dom";
import { SuccessAlertRightAligned } from "../../bills/startorderModals/modals";

export const CreditAccount = () => {
  const { id, payType } = useParams();
  const navigate = useNavigate();

  const [creditAmount, setCreditAmount] = useState(0);
  const [sucess, setSucess] = useState({ state: false, message: "" });

  const creditCustomer = () => {
    HttpComponent({
      method: "POST",
      url: `/api/refillCustomerAccount?customerId=${id}`,
      body: { amount: parseInt(creditAmount), paymentMode: payType, description: "TopUp" },
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {

        if (data.status === 201) {
          setSucess({ state: true, message: "Customer has been updated succesfully!" });
          setTimeout(() => {
            setSucess({ state: false });
            navigate(`/customers`)
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <div style={customerstyles.universalStyle}>
      <Grid container direction={"column"}>
        <Grid item width={"100%"} style={{ margin: "0px 0px 10px 0px" }}>
          <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
            <span>Dashboard</span>
            <span onClick={() => navigate('/customers')} >Customers</span>
            <span style={{ color: "red" }}> Credit Account</span>
          </Breadcrumbs>
        </Grid>

        <Grid item style={{ margin: "0px 0px 10px 0px" }}>
          <p>
            {" "}
            <ArrowBackIos onClick={() => navigate(`/customers`)} /> Credit Account
          </p>

          <TextField label={"Top up amount"} value={creditAmount} onChange={(e) => setCreditAmount(e.target.value)} />
        </Grid>

        <Grid item>
          <Button onClick={() => navigate("/customers")} style={customerstyles.cancelBtn}>
            Cancel
          </Button>
          <Button style={customerstyles.approvalButton} onClick={() => (creditAmount > 0 ? creditCustomer() : null)}>
            Top Up
          </Button>
        </Grid>
      </Grid>

      {sucess && <SuccessAlertRightAligned message={sucess.message} sucess={sucess.state} />}
    </div>
  );
};
