import React, { useEffect, useState } from "react";
import { AccordionDetails, AccordionSummary, Typography, Grid, Accordion } from "@mui/material";
import { ArrowForwardIos } from "@material-ui/icons";
import StockFunctions from "../stockEndpoints";

const Category = (props) => {
  const setCategoryId = props?.setCategoryId;
  const inputValue = props?.inputValue;

  const [productCategories, setProductCategories] = useState([]);

  // AACORDION DISPLAY;
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    const stockFunctions = new StockFunctions();
    if(inputValue === "")
    stockFunctions.getCategories(1, 10).then((data) => {
      setProductCategories(data?.response?.data);
    });
  }, [inputValue]);

  

  useEffect(() => {
    const stockfunctions = new StockFunctions();
    if (inputValue) stockfunctions.searchCategories(1, 5000, inputValue).then((data) => {
      setProductCategories(data?.response?.data);
    });
  }, [inputValue]);

  return (
    <div>
      <Grid container direction={"column"}>
        <Grid item>
          {productCategories?.map((category) => {
            return (
              <Accordion key={category?._id} onChange={handleChange(category?._id)} expanded={expanded === category._id}>
                <AccordionSummary expandIcon={<ArrowForwardIos />} onClick={() => setCategoryId(category?._id)}>
                  <Typography>{category?.categoryName}</Typography>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
              </Accordion>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};

export default Category;
