import React, {useEffect, useState} from 'react'
import profilePicture from "../../common/images/profile_picture.svg";
import {Box, Divider, Grid, styled, Tab, Typography} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {DataGrid} from "@mui/x-data-grid";
import {Link} from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import Select from '@material-ui/core/Select';
import CustomTable from "../School/CustomTable";
import HttpComponent from "../School/MakeRequest";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {clearCategoriesToAdd} from "../../features/businessCategorySlice";
import {useSelector} from "react-redux";
import ListItemText from "@material-ui/core/ListItemText";
import CustomerInvoice from './customerComponents/customerinvoice';
import Customertransaction from './customerComponents/customertransaction';
import Customeractivity from './customerComponents/customeractivity';
import { Button } from '@material-ui/core';
const baseUrl = process.env.REACT_APP_BASE_URL;
import { useNavigate } from 'react-router-dom';

const AntTabs = styled(TabList)({
    borderBottom: "3px solid #e8e8e8",
    "& .MuiTabs-indicator": {
        backgroundColor: "#dc3545",
    },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: "none",
        minWidth: 0,
        [theme.breakpoints.up("sm")]: {
            minWidth: 0,
        },
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        fontSize: "13px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "2.75",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#6e7074",
        fontFamily: ["Poppins"].join(","),
        "&:hover": {
            color: "#032541",
            opacity: 1,
        },
        "&.Mui-selected": {
            color: "#032541",
            fontWeight: "bold",
        },
        "&.Mui-focusVisible": {
            backgroundColor: "blue",
        },
    })
);


const CustomerAccountDash = () => {
    const navigate = useNavigate();
    
    const {businessCat} = useSelector((store)=>store.businessCategory)
    const [notSchoolAndRent, setNotSchoolAndRent] = useState(false);

    useEffect(() => {

        if(businessCat === 'Eatery, Bar and Restaurant' ){
            setNotSchoolAndRent(true)
        }
        
    },[businessCat])
   

    const theColumns = {
        display:'flex',
        flexDirection:'column',
        justifyContent:"center"
    }
    const commonTextSize = {
        fontSize:"15px"
    }

    useEffect(() => {
        console.log("i am in the customer dash")
    }, []);


    const [value, setValue] = useState("Invoices");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [receivedRows, setReceivedRows] = useState([]);
    const [receivedPageSize, setReceivedPageSize] = useState(5);
    const [userData, setUserData] = useState();
    const [customerActivity, setCustomerActivity] = useState()
    const [customerTransRows, setCustomerTransRows] = useState([])
    const [customerTransPageSize, setCustomerTransPageSize] = useState(5);
    const [selectedBusinessName, setSelectedBusinessName] = useState("");
    const [customerBusinessData, setCustomerBusinessData] = useState()
    const [token, setToken] = useState(localStorage.getItem("X-Authorization"))

    function convertDate(dateString) {
        let date = new Date(dateString);
        let options = { month: 'short' };
        let day = date.getDate();
        let suffix = 'th';

        if (day === 1 || day === 21 || day === 31) {
            suffix = 'st';
        } else if (day === 2 || day === 22) {
            suffix = 'nd';
        } else if (day === 3 || day === 23) {
            suffix = 'rd';
        }

        return day + suffix + ' ' + date.toLocaleString('default', options) + ' ' + date.getFullYear();
    }

    const fetchReceived = async () =>{
        try{
            const headers = {method : 'GET' , 'X-Authorization': localStorage.getItem('X-Authorization')};
            const response  = await fetch(baseUrl+"/api/get_user_invoices", {headers});
            if (response.status === 200) {
                await response.json()
                    .then((data)=>{
                        console.log("received invoices", data)
                        const rowsWithIds = data.data.map(row => {
                            const createdAt = new Date(row.createdAt).toLocaleDateString("de-DE", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric"
                            });
                            const amountPaid = row.invoiceAmount - row.invoiceBalance
                            return { ...row, id: row._id, createdAt, amountPaid }
                        });
                        setReceivedRows(rowsWithIds);
                    })
            }
        }catch (e) {
            console.error("error fetching recived invoices",e.message);
        }
    }

    const fetchActivity = async () =>{
        const customerDetailsResponse = await fetch(
            baseUrl + `/api/CustomersActivities`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            }
        );
        if (customerDetailsResponse.status === 201) {
            const customerDetailsData = await customerDetailsResponse.json();
            console.log("init",customerDetailsData.data)
            setCustomerActivity(customerDetailsData.data)
        }
    }

    const getTransactions = async () => {
        console.log("test working")
        const customerDetailsResponse = await fetch(
            baseUrl + `/api/customerTransactions`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            }
        );
        if (customerDetailsResponse.status === 201) {
            await customerDetailsResponse.json().then((data)=>{
                console.log("transa",data.transaction)
                let counter = 1;
                const rowsWithIds = data.transaction.map(row => {
                    const transactionTime = new Date(row.transactionTime).toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric"
                    });
                    return { ...row, id: counter++, transactionTime }
                });
                console.log("new rows",rowsWithIds)
                setCustomerTransRows(rowsWithIds);
            });
        }
    }

    let customerId = localStorage.getItem("customerId")

    const getCustomerDetails = async () => {
        try {
            const customerDetailsResponse = await fetch(
                baseUrl + `/api/get_customer_by_number?customerId=${customerId}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                }
            );

            if (customerDetailsResponse.status === 200) {
                const customerDetailsData = await customerDetailsResponse.json();
                console.log("det",customerDetailsData)
                console.log("sd",customerDetailsData.data[0])
                setUserData(customerDetailsData.data[0])
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleBusinessChange = async event => {
        const selectedBusiness = customerBusinessData.businesses.find(business => business.businessName === event.target.value);
        setSelectedBusinessName(event.target.value);
        console.log("new id", selectedBusiness.businessId)
        await fetch(baseUrl + `/api/change_customer_business`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-Authorization": localStorage.getItem("X-Authorization")
            },
            body: JSON.stringify({
                oldToken: localStorage.getItem("X-Authorization"),
                businessId: selectedBusiness.businessId
            })
        }).then(async (data) => {
            let response = await data.json()
            console.log("changing business done")
            localStorage.setItem("X-Authorization",response.data.newToken)
            setToken(localStorage.getItem("X-Authorization"))
        });
    };

    const getUserBusinesses = async () => {
        // get_customer_businesses
        const customerDetailsResponse = await fetch(
            baseUrl + `/api/get_customer_businesses`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            }
        );
        if (customerDetailsResponse.status === 200) {
            const customerDetailsData = await customerDetailsResponse.json();
            console.log("yghj",customerDetailsData)
            setCustomerBusinessData(customerDetailsData?.data)
            setSelectedBusinessName(customerDetailsData?.data?.businessName)
        }
    }

    const { userId ,X_Authorization } = useSelector((store) => store.user);

    const [students,setStudents] = useState([])

    const fetchStudents = () =>{
        HttpComponent({
            method: 'POST',
            url: `/api/getBillableItems?page=1&limit=1000`,
            body: {customerId:customerId},
            token: X_Authorization
        }).then((data)=>{
            console.log("here store is data",data);
            if(data.status === 200){
                setStudents(data.response.data)
            }else{
                console.error("Error setting info")
            }
        }).catch((error)=>{
            console.error(error.message);
        })
    }

    const numberFormat = (value) =>
        new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "KES",
        }).format(value);

    const [receivedReceipts, setReceivedReceipts] = useState([]);

    const fetchReceivedReceipts = async () =>{
        try{
            const headers = {method : 'GET' , 'X-Authorization': localStorage.getItem('X-Authorization')};
            const response  = await fetch(baseUrl+"/api/get_user_receipts_invoice_only", {headers});
            if (response.status === 201) {
                await response.json()
                    .then((data)=>{
                        console.log("received receipts", data)
                        const rowsWithIds = data.data.map(row => {
                            const issuedDate = new Date(row.issuedDate).toLocaleDateString("de-DE", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric"
                            });
                            const amount = numberFormat(row.amount)
                            return { ...row, id: row.transactionId, issuedDate, amount }
                        });
                        setReceivedReceipts(rowsWithIds);
                    })
            }
        }catch (e) {
            console.error("error fetching recived invoices",e.message);
        }
    }


    useEffect(() => {
        fetchStudents()
        fetchActivity()
        getCustomerDetails()
        fetchReceived()
        getTransactions()
        getUserBusinesses()
        fetchReceivedReceipts()
    }, [token]);

    return (
        <>
            <div style={{width:"100%"}}>
                <span style={{
                    marginLeft:20,
                    "color":"#dc3545",
                    fontWeight:"600"
                }}>Businesses: </span>
                <span style={{
                    color:"#032541"
                }}>
                    <Select
                        labelId="business-select-label"
                        id="business-select"
                        value={selectedBusinessName && selectedBusinessName}
                        onChange={handleBusinessChange}
                    >
                    {customerBusinessData?.businesses
                        .map(business => (
                            <MenuItem key={business.businessId} value={business.businessName}>
                                {business.businessName}
                            </MenuItem>
                        ))}
                    </Select>
                </span>
            </div>
            <div style={{display:"flex", justifyContent:"flex-end"}}>
                <Box sx={{ minWidth: 120 }}>
                    
                    {/* navigate("/school/request/service") */}
                    {/* <Button onClick={() => {
                        navigate("/school/request/service")
                    }} style={{ background: "#032541", marginRight: "40px", color: "#fff", padding: "10px 20px" }}> Request Service</Button>  */}
                </Box>
            </div>
           
            <div style={{display:"flex", justifyContent:"center"}}>
                <div style={{
                    display:"flex",
                    justifyContent:"space-between",
                    height:"150px",
                    width:"95%",
                    boxShadow:"0 4px 9px 0 rgba(0, 0, 0, 0.16)",
                    borderRadius:"15px",
                    marginTop:"3%",
                    padding:"20px"}}>

                    <div style={theColumns}>
                        <img src={profilePicture} alt="Profile Picture Placeholder" style={{
                            height:"110px",
                            width:"110px"
                        }}/>
                    </div>
                    <div style={theColumns}>
                        <div style={{color:'#032541', fontSize:"25px", fontWeight:"bold", paddingTop:"0.125rem", paddingBottom:"0.125rem"}}>{userData?.firstName} {userData?.lastName}</div>
                        <div style={{fontSize:"15px", color:"#666f76" , paddingTop:"0.125rem", paddingBottom:"0.125rem"}}>Added {convertDate(userData?.createdAt)}</div>
                        <div style={{color:'#032541', paddingTop:"0.125rem", paddingBottom:"0.125rem"}}><span style={{fontWeight:"bold"}}>Customer type: </span><span style={{color:"#666f76"}}>{userData?.customerType}</span></div>
                    </div>
                    <div style={theColumns}>
                        <div style={{commonTextSize,paddingTop:"0.25rem", paddingBottom:"0.25rem"}}><span style={{fontWeight:"bold",color:"#032541"}}>Address: </span><span style={{color:"#666f76"}}>{userData?.country}</span></div>
                      {notSchoolAndRent ?  null : <div style={{commonTextSize,paddingTop:"0.25rem", paddingBottom:"0.25rem"}}><span style={{fontWeight:"bold",color:"#032541"}}>No of Students: </span><span style={{color:"#666f76"}}>{students?.length}</span></div> }  
                        {/*<div style={{commonTextSize,color:"#17ae7b", fontWeight:"bold",paddingTop:"0.25rem", paddingBottom:"0.25rem"}}>Current Balance: <span>{numberFormat(userData?.totalBalance)}</span></div>*/}
                    </div>
                    <div style={theColumns}>
                        <div style={{commonTextSize,paddingTop:"0.25rem", paddingBottom:"0.25rem"}}><span style={{fontWeight:"bold",color:"#032541"}}>Email: </span><span style={{color:"#666f76"}}>{userData?.email}</span></div>
                        <div style={{commonTextSize,paddingTop:"0.25rem", paddingBottom:"0.25rem"}}><span style={{fontWeight:"bold",color:"#032541"}}>Mobile Number: </span><span style={{color:"#666f76"}}>{userData?.phone}</span></div>
                        {/*<div style={{commonTextSize ,paddingTop:"0.188rem", paddingBottom:"0.438rem"}}><span style={{fontWeight:"bold",color:"#032541"}}>Total Credited: </span><span style={{color:"#666f76"}}>{numberFormat(userData?.totalCredits)}</span></div>*/}
                        {/*<div style={{commonTextSize,color:"#dc3545", paddingBottom:"2.125rem"}}><span style={{fontWeight:"bold"}}>Total Expenses: </span> <span>{numberFormat(userData?.totalExpense)}</span></div>*/}
                    </div>

                    <div style={theColumns}>
                        <div style={{commonTextSize,paddingTop:"0.25rem", paddingBottom:"0.25rem"}}><span style={{fontWeight:"bold",color:"#032541"}}>Pending Invoices: </span><span style={{color:"#666f76"}}>{userData?.pendingInvoiceCount}</span></div>
                        <div style={{commonTextSize,paddingTop:"0.25rem", paddingBottom:"0.25rem"}}><span style={{fontWeight:"bold",color:"#032541"}}>Pending Balance: </span><span style={{color:"#666f76"}}>KES {userData?.pendingAmount}</span></div>
                    </div>
                </div>
            </div>
            <Grid container marginBottom="0.625rem" marginTop="0.625rem">
                <Grid item style={{width:"100%"}}>
                    <TabContext value={value}>
                        <Box>
                            <AntTabs onChange={handleChange}>
                                <AntTab label="Invoices" value="Invoices"/>
                                <AntTab label="Transactions" value="Transactions" />
                                <AntTab label="Receipts" value="Receipts" />

                                {notSchoolAndRent ? null : <AntTab label="Students" value="Students" /> }
                                
                                {/*{businessCat === "School" ?*/}
                                {/*    <AntTab label="Receipts" value="Receipts" />*/}
                                {/*    :*/}
                                {/*    <>*/}
                                {/*        <AntTab label="Activity" value="Activity" />*/}
                                {/*        <AntTab label="Receipts" value="Receipts" />*/}
                                {/*    </>*/}
                                {/*}*/}
                            </AntTabs>
                        </Box>

                        <TabPanel value={"Students"}>
                            <CustomTable data={students} columns={[
                                {field: 'itemNumber', headerName: 'Admission Number No', flex:1, renderCell : (params) => {return (<Link to={`/school/student/${customerId}/${params.value}`}>{params.value}</Link> )}},
                                {field: 'studentName', headerName: 'Student Name',flex:1,
                                    renderCell : (params) => {
                                    return (<Typography>{params.row.firstName} {params.row.lastName}
                                    </Typography> )}
                                },
                                {field: 'grade', headerName: 'Grade',flex:1},
                                {field: 'term', headerName: 'Term',flex:1},
                                {field: 'additionalInfo', headerName: 'Additional Info',flex:1,
                                    renderCell : (params) => {
                                        return (<Typography>{params.value !== "" ? params.value : "N/A"}
                                        </Typography> )}},
                                {field: 'pendingInvoices', headerName: 'Pending Invoices',flex:1},
                                {field: 'pendingBalance', headerName: 'Amount Due',flex:1,
                                    renderCell : (params) => {
                                        return (<Typography>KES {params.value}
                                        </Typography> )}
                                },

                                {field: 'action', headerName: 'Actions',flex:1,
                                    renderCell : (params) => {
                                        return (<MoreVertIcon/> )}},
                            ]} />
                        </TabPanel>


                        <TabPanel value="Transactions">
                            {
                                notSchoolAndRent && <Customertransaction customerId={localStorage.getItem('customerId')} />
                            }

                            {
                                !notSchoolAndRent &&               <div style={{
                                    height:"350px",
                                    minWidth:"1000px"
                                }}>
    
                                    <CustomTable data={customerTransRows} columns={[
                                        {field: 'transactionNo',headerName: 'Transaction No',flex:1 },
                                        {field: 'transactionTime', headerName: 'Date',flex:1},
                                        {field: 'noOfItems', headerName: 'No. Of Items',flex:1},
                                        {field: 'business', headerName: 'Business',flex:1},
                                        {field: 'branchName', headerName: 'Branch',flex:1},
                                        {field: 'amount', headerName: 'Amount',flex:1},
                                        {field: 'approvedBy', headerName: 'Approved By',flex:1}
                                    ]} rowsPerPage={5}/>
                                </div>
                            }
              
                        </TabPanel>

                        {/*{businessCat === "School" ?*/}
                        {/*    <TabPanel value="Receipts">*/}
                        {/*        <div style={{*/}
                        {/*        height:"350px",*/}
                        {/*        minWidth:"1000px"*/}
                        {/*    }}>*/}
                        {/*            <CustomTable data={receivedReceipts} columns={[*/}
                        {/*                {field: 'receiptNo', headerName: 'Receipt Number', flex:1, renderCell : (params) => {return (<Link to={`/receipt/${params.value}/${params.row.invoiceNumber}`}>{params.value}</Link> )}},*/}
                        {/*                {field: 'invoiceNumber', headerName: 'Invoice Number',flex:1},*/}
                        {/*                {field: 'from', headerName: 'From',flex:1},*/}
                        {/*                {field: 'amount', headerName: 'Amount Paid',flex:1},*/}
                        {/*                {field: 'issuedDate', headerName: 'Date Issued',flex:1},*/}
                        {/*            ]} rowsPerPage={5}/>*/}
                        {/*    </div>*/}
                        {/*</TabPanel>*/}
                        {/*    :*/}
                        {/*    null*/}
                        {/*}*/}



                        {/*<TabPanel value="Activity">*/}
                        {/*    <div style={{*/}
                        {/*        height:"350px",*/}
                        {/*        minWidth:"1000px"*/}
                        {/*    }}>*/}
                        {/*        {customerActivity?.map((activity, index) => (*/}
                        {/*            <>*/}
                        {/*            <div key={index}>*/}
                        {/*                {numberFormat(activity.amount)} {activity.customerPaymentType === "credit" ? "credited" : "debited"} {activity.customerPaymentType === "credit" ? "to" : "from"} customer account by {activity.createdBy}*/}
                        {/*            </div>*/}
                        {/*            <Divider/>*/}
                        {/*            </>*/}
                        {/*        ))}*/}
                        {/*    </div>*/}
                        {/*</TabPanel>*/}
                       

                        <TabPanel value="Receipts">

                             
                        {
                           notSchoolAndRent &&   <Customeractivity customerId={localStorage.getItem('customerId')} />
                        }
                       
                       {
                        !notSchoolAndRent &&    <div style={{
                            height:"350px",
                            minWidth:"1000px"
                        }}>
                            <CustomTable data={receivedReceipts} columns={[
                                {field: 'receiptNo', headerName: 'Receipt Number', flex:1, renderCell : (params) => {return (<Link to={`/receipt/${params.value}/${params.row.invoiceNumber}`}>{params.value}</Link> )}},
                                {field: 'invoiceNumber', headerName: 'Invoice Number',flex:1},
                                {field: 'from', headerName: 'From',flex:1},
                                {field: 'amount', headerName: 'Amount Paid',flex:1},
                                {field: 'issuedDate', headerName: 'Date Issued',flex:1},
                            ]} rowsPerPage={5}/>
                        </div>
                       }
                         
                        </TabPanel>

                        <TabPanel value="Invoices">

                    {notSchoolAndRent &&  <CustomerInvoice customerId={localStorage.getItem('customerId')} />}
                        
                    {!notSchoolAndRent &&  <div style={{
                                height:"380px",
                                minWidth:"500px"
                            }}>
                                <CustomTable data={receivedRows} columns={[
                                    {field: 'invoiceNumber', headerName: 'Invoice No', flex:1,
                                        renderCell : (params) => {
                                            return (
                                                <Link to={`/invoice/${params.value}`}>{params.value}</Link>
                                            )}
                                    },
                                    {field: 'createdAt', headerName: 'Date Issued',flex:1},
                                    {field: 'invoiceAmount', headerName: 'Amount',flex:1},
                                    {field: 'amountPaid', headerName: 'Amount Paid',flex:1},
                                    {field: 'invoiceType', headerName: 'Type',flex:1},
                                    {field: 'invoiceStatus', headerName: 'Status',flex:1,
                                        renderCell : (params) => {
                                            return (
                                                <Typography sx={{
                                                    color:
                                                        params.value == "Paid" ?
                                                            "#17ae7b" :
                                                            params.value == "Unpaid" ?
                                                                "#dc3545" :
                                                                params.value == "Partially Paid" ?
                                                                    "#ff8503" :
                                                                    "#032541"
                                                }}
                                                >
                                                    {params.value}
                                                </Typography>
                                            )}
                                    },
                                ]} />
                            </div> }

                        </TabPanel>

                    </TabContext>
                </Grid>
            </Grid>

        </>
        );
}



export default CustomerAccountDash;
