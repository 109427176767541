import { Breadcrumbs, Card, Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import CustomTable from "../../School/CustomTable";
import HttpComponent from "../../School/MakeRequest";
import { useSelector } from "react-redux";
import CurrencyFormatter from "../../../utils/currencyFormatter";
import { makeStyles } from "@material-ui/core/styles";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// image imports
import amountCollected from '../../../common/images/amountCollected.svg'
import transactionArrow from '../../../common/images/transactionTypeArrow.svg'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CustomSearchInput from "../../School/CustomSearchInput";
import ExportMenu from "../../School/ExportMenu";
import DateFormatter from "../../../utils/dateFormatter";
import BankImg from '../../School/Images/disabldbank-icon.svg'
import CardImg from '../../School/Images/disabledcard-icon.svg'
import MpesaImg from '../../School/Images/disabledphone-icon.svg'




// Active
const activeStyles = makeStyles({ active: { border: "1px solid #53365c" }, activeSummary: { border: "1px solid #17ae7b" } });

//Global Columns
const columns = [
	{ field: "transactionID", flex: 1, renderHeader: (params) => { return (<strong style={{ fontSize: '14px', color: '#032541' }}>Transaction Id</strong>) } },
	{ field: "receiptNumber", flex: 1, renderHeader: (params) => { return (<strong style={{ fontSize: '14px', color: '#032541' }}>ReceiptNumber</strong>) } },
	{ field: "transactionType", flex: 1, renderHeader: (params) => { return (<strong style={{ fontSize: '14px', color: '#032541' }}>Payment Mode</strong>) } },
	{ field: "createdAt", flex: 1, renderHeader: (params) => { return (<strong style={{ fontSize: '14px', color: '#032541' }}>Date & Time</strong>) }, renderCell: (params) => { return (DateFormatter(params.value)) } },
	{ field: "transamount", flex: 1, renderHeader: (params) => { return (<strong style={{ fontSize: '14px', color: '#032541' }}>Amount (KES)</strong>) }, renderCell: (params) => { return (CurrencyFormatter(params.value, 'KES')) } }
]

export default function TransactionSummary() {
	const navigate = useNavigate()
	const { month, year } = useParams()

	const { X_Authorization } = useSelector((store) => store.user)

	// Loader Setter
	const [loader, setLoader] = useState(false)
	const [startDate, setStartDate] = useState(new Date())
	const [endDate, setEndDate] = useState(new Date())


	// Setters and Getters
	const [transactionBreakDown, setTransactionBreakDown] = useState([])
	const [total, setTotal] = useState(0)
	const classes = [activeStyles()]

	// Card Setters and Getters
	const [activeCard, setActiveCard] = useState(null);
	const [data, setData] = useState('')
	const [level, setLevel] = useState(1)

	// Handle Card Click
	const setActiveLevel = (level) => {
		setLevel(level)
		console.log(`Level set to`, level)
	}

	const handleClick = (index, name) => {
		setActiveCard(index);
		getTransactionsByType(name)
	};

	// Get Transaction Types Summary
	function getTransactionTypeSummary() {
		HttpComponent({
			method: 'GET',
			url: `/api/v1/payments/by_month?year=${year}&month=${month}`,
			token: X_Authorization
		}).then((data) => {
			if (data.status === 200) {
				setTransactionBreakDown(data.response.transactions)
				setTotal(data.response.total)
				console.log(`Transaction breakdown Data`, data.response.transactions)
			} else {
				console.log(`Error getting transaction BreakDown`)
			}

		}).catch((e) => {
			console.log(`Error getting transaction BreakDown`, e.message)
		})
	}

	// Get Transactions By type
	function getTransactionsByType(cardName) {
		try {
			setLoader(true)
			HttpComponent({
				method: 'GET',
				url: `/api/v1/payments/by_month_by_transaction_type?year=${year}&month=${month}&transactionType=${cardName}&page=1&limit=1000`,
				token: X_Authorization
			}).then((data) => {
				if (data.status === 200) {
					setLoader(false)
					setData(data.response.transactions)
					console.log(`${cardName} Transaction breakdown Data`, data.response.transactions)
				} else {
					console.log(`Error getting ${cardName} transaction `)
				}

			}).catch((e) => {
				console.log(`Error getting ${cardName} transaction`, e.message)
			})

		} catch (e) {
			console.log(`Error getting ${cardName} transaction`, e.message)
		}
	}

	useEffect(() => {
		getTransactionsByType("")
	}, [])

	// Search functionality
	const [dataToShow, setDataToShow] = useState([]);

	console.log(dataToShow ,'all data')

	const [formData, setFormData] = useState({ search: "" })

	const handleInputChange = (e) => { setFormData({ ...formData, [e.target.name]: e.target.value, }); };

	const setSearchData = () => {
		let searchable = [...data]
		if (formData.search !== "") {
			let result = searchable.filter(transaction =>
				transaction.transactionID.toLowerCase()?.includes(formData.search) ||
				transaction.receiptNumber?.includes(formData.search) || 
				transaction.createdAt.toLowerCase()?.includes(formData.search) || 
				transaction.transactionType.toLowerCase()?.includes(formData.search)
			);
			console.log("search", result)
			setDataToShow(result);
		} else {
			setDataToShow(data)
		}
	}
	//csv columns

	const csvColumns = [
		{ label: "Transaction ID", key: "transactionID" },
		{ label: "Receipt Number", key: "receiptNumber" },
		{label:"Payment Mode" , key:"transactionType"},
		{ label: "Created At", key: "createdAt" },
		{ label: "Transaction Amount", key: "transamount" }
	]

	// file headers

	const fileHeaders = [[
		"Transaction ID",
		"Receipt Number",
		"Payment Mode",
		"Created At",
		"Transaction Amount"
	]]

	// useEffect
	// Search UseEffect
	useEffect(() => {
		setSearchData()
		console.log("search", formData.search)
	}, [formData.search, data]);

	// Get transaction
	useEffect(() => {
		getTransactionTypeSummary()
	}, [])

	// Bread Crumbs
	const breadcrumbs = [
		<Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Dashboard</Typography>,
		<Typography style={{ color: '#dc3545', fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.error">Transaction Summary</Typography>
	];

	//custom date field

	const handleStartDateChange = (value) => {
		setStartDate(value)

	}

	const handleEndDateChange = (value) => {
		setEndDate(value)
	}

	//file data pdf
	const fileDataPDF = dataToShow && dataToShow?.map((transaction) => [
		transaction?.transactionID,
		transaction?.receiptNumber,
		transaction?.transactionType,
		transaction?.createdAt,
		transaction?.transamount
	])

	//file data 

	const fileData = dataToShow && dataToShow?.map((transaction) => {
		return {
			"Transaction ID": transaction?.transactionID,
			"Receipt Number": transaction?.receiptNumber,
			"Payment Mode":transaction?.transactionType,
			"Created At": transaction?.createdAt,
			"Transaction Amount": transaction?.transamount
		}
	})

	return (
		<div>

			{/*Header Container*/}
			<Grid container justifycontent={'flex-start'} mt={-3}>

				{/*back Image*/}
				<Grid item>
					<ArrowBackIosIcon onClick={() => navigate(-1)} style={{ color: '#707070', fontSize: '35px' }} />
				</Grid>

				{/*Month Name*/}
				<Grid item>
					<span style={{ color: '#032541', fontWeight: 'bold', fontSize: '25px' }}>{month} {year}</span>
				</Grid>
			</Grid>

			{/*BreadCrumb Container*/}
			<Grid container justifyContent={'flex-start'} mt={3}>
				<Grid item>
					<Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
						{breadcrumbs}
					</Breadcrumbs>
				</Grid>
			</Grid>

			{/*Summary Cards*/}
			<Grid container justifyContent={'flex-start'} alignContent={'center'} mt={3} column={4} spacing={2}>

				{/*Amount Collected*/}
				<Grid item xs={12} sm={6} md={4} lg={3}>
					<Card className={`${classes.root} ${activeCard === 0 ? classes[0].activeSummary : ""}`} onClick={() => { setActiveCard(0); handleClick(0, "") }} style={{ cursor: "pointer", width: '100%', height: '100px', boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px', backGroundColor: '#fff' }}>

						{/*Icon container*/}
						<Grid container direction={'row'} spacing={3}>

							{/*Icon*/}
							<Grid item mt={3} ml={3}>
								<img src={amountCollected} alt={'amount collected'} />
							</Grid>

							{/*Title and amount*/}
							<Grid item mt={3}>
								<Grid container direction={'column'} justifyContent={'flex-start'}>

									{/*Title*/}
									<Grid item>
										<span style={{ color: '#032541', fontWeight: 'bold', fontSize: '11px' }}> Amount Collected</span>
									</Grid>

									{/*Amount*/}
									<Grid item>
										<span style={{ color: '#17ae7b', fontWeight: 'bold', fontSize: '20px' }}>{CurrencyFormatter(total, 'KES')}</span>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

					</Card>
				</Grid>
				{/*transaction types map*/}
				{transactionBreakDown.map((transactionType, index) => (
					<Grid key={index} item xs={12} sm={6} md={4} lg={3}>
						<Card className={`${classes.root} ${activeCard === index ? classes[0].active : ""}`} onClick={() => { setActiveLevel(index + 1); handleClick(index, transactionType.transactionType) }} style={{ cursor: 'pointer', height: '100px', boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px', backGroundColor: '#fff' }}>

							{/*Icon container*/}
							<Grid container direction={'row'} spacing={3}>

								{/*Icon*/}
								<Grid item mt={3} ml={3}>
									{
										transactionType.transactionType === 'MPESA' ? <img src={MpesaImg} alt={'transaction type'} /> :
											transactionType.transactionType === 'Card Payment' ? <img src={CardImg} alt={'transaction type'} /> :
												transactionType.transactionType === 'BANK' ? <img src={BankImg} alt={'transaction type'} /> : ''
									}

								</Grid>

								{/*Title and amount*/}
								<Grid item mt={3}>
									<Grid container direction={'column'} justifyContent={'flex-start'}>

										<Grid item>
											<span style={{ color: '#707070', fontWeight: 'bold', fontSize: '11px' }}>{transactionType.transactionType}</span>
										</Grid>

										{/*Amount*/}
										<Grid item>
											<span style={{ color: '#032541', fontWeight: 'bold', fontSize: '20px' }}>{CurrencyFormatter(transactionType.total, 'KES')}</span>
										</Grid>

									</Grid>
								</Grid>
							</Grid>

						</Card>
					</Grid>
				))}

			</Grid>

			{/*Search and Export Container*/}
			<Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'} mt={4}>

				{/*SearchBox*/}
				<Grid item>
					<CustomSearchInput name={"search"} value={formData.search} onChange={handleInputChange} placeholder="Search:" />
				</Grid>
				{/*start date*/}
				<Grid item>
					<LocalizationProvider dateAdapter={AdapterDayjs} className="">
						<DatePicker
							label="From"
							value={startDate}
							onChange={handleStartDateChange}
							renderInput={(params) => <TextField {...params} />}
							inputFormat="DD-MM-YYYY"
							format="DD-MM-YYYY"
						/>
					</LocalizationProvider>
				</Grid>

				{/*end date*/}

				<Grid item>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							className='ml-2'
							label="To"
							value={endDate}
							onChange={handleEndDateChange}
							renderInput={(params) => <TextField {...params} />}
							inputFormat="DD-MM-YYYY"
							format="DD-MM-YYYY"
						/>
					</LocalizationProvider>
				</Grid>


				{/*Export button*/}
				<Grid item>
					<ExportMenu 
						csvColumns={csvColumns} 
						fileData={fileData} 
						fileDataPDF={fileDataPDF} 
						fileHeaders={fileHeaders} 
						fileName={'transaction summary'} 
						title={'transaction summary'} 
					/>
				</Grid>
			</Grid>

			{/*Mpesa, Card Data Grid, Bank Deposits, Data Grid Component*/}
			<CustomTable fontSize={'10px'} data={dataToShow} columns={columns} rowsPerPage={5} loading={loader} />

		</div>
	)
}