import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Sidebar from "./sideBar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

const Dash = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>{children}</main>
    </div>
  );
};

export default Dash;
