import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Grid, Breadcrumbs } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import SchoolForm from "./StudentForm";
import CustomTextFieldSmall from "./CustomTextFieldSmall";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import HttpComponent from "./MakeRequest";
import { changeToken } from "../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { addStudentsServices, clearAdditions, removeServiceFromStudent } from "../../features/customerSlice";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import ServiceForm from "./ServiceForm";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

let baseUrl = process.env.REACT_APP_BASE_URL;

//textinput styles

const useStyles = makeStyles((theme) => ({
  textinpu: {
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));

const StudentServicesForm = (props) => {
  const classes = useStyles();
  let { setStudentFormData } = props;
  const [schoolTypeName, setSchoolTypeName] = useState('')

  const GetSchoolTypeIdForBusiness = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_type_by_businesss`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      }
    });
    const data = await response.json();
    console.log(data, 'School Type Id');
    if (response.status === 201) {
      setSchoolTypeName(data.data?.schoolType)


    }
  }
  useEffect(() => {
    GetSchoolTypeIdForBusiness();
  }, []);
  const { userId, X_Authorization } = useSelector((store) => store.user);

  const [checkboxData, setCheckboxData] = useState([]);
  const [showData, setShowData] = useState([]);
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });
  const [formData, setFormData] = useState({
    search: "",
  });
  const [total, setTotal] = useState(0);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const { additionServices, studentToAdd, parentToAdd } = useSelector((store) => store.customers);

  const { servicesToAdd } = useSelector((store) => store.services);

  const [newState, setNewState] = useState([]);

  useEffect(() => {
    setNewState(additionServices);
  }, [additionServices]);

  console.log(additionServices, 'additioa')
  console.log("studentToAdd studentToAdd", studentToAdd)

  const setData = () => {
    if (formData.search !== "") {
      const searchTerm = formData.search.toLowerCase();
      const filteredData = checkboxData.filter((obj) => obj.productName.toLowerCase().includes(searchTerm));
      setShowData(filteredData);
    } else {
      setShowData(checkboxData);
    }
  };

  useEffect(() => {
    setData();
  }, [formData.search, checkboxData]);

  const fetchServices = () => {
    HttpComponent({
      method: "GET",
      url: "/api/listProducts/Active/?grade=true&search",
      body: null,
      token: X_Authorization,
    })
      .then((data) => {
        console.log("here store is data", data);
        if (data.status === 200) {
          setCheckboxData(data.response.data);
          setData();
        } else {
          console.error("Error setting info");
          setErrorShow({ state: true, message: data.response.message });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCheckboxChange = (event, label, amount, id) => {
    if (event.target.checked) {
      setTotal(total + amount);
      let data = {
        name: label,
        amount: amount,
        frequency: "Monthly",
        itemNumber: studentToAdd.itemNumber,
        productId: id,
      };
      dispatch(addStudentsServices(data));
    } else {
      setTotal(total - amount);
      const index = additionServices.findIndex((service) => service.name === label);
      dispatch(removeServiceFromStudent({ index }));
    }
  };

  const [buttonsDisabled, setButtonsDisabled] = useState(true);

  useEffect(() => {
    setButtonsDisabled(true);
  }, []);

  const isButtonsDisabled = () => {
    const index = additionServices?.findIndex((service) => service.itemNumber === studentToAdd.itemNumber);
    if (!additionServices) {
      setButtonsDisabled(true);
      return true;
    } else {
      if (index != -1) {
        setButtonsDisabled(false);
        return false;
      } else {
        setButtonsDisabled(true);
        return true;
      }
    }
  };
  useEffect(() => {
    isButtonsDisabled();
  }, [additionServices]);

  useEffect(() => {
    fetchServices();
  }, []);

  const noStyle = {
    borderRadius: "4px",
    border: "solid 1px #032541",
    color: "#032541",
  };

  const buttonWidthHeight = {
    width: "9.063rem",
    height: "3.125rem",
  };

  const yesStyle = {
    borderRadius: "4px",
    backgroundColor: "#032541",
    color: "white",
    "&:hover": {
      backgroundColor: "#032541",
      color: "white",
    },
  };

  //handle next action
  const handlePrev = () => {
    navigate('/school/service/create')
    localStorage.setItem("studentsetup", "studentsetup")
    // props.setView('services')
  }

  // const handleAddAnother = () => {
  //   props.SetDisabled(true);
  //   setStudentFormData({
  //     admissionNumber: "",
  //     admissionDate: Date.now(),
  //     firstName: "",
  //     lastName: "",
  //     gender: "",
  //     grade: "",
  //     info: "",
  //     term: "",
  //   });
  //   props.setParentSetUp(true);
  //   props.setStudentSuccess({ state: true, message: "Student Saved successfully" });
  //   navigate("/school/students")
  //   dispatch(clearAdditions())

  //   localStorage.removeItem('studentsetup')


  // };


  //save formdata 
  //let data = { ...parentToAdd, extraServices: [...additionServices], billableItems: [...studentsToAdd] };



  const [addStude, setAddStudent] = useState([])

  useEffect(() => {

    setAddStudent(studentToAdd)

  }, [studentToAdd])

  //...servicesToAdd, extraServices: [...additionServices],

  const saveParentToDB = async () => {
    let firstParent = []
    let secondParent = []
    if (parentToAdd?.length > 0) {
      firstParent = parentToAdd[0]
      secondParent = parentToAdd[1]
    }

    console.log('second parent', secondParent, 'first parent', firstParent, 'addition services', additionServices, 'students to add', addStude);

    // let data =[ { ...firstParent,  billableItems:[...addStude]}, {...secondParent}];
    let data
    if (schoolTypeName.includes("University")) {
      console.log("firstParent not defined", firstParent, "secondParent not defined", secondParent, "and", "Second parent is defined", secondParent)
      data = { ...addStude }
    } else if (secondParent?.length > 0 && firstParent?.length > 0) {
      data = [{ ...firstParent, extraServices: newState, billableItems: [{ ...addStude }] }, { ...secondParent }];
      console.log('if mydatra', data)
    } else if (firstParent) {
      data = [{ ...firstParent, billableItems: [{ ...addStude }], extraServices: newState }];
      console.log('else mydatra', data)
    }

    // data.phone = "+" + data.phone;
    console.log(' mydatra', data)


    try {
      const studentData = await fetch(`${baseUrl}/api/addCustomer`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      await studentData.json().then((data) => {
        console.log(data, 'student created sucessfully')
        if (data.Status === "SUCCESS") {
          dispatch(clearAdditions());
          setSuccessShow({ state: true, message: "Student created successfully" });
          navigate("/school/students")
          localStorage.removeItem('studentsetup')
        }
        if (data.Status === "FAILED") {
          dispatch(clearAdditions());
          setErrorShow({ state: true, message: data.message });

        }
      })
    } catch (error) {
      console.log(error)
    }
  }



  return (
    <Box component={"div"} style={{ maxWidth: "600px", margin: "auto", marginTop: "40px" }}>
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
      <div className="d-flex justify-content-between">
        <Box component="div" sx={{ display: "flex", alignContent: "center" }}>
          <NavigateBeforeIcon />
          <Typography variant="h6" style={{ fontSize: "15px", fontWeight: "bold", paddingLeft: "10px" }}>
            Services
          </Typography>
        </Box>
        <Box component="div" sx={{ display: "flex", alignContent: "center" }}>
          <Button style={{ background: "#f5f6f7", textTransform: "inherit" }} onClick={handlePrev}>
            Create Service
          </Button>
        </Box>
      </div>
      <Box component="div" sx={{ display: "flex", marginTop: "20px" }}>
        <TextField fullWidth value={formData.search} onChange={handleInputChange} name={"search"} placeholder={"Search"} />
      </Box>
      <Box component="div" sx={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
        <Typography variant="body2" fontWeight={"bold"}>
          Services
        </Typography>
        <Typography variant="body2" fontWeight={"bold"}>
          Amount
        </Typography>
      </Box>
      <Box sx={{ maxHeight: "18.75rem", overflowY: "scroll" }}>
        {showData.length ? (
          <>
            {showData?.map((data) => (
              <Box component="div" key={data._id} style={{ display: "flex", justifyContent: "space-between", padding: "0.5rem" }}>
                <FormControlLabel control={<Checkbox disabled={props.disabled} onChange={(event) => handleCheckboxChange(event, data.productName, data.productPrice, data._id)} />} label={data.productName} />
                <Box component={"div"}>
                  <CustomTextFieldSmall required label="Amount" defaultValue={`KES ${data.productPrice}`} disabled={true} />
                </Box>
              </Box>
            ))}
          </>
        ) : (
          "No Services Found"
        )}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", textAlign: "right", justifyContent: "center" }}>
        <Typography sx={{ color: "#032541", fontWeight: 400 }}>Total</Typography>
        <Typography sx={{ color: "#032541", fontWeight: 700 }}>KES {total?.toFixed(2)}</Typography>
      </Box>
      <Box component="div" sx={{ display: "flex", marginTop: "40px", flexDirection: "column", justifyContent: "center", marginBottom: 2 }}>
        <Box component="div" sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
          <Button
            sx={{ ...noStyle, ...buttonWidthHeight }}
            onClick={() => {
              props.setView("done");
              props.setParentSetUp(true);
            }}
          // disabled={buttonsDisabled}
          >
            Cancel
          </Button>
          <Button onClick={saveParentToDB} sx={{ ...yesStyle, ...buttonWidthHeight, marginLeft: 1 }}>
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default StudentServicesForm;
