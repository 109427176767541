import { Grid, Breadcrumbs, Typography, Button, TextField} from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useEffect, useState } from "react";
import HttpComponent from "../../../School/MakeRequest";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Zed-Payit</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Outlets</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Edit Outlet</Typography>
]

export default function EditOutLet(props){

    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })

    const [formData ,setFormData] = useState({outletName:""})
    const [branchId , setBranchId] = useState('')
    const [outletId , setOutletId] = useState()


    //api/get_store_details?storeType=stores&storeId=${id}
    const getOutletDetais =()=>{
        try {
            HttpComponent({
                method:'GET',
                url:`/api/get_store_details?storeType=stores&storeId=${props?.outLetId}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if(data.status === 200){
                    setFormData({
                        ...formData,
                        outletName:data?.response?.data[0]?.name
                    })
                    setBranchId(data?.response?.data[0]?.branchId)
                    setOutletId(data?.response?.data[0]?._id)
                }
            })
            
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=>{
        getOutletDetais()
    },[])

    const handleOnChange = (e)=>{
        setFormData({
            ...formData ,
            [e.target.name]:e.target.value
        })
    }

    const navigate = useNavigate()

    //update outlet

    function updateOutlet(){
        try{
            HttpComponent({
                method:'POST',
                url:`/api/v1/editOutlet?outletId=${props?.outLetId}`,
                body:{
                    storeName:formData.outletName,
                    branchId:branchId
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if(data.status === 200){
                    setSuccessShow({state:true , message:"Successfully updated outlet details"})
                }else{
                    setErrorShow({state:true , message:"Error updating outlet details"})
                }
                setTimeout(()=>{
                    navigate(-1)
                },[1000])
            })
        }catch(error){

        }
    }

    return(
        <Grid container direction={'column'}>
             <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{cursor:"pointer"}} onClick={()=>navigate(-1)}/>
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Edit Outlet</Typography>
            </Grid>
            <Grid item display={'flex'} mt={2}>
                <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                    <Grid item>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={3}>
                <Typography style={{ fontWeight: 600, color: "#707070", fontSize: "18px" }}>Outlet Details</Typography>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <TextField id="outlined-basic" name="outletName" onChange={handleOnChange} style={{ width: "100%" }} value={formData.outletName} label="Name" variant="outlined" />
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <TextField id="outlined-basic" name="outletDescription"  onChange={handleOnChange} style={{ width: "100%" }} value={formData.outletDescription} label="Description" variant="outlined" />
            </Grid>
            <Grid mt={2} item style={{ width: "50%" }} display={'flex'} justifyContent={'flex-end'}>
                <Button style={{ background: "#fff", color: "#DC3545", border: "1px solid #DC3545", width: "125px", marginRight: "5px", textTransform: "inherit" }}>Cancel</Button>
                <Button onClick={updateOutlet} style={!formData.outletName || !formData.outletName ? {opacity:0.4 ,width: "125px",background: "#032541", textTransform: "inherit",}:{ background: "#032541", textTransform: "inherit", width: "125px", color: "#fff" , opacity:1 }}>Submit</Button>
            </Grid>
        </Grid>
    )
}