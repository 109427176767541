import React, { useState, useEffect } from "react";
import CustomDataGrid from "../customDataGrid";
import { Button, Grid } from "@mui/material";
import { stockStyles } from "../stockStyling";
import SearchFilter from "../../../customerAccounts/customerComponents/searchFilter";
import { Delete } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import StockFunctions from "../stockEndpoints";

const StockOrderViewBatch = ({storeId, supplierId}) => {
  const stockfunctions = new StockFunctions();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");

  //DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  useEffect(() => {
    const stockOrderproducts = JSON.parse(localStorage.getItem("stockOrderItems"));
    if(stockOrderproducts){
      const stockProductsToView = stockOrderproducts.filter((stockItem) => stockItem.changed === true);
      setPageState({ ...pageState, data: stockProductsToView, count: stockProductsToView?.length });
    }

  }, [pageState?.page, dataGridPageSize]);

  const stockOrderColumn = [
    { field: "productName", headerName: "Product Name", flex: 1 },
    { field: "qtyRequested", headerName: "Qty Requested", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return <Delete style={{ color: "red", cursor: "pointer" }} onClick={() => removeProduct(params.row.id)} />;
      },
    },
  ];

  const stockOrderRow = pageState?.data
  ?.filter((stock) => {
    return inputValue?.toLocaleLowerCase() === "" ? stock : stock?.productName?.toLocaleLowerCase()?.includes(inputValue?.toLocaleLowerCase())
  })
  ?.map((stock) => ({
    id: stock?._id || stock.id,
    qtyRequested: stock?.qtyRequested,
    productName: stock?.productName,
    changed: stock?.changed,
  }));

  const removeProduct = (id) => {
    const stockOrderproducts = [...stockOrderRow];

    const currentIndex = stockOrderproducts.findIndex((prod) => prod.id === id);

    stockOrderproducts.splice(currentIndex, 1);

    setPageState({ ...pageState, data: stockOrderproducts, count: stockOrderproducts?.length });
    localStorage.setItem("stockOrderItems", JSON.stringify(stockOrderproducts));
  };

  const submitBatch = () => {
    navigate(`/stockOrder/purchaseOrderPreview?${storeId}?${supplierId}`)
  }


  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState({email:"", name:"", phone:"", storeName:""})
  const [storeName, setStoreName] = useState("")

    // getPurchaseOrderDetails;
    useEffect(() => {
      stockfunctions
        .getSuppliers(1, 1000, 'ACTIVE')
        .then((data) => {
          if (data?.status === 201) {
            const suppliers = data?.response?.data?.filter((supplier) => supplier?._id  === supplierId)
            const { email, name, phone,  } = suppliers[0]
            setPurchaseOrderDetails({ ...purchaseOrderDetails, email : email, name: name, phone: phone });
          }
        })
        .catch((e) => {
          // console.log(e.message);
        });

      stockfunctions.getStoreById(storeId).then((data) => {
        if(data?.status === 200){
          const  { name } = data?.response?.data[0]
          setStoreName(name)

        }
      })

    }, []);

    const clearStage = () => {
      localStorage.removeItem("stockOrderItems");
      navigate(-1);
    }

  return (
    <div style={{ width: "100%" }}>
      <Grid container>
        <Grid item>{/* <Breadcrumbcomponent breadcrumbs={breadcrumb} /> */}</Grid>
      </Grid>

      <Grid container style={stockStyles.batchDetailsHolder} width={"100%"} alignItems={"center"} justifyContent={"space-between"} direction={"row"} margin={"0px 0px 1% 0px"}>
        <Grid item>
          <span style={stockStyles.submitBatchdisplay}>Current Store :  {storeName}</span>
        </Grid>

        <Grid item>
          <span style={stockStyles.submitBatchdisplay}>To :  {purchaseOrderDetails?.name}</span>
        </Grid>
      </Grid>

      <Grid container direction={"row"} width={"100%"} alignItems={"center"} justifyContent={"space-between"} margin={"0px 0px 1% 0px"}>
        <Grid item sx={{width:'30%'}}>
          <SearchFilter setInputValue={setInputValue} placeholder={"Search"} />
        </Grid>
        <Grid item sx={{ display: "flex", gap: "20px", width: "50%", justifyContent: "flex-end" }}>
          <Button style={stockStyles.cancelButton} onClick={clearStage}>
            Clear
          </Button>
          <Button style={stockStyles.viewBatchButton} onClick={() => submitBatch()}>
            Preview Order
          </Button>
        </Grid>
      </Grid>

      <Grid container>
        {/* <CustomDataGrid/> */}
        <CustomDataGrid column={stockOrderColumn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={stockOrderRow} />
      </Grid>
    </div>
  );
};

export default StockOrderViewBatch;
