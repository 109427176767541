import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Breadcrumbs, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import HttpComponent from "../../School/MakeRequest";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";

//breadcrumbs

const breadcrumbs = [
        <Typography key="X" sx={{color:"#707070",fontSize:"0.875rem"}}>
        Dashboard
        </Typography>,
        <Typography key={"add"} sx={{color:"#707070", fontSize:"0.875rem"}}>
        Products / Services Categories
        </Typography>,
        <Typography key={"add"} sx={{color:"#dc3545", fontSize:"0.875rem"}}>
        Create Categories
        </Typography>
];


export default function CreateProductService(props){
    const [selectedValue ,setSelectedValue] = useState('')
    const [categoryName , setCategoryName] = useState('')
    const [categoryDescription , setCategoryDescription] = useState('')
    const {X_Authorization} = useSelector((store)=>store.user)
    const businessId = localStorage.getItem('businessId')
    const createdBy = localStorage.getItem('group')
    const [successShow, setSuccessShow] = useState({state: false, message: ""})
	const [errorShow, setErrorShow] = useState({state: false, message: ""})

    const formData = {
        businessID:businessId,
        categoryDescription:categoryDescription,
        categoryName:categoryName,
        productService:selectedValue,
        createdBy:createdBy
    }
    //service type
    const typeOptions = [
        {
            value:"Product",
            label:"Product" ,
        },
        {
            value:"Service",
            label:"Service" ,
        }
    ]

    const mapOptions = typeOptions.map((option)=>({value:option.value ,label:option.label}))

    function submitCreateProductCategory(){
        try {
            HttpComponent({
                method:'POST',
                url:`/api/createCategory`,
                body:formData,
                token:X_Authorization,

            }).then((data)=>{
                console.log(data , ' created product category')

                if(data.status === 201){
                    setSuccessShow({state:true , message:data.response.message})

                    setTimeout(()=>{
                        window.location.reload()
                    },2000)
                }else{
                    setErrorShow({state:true ,message:data.response.message})
                    setTimeout(()=>{
                        window.location.reload()
                    },2000)
                }
            })
        } catch (error) {
            
        }

    }    

    return(
        <>
        <SuccessAlert vertical="top" horizontal="right" onClose={()=>setSuccessShow({...successShow, state:false})} open={successShow.state} message={successShow.message}/>
			<ErrorAlert vertical="top" horizontal="right" onClose={()=>setErrorShow({...errorShow, state:false})} open={errorShow.state} message={errorShow.message}/>
            <Grid container direction={'column'}>
                <Grid item display={'flex'}  alignContent={'center'} alignItems={'center'} marginBottom={'2px'}>
                    <ArrowBackIosIcon onClick={props?.goBack}/>
                    <Typography variant="h6" style={{color:'#032541' , fontWeight:700 , fontSize:"25px"}}>Product / Service Categories</Typography>
                </Grid>
                <Grid item mb={'10px'} mt={'10px'}>
                     <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                            {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
                <Grid item mb={'10px'} mt={'10px'}>
                    <Typography variant="body2" style={{color:'#707070', fontSize:"18px"}}>Create a Category</Typography>
                </Grid>
                <Grid item mt={3} mb={2}>
                    <TextField id="outlined-basic" value={categoryName} onChange={(e)=>setCategoryName(e.target.value)} label="Category Name" style={{fontWeight:200 , width:"400px"}}/>
                </Grid>
                <Grid item mt={2} mb={2}>
                    <FormControl sx={{ minWidth: 400 }}>
                    <InputLabel id="demo-simple-select-helper-label">Category Type</InputLabel>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  value={selectedValue}
                                  onChange={(e)=>setSelectedValue(e.target.value)}
                                  label="Category Type"
                                >
                                  {mapOptions.map((option)=>{
                                    return(
                                        <MenuItem value={option.value}>{option.label}</MenuItem>
                                    )
                                  })}
                                </Select>
                    </FormControl>
                </Grid>
                <Grid container>
                     <Grid item lg={6} xl={6} md={12} mt={2} mb={2}>
                         <TextField value={categoryDescription} onChange={(e)=>setCategoryDescription(e.target.value)} id="outlined-basic" label="Category Description" style={{fontWeight:200,width:"400px"}}/>
                     </Grid>
                </Grid>
                <Grid container>
                    <Grid item style={{width:"400px"}} display={'flex'} justifyContent={'flex-end'} alignContent={'flex-end'}>
                        <Button onClick={props?.goBack} style={{border:"1px solid #dc3545" ,marginRight:'10px',color:"#dc3545" ,textTransform:'inherit' , width:"100px"}}>Cancel</Button>
                        <Button onClick={submitCreateProductCategory}  style={!categoryDescription || !selectedValue || !categoryName ? {background:"#032541" ,color:"#fff" ,textTransform:'inherit' , cursor:'pointer', width:"100px" , opacity:.2}:{background:"#032541" ,color:"#fff",textTransform:'inherit' , cursor:'pointer', width:"100px" }}>Create</Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )

}