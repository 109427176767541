import { Checkbox, Grid, Tab } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { ArrowBackIos, Cancel } from "@material-ui/icons";
import moment from "moment-timezone/moment-timezone-utils";
import { TabContainer } from "react-bootstrap";
import { ApprovedAddStockBatch, ApprovedStockTakeBatch, ApprovedStockTransfer } from "./stockModals/successBatchApproval";
import { NoRowsOverlay } from "../../No Rows/noRowsOverlay";
import Breadcrumbcomponent from "./breadcrumb";
import { stockStyles } from "./stockStyling";
import PendingBatchApproval from "./stockModals/pendingBatchApproval";
import { CancelledAddStockBatch, CancelledStockTakeBatch, CancelledStockTransferBatch } from "./stockModals/cancelledStockBatchApproval";
import PendingBatchApprovalStockTake from "./stockModals/pendingBatchApprovalStockTake";
import PendingBatchApprovalStockTransfer from "./stockModals/pendingBatchApprovalStockTransfer";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const baseUrl = process.env.REACT_APP_BASE_URL;

// Format currency to Local
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);

// Custom Tab Styling
const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#dc3545" } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({ textTransform: "none", minWidth: 0, [theme.breakpoints.up("sm")]: { minWidth: 0 }, fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1), fontSize: "14px", fontStretch: "normal", fontStyle: "normal", lineHeight: "2.75", letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","), "&:hover": { color: "#6e7074", opacity: 1 }, "&.Mui-selected": { color: "#dc3545", fontWeight: 600 }, "&.Mui-focusVisible": { backgroundColor: "blue" } }));

const APPROVAL = (props) => {
  const navigate = useNavigate();
  const approvalAddStockColumns = [
    {
      field: "batchNumber",
      headerName: "Batch Number",
      flex: 1,
      headerAlign: "left",
      renderHeader: () => {
        return <strong style={{ fontSize: "12px" }}>Batch Number</strong>;
      },
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      flex: 1,
      headerAlign: "left",
      renderHeader: () => {
        return <strong style={{ fontSize: "12px" }}>Date Created</strong>;
      },
      valueFormatter: (params) => {
        return moment(params.value).tz("Africa/Nairobi").format("llll");
      },
    },
    {
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <Checkbox value={params.id} checked={params.isChecked} onChange={(e) => handleAddStockCheckBox(e)} color="success">
              Action
            </Checkbox>
            <Cancel style={{ color: "#dc3545" }}></Cancel>
          </div>
        );
      },
      renderHeader: () => {
        return (
          <strong style={{ fontSize: "12px" }}>
            Action{" "}
            <select style={{ fontFamily: "Poppins", fontSize: "10px" }}>
              <option>Select Action</option>
              <option>Approve All</option>
              <option>Decline All</option>
            </select>
          </strong>
        );
      },
    },
  ];

  const approvalStockTakeColumns = [
    {
      field: "batchNumber",
      headerName: "Batch Number",
      flex: 1,
      headerAlign: "left",
      renderHeader: () => {
        return <strong style={{ fontSize: "12px" }}>Batch Number</strong>;
      },
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      flex: 1,
      headerAlign: "left",
      renderHeader: () => {
        return <strong style={{ fontSize: "12px" }}>Date Created</strong>;
      },
    },
    {
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <Checkbox value={params.id} checked={params.isChecked} onChange={(e) => handleStockTakeCheckBox(e)} color="success">
              Action
            </Checkbox>
            <Cancel style={{ color: "#dc3545" }}></Cancel>
          </div>
        );
      },
      renderHeader: () => {
        return (
          <strong style={{ fontSize: "12px" }}>
            Action{" "}
            <select style={{ fontFamily: "Poppins", fontSize: "10px" }}>
              <option>Select Action</option>
              <option>Approve All</option>
              <option>Decline All</option>
            </select>
          </strong>
        );
      },
    },
  ];

  // handle tab Changes
  const [tabValue, setTabValue] = React.useState("Add Stock");
  const [mainTabValue, setMainTabValue] = React.useState("Approved");
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
  };

  const breadCrumb = [
    <span key={1}>Dashboard</span>,
    <span key={2} style={{ cursor: "pointer" }} onClick={() => navigate(`/stock`)}>
      Stock
    </span>,
    <span key={3} style={{ color: "red" }}>Approvals</span>,
  ];

  const { wareHouseState } = useSelector((store) => store.sidebar);

  return (
    <div style={{ width: "100%" }}>
      <Grid container width={"100%"}>
        <Grid item sx={{ width: "100%", margin: "0% 0% 1% 0", display: "flex", alignItems: "center" }}>
          <span style={{ cursor: "pointer" }}>
            <ArrowBackIos onClick={() => navigate(`/stock`)} />
          </span>
          <span style={stockStyles.mediumIntroText}>Stock Management</span>
        </Grid>

        <Grid item sx={{ width: "100%", margin: "0% 0% 1% 0" }}>
          <Breadcrumbcomponent breadcrumbs={breadCrumb} />
        </Grid>
      </Grid>

      <Grid container width={"100%"}>
        <Grid item sx={{ width: "100%" }}>
          <TabContext value={mainTabValue}>
            <AntTabs onChange={handleMainTabChange}>
              <AntTab label="Approved" value="Approved" />
              <AntTab label="Pending" value="Pending" />
              <AntTab label="Cancelled" value="Cancelled" />
            </AntTabs>

            <TabPanel value="Approved">
              <TabContext value={tabValue}>
                <AntTabs onChange={handleTabChange}>
                  <AntTab value="Add Stock" label="Add Stock" />
                  <AntTab value="Stock Take" label="Stock Take" />
                  {wareHouseState && <AntTab value="Stock Transfer" label="Stock Transfer" />}

                  {/* <AntTab value="Returns" label="Returns" /> */}
                  <AntTab />
                  <AntTab />
                </AntTabs>

                <TabPanel value="Add Stock">
                  <ApprovedAddStockBatch />
                </TabPanel>

                <TabPanel value="Stock Take">
                  <ApprovedStockTakeBatch />
                </TabPanel>

                {wareHouseState && (
                  <TabPanel value="Stock Transfer">
                    <ApprovedStockTransfer />
                  </TabPanel>
                )}
              </TabContext>
            </TabPanel>

            <TabPanel value="Pending">
              <TabContext value={tabValue}>
                <AntTabs onChange={handleTabChange}>
                  <AntTab value="Add Stock" label="Add Stock" />
                  <AntTab value="Stock Take" label="Stock Take" />
                  {wareHouseState && <AntTab value="Stock Transfer" label="Stock Transfer" />}

                  {/* <AntTab value="Returns" label="Returns" /> */}
                  <AntTab />
                  <AntTab />
                </AntTabs>

                <TabPanel value="Add Stock">
                  <PendingBatchApproval />
                </TabPanel>

                <TabPanel value="Stock Take">
                  <PendingBatchApprovalStockTake />
                </TabPanel>
                {wareHouseState && (
                  <TabPanel value="Stock Transfer">
                    <PendingBatchApprovalStockTransfer />
                  </TabPanel>
                )}
              </TabContext>
            </TabPanel>

            <TabPanel value="Cancelled">
              <TabContext value={tabValue}>
                <AntTabs onChange={handleTabChange}>
                  <AntTab value="Add Stock" label="Add Stock" />
                  <AntTab value="Stock Take" label="Stock Take" />
                  {wareHouseState && <AntTab value="Stock Transfer" label="Stock Transfer" />}

                  {/* <AntTab value="Returns" label="Returns" /> */}
                  <AntTab />
                  <AntTab />
                </AntTabs>

                <TabPanel value="Add Stock">
                  <CancelledAddStockBatch />
                </TabPanel>

                <TabPanel value="Stock Take">
                  <CancelledStockTakeBatch />
                </TabPanel>

                {wareHouseState && (
                  <TabPanel value="Stock Transfer">
                    <CancelledStockTransferBatch />
                  </TabPanel>
                )}
              </TabContext>
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </div>
  );
};

export { APPROVAL };
