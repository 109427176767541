import React, { useState, useEffect } from "react";
import CustomDataGrid from "../customDataGrid";
import StockFunctions from "../stockEndpoints";
import DateFormatter from "../../../../utils/dateFormatter";
import { useNavigate  } from "react-router-dom";

const ApprovedAddStockBatch = () => {

	const navigate = useNavigate();
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize, mainstores: [], warehouseLoading: true, mainstorestock: [] });

  const addStockColumn = [
    { field: "batchNumber", headerName: "Batch No", flex: 1,renderCell:(params) => {
		return <span style={{color:"red", cursor:"pointer"}} onClick={() => navigate(`/viewmoreStock/approved/${params?.row?.batchId}`)} >{params?.row?.batchNumber}</span>
	} },
    { field: "dateCreated", headerName: "Date Created", flex: 1 },
    { field: "dateApproved", headerName: "Approved On", flex: 1 },
  ];

  useEffect(() => {
    const stockfunctions = new StockFunctions();

    stockfunctions.getApprovedAddStock(pageState.page, dataGridPageSize).then((data) => {
     setPageState({...pageState , data:data?.response?.data, count: data?.response?.count , isLoading:false});
    });
  }, [dataGridPageSize, pageState?.page]);

  const addStockRow = pageState?.data?.map((stock) => ({
	id:stock?._id,
	batchNumber:stock?.batchNumber,
	dateCreated: DateFormatter(stock?.dateCreated) ,
	dateApproved:DateFormatter(stock?.dateApproved) ,
	batchId:stock?.batchId
  }))

  return (
    <div>
      {/* <CustomDataGrid /> */}
      <CustomDataGrid column={addStockColumn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={addStockRow} />
    </div>
  );
};



const ApprovedStockTransfer = () => {

	const [dataGridPageSize, setPageSize] = React.useState(5);
	const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize, mainstores: [], warehouseLoading: true, mainstorestock: [] });
  
	const addStockColumn = [
	  { field: "batchNumber", headerName: "Batch No", flex: 1 },
	  { field: "dateCreated", headerName: "Date Created", flex: 1 },
	  { field: "dateApproved", headerName: "Approved On", flex: 1 },
	];
  
	useEffect(() => {
	  const stockfunctions = new StockFunctions();
  
	  stockfunctions.getApprovedStockTransfer(pageState.page, dataGridPageSize).then((data) => {
	   setPageState({...pageState , data:data?.response?.data, count: data?.response?.count , isLoading:false});
	  });
	}, [dataGridPageSize, pageState?.page]);
  
	const addStockRow = pageState?.data?.map((stock) => ({
	  id:stock?._id,
	  batchNumber:stock?.batchNumber,
	  dateCreated: DateFormatter(stock?.dateCreated) ,
	  dateApproved:DateFormatter(stock?.dateApproved) 
	}))

	return (
		<div>
			<CustomDataGrid column={addStockColumn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={addStockRow} />
		</div>
	)
}


const ApprovedStockTakeBatch = () => {

	const [dataGridPageSize, setPageSize] = React.useState(5);
	const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize, mainstores: [], warehouseLoading: true, mainstorestock: [] });
  
	const addStockColumn = [
	  { field: "batchNumber", headerName: "Batch No", flex: 1 },
	  { field: "dateCreated", headerName: "Date Created", flex: 1 },
	  { field: "dateApproved", headerName: "Approved On", flex: 1 },
	];
  
	useEffect(() => {
	  const stockfunctions = new StockFunctions();
  
	  stockfunctions.getApprovedStockTakeBatch(pageState.page, dataGridPageSize).then((data) => {
	   setPageState({...pageState , data:data?.response?.data, count: data?.response?.count , isLoading:false});
	  });
	}, [dataGridPageSize, pageState?.page]);
  
	const addStockRow = pageState?.data?.map((stock) => ({
	  id:stock?._id,
	  batchNumber:stock?.batchNumber,
	  dateCreated: DateFormatter(stock?.dateCreated) ,
	  dateApproved:DateFormatter(stock?.dateApproved) 
	}))

	return (
		<div>
			<CustomDataGrid column={addStockColumn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={addStockRow} />
		</div>
	)
}



export  { ApprovedAddStockBatch , ApprovedStockTransfer , ApprovedStockTakeBatch };
