import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {ExpandLess} from "@material-ui/icons";
import {ExpandMore} from "@mui/icons-material";
import {Collapse} from "@mui/material";
import List from "@material-ui/core/List";
import {Link} from "react-router-dom";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import AssessmentIcon from '@mui/icons-material/Assessment';
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

export default function Report (props) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	let { businessCat } = useSelector((store) => store.businessCategory);
	if(!businessCat) businessCat = localStorage.getItem("businessCategory");

	const handleClick = () => {setOpen(!open);};

	const userGroup = localStorage.getItem('group')

	return (
		<div>

			{/*Users*/}
			<ListItem button onClick={handleClick} style={{ color: open ? '#04f2fc' : '#fff' }}>
				<div style={{marginRight : '5%'}}>
					<AssessmentIcon />
				</div>
				<ListItemText primary="Reports" style={{fontFamily : 'Poppins'}}/>
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItem>

			{/*Collapsed Users*/}
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>

					{ (userGroup === 'Merchant' && businessCat === 'Rental') ?
						<Link style={{textDecoration: 'none', color : '#fff'}} to="/tenantsReport">
							<ListItem button className={classes.nested}>
								<ListItemText primary="Tenants Report" />
							</ListItem>
						</Link>
						: (userGroup === 'Admin' || userGroup === 'Merchant' || userGroup === "Owner") ?
						<div>
							{/* <Link style={{textDecoration: 'none', color : '#fff'}} to="/transactions">
								<ListItem button className={classes.nested}>
									<ListItemText primary="All Transactions" />
								</ListItem>
							</Link> */}

							<Link style={{textDecoration: 'none', color : '#fff'}} to="/reports">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Product Sales Report" />
								</ListItem>
							</Link>

							<Link style={{textDecoration: 'none', color : '#fff'}} to="/reports-by-cashier">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Sale Report by Cashier" />
								</ListItem>
							</Link>

							<Link style={{textDecoration: 'none', color : '#fff'}} to="/get_unpaid_orders_by_cashier">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Unpaid Orders by Cashier" />
								</ListItem>
							</Link>

							<Link style={{textDecoration: 'none', color : '#fff'}} to="/get_detailed_sales_report">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Sales Report" />
								</ListItem>
							</Link>

							<Link style={{textDecoration: 'none', color : '#fff'}} to="/get_sales_total_by_product">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Sales Total by Product" />
								</ListItem>
							</Link>

							<Link style={{textDecoration: 'none', color : '#fff'}} to="/get_total_sales_by_item_by_payments">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Sales by Product" />
								</ListItem>
							</Link>
							<Link style={{textDecoration: 'none', color : '#fff'}} to="/get_all_cashier_to_settle">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Cashier Settlement" />
								</ListItem>
							</Link>

						</div>: null
					}

				</List>
			</Collapse>
		</div>
	)
}