import {Button, ButtonBase, Grid} from "@mui/material";
import React, {useEffect, useState, useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// Image imports
import Eatery from "../../images/eatery.svg"
import Hotel from "../../images/Hotel.svg"
import Service from "../../images/Fuel.svg"
import Pharmacy from "../../images/pharmacy.svg"
import Hardware from "../../images/hardware.svg"
import Liquor from "../../images/liquor.svg"
import Retail from "../../images/retail.svg"
import WholeSale from "../../images/wholesale.svg"
import Manufucture from "../../images/manufucture.svg"
import Supplier from "../../images/supplier.svg"
import Distributor from "../../images/distributor.svg"
import School from "../../images/school.svg"
import Rental from "../../images/Rental.svg"
import { changeCategory} from '../../features/generalBusinessCategorySlice'

// Base Url
const baseUrl = process.env.REACT_APP_BASE_URL;
const username = localStorage.getItem('username')
export default function CreateBusiness() {
	const dispatch = useDispatch();
	

	const businessTypesMap = [
		{name : "Eatery, Bar and\n" + "Restaurant", img : Eatery, link : '/setup', category: 'eatery'},
		{name : "Hotel", img : Hotel, link : '/setup', category: 'hotel'},
		{name : "Service Station(Gas Station)", img : Service, link : '/setup', category: 'servicestation'},
		{name : "Pharmacy", img : Pharmacy, link : '/setup', category: 'pharmacy'},
		{name : "Hardware", img : Hardware, link : '/setup', category: 'hardware'},
		{name : "Liquor Shop", img : Liquor, link : '/setup', category: 'liquorshop'},
		{name : "Retail Shop", img : Retail, link : '/setup', category: 'retailshop'},
		{name : "WholeSale Shop", img : WholeSale, link : '/setup', category: 'wholesale'},
		{name : "Manufacturer", img : Manufucture, link : '/setup', category: 'manufacturer'},
		{name : "Suppliers & Vendors", img : Supplier, link : '/setup', category: 'suppliers'},
		{name : "Distributors", img : Distributor, link : '/setup', category: 'distributors'},
		{name : "School/College", img : School, link : '/setup/school', category: 'schoolBusiness'},
		{name : "Rental / Housing", img : Rental, link : '/setup/rental', category: 'rentalBusiness'},
	]
	const navigate = useNavigate()
	return (
		<div style={{fontFamily : 'Poppins'}}>

			{/*Create business label*/}
			<Grid container justifyContent={'center'} alignContent={'center'} style={{marginTop : '100px'}}>
				<Grid item style={{color : '#032541', fontSize : '25px', fontWeight : '600'}}>
					<span>Create your Business Now</span>
				</Grid>
			</Grid>

			{/*Select business label*/}
			<Grid container justifyContent={'center'} alignContent={'center'} style={{marginTop : '20px'}}>
				<Grid item style={{color : '#707070', fontSize : '16px', fontWeight : '500'}}>
					<span>Select your business Type</span>
				</Grid>
			</Grid>

			{/*Business Images*/}
			<Grid container justifyContent={'center'} alignContent={'center'} direction={'row'} style={{marginTop : '20px', padding : '0px 250px 0px 250px'}} spacing={2} >

				{
					businessTypesMap.map((businessType, index) => (
						<Grid item key={index} onClick={ async()=> {
							dispatch(changeCategory(businessType.category))
							navigate(`${businessType.link}/${businessType.category}`)
					}}>
							<Grid container direction={'row'}  aligContent={'center'} justifyContent={'center'} style={{textAlign : 'center'}}>
								<Grid container direction={'column'} spacing={2} alignContent={'center'} justifyContent={'center'} style={{textAlign : 'center'}}>
									{/*image & name*/}
										{/*image*/}
										<Grid item>
											<ButtonBase>
												<img style={{width : '100px', fill : 'red'}} src={businessType.img} alt={businessType.name} />
											</ButtonBase>
										</Grid>

										{/*name*/}
										<Grid item style={{width : '150px', textAlign : 'center',  fontSize : '14px'}}>
											<Link style={{textDecoration : "none", color : '#707070',  '&:hover': { color: '#032541' } }} to={businessType.link}>{businessType.name}</Link>
										</Grid>
								</Grid>
							</Grid>

						</Grid>
					))
				}

			</Grid>

		</div>
	);
}
