import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import HttpComponent from "../../../School/MakeRequest";
import { useSelector } from "react-redux";
import { Button, Grid, TextField } from "@mui/material";
import { Typography } from "@material-ui/core";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";


//base url

export default function EditTenantInfo(){

    //get the cutsomer id from the url params
    const { customerId } = useParams();
    const { X_Authorization } = useSelector((store) => store.user)
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
	const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [tenantDetails ,setTenantDetails] = useState({firstName:"" ,lastName:"" ,phone:"" ,email:""})
    const navigate = useNavigate()

    // handle onChange of the inputs

    const handleInputOnChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        //update the on state change

        setTenantDetails({ ...tenantDetails, [name]: value })


    }

    //fetch tenant details from the customer endpoints

    function fetchSingleTenant() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/get_customer_by_customer_id?customerId=${customerId}`,
                token: X_Authorization
            }).then((data) => {
                setTenantDetails({
                    ...tenantDetails,
                    firstName: data?.response?.data?.firstName,
                    lastName: data?.response?.data?.lastName,
                    email: data?.response?.data?.email,
                    phone: data?.response?.data?.phone
                })
              
            }).catch((e)=>console.log(e))

        } catch (error) {
            console.log(error)
        }

    }

    //useeffect to fetch tenant data

    useEffect(()=>{
        fetchSingleTenant()
    },[])

 

    // save tenant data after editing

    function postEditedTenantInfo(){
        try {
            HttpComponent({
                method:"PUT" ,
                url:`/api/updateCustomer?customerId=${customerId}` ,
                body:{
                    firstName:tenantDetails.firstName ,
                    lastName:tenantDetails.lastName ,
                    email:tenantDetails.email ,
                    phone:tenantDetails.phone
                },
                token:X_Authorization
            }).then((data)=>{
            
                if(data.status === 202){
                    setSuccessShow({state:true ,message:'Tenant information updated sucessfully'})
                    setTimeout(() => {
                        navigate('/tenants')
                      }, 2000);
                     
                }else{
                    setErrorShow({state:true ,message:"Error updating tenant information"})
                }
              
            })
        } catch (error) {
            console.log(error)
            
        }
    }
    return(
        <div>
            <ErrorAlert  vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} /> 
     		<SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />	
            <Grid container>
                <Typography variant="h6">Edit Tenant Details</Typography>
            </Grid>
            <Grid container spacing={3} mb={3} mt={3}>
                <Grid item >
                    <TextField fullWidth id="outlined-basic" name="firstName" value={tenantDetails?.firstName} onChange={handleInputOnChange} style={{width:"400px"}} label="FirstName"  variant="outlined" />
                </Grid>
                <Grid item>
                    <TextField fullWidth id="outlined-basic" name="lastName"  value={tenantDetails?.lastName} onChange={handleInputOnChange} style={{width:"400px"}} label="LastName" variant="outlined" />
                </Grid>
            </Grid>
            <Grid container spacing={3}  mb={2} mt={2}>
                <Grid item>
                    <TextField fullWidth id="outlined-basic" name="phone" value={tenantDetails?.phone} onChange={handleInputOnChange} style={{width:"400px"}} label="phoneNumber"  variant="outlined" />
                </Grid>
                <Grid item>
                    <TextField fullWidth id="outlined-basic" name="email" value={tenantDetails?.email} onChange={handleInputOnChange} style={{width:"400px"}} label="email" variant="outlined" />
                </Grid>
            </Grid>
            <Grid>
                <Button onClick={postEditedTenantInfo} style={{ background: "#032541" ,padding:"8px 15px" , width:"200px" ,color:"#fff"}}>Update </Button>
            </Grid>
        </div>
    )
}
