import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Link} from "react-router-dom";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(2),
	},
}));

export default function Expenditure () {

	const [active, setActive] = React.useState(false);
	const handleClick = () => {setActive(!active)};
	const classes = useStyles();

	return (
		<div>
			<ListItem button className={classes.nested} onClick={handleClick} style={{ color: active ? '#04f2fc' : '#fff'  }}>
				<div style={{marginRight : '5%'}}>
					<CurrencyExchangeIcon />
				</div>
				<Link style={{textDecoration: 'none', color : '#fff'}} to="/expenditure">
					<ListItemText primary="Expenditure" />
				</Link>
			</ListItem>
		</div>
	)
}
