import { Grid, List, ListItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import Dash from "../../common/dash";
import { redirect, useNavigate } from "react-router-dom";
import user from "../../common/images/user-icon.png";
import bell from "../../common/images/bell-icon.png";
import logout from "../../common/images/logout-icon.png";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Switch from "@mui/material/Switch";
import HttpComponent from "../School/MakeRequest";

// Base Url
const baseUrl = process.env.REACT_APP_BASE_URL;

const StockConfig = (props) => {
  const nav = useNavigate();
  const [stockConfigMap, setStockConfigMap] = useState([]);
  const [switchState, setSwitchState] = useState("");
  const handleSwitchClick = (index) => {
    const newItems = [...stockConfigMap];
    newItems[index].isSelected = !newItems[index].isSelected;
    setStockConfigMap(newItems);
  };

  const handleStockConfigUpdate = async (index) => {
    try {
      const response = await fetch(baseUrl + `/api/update_branch_stock_option`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({ stockOptionId: stockConfigMap[index].configName }),
      });
      await response.json().then((data) => {
        fetchStockConfigs().then(() => console.log("reFethcing has just happened!<>"));
      });
    } catch (err) {
      console.error(err);
    }
  };

  const fetchStockConfigs = async () => {
    try {
      const response = await fetch(baseUrl + "/api/get_branch_stock_options_list", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      await response
        .json()
        .then((data) => {
          const stockMap = data.data.map((config) => {
            return {
              id: config.id,
              configName: config.name,
              isSelected: config.isSelected,
            };
          });

          console.log("stockMap ====<><>", stockMap);
          setStockConfigMap(stockMap);
        })
        .catch((err) => {
          console.log("error getting stocks", err.message);
        });
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    fetchStockConfigs()
      .then(() => {
        console.log("Found!");
      })
      .catch((e) => {
        console.log(`Error on Getting Stock Config ${e.message}`);
      });
  }, []);

      // enable stock in sale!
      const upDateStockConfigs = (stock) => {
        switch (stock?.id) {
          case "No Stock":
            HttpComponent({
              method: "POST",
              url: `/api/update_branch_stock_option`,
              body: { stockOptionId: "No Stock" },
              token: localStorage.getItem("X-Authorization"),
            })
              .then(() => {
                HttpComponent({
                    method: "POST",
                    url: `/api/updateBranchStockNegativeStatus`,
                    body: { status: false },
                    token: localStorage.getItem("X-Authorization"),
                }).then(() => fetchStockConfigs())
    
                HttpComponent({
                    method: "POST",
                    url: `/api/update_supervisor_can_do_return`,
                    body: { status: false },
                    token: localStorage.getItem("X-Authorization"),
                  }).then(() => fetchStockConfigs())
    
                  HttpComponent({
                    method: "POST",
                    url: `/api/update_show_buying_price_in_sale_status`,
                    body: { showBuyingPriceInSale: false },
                    token: localStorage.getItem("X-Authorization"),
                  }).then(() => fetchStockConfigs())
              })
              .catch((e) => console.log(e.message));
            break;
    
          case "Stock In Zed":
            HttpComponent({
              method: "POST",
              url: `/api/update_branch_stock_option`,
              body: { stockOptionId: "Stock In Zed" },
              token: localStorage.getItem("X-Authorization"),
            })
              .then((data) => fetchStockConfigs())
              .catch((e) => console.log(e.message));
            break;
          case "Stock in Sale":
            HttpComponent({
              method: "POST",
              url: `/api/update_branch_stock_in_sale_status`,
              body: { showStockInSale: stock.isSelected },
              token: localStorage.getItem("X-Authorization"),
            })
              .then((data) => {
                fetchStockConfigs();
              })
              .catch((e) => {
                console.log(e.message);
              });
            break;
          case "Buying price In Sale":
            const noStockBuying = [...stockConfigMap]
            const noStockBuyingValue = noStockBuying[0]?.isSelected
            HttpComponent({
              method: "POST",
              url: `/api/update_show_buying_price_in_sale_status`,
              body: { showBuyingPriceInSale: noStockBuyingValue === true ? false : true },
              token: localStorage.getItem("X-Authorization"),
            })
              .then((data) => fetchStockConfigs())
              .catch((e) => console.log(e.message));
            break;
          case "Allow Negative Order":
            const newStock = [...stockConfigMap]
            const noStockValue = newStock[0]?.isSelected
            HttpComponent({
              method: "POST",
              url: `/api/updateBranchStockNegativeStatus`,
              body: { status: noStockValue === true ? false : true },
              token: localStorage.getItem("X-Authorization"),
            })
              .then((data) => fetchStockConfigs())
              .catch((e) => console.log(e.message));
            break;
          case "Enable Return by Supervisor":
            const noStockReturn = [...stockConfigMap]
            const noStockReturnValue = noStockReturn[0]?.isSelected
            HttpComponent({
              method: "POST",
              url: `/api/update_supervisor_can_do_return`,
              body: { status: noStockReturnValue === true ? false : true }, 
              token: localStorage.getItem("X-Authorization"),
            })
              .then((data) => fetchStockConfigs())
              .catch((e) => console.log(e.message));
            break;
          default:
            break;
        }
      };

  return (
    <div>
      <Grid container item className={"container-fluid"}>
        {/*Page Title Container*/}
        <Grid container item justifyContent="flex-start" alignItems="left" style={{ width: "86%" }}>
          <label style={{ height: "35px", margin: "24px 718px 24px 0", fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.32", letterSpacing: "normal", textAlign: "left", color: "#dc3545" }}>Configuration</label>
        </Grid>

        {/*Page Sub title and Search */}
        <Grid container item justifyContent="space-between" alignItems="left" style={{ width: "86%" }}>
          <Grid item>
            <div style={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: "600" }}>
              <span style={{ color: "#6e7074" }}>Dashboard \ </span>
              <span style={{ color: "#032541" }}>Stock Configs</span>
            </div>
          </Grid>

          {/*Search input*/}
          <Grid item>
            <span style={{ marginRight: "10px", fontFamily: "Poppins", fontSize: "15px", fontWeight: "bold", color: "#032541" }}>Search :</span>
            <TextField
              size="small"
              placeholder={"search"}
              style={{ height: "1px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ width: "15px", height: "15px" }} />
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </Grid>
        </Grid>

        {/*Headers*/}
        <Grid container item justifyContent={"space-between"} alignContent={"center"} style={{ width: "86%", marginTop: "30px" }}>
          <Grid item style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: "600", color: "#032541" }}>
            Configuration
          </Grid>
          <Grid item style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: "600", color: "#032541" }}>
            Action
          </Grid>
        </Grid>

        {/*Configurations*/}
        <Grid container item style={{ width: "86%", marginTop: "30px" }}>
          <Grid item style={{ width: "100%" }}>
            <List>
              {stockConfigMap.map((stock, index) => {
                return (
                  <ListItem key={stock.id} style={{ marginBottom: "10px", fontFamily: "Poppins", fontSize: "14px", borderRadius: "6px", border: "solid 1px rgba(193,193,193, .3)" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                      <div style={{ paddingTop: "10px", fontFamily: "Poppins", fontSize: "15px", color: "#032541" }}>{stock.configName}</div>
                      <div>
                        <Switch
                          checked={stock.isSelected}
                          onClick={() => {
                            handleSwitchClick(index);
                            upDateStockConfigs(stock);
                          }}
                        />
                      </div>
                    </div>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export { StockConfig };
