import {Breadcrumbs, Button, ButtonBase, Grid, Tab, Typography} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {styled} from "@mui/material/styles";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import CurrencyFormatter from "../../../../utils/currencyFormatter";
import CustomTable from "../../../School/CustomTable";
import HttpComponent from "../../../School/MakeRequest";
import {useDispatch, useSelector} from "react-redux";
import CustomSearchInput from "../../../School/CustomSearchInput";
import {makeStyles} from "@material-ui/core/styles";
import CreateTInvoice from "../tenantDetails/CreateTinvoice";
import PreviewAllInvoices from "./previewAllInvoice";
import { handleReset } from "../../../../features/tenantsDataSlice";

// Bread Crumbs
const breadcrumbs = [
	<Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">Dashboard</Typography>,
	<Typography style={{color : '#dc3545', fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.error">Invoices</Typography>
];

const useStyles = makeStyles((theme) => ({
	unpaid: {
		background : 'rgba(220, 53, 69, 0.1)',
		color : "#272d3b",
		borderRadius : "4px",
		padding : "5px"
	},
	paid : {
		background : 'rgba(23, 174, 123, 0.1)',
		color : "#272d3b",
		borderRadius : "4px",
		padding : "5px"
	},
	overPaid : {
		background : 'rgba(23, 174, 123, 0.1)',
		color : "#272d3b",
		borderRadius : "4px",
		padding : "5px"
	},
	partiallyPaid : {
		color : "#272d3b",
		background : 'rgba(255, 133, 3, 0.1)',
		borderRadius : "4px",
		padding : "5px"

	},forfeited : {
		color : "#272d3b",
		background : 'rgba(18, 104, 240, 0.1)',
		borderRadius : "4px",
		padding : "5px"
	}
}));


const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#dc3545" } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
	textTransform: "none", minWidth: 0, [theme.breakpoints.up("sm")]: { minWidth: 0 },
	fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1),
	fontSize: "18px", fontStretch: "normal", fontStyle: "normal", lineHeight: "2.75",
	letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","),
	"&:hover": { color: "#032541", opacity: 1 }, "&.Mui-selected": { color: "#dc3545", fontWeight: 600 },
	"&.Mui-focusVisible": { backgroundColor: "blue" },
}));


export default function TenantInvoice() {
		const classes = useStyles()
		const dispatch = useDispatch()
		const [flagState ,setFlagState] = useState('listInvoices')
		const [draftInv ,setDraftInv] = useState([])

		const handleFlagState = ()=>{
			setFlagState('createINVO')
			dispatch(handleReset())
		}

		const handlepreviewinvoice = ()=>{
			  setFlagState('previewinvoices')
		}

		const goBack = ()=>{
			setFlagState('listInvoices')
		}

		

		const outgoingColumns = [
			{field : 'invoiceNumber', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >invoice No.</strong>)}, renderCell : (params) => {return (
				<Link to={`/tenantInvoicePreview/${params.value}`} style={{textDecoration : "none", textTransform : "none"}} className={params.row.invoiceStatus === 'CANCEL' ? classes.unpaid : params.row.invoiceStatus === 'Partially Paid' ? classes.partiallyPaid : params.row.invoiceStatus === 'FORFEIT' ? classes.forfeited : params.row.invoiceStatus === 'Paid' ? classes.paid : params.row.invoiceStatus === "Unpaid" ? classes.unpaid : params.row.invoiceStatus === "OverPaid" ? classes.overpaid : ""}>{params.value}</Link>
				)}},
			{field : 'sendToFirstName', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >To</strong>)}},
			{field : 'createdAt', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >Invoice Month</strong>)}},
			{field : 'invoiceAmount', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >Invoice Amount</strong>)}, renderCell : (params) => {return (CurrencyFormatter(params.value, 'KES'))}},
			{field : 'invoiceBalance', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >Paid Amount</strong>)}, renderCell : (params) => {return (
				<span className={params.row.invoiceStatus === 'CANCEL' ? classes.unpaid : params.row.invoiceStatus === 'Partially Paid' ? classes.partiallyPaid : params.row.invoiceStatus === 'FORFEIT' ? classes.forfeited : params.row.invoiceStatus === 'Paid' ? classes.paid : params.row.invoiceStatus === "Unpaid" ? classes.unpaid : params.row.invoiceStatus === "OverPaid" ? classes.overpaid : ""} >{CurrencyFormatter(parseInt(params.row.invoiceAmount) - parseInt(params.value), 'KES')}</span>
				)}},
			{field : 'invoiceType', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >Type</strong>)}},
			{field : 'invoiceStatus', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >Status</strong>)}, renderCell : (params) => {return (
				<span className={params.row.invoiceStatus === 'CANCEL' ? classes.unpaid : params.row.invoiceStatus === 'Partially Paid' ? classes.partiallyPaid : params.row.invoiceStatus === 'FORFEIT' ? classes.forfeited : params.row.invoiceStatus === 'Paid' ? classes.paid : params.row.invoiceStatus === "Unpaid" ? classes.unpaid : params.row.invoiceStatus === "OverPaid" ? classes.overpaid : ""}>{params.value}</span>
				)}},
		]

		const incomingColumns = [
			{field : 'invoiceNumber', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >invoice No.</strong>)}, renderCell : (params) => {return (
					<Link to={`/tenantInvoicePreview/${params.value}`} style={{textDecoration : "none", textTransform : "none"}} className={params.row.invoiceStatus === 'CANCEL' ? classes.unpaid : params.row.invoiceStatus === 'Partially Paid' ? classes.partiallyPaid : params.row.invoiceStatus === 'FORFEIT' ? classes.forfeited : params.row.invoiceStatus === 'Paid' ? classes.paid : params.row.invoiceStatus === "Unpaid" ? classes.unpaid : params.row.invoiceStatus === "OverPaid" ? classes.overpaid : ""}>{params.value}</Link>
				)}},
			{field : 'sendToFirstName', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >From</strong>)}},
			{field : 'createdAt', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >Invoice Month</strong>)}},
			{field : 'invoiceAmount', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >Invoice Amount</strong>)}, renderCell : (params) => {return (CurrencyFormatter(params.value, 'KES'))}},
			{field : 'invoiceBalance', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >Paid Amount</strong>)}, renderCell : (params) => {return (
					<span className={params.row.invoiceStatus === 'CANCEL' ? classes.unpaid : params.row.invoiceStatus === 'Partially Paid' ? classes.partiallyPaid : params.row.invoiceStatus === 'FORFEIT' ? classes.forfeited : params.row.invoiceStatus === 'Paid' ? classes.paid : params.row.invoiceStatus === "Unpaid" ? classes.unpaid : params.row.invoiceStatus === "OverPaid" ? classes.overpaid : ""} >{CurrencyFormatter(parseInt(params.row.invoiceAmount) - parseInt(params.value), 'KES')}</span>
				)}},
			{field : 'invoiceType', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >Type</strong>)}},
			{field : 'invoiceStatus', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >Status</strong>)}, renderCell : (params) => {return (
					<span className={params.row.invoiceStatus === 'CANCEL' ? classes.unpaid : params.row.invoiceStatus === 'Partially Paid' ? classes.partiallyPaid : params.row.invoiceStatus === 'FORFEIT' ? classes.forfeited : params.row.invoiceStatus === 'Paid' ? classes.paid : params.row.invoiceStatus === "Unpaid" ? classes.unpaid : params.row.invoiceStatus === "OverPaid" ? classes.overpaid : ""}>{params.value}</span>
				)}},
		]

		const draftInvoices = [
			{field : 'invoiceNumber', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >invoice No.</strong>)}, renderCell : (params) => {return (
				<Link to={`/tenantInvoicePreview/${params.value}`} style={{textDecoration : "none", textTransform : "none"}} className={params.row.invoiceStatus === 'CANCEL' ? classes.unpaid : params.row.invoiceStatus === 'Partially Paid' ? classes.partiallyPaid : params.row.invoiceStatus === 'FORFEIT' ? classes.forfeited : params.row.invoiceStatus === 'Paid' ? classes.paid : params.row.invoiceStatus === "Unpaid" ? classes.unpaid : params.row.invoiceStatus === "OverPaid" ? classes.overpaid : ""}>{params.value}</Link>
			)}},
		{field : 'sendToFirstName', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >From</strong>)}},
		{field : 'createdAt', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >Invoice Month</strong>)}},
		{field : 'invoiceAmount', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >Invoice Amount</strong>)}, renderCell : (params) => {return (CurrencyFormatter(params.value, 'KES'))}},
		{field : 'invoiceType', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >Type</strong>)}},
		{field : 'invoiceStatus', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >Status</strong>)}},

		]

		const navigate = useNavigate()
		const {X_Authorization} = useSelector((store) => store.user)

		// Data
		const [data, setData] = useState('')

		const [tabValue, setTabValue] = useState("Outgoing");

		// Loader Setter
		const [loader, setLoader] = useState(false)
		const handleTabChange = (event, newValue) => {setTabValue(newValue);};

		// Search functionality
		const [dataToShow, setDataToShow] = useState([]);
		const [formData, setFormData] = useState({search:""})

		const handleInputChange = (e) => {setFormData({...formData, [e.target.name]: e.target.value,});};

		const setSearchData = () => {
			let searchable = [...data]
			if(formData.search !== ""){
				let result = searchable.filter(invoice =>
					invoice.invoiceNumber?.toLowerCase()?.includes(formData.search) || invoice.invoiceNumber?.includes(formData.search) ||
					invoice.sendToFirstName?.toLowerCase().includes(formData.search) || invoice.sendToFirstName.includes(formData.search)
				);
				setDataToShow(result);
			}else{
				setDataToShow(data)
			}
		}


		// Fetch Invoices
		function getInvoices () {
			try {
				HttpComponent ({
					method : 'GET',

					url : `/api/get_business_invoices?limit=1000&page=1`,
					token : X_Authorization
				}).then((data) => {
					if (data.status === 200) {
						setData(data.response.data)
					} else {
						console.log(`Error getting Invoices`)
					}

				}).catch((e) => {
					console.log(`Error getting Invoices`, e.message)
				})
			} catch (e) {
				console.log(`Error getting Invoices`, e.message)
			}
		}

		// Fetch Incoming Invoices
		function getIncomingInvoices () {
			try {
				HttpComponent ({
					method : 'GET',
					url : `/api/get_user_invoices?limit=1000&page=1`,
					token : X_Authorization
				}).then((data) => {
					if (data.status === 200) {
						setData(data.response.data)
					} else {
						console.log(`Error getting Invoices`)
					}

				}).catch((e) => {
					console.log(`Error getting Invoices`, e.message)
				})
			} catch (e) {
				console.log(`Error getting Invoices`, e.message)
			}
		}

		//get draft invoices
		function getDraftInvoices () {
			try {
				HttpComponent ({
					method : 'GET',
					url : `/api/get_business_invoices_by_status?limit=1000&page=1&status=DRAFT`,
					token : X_Authorization
				}).then((data) => {

					console.log(data ,'draft invoices')
					if (data.status === 200) {
						setDraftInv(data.response.data)
					} else {
						console.log(`Error getting Invoices`)
					}

				}).catch((e) => {
					console.log(`Error getting Invoices`, e.message)
				})
			} catch (e) {
				console.log(`Error getting Invoices`, e.message)
			}
		}



		// Use Effect

		useEffect(()=>{
			getDraftInvoices();

		},[])
		// Search UseEffect
		useEffect(() => {
			setSearchData()
		}, [formData.search, data]);

		// get Invoices UseEffect
		useEffect(() => {
			if (tabValue === 'Outgoing') {
				getInvoices()
			} else if (tabValue === 'Incoming') {
				getIncomingInvoices()
			}
		}, [tabValue])


		return (
			<div>
				{flagState  === 'listInvoices' ? 
				<>

				{/*Header Container*/}
				<Grid container justifyContent={'space-between'} mb={3}>
					{/*Month Name*/}
					<Grid item>
						<span style={{color : '#dc3545', fontWeight : 'bold', fontSize : '25px'}}>Invoice</span>
						<Grid item>
							<Breadcrumbs style={{fontFamily : 'Poppins', fontSize : '14px'}} separator={<FiberManualRecordIcon style={{fontSize:"0.625rem", fontFamily : 'Poppins', color:"#e1e5e8"}} />} aria-label="breadcrumb">
								{breadcrumbs}
							</Breadcrumbs>
						</Grid>
					</Grid>

					<Grid item>
						{/*BreadCrumb Container & Add More units*/}
						<Grid container justifyContent={'flex-end'} spacing={2}>

							{/*Create Statement*/}
							<Grid item>
							<Button style={{padding : '10px', marginRight:"20px" , textTransform : 'none', color : '#032541', background : '#fff', height : '45px', fontSize : '16px', fontWeight : '600', border : '1px solid #032541'}}>
									Statement
								</Button>
								<Button onClick={handleFlagState} style={{padding : '10px', textTransform : 'none', color : '#fff', height : '45px', fontSize : '16px', fontWeight : '600',  background : '#032541'}}>
									Create Invoice
								</Button>
							</Grid>

						</Grid>
					</Grid>
				</Grid>

				{/*Tabs*/}
				<TabContext value={tabValue}>
					<AntTabs onChange={handleTabChange} textColor="primary" TabIndicatorProps={{ hidden: true }}>
						<AntTab label="Outgoing" value="Outgoing" />
						<AntTab label="Incoming" value="Incoming" />
						<AntTab label="Saved" value="Saved" />
					</AntTabs>


					{/*Search and Export Container*/}
					<Grid container justifyContent={'space-between'} mt={4}>

						{/*SearchBox*/}
						<Grid item>
							<CustomSearchInput name={"search"} value={formData.search} onChange={handleInputChange} placeholder="Search:"/>
						</Grid>

						{/*Export button*/}
						<Grid item>
							{/*	TODO : Add Export Button*/}
						</Grid>
					</Grid>

					{/*Outgoing tenants*/}
					<TabPanel value="Outgoing">
						<CustomTable fontSize={'10px'} data={dataToShow} columns={outgoingColumns} rowsPerPage={5} loading={loader} />
					</TabPanel>

					{/*Incoming tenants*/}
					<TabPanel value="Incoming">
						<CustomTable fontSize={'10px'} data={dataToShow} columns={incomingColumns} rowsPerPage={5} loading={loader} />
					</TabPanel>
					<TabPanel value="Saved">
						<CustomTable fontSize={'10px'} data={draftInv} columns={draftInvoices} rowsPerPage={5} loading={loader} />
					</TabPanel>

				</TabContext>
				</>
				: flagState === 'createINVO' ? <CreateTInvoice goBack={goBack} preview={handlepreviewinvoice} handleFlagState={handleFlagState}/> : flagState === 'previewinvoices' ? <PreviewAllInvoices goBack={goBack}/>: null
				
				}

			</div>
		)
}