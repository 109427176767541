import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import { Link } from "react-router-dom";
import Users from "./DashBoard Components/users";
import Business from "./DashBoard Components/business";
import Terminals from "./DashBoard Components/terminals";
import Bills from "./DashBoard Components/bills";
import Inventory from "./DashBoard Components/inventory";
import CustomerOnAccount from "./DashBoard Components/customers";
import Transactions from "./DashBoard Components/transactions";
import Report from "./DashBoard Components/report";
import MerchantConfiguration from "./DashBoard Components/merchantConfiguration";
import AdminConfiguration from "./DashBoard Components/adminConfiguration";
import Accounting from "./DashBoard Components/accounting";
import { Divider } from "@mui/material";
import DashLogo from "./images/dashlogo.png";
import Dashboard from "./DashBoard Components/dashboard";
import Sms from "./DashBoard Components/sms";
import House from "./DashBoard Components/house";
import Expenditure from "./DashBoard Components/expenditure";
import { ArrowBackIos } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { closeSideBar, switchWarehouseState } from "../features/sideBarSlice";
import CreditNote from "./DashBoard Components/creditNote";
import Vouchers from "./DashBoard Components/vouchers";
// import PaymentSetup from "../components/School/PaymentSetup";
import PaymentSetUP from "./DashBoard Components/paymentsetup";
import PaymentSettingUp from "../components/paymentmodule/paymentsettingup";
import Warehouse from "./DashBoard Components/warehouse";
import logout from "./images/logout-icon.png";
import aboutIcon from "./images/about-icon.svg";
import Box from '@mui/material/Box';
import { redirect, useNavigate } from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import StockFunctions from "../components/products/stockComponents/stockEndpoints";
import AdminBankSetUp from "./user Dashboard Components/banks/banksetup";



// responsive navbar styling
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontFamily: "Poppins",
  },
  drawer: {
    width: 291,
    color: "#fff",
    flexShrink: 0,
  },
  drawerPaper: {
    width: 291,
    color: "#fff",
    backgroundColor: "rgb(3,37,65)",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nested: {
    color: "#fff",
    paddingLeft: theme.spacing(4),
  },
}));

export default function Sidebar({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const userGroup = localStorage.getItem("group");
  let businessCat = localStorage.getItem("businessCategory");
  let branchId = localStorage.getItem("BranchID");
  const { group } = useSelector((store) => store.user);
  
  const isStoreOperator = userGroup && userGroup?.toLocaleLowerCase()?.includes("storeoperator")
  const isCashier = branchId && userGroup && userGroup === "Cashier" || branchId && isStoreOperator ;
  const { isSideBarOpen, isMobile, wareHouseState } = useSelector((store) => store.sidebar);
  const IsStoreManager = userGroup && userGroup?.toLocaleLowerCase()?.includes("storemanager")
  const isSupervisor = userGroup && userGroup === "Supervisor"

  // user who is not parent or tenant but customer on accounts;
  const [businessUser, setBusinessUser] = useState(false)

  useEffect(() => {
    if(userGroup === "Cashier"){
      dispatch(closeSideBar(false))
    }
  }, [])

  useEffect(() => {

  if(userGroup === 'Customer'  && businessCat !== 'Rental' || businessCat === "School"   ) {
    setBusinessUser(true)
  } 

 
  },[userGroup, businessCat])

    // if(userGroup === "StoreOperator" || userGroup === "STOREOPERATOR" || userGroup === "STOREMANAGER"){
 

  let drawerStyles = {
    color: "#fff",
    width: "291px",
    flexShrink: "0",
  };

  if (isSideBarOpen === false) {
    drawerStyles = {
      color: "#fff",
      width: "0px",
      flexShrink: "0",
    };
    // classes.drawer = {width: 0,
    //   color: "#fff",
    //   flexShrink: 0,}
  } else if (isSideBarOpen === true) {
    drawerStyles = {
      color: "#fff",
      width: "291px",
      flexShrink: "0",
    };
  } else {
    drawerStyles = {
      drawerColor: "#fff",
      drawerWidth: "291px",
      flexShrink: "0",
    };
  }


  
  return (
    <div className={classes.root}>
      <Drawer open={isSideBarOpen} style={drawerStyles} variant="persistent" classes={{ paper: classes.drawerPaper }}>
        {/*logo*/}
        <div align={"center"} style={{ marginTop: "5%", marginBottom: "5%" }}>
          <Link to={"/dashboard"} style={{ marginRight: "30%" }}>
            <img src={DashLogo} alt="Zed Logo" width="140px" height="70px" />
          </Link>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(closeSideBar({ sideBarState: false }));
              // setSideBarToggler(false)
            }}
          >
            <ArrowBackIos />
          </span>
        </div>
        <Divider />
        <List>
          {/*Dashboard*/}
          {isCashier || IsStoreManager || isSupervisor ?  null :  <Dashboard />} 

          {/*Users*/}
          {(userGroup === "Customer" || userGroup === "Merchant"  && businessCat === "Rental") ||  businessUser || isCashier || IsStoreManager || isSupervisor   ? null : <Users />}

          {/*Business*/}
           <Business />

            {/*Credit Note*/}
          {userGroup === "Customer" || businessCat === "Rental" ||  businessUser ||  isCashier || !wareHouseState || isSupervisor  ? null :  <Warehouse/>}
         

          {/*Bills*/}
          {businessCat === "Rental" || businessCat === "School" ||  businessUser || IsStoreManager ? null : <Bills />}
          {businessCat === "Rental" || userGroup === "Customer"|| userGroup ==='Admin' ||  businessUser || isCashier || IsStoreManager || isSupervisor ? null : <PaymentSetUP />}

          {userGroup === 'Admin' ?<AdminBankSetUp/> :null }

          {/*House*/}
          {(userGroup === "Customer" && businessCat === "Rental") || isCashier || businessCat === "School" || businessCat !== "Rental"   ? null : <House />}

          {/*sms*/}
          {(userGroup === "Customer" || userGroup === "Merchant" && businessCat === "Rental") ||  businessUser || isCashier || IsStoreManager || isSupervisor ? null : <Sms />}
          {/*{!isCashier && userGroup !== 'Customer' && businessCat === 'Rental' ? <Sms />}*/}

          {/*Terminals*/}
          {businessCat === "Rental" ||  businessUser || isCashier || IsStoreManager || isSupervisor  ? null : <Terminals />}

          {/*Inventory*/}
          {(userGroup === "Customer" && businessCat === "Rental") ||  businessUser || isCashier  ? null : <Inventory businessCat={businessCat} />}

          {/*Expenditure*/}
          {businessCat === "Rental" ||  businessUser || isCashier || IsStoreManager || isSupervisor ? null : <Expenditure />}
          {/*Credit Note*/}
          {userGroup === "Customer" || businessCat === "Rental" ||  businessUser || isCashier || IsStoreManager   ? null : <CreditNote />}

          {/*Vouchers Note*/}
          {businessCat === "Rental" ||  businessUser || isCashier || IsStoreManager ? null : <Vouchers />}

          {/*Customer on Account*/}
          {(userGroup === "Customer"  && businessCat === "Rental" || businessCat === "School") ||  businessUser || isCashier ? null : <CustomerOnAccount />}

          {/*Transactions*/}
          {(userGroup === "Customer" || userGroup === "Merchant" && businessCat === "Rental") ||  businessUser || isCashier || IsStoreManager || isSupervisor  ? null : <Transactions />}

          {/*Reports*/}
          {(userGroup === "Customer" && businessCat === "Rental") ||  businessUser || isCashier || IsStoreManager || isSupervisor ? null : <Report />}

          {/*Configuration*/}
          {userGroup === "Merchant" ? <MerchantConfiguration /> : userGroup === "Admin"  ? <AdminConfiguration /> : null}

          {/* about */}
          <Box style={{ display:'flex', alignItems:'center', cursor:'pointer' }}>
            <Box style={{ width: "25px", height: "25px",marginLeft:'18px', marginRight: "10px", }}><InfoIcon /></Box>
            <Box><Typography>About ZED</Typography></Box>
          </Box>
          {/* logout */}
          <Box onClick={() => {
              navigate("/logout");
            }}
            style={{ display:'flex', alignItems:'center', cursor:'pointer', marginTop:"10px", marginBottom:'20px' }}
            >
          <Box>
              <img
            style={{ width: "25px", height: "25px", objectFit: "contain", marginLeft:'18px', borderRadius: "50%", border: "1px solid transparent", padding: "2px", marginRight: "10px", cursor: "pointer" }}
            src={logout}
            alt={"img"}
          />
          </Box>
            <Box>Logout</Box>
          </Box>

          {/*Accounting*/}
          {businessCat === "Rental" || isCashier ? null : <Accounting />}

          {/*Footer*/}
          <div align={"center"} style={{ marginTop: "30%" }}>
            <span>Copyright ©{new Date().getFullYear()} Zed. All rights reserved.</span>
          </div>
        </List>
      </Drawer>
      <main className={classes.content}>{children}</main>
    </div>
  );
}
