import {TabContext, TabList, TabPanel} from "@mui/lab";
import {DataGrid} from "@mui/x-data-grid";
import React, {useState} from 'react'
import {makeStyles} from "@material-ui/core/styles";
import Pagination from "@mui/material/Pagination";
import {Box, Grid, styled, Tab} from "@mui/material";
import Stack from '@mui/material/Stack';
import lodash from "lodash";
import orderImage from "../../../common/images/orderNumbers.svg"
import {NoRowsOverlay} from "../../No Rows/noRowsOverlay";
import OpenOrders from "./openOrders";

const numberFormat = (value) => new Intl.NumberFormat("en-US", {style: "currency", currency: "KES"}).format(value);

//Styling
const ListStyles = makeStyles((theme) => ({
	orderList : {
		fontFamily : 'Poppins',
		fontWeight : '500',
		borderRadius:"6px",
		border:"solid 1px #e1d6d6",
		height : '64px',
		color : '#032541',
		fontSize : '15px'
	},
	iconColor : {
		fontSize : 'small',
		padding : '3px',
		color : '#DC3545FF',
		marginRight : '20px'
	},
	footerTotal : {
		fontSize : '20px',
		color : '#17ae7b',
		fontWeight : '600',
		textAlign : 'right',
		marginTop : '10px',
		height: "103px",
		boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
		backgroundColor: "#fff",
		paddingTop : '25px',
		paddingRight : '25px'
	},
	imageStyle : {
		paddingLeft : '25px',
	},
	innerTotalOrderHeader :{
		paddingLeft : '25px',
		color : "#032541"
	},
	innerTotalOrderNumber :{
		fontWeight : 'bold'
	}
}))

// tabbing styling
const AntTabs = styled(TabList)({borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": {backgroundColor: "#fff",},});
const AntTab = styled((props) =>
	<Tab disableRipple {...props} />)(({ theme }) =>
	({textTransform: "none", minWidth: 0, [theme.breakpoints.up("sm")]:
			{minWidth: 0,}, fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1), fontSize: "13px", fontStretch: "normal", fontStyle: "normal", lineHeight: "2.75", letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","), "&:hover": {color: "#032541", opacity: 1,}, "&.Mui-selected": {color: "#dc3545", fontWeight: 600,}, "&.Mui-focusVisible": {backgroundColor: "blue",},}));


const cashierColumns = [
	{field : 'category', renderHeader : () => {return <strong style={{fontSize : '15px', fontWeight : '600', color : '#032541FF'}}>Product Category</strong>}, flex : 1, align : 'left', headerAlign : 'left'},
	{field : 'orders', renderHeader : () => {return <strong style={{fontSize : '15px', fontWeight : '600', color : '#032541FF'}}>Orders</strong>}, flex : 1, align : 'left', headerAlign : 'left'},
	{field : 'sales', renderHeader : () => {return <strong style={{fontSize : '15px', fontWeight : '600', color : '#032541FF'}}>Sales</strong>}, flex : 1, align : 'left', headerAlign : 'left'}
]

const counterColumns = [
	{field : 'date', renderHeader : () => {return <strong style={{fontSize : '15px', fontWeight : '600', color : '#032541FF'}}>Date & Time</strong>}, flex : 1, align : 'left', headerAlign : 'left'},
	{field : 'paymentMode', renderHeader : () => {return <strong style={{fontSize : '15px', fontWeight : '600', color : '#032541FF'}}>Payment Mode</strong>}, flex : 1, align : 'left', headerAlign : 'left'},
	{field : 'amount', renderHeader : () => {return <strong style={{fontSize : '15px', fontWeight : '600', color : '#032541FF'}}>Amount</strong>}, flex : 1, align : 'left', headerAlign : 'left'},
]

export default function ShiftReport () {
	const classes = ListStyles();
	
	//tab Setter
	const [tabValue, setTabValue] = useState("cashier");
	const setNewTabValue = (prev, newValue) => {setTabValue(newValue);}
	
	const cashierRows = [
		{id : 1, category : 'wine', orders : '14' , sales : numberFormat(54000)},
		{id : 2, category : 'wine', orders : '14' , sales : numberFormat(54000)},
		{id : 3, category : 'wine', orders : '14' , sales : numberFormat(54000)},
	]
	
	const counterRows = [
		{id : 1, date : '2023-01-05 1:45:06 Pm', paymentMode : 'Cash Payment' , amount : 54000},
		{id : 2, date : '2023-01-05 1:45:06 Pm', paymentMode : 'Cash Payment' , amount : 54000},
		{id : 3, date : '2023-01-05 1:45:06 Pm', paymentMode : 'Cash Payment' , amount : 54000},
	]
	
	const totalSum = lodash.sumBy(counterRows, amounts => {return amounts.amount}) || 0
	
	return (
		<div style={{fontFamily : 'Poppins'}}>
			
			{/*Cashier & On Counter Container*/}
			<TabContext value={tabValue}>
				<Box>
					
					{/*Tab Header*/}
					<AntTabs onChange={setNewTabValue}>
						<AntTab value="cashier" label="Cashier" />
						<AntTab value="counter" label="Over the Counter" />
					</AntTabs>
				</Box>
				
				{/*Tabs*/}
				{/*Cashier*/}
				<TabPanel value="cashier">
					<Box sx={{ height: "400px" }}>
						<DataGrid
							components={{NoRowsOverlay : NoRowsOverlay}}
							sx={{height : '400px', width : 'auto', fontFamily : 'Poppins', fontSize: "10px", color : '#272d3d',boxShadow: 0, border: 0}}
							columns={cashierColumns}
							rows={[]}
							getRowId={(row) => row.id}
						/>
					</Box>
				</TabPanel>
				
				{/*Over the Counter*/}
				<TabPanel value="counter">
					<Box sx={{ height: "400px" }}>
						<DataGrid
							components={{NoRowsOverlay : NoRowsOverlay}}
							sx={{height : '400px', width : 'auto', fontFamily : 'Poppins', fontSize: "10px", color : '#272d3d',boxShadow: 0, border: 0}}
							columns={counterColumns}
							rows={[]}
							getRowId={(row) => row.id}
						/>
					</Box>
				</TabPanel>
			</TabContext>

			
			{/*Total Container and Number of orders*/}
			<Grid container justifyContent={'space-between'} className={classes.footerTotal} >
				
				{/*Order information*/}
				<Grid item>
					<Grid container justifycontent={'space-between'}>
						<Grid item>
							<img alt="" src={orderImage} className={classes.imageStyle}/>
						</Grid>
						<Grid item>
							<Grid container justifyContent={'space=between'} direction={'column'}>
								<Grid item>
									<span className={classes.innerTotalOrderHeader} >Total Orders</span>
								</Grid>
								<Grid item>
									<span className={classes.innerTotalOrderNumber} >165</span>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				
				{/*Paid Bills Amount*/}
				<Grid item>
					<Stack spacing={2}>
						<span>Total Paid bills = {numberFormat(totalSum)}</span>
					</Stack>
				</Grid>
			</Grid>
		</div>
	)
}