import { Box, Button, Grid, List, ListItem, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Switch from "@mui/material/Switch";
import HttpComponent from "../School/MakeRequest";
import ZedaccImg from '../../images/zedacc.svg'
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import axios from "axios";
import jwtDecode from "jwt-decode";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL


const zedAccountingmodal = {
    borderRadius: "10px", backgroundColor: "#fff", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "600px", height: "390px", p: 2,
}


const AccountingConfig = () => {
    const [openZedAccountingModal, setOpenZedAccountingModal] = useState(false);
    const [accountStatus, setAccountStatus] = useState()
    const [successNotificationOpen, setSuccessNotificationOpen] = useState(false);
    const [message, setMessage] = useState('')

    const handleSuccessNotificationClick = () => {
        setSuccessNotificationOpen(true);
    };

    const handleSuccessNotificationClose = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationOpen(false); };

    const [zedAccountingStatus, setZedAccountingStatus] = useState(() => {
        const savedStatus = localStorage.getItem('zedAccountingStatus');
        return savedStatus ? JSON.parse(savedStatus) : false;
    })

    const handleOpenZedAccountingModal = () => {
        setOpenZedAccountingModal(true)
    }
    const handleCloseZedAccountingModal = () => {
        if (zedAccountingStatus === true) {
            return;
        }
        setOpenZedAccountingModal(false);
        setTimeout(()=>{
            window.location.reload()
        },[1000])
    }

    const swicthAccounting = () => {
        if (accountStatus) {
            handleCloseZedAccountingModal()
        } else if (!accountStatus) {
            handleOpenZedAccountingModal()
        }

    }

    function getAccountingStatus() {
        try {
            HttpComponent({
                method: "POST",
                url: '/api/v1/check_accounting_status',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data.status === 200) {
                    setAccountStatus(data?.response?.data?.accountingEnabled)
                }
            })
        } catch (error) {
        }
    }

    useEffect(() => {
        getAccountingStatus()
    }, [])



    //enable accounting
    function handleEnableAccounting() {
        try {
            HttpComponent({
                method: "POST",
                url: '/api/v1/enable_accounting?status=true',
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then(async(data) => {
                //console.log(data, 'acc status')
               
                if (data.status === 201) {
                    setMessage('Zed Accounting enabled successfully , click zedAccounting icon to proceed')
                    handleSuccessNotificationClick();
                    setZedAccountingStatus(data.response.data.accountingEnabled)
                    localStorage.setItem('zedAccountingStatus', true);
                }
            }).then(async () => {
                const newT = await HttpComponent({
                    method: "POST",
                    url: `/api/get_token_after_invite`,
                    token: localStorage.getItem('X-Authorization')
                })
                const result = newT?.response?.data?.token
                const decodedT = jwtDecode(result)
                localStorage.setItem('X-Authorization', result);
                localStorage.setItem('zedAccountingId', decodedT?.zedAccountingId);
                setTimeout(() => {
                    handleCloseZedAccountingModal()
                    window.location.reload()
                }, 1000);
            })
        } catch (error) {

        }
    }

    return (
        <div>
            <Grid container item className={"container-fluid"}>
                {/*Page Title Container*/}
                <Grid container item justifyContent="flex-start" alignItems="left" style={{ width: "86%" }}>
                    <label style={{ height: "35px", margin: "24px 718px 24px 0", fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.32", letterSpacing: "normal", textAlign: "left", color: "#dc3545" }}>Configuration</label>
                </Grid>

                {/*Page Sub title and Search */}
                <Grid container item justifyContent="space-between" alignItems="left" style={{ width: "86%" }}>
                    <Grid item>
                        <div style={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: "600" }}>
                            <span style={{ color: "#6e7074" }}>Dashboard \ </span>
                            <span style={{ color: "#032541" }}>Accounting Config</span>
                        </div>
                    </Grid>

                    {/*Search input*/}
                    <Grid item>
                        <span style={{ marginRight: "10px", fontFamily: "Poppins", fontSize: "15px", fontWeight: "bold", color: "#032541" }}>Search :</span>
                        <TextField
                            size="small"
                            placeholder={"search"}
                            style={{ height: "1px" }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ width: "15px", height: "15px" }} />
                                    </InputAdornment>
                                ),
                            }}
                        ></TextField>
                    </Grid>
                </Grid>

                {/*Headers*/}
                <Grid container item justifyContent={"space-between"} alignContent={"center"} style={{ width: "86%", marginTop: "30px" }}>
                    <Grid item style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: "600", color: "#032541" }}>
                        Configuration
                    </Grid>
                    <Grid item style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: "600", color: "#032541" }}>
                        Action
                    </Grid>
                </Grid>
                <Grid container style={{ width: "86%", marginTop: "30px" }}>
                    <Grid item style={{ width: "100%" }}>
                        <List>
                            <ListItem style={{ marginBottom: "10px", fontFamily: "Poppins", fontSize: "14px", borderRadius: "6px", border: "solid 1px rgba(193,193,193, .3)" }}>
                                <Box style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                                    <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable Accounting</Box>
                                    <Box>
                                        <Switch checked={accountStatus} onClick={swicthAccounting} />
                                    </Box>
                                </Box>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Grid>
            <Modal
                open={openZedAccountingModal} onClose={handleCloseZedAccountingModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                <Box sx={zedAccountingmodal}>
                    <form>
                        <Box style={{ display: "flex", justifyContent: "" }}>
                            <Box>
                                <img src={ZedaccImg} style={{ marginTop: "40px" }} alt="EnableBigIcon" />
                            </Box>
                            <Box style={{ marginLeft: "20px" }}>
                                <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600", }}>Enable Accounting </Box>
                                <Box>
                                    <p style={{ fontSize: "14px", color: "#707070" }}>
                                        Enabling accounting  will allow the business to:
                                    </p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>

                                    <Box>
                                        <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}> Manage financial transactions</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>

                                    <Box>
                                        <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage all accounting transactions.</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>

                                    <Box>
                                        <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Prepare budget forecasts.</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>
                                    <Box>
                                        <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage balance sheets and profit/loss statements..</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>
                                    <Box>
                                        <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Publish financial statements in time.</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>
                                    <Box>
                                        <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Reconcile accounts payable and receivable..</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "15px", marginBottom: "20px", paddingBottom: "10px" }}>

                                    <Box>
                                        <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={
                                            handleCloseZedAccountingModal

                                        }>Cancel</Button>
                                    </Box>
                                    <Box>
                                        <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff", marginLeft: "40px" }}
                                            onClick={handleEnableAccounting}>Enable</Button>
                                    </Box>

                                </Box>
                            </Box>
                        </Box>
                    </form>
                </Box>
            </Modal>
            <SuccessAlert message={message} open={successNotificationOpen} onClose={handleSuccessNotificationClose} horizontal={'right'} vertical={'top'} />
        </div>
    );
};

export { AccountingConfig };
