import Dash from "../../../common/dash";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton, Table, TableCell, TableHead, TableRow } from "@mui/material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import cardIcon from "../../../common/images/card-icn.svg";
import creditIcon from "../../../common/images/creditbal-icn.svg";
import mpesaIcon from "../../../common/images/mpesa-icn.svg";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { useNavigate } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import user from "../../../common/images/user-icon.png";
import bell from "../../../common/images/bell-icon.png";
import logout from "../../../common/images/logout-icon.png";
import logoutuser from "../../logout/logout";
import { format } from "date-fns";
import { ReceivedPayment } from "../../Modals/Invoice/receivedPayment";
import { sumBy } from "lodash";
import { ErrorAlertRightAligned, SuccessAlertRightAligned } from "../../bills/startorderModals/modals";
import { Add } from "@mui/icons-material";
import { AddCircle, Delete } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { removeInvoiceItems } from "../../../features/startorderSlice";
const baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
  address_text_common: {
    fontSize: "13px",
    fontWeight: "300",
  },
  table_titles: {
    fontSize: "14px",
    fontWeight: "700",
  },
  table_body: {
    fontSize: "13px",
    fontWeight: "300",
    padding: theme.spacing(0.25),
  },
  footer_text: {
    fontSize: "10px",
    fontWeight: "300",
    padding: theme.spacing(0.25),
  },
  totals: {
    fontSize: "14px",
    padding: theme.spacing(0.25),
  },
  p1: {
    padding: theme.spacing(0.25),
  },
  p2: {
    padding: theme.spacing(0.5),
  },
  p3: {
    padding: theme.spacing(1),
  },
  mt4: {
    marginTop: theme.spacing(4),
  },
  m1: {
    margin: theme.spacing(1),
  },
  textLeft: {
    textAlign: "left",
  },
  textCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
}));

const InvoicePreview = (props) => {

  const setInvoiceItemsShow = props.setInvoiceItemsShow;
  const setPreviewInvoiceItemsShow = props.setPreviewInvoiceItemsShow
  const invoiceArrayProduct = props?.invoiceArrayProduct;
  const customerInfo = props?.customerInfo;
  const newCustomerId = props?.newCustomerId;
  const setAddItems = props?.setAddItems
  const dispatch = useDispatch();
  



  const [newInvoiceArrays, setInvoiceItemsArray] = useState(invoiceArrayProduct)

  const invoiceTotals = sumBy(newInvoiceArrays, (invoiceArray) => {
    return invoiceArray.totalAmount;
  });

  const [newInvoiceArray, setNewInvoiceArray] = useState([])
  // save customer;
  useEffect(() => {

    const InvoiceArray = newInvoiceArrays?.map((prod) => {
      return      {
        productId: prod.checkedProductId,
        amount: prod.totalAmount/prod.itemCount,
        quantity: prod.itemCount
      }
    })

    setNewInvoiceArray(InvoiceArray)
 
  }, [invoiceArrayProduct])

  const classes = useStyles();
  const navigate = useNavigate();
  const date = new Date();
  const formattedDate = format(date, "EEEE, MMMM dd, yyyy");
  const [businessName, setBusinessName] = useState(localStorage.getItem("businessName"));
  const [invoiceData, setInvoiceData] = useState(JSON.parse(localStorage.getItem("invoice_data")));
  const [businessInfo, setBusinessInfo] = useState();
  const [responseData, setResponseData] = useState();
  const [receivePaymentOpen, setReceivePaymentOpen] = useState(false);

  const downloadPDF = () => {
    const input = document.getElementById("downloadable");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0);
      const inputWidthPx = input.clientWidth;
      const inputHeightPx = input.clientHeight;
      const dpi = window.devicePixelRatio * 96;
      let scaleFactor = 1;
      if (window.innerWidth > 1512) {
        scaleFactor = 1.4;
      }
      const inputWidthMm = ((inputWidthPx / dpi) * 25.4) / scaleFactor;
      const inputHeightMm = (inputHeightPx / dpi) * 25.4;
      const pdf = new jsPDF("p", "mm", [inputWidthMm, inputHeightMm]);
      pdf.addImage(imgData, "JPEG", 5, 5, inputWidthMm - 10, inputHeightMm - 10);
      pdf.save(`invoice.pdf`);
    });
  };
  const back = () => {
    navigate(-1);
  };

  const getBusinessDetails = async () => {
    try {
      const businessDetailsResponse = await fetch(baseUrl + "/api/get_business_info", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      if (businessDetailsResponse.status === 200) {
        const businessDetailsData = await businessDetailsResponse.json();
        console.log("business data ", businessDetailsData.data);
        setBusinessInfo(businessDetailsData.data);
      }
    } catch (error) {
      console.error("error fetching customer details", error.message);
    }
  };

  const [sucess, setSucess] = useState({ state: false, message: "" });
  const [error, setError] = useState({ state: false, message: "" });
  console.log("url", props.customerId, newCustomerId, props.invoiceType, props.frequency, newInvoiceArray);

  const saveInvoice = async () => {


    let url = baseUrl + "/api/generateInvoice";
    console.log("url", props.customerId, newCustomerId, props.invoiceType, props.frequency, newInvoiceArray);
    const response = await fetch(url, {
      
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
      body: JSON.stringify({
        customerId: newCustomerId ? newCustomerId : props.customerId,
        type:props.invoiceType,
        frequency:props.frequency,
        products:newInvoiceArray
      }),
    });
    const responseData = await response.json();
    if(response.status === 200){
      
      setSucess({ state: true, message: "Invoice has been craeted and sent to customers email!" });
       setTimeout(() => {
        setSucess({ state: false });
        navigate(`/customerDetails/${newCustomerId ? newCustomerId : props.customerId }`);
      }, 2000);
    }else if(responseData.status  === "FAILED"){
      console.log("error", responseData.message);
      setError({ state: true, message: responseData.message });
      setTimeout(() => {
        setError({ state: false });
      }, 3000);

      
    }
    console.log("save invoice response data", responseData);
    setResponseData(responseData);
    console.log("response test", responseData.data);
   
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "KES",
    }).format(value);

  useEffect(() => {
    getBusinessDetails();
  }, []);


  // remove items from array;
  const removeItems = (product) => {

    setInvoiceItemsArray(newInvoiceArrays?.filter((prod) => prod.checkedProductId !== product.checkedProductId))  
  }

  return (
    <>
      <div style={{ width: "80%", marginLeft: "10%" }}>
        <div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ paddingTop: 15, display: "flex" }}>
                <span>
                  <IconButton aria-label="back" onClick={() => {
                    setAddItems(newInvoiceArrays)
                    setPreviewInvoiceItemsShow(false)
                  } }>
                    <KeyboardBackspaceOutlinedIcon sx={{ color: "#dc3545", fontSize: "25px" }} />
                  </IconButton>
                </span>
                <span style={{ fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", textAlign: "left", color: "#dc3545" }}>Preview Invoice</span>
              </div>
              <div
                style={{
                  padding: 10,
                  display: "flex",
                }}
              >
                {responseData && responseData.status === "SUCCESS" ? (
                  <>
                    <Button style={{ width: "180px", height: "45px", borderRadius: "5px", backgroundColor: "transparent", color: "#032541", border: "solid 1px #032541", marginRight: 2 }} onClick={() => setReceivePaymentOpen(true)}>
                      Received Payment
                    </Button>
                    <Button style={{ width: "180px", height: "45px", borderRadius: "5px", backgroundColor: "#032541", color: "white" }}>Resend Invoice</Button>
                  </>
                ) : (
                  <Button style={{ width: "180px", height: "45px", borderRadius: "5px", backgroundColor: "#032541", color: "white" }} onClick={() => {
                    saveInvoice()
                    dispatch(removeInvoiceItems())
                  } }>
                    Send Invoice
                  </Button>
                )}
                <span
                  style={{
                    backgroundColor: "rgba(3, 37, 65, 0.05)",
                    width: "45px",
                    height: "45px",
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: 5,
                    borderRadius: 5,
                  }}
                >
                  <IconButton aria-label="download" onClick={downloadPDF}>
                    <FileDownloadOutlinedIcon sx={{ color: "#032541", fontSize: "25px" }} />
                  </IconButton>
                </span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                overflow: "visible",
                height: "auto",
              }}
              id="downloadable"
            >
              <div
                className={classes.p3}
                style={{
                  backgroundColor: "rgba(220,53,69,0.05)",
                  height: "150px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className={classes.p1}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      color: "black",
                      fontSize: "40px",
                      fontWeight: "700",
                    }}
                  >
                    INVOICE
                  </span>
                  <span
                    style={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    Date:
                  </span>
                  <span style={{ fontSize: "14px" }}>{formattedDate}</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      color: "#dc3545",
                      fontSize: "40px",
                      fontWeight: "700",
                    }}
                  >
                    Unpaid
                  </span>
                  <span
                    style={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    Invoice No:
                  </span>
                  {responseData && responseData.status === "SUCCESS" && <span style={{ fontSize: "14px" }}>{responseData.data.invoiceNumber}</span>}
                </div>
              </div>

              <div
                className={classes.p3}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    From:
                  </span>
                  <span className={classes.address_text_common} style={{ fontWeight: "700" }}>
                    {businessName}
                  </span>
                  {/*                  <span className={classes.address_text_common}>*/}
                  {/*  Branch: Parklands*/}
                  {/*</span>*/}
                  <span className={classes.address_text_common}>
                    {businessInfo?.businessOwnerAddress}, {businessInfo?.country}
                  </span>
                  <span className={classes.address_text_common}>{businessInfo?.businessOwnerEmail}</span>
                  <span className={classes.address_text_common}>{businessInfo?.businessOwnerPhone}</span>
                </div>
                <div
                  className={classes.mt4}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                  }}
                >
                  <span className={classes.address_text_common} style={{ fontWeight: "700" }}>
                    Billed to:
                  </span>
                  <span className={classes?.address_text_common}>{customerInfo?.customerName}</span>
                  <span className={classes?.address_text_common}>{customerInfo?.customerEmail}</span>
                  <span className={classes?.address_text_common}>{customerInfo?.customerPhone}</span>
                </div>
              </div>

              <div className={classes.m1} style={{ border: "dotted 1px" }}></div>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.textLeft}>Description</TableCell>
                    <TableCell className={classes.textCenter}>Price (KES)</TableCell>
                    <TableCell className={classes.textCenter}>Qty</TableCell>
                    <TableCell className={classes.textRight}>Total (KES)</TableCell>
                    <TableCell className={classes.textRight}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                {newInvoiceArrays?.map((product, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.textLeft}>
                      <span className={classes.table_body}>{product.itemName}</span>
                    </TableCell>
                    <TableCell className={classes.textCenter}>
                      <span className={classes.table_body}>{numberFormat(product.itemAmount)}</span>
                    </TableCell>
                    <TableCell className={classes.textCenter}>
                      <span className={classes.table_body}>{product.itemCount}</span>
                    </TableCell>
                    <TableCell className={classes.textRight}>
                      <span className={classes.table_body}>{numberFormat(product.totalAmount)}</span>
                    </TableCell>
                    <TableCell className={classes.textRight}>
                      <span className={classes.table_body}><Delete style={{color:"red", cursor:"pointer"}} onClick={() => removeItems(product)} /></span>
                    </TableCell>
                  </TableRow>
                ))}
              </Table>

              <div style={{display:'flex', alignItems:"center", justifyContent:"center", margin:"10px 0px 0px 0px"}} >
                  <span style={{cursor:'pointer'}} onClick={() => {
                    setAddItems(newInvoiceArrays)
                    setPreviewInvoiceItemsShow(false)
                  }} > <AddCircle/> Add products/Service</span>
                </div>

              <div
                className={classes.p3}
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <div
                  className={classes.p1}
                  style={{
                    backgroundColor: "#f7f7f7",
                    width: "288px",
                    height: "130px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>Subtotal</span>
                    <span>{numberFormat(invoiceTotals)}</span>
                  </div>
                  <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>Discount</span>
                    <span>KES 0.00</span>
                  </div>
                  <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                    <span style={{ color: "#dc3545", fontWeight: "bold" }}>Total</span>
                    <span
                      style={{
                        fontSize: "16px",
                        color: "#dc3545",
                        fontWeight: "bold",
                      }}
                    >
                      {numberFormat(invoiceTotals)}
                    </span>
                  </div>
                </div>

                
              </div>
{/* 
              <div style={{display:'flex', alignItems:"center", justifyContent:"center"}} >
                  <span style={{cursor:'pointer'}} onClick={() => setPreviewInvoiceItemsShow(false)} > <AddCircle/> Add products/Service</span>
                </div> */}

        

              <div style={{ border: "dotted 1px" }}></div>

              <div className={classes.p3} style={{ display: "flex", flexDirection: "column" }}>
                <div className={classes.p1}>
                  <span
                    style={{
                      color: "#032541",
                      fontWeight: "700",
                      fontSize: "16px",
                    }}
                  >
                    Accepted Payment Methods
                  </span>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <img className={classes.p2} src={mpesaIcon} alt="M-pesa" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "12px",
                      }}
                    >
                      <span>M-Pesa</span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <img className={classes.p2} src={cardIcon} alt="Visa" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "12px",
                      }}
                    >
                      <span>Card</span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <img className={classes.p2} src={creditIcon} alt="Credit" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "12px",
                      }}
                    >
                      <span>Credit Balance</span>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ border: "dotted 1px" }}></div>

              <div className={classes.p2} style={{ display: "flex", justifyContent: "center" }}>
                <div className={classes.textCenter} style={{ fontSize: "10px", fontWeight: "300" }}>
                  This is a system-generated invoice is created without any alteration whatsoever.
                  <br />
                  Thank you for your business.
                </div>
              </div>
              <div
                className={classes.p2}
                style={{
                  backgroundColor: "#f7f7f7",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span className={classes.footer_text}>
                  Powered by{" "}
                  <span
                    style={{
                      fontWeight: "700",
                    }}
                  >
                    ZED Payments Limited
                  </span>
                </span>
                <span className={classes.footer_text}>.</span>
                <span className={classes.footer_text}>info@zed.business</span>
                <span className={classes.footer_text}>.</span>
                <span className={classes.footer_text}>v1.0.2</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Received payment*/}

      {sucess && <SuccessAlertRightAligned message={sucess.message} sucess={sucess.state} />}
      {error && <ErrorAlertRightAligned message={error.message} error={error.state} />}

      <ReceivedPayment paymentOpen={receivePaymentOpen} onClose={() => setReceivePaymentOpen(false)} />
    </>
  );
};

export default InvoicePreview;
