import {CheckCircle, Circle, Close} from "@mui/icons-material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Box, Button, Checkbox, Grid, Input, Modal, Stack, styled, Tab, TextareaAutosize} from "@mui/material";
import TextField from "@mui/material/TextField";
import {DataGrid} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import PhoneInput from "react-phone-input-2";
import addIcon from "../../../../common/images/addicon.svg";
import emptyState from "../../../../common/images/emptystate.svg";
import FormControlLabel from '@mui/material/FormControlLabel';
import {SuccessBatch} from "../../../products/stockComponents/stockModals/successBatchSubmition";

const baseUrl = process.env.REACT_APP_BASE_URL;

const modalStyle = {backgroundColor: "#fff", borderRadius: "15px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "50%", height: "auto", fontFamily: 'Poppins'}
const headerModalStyle = {fontFamily : 'Poppins',width: "100%", height: "80px", borderRadius: "15px 15px 0 0", backgroundColor: "#dc3545", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "20px",}

// Custom Tab Styling
const AntTabs = styled(TabList)({borderBottom: "3px solid #e8e8e8", "& .MuiTabs-indicator": {backgroundColor: "#dc3545",},});
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({textTransform: "none", minWidth: 0, [theme.breakpoints.up("sm")]:
			{minWidth: 0,}, fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1), fontSize: "13px", fontStretch: "normal",
		fontStyle: "normal", lineHeight: "2.75", letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","), "&:hover":
			{color: "#032541", opacity: 1,}, "&.Mui-selected": {color: "#dc3545", fontWeight: 600,}, "&.Mui-focusVisible": {backgroundColor: "blue",},}));

const numberFormat = (value) => new Intl.NumberFormat("en-US", {style: "currency", currency: "KES",}).format(value);

export default function AddTenant(props) {
	
	const open = props.open
	const handleClose = props.onClose
	
	const [tabvalue, setTabValue] = useState("PersonalDetails");
	
	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};
	const [customerDetails, SetCustomerDetails] = useState({customerAddress : "", customerType: "", customerFirstName: "", customerLastName: "", companyName: "", companyAdress: "", companyCode: "", customerCode: "", companyPhoneNo: "", customerPhoneNo: "", customerEmail: "", companyEmail: "", paymentType: "Prepaid", creditPaymentInstruments: "", prepaidPaymentInstruments: "", limit: "", amountRecieved: "",});
	const [userPhone, setPhone] = useState("");
	
	//Selected Items from CheckBox
	let [util, setUtil] = useState([])
	const [selectedItems, setSelectedItems] = useState([]);
	const handleCheckboxChange = (event, item) => {
		if (event.target.checked) {
			setSelectedItems([...selectedItems, item]);
			const object = {"amount" : item.productPrice, "name" : item.productName, "productId" : item._id}
			setUtil([...util, object])
		} else {
			setSelectedItems(selectedItems.filter(selectedItem => selectedItem !== item));
			setUtil(util.filter((service) => service.productId !== item._id))
		}
	};
	
	//billable
	const [billingFrequency, setBillingFrequency] = useState('Monthly')
	const [unitNumber, setUnitNumber] = useState('')
	const [billAmount, setBillAmount] = useState(0)
	const [unitSize, setUnitSize] = useState('')
	const [billProductId, setBillProductId] = useState('')
	
	//Product Data
	const [product, setProduct] = useState([])
	
	//Handle unitAmount
	const handleUnitAmount = (value) => {
		const x = product.find(prd => prd.productName === value)
		setBillAmount(x.productPrice)
		setBillProductId(x._id)
	}
	
	const secondName = customerDetails.customerFirstName.split(' ')[1]
	
	const addTenant = async() => {
		try {
			const fName = customerDetails.customerFirstName?.split(' ')[0]
			const sName = customerDetails.customerFirstName?.split(' ')[1]
			
			const response = await fetch(baseUrl + "/api/addCustomer", {
				method: "POST",
				headers: {"Content-Type": "application/json", Accept: "application/json", "X-Authorization": localStorage.getItem("X-Authorization")},
				body : JSON.stringify({
					billableItems : [{itemNumber: unitNumber, amount:billAmount, billingFrequency: billingFrequency, facilitySize: unitSize, productId: billProductId, extraServices : util}],
					businessId: localStorage.getItem("businessId"),
					customerType: customerDetails.customerType,
					customerAddress: customerDetails.customerAddress,
					email: customerDetails.customerEmail,
					firstName: fName,
					lastName: sName,
					phone: '+' + userPhone,
					paymentType: customerDetails.paymentType,
					limit: customerDetails.paymentType === "Credit" ? customerDetails.limit : "  ",
					amountReceived: customerDetails.paymentType === "Prepaid" ? customerDetails.amountRecieved : "  ",
					limitInstrument: customerDetails.paymentType === "Prepaid" ? customerDetails.prepaidPaymentInstruments : customerDetails.creditPaymentInstruments,
				})
			})
				await response.json()
					.then((data) => {if (response.status === 201) {console.log(`Success in Adding Tenant`, data); handleSuccessOpen()}})
					.catch((e) => {console.log(`Error in Adding Tenant`, e.message)})
		} catch (e) {console.log(`Error Adding Tenant`)}}
	
	const fetchUrl = baseUrl + "/api/listProducts";
	
	//Fetch products
	const fetchProducts = async () => {
		const response = await fetch(fetchUrl + `/Active/?page=1&limit=100&search=`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					"X-Authorization": localStorage.getItem("X-Authorization")}
			})
			await response.json()
				.then((response) => {setProduct(response.data); console.log(`Product Data`, response.data)})
				.catch((e) => {console.log(`Error Getting Products`, e.message)})
	}
	//Global Setter
	const getCustomerPersonalDetailsInputValue = (e) => {
		const value = e.target.value;
		const name = e.target.name;
		
		SetCustomerDetails({ ...customerDetails, [name]: value });
	};
	
	useEffect(() => {
		fetchProducts()
			.then(() => {console.log('Getting Products')})
			.catch((e) => {console.log(`Error Getting Products`, e.message)})
	}, [])
	
	//Success Modal
	const [successOpen, setSuccessOpen] = useState(false)
	const handleSuccessOpen = () => setSuccessOpen(true)
	const handleSuccessClose = () => {setSuccessOpen(false); handleClose(); window.location.reload()}
	
	console.log('User Phone .', userPhone)
	
	return (
		<div style={{fontFamily : 'Poppins'}}>
			<Modal open={open} onClose={handleClose}>
				<Box style={modalStyle}>
				<Box style={headerModalStyle}>
		              <span style={{color: "white", fontFamily: "Poppins"}}>
		                Add New Tenant
		              </span>
					<span style={{color: "white"}}>
                        <Close onClick={handleClose} style={{cursor: "pointer"}}/>
                    </span>
				</Box>
				
				{/* TAB CONTROLS */}
				<Grid container>
					<Grid item style={{width: "100%"}}>
						<Box sx={{"& .super-app-theme--header": {color: "#032541", fontWeight: "bold", fontSize: "10px", fontFamily: "Poppins", fontStretch: "normal", fontStyle: "normal",},}}>
							<TabContext value={tabvalue}>
								<Box style={{width: "100%"}}>
									<AntTabs onChange={handleTabChange} variant="fullWidth" scrollButtons={false} aria-span="scrollable prevent tabs example">
										<AntTab icon={tabvalue === "PersonalDetails" ? (<CheckCircle style={{color: "#447c69"}}/>) : (<Circle style={{color: "#b4bcc4"}}/>)} iconPosition="start" style={{fontFamily: "Poppins", fontSize: "20px", fontWeight: "600", color: "#032541",}} label="Personal Details" value="PersonalDetails" centered/>
										<AntTab icon={tabvalue === "HouseDetails" ? (<CheckCircle style={{color: "#447c69"}}/>) : (<Circle style={{color: "#b4bcc4"}}/>)} iconPosition="start" style={{fontFamily: "Poppins", fontSize: "20px", fontWeight: "600", color: "#032541",}} label="House Details" value="HouseDetails" centered/>
										<AntTab icon={tabvalue !== "Charges" ? (<Circle style={{color: "#b4bcc4"}}/>) : (<CheckCircle style={{color: "#447c69"}}/>)} iconPosition="start" style={{fontFamily: "Poppins", fontSize: "20px", fontWeight: "600", color: "#032541",}} label="Charges" value="Charges" centered/>
									</AntTabs>
								</Box>
								
								{/* PERSONAL DETAILS PANEL */}
								<TabPanel value="PersonalDetails">
									<Grid container direction={'row'} justifyContent={'space-between'} spacing={2}>
										
										{/*Name, address, phone*/}
										<Grid item xs={6}>
											<Grid container>
												
												{/*Name*/}
												<Grid item style={{width : '100%'}}>
													<Grid item>
														<span style={{color : "#032541", fontSize : '15px'}} >Name</span>
													</Grid>
													<Grid item mt={0.8}>
														<input placeholder='Enter name here..' style={{fontSize : '13px', padding: "7px", borderRadius : '12px', height : '40px', border: "2px solid #f1f1f1", outline: "none", width : '100%'}} name='customerFirstName' onChange={getCustomerPersonalDetailsInputValue} value={customerDetails.customerFirstName} />
													</Grid>
												</Grid>
												
												{/*Address*/}
												<Grid item mt={2} style={{width : '100%'}}>
													<Grid item>
														<span style={{color : "#032541", fontSize : '15px'}} >Physical Address</span>
													</Grid>
													<Grid item mt={0.8}>
														<input placeholder='Enter Address here..' style={{fontSize : '13px', padding: "7px", borderRadius : '12px', height : '40px', border: "2px solid #f1f1f1", outline: "none", width : '100%'}} name={'customerAddress'} onChange={getCustomerPersonalDetailsInputValue} value={customerDetails.customerAddress} />
													</Grid>
												</Grid>
												
												{/*Phone*/}
												<Grid item mt={2} style={{width : '100%'}}>
													<Grid item>
														<span style={{color : "#032541", fontSize : '15px'}} >Phone</span>
													</Grid>
													<Grid item mt={0.8}>
														<PhoneInput required country={"ke"} enableSearch={true} value={userPhone} onChange={(phone) => setPhone(phone)}/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										
										{/*email customer type*/}
										<Grid item xs={6}>
											<Grid container>
												
												{/*Email*/}
												<Grid item style={{width : '100%'}}>
													<Grid item>
														<span style={{color : "#032541", fontSize : '15px'}} >Email</span>
													</Grid>
													<Grid item mt={0.8}>
														<input type='email' placeholder='Enter name here..' style={{fontSize : '13px', padding: "7px", borderRadius : '12px', height : '40px', border: "2px solid #f1f1f1", outline: "none", width : '100%'}} onChange={getCustomerPersonalDetailsInputValue} name={'customerEmail'} value={customerDetails.customerEmail} />
													</Grid>
												</Grid>
												
												{/*Customer type*/}
												<Grid item mt={2} style={{width : '100%'}}>
													<Grid item>
														<span style={{color : "#032541", fontSize : '15px'}} >Customer Type</span>
													</Grid>
													<Grid item mt={0.8}>
														<select style={{background : '#fff', fontSize : '13px', padding: "7px", borderRadius : '12px', height : '40px', border: "2px solid #f1f1f1", outline: "none", width : '100%'}} onChange={getCustomerPersonalDetailsInputValue} name={'customerType'} value={customerDetails.customerType}>
															<option>Select customer Type</option>
															<option value="Company">Company</option>
															<option value="Individual">Individual</option>
														</select>
													</Grid>
												</Grid>
												
											</Grid>
										</Grid>
									</Grid>
									
									{/*Buttons CANCEL NEXT*/}
									<Grid container direction={'row'} justifyContent={'center'} alignContent={'center'} mt={1} spacing={5}>
										<Grid item>
											<Button onClick={handleClose} style={{fontSize : '16px', fontWeight : '600', borderRadius : '6px' ,width:"122px",height:"42.6px",border:"solid 1px #dc3545", color : '#dc3545'}} >
												Cancel
											</Button>
										</Grid>
										<Grid item>
											<Button onClick={() => setTabValue("HouseDetails")} style={{fontSize : '16px', fontWeight : '600', borderRadius : '6px' ,width:"122px",height:"42.6px",color : '#fff', background : "#032541"}} >
												Next
											</Button>
										</Grid>
									</Grid>
								</TabPanel>
								
								{/*HOUSE DETAILS*/}
								<TabPanel value={"HouseDetails"}>
									<Grid container direction={'row'} justifyContent={'space-between'} spacing={2}>
										
										{/*Service Type, House number*/}
										<Grid item xs={6}>
											<Grid container>
												
												{/*Service Type*/}
												<Grid item style={{width : '100%'}}>
													<Grid item>
														<span style={{color : "#032541", fontSize : '15px'}} >Service Type</span>
													</Grid>
													<Grid item mt={0.8}>
														<select style={{background : '#fff', fontSize : '13px', padding: "7px", borderRadius : '12px', height : '40px', border: "2px solid #f1f1f1", outline: "none", width : '100%'}} onChange={(e) => {setUnitSize(e.target.value); handleUnitAmount(e.target.value)}} value={unitSize}>
															<option>Select Service Type</option>
															{
																product.map((data, index) => (
																	<option key={index} value={data.productName} style={{fontSize : '12px', color : '#032541'}} >{data.productName}</option>
																))
															}
														</select>
													</Grid>
												</Grid>
												
												{/*House Number*/}
												<Grid item mt={2} style={{width : '100%'}}>
													<Grid item>
														<span style={{color : "#032541", fontSize : '15px'}} >House Number</span>
													</Grid>
													<Grid item mt={0.8}>
														<input placeholder='Enter House Number..' style={{fontSize : '13px', padding: "7px", borderRadius : '12px', height : '40px', border: "2px solid #f1f1f1", outline: "none", width : '100%'}} value={unitNumber} onChange={(e) => setUnitNumber(e.target.value)}/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										
										{/*Billing Information*/}
										<Grid item xs={6}>
											<Grid container>
												
												{/*Billing  Period*/}
												<Grid item style={{width : '100%'}}>
													<Grid item>
														<span style={{color : "#032541", fontSize : '15px'}} >Billing Period</span>
													</Grid>
													<Grid item mt={0.8}>
														<select style={{background : '#fff', fontSize : '13px', padding: "7px", borderRadius : '12px', height : '40px', border: "2px solid #f1f1f1", outline: "none", width : '100%'}} onChange={(e) => {setBillingFrequency(e.target.value)}} value={billingFrequency}>
															<option>Billing Frequency</option>
															<option value="Monthly">Monthly</option>
															<option value="Quarterly">Quarterly</option>
															<option value="Half">Half</option>
															<option value="Yearly">Yearly</option>
														</select>
													</Grid>
												</Grid>
												
											</Grid>
										</Grid>
									</Grid>
									
									{/*Buttons CANCEL NEXT*/}
									<Grid container direction={'row'} justifyContent={'center'} alignContent={'center'} mt={1} spacing={5}>
										<Grid item>
											<Button onClick={handleClose} style={{fontSize : '16px', fontWeight : '600', borderRadius : '6px' ,width:"122px",height:"42.6px",border:"solid 1px #dc3545", color : '#dc3545'}} >
												Cancel
											</Button>
										</Grid>
										<Grid item>
											<Button onClick={() => setTabValue("Charges")} style={{fontSize : '16px', fontWeight : '600', borderRadius : '6px' ,width:"122px",height:"42.6px",color : '#fff', background : "#032541"}} >
												Next
											</Button>
										</Grid>
									</Grid>
								</TabPanel>
								
								{/* CHARGES INFORMATION PANELS; */}
								<TabPanel value="Charges">
									
									<Grid container direction={'row'} justifyContent={'space-between'} spacing={2}>
										
										{/*Rent Deposit, service Charge, electricity*/}
										<Grid item>
											<Grid container direction={'row'} justifyContent={'space-between'} spacing={1}>
												{
													product.map((data, index) => (
														<>
															{/*Rent Deposit*/}
															<Grid key={index} item>
																<Grid container direction={'column'} justifyContent={'space-between'}>
																	
																	{/*Checkbox and Label*/}
																	<Grid item>
																		<FormControlLabel style={{color : '#6e7074', fontSize : '14px'}}  label={data.productName} control={
																			<Checkbox style={{color : '#dc3545'}} edge="start" checked={selectedItems.includes(data)} onChange={event => handleCheckboxChange(event, data)} />} />
																	</Grid>
																	
																	{/*Input Box*/}
																	<Grid item>
																		<TextField readOnly size="small" sx={{ '& .MuiTextField-root': { m: 1, height : "35px", width : '212px'}, fontSize : '12px'}} disabled id="outlined-required" label="Amount" value={numberFormat(data.productPrice)} defaultValue={numberFormat(data.productPrice)}/>
																	</Grid>
																</Grid>
															</Grid>
														</>
													))
												}
											</Grid>
										</Grid>
										
									</Grid>
									
									{/*Buttons CANCEL NEXT*/}
									<Grid container direction={'row'} justifyContent={'center'} alignContent={'center'} mt={1} spacing={5}>
										<Grid item>
											<Button onClick={handleClose} style={{fontSize : '16px', fontWeight : '600', borderRadius : '6px' ,width:"122px",height:"42.6px",border:"solid 1px #dc3545", color : '#dc3545'}} >
												Cancel
											</Button>
										</Grid>
										<Grid item>
											<Button onClick={addTenant} style={{fontSize : '16px', fontWeight : '600', borderRadius : '6px' ,width:"122px",height:"42.6px",color : '#fff', background : "#032541"}} >
												Add
											</Button>
										</Grid>
									</Grid>
								
								</TabPanel>
							</TabContext>
						</Box>
					</Grid>
				</Grid>
			</Box>
			</Modal>
			
			{/*Success Adding Tenant*/}
			<SuccessBatch submitModal={successOpen} handleClose={handleSuccessClose} message={'Tenant Added Successfully!'}/>
		
		</div>
	)
}
