import { Grid, TextField, Button, Breadcrumbs } from "@mui/material";
import React, { useState, useEffect } from "react";
import { warehousestyles } from "../styles";
import PhoneInput from "react-phone-input-2";
import HttpComponent from "../../School/MakeRequest";
import { SuccessAlertRightAligned, ErrorAlertRightAligned } from "../../bills/startorderModals/modals";
import { NavigateNext, ArrowBackIos } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router";
import SupplierTypes from "./supplierTypes";
import AddInZedSuppliers from "./addInZedSuppliers";
import GeoLocation from "../../../hooks/useLocation";
import AutocompleteComponent from "../../../common/autoComplete";

export const Addsupplier = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const queryPayams = decodeURIComponent(window.location.search);
  
  const supplierId = queryPayams.slice(1)


  const [supplier, setSupplier] = useState({ username: "", email: "", location: "", bankAccount: "" });
  const [customerPhone, setCustomerPhone] = useState("");
  const [supplierBsNo, setSupplierBsNo] = useState("")
  const [inZed, setInZed] = useState("notInZed")
   const [allInputsFilled, setInputField] = useState(false);
  const [success, setSucess] = useState({ state: false, message: "" });
  const [error, setError] = useState({ state: false, message: "" });
  const [selectedValue ,setSelectedValue] = useState('')
  const [bankValue, setInputValue] = useState("");
  const [selectedBank, setSelectValue] = useState("")
  const [banks , setBanks] = useState([])

  const getSupplierDetails = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setSupplier({ ...supplier, [name]: value });
  };


  useEffect(() => {
    if (supplier.username && supplier.email && selectedValue && customerPhone) {
      setInputField(true);
    }
  }, [supplier.username, supplier.email, selectedValue, customerPhone]);



  const createSupplier = () => {

    
    HttpComponent({
      method: "POST",
      url: `/api/v1/supplier/new`,
      body: inZed === "notInZed" ?  {
        name: supplier.username,
        phone: customerPhone[3]?.startsWith(0) ? "+254" + customerPhone?.substring(4) : `${customerPhone}`,
        email: supplier.email,
        location: selectedValue?.description,
        // selectedBank: selectedBank
        // accountNumber:supplier?.bankAccount
      }: {name: supplier.username, phone: customerPhone[3]?.startsWith(0) ? "+254" + customerPhone?.substring(4) : `${customerPhone}`,  email: supplier.email, location: supplier.location, supplierBusinessNo:supplierBsNo, supplierType:inZed, paymentTerms: inZed === "inZed" ? " " : ""},
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 201) {
          setSupplier({ username: "", email: "", location: "" });
          setCustomerPhone("");
          setSucess({ state: true, message: "Supplier has been added sucessfully!" });
          navigate(`/suppliers`);
        }

        if (data.status === 400) {
          setError({ state: true, message: data.response.error || data.response.message });
        }
        setTimeout(() => {
          setSucess({ state: false });
          setError({ state: false });
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // get single substore details;

  const getSupplier = () => {
    // console.log('end point is running');
    HttpComponent({
      method: "GET",
      url: `/api/get_store_details?storeType=suppliers&storeId=${supplierId}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 200) {
          const { name, phone, email, location } = data.response.data[0].suppliers;

          setSupplier({ username: name, email: email});
          setCustomerPhone(phone);
          setSelectedValue(location)
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    if (supplierId) {
      getSupplier();
    }
  }, [supplierId]);

  const editSupplier = () => {
    HttpComponent({
      method: "POST",
      url: `/api/v1/supplier/edit/${supplierId}`,
      body: { phone: customerPhone, name: supplier.username, location: selectedValue?.description, email: supplier.email },
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 200) {
          setSucess({ state: true, message: "Supplier has been edited sucessfully!" });
          setTimeout(() => {
            setSucess({ state: false });
           navigate(`/suppliers`)
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div style={warehousestyles.mainDivStyling}>
      <Grid container style={warehousestyles.gridContainerMargin}>
        <Grid item>
          <span style={warehousestyles.supplierText}>
            {" "}
            <ArrowBackIos onClick={() => navigate(-1)} /> Suppliers
          </span>
        </Grid>
      </Grid>
      <Grid container style={warehousestyles.gridContainerMargin}>
        <Grid item>
          <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
            <span>Dashboard</span>
            <span onClick={() => navigate(-1)}>Suppliers</span>
            {id === "newSupplier" ? <span style={{ color: "red" }}>Add Supplier</span> : <span style={{ color: "red" }}>Edit Supplier</span>}
          </Breadcrumbs>
        </Grid>
      </Grid>

      {id === "addsupplier" && <SupplierTypes />}
      {id === "supplierInZed" && <AddInZedSuppliers setSupplier={setSupplier} setCustomerPhone={setCustomerPhone} setSupplierBsNo={setSupplierBsNo} setInZed={setInZed} />}

      {id === "newSupplier" && (
        <Grid container direction={"column"} width={"100%"}>
          <Grid item style={warehousestyles.addsupplierGridItems}>
            <TextField name="username" value={supplier.username} onChange={getSupplierDetails} style={warehousestyles.addsupplierInputs} label="Supplier Name" variant="outlined" />
          </Grid>

          <Grid>
            <PhoneInput name="phone" value={customerPhone} onChange={(phone) => setCustomerPhone(phone)} style={warehousestyles.addsupplierInputs} required country={"ke"} enableSearch={true} label={"Mobile Number"} multiline inputStyle={{ height: "55px", width: "70%", margin: "0px 0px 10px 0px" }} />
          </Grid>
          <Grid item style={warehousestyles.addsupplierGridItems}>
            <TextField name="email" value={supplier.email} onChange={getSupplierDetails} style={warehousestyles.addsupplierInputs} label="Email Adress" variant="outlined" />
          </Grid>

          <Grid item style={warehousestyles.addsupplierGridItems}>
            <GeoLocation sx={{width:"50%"}} onValueChange={(location)=>setSelectedValue(location)} selectedValue={selectedValue}/>
            {/* <TextField name="location" value={supplier.location} onChange={getSupplierDetails} style={warehousestyles.addsupplierInputs} label="Location" variant="outlined" /> */}
          </Grid>

          <Grid item style={warehousestyles.addsupplierGridItems}>
          <AutocompleteComponent label={'Search Bank'} styles={{width:"50%"}} setInputValue={setInputValue}  setSelectValue={setSelectValue} data={banks.length === 0 ? [{name:"No banks", age : 23}] : banks } />
          </Grid>

          <Grid item style={warehousestyles.addsupplierGridItems}>
            <TextField name="bankAccount" value={supplier?.bankAccount} onChange={getSupplierDetails} style={warehousestyles.addsupplierInputs} label="Bank Account Number" variant="outlined" />
          </Grid>

          <Grid item style={warehousestyles.addsupplierGridItems}>
            <div style={warehousestyles.rightAlignedButtonHolder}>
              
              {
                supplierId ?     
                <Button style={!allInputsFilled ? warehousestyles.createcustomerInactiveBtn : warehousestyles.createCustomerActiveBtn} onClick={() => (allInputsFilled ? editSupplier() : null)}>
                  update
                </Button> :  <Button style={!allInputsFilled ? warehousestyles.createcustomerInactiveBtn : warehousestyles.createCustomerActiveBtn} onClick={() => (allInputsFilled ? createSupplier() : null)}>
                  Add
                </Button>
              }
       
        
            
            </div>
          </Grid>
        </Grid>
      )}

      {success && <SuccessAlertRightAligned message={success.message} sucess={success.state} />}

      {error && <ErrorAlertRightAligned error={error.state} message={error.message} />}
    </div>
  );
};
