import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Link} from "react-router-dom";
import React from "react";
import SettingsIcon from '@mui/icons-material/Settings';
import {useSelector} from "react-redux";

const TenantConfiguration = ()=>{

	let { businessCat } = useSelector((store) => store.businessCategory);
	if(!businessCat) businessCat = localStorage.getItem("businessCategory");

  
    return(
        <div>
            <ListItem button>
                <div style={{ marginRight: '5%' }}>
                    <SettingsIcon />
                </div>
                <Link style={{textDecoration: 'none', color : '#fff'}} to="/tenantConfig">
                    <ListItemText primary="Configurations" style={{ fontFamily: 'Poppins' }} />
                </Link>
            </ListItem>
        </div>
    )

}
export default TenantConfiguration