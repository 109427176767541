import React, { useEffect, useState } from "react";
import Dash from "../../common/dash";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { ElIf, If } from "rc-if-else";
import {Breadcrumbs, Checkbox, Grid, ListItemText, OutlinedInput, Select, Tab} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { alpha, styled } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import deactivateGroup from "../../common/images/deactivate.svg";
import deactivate from "../../common/images/deactivate.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@material-ui/core/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import activateGroup from "../../common/images/activate.svg";
import { SessionModal } from "../Modals/SessionExpiredModal/sessionModal";
import { SuccessModal } from "../Modals/SuccessModal/successModal";
import { QuestionModal } from "../Modals/QuestionModal/questionModal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import MailIcon from "@mui/icons-material/Mail";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import switchbusiness from "../../common/images/switchbusiness.svg";
import jsondecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logInUser, logoutUser, changeToken } from "../../features/userSlice";
import { handleBranchDetails } from "../../features/branchSlice";
import { handleBusinessBranches } from "../../features/businessBranchesSlice";
import { SuccessAlertRightAligned } from "../bills/startorderModals/modals";
import { handleBusinessCategory } from "../../features/businessCategorySlice";
import { MultipleStopSharp } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CustomSearchInput from "../School/CustomSearchInput";
import { reversePageLoadState } from "../../features/autorefreshonpageload";
import { resetPaymentOptions } from "../../features/paymentOptionsSlice";
import StockFunctions from "../products/stockComponents/stockEndpoints";
import { switchWarehouseState } from "../../features/sideBarSlice";
// session Expired
const sessionStyle = {
	borderRadius: "36px",
	boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
	backgroundColor: "#fff",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "660px",
	height: "570px",
	p: 4,
};

//Add shop Style
const addShopStyle = {
	transform: "translate(-50%, -50%)",
	position: "absolute",
	top: "50%",
	left: "50%",
	width: "900px",
	height: "600px",
	margin: "26px 0 0",
	borderRadius: "15px",
	backgroundColor: "#fff",
};

const baseUrl = process.env.REACT_APP_BASE_URL;

const style = {
	width: "665px",
	height: "900px",
	margin: "20px Auto 0 Auto",
	padding: "0 0 26.8px",
	borderRadius: "15px",
	backgroundColor: "#fff",
	textAlign: "right",
};

const SuccessModalStyle = {
	width: "506px",
	height: "506px",
	margin: "10% Auto 0 Auto",
	padding: "86px 24px 97.7px 61px",
	borderRadius: "36px",
	boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
	backgroundColor: "#fff",
};

const breadcrumbs = [
	<Typography key="X" sx={{color:"#707070",fontSize:"0.875rem"}}>
		Dashboard
	</Typography>,
	<Typography key={"add"} sx={{color:"#dc3545", fontSize:"0.875rem"}}>
		My Businesses
	</Typography>
];

const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
		transformOrigin={{
			vertical: "top",
			horizontal: "right",
			frontFamily: "Poppins",
		}}
		{...props}
	/>
))(({ theme }) => ({
	"& .MuiPaper-root": {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 18,
		frontFamily: "Poppins",
		color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
		boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
		"& .MuiMenu-list": { padding: "4px 0" },
		"& .MuiMenuItem-root": {
			"& .MuiSvgIcon-root": {
				frontFamily: "Poppins",
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			"&:active": {
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
			},
		},
	},
}));

// Custom Tab Styling
const AntTabs = styled(TabList)({
	borderBottom: "0px solid #e8e8e8",
	"& .MuiTabs-indicator": {
		backgroundColor: "#ffffff00",
	},
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
	// textTransform: "none",
	minWidth: 0,
	[theme.breakpoints.up("sm")]: {
		minWidth: 0,
	},
	fontWeight: theme.typography.fontWeightRegular,
	marginRight: theme.spacing(1),
	fontSize: "13px",
	fontStretch: "normal",
	fontStyle: "normal",
	lineHeight: "2.75",
	letterSpacing: "normal",
	textAlign: "left",
	color: "#6e7074",
	fontFamily: ["Poppins"].join(","),
	"&:hover": {
		color: "#032541",
		opacity: 1,
	},
	"&.Mui-selected": {
		color: "#dc3545",
		fontWeight: 600,
	},
	"&.Mui-focusVisible": {
		backgroundColor: "blue",
	},
}));

const modalTableStyle = {
	transform: "translate(-50%, -50%)",
	position: "absolute",
	top: "50%",
	left: "50%",
	width: "900px",
	height: "350px",
	padding: "0 0 73.9px",
	borderRadius: "15px",
	backgroundColor: "#fff",
};

// Conditional Data grid columns
//Active Columns
const activeColumns = [
	{
		field: "businessName",
		headerName: "Business Name",
		flex: 1,
		headerAlign: "left",
		align: "left",
		editable: false,
		renderHeader: () => (
			<strong
				style={{
					fontFamily: "Poppins",
					fontSize: "14px",
					fontWeight: "600",
					color: "#032541",
				}}
			>
				{"Business Name"}
			</strong>
		),
	},
	{
		field: "businessNumber",
		headerName: "Business ID",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
		renderHeader: () => (
			<strong
				style={{
					fontFamily: "Poppins",
					fontSize: "14px",
					fontWeight: "600",
					color: "#032541",
				}}
			>
				{"Business ID"}
			</strong>
		),
	},
	{
		field: "businessOwnerName",
		headerName: "Business Owner Name",
		flex: 1,
		headerAlign: "left",
		align: "left",
		editable: false,
		renderHeader: () => (
			<strong
				style={{
					fontFamily: "Poppins",
					fontSize: "14px",
					fontWeight: "600",
					color: "#032541",
				}}
			>
				{"BusinessOwnerName"}
			</strong>
		),
	},
	{
		field: "businessOwnerEmail",
		headerName: "Email",
		flex: 1,
		headerAlign: "left",
		align: "left",
		editable: false,
		renderHeader: () => (
			<strong
				style={{
					fontFamily: "Poppins",
					fontSize: "14px",
					fontWeight: "600",
					color: "#032541",
				}}
			>
				{"Email"}
			</strong>
		),
	},
	{
		field: "businessOwnerPhone",
		headerName: "Phone",
		flex: 1,
		headerAlign: "left",
		align: "left",
		editable: false,
		renderHeader: () => (
			<strong
				style={{
					fontFamily: "Poppins",
					fontSize: "14px",
					fontWeight: "600",
					color: "#032541",
				}}
			>
				{"Phone"}
			</strong>
		),
	},
	{
		field: "branches",
		headerName: "Branches",
		flex: 1,
		headerAlign: "left",
		align: "left",
		editable: false,
		renderHeader: () => (
			<strong
				style={{
					fontFamily: "Poppins",
					fontSize: "14px",
					fontWeight: "600",
					color: "#032541",
				}}
			>
				{"Branches"}
			</strong>
		),
		renderCell: (params) => {
			// modal config
			const [tableModal, setTableModal] = useState(false);
			const handleTableOpen = () => setTableModal(true);
			const handleTableClose = () => setTableModal(false);

			// console.log("@!@!@", params.row.initialSerialNumber)
			const branches = params.row.branches;

			// pagination config
			const pagination = paginationFactory({
				page: 1,
				hideSizePerPage: true,
				sizePerPage: 6,
				lastPageText: ">>",
				firstPageText: "<<",
				nextPageText: ">",
				prePageText: "<",
				showTotal: true,
				alwaysShowAllBtns: false,
				onPageChange: function (page, sizePerPage) {
					// console.log("page", page);
					// console.log("sizePerPage", sizePerPage);
				},
				onSizePerPageChange: function (page, sizePerPage) {
					// console.log("page", page);
					// console.log("sizePerPage", sizePerPage);
				},
			});

			// columns for the row data
			// const internalColumns = [
			//   { dataField: "", text: "Terminals", sort: true },
			// ];

			const columns = [
				{ field: "id", headerName: "Branch Code", flex: 1 },
				{ field: "branchName", headerName: "Branch Name", flex: 1 },
				{ field: "isDefault", headerName: "Is Default", flex: 1 },
				{ field: "createdAt", headerName: "Created At", flex: 1 },
			];

			const rows = branches?.map((branch) => ({
				id: branch.branchCode,
				branchName: branch.branchName,
				isDefault: branch.isDefault,
				createdAt: branch.createdAt,
			}));

			return (
				<div>
					{/*button*/}
					<Button onClick={handleTableOpen} sx={{ fontFamily: "Poppins", fontWeight: 600, color: "#06a3ab" }}>
						{params.row.branches.length <= 0 ? "N/A" : params.row.branches.length}
					</Button>

					{/*modal with table*/}
					{params.row.branches.length > 0 ? (
						<>
							<Modal open={tableModal} onClose={handleTableClose}>
								<Box sx={modalTableStyle}>
									<Grid sx={{ width: "auto", height: "50px", padding: "13px 30px 3px", borderRadius: "0px", backgroundColor: "#dc3545" }}>
										<span style={{ width: "auto", height: "2px", margin: "0 340px 0 0", fontFamily: "Poppins", fontSize: "15px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: "normal", textAlign: "left", color: "#fff" }}>Branch Details for {params.row.businessName}</span>
									</Grid>
									<DataGrid rows={rows} columns={columns} pageSize={5} rowHeight={35} />
								</Box>
							</Modal>
						</>
					) : (
						<></>
					)}
				</div>
			);
		},
	},
	{
		field: "initialSerialNumber",
		headerName: "Initial Serial",
		headerAlign: "left",
		align: "left",
		editable: false,
		renderHeader: () => (
			<strong
				style={{
					fontFamily: "Poppins",
					fontSize: "14px",
					fontWeight: "600",
					color: "#032541",
				}}
			>
				{"Terminals"}
			</strong>
		),
		// formatter: (params.row.initialSerialNumber) => (
		//   for(let i = 0; i < params.row.initialSerialNumber.length; i++) {
		//     let IDs = params.row.initialSerialNumber;
		//     return (IDs)
		//  }
		//  console.log(">>>>>>>",IDs)
		// ),
		renderCell: (params) => {
			// modal config
			const [tableModal, setTableModal] = useState(false);
			const handleTableOpen = () => setTableModal(true);
			const handleTableClose = () => setTableModal(false);

			// console.log("@!@!@", params.row.initialSerialNumber)
			const serials = params.row.initialSerialNumber;

			// pagination config
			const pagination = paginationFactory({
				page: 1,
				hideSizePerPage: true,
				sizePerPage: 6,
				lastPageText: ">>",
				firstPageText: "<<",
				nextPageText: ">",
				prePageText: "<",
				showTotal: true,
				alwaysShowAllBtns: false,
				onPageChange: function (page, sizePerPage) {
					// console.log("page", page);
					// console.log("sizePerPage", sizePerPage);
				},
				onSizePerPageChange: function (page, sizePerPage) {
					// console.log("page", page);
					// console.log("sizePerPage", sizePerPage);
				},
			});

			// columns for the row data
			// const internalColumns = [
			//   { dataField: "", text: "Terminals", sort: true },
			// ];

			const columns = [{ field: "id", headerName: "Serial Number", flex: 1 }];

			// const rows = [
			//   {
			//     id: serials[i],
			//   }
			// ];

			const rows = serials?.map((serial) => ({ id: serial }));

			return (
				<div>
					{/*button*/}
					<Button onClick={handleTableOpen} sx={{ fontFamily: "Poppins", fontWeight: 600, color: "#06a3ab" }}>
						{params.row.initialSerialNumber.length <= 0 ? "N/A" : params.row.initialSerialNumber.length}
					</Button>

					{/*modal with table*/}
					{params.row.initialSerialNumber.length > 0 ? (
						<>
							<Modal open={tableModal} onClose={handleTableClose}>
								<Box sx={modalTableStyle}>
									<Grid
										sx={{
											width: "auto",
											height: "50px",
											padding: "13px 30px 3px",
											borderRadius: "0px",
											backgroundColor: "#dc3545",
										}}
									>
                    <span
	                    style={{
		                    width: "auto",
		                    height: "2px",
		                    margin: "0 340px 0 0",
		                    fontFamily: "Poppins",
		                    fontSize: "15px",
		                    fontWeight: "normal",
		                    fontStretch: "normal",
		                    fontStyle: "normal",
		                    lineHeight: "normal",
		                    letterSpacing: "normal",
		                    textAlign: "left",
		                    color: "#fff",
	                    }}
                    >
                      Terminal Details for {params.row.businessName}
                    </span>
									</Grid>
									<DataGrid rows={rows} columns={columns} pageSize={5} rowHeight={35} />
								</Box>
							</Modal>
						</>
					) : (
						<></>
					)}

				</div>
			);
		},
		flex: 1,
	},

	{
		field: "createdAt",
		headerName: "Date Created",
		flex: 1,
		headerAlign: "center",
		align: "left",
		renderHeader: () => (
			<strong
				style={{
					fontFamily: "Poppins",
					fontSize: "14px",
					fontWeight: 600,
					color: "#032541",
				}}
			>
				{"Date Created"}
			</strong>
		),
	},
];

var sucess = false;

// if (localStorage.getItem("group") === "Admin" || localStorage.getItem("group") === "Merchant") {
activeColumns.push({
	field: "Actions",
	headerName: "Actions",
	flex: 1,
	headerAlign: "center",
	align: "center",
	editable: false,
	renderHeader: () => (
		<strong
			style={{
				fontFamily: "Poppins",
				fontSize: "14px",
				fontWeight: "600",
				color: "#032541",
			}}
		>
			{"Actions"}
		</strong>
	),
	renderCell: (params, event) => {
		const { row } = params;

		// session Modal Config
		const [sessionOpen, setSessionOpen] = React.useState(false);
		const handleSessionOpen = () => setSessionOpen(true);
		const handleSessionClose = () => setSessionOpen(false);
		const handleLogin = () => {
			window.location.href = "/logout";
		};

		// Existing terminal Modal config
		const [existingUserModal, setExistingUserModalOpen] = useState(false);
		const existingUserModalHandleOpen = () => setExistingUserModalOpen(true);
		const existingUserModalHandleClose = () => setExistingUserModalOpen(false);

		//DropDown Handler
		const [anchorEl, setAnchorEl] = React.useState(null);
		const ActionOpen = Boolean(anchorEl);
		const handleClick = (event) => {
			setAnchorEl(event.currentTarget);
		};
		const handleActionClose = () => {
			setAnchorEl(null);
		};

		// Updating the terminals Data as well
		const updateTerminal = async (biz_id) => {
			try {
				const response = await fetch(baseUrl + "/api/updateTerminal/" + initialSerial, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
					body: JSON.stringify({
						businessID: biz_id,
						till_paybill: till_paybill,
					}),
				});
				const data = await response.json();
				// console.log(data);

				if (response.status === 202) {
					handleClose();
				} else if (response.status === 407) {
					handleClose();
					existingUserModalHandleOpen();
				} else if (response.status === 401) {
					handleSessionOpen();
				} else {
					alert("Something went wrong");
				}
			} catch (error) {
				console.log(error);
			}
		};

		// Terminals Setters and Getters
		const [terminals, setTerminals] = useState([]);
		const [bulkTerminals, setBulkTerminals] = useState([]);

		const ITEM_HEIGHT = 48;
		const ITEM_PADDING_TOP = 8;
		const MenuProps = {
			PaperProps: {
				style: {
					maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
					width: 250,
				},
			},
		};

		// const handleBulkTerminals = (event) => {
		//     const {
		//         target: { value }
		//     } = event;
		//     setBulkTerminals(
		//         // On autofill, we get a  value.
		//         typeof value === "string" ? value.split(",") : value
		//     );
		//     setInitialSerial(initialSerial,value.forEach(x => initialSerial.push(x)))
		// };

		// Edit  Modal Config
		const [open, setOpen] = useState(false);
		const handleClickOpen = () => setOpen(true);
		const handleClose = () => {
			setOpen(false);
			window.location.reload(false);
		};

		// handle tab Changes
		const [tabValue, setTabValue] = React.useState("Active");
		const handleTabChange = (event, newValue) => {
			setTabValue(newValue);
		};

		//handle adding shop tab changes
		const [tabCreateValue, setTabCreateValue] = React.useState("businessDetails");
		const handleTabCreateChange = (event, newCreateValue) => {
			bulkTerminals.push(row.initialSerialNumber);
			setTabCreateValue(newCreateValue);
		};

		// Deactivate  Modal Config
		const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
		const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
		const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

		// Success Deactivation Modal Config
		const [successDeactivationModalOpen, setSuccessDeactivationModalOpen] = useState(false);
		const successDeactivationModalHandleOpen = () => setSuccessDeactivationModalOpen(true);
		const successDeactivationModalHandleClose = () => setSuccessDeactivationModalOpen(false);

		// Success Edit Modal Control
		const [successOpen, setSuccessOpen] = React.useState(false);
		const handleSuccessOpen = () => setSuccessOpen(true);
		const handleSuccessClose = () => setSuccessOpen(false);

		// Shop Creation setters and getters
		// Business Creation Setters and Getters
		const [businessName, setBusinessName] = useState(row.businessName);
		const [businessOwnerName, setBusinessOwnerName] = useState(row.businessOwnerName);
		const [businessOwnerEmail, setBusinessOwnerEmail] = useState(row.businessOwnerEmail);
		const [businessOwnerPhone, setBusinessOwnerPhone] = useState(row.businessOwnerPhone);
		const [businessOwnerAddress, setbusinessOwnerAddress] = useState(row.businessOwnerAddress);
		const [till_paybill, settill_paybill] = useState(row.till_paybill);
		const [initialSerial, setInitialSerial] = useState(row.initialSerialNumber);
		const [businessNumber, setBusinessNumber] = useState(row.businessNumber);
		const [till, setTill] = useState(row.Till);
		const [payBill, setPayBill] = useState(row.Paybill);
		const [vooma, setVooma] = useState(row.Vooma);
		const [equitel, setEquitel] = useState(row.Equitel);

		//Group Update Url
		const updateUrl = baseUrl + `/api/updateBusiness/?businessId=${businessNumber}`;
		const deleteUrl = baseUrl + "/api/deleteBusiness/" + row.id;
		const terminaUpdate = baseUrl + "/api/bulkUpdateTerminals";

		// Terminals URL
		const TfetchUrl = baseUrl + "/api/unassignedTerminals/Active";

		const group = localStorage.getItem("group");

		// Get all Active Terminals
		const fetchTerminals = async () => {
			try {
				const tresponse = await fetch(TfetchUrl, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
				});
				if (tresponse.status === 200) {
					const tdata = await tresponse.json();
					setTerminals(tdata.data);
				} else if (tresponse.status === 401) {
					handleSessionOpen();
				}
			} catch (e) {
				console.log(e);
			}
		};

		useEffect(() => {
			fetchTerminals();
		}, []);

		// Mapping terminals to the drop-Down
		const terminalOptions = terminals.map((term) => {
			return {
				serial: term.terminalSerialNumber,
			};
		});

		//Update Function
		const update = async (e) => {
			e.preventDefault();
			const editResponse = await fetch(updateUrl, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					"X-Authorization": localStorage.getItem("X-Authorization"),
				},
				body: JSON.stringify({
					businessName: businessName,
					businessOwnerName: businessOwnerName,
					businessOwnerEmail: businessOwnerEmail,
					businessOwnerPhone: businessOwnerPhone,
					businessOwnerAddress: businessOwnerAddress,
					Till: till,
					Paybill: payBill,
					Equitel: equitel,
					Vooma: vooma,
					modifiedAtBy: localStorage.getItem("username"),
					modifiedAt: Date.now(),
					bulkTerminals: bulkTerminals,
				}),
			});
			if (editResponse.status === 202) {
				// Bulk update terminals
				const updateTerminals = await fetch(terminaUpdate, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
					body: JSON.stringify({
						businessID: businessNumber,
						Till: till,
						Paybill: payBill,
						Equitel: equitel,
						Vooma: vooma,
						bulkTerminals: bulkTerminals,
					}),
				});
				// console.log(await updateTerminals.json());
				setBusinessName("");
				setBusinessOwnerName("");
				setBusinessOwnerEmail("");
				setBusinessOwnerPhone("");
				setbusinessOwnerAddress("");
				setBusinessNumber("");
				setBusinessOwnerPhone("");
				setBulkTerminals("");

				handleClose();
				handleSuccessOpen();
				await updateTerminals();
			} else if (editResponse.status === 401) {
				handleSessionOpen();
			}
		};

		// Deactivate Update Function
		const deactivateUpdate = async (e) => {
			e.preventDefault();
			try {
				const deactivateResponse = await fetch(updateUrl, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
					body: JSON.stringify({
						businessState: "Deactivation",
						requestedBy: localStorage.getItem("username"),
						deactivatedAt: Date.now(),
					}),
				});
				if (deactivateResponse.status === 202) {
					DeactivateModalHandleClose();
					successDeactivationModalHandleOpen();
				} else if (deactivateResponse.status === 401) {
					handleSessionOpen();
				}
			} catch (e) {
				console.log(e);
			}
		};
		const handleBulkTerminals = (event) => {
			const {
				target: { value },
			} = event;
			setBulkTerminals(typeof value === "string" ? value.split(",") : value);
		};
		for (let i = 0; i < initialSerial.length; i++) {
			if (!bulkTerminals.includes(initialSerial[i])) {
				bulkTerminals.push(initialSerial[i]);
			}
		}
		// console.log(bulkTerminals, "BulkTerminals")
		// console.log(row.initialSerialNumber, "Initial Serial")

		const userType = localStorage.getItem("group");
		const GROUPS = localStorage.getItem('group')
		const switchBs = GROUPS === 'Owner' || GROUPS === 'Merchant' || GROUPS === 'Director'

		const navigate = useNavigate();
		const dispatch = useDispatch();
		const [openSucess, setOpenSuccess] = useState(false);
		const [branchId, setDefaultBranchId] = useState("");
		const [userGroup, setUserGroup] = useState("");
		const [category, setUserCategory] = useState("");

		// SWITCH TO NEW BUSINESS;
		const switchDefaultBusiness = async (defaultbsId) => {
			try {
				const switchDefaultBusinessResp = await fetch(baseUrl + "/api/set_user_default_business ", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
					body: JSON.stringify({
						defaultBusinessId: defaultbsId,
					}),
				});

				if (switchDefaultBusinessResp.status === 200) {
					switchBusiness();
				}
			} catch (error) {
				console.log(error);
			}
		};
		const switchBusiness = async () => {
			// console.log(branchId);
			try {
				const switchBusinessResp = await fetch(baseUrl + "/api/get_token_after_invite", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
					body: JSON.stringify({}),
				});

				if (switchBusinessResp.status === 200) {
					const switchRespData = await switchBusinessResp.json();
					setUserGroup(switchRespData.data.group);
					setUserCategory(switchRespData.data.businessCategory);

					dispatch(handleBusinessCategory(switchRespData.data.businessCategory));
					fetchBranches(switchRespData.data.token).then(() => decodeTokenAndSetNewBusiness(switchRespData.data.token, switchRespData.data));
					sucess = true;
				}
			} catch (error) {
				console.log(error);
			}
		};

		// // decode token and update bsuniess details;
		const decodeTokenAndSetNewBusiness = (newToken, data) => {
			const decodedToken = jsondecode(newToken);

			localStorage.setItem("X-Authorization", newToken);
			localStorage.setItem("group", decodedToken.group);
			localStorage.setItem("zedAccountingId", decodedToken.zedAccountingId);
			setUserGroup(decodedToken.group)
			localStorage.setItem("email", decodedToken.email);
			localStorage.setItem("state", decodedToken.state);
			localStorage.setItem("businessID", decodedToken.defaultBusinessId);
			localStorage.setItem("username", decodedToken.userName);
			localStorage.setItem("businessName", decodedToken.businessName);
			localStorage.setItem("businessId", decodedToken.businessNumber);
			localStorage.setItem("userId", decodedToken.userId);
			localStorage.setItem("branch", " ");
			if(decodedToken.branchId) localStorage.setItem("BranchID", decodedToken.branchId);

			localStorage.setItem("customerId", decodedToken.customerId);
		
			localStorage.setItem("businessCategory", decodedToken.businessCategory);

			branchId && dispatch(logInUser({ isLoggedIn: true, X_Authorization: newToken, userId: decodedToken.userId, email: decodedToken.email, username: decodedToken.username, group: decodedToken.group, state: decodedToken.state, defaultBusinessId: decodedToken.defaultBusinessId, businessNumber: decodedToken.businessNumber, businessName: decodedToken.businessName, businessCategory: decodedToken.businessCategory, branchId: branchId, customerId: decodedToken.customerId }));
		};

		const fetchBranches = async (token) => {
	
			try {
				const response = await fetch(baseUrl + "/api/getBusinessBranchesByBid", {
					method: "GET",
					headers: {
						"X-Authorization": token,
						"Content-Type": "application/json",
						Accept: "application/json",
					},
				});
				const branchData = await response.json();

				// get default branch Id;
				const defaultBranchId = branchData.data[0]._id;
				setDefaultBranchId(defaultBranchId);
				if (response.status === 401) {
					return navigate("/logout");
				}
				if (response.status === 200) {
					dispatch(handleBusinessBranches({ branchesArr: branchData.data }));
					dispatch(handleBranchDetails({ _id: defaultBranchId }));
					dispatch(reversePageLoadState());
					localStorage.setItem('BranchID', branchId)
					// window.location.reload()
					const stockfunctions = new StockFunctions();
				     stockfunctions.getWarehouseStatus(token).then((data) => {
						if(data?.status === 201){
						  dispatch(switchWarehouseState(data?.response?.data?.warehouseOn))
						}
					   })
					
						if(userGroup && userGroup === 'Cashier'){
							navigate("/orders/startorder");
						}else {	
							navigate("/dashboard");	
						}
							
				
				}
			} catch (e) {
				console.log(e);
			}
		};

		return (
			<div>
				{/* success busines switch */}
				{/* {openSucess && <SuccessAlertRightAligned message={"Business switched successfully select a branch to proceed!"} openSucess={openSucess} />} */}
				{/*  Edit Merchant Modal*/}
				<Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="container-fluid">
					<Box sx={addShopStyle}>
						<Box
							item
							sx={{
								width: "900px",
								height: "50px",
								margin: "0 0 0px",
								padding: "13px 9px 19px 10px",
								borderRadius: "16px",
								backgroundColor: "#dc3545",
							}}
						>
              <span
	              style={{
		              height: "13px",
		              margin: "0 48px 20px 20px",
		              fontFamily: "Poppins",
		              fontSize: "15px",
		              fontWeight: "normal",
		              fontStretch: "normal",
		              fontStyle: "normal",
		              lineHeight: "normal",
		              letterSpacing: "normal",
		              textAlign: "left",
		              color: "#fff",
	              }}
              >
                Add a shop/Business
              </span>
						</Box>
						<Box
							sx={{
								width: "900px",
								height: "500px",
								margin: "-50px 0 63px 0.5px",
								padding: "55px 0 0",
							}}
						>
							{/*Tabs Configuration*/}
							<TabContext value={tabCreateValue.toString()}>
								<Box>
									<AntTabs onChange={handleTabCreateChange} aria-label="Shop tabs" TabIndicatorProps={{ hidden: false }} textColor="primary">
										<AntTab
											style={{
												height: "35px",
												margin: "13px 1px 16px 13px",
												fontFamily: "Poppins",
												fontSize: "20px",
												fontWeight: "600",
												fontStretch: "normal",
												fontStyle: "normal",
												lineHeight: "1.32",
												letterSpacing: "normal",
												textAlign: "left",
												color: "#032541",
											}}
											label="Business Details"
											value="businessDetails"
										/>
										<AntTab style={{ width: "200px" }}></AntTab>
										<AntTab
											style={{
												height: "35px",
												margin: "13px 3px 16px 16px",
												fontFamily: "Poppins",
												fontSize: "20px",
												fontWeight: "600",
												fontStretch: "normal",
												fontStyle: "normal",
												lineHeight: "1.32",
												letterSpacing: "normal",
												textAlign: "left",
												color: "#032541",
											}}
											label="Payment Details"
											value="paymentDetails"
										/>
									</AntTabs>
								</Box>

								<form onSubmit={update}>
									{/*BusinessDetails*/}
									<TabPanel value="businessDetails">
										<Grid container columns={2}>
											{/*right side*/}
											<Grid item xs={1}>
												{/*business name*/}
												<Box
													sx={{
														width: "489px",
														height: "81px",
														margin: "7px 16px 22px 20px",
													}}
												>
                          <span
	                          style={{
		                          height: "21px",
		                          margin: "0 351px 15px 16px",
		                          fontFamily: "Poppins",
		                          fontSize: "15px",
		                          fontWeight: "normal",
		                          fontStretch: "normal",
		                          fontStyle: "normal",
		                          lineHeight: "2",
		                          letterSpacing: "normal",
		                          textAlign: "left",
		                          color: "#032541",
	                          }}
                          >
                            Business Name
                          </span>
													<input
														type={"text"}
														required
														value={businessName}
														onChange={(e) => setBusinessName(e.target.value)}
														style={{
															width: "360px",
															fontFamily: "Poppins",
															fontSize: "12px",
															padding: "15px",
															height: "45px",
															margin: "15px 0 0 12px",
															objectFit: "contain",
															borderRadius: "12px",
															border: "solid 1px #f1f1f1",
														}}
														placeholder="Enter Business Name Here"
													/>
												</Box>

												{/*business owner name*/}
												<Box
													sx={{
														width: "489px",
														height: "81px",
														margin: "4px 16px 26px 20px",
													}}
												>
                          <span
	                          style={{
		                          height: "21px",
		                          margin: "0 289px 15px 16px",
		                          fontFamily: "Poppins",
		                          fontSize: "15px",
		                          fontWeight: "normal",
		                          fontStretch: "normal",
		                          fontStyle: "normal",
		                          lineHeight: "2",
		                          letterSpacing: "normal",
		                          textAlign: "left",
		                          color: "#032541",
	                          }}
                          >
                            Business Owner's Name
                          </span>
													<input
														type={"text"}
														required
														value={businessOwnerName}
														onChange={(e) => setBusinessOwnerName(e.target.value)}
														style={{
															width: "360px",
															fontFamily: "Poppins",
															fontSize: "12px",
															padding: "15px",
															height: "45px",
															margin: "15px 0 0 12px",
															objectFit: "contain",
															borderRadius: "12px",
															border: "solid 1px #e4e4e4",
														}}
														placeholder="Enter Business Owner's Name Here"
													/>
												</Box>

												{/*phone number*/}
												<Box
													sx={{
														width: "489px",
														height: "81px",
														margin: "6px 16px 7px 20px",
													}}
												>
                          <span
	                          style={{
		                          height: "21px",
		                          margin: "0 354px 15px 16px",
		                          fontFamily: "Poppins",
		                          fontSize: "15px",
		                          fontWeight: "normal",
		                          fontStretch: "normal",
		                          fontStyle: "normal",
		                          lineHeight: "2",
		                          letterSpacing: "normal",
		                          textAlign: "left",
		                          color: "#032541",
	                          }}
                          >
                            Phone Number
                          </span>
													<input
														type={"text"}
														required
														value={businessOwnerPhone}
														onChange={(e) => setBusinessOwnerPhone(e.target.value)}
														maxLength="10"
														style={{
															width: "360px",
															fontFamily: "Poppins",
															fontSize: "12px",
															padding: "15px",
															height: "45px",
															margin: "15px 0 0 12px",
															objectFit: "contain",
															borderRadius: "12px",
															border: "solid 1px #e4e4e4",
														}}
														placeholder="Enter Phone Number Here"
													/>
												</Box>
											</Grid>

											{/*left side*/}
											<Grid item xs={1}>
												{/*Email*/}
												<Box
													sx={{
														width: "489px",
														height: "81px",
														margin: "7px 24px 22px 35px",
													}}
												>
                          <span
	                          style={{
		                          width: "48px",
		                          height: "21px",
		                          margin: "0 351px 3px 0",
		                          fontFamily: "Poppins",
		                          fontSize: "15px",
		                          fontWeight: "normal",
		                          fontStretch: "normal",
		                          fontStyle: "normal",
		                          lineHeight: "2",
		                          letterSpacing: "normal",
		                          textAlign: "left",
		                          color: "#032541",
	                          }}
                          >
                            Email
                          </span>
													<input
														type={"text"}
														required
														value={businessOwnerEmail}
														onChange={(e) => setBusinessOwnerEmail(e.target.value)}
														maxLength="50"
														disabled={true}
														style={{
															width: "360px",
															fontFamily: "Poppins",
															fontSize: "12px",
															padding: "15px",
															height: "45px",
															margin: "14px 0 0",
															objectFit: "contain",
															borderRadius: "12px",
															border: "solid 1px #e4e4e4",
														}}
														placeholder="Enter Valid Email Address"
													/>
												</Box>

												{/*Geolocation*/}
												<Box
													sx={{
														width: "489px",
														height: "81px",
														margin: "4px 24px 26px 35px",
													}}
												>
                          <span
	                          style={{
		                          width: "110px",
		                          height: "21px",
		                          margin: "0 289px 15px 0",
		                          fontFamily: "Poppins",
		                          fontSize: "15px",
		                          fontWeight: "normal",
		                          fontStretch: "normal",
		                          fontStyle: "normal",
		                          lineHeight: "2",
		                          letterSpacing: "normal",
		                          textAlign: "left",
		                          color: "#032541",
	                          }}
                          >
                            Geo-Location
                          </span>
													<input
														type={"text"}
														required
														value={businessOwnerAddress}
														onChange={(e) => setbusinessOwnerAddress(e.target.value)}
														maxLength="25"
														style={{
															width: "360px",
															fontFamily: "Poppins",
															fontSize: "12px",
															padding: "15px",
															height: "45px",
															margin: "7px 0 0",
															objectFit: "contain",
															borderRadius: "12px",
															border: "solid 1px #e4e4e4",
														}}
														placeholder="Enter Businesss Location"
													/>
												</Box>

												{/*Assign terminals*/}
												<Box
													sx={{
														width: "489px",
														height: "81px",
														margin: "6px 24px 7px 35px",
													}}
												>
                          <span
	                          style={{
		                          width: "134px",
		                          height: "21px",
		                          margin: "0 265px 16px 0",
		                          fontFamily: "Poppins",
		                          fontSize: "15px",
		                          fontWeight: "normal",
		                          fontStretch: "normal",
		                          fontStyle: "normal",
		                          lineHeight: "2",
		                          letterSpacing: "normal",
		                          textAlign: "left",
		                          color: "#032541",
	                          }}
                          >
                            Assign Terminals
                          </span>
													<Select
														required
														sx={{
															width: "360px",
															fontFamily: "Poppins",
															fontSize: "12px",
															padding: "15px",
															height: "45px",
															margin: "6px 0px 50.4px 0px",
															objectFit: "contain",
															borderRadius: "12px",
															border: "solid 1px #e4e4e4",
														}}
														labelId="demo-multiple-checkbox-label"
														id="demo-multiple-checkbox"
														multiple
														value={bulkTerminals}
														onChange={handleBulkTerminals}
														input={<OutlinedInput label="Tag" />}
														renderValue={(selected) => selected.join(", ")}
														MenuProps={MenuProps}
													>
														{/* {terminalOptions?.length > 0 ? (
                                <>
                                  {terminalOptions?.map((serials) => (

                                    <MenuItem
                                      key={serials.serial}
                                      value={serials.serial}
                                    >
                                      <Checkbox
                                        checked={
                                          bulkTerminals.indexOf(
                                            serials.serial
                                          ) > -1
                                        }
                                      />
                                      <ListItemText primary={serials.serial} />
                                    </MenuItem>
                                  ))}
                                </>
                              ) : (
                                <>
                                  <MenuItem
                                    key={serials.serial}
                                    value={serials.serial}
                                  >
                                    <Checkbox
                                      checked={
                                        bulkTerminals.indexOf(serials.serial) >
                                        -1
                                      }
                                    />
                                    <ListItemText>
                                      No terminals available
                                    </ListItemText>
                                  </MenuItem>
                                </>
                              )} */}

														{terminalOptions?.map((serials) => (
															<MenuItem key={serials.serial} value={serials.serial}>
																<Checkbox checked={bulkTerminals.indexOf(serials.serial) > -1} />
																<ListItemText primary={serials.serial} />
															</MenuItem>
														))}
													</Select>
												</Box>
											</Grid>
										</Grid>
										<Grid container columns={2} marginTop="15px">
											<Grid item xs={1.3}>
                        <span
	                        style={{
		                        fontFamily: "Poppins",
		                        fontSize: "15px",
		                        margin: "0px 0px 0px 32px",
	                        }}
                        >
                          Assigned Terminals
                        </span>
												<Box>
                          <textarea
	                          readOnly
	                          value={initialSerial}
	                          style={{
		                          borderRadius: "12px",
		                          height: "49px",
		                          border: "solid 1px #e4e4e4",
		                          width: "360px",
		                          margin: "15px 0px 0px 30px",
		                          paddingLeft: "15px",
		                          fontFamily: "Poppins",
		                          fontSize: "12px",
	                          }}
                          >
                            {initialSerial}
                          </textarea>
												</Box>
											</Grid>
											<Grid item>
												{/*Buttons*/}
												<Box>
													<Button
														onClick={handleClose}
														sx={{
															fontFamily: "Poppins",
															fontSize: "12px",
															fontWeight: "600",
															fontStretch: "normal",
															fontStyle: "normal",
															lineHeight: "1.67",
															letterSpacing: "normal",
															textAlign: "left",
															color: "#cb1227",
															width: "80px",
															height: "42.6px",
															margin: "0.4px 55px 0 0",
															border: "solid 1px #dc3545",
														}}
													>
														Cancel
													</Button>
													<Button
														align={"right"}
														value={tabCreateValue}
														onClick={(e) => {
															setTabCreateValue("paymentDetails");
														}}
														sx={{
															fontFamily: "Poppins",
															fontSize: "12px",
															fontWeight: "600",
															fontStretch: "normal",
															fontStyle: "normal",
															lineHeight: "1.67",
															letterSpacing: "normal",
															textAlign: "left",
															color: "#fff",
															width: "80px",
															height: "42.6px",
															margin: "0 0 0.4px 55px",
															backgroundColor: "#032541",
														}}
													>
														Next
													</Button>
												</Box>
											</Grid>
										</Grid>
									</TabPanel>

									{/*PaymentDetails*/}
									<TabPanel value="paymentDetails">
										<Grid container columns={2}>
											{/*right side*/}
											<Grid item xs={1}>
												{/*Till Number*/}
												<Box
													sx={{
														width: "489px",
														height: "81px",
														margin: "7px 16px 22px 20px",
													}}
												>
                          <span
	                          style={{
		                          height: "21px",
		                          margin: "0 351px 15px 16px",
		                          fontFamily: "Poppins",
		                          fontSize: "15px",
		                          fontWeight: "normal",
		                          fontStretch: "normal",
		                          fontStyle: "normal",
		                          lineHeight: "2",
		                          letterSpacing: "normal",
		                          textAlign: "left",
		                          color: "#032541",
	                          }}
                          >
                            Till Number
                          </span>
													<input
														type={"number"}
														value={till}
														onChange={(e) => setTill(e.target.value)}
														style={{
															width: "360px",
															fontFamily: "Poppins",
															fontSize: "12px",
															padding: "15px",
															height: "45px",
															margin: "15px 0 0 12px",
															objectFit: "contain",
															borderRadius: "12px",
															border: "solid 1px #f1f1f1",
														}}
														placeholder="Enter Till Number Here"
													/>
												</Box>

												{/*Paybill Number*/}
												<Box
													sx={{
														width: "489px",
														height: "81px",
														margin: "4px 16px 26px 20px",
													}}
												>
                          <span
	                          style={{
		                          height: "21px",
		                          margin: "0 289px 15px 16px",
		                          fontFamily: "Poppins",
		                          fontSize: "15px",
		                          fontWeight: "normal",
		                          fontStretch: "normal",
		                          fontStyle: "normal",
		                          lineHeight: "2",
		                          letterSpacing: "normal",
		                          textAlign: "left",
		                          color: "#032541",
	                          }}
                          >
                            Paybill Number
                          </span>
													<input
														type={"number"}
														value={payBill}
														onChange={(e) => setPayBill(e.target.value)}
														style={{
															width: "360px",
															fontFamily: "Poppins",
															fontSize: "12px",
															padding: "15px",
															height: "45px",
															margin: "15px 0 0 12px",
															objectFit: "contain",
															borderRadius: "12px",
															border: "solid 1px #e4e4e4",
														}}
														placeholder="Enter Paybill Number Here"
													/>
												</Box>
											</Grid>

											{/*left side*/}
											<Grid item xs={1}>
												{/*Equitel*/}
												<Box
													sx={{
														width: "489px",
														height: "81px",
														margin: "7px 24px 22px 35px",
													}}
												>
                          <span
	                          style={{
		                          width: "48px",
		                          height: "21px",
		                          margin: "0 351px 3px 0",
		                          fontFamily: "Poppins",
		                          fontSize: "15px",
		                          fontWeight: "normal",
		                          fontStretch: "normal",
		                          fontStyle: "normal",
		                          lineHeight: "2",
		                          letterSpacing: "normal",
		                          textAlign: "left",
		                          color: "#032541",
	                          }}
                          >
                            Equitel
                          </span>
													<input
														type={"number"}
														value={equitel}
														onChange={(e) => setEquitel(e.target.value)}
														style={{
															width: "360px",
															fontFamily: "Poppins",
															fontSize: "12px",
															padding: "15px",
															height: "45px",
															margin: "14px 0 0",
															objectFit: "contain",
															borderRadius: "12px",
															border: "solid 1px #e4e4e4",
														}}
														placeholder="Enter Equitel Here"
													/>
												</Box>

												{/*Vooma*/}
												<Box
													sx={{
														width: "489px",
														height: "81px",
														margin: "4px 24px 26px 35px",
													}}
												>
                          <span
	                          style={{
		                          width: "110px",
		                          height: "21px",
		                          margin: "0 289px 15px 0",
		                          fontFamily: "Poppins",
		                          fontSize: "15px",
		                          fontWeight: "normal",
		                          fontStretch: "normal",
		                          fontStyle: "normal",
		                          lineHeight: "2",
		                          letterSpacing: "normal",
		                          textAlign: "left",
		                          color: "#032541",
	                          }}
                          >
                            Vooma
                          </span>
													<input
														type={"number"}
														value={vooma}
														onChange={(e) => setVooma(e.target.value)}
														style={{
															width: "360px",
															fontFamily: "Poppins",
															fontSize: "12px",
															padding: "15px",
															height: "45px",
															margin: "7px 0 0",
															objectFit: "contain",
															borderRadius: "12px",
															border: "solid 1px #e4e4e4",
														}}
														placeholder="Enter Vooma Here"
													/>
												</Box>
											</Grid>
										</Grid>
										<Grid container columns={2} marginTop="15px">
											<Grid item xs={1.29}></Grid>
											<Grid item>
												{/*Buttons*/}
												<Box>
													<Button
														onClick={handleClose}
														sx={{
															fontFamily: "Poppins",
															fontSize: "12px",
															fontWeight: "600",
															fontStretch: "normal",
															fontStyle: "normal",
															lineHeight: "1.67",
															letterSpacing: "normal",
															textAlign: "left",
															color: "#cb1227",
															width: "80px",
															height: "42.6px",
															margin: "0.4px 55px 0 0",
															border: "solid 1px #dc3545",
														}}
													>
														Cancel
													</Button>
													<Button
														type="submit"
														sx={{
															fontFamily: "Poppins",
															fontSize: "12px",
															fontWeight: "600",
															fontStretch: "normal",
															fontStyle: "normal",
															lineHeight: "1.67",
															letterSpacing: "normal",
															textAlign: "left",
															color: "#fff",
															width: "80px",
															height: "42.6px",
															margin: "0 0 0.4px 55px",
															backgroundColor: "#032541",
														}}
													>
														Save
													</Button>
												</Box>
											</Grid>
										</Grid>
									</TabPanel>
								</form>
							</TabContext>
						</Box>
					</Box>
				</Modal>

				{/*sessionModal*/}
				<SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen} />

				{/*Deactivate Group Modal*/}
				<QuestionModal deactivateModalOpen={deactivateModalOpen} onClose={DeactivateModalHandleClose} formAction={deactivateUpdate} actionImage={deactivateGroup} action="Deactivate" item={row.businessName} processName={"deactivate"} />

				{/*Status Update Modals*/}
				{/*Success Deactivation Modal*/}
				<SuccessModal onClose={successDeactivationModalHandleClose} editConfigOpen={successDeactivationModalOpen} modalRow={row.businessName} successObject={"Business"} successType={"Deactivated"} />

				{/*Success Edit Groups*/}
				<SuccessModal onClose={handleSuccessClose} editConfigOpen={successOpen} modalRow={row.businessName} successObject={"Business"} successType={"Updated!"} />

				{/*Action Button Menu*/}
				<Button sx={{ fontFamily: "Poppins", backgroundColor: "#dc3545" }} id="demo-customized-button" aria-controls={ActionOpen ? "demo-customized-menu" : undefined} aria-haspopup="true" aria-expanded={ActionOpen ? "true" : undefined} variant="contained" disableElevation onClick={handleClick} endIcon={<KeyboardArrowDownIcon sx={{ marginLeft: "-10px" }} />}>
					Action
				</Button>

				<StyledMenu id="demo-customized-menu" MenuListProps={{ "aria-labelledby": "demo-customized-button" }} anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>

					{
						switchBs ? <div>
					<MenuItem
						disableRipple
						 onClick={() =>navigate(`/shops/edit/${params?.row?.id}`) }
						// onClick={() => {
						// 	handleClickOpen();
						// 	handleActionClose();
						// }}
					>
						<EditIcon style={{ color: "#032541" }} /> Edit
					</MenuItem>
					<MenuItem
						onClick={() => {
							DeactivateModalHandleOpen();
							handleActionClose();
						}}
						disableRipple
					>
						<CancelIcon style={{ color: "#dc3545" }} /> Deactivate
					</MenuItem>
						</div> : null
					}
									
					
					<MenuItem
						onClick={() => {
							switchDefaultBusiness(params.row.id);
						}}
						disableRipple
					>
						<MultipleStopSharp /> Switch
						
					</MenuItem>
				</StyledMenu>
			</div>
		);
	},
});
// }

// New Columns
const newColumns = [
	// { field: 'id', headerName: 'ID' },
	{
		field: "businessName",
		headerName: "Business Name",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "businessNumber",
		headerName: "Business ID",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},

	{
		field: "businessOwnerName",
		headerName: "businessOwnerName",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "businessOwnerEmail",
		headerName: "Email",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "businessOwnerPhone",
		headerName: "Phone",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "initialSerialNumber",
		headerName: "Initial Serial",
		headerAlign: "left",
		align: "left",
		editable: false,
		width: 300,
	},
	{
		field: "createdAt",
		headerName: "Created At",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "createdBy",
		headerName: "Created By",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},

	// Actions Button
];

if (localStorage.getItem("group") === "Admin") {
	newColumns.push({
		field: "Actions",
		headerName: "Actions",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
		renderCell: (params, event) => {
			//DropDown Handler
			const [anchorEl, setAnchorEl] = React.useState(null);
			const ActionOpen = Boolean(anchorEl);
			const handleClick = (event) => {
				setAnchorEl(event.currentTarget);
			};
			const handleActionClose = () => {
				setAnchorEl(null);
			};

			const { row } = params;

			// session Modal Config
			const [sessionOpen, setSessionOpen] = React.useState(false);
			const handleSessionOpen = () => setSessionOpen(true);
			const handleSessionClose = () => setSessionOpen(false);
			const handleLogin = () => {
				window.location.href = "/logout";
			};

			// Activate Group Modal Config
			const [ActivateModalOpen, setActivateModalOpen] = useState(false);
			const ActivateModalHandleOpen = () => setActivateModalOpen(true);
			const ActivateModalHandleClose = () => setActivateModalOpen(false);

			// Deactivate Group Modal Config
			const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
			const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
			const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

			// Status Updates Modals
			// Success Activation Modal Config
			const [successActivationModalOpen, setSuccessActivationModalOpen] = useState(false);
			const successActivationModalHandleOpen = () => setSuccessActivationModalOpen(true);
			const successActivationModalHandleClose = () => setSuccessActivationModalOpen(false);

			// Success Activation Modal Config
			const [successMailSendModalOpen, setSuccessMailSendModalOpen] = useState(false);
			const successMailSendModalHandleOpen = () => setSuccessMailSendModalOpen(true);
			const successMailSendModalHandleClose = () => setSuccessMailSendModalOpen(false);

			// Success Deactivation Modal Config
			const [successDeactivationModalOpen, setSuccessDeactivationModalOpen] = useState(false);
			const successDeactivationModalHandleOpen = () => setSuccessDeactivationModalOpen(true);
			const successDeactivationModalHandleClose = () => setSuccessDeactivationModalOpen(false);

			//Group Update Url
			const updateUrl = baseUrl + "/api/updateBusiness/" + row.id;
			const resendUrl = baseUrl + "/api/resendMail/";

			// Activate Groups Update Function
			const activateUpdate = async (e) => {
				e.preventDefault();
				try {
					const activateResponse = await fetch(updateUrl, {
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
						body: JSON.stringify({
							businessState: "Active",
							modifiedAt: Date.now(),
							requestedBy: localStorage.getItem("businessOwnerName"),
						}),
					});
					if (activateResponse.status === 202) {
						ActivateModalHandleClose();
						successActivationModalHandleOpen();
					} else if (activateResponse.status === 401) {
						handleSessionOpen();
					}
				} catch (e) {
					console.log(e);
				}
			};

			// Deactivate Groups Update Function
			const deactivateUpdate = async (e) => {
				e.preventDefault();
				try {
					const deactivateResponse = await fetch(updateUrl, {
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
						body: JSON.stringify({
							businessState: "Deactivation",
							requestedBy: localStorage.getItem("username"),
							deactivatedAt: Date.now(),
							approvedBy: localStorage.getItem("businessOwnerName"),
						}),
					});
					if (deactivateResponse.status === 202) {
						DeactivateModalHandleClose();
						successDeactivationModalHandleOpen();
					} else if (deactivateResponse.status === 401) {
						handleSessionOpen();
					}
				} catch (e) {
					console.log(e);
				}
			};

			// Resend Mail
			function resendMail() {
				const mail = async () => {
					// event.preventDefault()
					try {
						const response = await fetch(resendUrl, {
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								Accept: "application/json",
							},
							body: JSON.stringify({ email: row.businessOwnerEmail }),
						});
						if (response.status === 200) {
							successMailSendModalHandleOpen();
						}
					} catch (e) {
						console.log(e);
					}
				};
				mail();
			}

			return (
				<div>
					{/*sessionModal*/}
					<SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen} />

					{/*Activate Modal*/}
					<QuestionModal deactivateModalOpen={ActivateModalOpen} onClose={ActivateModalHandleClose} formAction={activateUpdate} actionImage={activateGroup} action="Activate" item={row.businessName} processName={"activate"} />

					{/*Activate Modal*/}
					<QuestionModal deactivateModalOpen={deactivateModalOpen} onClose={DeactivateModalHandleClose} formAction={deactivateUpdate} actionImage={deactivateGroup} action="Deactivate" item={row.businessName} processName={"Deactivate"} />

					{/*Status Update Modals*/}
					{/*Success Deactivation Modal*/}
					<SuccessModal onClose={successDeactivationModalHandleClose} editConfigOpen={successDeactivationModalOpen} modalRow={row.businessName} successObject={"Business"} successType={"Deactivated"} />

					{/*Success Activation Modal*/}
					<SuccessModal onClose={successActivationModalHandleClose} editConfigOpen={successActivationModalOpen} modalRow={row.businessName} successObject={"Business"} successType={"Activated"} />

					{/*Success mail Send*/}
					<SuccessModal onClose={successMailSendModalHandleClose} editConfigOpen={successMailSendModalOpen} modalRow={row.businessName} successObject={"Mail"} successType={"Sent"} />

					{/*Action Button Menu*/}
					<Button sx={{ backgroundColor: "#dc3545" }} id="demo-customized-button" aria-controls={ActionOpen ? "demo-customized-menu" : undefined} aria-haspopup="true" aria-expanded={ActionOpen ? "true" : undefined} variant="contained" disableElevation onClick={handleClick} endIcon={<KeyboardArrowDownIcon />}>
						Action
					</Button>
					<StyledMenu id="demo-customized-menu" MenuListProps={{ "aria-labelledby": "demo-customized-button" }} anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
						<MenuItem
							disableRipple
							onClick={() => {
								ActivateModalHandleOpen();
								handleActionClose();
							}}
						>
							<EditIcon sx={{ color: "black" }} />
							Approve
						</MenuItem>
						<MenuItem
							onClick={() => {
								DeactivateModalHandleOpen();
								handleActionClose();
							}}
							disableRipple
						>
							<PersonRemoveIcon />
							Decline
						</MenuItem>
						<MenuItem
							onClick={() => {
								resendMail();
								handleActionClose();
							}}
							disableRipple
						>
							<MailIcon />
							Resend Mail
						</MenuItem>
					</StyledMenu>
				</div>
			);
		},
	});
}

// Edited Columns
const editedColumns = [
	// { field: 'id', headerName: 'ID' },
	{
		field: "businessName",
		headerName: "Business Name",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "businessNumber",
		headerName: "Business ID",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "businessOwnerName",
		headerName: "businessOwnerName",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "businessOwnerEmail",
		headerName: "Email",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "businessOwnerPhone",
		headerName: "Phone",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "initialSerialNumber",
		headerName: "Initial Serial",
		headerAlign: "left",
		align: "left",
		editable: false,
		width: 300,
	},
	{
		field: "modifiedBy",
		headerName: "Edited By",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "modifiedAt",
		headerName: "Date Modified",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},

	// Action Button.
];

if (localStorage.getItem("group") === "Admin") {
	editedColumns.push({
		field: "Actions",
		headerName: "Actions",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
		renderCell: (params, event) => {
			//DropDown Handler
			const [anchorEl, setAnchorEl] = React.useState(null);
			const ActionOpen = Boolean(anchorEl);
			const handleClick = (event) => {
				setAnchorEl(event.currentTarget);
			};
			const handleActionClose = () => {
				setAnchorEl(null);
			};

			const { row } = params;

			// session Modal Config
			const [sessionOpen, setSessionOpen] = React.useState(false);
			const handleSessionOpen = () => setSessionOpen(true);
			const handleSessionClose = () => setSessionOpen(false);
			const handleLogin = () => {
				window.location.href = "/logout";
			};

			// Activate Group Modal Config
			const [ActivateModalOpen, setActivateModalOpen] = useState(false);
			const ActivateModalHandleOpen = () => setActivateModalOpen(true);
			const ActivateModalHandleClose = () => setActivateModalOpen(false);

			// Edit Group Modal Config
			const [open, setOpen] = useState(false);
			const handleClickOpen = () => setOpen(true);
			const handleClose = () => setOpen(false);

			// Deactivate Group Modal Config
			const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
			const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
			const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

			// DeactivateRequest Group Modal Config
			const [deactivateReqModalOpen, setDeactivateReqModalOpen] = useState(false);
			const DeactivateReqModalHandleOpen = () => setDeactivateReqModalOpen(true);
			const DeactivateReqModalHandleClose = () => setDeactivateReqModalOpen(false);

			// Delete Group Modal Config
			const [deleteModalOpen, setDeleteModalOpen] = useState(false);
			const DeleteModalHandleOpen = () => setDeleteModalOpen(true);
			const DeleteModalHandleClose = () => setDeleteModalOpen(false);

			// Status Updates Modals
			// Success Activation Modal Config
			const [successActivationModalOpen, setSuccessActivationModalOpen] = useState(false);
			const successActivationModalHandleOpen = () => setSuccessActivationModalOpen(true);
			const successActivationModalHandleClose = () => setSuccessActivationModalOpen(false);

			// Success Deactivation Modal Config
			const [successDeactivationModalOpen, setSuccessDeactivationModalOpen] = useState(false);
			const successDeactivationModalHandleOpen = () => setSuccessDeactivationModalOpen(true);
			const successDeactivationModalHandleClose = () => setSuccessDeactivationModalOpen(false);

			// Success Deletion Modal Config
			const [successDeletionModalOpen, setSuccessDeletionModalOpen] = useState(false);
			const successDeletionModalHandleOpen = () => setSuccessDeletionModalOpen(true);
			const successDeletionModalHandleClose = () => setSuccessDeletionModalOpen(false);

			// Data Getters and Setters
			const [groupName, setGroupName] = useState(row.businessOwnerName);
			const [createdAt, setCreatedAt] = useState(row.createdAt);
			const [businessOwnerName, setbusinessOwnerName] = useState(row.businessOwnerName);
			const [businessOwnerEmail, setbusinessOwnerEmail] = useState(row.businessOwnerEmail);

			//Group Update Url
			const updateUrl = baseUrl + "/api/updateBusiness/" + row.id;
			const deleteUrl = baseUrl + "/api/deleteBusiness/" + row.id;

			//Update Group Function
			//Update Group Function
			const update = async () => {
				const response = await fetch(updateUrl, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
					body: JSON.stringify({
						groupName: groupName,
						businessOwnerName: businessOwnerName,
						businessOwnerEmail: businessOwnerEmail,
						createdAt: createdAt,
						requestedBy: localStorage.getItem("username"),
						modifiedAt: Date.now(),
					}),
				});
				const data = await response.json();
				if (response.status === 202) {
					// console.log("Group Updated");
				} else if (response.status === 401) {
					handleSessionOpen();
				}
			};

			// Activate Groups Update Function
			const activateUpdate = async () => {
				try {
					const activateResponse = await fetch(updateUrl, {
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
						body: JSON.stringify({
							businessState: "Active",
							modifiedAt: Date.now(),
							requestedBy: localStorage.getItem("businessOwnerName"),
						}),
					});
					if (activateResponse.status === 202) {
						// console.log("Shop Activated!");
					} else if (activateResponse.status === 401) {
						handleSessionOpen();
					}
				} catch (e) {
					console.log(e);
				}
			};

			// Deactivate Groups Update Function
			const deactivateUpdate = async () => {
				try {
					const deactivateResponse = await fetch(updateUrl, {
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
						body: JSON.stringify({
							businessState: "Deactivation",
							requestedBy: localStorage.getItem("username"),
							deactivatedAt: Date.now(),
							approvedBy: localStorage.getItem("businessOwnerName"),
						}),
					});
					if (deactivateResponse.status === 202) {
						// console.log("Shop Activated!");
					} else if (deactivateResponse.status === 401) {
						handleSessionOpen();
					}
				} catch (e) {
					console.log(e);
				}
			};

			// Deactivate Request Groups Update Function
			const deactivateReqUpdate = async () => {
				try {
					const deactivateResponse = await fetch(updateUrl, {
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
						body: JSON.stringify({
							businessState: "Inactive",
							deactivatedAt: Date.now(),
							approvedBy: localStorage.getItem("businessOwnerName"),
						}),
					});
					if (deactivateResponse.status === 202) {
						// console.log("Shop Activated!");
					} else if (deactivateResponse.status === 401) {
						handleSessionOpen();
					}
				} catch (e) {
					console.log(e);
				}
			};

			// Delete Groups Update Function
			const deleteUpdate = async () => {
				try {
					const deleteResponse = await fetch(deleteUrl, {
						method: "DELETE",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
					});
					if (deleteResponse.status === 202) {
						// console.log("Shop Deletd!");
					} else if (deleteResponse.status === 401) {
						handleSessionOpen();
					}
				} catch (e) {
					console.log(e);
				}
			};
			return (
				<div>
					{/*sessionModal*/}
					<SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen} />

					{/*Edit Group Modal*/}
					<Modal className="container container-fluid" open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
						<div
							style={{
								width: "553px",
								height: "618px",
								margin: "9% auto 0px auto",
								borderRadius: "15px",
								backgroundColor: "#fff",
							}}
						>
							<div
								style={{
									width: "553x",
									height: "90px",
									margin: "0 0 9px",
									padding: "36px 30px 33px",
									borderRadius: "16px",
									backgroundColor: "#dc3545",
								}}
							>
								<label>Edit a Group</label>
							</div>
							{/*Content Group and Form*/}
							<div
								align="center"
								style={{
									width: "404px",
									height: "414.1px",
									margin: "29px 60px 41.9px 70px",
								}}
							>
								<form onSubmit={update}>
									{/*Serial No.*/}
									<label
										style={{
											height: "20px",
											margin: "0 340px 10px 0",
											fontFamily: "Poppins",
											fontSize: "14px",
											fontWeight: "normal",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "3.14",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#6e7074",
										}}
									>
										Serial No.
									</label>
									<input
										required
										value={groupName}
										onChange={(e) => setGroupName(e.target.value)}
										placeholder="Serial"
										style={{
											width: "404px",
											height: "39px",
											margin: "10px 0 0",
											objectFit: "contain",
											borderRadius: "4px",
											border: "solid 1px #dfdede",
											backgroundColor: "#fff",
										}}
									/>

									{/*POS createdAt*/}
									<label
										style={{
											width: "100px",
											height: "20px",
											margin: "0 328px 10px 0",
											fontFamily: "Poppins",
											fontSize: "14px",
											fontWeight: "normal",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "3.14",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#6e7074",
										}}
									>
										POS createdAt.
									</label>
									<input
										required
										value={createdAt}
										onChange={(e) => setCreatedAt(e.target.value)}
										placeholder="createdAt"
										style={{
											width: "404px",
											height: "39px",
											margin: "10px 0 0",
											objectFit: "contain",
											borderRadius: "4px",
											border: "solid 1px #dfdede",
											backgroundColor: "#fff",
										}}
									/>

									{/*PayBill*/}
									<label
										style={{
											width: "110px",
											height: "20px",
											margin: "0 304px 10px 0",
											fontFamily: "Poppins",
											fontSize: "14px",
											fontWeight: "normal",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "3.14",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#6e7074",
										}}
									>
										Pay Bill/Till No.
									</label>
									<input
										required
										value={businessOwnerName}
										onChange={(e) => setbusinessOwnerName(e.target.value)}
										placeholder="businessOwnerName"
										style={{
											width: "404px",
											height: "39px",
											margin: "10px 0 0",
											objectFit: "contain",
											borderRadius: "4px",
											border: "solid 1px #dfdede",
											backgroundColor: "#fff",
										}}
									/>

									{/*Description*/}
									<label
										style={{
											width: "78px",
											height: "20px",
											margin: "0 324px 10px 0",
											fontFamily: "Poppins",
											fontSize: "14px",
											fontWeight: "normal",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "3.14",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#6e7074",
										}}
									>
										Description
									</label>
									<input
										required
										value={businessOwnerEmail}
										onChange={(e) => setbusinessOwnerEmail(e.target.value)}
										placeholder="Description"
										style={{
											width: "404px",
											height: "39px",
											margin: "10px 0 0",
											objectFit: "contain",
											borderRadius: "4px",
											border: "solid 1px #dfdede",
											backgroundColor: "#fff",
										}}
									/>

									{/*Buttons*/}
									<div align="right">
										{/*  Close  */}
										<Button
											onClick={handleClose}
											variant="outlined"
											style={{
												fontFamily: "Poppins",
												fontSize: "11px",
												textAlign: "left",
												color: "#032541",
												width: "90px",
												height: "33.1px",
												margin: "30px 30px 0 0",
												padding: "9px 29px 8.1px 30px",
												border: "solid 1px #032541",
											}}
										>
											Close
										</Button>
										{/*  save  */}
										<Button
											type="submit"
											variant="standard"
											style={{
												color: "#fff",
												fontFamily: "Poppins",
												fontSize: "11px",
												width: "90px",
												height: "33.1px",
												margin: "30px 0 0 30px",
												padding: "9px 32px 8.1px 31px",
												backgroundColor: "#032541",
											}}
										>
											Save
										</Button>
									</div>
								</form>
							</div>
						</div>
					</Modal>

					{/*Activate Group Modal*/}
					<Modal centered align={"center"} className="container container-fluid" open={ActivateModalOpen} onClose={ActivateModalHandleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
						<div
							style={{
								width: "553px",
								height: "306px",
								margin: "10% 23px 8px 39px",
								padding: "0 0 57.9px",
								borderRadius: "15px",
								backgroundColor: "#fff",
							}}
						>
							<div
								style={{
									width: "553px",
									height: "90px",
									margin: "0 0 50px",
									padding: "35px 36px",
									borderRadius: "16px",
									backgroundColor: "#dc3545",
								}}
							>
								<label
									style={{
										width: "140px",
										height: "20px",
										margin: "0 325px 0 0",
										fontFamily: "Poppins",
										fontSize: "14px",
										fontWeight: "600",
										fontStretch: "normal",
										fontStyle: "normal",
										lineHeight: "normal",
										letterSpacing: "normal",
										textAlign: "left",
										color: "#fff",
									}}
								>
									Activate Group
								</label>
							</div>
							<div>
								<form onSubmit={activateUpdate}>
									<img
										style={{
											width: "70.4px",
											height: "70.4px",
											objectFit: "contain",
										}}
										src={activateGroup}
										alt={"activate Group"}
									/>
									<label
										style={{
											height: "24px",
											margin: "0 1.1px 0 14px",
											fontFamily: "Poppins",
											fontSize: "17px",
											fontWeight: "500",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "1.47",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#032541",
										}}
									>
										Do you want to Activate <b>{row.businessName}?</b>
									</label>
									<div align="right">
										<Button
											onClick={ActivateModalHandleClose}
											variant="outlined"
											style={{
												fontFamily: "Poppins",
												fontSize: "11px",
												textAlign: "left",
												color: "#dc3545",
												width: "90px",
												height: "33.1px",
												margin: "30px 30px 0 0",
												padding: "9px 29px 8.1px 30px",
												border: "solid 1px #dc3545",
											}}
										>
											Cancel
										</Button>
										{/*  save  */}
										<Button
											type="submit"
											variant="standard"
											style={{
												color: "#fff",
												fontFamily: "Poppins",
												fontSize: "11px",
												width: "90px",
												height: "33.1px",
												margin: "30px 16px 0 30px",
												padding: "9px 32px 8.1px 31px",
												backgroundColor: "#032541",
											}}
										>
											Activate
										</Button>
									</div>
								</form>
							</div>
						</div>
					</Modal>

					{/*Deactivate Group Modal*/}
					<Modal centered align={"center"} className="container container-fluid" open={deactivateModalOpen} onClose={DeactivateModalHandleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
						<div
							style={{
								width: "553px",
								height: "306px",
								margin: "10% 23px 8px 39px",
								padding: "0 0 57.9px",
								borderRadius: "15px",
								backgroundColor: "#fff",
							}}
						>
							<div
								style={{
									width: "553px",
									height: "90px",
									margin: "0 0 50px",
									padding: "35px 36px",
									borderRadius: "16px",
									backgroundColor: "#dc3545",
								}}
							>
								<label
									style={{
										height: "20px",
										margin: "0 325px 0 0",
										fontFamily: "Poppins",
										fontSize: "14px",
										fontWeight: "600",
										fontStretch: "normal",
										fontStyle: "normal",
										lineHeight: "normal",
										letterSpacing: "normal",
										textAlign: "left",
										color: "#fff",
									}}
								>
									Deactivate Group
								</label>
							</div>
							<div>
								<form onSubmit={deactivateUpdate}>
									<img
										style={{
											width: "70.4px",
											height: "70.4px",
											objectFit: "contain",
										}}
										src={deactivateGroup}
										alt={"activate Group"}
									/>
									<label
										style={{
											height: "24px",
											margin: "0 1.1px 0 14px",
											fontFamily: "Poppins",
											fontSize: "17px",
											fontWeight: "500",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "1.47",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#032541",
										}}
									>
										Do you want to Deactivate <b>{row.businessName}?</b>
									</label>
									<div align="right">
										<Button
											onClick={DeactivateModalHandleClose}
											variant="outlined"
											style={{
												fontFamily: "Poppins",
												fontSize: "11px",
												textAlign: "left",
												color: "#dc3545",
												width: "90px",
												height: "33.1px",
												margin: "30px 30px 0 0",
												padding: "9px 29px 8.1px 30px",
												border: "solid 1px #dc3545",
											}}
										>
											Cancel
										</Button>
										{/*  save  */}
										<Button
											type="submit"
											variant="standard"
											style={{
												color: "#fff",
												fontFamily: "Poppins",
												fontSize: "11px",
												width: "90px",
												height: "33.1px",
												margin: "30px 16px 0 30px",
												padding: "9px 32px 8.1px 31px",
												backgroundColor: "#032541",
											}}
										>
											Deactivate
										</Button>
									</div>
								</form>
							</div>
						</div>
					</Modal>

					{/*Deactivation Request Group Modal*/}
					<Modal centered align={"center"} className="container container-fluid" open={deactivateReqModalOpen} onClose={DeactivateReqModalHandleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
						<div
							style={{
								width: "665px",
								height: "604px",
								margin: "10% 47px 5px 96px",
								padding: "0 0 9px",
								borderRadius: "15px",
								backgroundColor: "#fff",
							}}
						>
							<div
								style={{
									width: "665px",
									height: "90px",
									margin: "0 0 3px",
									padding: "35px 30px 34px",
									borderRadius: "16px",
									backgroundColor: "#dc3545",
								}}
							>
								<label
									style={{
										height: "20px",
										margin: "0 325px 0 0",
										fontFamily: "Poppins",
										fontSize: "14px",
										fontWeight: "600",
										fontStretch: "normal",
										fontStyle: "normal",
										lineHeight: "normal",
										letterSpacing: "normal",
										textAlign: "left",
										color: "#fff",
									}}
								>
									Deactivate Group Shop Group
								</label>
							</div>
							<form onSubmit={deactivateReqUpdate}>
								<div>
									<div>
										<label
											style={{
												eight: "44px",
												margin: "48px 106px 37px 84px",
												fontFamily: "Poppins",
												fontSize: "15px",
												fontWeight: "normal",
												fontStretch: "normal",
												fontStyle: "normal",
												lineHeight: "normal",
												letterSpacing: "normal",
												textAlign: "left",
												color: "#032541",
											}}
										>
											To deactivate Group <b>{row.businessName}</b>, Select the Reason and describe why you are deactivating.
										</label>
									</div>
									<label
										style={{
											width: "41px",
											height: "16px",
											margin: "0px 537px 10px 84px",
											fontFamily: "Poppins",
											fontSize: "11px",
											fontWeight: "normal",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "4",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#6e7074",
										}}
									>
										Reason
									</label>
									<Select
										value={reasonCode}
										onChange={(e) => setReasonCode(e.target.value)}
										defaultValue=""
										id="grouped-select"
										label="Grouping"
										sx={{
											fontFamily: "Poppins",
											fontSize: "12px",
											textAlign: "left",
											width: "496px",
											height: "39px",
											margin: "10px 85px 20px 84px",
											objectFit: "contain",
											borderRadius: "4px",
											border: "solid 1px #dfdede",
											backgroundColor: "#fff",
										}}
									>
										<MenuItem value="">
											<em>None</em>
										</MenuItem>
										{reasonData.map((reason) => (
											<MenuItem
												sx={{
													fontFamily: "Poppins",
													fontSize: "12px",
													textAlign: "left",
												}}
												key={reason.code}
												value={reason.code}
											>
												{reason.code} {reason.name}
											</MenuItem>
										))}
									</Select>
									<label
										style={{
											width: "62px",
											height: "16px",
											margin: "0px 537px 10px 84px",
											fontFamily: "Poppins",
											fontSize: "11px",
											fontWeight: "normal",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "4",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#6e7074",
										}}
									>
										Description
									</label>
									<textarea
										id="w3review"
										name="w3review"
										rows="4"
										cols="50"
										placeholder="Enter Description"
										style={{
											width: "505px",
											height: "106px",
											margin: "10px 76px 43px 84px",
											objectFit: "contain",
											borderRadius: "4px",
											border: "solid 1px #dfdede",
											backgroundColor: "#fff",
										}}
									></textarea>
									<div align="right">
										<Button
											onClick={DeactivateReqModalHandleClose}
											variant="outlined"
											style={{
												fontSize: "11px",
												color: "#032541",
												width: "90px",
												height: "33.1px",
												margin: "0px 30px 62.9px 13px",
												padding: "9px 29px 8.1px 30px",
												border: "solid 1px #032541",
											}}
										>
											Cancel
										</Button>
										{/*  save  */}
										<Button
											type="submit"
											variant="standard"
											style={{
												fontSize: "11px",
												color: "#fff",
												width: "90px",
												height: "33.1px",
												margin: "0px 76px 62.9px 30px",
												padding: "9px 15px 8.1px 15px",
												backgroundColor: "#dc3545",
											}}
										>
											Deactivate
										</Button>
									</div>
								</div>
							</form>
						</div>
					</Modal>

					{/*Delete Group Modal*/}
					<Modal centered align={"center"} className="container container-fluid" open={deleteModalOpen} onClose={DeleteModalHandleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
						<div
							style={{
								width: "553px",
								height: "306px",
								margin: "10% 23px 8px 39px",
								padding: "0 0 57.9px",
								borderRadius: "15px",
								backgroundColor: "#fff",
							}}
						>
							<div
								style={{
									width: "553px",
									height: "90px",
									margin: "0 0 50px",
									padding: "35px 36px",
									borderRadius: "16px",
									backgroundColor: "#dc3545",
								}}
							>
								<label
									style={{
										height: "20px",
										margin: "0 325px 0 0",
										fontFamily: "Poppins",
										fontSize: "14px",
										fontWeight: "600",
										fontStretch: "normal",
										fontStyle: "normal",
										lineHeight: "normal",
										letterSpacing: "normal",
										textAlign: "left",
										color: "#fff",
									}}
								>
									Delete Group
								</label>
							</div>
							<div>
								<form onSubmit={deleteUpdate}>
									<img
										style={{
											width: "70.4px",
											height: "70.4px",
											objectFit: "contain",
										}}
										src={deactivateGroup}
										alt={"activate Group"}
									/>
									<label
										style={{
											height: "24px",
											margin: "0 1.1px 0 14px",
											fontFamily: "Poppins",
											fontSize: "17px",
											fontWeight: "500",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "1.47",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#032541",
										}}
									>
										Do you want to delete <b>{row.businessName}?</b>
									</label>
									<div align="right">
										<Button
											onClick={DeleteModalHandleClose}
											variant="outlined"
											style={{
												fontFamily: "Poppins",
												fontSize: "11px",
												textAlign: "left",
												color: "#032541",
												width: "90px",
												height: "33.1px",
												margin: "30px 30px 0 0",
												padding: "9px 29px 8.1px 30px",
												border: "solid 1px #032541",
											}}
										>
											Cancel
										</Button>
										{/*  save  */}
										<Button
											type="submit"
											variant="standard"
											style={{
												color: "#fff",
												fontFamily: "Poppins",
												fontSize: "11px",
												width: "90px",
												height: "33.1px",
												margin: "30px 16px 0 30px",
												padding: "9px 32px 8.1px 31px",
												backgroundColor: "#dc3545",
											}}
										>
											Delete
										</Button>
									</div>
								</form>
							</div>
						</div>
					</Modal>

					{/*Status Update Modals*/}
					{/*Success Deactivation Modal*/}
					<SuccessModal onClose={successDeactivationModalHandleClose} editConfigOpen={successDeactivationModalOpen} modalRow={row.businessName} successObject={"Business"} successType={"Deactivated"} />

					{/*Success Deletion Modal*/}
					<SuccessModal onClose={successDeletionModalHandleClose} editConfigOpen={successDeletionModalOpen} modalRow={row.businessName} successObject={"Business"} successType={"Deleted"} />

					{/*Success Activation Modal*/}
					<SuccessModal onClose={successActivationModalHandleClose} editConfigOpen={successActivationModalOpen} modalRow={row.businessName} successObject={"Business"} successType={"Activated"} />

					{/*Action Button Menu*/}
					<Button sx={{ backgroundColor: "#dc3545" }} id="demo-customized-button" aria-controls={ActionOpen ? "demo-customized-menu" : undefined} aria-haspopup="true" aria-expanded={ActionOpen ? "true" : undefined} variant="contained" disableElevation onClick={handleClick} endIcon={<KeyboardArrowDownIcon />}>
						Action
					</Button>
					<StyledMenu id="demo-customized-menu" MenuListProps={{ "aria-labelledby": "demo-customized-button" }} anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
						{/*Conditional Modal Popups*/}
						{/*Check if is on Active Tab*/}
						<If condition={localStorage.getItem("tabValue") === "Active"}>
							<MenuItem
								disableRipple
								onClick={() => {
									handleClickOpen();
									handleActionClose();
								}}
							>
								<EditIcon sx={{ color: "black" }} />
								Edit
							</MenuItem>
							<MenuItem
								onClick={() => {
									DeactivateModalHandleOpen();
									handleActionClose();
								}}
								disableRipple
							>
								<PersonRemoveIcon />
								Deactivate
							</MenuItem>

							{/*Check if is on New Tab*/}
							<ElIf condition={localStorage.getItem("tabValue") === "New"}>
								<MenuItem
									disableRipple
									onClick={() => {
										ActivateModalHandleOpen();
										handleActionClose();
									}}
								>
									<EditIcon sx={{ color: "black" }} />
									Approve
								</MenuItem>
								<MenuItem
									onClick={() => {
										DeactivateModalHandleOpen();
										handleActionClose();
									}}
									disableRipple
								>
									<PersonRemoveIcon />
									Decline
								</MenuItem>
							</ElIf>

							{/*Check if is on Inactive Tab*/}
							<ElIf condition={localStorage.getItem("tabValue") === "Inactive"}>
								<MenuItem
									disableRipple
									onClick={() => {
										ActivateModalHandleOpen();
										handleActionClose();
									}}
								>
									<EditIcon sx={{ color: "black" }} />
									Activate
								</MenuItem>
								<MenuItem
									onClick={() => {
										DeleteModalHandleOpen();
										handleActionClose();
									}}
									disableRipple
								>
									<PersonRemoveIcon />
									Delete
								</MenuItem>
							</ElIf>

							{/*Check if is on Pending Tab*/}
							<ElIf condition={localStorage.getItem("tabValue") === "Pending"}>
								<MenuItem
									disableRipple
									onClick={() => {
										handleClickOpen();
										handleActionClose();
									}}
								>
									<EditIcon sx={{ color: "black" }} />
									Activate
								</MenuItem>
								<MenuItem
									onClick={() => {
										handleActionClose();
									}}
									disableRipple
								>
									<PersonRemoveIcon />
									Delete
								</MenuItem>
							</ElIf>

							{/*Check if is on Deactivation Tab*/}
							<ElIf condition={localStorage.getItem("tabValue") === "Deactivation"}>
								<MenuItem
									disableRipple
									onClick={() => {
										DeactivateReqModalHandleOpen();
										handleActionClose();
									}}
								>
									<EditIcon sx={{ color: "black" }} />
									Approve
								</MenuItem>
								<MenuItem
									onClick={() => {
										ActivateModalHandleOpen();
										handleActionClose();
									}}
									disableRipple
								>
									<PersonRemoveIcon />
									Decline
								</MenuItem>
							</ElIf>
						</If>
					</StyledMenu>
				</div>
			);
		},
	});
}

// Deactivation request Columns
const deactivationColumns = [
	{
		field: "businessName",
		headerName: "Business Name",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "businessNumber",
		headerName: "Business ID",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "businessOwnerName",
		headerName: "businessOwnerName",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "businessOwnerEmail",
		headerName: "Email",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "businessOwnerPhone",
		headerName: "Phone",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "initialSerialNumber",
		headerName: "Initial Serial",
		headerAlign: "left",
		align: "left",
		editable: false,
		width: 300,
	},
	{
		field: "requestedBy",
		headerName: "Requested By",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "deactivatedAt",
		headerName: "Date Deactivated",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
];

if (localStorage.getItem("group") === "Admin") {
	deactivationColumns.push({
		field: "Actions",
		headerName: "Actions",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
		renderCell: (params, event) => {
			//DropDown Handler
			const [anchorEl, setAnchorEl] = React.useState(null);
			const ActionOpen = Boolean(anchorEl);
			const handleClick = (event) => {
				setAnchorEl(event.currentTarget);
			};
			const handleActionClose = () => {
				setAnchorEl(null);
			};

			const { row } = params;

			// session Modal Config
			const [sessionOpen, setSessionOpen] = React.useState(false);
			const handleSessionOpen = () => setSessionOpen(true);
			const handleSessionClose = () => setSessionOpen(false);
			const handleLogin = () => {
				window.location.href = "/logout";
			};

			// Activate Group Modal Config
			const [ActivateModalOpen, setActivateModalOpen] = useState(false);
			const ActivateModalHandleOpen = () => setActivateModalOpen(true);
			const ActivateModalHandleClose = () => setActivateModalOpen(false);

			// Deactivate Group Modal Config
			const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
			const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
			const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

			// DeactivateRequest Group Modal Config
			const [deactivateReqModalOpen, setDeactivateReqModalOpen] = useState(false);
			const DeactivateReqModalHandleOpen = () => setDeactivateReqModalOpen(true);
			const DeactivateReqModalHandleClose = () => setDeactivateReqModalOpen(false);

			// Status Updates Modals
			// Success Activation Modal Config
			const [successActivationModalOpen, setSuccessActivationModalOpen] = useState(false);
			const successActivationModalHandleOpen = () => setSuccessActivationModalOpen(true);
			const successActivationModalHandleClose = () => setSuccessActivationModalOpen(false);

			// Success Deactivation Modal Config
			const [successDeactivationModalOpen, setSuccessDeactivationModalOpen] = useState(false);
			const successDeactivationModalHandleOpen = () => setSuccessDeactivationModalOpen(true);
			const successDeactivationModalHandleClose = () => setSuccessDeactivationModalOpen(false);

			//Group Update Url
			const updateUrl = baseUrl + "/api/updateBusiness/" + row.id;

			//value Setters and Getters
			const [reasonData, setReasonData] = useState([]);
			const [reasonCode, setReasonCode] = useState("");
			const [reasonDescription, setReasonDescription] = useState("");

			//Reason Update Url
			const reasonUrl = baseUrl + "/api/reasons";

			// Getting Reason Codes
			const getReasonCodes = async () => {
				try {
					const response = await fetch(reasonUrl, {
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
					});
					const data = await response.json();
					if (response.status === 200) {
						setReasonData(data.reasons);
					} else if (response.status === 401) {
						handleSessionOpen();
					}
				} catch (e) {
					console.log(e);
				}
			};
			useEffect(() => {
				getReasonCodes();
			}, []);

			// Activate Groups Update Function
			const activateUpdate = async (e) => {
				e.preventDefault();
				try {
					const activateResponse = await fetch(updateUrl, {
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
						body: JSON.stringify({
							businessState: "Active",
							modifiedAt: Date.now(),
							requestedBy: localStorage.getItem("businessOwnerName"),
						}),
					});
					if (activateResponse.status === 202) {
						ActivateModalHandleClose();
						successActivationModalHandleOpen();
					} else if (activateResponse.status === 401) {
						handleSessionOpen();
					}
				} catch (e) {
					console.log(e);
				}
			};

			// Deactivate Groups Update Function
			const deactivateUpdate = async (e) => {
				e.preventDefault();
				try {
					const deactivateResponse = await fetch(updateUrl, {
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
						body: JSON.stringify({
							businessState: "Deactivation",

							requestedBy: localStorage.getItem("username"),
							deactivatedAt: Date.now(),
							approvedBy: localStorage.getItem("businessOwnerName"),
						}),
					});
					if (deactivateResponse.status === 202) {
						DeactivateModalHandleClose();
						successDeactivationModalHandleOpen();
					} else if (deactivateResponse.status === 401) {
						handleSessionOpen();
					}
				} catch (e) {
					console.log(e);
				}
			};

			// Deactivate Request Groups Update Function
			const deactivateReqUpdate = async (e) => {
				e.preventDefault();
				try {
					const deactivateResponse = await fetch(updateUrl, {
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
						body: JSON.stringify({
							businessState: "Inactive",
							deactivationCode: reasonCode,
							deactivationDescription: reasonDescription,
							deactivatedAt: Date.now(),
							approvedBy: localStorage.getItem("businessOwnerName"),
						}),
					});
					if (deactivateResponse.status === 202) {
						DeactivateReqModalHandleClose();
						successDeactivationModalHandleOpen();
					} else if (deactivateResponse.status === 401) {
						handleSessionOpen();
					}
				} catch (e) {
					console.log(e);
				}
			};

			return (
				<div>
					{/*sessionModal*/}
					<SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen} />

					{/*Activate Modal*/}
					<QuestionModal deactivateModalOpen={ActivateModalOpen} onClose={ActivateModalHandleClose} formAction={activateUpdate} actionImage={activateGroup} action="Activate" item={row.businessName} processName={"Activate"} />

					{/*Activate Modal*/}
					<QuestionModal deactivateModalOpen={deactivateModalOpen} onClose={DeactivateModalHandleClose} formAction={deactivateUpdate} actionImage={deactivateGroup} action="Deactivate" item={row.businessName} processName={"Deactivate"} />

					{/*Deactivation Request Group Modal*/}
					<Modal centered align={"center"} className="container container-fluid" open={deactivateReqModalOpen} onClose={DeactivateReqModalHandleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
						<div
							style={{
								width: "665px",
								height: "604px",
								margin: "10% 47px 5px 96px",
								padding: "0 0 9px",
								borderRadius: "15px",
								backgroundColor: "#fff",
							}}
						>
							<div
								style={{
									width: "665px",
									height: "90px",
									margin: "0 0 3px",
									padding: "35px 30px 34px",
									borderRadius: "16px",
									backgroundColor: "#dc3545",
								}}
							>
								<label
									style={{
										height: "20px",
										margin: "0 325px 0 0",
										fontFamily: "Poppins",
										fontSize: "14px",
										fontWeight: "600",
										fontStretch: "normal",
										fontStyle: "normal",
										lineHeight: "normal",
										letterSpacing: "normal",
										textAlign: "left",
										color: "#fff",
									}}
								>
									Deactivate Group Shop Group
								</label>
							</div>
							<form onSubmit={deactivateReqUpdate}>
								<div>
									<div>
										<label
											style={{
												eight: "44px",
												margin: "48px 106px 37px 84px",
												fontFamily: "Poppins",
												fontSize: "15px",
												fontWeight: "normal",
												fontStretch: "normal",
												fontStyle: "normal",
												lineHeight: "normal",
												letterSpacing: "normal",
												textAlign: "left",
												color: "#032541",
											}}
										>
											To deactivate Group <b>{row.businessName}</b>, Select the Reason and describe why you are deactivating.
										</label>
									</div>
									<label
										style={{
											width: "41px",
											height: "16px",
											margin: "0px 537px 10px 84px",
											fontFamily: "Poppins",
											fontSize: "11px",
											fontWeight: "normal",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "4",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#6e7074",
										}}
									>
										Reason
									</label>
									{/*<Select defaultValue="" id="grouped-select" label="Grouping" sx={{width : "496px" , height : "39px" , margin : "10px 85px 20px 84px" , objectFit : "contain" , borderRadius : "4px" , border : "solid 1px #dfdede" , backgroundColor : "#fff"}}>*/}
									<Select
										value={reasonCode}
										onChange={(e) => setReasonCode(e.target.value)}
										defaultValue=""
										id="grouped-select"
										label="Grouping"
										sx={{
											fontFamily: "Poppins",
											fontSize: "12px",
											textAlign: "left",
											width: "496px",
											height: "39px",
											margin: "10px 85px 20px 84px",
											objectFit: "contain",
											borderRadius: "4px",
											border: "solid 1px #dfdede",
											backgroundColor: "#fff",
										}}
									>
										<MenuItem value="">
											<em>None</em>
										</MenuItem>
										{reasonData.map((reason) => (
											<MenuItem
												sx={{
													fontFamily: "Poppins",
													fontSize: "12px",
													textAlign: "left",
												}}
												key={reason.code}
												value={reason.code}
											>
												{reason.code} {reason.name}
											</MenuItem>
										))}
									</Select>
									<label
										style={{
											width: "62px",
											height: "16px",
											margin: "0px 537px 10px 84px",
											fontFamily: "Poppins",
											fontSize: "11px",
											fontWeight: "normal",
											fontStretch: "normal",
											fontStyle: "normal",
											lineHeight: "4",
											letterSpacing: "normal",
											textAlign: "left",
											color: "#6e7074",
										}}
									>
										Description
									</label>
									<textarea
										value={reasonDescription}
										onChange={(e) => setReasonDescription(e.target.value)}
										id="w3review"
										name="w3review"
										rows="4"
										cols="50"
										placeholder="Enter Description"
										style={{
											padding: "10px",
											fontFamily: "Poppins",
											fontSize: "12px",
											textAlign: "left",
											width: "505px",
											height: "106px",
											margin: "10px 76px 43px 84px",
											objectFit: "contain",
											borderRadius: "4px",
											border: "solid 1px #dfdede",
											backgroundColor: "#fff",
										}}
									></textarea>
									{/*<textarea id="w3review" name="w3review" rows="4" cols="50" placeholder='Enter Description' style={{width : "505px" , height : "106px" , margin : "10px 76px 43px 84px" , objectFit : "contain" , borderRadius : "4px" , border : "solid 1px #dfdede" , backgroundColor : "#fff"}}></textarea>*/}
									<div align="right">
										<Button
											onClick={DeactivateReqModalHandleClose}
											variant="outlined"
											style={{
												fontSize: "11px",
												color: "#032541",
												width: "90px",
												height: "33.1px",
												margin: "0px 30px 62.9px 13px",
												padding: "9px 29px 8.1px 30px",
												border: "solid 1px #032541",
											}}
										>
											Cancel
										</Button>
										{/*  save  */}
										<Button
											type="submit"
											variant="standard"
											style={{
												fontSize: "11px",
												color: "#fff",
												width: "90px",
												height: "33.1px",
												margin: "0px 76px 62.9px 30px",
												padding: "9px 15px 8.1px 15px",
												backgroundColor: "#dc3545",
											}}
										>
											Deactivate
										</Button>
									</div>
								</div>
							</form>
						</div>
					</Modal>

					{/*Status Update Modals*/}
					{/*Success Deactivation Modal*/}
					<SuccessModal onClose={successDeactivationModalHandleClose} editConfigOpen={successDeactivationModalOpen} modalRow={row.businessName} successObject={"Business"} successType={"Deactivated"} />

					{/*Success Activation Modal*/}
					<SuccessModal onClose={successActivationModalHandleClose} editConfigOpen={successActivationModalOpen} modalRow={row.businessName} successObject={"Business"} successType={"Activated"} />

					{/*Action Button Menu*/}
					<Button sx={{ backgroundColor: "#dc3545" }} id="demo-customized-button" aria-controls={ActionOpen ? "demo-customized-menu" : undefined} aria-haspopup="true" aria-expanded={ActionOpen ? "true" : undefined} variant="contained" disableElevation onClick={handleClick} endIcon={<KeyboardArrowDownIcon />}>
						Action
					</Button>
					<StyledMenu id="demo-customized-menu" MenuListProps={{ "aria-labelledby": "demo-customized-button" }} anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
						<MenuItem
							disableRipple
							onClick={() => {
								DeactivateReqModalHandleOpen();
								handleActionClose();
							}}
						>
							<EditIcon sx={{ color: "black" }} />
							Approve
						</MenuItem>
						<MenuItem
							onClick={() => {
								ActivateModalHandleOpen();
								handleActionClose();
							}}
							disableRipple
						>
							<PersonRemoveIcon />
							Decline
						</MenuItem>
					</StyledMenu>
				</div>
			);
		},
	});
}

// Inactive Columns
const inactiveColumns = [
	// { field: 'id', headerName: 'ID' },
	{
		field: "businessName",
		headerName: "Business Name",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "businessNumber",
		headerName: "Business ID",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "businessOwnerName",
		headerName: "businessOwnerName",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "businessOwnerEmail",
		headerName: "Email",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "businessOwnerPhone",
		headerName: "Phone",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "initialSerialNumber",
		headerName: "Initial Serial",
		headerAlign: "left",
		align: "left",
		editable: false,
		width: 300,
	},
	{
		field: "deactivatedBy",
		headerName: "Requested By",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "requestedBy",
		headerName: "Requested By",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "deactivatedAt",
		headerName: "Date Deactivated",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},
	{
		field: "approvedBy",
		headerName: "Approved By",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
	},

	// Action Button.
];

if (localStorage.getItem("group") === "Admin") {
	inactiveColumns.push({
		field: "Actions",
		headerName: "Actions",
		flex: 1,
		headerAlign: "center",
		align: "center",
		editable: false,
		renderCell: (params, event) => {
			//DropDown Handler
			const [anchorEl, setAnchorEl] = React.useState(null);
			const ActionOpen = Boolean(anchorEl);
			const handleClick = (event) => {
				setAnchorEl(event.currentTarget);
			};
			const handleActionClose = () => {
				setAnchorEl(null);
			};

			const { row } = params;

			// session Modal Config
			const [sessionOpen, setSessionOpen] = React.useState(false);
			const handleSessionOpen = () => setSessionOpen(true);
			const handleSessionClose = () => setSessionOpen(false);
			const handleLogin = () => {
				window.location.href = "/logout";
			};

			// Activate Group Modal Config
			const [ActivateModalOpen, setActivateModalOpen] = useState(false);
			const ActivateModalHandleOpen = () => setActivateModalOpen(true);
			const ActivateModalHandleClose = () => setActivateModalOpen(false);

			// Delete Group Modal Config
			const [deleteModalOpen, setDeleteModalOpen] = useState(false);
			const DeleteModalHandleOpen = () => setDeleteModalOpen(true);
			const DeleteModalHandleClose = () => setDeleteModalOpen(false);

			// Status Updates Modals
			// Success Activation Modal Config
			const [successActivationModalOpen, setSuccessActivationModalOpen] = useState(false);
			const successActivationModalHandleOpen = () => setSuccessActivationModalOpen(true);
			const successActivationModalHandleClose = () => setSuccessActivationModalOpen(false);

			// Success Deletion Modal Config
			const [successDeletionModalOpen, setSuccessDeletionModalOpen] = useState(false);
			const successDeletionModalHandleOpen = () => setSuccessDeletionModalOpen(true);
			const successDeletionModalHandleClose = () => setSuccessDeletionModalOpen(false);

			//Group Update Url
			const updateUrl = baseUrl + "/api/updateBusiness/" + row.id;
			const deleteUrl = baseUrl + "/api/deleteBusiness/" + row.id;

			// Activate Groups Update Function
			const activateUpdate = async (e) => {
				e.preventDefault();
				try {
					const activateResponse = await fetch(updateUrl, {
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
						body: JSON.stringify({
							businessState: "Active",
							modifiedAt: Date.now(),
							requestedBy: localStorage.getItem("businessOwnerName"),
						}),
					});
					if (activateResponse.status === 202) {
						ActivateModalHandleClose();
						successActivationModalHandleOpen();
					} else if (activateResponse.status === 401) {
						handleSessionOpen();
					}
				} catch (e) {
					console.log(e);
				}
			};

			// Delete Groups Update Function
			const deleteUpdate = async (e) => {
				e.preventDefault();
				try {
					const deleteResponse = await fetch(deleteUrl, {
						method: "DELETE",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							"X-Authorization": localStorage.getItem("X-Authorization"),
						},
					});
					if (deleteResponse.status === 202) {
						DeleteModalHandleClose();
						successDeletionModalHandleOpen();
					} else if (deleteResponse.status === 401) {
						handleSessionOpen();
					}
				} catch (e) {
					console.log(e);
				}
			};

			return (
				<div>
					{/*sessionModal*/}
					<SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen} />

					{/*Activate Modal*/}
					<QuestionModal deactivateModalOpen={ActivateModalOpen} onClose={ActivateModalHandleClose} formAction={activateUpdate} actionImage={activateGroup} action="Activate" item={row.businessName} processName={"Activate"} />

					{/*Delete Modal*/}
					<QuestionModal deactivateModalOpen={deleteModalOpen} onClose={DeleteModalHandleClose} formAction={deleteUpdate} actionImage={deactivateGroup} action="Delete" item={row.businessName} processName={"Delete"} />

					{/*Success Deletion Modal*/}
					<SuccessModal onClose={successDeletionModalHandleClose} editConfigOpen={successDeletionModalOpen} modalRow={row.businessName} successObject={"Business"} successType={"Deleted"} />

					{/*Success Activation Modal*/}
					<SuccessModal onClose={successActivationModalHandleClose} editConfigOpen={successActivationModalOpen} modalRow={row.businessName} successObject={"Business"} successType={"Activated"} />

					{/*Action Button Menu*/}
					<Button sx={{ backgroundColor: "#dc3545" }} id="demo-customized-button" aria-controls={ActionOpen ? "demo-customized-menu" : undefined} aria-haspopup="true" aria-expanded={ActionOpen ? "true" : undefined} variant="contained" disableElevation onClick={handleClick} endIcon={<KeyboardArrowDownIcon />}>
						Action
					</Button>
					<StyledMenu id="demo-customized-menu" MenuListProps={{ "aria-labelledby": "demo-customized-button" }} anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
						{/*Check if is on Inactive Tab*/}
						<MenuItem
							disableRipple
							onClick={() => {
								ActivateModalHandleOpen();
								handleActionClose();
							}}
						>
							<EditIcon sx={{ color: "black" }} />
							Activate
						</MenuItem>
						<MenuItem
							onClick={() => {
								DeleteModalHandleOpen();
								handleActionClose();
							}}
							disableRipple
						>
							<PersonRemoveIcon />
							Delete
						</MenuItem>
					</StyledMenu>
				</div>
			);
		},
	});
}

const Shops = () => {
	//const auth_token = localStorage.getItem("X-Authorization");

	// session Modal Config
	const navigate = useNavigate();
	const [sessionOpen, setSessionOpen] = React.useState(false);
	const handleSessionOpen = () => setSessionOpen(true);
	const handleSessionClose = () => setSessionOpen(false);

	// Add shop Modal Config
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	// handle tab Changes
	const [tabValue, setTabValue] = React.useState("Active");
	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	//handle adding shop tab changes
	const [tabCreateValue, setTabCreateValue] = React.useState("businessDetails");
	const handleTabCreateChange = (event, newCreateValue) => {
		setTabCreateValue(newCreateValue);
	};

	// Urls
	const TfetchUrl = baseUrl + "/api/unassignedTerminals/Active";
	const fetchUrl = baseUrl + "/api/get_my_businesses";
	const postUrl = baseUrl + "/api/CreateBusiness";
	const terminaUpdate = baseUrl + "/api/bulkUpdateTerminals";

	// Existing terminal Modal config
	const [existingUserModal, setExistingUserModalOpen] = useState(false);
	const existingUserModalHandleOpen = () => setExistingUserModalOpen(true);
	const existingUserModalHandleClose = () => setExistingUserModalOpen(false);

	// Success Modal Control
	const [successOpen, setSuccessOpen] = React.useState(false);
	const handleSuccessOpen = () => setSuccessOpen(true);
	const handleSuccessClose = () => setSuccessOpen(false);

	// Terminals Setters and Getters
	const [terminals, setTerminals] = useState([]);
	const [bulkTerminals, setBulkTerminals] = useState([]);

	// Business Creation Setters and Getters
	const [businessName, setBusinessName] = useState("");
	const [businessOwnerName, setBusinessOwnerName] = useState("");
	const [businessOwnerEmail, setBusinessOwnerEmail] = useState("");
	const [businessOwnerPhone, setBusinessOwnerPhone] = useState("");
	const [businessOwnerAddress, setbusinessOwnerAddress] = useState("");
	const [till_paybill, settill_paybill] = useState("");
	const [initialSerial, setInitialSerial] = useState([]);
	const [businessNumber, setBusinessNumber] = useState("");
	const [till, setTill] = useState("");
	const [payBill, setPayBill] = useState("");
	const [vooma, setVooma] = useState("");
	const [equitel, setEquitel] = useState("");
	const dispatch = useDispatch();

	// Tabs Setters and Getters
	const [activeTab, setActiveTabs] = useState([]);
	const [newTab, setNewTabs] = useState([]);
	const [inactiveTab, setInactiveTab] = useState([]);
	const [pendingTab, setPendingTab] = useState([]);
	const [deactivationTab, setDeactivationTabs] = useState([]);


	//handle search box
	// const [searchValue, setSearchValue] = useState("");

	// Filter data grid by transaction type using select input
	// const [filteredData, setFilteredData] = useState([]);

	// handles clicking next on shop addition
	const handleNext = (event, newValue) => {
		setTabValue(newValue);
	};

	const [dataGridPageSize, setNewPageSize] = useState(10)
	
	const [pageState, setPageState] = useState({
		isLoading: false,
		data: [],
		total: 0,
		page: 1,
		pageSize: dataGridPageSize,
		// search : searchValue
	});

	// Get all Shops based on state
	// Check if Tab is on Active

	// Fetch Active Tabs

	const ActiveTab = async () => {
		if (tabValue === "Active") {
			try {
				// setPageState((old) => ({ ...old, isLoading: true }));
				const ActiveResponse = await fetch(baseUrl + `/api/get_available_businesses?page=${pageState.page}&&limit=${dataGridPageSize}`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
				});
				await ActiveResponse.json().then((response) => {
					setActiveTabs(response.data);
					setPageState((old) => ({
						...old,
						isLoading: false,
						data: response.data,
						total: response.count,
					}));
				});

				if (ActiveResponse.status === 200) {
				} else if (ActiveResponse.status === 401) {
					handleSessionOpen();
				}
			} catch (e) {
				console.log(e);
			}
		}
	};

	useEffect(() => {
		ActiveTab();
	}, [tabValue, pageState.page, dataGridPageSize]);


	// query the database;
	const [searchValue, setSearchValue] = useState("");

	const queryShops = async () => {
		if (tabValue === "Active") {
			try {
				// setPageState((old) => ({ ...old, isLoading: true }));
				const ActiveResponse = await fetch(baseUrl + `/api/get_available_businesses?limit=${dataGridPageSize }&page=${pageState.page}&searchValue=${searchValue}`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
				});
				await ActiveResponse.json().then((response) => {

					setActiveTabs(response.data);
					// console.log(response.data);
					setPageState((old) => ({
						...old,
						isLoading: false,
						data: response.data,
						total: response.count,
					}));
				});


				if (ActiveResponse.status === 200) {
				} else if (ActiveResponse.status === 401) {
					handleSessionOpen();
				}
			} catch (e) {
				console.log(e);
			}
		}
	};

	useEffect(() => {
		queryShops();
	}, [tabValue, pageState.page, dataGridPageSize ,searchValue]);

	// Check if tab is on New
	// Fetch New Tabs yet to be Approved
	const NewTabs = async () => {
		try {
			const newResponse = await fetch(fetchUrl + "/New", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					"X-Authorization": localStorage.getItem("X-Authorization"),
				},
			});
			const data = await newResponse.json();
			if (newResponse.status === 200) {
				setNewTabs(data.data);
				// setFilteredData(data.data)
				localStorage.setItem("tabValue", "New");
			} else if (newResponse.status === 401) {
				handleSessionOpen();
			}
		} catch (e) {
			console.log(e);
		}
	};
	useEffect(() => {
		NewTabs();
	}, [tabValue]);

	// Check if tab is on Pending
	// Fetch Edits Approval Tabs
	const PendingTabs = async () => {
		try {
			const inactiveResponse = await fetch(fetchUrl + "/Pending", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					"X-Authorization": localStorage.getItem("X-Authorization"),
				},
			});
			const data = await inactiveResponse.json();
			if (inactiveResponse.status === 200) {
				setPendingTab(data.data);
				localStorage.setItem("tabValue", "Pending");
			} else if (inactiveResponse.status === 401) {
				handleSessionOpen();
			}
		} catch (e) {
			console.log(e);
		}
	};
	useEffect(() => {
		PendingTabs();
	}, [tabValue]);
	// }

	// Check if tab is on Deactivation

	// Fetch Pending Deactivation Tabs
	const DeactivationTabs = async () => {
		try {
			const inactiveResponse = await fetch(fetchUrl + "/Deactivation", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					"X-Authorization": localStorage.getItem("X-Authorization"),
				},
			});
			const data = await inactiveResponse.json();
			if (inactiveResponse.status === 200) {
				setDeactivationTabs(data.data);
				localStorage.setItem("tabValue", "Deactivation");
			} else if (inactiveResponse.status === 401) {
				handleSessionOpen();
			}
		} catch (e) {
			console.log(e);
		}
	};
	useEffect(() => {
		DeactivationTabs();
	}, [tabValue]);

	// Check if tab is on Inactive
	// Fetch Deactivated Tabs
	const InactiveTabs = async () => {
		try {
			const inactiveResponse = await fetch(fetchUrl + "/Inactive", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					"X-Authorization": localStorage.getItem("X-Authorization"),
				},
			});
			const data = await inactiveResponse.json();
			if (inactiveResponse.status === 200) {
				setInactiveTab(data.data);
				localStorage.setItem("tabValue", "Inactive");
			} else if (inactiveResponse.status === 401) {
				handleSessionOpen();
			}
		} catch (e) {
			console.log(e);
		}
	};
	useEffect(() => {
		InactiveTabs();
	}, [tabValue]);

	// Updating the terminals Data as well
	const updateTerminal = async (biz_id) => {
		try {
			const response = await fetch(baseUrl + "/api/updateTerminal/" + initialSerial, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					"X-Authorization": localStorage.getItem("X-Authorization"),
				},
				body: JSON.stringify({
					businessID: biz_id,
					till_paybill: till_paybill,
				}),
			});
			const data = await response.json();
			// console.log(data);

			if (response.status === 202) {
				handleClose();
			} else if (response.status === 407) {
				handleClose();
				existingUserModalHandleOpen();
			} else if (response.status === 401) {
				handleSessionOpen();
			} else {
				alert("Something went wrong");
			}
		} catch (error) {
			console.log(error);
		}
	};

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
	};

	const handleBulkTerminals = (event) => {
		const {
			target: { value },
		} = event;
		setBulkTerminals(typeof value === "string" ? value.split(",") : value);
	};

	// Add New Shop Handler
	async function addShopHandler(event) {
		event.preventDefault();
		try {
			const response = await fetch(postUrl, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					"X-Authorization": localStorage.getItem("X-Authorization"),
				},
				body: JSON.stringify({
					businessName: businessName,
					businessOwnerName: businessOwnerName,
					businessOwnerEmail: businessOwnerEmail,
					businessOwnerPhone: businessOwnerPhone,
					businessOwnerAddress: businessOwnerAddress,
					Till: till,
					Paybill: payBill,
					Equitel: equitel,
					Vooma: vooma,
					bulkTerminals: bulkTerminals,
				}),
			});
			const data = await response.json();
			// console.log(data)

			if (response.status === 201) {
				// Bulk update terminals
				const updateTerminals = await fetch(terminaUpdate, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
					body: JSON.stringify({
						businessID: data.bid,
						Till: till,
						Paybill: payBill,
						Vooma: vooma,
						Equitel: equitel,
						bulkTerminals: bulkTerminals,
					}),
				});

				setBusinessName("");
				setBusinessOwnerName("");
				setBusinessOwnerEmail("");
				setBusinessOwnerPhone("");
				setbusinessOwnerAddress("");
				setBusinessNumber("");
				setBusinessOwnerPhone("");
				setBulkTerminals("");

				handleClose();
				handleSuccessOpen();
				await addShopHandler();
			} else if (response.status === 505) {
				handleClose();
				existingUserModalHandleOpen();
			} else if (response.status === 401) {
				handleSessionOpen();
			}
			await updateTerminal(data.businessNumber);
		} catch (error) {
			console.log(error);
		}
	}

	const group = localStorage.getItem("group");

	// Get all Active Terminals
	if (group === "Admin") {
		const fetchTerminals = async () => {
			try {
				const tresponse = await fetch(TfetchUrl, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
				});
				if (tresponse.status === 200) {
					const tdata = await tresponse.json();
					setTerminals(tdata.data);
				} else if (tresponse.status === 401) {
					handleSessionOpen();
				}
			} catch (e) {
				console.log(e);
			}
		};
		useEffect(() => {
			fetchTerminals();
		}, []);
	}

	// Mapping terminals to the drop-Down
	const terminalOptions = terminals.map((term) => {
		return {
			serial: term.terminalSerialNumber,
		};
	});

	// Shop Mapping to Data-grids
	// Active Tabs Row Data
	const activeRowData = activeTab.map((activeTabMap) => {
		return {
			id: activeTabMap._id,
			businessName: activeTabMap.businessName,
			businessNumber: activeTabMap.businessNumber,
			businessOwnerName: activeTabMap.businessOwnerName,
			businessOwnerEmail: activeTabMap.businessOwnerEmail,
			businessOwnerPhone: activeTabMap.businessOwnerPhone,
			initialSerialNumber: activeTabMap.bulkTerminals,
			businessOwnerAddress: activeTabMap.businessOwnerAddress,
			branches: activeTabMap.branches,
			Till: activeTabMap.Till,
			Paybill: activeTabMap.Paybill,
			Vooma: activeTabMap.Vooma,
			Equitel: activeTabMap.Equitel,
			till_paybill: activeTabMap.till_paybill,
			createdAt: activeTabMap.createdAt.split("T")[0],
			bulkTerminals: activeTabMap.bulkTerminals,
		};
	});

	// New Tabs Row Data
	const newRowData = newTab.map((newTabMap) => {
		return {
			id: newTabMap._id,
			businessName: newTabMap.businessName,
			businessNumber: newTabMap.businessNumber,
			businessOwnerName: newTabMap.businessOwnerName,
			businessOwnerEmail: newTabMap.businessOwnerEmail,
			businessOwnerPhone: newTabMap.businessOwnerPhone,
			initialSerialNumber: newTabMap.bulkTerminals,
			businessOwnerAddress: newTabMap.businessOwnerAddress,
			createdAt: newTabMap.createdAt,
			createdBy: newTabMap.createdBy,
		};
	});

	// Inactive Tabs Row Data
	const inactiveRowData = inactiveTab.map((inactiveTabMap) => {
		return {
			id: inactiveTabMap._id,
			businessName: inactiveTabMap.businessName,
			businessNumber: inactiveTabMap.businessNumber,
			businessOwnerName: inactiveTabMap.businessOwnerName,
			businessOwnerEmail: inactiveTabMap.businessOwnerEmail,
			businessOwnerPhone: inactiveTabMap.businessOwnerPhone,
			initialSerialNumber: inactiveTabMap.bulkTerminals,
			businessOwnerAddress: inactiveTabMap.businessOwnerAddress,
			createdAt: inactiveTabMap.createdAt,
			deactivatedAt: inactiveTabMap.deactivatedAt,
			requestedBy: inactiveTabMap.requestedBy,
			approvedBy: inactiveTabMap.approvedBy,
		};
	});

	const pendingRowData = pendingTab.map((pendingTabMap) => {
		return {
			id: pendingTabMap._id,
			businessName: pendingTabMap.businessName,
			businessNumber: pendingTabMap.businessNumber,
			businessOwnerName: pendingTabMap.businessOwnerName,
			businessOwnerEmail: pendingTabMap.businessOwnerEmail,
			businessOwnerPhone: pendingTabMap.businessOwnerPhone,
			initialSerialNumber: pendingTabMap.bulkTerminals,
			businessOwnerAddress: pendingTabMap.businessOwnerAddress,
			createdAt: pendingTabMap.createdAt,
			modifiedAt: pendingTabMap.modifiedAt,
			modifiedBy: pendingTabMap.modifiedBy,
		};
	});

	// Deactivation Tabs Row Data
	const deactivationRowData = deactivationTab.map((deactivateTabMap) => {
		return {
			id: deactivateTabMap._id,
			businessName: deactivateTabMap.businessName,
			businessNumber: deactivateTabMap.businessNumber,
			businessOwnerName: deactivateTabMap.businessOwnerName,
			businessOwnerEmail: deactivateTabMap.businessOwnerEmail,
			businessOwnerPhone: deactivateTabMap.businessOwnerPhone,
			initialSerialNumber: deactivateTabMap.bulkTerminals,
			businessOwnerAddress: deactivateTabMap.businessOwnerAddress,
			createdAt: deactivateTabMap.createdAt,
			deactivatedAt: deactivateTabMap.deactivatedAt,
			requestedBy: deactivateTabMap.requestedBy,
		};
	});

	// page size setter and getter
	const [pageSize, setPageSize] = React.useState(5);
	// const [searchValue, setSearchValue] = useState("");
	const [filteredData, setFilteredData] = useState([]);

	useEffect(() => {
		let filteredData = activeRowData;

		filteredData = filterBySearchBox(filteredData);
		setFilteredData(filteredData);
	}, [searchValue, tabValue]);

	useEffect(() => {
		let filteredData = activeRowData;

		filteredData = filterBySearchBox(filteredData);
		setFilteredData(filteredData);
	}, [activeTab, searchValue]);

	// const filterBySearchBox = (data) => {
	//   return data.filter((item) => {
	//     if (
	//       item.businessName.toLowerCase().includes(searchValue.toLowerCase()) ||
	//       item.businessOwnerPhone.toLowerCase().includes(searchValue.toLowerCase()) ||
	//       item.businessOwnerEmail.toLowerCase().includes(searchValue.toLowerCase()) ||
	//       item.businessNumber.toLowerCase().includes(searchValue.toLowerCase())
	//     ) {
	//       return item;
	//     }
	//   });
	// };

	const handleSearchBox = (event) => {
		setSearchValue(event.target.value);
		// console.log(">>>>>>>>>",searchValue)
	};

	//handles searchBox filter
	const filterBySearchBox = (data) => {
		return data.filter((query) => {
			if (searchValue === "zed") {
				setSearchValue("");
				return activeRowData;
			} else if (query.businessName.toLowerCase().includes(searchValue.toLowerCase())) {
				return query;
			} else if (query.businessOwnerPhone.toLowerCase().includes(searchValue.toLowerCase())) {
				return query;
			} else if (query.businessOwnerEmail.toLowerCase().includes(searchValue.toLowerCase())) {
				return query;
				// } else if (
				//   query.businessName.toLowerCase().includes(searchValue.toLowerCase())
				// ) {
				//   return query;
			} else if (query.businessNumber.toLowerCase().includes(searchValue.toLowerCase())) {
				return query;
			}
		});
	};

	// create business navigation
	const addBusiness =() =>{
		localStorage.setItem("businessName", "");
		dispatch(resetPaymentOptions());
		navigate("/createBusiness");
	}


	// App Start
	return (
		<div style={{ fontFamily: "Poppins" }}>
			<div>
				<div>
					<div>


						<Box component="div" sx={{marginLeft:2}}>
							<Box component="div" >
								<Typography sx={{color:"#032541", fontSize:"1.563rem", fontWeight:700}}>
									My Businesses
								</Typography>
							</Box>
							<Box component="div" sx={{}}>
								<Breadcrumbs
									separator={<FiberManualRecordIcon sx={{fontSize:"0.625rem", fontFamily : 'Poppins', color:"#e1e5e8"}} />}
									aria-label="breadcrumb">
									{breadcrumbs}
								</Breadcrumbs>
							</Box>
						</Box>

						<Grid container columns={1} sx={{ marginTop: "22px", width:"100%" }}>
							{/*<Grid item xs={0.9} md={0.755}></Grid>*/}
							<Grid item sx={{width:"100%"}}>

								{/*></input>*/}
								<Box sx={{marginLeft:2, display:"flex", width:"100%"}}>
									<CustomSearchInput name={"search"} value={searchValue} placeholder={"Search:"}
									                   onChange={handleSearchBox}/>
									<Box sx={{marginLeft:"auto"}}>
										
											<Button sx={{
												fontFamily: "Poppins",
												fontSize: "9px",
												color: "#f5f5f5",
												borderRadius: "4px",
												backgroundColor: "#032541",
												width:" 145px",
												height: "45px",
												"&:hover": {
													color: "#f5f5f5",
													backgroundColor: "#032541",
												},
											}}  onClick={addBusiness} startIcon={<AddIcon />}>
												Add Business
											</Button>
									
									</Box>
								</Box>

							</Grid>
						</Grid>

						{/*Tabbed Data Grid*/}
						<Box
							sx={{
								"& .super-app-theme--header": {
									color: "#032541",
									fontWeight: "600",
									fontSize: "10px",
								},
							}}
						>
							<TabContext value={tabValue}>
								<Box>
									<AntTabs onChange={handleTabChange} aria-label="Shop tabs" TabIndicatorProps={{ hidden: true }} textColor="primary">
										<AntTab label="Active Business " value="Active" />
										<AntTab label="New Business " value="New" />
										<AntTab label="Edited Business " value="Pending" />
										<AntTab label="Deactivation request" value="Deactivation" />
										<AntTab label="Inactive Business " value="Inactive" />
									</AntTabs>
								</Box>

								<TabPanel value="Active">
									<DataGrid
										style={{
											height: "500px",
											width: "auto",
											overflow: "visible",
											border: "solid 0px transparent",
										}}
										// rows={activeRowData}
										rows={filteredData}
										columns={activeColumns}
										rowCount={pageState.total}
										loading={pageState.isLoading}
										pagination
										paginationMode="server"
										page={pageState.page - 1}
										pageSize={dataGridPageSize}
										onPageChange={(newPage) => {
											setPageState((old) => ({
												...old,
												page: newPage + 1,
												pageSize: dataGridPageSize,
											}));
										}}
										onPageSizeChange={(newPageSize) => {
											setNewPageSize(newPageSize)
											setPageSize(newPageSize)
										} }
										rowsPerPageOptions={[5, 10, 20, 50, 100]}
										sx={{
											fontFamily: "Poppins",
											fontSize: "12px",
											color: "#272d3b",
										}}
									></DataGrid>
								</TabPanel>

								<TabPanel value="New">
									<DataGrid
										style={{ height: "500px", width: "auto" }}
										rows={newRowData}
										columns={newColumns}
										pageSize={pageSize}
										onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
										rowsPerPageOptions={[5, 10, 20, 50, 100]}
										pagination
										sx={{
											fontFamily: "Poppins",
											fontSize: "10px",
											color: "#272d3b",
										}}
									></DataGrid>
								</TabPanel>

								<TabPanel value="Pending">
									<DataGrid
										style={{ height: "500px", width: "auto" }}
										rows={pendingRowData}
										columns={editedColumns}
										pageSize={pageSize}
										onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
										rowsPerPageOptions={[5, 10, 20, 50, 100]}
										pagination
										sx={{
											fontFamily: "Poppins",
											fontSize: "10px",
											color: "#272d3b",
										}}
									></DataGrid>
								</TabPanel>

								<TabPanel value="Deactivation">
									<DataGrid
										style={{ height: "500px", width: "auto" }}
										rows={deactivationRowData}
										columns={deactivationColumns}
										pageSize={pageSize}
										onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
										rowsPerPageOptions={[5, 10, 20, 50, 100]}
										pagination
										sx={{
											fontFamily: "Poppins",
											fontSize: "10px",
											color: "#272d3b",
										}}
									></DataGrid>
								</TabPanel>

								<TabPanel value="Inactive">
									<DataGrid
										style={{ height: "500px", width: "auto" }}
										rows={inactiveRowData}
										columns={inactiveColumns}
										pageSize={pageSize}
										onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
										rowsPerPageOptions={[5, 10, 20, 50, 100]}
										pagination
										sx={{
											fontFamily: "Poppins",
											fontSize: "10px",
											color: "#272d3b",
										}}
									></DataGrid>
								</TabPanel>
							</TabContext>
						</Box>
					</div>

					{/*sessionModal*/}
					<SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen} />

					{/*Existing user Modal*/}
					<Modal centered align={"center"} className="container container-fluid" open={existingUserModal} onClose={existingUserModalHandleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
						<div
							style={{
								width: "506px",
								height: "506px",
								margin: "10% 16px 23px 45px",
								padding: "87px 75px 92.7px 34px",
								borderRadius: "36px",
								boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
								backgroundColor: "#fff",
							}}
						>
							<img src={deactivate} alt={"success deactivation"} />
							<label
								style={{
									width: "356px",
									height: "65px",
									margin: "37.8px 0 0",
									fontFamily: "Poppins",
									fontSize: "25px",
									fontWeight: "normal",
									fontStretch: "normal",
									fontStyle: "normal",
									lineHeight: "1.2",
									letterSpacing: "normal",
									textAlign: "center",
									color: "#6e7074",
								}}
							>
								Business is Existing
							</label>
							<Button
								onClick={() => {
									existingUserModalHandleClose();
									window.location.reload(true);
								}}
								style={{
									fontFamily: "Poppins",
									fontSize: "25px",
									fontWeight: "600",
									color: "#fff",
									width: "124.5px",
									height: "52.3px",
									padding: "8px 44.5px 9.3px 35px",
									backgroundColor: "#032541",
								}}
							>
								OK
							</Button>
						</div>
					</Modal>

					{/*group addition success modal*/}
					<SuccessModal onClose={handleSuccessClose} editConfigOpen={successOpen} modalRow={businessName || "New"} successObject={"Business"} successType={"Created"} />

					{/*  Add Merchant Modal*/}
					<Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="container-fluid">
						<Box sx={addShopStyle}>
							<Box
								item
								sx={{
									width: "900px",
									height: "50px",
									margin: "0 0 0px",
									padding: "13px 9px 19px 10px",
									borderRadius: "16px",
									backgroundColor: "#dc3545",
								}}
							>
                <span
	                style={{
		                height: "13px",
		                margin: "0 48px 20px 20px",
		                fontFamily: "Poppins",
		                fontSize: "15px",
		                fontWeight: "normal",
		                fontStretch: "normal",
		                fontStyle: "normal",
		                lineHeight: "normal",
		                letterSpacing: "normal",
		                textAlign: "left",
		                color: "#fff",
	                }}
                >
                  Add a shop/Business
                </span>
							</Box>
							<Box
								sx={{
									width: "900px",
									height: "500px",
									margin: "-50px 0 63px 0.5px",
									padding: "55px 0 0",
								}}
							>
								{/*Tabs Configuration*/}
								<TabContext value={tabCreateValue.toString()}>
									<Box>
										<AntTabs onChange={handleTabCreateChange} aria-label="Shop tabs" TabIndicatorProps={{ hidden: false }} textColor="primary">
											<AntTab
												style={{
													height: "35px",
													margin: "13px 1px 16px 13px",
													fontFamily: "Poppins",
													fontSize: "20px",
													fontWeight: "600",
													fontStretch: "normal",
													fontStyle: "normal",
													lineHeight: "1.32",
													letterSpacing: "normal",
													textAlign: "left",
													color: "#032541",
												}}
												label="Business Details"
												value="businessDetails"
											/>

											<AntTab style={{ width: "200px" }}></AntTab>

											<AntTab
												style={{
													height: "35px",
													margin: "13px 3px 16px 16px",
													fontFamily: "Poppins",
													fontSize: "20px",
													fontWeight: "600",
													fontStretch: "normal",
													fontStyle: "normal",
													lineHeight: "1.32",
													letterSpacing: "normal",
													textAlign: "left",
													color: "#032541",
												}}
												label="Payment Details"
												value="paymentDetails"
											/>
										</AntTabs>
									</Box>

									<form onSubmit={addShopHandler}>
										{/*BusinessDetails*/}
										<TabPanel value="businessDetails">
											<Grid container columns={2}>
												{/*right side*/}
												<Grid item xs={1}>
													{/*business name*/}
													<Box
														sx={{
															width: "489px",
															height: "81px",
															margin: "7px 16px 22px 20px",
														}}
													>
                            <span
	                            style={{
		                            height: "21px",
		                            margin: "0 351px 15px 16px",
		                            fontFamily: "Poppins",
		                            fontSize: "15px",
		                            fontWeight: "normal",
		                            fontStretch: "normal",
		                            fontStyle: "normal",
		                            lineHeight: "2",
		                            letterSpacing: "normal",
		                            textAlign: "left",
		                            color: "#032541",
	                            }}
                            >
                              Business Name
                            </span>
														<input
															type="text"
															required
															value={businessName}
															onChange={(e) => setBusinessName(e.target.value)}
															style={{
																width: "360px",
																fontFamily: "Poppins",
																fontSize: "12px",
																padding: "15px",
																height: "45px",
																margin: "15px 0 0 12px",
																objectFit: "contain",
																borderRadius: "12px",
																border: "solid 1px #f1f1f1",
															}}
															placeholder="Enter Business Name Here"
														/>
													</Box>

													{/*business owner name*/}
													<Box
														sx={{
															width: "489px",
															height: "81px",
															margin: "4px 16px 26px 20px",
														}}
													>
                            <span
	                            style={{
		                            height: "21px",
		                            margin: "0 289px 15px 16px",
		                            fontFamily: "Poppins",
		                            fontSize: "15px",
		                            fontWeight: "normal",
		                            fontStretch: "normal",
		                            fontStyle: "normal",
		                            lineHeight: "2",
		                            letterSpacing: "normal",
		                            textAlign: "left",
		                            color: "#032541",
	                            }}
                            >
                              Business Owner's Name
                            </span>
														<input
															type={"text"}
															required
															value={businessOwnerName}
															onChange={(e) => setBusinessOwnerName(e.target.value)}
															style={{
																width: "360px",
																fontFamily: "Poppins",
																fontSize: "12px",
																padding: "15px",
																height: "45px",
																margin: "15px 0 0 12px",
																objectFit: "contain",
																borderRadius: "12px",
																border: "solid 1px #e4e4e4",
															}}
															placeholder="Enter Business Owner's Name Here"
														/>
													</Box>

													{/*phone number*/}
													<Box
														sx={{
															width: "489px",
															height: "81px",
															margin: "6px 16px 7px 20px",
														}}
													>
                            <span
	                            style={{
		                            height: "21px",
		                            margin: "0 354px 15px 16px",
		                            fontFamily: "Poppins",
		                            fontSize: "15px",
		                            fontWeight: "normal",
		                            fontStretch: "normal",
		                            fontStyle: "normal",
		                            lineHeight: "2",
		                            letterSpacing: "normal",
		                            textAlign: "left",
		                            color: "#032541",
	                            }}
                            >
                              Phone Number
                            </span>
														<input
															type={"number"}
															required
															value={businessOwnerPhone}
															onChange={(e) => setBusinessOwnerPhone(e.target.value)}
															maxLength="10"
															style={{
																width: "360px",
																fontFamily: "Poppins",
																fontSize: "12px",
																padding: "15px",
																height: "45px",
																margin: "15px 0 0 12px",
																objectFit: "contain",
																borderRadius: "12px",
																border: "solid 1px #e4e4e4",
															}}
															placeholder="Enter Phone Number Here"
														/>
													</Box>
												</Grid>

												{/*left side*/}
												<Grid item xs={1}>
													{/*Email*/}
													<Box
														sx={{
															width: "489px",
															height: "81px",
															margin: "7px 24px 22px 35px",
														}}
													>
                            <span
	                            style={{
		                            width: "48px",
		                            height: "21px",
		                            margin: "0 351px 3px 0",
		                            fontFamily: "Poppins",
		                            fontSize: "15px",
		                            fontWeight: "normal",
		                            fontStretch: "normal",
		                            fontStyle: "normal",
		                            lineHeight: "2",
		                            letterSpacing: "normal",
		                            textAlign: "left",
		                            color: "#032541",
	                            }}
                            >
                              Email
                            </span>
														<input
															type={"text"}
															required
															value={businessOwnerEmail}
															onChange={(e) => setBusinessOwnerEmail(e.target.value)}
															maxLength="50"
															style={{
																width: "360px",
																fontFamily: "Poppins",
																fontSize: "12px",
																padding: "15px",
																height: "45px",
																margin: "14px 0 0",
																objectFit: "contain",
																borderRadius: "12px",
																border: "solid 1px #e4e4e4",
															}}
															placeholder="Enter Valid Email Address"
														/>
													</Box>

													{/*Geolocation*/}
													<Box
														sx={{
															width: "489px",
															height: "81px",
															margin: "4px 24px 26px 35px",
														}}
													>
                            <span
	                            style={{
		                            width: "110px",
		                            height: "21px",
		                            margin: "0 289px 15px 0",
		                            fontFamily: "Poppins",
		                            fontSize: "15px",
		                            fontWeight: "normal",
		                            fontStretch: "normal",
		                            fontStyle: "normal",
		                            lineHeight: "2",
		                            letterSpacing: "normal",
		                            textAlign: "left",
		                            color: "#032541",
	                            }}
                            >
                              Geo-Location
                            </span>
														<input
															type={"text"}
															required
															value={businessOwnerAddress}
															onChange={(e) => setbusinessOwnerAddress(e.target.value)}
															maxLength="25"
															style={{
																width: "360px",
																fontFamily: "Poppins",
																fontSize: "12px",
																padding: "15px",
																height: "45px",
																margin: "7px 0 0",
																objectFit: "contain",
																borderRadius: "12px",
																border: "solid 1px #e4e4e4",
															}}
															placeholder="Enter Businesss Location"
														/>
													</Box>

													{/*Assign terminals*/}
													<Box
														sx={{
															width: "489px",
															height: "81px",
															margin: "6px 24px 7px 35px",
														}}
													>
                            <span
	                            style={{
		                            width: "134px",
		                            height: "21px",
		                            margin: "0 265px 16px 0",
		                            fontFamily: "Poppins",
		                            fontSize: "15px",
		                            fontWeight: "normal",
		                            fontStretch: "normal",
		                            fontStyle: "normal",
		                            lineHeight: "2",
		                            letterSpacing: "normal",
		                            textAlign: "left",
		                            color: "#032541",
	                            }}
                            >
                              Assign Terminals
                            </span>
														<Select
															required
															sx={{
																width: "360px",
																fontFamily: "Poppins",
																fontSize: "12px",
																padding: "15px",
																height: "45px",
																margin: "6px 0px 50.4px 0px",
																objectFit: "contain",
																borderRadius: "12px",
																border: "solid 1px #e4e4e4",
															}}
															labelId="demo-multiple-checkbox-label"
															id="demo-multiple-checkbox"
															multiple
															value={bulkTerminals}
															onChange={handleBulkTerminals}
															input={<OutlinedInput label="Tag" />}
															renderValue={(selected) => selected.join(", ")}
															MenuProps={MenuProps}
														>
															{terminalOptions.map((serials) => (
																<MenuItem key={serials.serial} value={serials.serial}>
																	<Checkbox checked={bulkTerminals.indexOf(serials.serial) > -1} />
																	<ListItemText primary={serials.serial} />
																</MenuItem>
															))}
														</Select>
													</Box>
												</Grid>
											</Grid>
											<Grid container columns={2} marginTop="15px">
												<Grid item xs={1.29}></Grid>
												<Grid item>
													{/*Buttons*/}
													<Box>
														<Button
															onClick={handleClose}
															sx={{
																fontFamily: "Poppins",
																fontSize: "12px",
																fontWeight: "600",
																fontStretch: "normal",
																fontStyle: "normal",
																lineHeight: "1.67",
																letterSpacing: "normal",
																textAlign: "left",
																color: "#cb1227",
																width: "80px",
																height: "42.6px",
																margin: "0.4px 55px 0 0",
																border: "solid 1px #dc3545",
															}}
														>
															Cancel
														</Button>
														<Button
															align={"right"}
															value={tabCreateValue}
															onClick={(e) => {
																setTabCreateValue("paymentDetails");
															}}
															sx={{
																fontFamily: "Poppins",
																fontSize: "12px",
																fontWeight: "600",
																fontStretch: "normal",
																fontStyle: "normal",
																lineHeight: "1.67",
																letterSpacing: "normal",
																textAlign: "left",
																color: "#fff",
																width: "80px",
																height: "42.6px",
																margin: "0 0 0.4px 55px",
																backgroundColor: "#032541",
															}}
														>
															Next
														</Button>
													</Box>
												</Grid>
											</Grid>
										</TabPanel>

										{/*PaymentDetails*/}
										<TabPanel value="paymentDetails">
											<Grid container columns={2}>
												{/*right side*/}
												<Grid item xs={1}>
													{/*Till Number*/}
													<Box
														sx={{
															width: "489px",
															height: "81px",
															margin: "7px 16px 22px 20px",
														}}
													>
                            <span
	                            style={{
		                            height: "21px",
		                            margin: "0 351px 15px 16px",
		                            fontFamily: "Poppins",
		                            fontSize: "15px",
		                            fontWeight: "normal",
		                            fontStretch: "normal",
		                            fontStyle: "normal",
		                            lineHeight: "2",
		                            letterSpacing: "normal",
		                            textAlign: "left",
		                            color: "#032541",
	                            }}
                            >
                              Till Number
                            </span>
														<input
															type={"number"}
															value={till}
															onChange={(e) => setTill(e.target.value)}
															style={{
																width: "360px",
																fontFamily: "Poppins",
																fontSize: "12px",
																padding: "15px",
																height: "45px",
																margin: "15px 0 0 12px",
																objectFit: "contain",
																borderRadius: "12px",
																border: "solid 1px #f1f1f1",
															}}
															placeholder="Enter Till Number Here"
														/>
													</Box>

													{/*Paybill Number*/}
													<Box
														sx={{
															width: "489px",
															height: "81px",
															margin: "4px 16px 26px 20px",
														}}
													>
                            <span
	                            style={{
		                            height: "21px",
		                            margin: "0 289px 15px 16px",
		                            fontFamily: "Poppins",
		                            fontSize: "15px",
		                            fontWeight: "normal",
		                            fontStretch: "normal",
		                            fontStyle: "normal",
		                            lineHeight: "2",
		                            letterSpacing: "normal",
		                            textAlign: "left",
		                            color: "#032541",
	                            }}
                            >
                              Paybill Number
                            </span>
														<input
															type={"number"}
															value={payBill}
															onChange={(e) => setPayBill(e.target.value)}
															style={{
																width: "360px",
																fontFamily: "Poppins",
																fontSize: "12px",
																padding: "15px",
																height: "45px",
																margin: "15px 0 0 12px",
																objectFit: "contain",
																borderRadius: "12px",
																border: "solid 1px #e4e4e4",
															}}
															placeholder="Enter Paybill Number Here"
														/>
													</Box>
												</Grid>

												{/*left side*/}
												<Grid item xs={1}>
													{/*Equitel*/}
													<Box
														sx={{
															width: "489px",
															height: "81px",
															margin: "7px 24px 22px 35px",
														}}
													>
                            <span
	                            style={{
		                            width: "48px",
		                            height: "21px",
		                            margin: "0 351px 3px 0",
		                            fontFamily: "Poppins",
		                            fontSize: "15px",
		                            fontWeight: "normal",
		                            fontStretch: "normal",
		                            fontStyle: "normal",
		                            lineHeight: "2",
		                            letterSpacing: "normal",
		                            textAlign: "left",
		                            color: "#032541",
	                            }}
                            >
                              Equitel
                            </span>
														<input
															type={"number"}
															value={equitel}
															onChange={(e) => setEquitel(e.target.value)}
															style={{
																width: "360px",
																fontFamily: "Poppins",
																fontSize: "12px",
																padding: "15px",
																height: "45px",
																margin: "14px 0 0",
																objectFit: "contain",
																borderRadius: "12px",
																border: "solid 1px #e4e4e4",
															}}
															placeholder="Enter Equitel Here"
														/>
													</Box>

													{/*Vooma*/}
													<Box
														sx={{
															width: "489px",
															height: "81px",
															margin: "4px 24px 26px 35px",
														}}
													>
                            <span
	                            style={{
		                            width: "110px",
		                            height: "21px",
		                            margin: "0 289px 15px 0",
		                            fontFamily: "Poppins",
		                            fontSize: "15px",
		                            fontWeight: "normal",
		                            fontStretch: "normal",
		                            fontStyle: "normal",
		                            lineHeight: "2",
		                            letterSpacing: "normal",
		                            textAlign: "left",
		                            color: "#032541",
	                            }}
                            >
                              Vooma
                            </span>
														<input
															value={vooma}
															onChange={(e) => setVooma(e.target.value)}
															style={{
																width: "360px",
																fontFamily: "Poppins",
																fontSize: "12px",
																padding: "15px",
																height: "45px",
																margin: "7px 0 0",
																objectFit: "contain",
																borderRadius: "12px",
																border: "solid 1px #e4e4e4",
															}}
															placeholder="Enter Vooma Here"
														/>
													</Box>
												</Grid>
											</Grid>
											<Grid container columns={2} marginTop="15px">
												<Grid item xs={1.29}></Grid>
												<Grid item>
													{/*Buttons*/}
													<Box>
														<Button
															onClick={handleClose}
															sx={{
																fontFamily: "Poppins",
																fontSize: "12px",
																fontWeight: "600",
																fontStretch: "normal",
																fontStyle: "normal",
																lineHeight: "1.67",
																letterSpacing: "normal",
																textAlign: "left",
																color: "#cb1227",
																width: "80px",
																height: "42.6px",
																margin: "0.4px 55px 0 0",
																border: "solid 1px #dc3545",
															}}
														>
															Cancel
														</Button>
														<Button
															type="submit"
															sx={{
																fontFamily: "Poppins",
																fontSize: "12px",
																fontWeight: "600",
																fontStretch: "normal",
																fontStyle: "normal",
																lineHeight: "1.67",
																letterSpacing: "normal",
																textAlign: "left",
																color: "#fff",
																width: "80px",
																height: "42.6px",
																margin: "0 0 0.4px 55px",
																backgroundColor: "#032541",
															}}
														>
															Save
														</Button>
													</Box>
												</Grid>
											</Grid>
										</TabPanel>
									</form>
								</TabContext>
							</Box>
						</Box>
					</Modal>

					{/* success busines switch */}
					{/* {sucess && <SuccessAlertRightAligned message={"Business switched successfully select a branch to proceed!"} sucess={sucess} />} */}
					{/*  Edit Merchant Modal*/}
				</div>
			</div>
		</div>
	);
};
export default Shops;
