import React, {useState} from 'react'
import {makeStyles} from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import usePagination from '@mui/material/usePagination';
import {Grid, ListItem, styled} from "@mui/material";
import Stack from '@mui/material/Stack';
import lodash from "lodash";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const numberFormat = (value) => new Intl.NumberFormat("en-US", {style: "currency", currency: "KES"}).format(value);


//Styling
const ListStyles = makeStyles((theme) => ({
	orderList : {
		fontFamily : 'Poppins',
		fontWeight : '500',
		borderRadius:"6px",
		border:"solid 1px #e1d6d6",
		height : '64px',
		color : '#032541',
		fontSize : '15px'
	},
	iconColor : {
		fontSize : 'small',
		padding : '3px',
		color : '#032541',
		marginRight : '20px'
	},
	footerTotal : {
		fontSize : '20px',
		color : '#17ae7b',
		fontWeight : '600',
		textAlign : 'right',
		marginTop : '10px',
		height: "103px",
		margin: "70px 3.4px 17px 0",
		padding: "38px 25px 37px 0px",
		boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
		backgroundColor: "#fff"
	}
}))

export default function ReleasedOrders () {
	const classes = ListStyles();
	const [page, setPage] = useState(1)
	const [pageSize, setPageSize] = useState(5)
	
	const Orders = [
		{orderNumber : 1, cashier : 'David', time : '2023-01-05 1:45:06 Pm', table : 15, amount : 500},
		{orderNumber : 2, cashier : 'Kelvin', time : '2023-01-05 1:45:06 Pm', table : 16, amount : 500},
		{orderNumber : 3, cashier : 'Keli', time : '2023-01-05 1:45:06 Pm', table : 167, amount : 500},
		{orderNumber : 4, cashier : 'Patrick', time : '2023-01-05 1:45:06 Pm', table : 18, amount : 500},
		{orderNumber : 5, cashier : 'Tim', time : '2023-01-05 1:45:06 Pm', table : 19, amount : 500},
	]
	
	const noOfPages = Math.ceil(Orders.length / pageSize);
	const totalSum = lodash.sumBy(Orders, amounts => {return amounts.amount})
	
	const handlePage = (event, value) => {setPage(value);console.log(value);};
	
	return (
		<div style={{fontFamily : 'Poppins'}}>
			
			{/*orders Container*/}
			<div>
				<Stack spacing={1}>
					{
						Orders.splice((page -1) * pageSize, page * pageSize).map((orderData) => (
							<ListItem key={orderData.orderNumber} className={classes.orderList}>
								<FiberManualRecordIcon className={classes.iconColor} />
								<span> Order {orderData.orderNumber}. By {orderData.cashier} ,Table  {orderData.table} Time ordered - {orderData.time}. Time promised, 2023-01-05 2:28:06 Pm </span>
							</ListItem>
						))
					}
				</Stack>
			</div>
			
			{/*Total Container*/}
			<Grid item className={classes.footerTotal} >
				<Stack spacing={2}>
					<span>Total Paid bills = {numberFormat(totalSum)}</span>
				</Stack>
			</Grid>
			
			{/*Pagination Container*/}
			<Grid container justifyContent={'flex-end'} style={{marginTop : '3%'}}>
				<Grid item>
					<Pagination count={noOfPages} variant="outlined" shape="rounded" page={page} defaultPage={1} onChange={handlePage}/>
				</Grid>
			</Grid>
		</div>
	)
}