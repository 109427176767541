import MenuItem from "@material-ui/core/MenuItem";
import React, { useEffect, useRef, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import CustomSelectField from '../../School/CustomSelectField'
import moment from "moment/moment";
import Calendar from "react-calendar";
import { handleBranchDetails } from "../../../features/branchSlice";
import { handleTransactionDate } from "../../../features/transactionDateSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Grid, Select } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Form from "react-bootstrap/Form";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import totalTenants from "../../../common/images/tenants.svg"
import tenantWithBal from "../../../common/images/tenantsWithBalance.svg"
import StudentIcon from "../../../common/images/student-icn.svg";
import PaidInvoice from "../../../common/images/paid-invoice-icn.svg"
import UnpaidInvoice from "../../../common/images/unpaid-invoice-icn.svg"
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import dueImg from "../../../images/due.svg"
import receImg from "../../../images/rec.svg"
import unitImg from '../../../images/units.svg'
import tenantIm from '../../../images/tenats.svg'
import HttpComponent from "../../School/MakeRequest";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
let baseUrl = process.env.REACT_APP_BASE_URL

const todayDate = new Date();
const day = todayDate.getDate();
const month = todayDate.getMonth() + 1;
const year = todayDate.getFullYear();
const fullDate = year + "-" + month + "-" + day;

function addDays(date, days) {
	const result = new Date(date); result.setDate(result.getDate() + days);
	return result;
}

const LandLordDash = (props) => {
	const calendarDateRef = useRef(new Date());
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [totals, setTotals] = useState([]);
	const [chartData, setChartData] = useState([]);
	const [dropDown, setDropDown] = useState("7days");
	const [batchDropDown, setBatchDropDown] = useState("7days");
	const [currentIndex, setCurrentIndex] = useState(0);
	const [branches, setBranches] = useState([]);
	const [invoiceBatches ,setInvoiceBatches] = useState([])
	const [monthData, setMonthData] = useState([])
	const [loading, setLoading] = useState(true)
	const [year, setYear] = useState(new Date().getFullYear())

	//previous year
	
	function handlePrevClick() {
		setYear((year) => year - 1);
	  }

	  // next year user
	
	  function handleNextClick() {
		setYear((year) => year + 1);
	  }

	const {X_Authorization} = useSelector((store)=>store.user)

	//console.log(X_Authorization ,'authtoken')

	const fetchBranches = async () => {
		try {
			const response = await fetch(baseUrl + "/api/getBusinessBranchesByBid", {
				method: "GET",
				headers: {
					"X-Authorization": localStorage.getItem("X-Authorization"),
					"Content-Type": "application/json",
					Accept: "application/json",
				},
			});
			const data = await response.json();
			if (response.status === 401) { return window.location.href = "/logout" }
			if (response.status === 200) { setBranches(data.data); }
		} catch (e) {
			console.log(e);
		}
	};

	const switchBranch = async (branchId) => {
		try {
			const response = await fetch(baseUrl + `/api/get_business_token`, {
				method: "POST",
				headers: {
					"X-Authorization": localStorage.getItem("X-Authorization"),
					"Content-Type": "application/json",
					Accept: "application/json",
				},
				body: JSON.stringify({
					branch_id: branchId,
					oldToken: localStorage.getItem('X-Authorization'),
					business_id: localStorage.getItem('businessID')
				})
			})
			if (response.status === 401) { return window.location.href = "/logout" }
			await response.json()
				.then((data) => {
					localStorage.removeItem('X-Authorization')
					localStorage.setItem('X-Authorization', data.data.newToken)
					handleSuccessOpen()
				})
				.catch((e) => { console.log(e.message) })
		} catch (e) { console.log('Error Switching branch', e.message) }
	}

	// branch Switch handler
	const handleBranchSwitch = (branchId) => {
		switchBranch(branchId)
			.then(r => console.log('branch switch Success!'))
			.catch((e) => { console.log(e.message) })
	}

	const [schoolData, setSchoolData] = useState({
		totalStudents: 0,
		studentsWithBalances: 0,
		paidInvoices: "KES 0.00",
		unpaidInvoices: "KES 0.00",
		newStudents: 0,
		newParents: 0,
		totalParents: 0,
		totalPaidInvoices: 0,
		parentsWithBalances: 0,
		paidInvoicesCount: 0,
		totalUnits:0,
		occupiedUnits:0,
		unOccupiedUnits:0,
		houseWithBal:0,
		unpaidInvoicesCount: 0,
		expectAmount:0 ,
		pedBal:0
	})

	const newDashboardCards = [
		{id:'one', textOne:'Number of' ,textTwo:"Units" , textThree:'Tenants' , image:unitImg , firstNumber:schoolData.totalUnits, secondNumber:schoolData.totalStudents , link:'/units'},
		{id:'two', textOne:'Occupied' ,textTwo:"Units" , textThree:'Unoccupied' , image:tenantIm , firstNumber:schoolData.occupiedUnits, secondNumber:schoolData.unOccupiedUnits , link:'/units'},
		{id:'three', textOne:'Amount Expected' ,textTwo:"This Month" , textThree:'Pending' , image:dueImg , firstNumber:schoolData.expectAmount, secondNumber:schoolData.pedBal , link:'/'},
		{id:'four', textOne:'Amount Received' ,textTwo:"This Month" , textThree:'houses' , image:receImg , firstNumber:schoolData.paidInvoices, secondNumber:schoolData.houseWithBal , link:'/'}

	]

	// const divs = [
	// 	{ id: "two", textOne: "Total", textTwo: "Tenants", textThree: "Houses", image: totalTenants, firstNumber: schoolData.totalStudents, secondNumber: schoolData.totalParents, link: "/tenants" },
	// 	{ id: "four", textOne: "Paid", textTwo: "Invoices", textThree: "Invoices", image: PaidInvoice, fistNumberFontColor: '#17ae7b', firstNumber: schoolData.paidInvoices, secondNumber: schoolData.paidInvoicesCount, link: '/tenantInvoices' },
	// 	{ id: "five", textOne: "Unpaid", textTwo: "Invoices", textThree: "Invoices", image: UnpaidInvoice, fistNumberFontColor: '#dc3545', firstNumber: schoolData.unpaidInvoices, secondNumber: schoolData.unpaidInvoicesCount, link: '/tenantInvoices' },
	// 	{ id: "three", textOne: "Tenants with", textTwo: "Balances", textThree: "Houses", image: tenantWithBal, firstNumber: schoolData.studentsWithBalances, secondNumber: schoolData.parentsWithBalances, link: "/tenants" },
	// 	{ id: "six", textOne: "Partially Paid", textTwo: "Invoices", textThree: "Invoices", image: tenantWithBal, firstNumber: 0, secondNumber: 0, link: '/tenantInvoices' },

	// ];
	const monthDiv = [
		{ id: "January", textOne: "Total Amount", textTwo: "JAN", firstNumber: monthData.JAN },
		{ id: "February", textOne: "Total Amount", textTwo: "FEB", firstNumber: monthData.FEB },
		{ id: "March", textOne: "Total Amount", textTwo: "MAR", firstNumber: monthData.MAR },
		{ id: "April", textOne: "Total Amount", textTwo: "APR", firstNumber: monthData.APR },
		{ id: "May", textOne: "Total Amount", textTwo: "MAY", firstNumber: monthData.MAY },
		{ id: "June", textOne: "Total Amount", textTwo: "JUN", firstNumber: monthData.JUN },
		{ id: "July", textOne: "Total Amount", textTwo: "JUL", firstNumber: monthData.JUL },
		{ id: "August", textOne: "Total Amount", textTwo: "AUG", firstNumber: monthData.AUG },
		{ id: "September", textOne: "Total Amount", textTwo: "SEP", firstNumber: monthData.SEP },
		{ id: "October", textOne: "Total Amount", textTwo: "OCT", firstNumber: monthData.OCT },
		{ id: "November", textOne: "Total Amount", textTwo: "NOV", firstNumber: monthData.NOV },
		{ id: "December", textOne: "Total Amount", textTwo: "DEC", firstNumber: monthData.DEC },
	]

	const handleNext = () => { setCurrentIndex(currentIndex + 1); };
	const handlePrev = () => { setCurrentIndex(currentIndex - 1); };

	const dropChange = (event) => { setDropDown(event.target.value) }
	const invoiceDropdown  = (event) => {setBatchDropDown(event.target.value)}
	const addDate = (value) => { return new Date(value.replace(/-/g, "/")) }
	const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES", }).format(value);
	const [dValue, onDValueChange] = useState([moment(addDays(new Date(), -7)).format("YYYY-MM-DD"), moment(addDays(new Date(), 1)).format("YYYY-MM-DD"),]);
	const [calenderData, setCalenderData] = useState([]);
	const calendarOnChangeHandler = (date = calendarDateRef.current) => {
		calendarDateRef.current = moment(date).format("YYYY-MM-DD");
		dispatch(handleTransactionDate(moment(date).format("YYYY-MM-DD")));
		navigate('/transactions');
	};

	const paymentData = async () => {
		const donutResponse = await fetch(baseUrl + "/api/donutRange", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				"X-Authorization": localStorage.getItem("X-Authorization"),
			},
			body: JSON.stringify({ dateList: dValue }),
		});

		const data = await donutResponse.json();

		//console.log(data ,'payment data')

		if (donutResponse.status === 401) {
			 return window.location.href = "/logout"
			}
		if (donutResponse.status === 200) { 
			setChartData(data.totals);
			setLoading(false)
		 }
	};

	const fetchSchoolInfo = async () => {
		const response = await fetch(baseUrl + "/api/get_school_information", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				"X-Authorization": localStorage.getItem("X-Authorization"),
			},
		});
		HttpComponent({
			method: 'GET',
			url: "/api/get_rental_information",
			token: localStorage.getItem("X-Authorization")
		}).then((data) => {
			// setLoading()
			if (data.status === 201) {
				setLoading(false)
				setSchoolData({
					totalStudents: data.response.data.numberOfTenants,
					studentsWithBalances: data.response.data.tenantsWithBalancesCount,
					paidInvoices: numberFormat(data.response.data.paidInvoicesTotal),
					unpaidInvoices: numberFormat(data.response.data.unpaidInvoicesTotal),
					newStudents: data.response.data.newStudents,
					newParents: data.response.data.newStudents,
					totalParents: data.response.data.numberOfParents,
					totalPaidInvoices: data.response.data.paidInvoicesCount,
					parentsWithBalances: data.response.data.parentsWithBalances,
					paidInvoicesCount: data.response.data.paidInvoicesCount,
					unpaidInvoicesCount: data.response.data.tenantsWithBalancesCount,
					houseWithBal:data.response.data.housesWithBalanceCount,
					expectAmount:numberFormat(data.response.data.amountExpectedThisMonth),
					pedBal:numberFormat(data.response.data.pendingBalance ),
					occupiedUnits:data.response.data.occupiedUnits ,
					unOccupiedUnits:data.response.data.unOccupiedUnits,
					totalUnits:data.response.data.numberOfHouses
				});
			}
		}).catch((e) => {
			console.log(`Error Getting Tenant information`, e.message)
		})
	}

	// Fetch Total transactions per day
	const fetchTotals = async () => {
		try {
			const response = await fetch(baseUrl + "/api/getTotals", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					"X-Authorization": localStorage.getItem("X-Authorization"),
				},
			});
			const data = await response.json();
			if (response.status === 401) { return window.location.href = "/logout" }
			if (response.status === 200) {
				setCalenderData(data.totals);
				for (const dayTotal of data.totals) {
					const dayDate = addDate(dayTotal._id.Time);
					const newDate = addDate(fullDate);
					if (dayDate.toDateString() === newDate.toDateString()) { setTotals(dayTotal.amount); }
				}
			}
		} catch (e) {
			console.log(e);
		}
	};

	// `/api/get_invoice_batches?status=APPROVED&limit=5`

	// fetch invoice batches

	function fetchInvoiceBatches(){
		try {
			HttpComponent({
				method:'GET',
				url:`/api/get_invoice_rental_invoices_recent` ,
				token:X_Authorization
			}).then((data)=>{
				console.log(data , 'invoice data')
				if(data.status === 200){
					setInvoiceBatches(data.response.data);
					setLoading(false)
				}
			})
		} catch (error) {
			console.log(error , '')
		}
	}
	//console.log(invoiceBatches , 'hhhhhhhhhhh')

	// api/v1/payments/by_year?year=2024

	//api/get_per_month_invoice_total

	const fetchMonthlyTotals = async () => {
		try {
			const response = await fetch(`${baseUrl}/api/v1/payments/by_year?year=${year}`,{
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					"X-Authorization": localStorage.getItem("X-Authorization"),
				},
			});
			if (response.status === 401) {
				 return window.location.href = "/logout"
				}
			await response.json()
				.then((data) => { 
					
					//console.log(`Monthly totals Data`, data); 
					setMonthData(data.data)
					setLoading(false)
				})
				.catch((e) => { console.log(`Error Getting Month Totals`, e.message) })
		} catch (e) {
			console.log(e);
		}
	}



	useEffect(() => {
		if (batchDropDown || dropDown === "Today") {
			onDValueChange([
				moment(new Date()).format("YYYY-MM-DD"),
				moment(addDays(new Date(), 1)).format("YYYY-MM-DD"),
			]);
		} else if (batchDropDown || dropDown === "YesterDay") {
			onDValueChange([
				moment(addDays(new Date(), -1)).format("YYYY-MM-DD"),
				moment(addDays(new Date(), -1)).format("YYYY-MM-DD"),
			]);
		} else if (batchDropDown || dropDown === "7days") {
			onDValueChange([
				moment(addDays(new Date(Date.now()), -7)).format("YYYY-MM-DD"),
				moment(addDays(new Date(Date.now()), +1)).format("YYYY-MM-DD"),
			]);
		} else if (batchDropDown || dropDown === "this Month") {
			onDValueChange([
				moment(
					new Date(new Date().getFullYear(), new Date().getMonth())
				).format("YYYY-MM-DD"),
				moment(addDays(new Date(Date.now()), +1)).format("YYYY-MM-DD"),
			]);
		} else if (batchDropDown || dropDown === "last Month") {
			onDValueChange([
				moment(
					new Date(new Date().getFullYear(), new Date().getMonth() - 1)
				).format("YYYY-MM-DD"),
				moment(
					new Date(new Date().getFullYear(), new Date().getMonth())
				).format("YYYY-MM-DD"),
			]);
		}
	}, [dropDown ,batchDropDown]);

	useEffect(() => {
		fetchBranches()
			.then(() => { console.log('Branch totals Found') })
			.catch((e) => { console.log(`Error getting totals`, e.message) })
		paymentData()
			.then(() => { console.log('Branch totals Found') })
			.catch((e) => { console.log(`Error getting totals`, e.message) })
		fetchTotals()
			.then(() => { console.log('Day totals Found') })
			.catch((e) => { console.log(`Error getting totals`, e.message) })
		fetchInvoiceBatches()
			
	}, [dValue]);

	useEffect(() => {
		fetchSchoolInfo()
	}, [])

	useEffect(()=>{
		fetchMonthlyTotals()
	},[dValue ,year])

	const mnth = month
	

	return (
		<>
			<div style={{ display: "flex", flexDirection: "column", fontFamily: 'Poppins' }}>
				<Grid container justifyContent={"flex-start"}>
					<Grid item style={{ marginLeft: "1.125rem" }} >
						<span style={{ color: "#dc3545", fontSize: "17px", fontWeight: "bold" }}>Branch Name:</span>
						<Select placeHolder={"Select Branch"} value={localStorage.getItem("branch") || "Select Branch"} size="small" sx={{ width: "300px", m: 1, mt: 1, fontSize: "17px", fontWeight: "bold", ".MuiOutlinedInput-notchedOutline": { border: 0 } }} displayEmpty>
							{branches.map((bch) => (
								<MenuItem key={bch.branchCode} value={bch.branchName || "Select Branch"} onClick={() => { handleBranchSwitch(bch?._id); dispatch(handleBranchDetails(bch)); localStorage.setItem("branch", bch?.branchName); }} style={{ fontSize: "12px" }}>
									{bch.branchName || "Select Branch"}
								</MenuItem>
							))}
						</Select>
					</Grid>
				</Grid>
				<div style={{ display: "flex", justifyContent: "flex-start" }}>
					{currentIndex > 0 && (
						<div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
							<KeyboardArrowLeft onClick={handlePrev} />
						</div>)}
					{newDashboardCards.slice(currentIndex, currentIndex + 5).map(div => (
						<div key={div.id} 
						style={{ width: "100%", height: "135px", borderRadius: "12px", border: " solid 1px rgba(3, 37, 65, 0.2)", margin: "1.125rem", display: "flex", 
						background:
						div.id === 'one' ? 'rgba(61, 162, 248, 0.12)' : 
						div.id === "two" ? '#f3f4f6' : 
						div.id === "three" ? 'rgba(247, 144, 9, 0.12)' : 
						div.id === 'four'? 'rgba(16, 185, 129, 0.12)':
						'red' , transition: "all 0.3s ease-in-out" }}>
							<div style={{ display: "flex", flexDirection: "column", margin: "0.5rem", width: "100%", justifyContent: "flex-start", transition: "all 0.3s ease-in-out" }}>

								{/*Route to Tenants*/}
								{
									(div.textOne + " " + div.textTwo)?.toLowerCase().includes('total tenants') ?
										<div style={{ cursor: 'pointer' }} onClick={() => { navigate(div.link) }}>
											<div style={{ color: "#212b36", fontSize: "16px", transition: "all 0.3s ease-in-out" }}>{div.textOne}</div>
											<div style={{ color: "#212b36", fontSize: "16px", transition: "all 0.3s ease-in-out" }}>{div.textTwo}</div>
										</div> :
										<div>
											<div style={{ color: "#212b36", fontSize: "16px", transition: "all 0.3s ease-in-out" }}>{div.textOne}</div>
											<div style={{ color: "#212b36", fontSize: "16px", transition: "all 0.3s ease-in-out" }}>{div.textTwo}</div>
										</div> 
								}

								{/*Invoice Manenos*/} 
											{ (div.id === 'one')?
												<div style={{ cursor: 'pointer' }} onClick={() => { navigate(div.link) }}>
													<div style={{ fontWeight: "bold", color: div.fistNumberFontColor, fontSize: "26px", transition: "all 0.3s ease-in-out" }}>{ loading ? <CircularProgress size={'15px'}/>:div.firstNumber}</div>
												</div>:
												(div.textOne === "two") ?
													<div style={{ cursor: 'pointer' }} onClick={() => { navigate(div.link) }}>
														<div style={{ fontWeight: "bold", color: div.fistNumberFontColor, fontSize: "26px", transition: "all 0.3s ease-in-out" }}>{ loading ? <CircularProgress size={'15px'}/>:div.firstNumber}</div>
													</div> :
													(div.textOne === "three") ?
														<div style={{ cursor: 'pointer' }} onClick={() => { navigate(div.link) }}>
															<div style={{ fontWeight: "bold", color: div.fistNumberFontColor, fontSize: "26px", transition: "all 0.3s ease-in-out" }}>{loading ? <CircularProgress size={'15px'}/>:div.firstNumber}</div>
														</div> :
														(div.textOne === "four") ?
															<div style={{ cursor: 'pointer' }} onClick={() => { navigate(div.link) }}>
																<div style={{ fontWeight: "bold", fontSize: "26px", transition: "all 0.3s ease-in-out" }}>{loading ? <CircularProgress size={'15px'}/>:div.firstNumber}</div>
															</div> :
															<div style={{ fontWeight: "bold", fontSize: "26px", transition: "all 0.3s ease-in-out" }}>{loading ? <CircularProgress size={'15px'}/>:div.firstNumber}</div>
											
											}

											<div style={{ color: "#212b36", display:'flex', fontSize: "14px", transition: "all 0.3s ease-in-out" }}>
												<div style={{color:div.id === 'one' ? '#212b36' : div.id === 'two' ? '#dc3545' : div.id === 'three' ? '#dc3545' : div.id === 'four' ? '#212b36':null}}>
													{ loading ? <CircularProgress size={'15px'}/>:div.secondNumber} 
												</div>
												<div style={{marginLeft:"20px"}}>
													{loading ? <CircularProgress size={'15px'}/>:div.textThree}
												</div> 
											</div>
							</div>
							<div style={{ margin: "0.5rem", marginTop: "0.938rem", width: "20%", transition: "all 0.3s ease-in-out" }}>
								<img src={div.image} style={{ height: "30px", width: "30px" }} alt='' />
							</div>
						</div>))}
					{currentIndex < newDashboardCards.length - 5 && (
						<div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
							<KeyboardArrowRight onClick={handleNext} />
						</div>)}
				</div>
				<div style={{ display: "flex", width: "100%" }}>
					<div style={{ width: "90%", margin: "1.125rem", borderRadius: "12px", boxShadow: "0 3px 6px 0 rgba(120, 90, 236, 0.16)" }}>
						<Grid direction={'row'} display={'flex'} justifyContent={'space-between'}>
							<span style={{ fontSize: "17px", color: "#dc3545", fontWeight: 600, margin: "1.125rem" }}> Calender</span>
							<div style={{display:"flex" ,cursor:'pointer' ,alignItems:"center"}}>
								<ArrowBackIosNewIcon onClick={handlePrevClick}/>
										<span>{year}</span>
								<ArrowForwardIosIcon onClick={handleNextClick}/>
							</div>
						</Grid>
						{/*Head Container*/}
						<Grid container direction={'row'} spacing={2} justifyContent={'space-between'} style={{ marginLeft: '-8px', padding:'10px', marginTop: '-8px' }}>
							{monthData.map((month, index) => (
								<Grid onClick={() => navigate(`/transactionSummary/${month.month}/${year}`)} item xs={2.7} key={month.id} style={{ cursor: 'pointer', width: "100%", height: "135px", borderRadius: "12px", border: " solid 1px rgba(3, 37, 65, 0.2)", margin: "10px", display: "flex", transition: "all 0.3s ease-in-out" }}>
									<Grid container direction={'row'} justifyContent={'space-between'} style={{ paddingRight: "16px" }}>

										{/*Months and Total Amount Label*/}
										<Grid item style={{ color: '#6e7074', fontSize: '12px', width: '5px' }} >Total Amount</Grid>
										<Grid item style={{ color: '#032541', fontSize: '14px', fontWeight: 'bold' }} >{month.month}</Grid>

										{/*Amount*/}
										<Grid container direction={'column'} >
											<Grid item style={{ color: '#032541', fontSize: '14px', fontWeight: '700' }} >{loading ? <CircularProgress size={'15px'}/>:numberFormat(month.amount)}</Grid>
										</Grid>

									</Grid>
								</Grid>
							))}
						</Grid>
					</div>
						<div>
							<div style={{ width: "100%", margin: "1.125rem", borderRadius: "12px", boxShadow: "0 3px 6px 0 rgba(120, 90, 236, 0.16)" }}>
								<div style={{ color: "#dc3545", fontSize: "18px", fontWeight: "600", margin: "1.125rem", display: "flex", justifyContent: "space-between" }}>
									<div>Transaction Summary</div>
									<div>
										<Form.Select size="sm" aria-label="Default select example" style={{ borderWidth: "0px", backgroundColor: "#fff", color: "#032541", fontSize: "14px", fontWeight: "bold" }} value={dropDown} onChange={dropChange}>
											<option value="Today">Today</option>
											<option value="YesterDay">Yesterday</option>
											<option value="7days">Last 7 Days</option>
											<option value="this Month">This Month</option>
											<option value="last Month">Last Month</option>
										</Form.Select>
									</div>
								</div>
								<div style={{ margin: "1.5rem" }}>{chartData.length < 1 ? (
									<div>No Payment Data</div>) : (chartData.map((lvalues, index) => (
										<Grid container key={index} justifyContent={"space-between"} direction={"row"}>
											<Grid item>
												<FiberManualRecordIcon style={{ fontSize: "10px" ,color:lvalues._id === 'MPESA' ? '#1cd60f' : '#dc3545' }} />
												<span style={{marginLeft:"20px"}}>{lvalues._id}</span>
											</Grid>
											<Grid item>
												{loading ? <CircularProgress size={'15px'}/>:numberFormat(lvalues.amount)}
											</Grid>
										</Grid>
									)))}
								</div>
							</div>
							<div style={{ width: "100%", margin: "1.125rem", borderRadius: "12px", boxShadow: "0 3px 6px 0 rgba(120, 90, 236, 0.16)" }}>
								<div style={{ color: "#dc3545", fontSize: "18px", fontWeight: "600", margin: "1.125rem", display: "flex", justifyContent: "space-between" }}>
									<div>Recent Invoices</div>
									<div>
										<Form.Select size="sm" aria-label="Default select example" style={{ borderWidth: "0px", backgroundColor: "#fff", color: "#032541", fontSize: "14px", fontWeight: "bold" }} value={batchDropDown} onChange={invoiceDropdown}>
											<option value="Today">Today</option>
											<option value="YesterDay">Yesterday</option>
											<option value="7days">Last 7 Days</option>
											<option value="this Month">This Month</option>
											<option value="last Month">Last Month</option>
										</Form.Select>
									</div>
								</div>
								<div style={{ margin: "1.125rem" }}>{invoiceBatches?.length < 1 ? (
									<div>No recent invoices</div>) : (invoiceBatches?.map((lvalues, index) => (
										<Grid container key={index} justifyContent={"space-between"} direction={"row"}>
											<Grid item>
												<FiberManualRecordIcon style={{ fontSize: "10px" ,color:lvalues._id === 'MPESA' ? '#1cd60f' : '#dc3545' }} />
												<Link style={{marginLeft:"20px"}} to={`/invoice/${lvalues?.invoiceNumber}`}>{lvalues?.invoiceNumber}</Link>
											</Grid>
											<Grid item>
												{loading ? <CircularProgress size={'15px'}/>:numberFormat(lvalues?.invoiceAmount)}
											</Grid>
										</Grid>
									)))}
								</div>
						</div>	
					</div>
				</div>
			</div>
		</>
	)
}

export { LandLordDash }
