import SchoolMultiStepSetupForm from "./SchoolMultiStepSetupForm";
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Breadcrumbs, Button, Typography } from "@mui/material";
import CustomTable from './CustomTable';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { handleSchoolTypeName, removeSchoolTypeName } from "../../features/schoolType";
const baseUrl = process.env.REACT_APP_BASE_URL;

const breadcrumbs = [
    <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Dashboard
    </Typography>,
    <Typography key={""} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
        School Type
    </Typography>
];
const SchoolSetup = () => {
    const [schoolTypeState, setSchoolTypeState] = useState(localStorage.getItem("schoolTypeState"));
    const [schoolTypeStateId, setSchoolTypeStateId] = useState(localStorage.getItem("schoolTypeStateId"));
    console.log(schoolTypeState, "schoolTypeState")
    const dispatch = useDispatch()

    const columns = [
        {
            field: 'schoolType', headerName: 'School Types', flex: 1,
            renderCell: (params) => {
                const handleSchoolSelect = (schoolType, id) => {
                    // console.log(" i was clicked", schoolType)
                    localStorage.setItem("schoolTypeState", schoolType);
                    localStorage.setItem("schoolTypeStateId",id );
                    setSchoolTypeStateId(id);
                    setSchoolTypeState(schoolType); // Update the state variable
                    console.log(schoolTypeState, "schoolTypeState")
                    dispatch(removeSchoolTypeName())
                    let schoolDetails = {
                        schoolTypeName: schoolType,
                        schoolTypeId: id
                    }
                    dispatch(handleSchoolTypeName(schoolDetails))



                    

                }
                return (
                    <div>
                        <span style={{ display: "flex", justifyContent: "space-between" }} onClick={() => handleSchoolSelect(params.row.schoolType, params.row._id)} >
                            <span style={{ marginRight: "5px" }}>{params.row.schoolType}</span>
                            <ArrowForwardIos />
                        </span>
                    </div>
                )
            }
        },
    ]
    const { userId, X_Authorization } = useSelector((store) => store.user);
    const { parentToAdd, studentToAdd, studentsToAdd, additionServices } = useSelector((store) => store.customers);

    const [schoolOptions, setschoolOptions] = useState([]);
    const getSchoolTypes = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_types`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data)
        setschoolOptions(data.data);


    }


    useEffect(() => {
        getSchoolTypes();
    }, [])
    return (
        <Box component="div">
            {!schoolTypeState ?
                <Box component="div">
                    <div>
                        <Box component="div" sx={{ width: "100%", display: "flex", flexDirection: "column", marginTop: "-2%" }}>
                            <Breadcrumbs aria-label="breadcrumb" sx={{ marginTop: "1%" }}>
                                {breadcrumbs}
                            </Breadcrumbs>
                            <Box component="div" sx={{ width: "100%", display: "flex", flexDirection: "column", marginTop: "2%" }}>
                                <Typography sx={{ color: "#707070", fontSize: "1.5rem", fontWeight: "bold" }}>
                                    Select School Type
                                </Typography>
                                <Typography sx={{ color: "#707070", fontSize: "1rem", marginTop: "1%" }}>
                                    Please select the type of school you want to setup
                                </Typography>
                            </Box>
                            <Box component="div" sx={{ width: "80%", alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column", marginLeft: "30px", marginTop: "%" }}>
                                <CustomTable columns={columns} data={schoolOptions} />
                            </Box>
                        </Box>
                    </div>
                </Box> :
                <SchoolMultiStepSetupForm  schoolTypeState = {schoolTypeState} schoolTypeStateId={schoolTypeStateId} />

            }
        </Box>
    )
}

export default SchoolSetup;
