import React, {useState, useEffect} from "react";
import {Modal, Box, Grid, ButtonBase, Input, TextField, IconButton,} from "@mui/material";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import './phoneInput.css';
import Button from "@mui/material/Button";
import axios from "axios";
import {PaymentSent} from "./paymentSent";
import LoadingButton from '@mui/lab/LoadingButton';
const baseUrl = process.env.REACT_APP_BASE_URL;
import CircularProgress from '@material-ui/core/CircularProgress';
import {Snackbar} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MpesaStk = (props) => {


    const [mpesaStkOpen, setMpesaStkOpen] = useState(props.mpesaStkOpen);
    const [value, setValue] = useState()
    const [amount, setAmount] = useState(0)
    const [successOpen, setSuccessOpen] = useState(false);
    const [stkPushLoading, setStkPushLoading] = useState(false);

    const wholeModal = {width: '550px', height: '520px', backgroundColor: '#fff', borderColor:"transparent", borderRadius: "7px", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',};
    const headerSection = {backgroundColor: '#dc3545', width: '100%', borderTopLeftRadius: "7px", borderTopRightRadius: "7px", height: '109px', flexDirection: "row", alignContent: "center", justifyItems: "center", textAlign: "center",}


    const handleClick = async (orderID) => {
        setStkPushLoading(true);
        if(!value){
            console.error("PhoneNumber is not valid")
            setStkPushLoading(false);
        }
        let cleanPhoneNumber = value?.replace("+", "");
        console.log("clean",cleanPhoneNumber)
        console.log("value", amount)
        if(value.length !== 13 && amount > 0){
            console.error("PhoneNumber is not valid")
            setMessage("Invalid phone number");
            setOpen(true);
            setStkPushLoading(false);
        }else if(value.length === 13 && (amount <= 0 || !amount)){
            setMessage("Invalid amount");
            setOpen(true);
            setStkPushLoading(false)
        }else if(value.length !== 13 && (amount <= 0 || !amount)){
            setMessage("Enter valid values");
            setOpen(true);
            setStkPushLoading(false)
        }else{
            //TODO: currenclty hard coded to do stk push for sweeton
            let businessID = "63e2231b620df9bb8076c525"
            let object = {
                amount,
                phone: cleanPhoneNumber,
                orderID: orderID,
                businessID: businessID
            }

            console.log("object", object)
            try {
                const response = await axios.post(baseUrl+'/api/pushstk', object,{
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                });
                console.log("stk response",response?.data?.data);
                if(response.data.data.status === 200){
                    setStkPushLoading(false)
                    props.onClose()
                    setSuccessOpen(true);
                }else{
                    //TODO: add an error message
                    console.error("Problem with push stk")
                    setStkPushLoading(false)
                }

            } catch (error) {
                console.error(error);
                setStkPushLoading(false)
            }
        }
    }

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");


    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };


    useEffect(() => {
        setMpesaStkOpen(props.mpesaStkOpen);
    }, [props.mpesaStkOpen]);

    return (
        <div>
            <Modal open={mpesaStkOpen} onClose={props.onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={wholeModal}>
                    <div style={headerSection}>
                        <div style={{marginLeft: "auto", marginRight: "auto", color: "white", fontWeight: "bold", fontSize: 25, paddingTop:"40px"}}>
                            Pay Invoice
                        </div>
                    </div>

                    <Grid style={{
                        margin:"20px"
                    }}>
                        <div>
                            <Snackbar
                                anchorOrigin={{
                                    vertical: "middle",
                                    horizontal: "center",
                                }}
                                open={open}
                                autoHideDuration={6000}
                                onClose={handleClose}
                                action={
                                    <React.Fragment>
                                        <IconButton
                                            size="small"
                                            aria-label="close"
                                            color="inherit"
                                            onClick={handleClose}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    </React.Fragment>
                                }>
                                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                                    {message}
                                </Alert>
                            </Snackbar>
                        </div>
                        <Grid item>
                            <div style={{
                                display:"flex",
                                justifyContent:"space-between",
                                backgroundColor: 'rgba(112, 112, 112, 0.05)',
                                borderRadius: '10px',
                                width:"480px",
                                height:"60px"
                            }}>
                                <div style={{
                                    display:"flex",
                                    flexDirection:"column",
                                    padding:"5px"
                                }}>
                                    <div>Invoice No:</div>
                                    <div style={{
                                        color:"#032541"
                                    }}>{props.invoiceNumber}</div>
                                </div>
                                <div style={{
                                    display:"flex",
                                    flexDirection:"column",
                                    padding:"5px"
                                }}>
                                    <div>Invoice Bal.</div>
                                    <div style={{
                                        color:"#dc3545"
                                    }}>KES {props.invoiceBalance}</div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: 300,
                                marginLeft:"5px",
                                marginRight:"5px",
                                marginTop:"10px",
                                marginBottom:"10px"
                            }}>
                                <span>Kindly enter your mobile number to receive the payment prompt and enter you M-Pesa Pin.</span>
                            </div>
                        </Grid>
                        <Grid item>
                            <div>
                                <span style={{
                                    marginTop:"5px",
                                    marginBottom:"5px",
                                }}>Mobile Number<span style={{color:"#dc3545"}}>*</span></span>
                                <PhoneInput
                                    placeholder="Enter phone number"
                                    value={value}
                                    onChange={setValue}
                                    countrySelectProps={{ unicodeFlags: true }}
                                    international
                                    defaultCountry="KE"
                                />
                            </div>

                        </Grid>
                        <Grid item>
                            <div style={{
                                display:"flex",
                                flexDirection:"column"
                            }}>
                                <span style={{
                                    marginTop:"5px",
                                    marginBottom:"5px",
                                }}>Amount<span style={{color:"#dc3545"}}>*</span></span>
                                <TextField
                                    id="outlined-number"
                                    type="number"
                                    value={amount}
                                    onChange={(e)=>setAmount(e.target.value)}
                                    style={{
                                        height: "35px",
                                        width: "480px",
                                        borderRadius: "5px"
                                    }}
                                />
                            </div>
                        </Grid>
                        <div style={{
                            width:"100%",
                            display:"flex",
                            justifyContent:"center",
                            marginTop:"7%"
                        }}>
                            {stkPushLoading ?(
                                <LoadingButton variant="outlined" style={{
                                    "width":"180px",
                                    "height":"50px",
                                    "borderRadius":"5px",
                                    "backgroundColor":"#032541"
                                }}>
                                    <CircularProgress  size={20} style={{color:"white"}} />
                                </LoadingButton>
                            ):(
                                <Button style={{
                                    "width":"180px",
                                    "height":"50px",
                                    "borderRadius":"5px",
                                    "backgroundColor":"#032541",
                                    "color":"white"
                                }} onClick={()=>handleClick(props.invoiceNumber)}>
                                    Send Prompt
                                </Button>

                                )}
                        </div>

                    </Grid>
                </Box>
            </Modal>
            <PaymentSent successOpen={successOpen} onClose={()=>setSuccessOpen(false)}/>
        </div>
    );
};
export { MpesaStk };
