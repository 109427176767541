import moment from "moment-timezone/moment-timezone-utils";

export default function DateFormatter (value) {
	try {

		const myDate = new Date(value).toLocaleDateString() + ' ' + new Date(value).toLocaleTimeString();
		// return moment(value).tz('Africa/Nairobi').format("llll")
		return myDate
	} catch (e) {
		console.log(`Error Formatting Date`, e.message)
	}
}