import React, { useState, useEffect, useCallback } from "react";
import Dash from "../../common/dash";
import { useSelector } from 'react-redux';

import {
  Grid,
  Button,
  styled,
  Box,
  Modal,
  Tab,
  Stack,
  Menu,
  alpha,
} from "@mui/material";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Close,
  CheckCircle,
  Circle,
  Edit,
  Delete,
  KeyboardArrowDown,
} from "@mui/icons-material";
import user from "../../common/images/user-icon.png";
import bell from "../../common/images/bell-icon.png";
import logout from "../../common/images/logout-icon.png";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import { DataGrid } from "@mui/x-data-grid";
import ApprovalGrid from "../customerAccounts/customerComponents/customerAproval";
import SuspendedGrid from "../customerAccounts/customerComponents/suspendedCustomer";
import CompanyGrid from "../customerAccounts/customerComponents/company";
import IndividualGrid from "../customerAccounts/customerComponents/individual";
import CreditGrid from "../customerAccounts/customerComponents/credit";
import PrepaidGrid from "../customerAccounts/customerComponents/prepaid";
import suspendedIcon from "../../common/images/suspendedcustomer.svg";
import addIcon from "../../common/images/addicon.svg";
import successModal from "../../common/images/customersuccessfullyadded.svg";
import logoutuser from "../logout/logout";
const baseUrl = process.env.REACT_APP_BASE_URL;
import emptyState from "../../common/images/emptystate.svg";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import suspendCustomer from "../../common/images/deactivate.svg";
import successIcon from "../../common/images/customersuccessfullyadded.svg";
import { Link } from "react-router-dom";
import AddTenant from "./Modals/tenantModals/addTenant";
import {AddParentChildren} from "./Modals/schoolModals/addParentChildren";
import {SchoolCustomers} from "./customerComponents/schoolCustomers";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const companyUrl =
  baseUrl + "/api/customers_list?page=1&limit=10&customerType=Company";
const individualUrl =
  baseUrl + "/api/customers_list?page=1&limit=10&customerType=Individual";
const crediturl =
  baseUrl + "/api/customers_list?page=1&limit=10&customerType=Credit";
const prepaidurl =
  baseUrl + "/api/customers_list?page=1&limit=10&paymentType=Prepaid";

// ADD NEW CUSTOMER MODAL;
const Addcustomermodal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "15px",
  backgroundColor: "#fff",
  width: "665px",
  height: "641px",
};

// Custom Tab Styling
const AntTabs = styled(TabList)({
  borderBottom: "3px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#dc3545",
  },
});

const LinkName = styled(Link)({
  textDecoration: "none",
  color: "#dc3545",
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    fontSize: "13px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "2.75",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#6e7074",
    fontFamily: ["Poppins"].join(","),
    "&:hover": {
      color: "#032541",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#dc3545",
      fontWeight: 600,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "blue",
    },
  })
);

// GET {{baseUrl}}/api/customers_list
// GET {{baseUrl}}/api/customers_list?page=2&limit=1&paymentType=Prepaid&customerType=Individual

const Customers = () => {
  let { businessCat } = useSelector((store) => store.businessCategory);
  if(!businessCat) businessCat = localStorage.getItem("businessCategory");

  useEffect(()=>{
    console.log("business cat ", businessCat)
  },[])
  // category modal state management;
  const [open, setOpen] = useState(false);
  // const [activateCircle, setActivateCircle] = use
  const [userValue, setUservalue] = useState("PersonalDetails");
  const [parentChildrenOpen, setParentChildrenOpen] = useState(false)

  const [showAdditionalInfoDataGrids, setShowAddtionalInfoDataGrids] =
    useState(true);
  const [
    showAdditionalInfoEmployeeInputs,
    setShowAdditionalInfoEmployeeInputs,
  ] = useState(false);
  const [
    showAdditionalInfoAddVehicleInputs,
    setShowAdditionalInfoAddVehicleInputs,
  ] = useState(false);

  // ACTIVATE ADD VEHICLE;
  const activateAddVehicleInput = () => {
    setShowAddtionalInfoDataGrids(false);
    setShowAdditionalInfoEmployeeInputs(false);
    setShowAdditionalInfoAddVehicleInputs(true);
  };

  // DISMISS ADD VEHICLE
  const cancelAddVehicle = () => {
    setShowAddtionalInfoDataGrids(true);
    setShowAdditionalInfoAddVehicleInputs(false);
  };

  const activateAddEmployee = () => {
    setShowAddtionalInfoDataGrids(false);
    setShowAdditionalInfoEmployeeInputs(true);
    setShowAdditionalInfoAddVehicleInputs(false);
  };

  // dismiss aditionalInfoModal
  const closeAddtitonalInfo = () => {
    setUservalue("PersonalDetails");
  };

  // GET CUSTOMER DETAILS STARTS
  const [customerDetails, SetCustomerDetails] = useState({
    customerType: "",
    customerFirstName: "",
    customerLastName: "",
    companyName: "",
    companyAdress: "",
    companyCode: "",
    customerCode: "",
    companyPhoneNo: "",
    customerPhoneNo: "",
    customerEmail: "",
    companyEmail: "",
    paymentType: "",
    creditPaymentInstruments: "",
    prepaidPaymentInstruments: "",
    limit: "",
    amountRecieved: "",
  });

  // set customerCode + number;
  const getCodeAndNumber = () => {
    if (customerDetails.companyPhoneNo.startsWith(0)) {
      const companyNo = "+254" + customerDetails.companyPhoneNo.slice(1);

      SetCustomerDetails({ ...customerDetails, companyPhoneNo: companyNo });
    } else if (customerDetails.customerPhoneNo.startsWith(0)) {
      const customerNo = "+254" + customerDetails.customerPhoneNo.slice(1);
      SetCustomerDetails({ ...customerDetails, customerPhoneNo: customerNo });
    } else if (customerDetails.customerPhoneNo.startsWith(7)) {
      const customerNo = "+254" + customerDetails.customerPhoneNo;

      SetCustomerDetails({ ...customerDetails, customerPhoneNo: customerNo });
    } else if (customerDetails.companyPhoneNo.startsWith(7)) {
      const customerNo = "+254" + customerDetails.companyPhoneNo;
      SetCustomerDetails({ ...customerDetails, companyPhoneNo: customerNo });
    }
  };
  useEffect(() => {
    getCodeAndNumber();
  }, [customerDetails.customerPhoneNo, customerDetails.companyPhoneNo]);

  // get customer details onchnage
  const getCustomerPersonalDetailsInputValue = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    SetCustomerDetails({ ...customerDetails, [name]: value });
  };
  // GET COMPANY DETAILS

  // setters and getters of company details;
  const [showCompanyDetails, setShowCompanyDetails] = useState({
    companyName: false,
    companyAdress: false,
    companyEmail: false,
    companyPhone: false,
  });

  // setters and getters of Individual details;
  const [showIndividualDetails, setShowIndividualDetails] = useState({
    individualName: false,
    individualLocation: false,
    individualAdress: false,
    individualPhone: false,
  });

  const chnageCustomer = () => {
    if (customerDetails.customerType === "Company") {
      setShowCompanyDetails({
        ...showCompanyDetails,
        companyName: true,
        companyAdress: true,
        companyEmail: true,
        companyPhone: true,
      });

      setShowIndividualDetails({
        ...showIndividualDetails,
        individualName: false,
        individualAdress: false,
        individualPhone: false,
      });
    } else if (customerDetails.customerType === "Individual") {
      setShowIndividualDetails({
        ...showIndividualDetails,
        individualName: true,
        individualAdress: true,
        individualPhone: true,
      });

      setShowCompanyDetails({
        ...showCompanyDetails,
        companyName: false,
        companyAdress: false,
        companyEmail: false,
        companyPhone: false,
      });
    }
  };

  useEffect(() => {
    chnageCustomer();
  }, [customerDetails.customerType]);

  // set getters and setters of payment type;
  const [paymentInstruments, setPaymentInstruments] = useState({
    prepaid: false,
    credit: false,
    limit: false,
    amountRecieved: false,
  });

  const changePaymentInstruments = () => {
    if (customerDetails.paymentType === "Credit") {
      setPaymentInstruments({
        ...paymentInstruments,
        prepaid: false,
        credit: true,
        limit: true,
        amountRecieved: false,
      });
    } else if (customerDetails.paymentType === "Prepaid") {
      setPaymentInstruments({
        ...paymentInstruments,
        prepaid: true,
        credit: false,
        amountRecieved: true,
        limit: false,
      });
    }
  };

  useEffect(() => {
    changePaymentInstruments();
  }, [customerDetails.paymentType]);

  // POPULATE THE ALL VEHICLES UL ONCHANGE
  const [vehicleType, setvehicleType] = useState("");
  const [vehcileInput, setVehcileInput] = useState("");
  const [carModelsSelection, setCarModelSelection] = useState([]);
  const [showModelUl, setShowModelUl] = useState(false);
  const getVehicleType = (e) => {
    setvehicleType(e.target.value);
  };

  // FUNCTION TO GET THE VEHICLE TYPE;
  const getVehicleTypeFromDb = useCallback(async () => {
    try {
      const vehicleTypeResponse = await fetch(
        baseUrl + `/api/getCarModels?modelName=${vehicleType}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "applicattion/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        }
      );

      if (vehicleTypeResponse.status === 201) {
        const vehicleModels = await vehicleTypeResponse.json();
        setCarModelSelection(vehicleModels.data);
        setShowModelUl(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  });

  useEffect(() => {
    getVehicleTypeFromDb();
  }, [vehicleType]);

  // POPULATE ALL THE COLORS ON THE UL;
  const [colorType, setColorType] = useState("");
  const [colorInput, setColorInput] = useState("");
  const [colorTypeSelection, setColorTypeSelection] = useState([]);
  const [showColorType, setShowColorType] = useState(false);
  const getColorType = (e) => {
    setColorType(e.target.value);
  };

  const getColorTypeFromDb = useCallback(async () => {
    try {
      const colorResponse = await fetch(
        baseUrl + `/api/getCarColors?colorName=${colorType}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        }
      );

      if (colorResponse.status === 201) {
        const colorData = await colorResponse.json();
        setColorTypeSelection(colorData.data);
        setShowColorType(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  });
  useEffect(() => {
    getColorTypeFromDb();
  }, [colorType]);

  // ADD CUSTOMER (ADD EMPLOYEE NAME);
  const [employee, setEmployeeName] = useState({
    employeeName: "",
    employeePhone: "",
    employeeIdNo: "",
  });
  const [employeeArray, setEmployeeArray] = useState([]);

  const saveToEmployeeArray = () => {
    setEmployeeArray([...employeeArray, { ...employee }]);
    setShowAddtionalInfoDataGrids(true);
    setShowAdditionalInfoEmployeeInputs(false);
  };

  // get employee details onchange;
  const getEmployeeDetailsInputValue = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setEmployeeName({ ...employee, [name]: value });
  };

  const [vehicleDetails, setVehicleDetailsArray] = useState([]);
  const [vehicleRegNo, setvehicleRegNo] = useState("");

  const saveVehicleDetails = () => {
    setVehicleDetailsArray([
      ...vehicleDetails,
      { regNo: vehicleRegNo, color: colorInput, vehicleType: vehcileInput },
    ]);
    setShowAddtionalInfoDataGrids(true);
    setShowAdditionalInfoAddVehicleInputs(false);
  };

  // ADD CUSTOMER TO THE DATABSE SRATS HERE;

  const addCustomerToDb = async () => {
    try {
      const addCustomerResponse = await fetch(baseUrl + "/api/addCustomer", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },

        body: JSON.stringify({
          businessId: localStorage.getItem("businessId"),
          customerType: customerDetails.customerType,
          customerAddress:
            customerDetails.customerType === "Company"
              ? customerDetails.companyAdress
              : "  ",
          email:
            customerDetails.customerType === "Company"
              ? customerDetails.companyEmail
              : customerDetails.customerEmail,
          firstName:
            customerDetails.customerType === "Individual"
              ? customerDetails.customerFirstName
              : customerDetails.companyName,
          lastName:
            customerDetails.customerType === "Individual"
              ? customerDetails.customerLastName
              : "  ",
          phone:
            customerDetails.customerType === "Company"
              ? customerDetails.companyPhoneNo
              : customerDetails.customerPhoneNo,
          paymentType: customerDetails.paymentType,

          limit:
            customerDetails.paymentType === "Credit"
              ? customerDetails.limit
              : "  ",
          amountReceived:
            customerDetails.paymentType === "Prepaid"
              ? customerDetails.amountRecieved
              : "  ",
          limitInstrument:
            customerDetails.paymentType === "Prepaid"
              ? customerDetails.prepaidPaymentInstruments
              : customerDetails.creditPaymentInstruments,
          vehicles: vehicleDetails,
        }),
      });

      if (addCustomerResponse.status === 201) {
        setTimeout(() => {
          setOpen(false);
        }, 2000);

        setTimeout(() => {
          setOpenSuccessModal(true);
        }, 3000);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // GET CUSTOMER DETAILS ENDS

  // ADD CUSTOMER MODAL

  const showCustomerModal = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  // successfullcustomerdisplay;
  const showAddedSuccesfully = () => {};

  const closeSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  // handle ant tab changes;

  const handleChangedValue = (event, newValue) => {
    setUservalue(newValue);
  };

  // activate tab image changes;
  const [activatePersonalDetailsIcon, setActivatePersonalDetails] =
    useState(true);
  const [activateAddionalInfoIcon, setActivateAdditionalinfoIcon] =
    useState(true);

  const [allCustomers, setCustomers] = useState([]);
  // handle tab changes;
  const [tabvalue, setTabValue] = useState("Allcustomers");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // customer column material ui;
  const customerColumn = [
    {
      field: businessCat === 'Rental' ? 'name' : 'customerName' ,
      headerName: businessCat === 'Rental' ? 'Tenant Name' : 'Customer Name',
      renderHeader: () => (
        <strong style={{fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541",}}>
          {businessCat === 'Rental' ? 'Tenant Name' : 'Customer Name'}
        </strong>
      ),
      renderCell: (params) => {

        return (
          <div>
            {
              businessCat === 'Rental' ?
                  <LinkName to={`/tenantDetails/${params.row.id}`}>
                    {params.row.customerName}
                  </LinkName> :
                  <LinkName to={`/customerDetails/${params.row.id}`}>
                    {params.row.customerName}
                  </LinkName>
            }

          </div>
        );
      },
      flex: 1,
    },
    {
      field: businessCat === 'Rental' ? 'customerType' : 'customerType' ,
      headerName: businessCat === 'Rental' ? 'Tenant Type' : 'Tenant Type',
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "10px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          {businessCat === 'Rental' ? 'Tenant Type' : 'Tenant Type'}
        </strong>
      ),
      flex: 1,
    },
    {
      field: "amountReceived",
      headerName: "Payment",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "10px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          {"Amount Received"}
        </strong>
      ),
      flex: 1,
    },
    {
      field: "availableBalance",
      headerName: "Available Balance",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "10px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          {businessCat === 'Rental' ? 'Balance Amount' : 'Available Balance'}
        </strong>
      ),
      flex: 1,
    },
    {
      field: "limit",
      headerName: "Limit",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "10px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          {"Limit"}
        </strong>
      ),
      flex: 1,
    },
    {
      field: "Actions",
      headerName: "Actions",
      renderCell: (params) => {

        // ACTIONS BUTTON STATE;
        const [anchorEl, setAnchorEl] = React.useState(null);
        const MenuoptionOpen = Boolean(anchorEl);
        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };
        const handleActionClose = () => {
          setAnchorEl(null);
        };

        // sucessfull credit;
        const [creditSuccess, setCreditSuccess] = useState(false);
        const closeCreditSuccess = () => {
          setCreditSuccess(false);
        };

        // CREDIT CUSTOMER;
        const [creditCustomerModal, setCreditCustomer] = useState(false);
        const closeCreditModal = () => {
          setCreditCustomer(false);
        };

        // SET CUSTOMER ID TO FETCH;
        const [customerTopUpPrepaid, setCustomerTopPrepaid] = useState(false);
        const [customerTopupcredit, setCustomerTopCredit] = useState(false);

        // CREDIT CUSTOMER DETAILS;
        const userDetails = useCallback(async (data) => {
          try {
            const individualResp = await fetch(
              baseUrl + `/api/get_customer_by_number?customerId=${data}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  "X-Authorization": localStorage.getItem("X-Authorization"),
                },
              }
            );

            if (individualResp.status === 200) {
              const individualData = await individualResp.json();

              individualData.data.forEach((customer) => {
                if (
                  customer.paymentType === "credit" ||
                  customer.paymentType === "Credit"
                ) {
                  setCustomerTopCredit(true);
                } else if (
                  customer.paymentType === "Prepaid" ||
                  customer.paymentType === "Prepaid"
                ) {
                  setCustomerTopPrepaid(true);
                }
              });
            }
          } catch (error) {
            console.log(error.message);
          }
        });

        // CREDIT CLIENT;
        const [customerCreditAmount, setCustomerCreditAmount] = useState("");
        const [customerPayMode, setCustomerPayMode] = useState("");

        const creditCustomerFnc = async (data) => {
          try {
            const creditCustomerResp = await fetch(
              baseUrl + `/api/refillCustomerAccount?customerId=${data}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  "X-Authorization": localStorage.getItem("X-Authorization"),
                },
                body: JSON.stringify({
                  amount: parseInt(customerCreditAmount),
                  paymentMode: customerPayMode,
                  description: "TopUp",
                }),
              }
            );

            if (creditCustomerResp.status === 201) {
              setCreditCustomer(false);
              setCreditSuccess(true);
            }
          } catch (error) {
            console.log(error.message);
          }
        };
        return (
          <div>
            <Button endIcon={<KeyboardArrowDown />} style={{color: "#032541", textTransform: "capitalize", textAlign: "left",}} aria-controls={MenuoptionOpen ? "demo-customized-menu" : undefined} aria-haspopup="true" aria-expanded={MenuoptionOpen ? "true" : undefined} variant="outlined" disableElevation color="primary" onClick={handleClick} id="demo-customized-button">
              Actions
            </Button>

            {/* POP UP SUSPEND AND CREDIT */}
            <StyledMenu
              id="demo-customized-menu"
              aria-labelledby="demo-customized-menu"
              MenuListProps={{ "aria-labelledby": "demo-customized-button" }}
              open={MenuoptionOpen}
              anchorEl={anchorEl}
              onClose={handleActionClose}
            >
              <MenuItem disableRipple>
                <Button
                  style={{
                    backgroundColor: "#dc3545",
                    textTransform: "capitalize",
                    textAlign: "left",
                    color: "#ffffff",
                    width: "100%",
                  }}
                  onClick={() => {
                    setCustomerId(params.row.id);
                    setCustomerName(params.row.customerName);
                    setSuspendCustomerModal(true);
                    handleActionClose();
                  }}
                >
                  Suspend Customer
                  {/* Edit Tenant */}
                </Button>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setCreditCustomer(true);
                  handleActionClose();
                }}
                disableRipple
              >
                <Button
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "#032541",
                    color: "#ffffff",
                    width: "100%",
                  }}
                  onClick={() => {
                    userDetails(params.row.id);
                  }}
                >
                  {/* Generate Invoice */}
                  Credit Customer
                </Button>
              </MenuItem>
            </StyledMenu>

            {/* CREDIT CUSTOMER MODAL */}
            <Modal open={creditCustomerModal} onClose={closeCreditModal}>
              <Box
                style={{
                  width: "40%",
                  // height: "50vh",
                  backgroundColor: "#ffffff",
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  borderRadius: "19px 19px 0px 0px",
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    backgroundColor: "#dc3545",
                    color: "#ffffff",
                    padding: "20px",
                    borderRadius: "19px 19px 0px 0px",
                  }}
                >
                  <p style={{ textAlign: "center" }}>Credit Customer Account</p>
                </Box>

                <Box style={{ padding: "20px" }}>
                  <Box style={{ margin: "0 0 20px 0", width: "100%" }}>
                    <label
                      style={{
                        color: "#032541",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                      }}
                      htmlFor="amount"
                    >
                      Top Up Amount
                    </label>
                    <Box>
                      <input
                        type="text"
                        style={{
                          width: "100%",
                          border: "1px solid #f1f1f1",
                          outline: "none",
                          padding: "7px",
                          borderRadius: "10px",
                        }}
                        value={customerCreditAmount}
                        name="customeramount"
                        id="customeramount"
                        onChange={(e) =>
                          setCustomerCreditAmount(e.target.value)
                        }
                      />
                    </Box>
                  </Box>

                  <Box style={{ margin: "0 0 20px 0", width: "100%" }}>
                    <label
                      style={{
                        color: "#032541",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                      }}
                      htmlFor="paymenttype"
                    >
                      Payment Type
                    </label>
                    {customerTopupcredit && (
                      <Box>
                        <select
                          name="payMode"
                          id="payMode"
                          style={{
                            width: "100%",
                            border: "1px solid #f1f1f1",
                            outline: "none",
                            padding: "7px",
                            borderRadius: "10px",
                          }}
                          value={customerPayMode}
                          onChange={(e) => setCustomerPayMode(e.target.value)}
                        >
                          <option>Payment Type</option>
                          <option value="contract">Contract</option>
                          <option value="purchaseorder">Purchase Order</option>
                        </select>
                      </Box>
                    )}

                    {customerTopUpPrepaid && (
                      <Box>
                        <select
                          name="payMode"
                          id="payMode"
                          style={{
                            width: "100%",
                            border: "1px solid #f1f1f1",
                            outline: "none",
                            padding: "7px",
                            borderRadius: "10px",
                          }}
                          value={customerPayMode}
                          onChange={(e) => setCustomerPayMode(e.target.value)}
                        >
                          <option>Payment Type</option>
                          <option value="mobilemoney">Mobile Money</option>
                          <option value="cheque">Cheque</option>
                          <option value="cash">Cash</option>
                        </select>
                      </Box>
                    )}
                  </Box>

                  <Box
                    style={{
                      margin: "0 0 10px 0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      style={{
                        color: "#dc3545",
                        border: "1px solid #dc3545",
                        margin: "0px 20px 0px 0px",
                        width: "115px",
                      }}
                      onClick={closeCreditModal}
                    >
                      Close
                    </Button>
                    <Button
                      style={{
                        color: "#ffffff",
                        backgroundColor: "#032541",
                        margin: "0px 0px 0px 20px",
                        width: "115px",
                      }}
                      onClick={() => {
                        creditCustomerFnc(params.row.id);
                      }}
                    >
                      Top Up
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Modal>

            {/* SUCCESS TOP UP MODAL */}
            <Modal open={creditSuccess} onClose={closeCreditSuccess}>
              <Box
                style={{
                  width: "20%",
                  backgroundColor: "#ffffff",
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  borderRadius: "20px",
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box>
                    <p style={{ textAlign: "center" }}>
                      <img
                        src={successIcon}
                        alt=""
                        style={{
                          width: "82px",
                          height: "82px",
                          objectFit: "contain",
                        }}
                      />
                    </p>

                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "25px",
                        fontWeight: "600",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterspacing: "normal",
                        textAlign: "left",
                        color: "#032541",
                      }}
                    >
                      Successfull
                    </p>

                    <p style={{ textAlign: "center" }}>
                      Customer successfuly <br /> credited
                    </p>

                    <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        onClick={() => {
                          closeCreditSuccess();
                        }}
                        style={{
                          border: "solid 1px #dc3545",
                          outline: "none",
                          margin: "10px",
                          backgroundColor: "#032541",
                          color: "#ffffff",
                        }}
                      >
                        ok
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Modal>
          </div>
        );
      },
      flex: 1,
    },
  ];

    // query according to companyName; customerType;
    const [inputValue, setInputValue] = useState("");

  // customer row material ui;
  const customerRow = allCustomers
  .filter((customer) => {
    if (businessCat === 'Rental') {
      if (customer.name.toLowerCase().includes(inputValue.toLocaleLowerCase()) || customer.customerType.toLowerCase().includes(inputValue.toLowerCase())) {return customer}
    } else {
    if (customer.customerName.toLowerCase().includes(inputValue.toLowerCase()) || customer.customerType.toLowerCase().includes(inputValue.toLowerCase())) {return customer;}
    }
  })
  .map((customer) => ({
    id: customer._id,
    amountReceived: customer.amountReceived ? customer.amountReceived : "N/A",
    availableBalance: customer.availableBalance
      ? customer.availableBalance
      : "N/A",
    customerName: businessCat === 'Rental' ? (customer.name ? customer.name : "N/A") : (customer.customerName ? customer.customerName : "N/A"),
    customerType: customer.customerType ? customer.customerType : "N/A",
    limit: customer.limit ? customer.limit : "N/A",
  }));

  // SUSPEND CUSTOMER STATE AND  FUNCTIONALITY;
  const [suspendCustomerModal, setSuspendCustomerModal] = useState(false);
  const [suspendCustomerSuccess, setSuspendCustomerSuccessModal] =
    useState(false);
  const closeSuspendCustomerModal = () => {
    setSuspendCustomerModal(false);
  };

  const closeSuspendModalSuccess = () => {
    setSuspendCustomerSuccessModal();
  };

  const [customerId, setCustomerId] = useState("");
  const [customerName, setCustomerName] = useState("");

  // SUSPEND CUSTOMER;
  const suspendCustomerFnc = async () => {
    try {
      const suspendCustomerResponse = await fetch(
        baseUrl + `/api/suspendCustomer?customerId=${customerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        }
      );

      if (suspendCustomerResponse.status === 202) {
        setSuspendCustomerModal(false);
        setSuspendCustomerSuccessModal(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // FETCH ACTIVE CUSTOMERS;
  // if (tabvalue === "Allcustomers") {

  const getCustomers = useCallback(async () => {
    try {
      const productResponse = await fetch(
        baseUrl + "/api/customers_list?page=1&limit=10&status=ACTIVE",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        }
      );

    

      if (productResponse.status === 200) {
        const customerData = await productResponse.json();
        setCustomers(customerData.data);
      }
    } catch (error) {
      console.log(error);
    }
  });
  useEffect(() => {
    getCustomers();
  }, [tabvalue]);
  // }

  // ONCHNAGE TO GET FILTER TYPE;
  const [filtervalue, setFilterValue] = useState("");
  const getInputValue = (e) => {
    setInputValue(e.target.value);
  };
  // ADDITIONAL EMPLOYEE DETAILS(COLUMNS AND ROWS)
  const additionalInfoAddEmployeeRow = employeeArray
  .map((employee) => ({
    id: employee.employeeIdNo,
    employeeName: employee.employeeName,
    employeePhone: employee.employeePhone,
    employeeIdNo: employee.employeeIdNo,
  }));

  const additionalInfoAddEmployee = [
    { field: "employeeName", headerName: "Name", flex: 1 },
    {
      field: "employeePhone",
      headerName: "Phone No",
      flex: 1,
    },
    { field: "employeeIdNo", headerName: "E.ID", flex: 1 },
    {
      field: "Action",
      headerName: "Action",
      renderCell: () => {
        return (
          <div>
            <Grid container>
              <Grid item>
                <span>
                  <Edit />
                </span>
              </Grid>
              <Grid item>
                <span>
                  <Delete />
                </span>
              </Grid>
            </Grid>
          </div>
        );
      },
    },
  ];

  // ADDITIONAL DETAILS CAR DETAILS; (COLUMNS AND ROWS)
  const additionalInfoCarRegNoRow = vehicleDetails.map((vehicle) => ({
    id: vehicle.regNo,
    regNo: vehicle.regNo,
    vehicleType: vehicle.vehicleType,
    color: vehicle.color,
  }));
  const additionalInfoCarRegNo = [
    { field: "regNo", headerName: "Reg No", flex: 1 },
    { field: "vehicleType", headerName: "Car Type", flex: 1 },
    { field: "color", headerName: "Color", flex: 1 },
  ];

  return (
    <div>
      <div>
        {/* customer name */}
        <Grid container marginBottom="10px">
          <Grid item>
            <span style={{width: "127px", height: "35px", margin: "26px 61px 5px 0", fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.32", letterSpacing: "normal", textAlign: "left", color: "#dc3545",}}>
              {businessCat === 'Rental' ? 'Tenants' : 'Customers'}
            </span>
          </Grid>
        </Grid>

        {/* Dashboard  and button */}
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          marginBottom="10px"
        >
          <Grid item>
            <span>
              <span
                style={{
                  width: "281px",
                  height: "28px",
                  margin: "26px 2px 17px 0",
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  // fontWeight: "600",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1.65",
                  letterSpacing: "normal",
                  textAlign: "left",

                  color: "#b4bcc4",
                }}
              >
                Dashboard
              </span>
              <span
                style={{
                  color: "#b4bcc4",
                }}
              >
                /
              </span>
              <span
                style={{
                  width: "281px",
                  height: "28px",
                  margin: "26px 2px 17px 0",
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  // fontWeight: "600",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1.65",
                  letterSpacing: "normal",
                  textAlign: "left",
                  color: "#6e7074",
                }}
              >
                {businessCat === 'Rental' ? 'Tenants' : 'Customers'}
              </span>
            </span>
          </Grid>
          <Grid item>
            {businessCat != "School" ?(
                <Button
                    onClick={showCustomerModal}
                    style={{
                      backgroundColor: "#032541",
                      color: "white",
                      textTransform: "capitalize",
                    }}
                >
                  + Add {businessCat === 'Rental' ? "Tenant" : "Customer"}
                </Button>
            ):(
                <>
                  <Button
                      onClick={()=>setParentChildrenOpen(true)}
                      style={{
                        backgroundColor: "#032541",
                        color: "white",
                        textTransform: "capitalize",
                      }}
                  >
                    + Add Parent/Guardian
                  </Button>
                </>

            )}

          </Grid>
        </Grid>

        {/* export buttons */}
        <Grid container justifyContent="space-between" marginBottom="10px">
          <Grid item></Grid>

          <Grid item>
            <Button
              style={{
                width: "50px",
                height: "37px",
                margin: "0 12px 0 0",
                padding: "9px 10px 8px 11px",
                backgroundColor: "#05d8e1",
                color: "white",
              }}
            >
              CSV
            </Button>
            <Button
              style={{
                width: "50px",
                height: "37px",
                margin: "0 12px",
                padding: "9px 7px 8px",
                backgroundColor: "#032541",
                color: "white",
              }}
            >
              Excel
            </Button>

            <Button
              style={{
                width: "50px",
                height: "37px",
                margin: "0 0 0 12px",
                padding: "9px 12px 8px",
                backgroundColor: "#dc3545",
                color: "white",
              }}
            >
              PDF
            </Button>
          </Grid>
        </Grid>

        {/* Show and select container */}
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <span
              style={{
                width: "35px",
                height: "12px",
                margin: "6px 20.4px 5px 0",
                fontFamily: "Poppins",
                fontSize: "9px",
                fontWeight: "bold",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "2.78",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#032541",
              }}
            >
              Search:
            </span>
            <input
              style={{
                fontSize: "12px",
                color: "#272d3b",
                borderRadius: "6px",
                width: "238px",
                height: "30px",
                margin: "0 0 0 20.4px",
                padding: "7px 107.7px 6.4px 11.1px",
                objectFit: "contain",
                border: "solid 1px #032541",
                outline: "solid 1px #032541",
              }}
              type="text"
              onChange={getInputValue}
              value={inputValue}
            />
          </Grid>
        </Grid>

        {/* Tabs container */}
        <Grid container>
          <Grid item style={{ width: "100%" }}>
            <Box
              sx={{
                "& .super-app-theme--header": {
                  color: "#032541",
                  fontWeight: "bold",
                  fontSize: "10px",
                  fontFamily: "Poppins",
                  fontStretch: "normal",
                  fontStyle: "normal",
                },
              }}
            >
              <TabContext value={tabvalue}>
                <Box style={{ width: "100%" }}>
                  <AntTabs
                    onChange={handleTabChange}
                    variant="fullWidth"
                    disableEnforceFocus
                  >
                    {/* Tab Headers */}
                    <AntTab label={businessCat === 'Rental' ? 'Tenants' : 'Customers'} value="Allcustomers" />
                    <AntTab label={businessCat === 'Rental' ? 'Tenants Approval' : 'Customers Approval'} value="PendingApproval" />
                    <AntTab label={businessCat === 'Rental' ? 'Suspended Tenants' : 'Suspended Customers'} value="Suspended" />
                    {/* <AntTab label="Company" value="Company" />
                    <AntTab label="Individual" value="Individual" />
                    <AntTab label="Credit" value="Credit" />
                    <AntTab label="Prepaid" value="Prepaid" /> */}
                  </AntTabs>
                </Box>
                {/* Tab Data put datagrid in the tab panels */}
                <TabPanel value="Allcustomers">
                  {businessCat == "School" ?
                      (
                          <SchoolCustomers/>
                      ):(
                          <DataGrid
                              // onCellClick={handleOnCellClick}
                              style={{
                                height: "500px",
                                width: "100%",
                                // overflow: "visible",
                              }}
                              columns={customerColumn}
                              rows={customerRow}
                              pageSize={5}
                              rowsPerPageOptions={[5]}
                              pagination
                              // {...activeFilterData}
                          />
                  )}

                </TabPanel>

                <TabPanel value="PendingApproval">
                  <ApprovalGrid
                    tabvalue={tabvalue}
                    inputValue={inputValue}
                    filterValue={filtervalue}
                
                  />
                </TabPanel>

                <TabPanel value="Suspended">
                  <SuspendedGrid
                    tabvalue={tabvalue}
                    inputValue={inputValue}
                    filterValue={filtervalue}
                  
                  />
                </TabPanel>

                {/* <TabPanel value="Company">
                  <CompanyGrid

                  />
                </TabPanel> */}
                {/* <TabPanel value="Individual">
                  <IndividualGrid />
                </TabPanel> */}

                {/* <TabPanel value="Credit">
                  <CreditGrid />
                </TabPanel> */}

                {/* <TabPanel value="Prepaid">
                  <PrepaidGrid

                  />
                </TabPanel> */}
              </TabContext>
            </Box>
          </Grid>
        </Grid>

        {/* ADD  NEW CUSTOMER MODAL */}
        {businessCat === 'Rental' ? <AddTenant open={open}  onClose={handleClose}/>
        :<Modal open={open} onClose={handleClose}>
          <Box
            style={{
              backgroundColor: "#fff",
              borderRadius: "15px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "40%",
              height: "auto",
            }}
          >
            <Box
              style={{
                width: "100%",
                height: "80px",
                borderRadius: "15px 15px 0 0",
                backgroundColor: "#dc3545",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "20px",
              }}
            >
              <span style={{ color: "white", fontFamily: "Poppins" }}>
                Add New Customer
              </span>
              <span style={{ color: "white" }}>
                <Close onClick={handleClose} style={{ cursor: "pointer" }} />
              </span>
            </Box>

            {/* TAB CONTROLS */}
            <Grid container>
              <Grid item style={{ width: "100%" }}>
                <Box
                  sx={{
                    "& .super-app-theme--header": {
                      color: "#032541",
                      fontWeight: "bold",
                      fontSize: "10px",
                      fontFamily: "Poppins",
                      fontStretch: "normal",
                      fontStyle: "normal",
                    },
                  }}
                >
                  <TabContext value={userValue}>
                    <Box style={{ width: "100%" }}>
                      <AntTabs
                        onChange={handleChangedValue}
                        variant="fullWidth"
                        scrollButtons={false}
                        aria-label="scrollable prevent tabs example"
                      >
                        <AntTab
                          // icon={<CheckCircle style={{ color: "#447c69" }} />}
                          icon={
                            userValue === "PersonalDetails" ? (
                              <CheckCircle style={{ color: "#447c69" }} />
                            ) : (
                              <Circle style={{ color: "#b4bcc4" }} />
                            )
                          }
                          iconPosition="start"
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "15px",
                            fontWeight: "600",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "1.32",
                            letterSpacing: "normal",
                            color: "black",
                          }}
                          label="Personal Details"
                          value="PersonalDetails"
                          centered
                        />

                        <AntTab
                          icon={
                            userValue !== "AdditionalInfo" ? (
                              <Circle style={{ color: "#b4bcc4" }} />
                            ) : (
                              <CheckCircle style={{ color: "#447c69" }} />
                            )
                          }
                          iconPosition="start"
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "15px",
                            fontWeight: "600",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "1.32",
                            letterSpacing: "normal",
                            color: "black",
                          }}
                          label="Additional Info"
                          value="AdditionalInfo"
                          centered
                        />
                      </AntTabs>
                    </Box>

                    {/* PERSONAL DETAILS PANEL */}

                    <TabPanel value="PersonalDetails">
                      <Box>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          style={{ margin: "0 0 20px 0" }}
                        >
                          <Grid item xs="5">
                            <Stack>
                              <label
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "15px",
                                  fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",

                                  letterSpacing: "normal",
                                  textAlign: "left",
                                  color: "#032541",
                                }}
                                htmlFor="customertype"
                              >
                                Customer Type
                              </label>
                              <select
                                style={{
                                  objectFit: "contain",
                                  borderRadius: "12px",
                                  border: "2px solid #f1f1f1",
                                  outline: "none",
                                  // margin: "15px 0 0",
                                  padding: "7px",
                                  width: "100%",
                                }}
                                name="customerType"
                                id="customerType"
                                onChange={getCustomerPersonalDetailsInputValue}
                                value={customerDetails.customerType}
                              >
                                <option>Select customer Type</option>
                                <option value="Company">Company</option>
                                <option value="Individual">Individual</option>
                              </select>
                            </Stack>
                          </Grid>
                          <Grid item xs="5">
                            {/* individual name input */}

                            {showIndividualDetails.individualName && (
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                              >
                                <Stack>
                                  <label
                                    style={{
                                      fontFamily: "Poppins",
                                      fontSize: "15px",
                                      fontWeight: "normal",
                                      fontStretch: "normal",
                                      fontStyle: "normal",

                                      letterSpacing: "normal",
                                      textAlign: "left",
                                      color: "#032541",
                                    }}
                                    htmlFor="firstname"
                                  >
                                    First Name
                                  </label>
                                  <input
                                    style={{
                                      padding: "7px",
                                      objectFit: "contain",
                                      borderRadius: "12px",
                                      border: "2px solid #f1f1f1",
                                      outline: "none",

                                      width: "100%",
                                    }}
                                    required
                                    type="text"
                                    placeholder="Enter first Name"
                                    onChange={
                                      getCustomerPersonalDetailsInputValue
                                    }
                                    name="customerFirstName"
                                    id="customerFirstName"
                                    value={customerDetails.customerFirstName}
                                  />
                                </Stack>

                                <Stack>
                                  <label
                                    style={{
                                      fontFamily: "Poppins",
                                      fontSize: "15px",
                                      fontWeight: "normal",
                                      fontStretch: "normal",
                                      fontStyle: "normal",

                                      letterSpacing: "normal",
                                      textAlign: "left",
                                      color: "#032541",
                                    }}
                                    htmlFor="lastname"
                                  >
                                    Last Name
                                  </label>
                                  <input
                                    style={{
                                      padding: "7px",
                                      objectFit: "contain",
                                      borderRadius: "12px",
                                      border: "2px solid #f1f1f1",
                                      outline: "none",

                                      width: "100%",
                                    }}
                                    required
                                    type="text"
                                    placeholder="last name"
                                    onChange={
                                      getCustomerPersonalDetailsInputValue
                                    }
                                    name="customerLastName"
                                    id="customerLastName"
                                    value={customerDetails.customerLastName}
                                  />
                                </Stack>
                              </Stack>
                            )}

                            {/* company input */}
                            {showCompanyDetails.companyName && (
                              <Stack>
                                <label
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",

                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#032541",
                                  }}
                                  htmlFor="companyName"
                                >
                                  Company Name
                                </label>
                                <input
                                  style={{
                                    padding: "7px",
                                    objectFit: "contain",
                                    borderRadius: "12px",
                                    border: "2px solid #f1f1f1",
                                    outline: "none",

                                    width: "100%",
                                  }}
                                  required
                                  type="text"
                                  placeholder="Enter company name here"
                                  onChange={
                                    getCustomerPersonalDetailsInputValue
                                  }
                                  name="companyName"
                                  id="companyName"
                                  value={customerDetails.companyName}
                                />
                              </Stack>
                            )}
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          style={{ margin: "0 0 20px 0" }}
                        >
                          <Grid item xs="5">
                            {showIndividualDetails.individualAdress && (
                              <Stack>
                                <label
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",

                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#032541",
                                  }}
                                  htmlFor="email"
                                >
                                  Email
                                </label>
                                <input
                                  style={{
                                    padding: "7px",
                                    objectFit: "contain",
                                    borderRadius: "12px",
                                    border: "2px solid #f1f1f1",
                                    outline: "none",

                                    width: "100%",
                                  }}
                                  required
                                  type="text"
                                  placeholder="Enter customer valid email address"
                                  onChange={
                                    getCustomerPersonalDetailsInputValue
                                  }
                                  name="customerEmail"
                                  id="customerEmail"
                                  value={customerDetails.customerEmail}
                                />
                              </Stack>
                            )}

                            {/* company email */}
                            {showCompanyDetails.companyEmail && (
                              <Stack>
                                <label
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",

                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#032541",
                                  }}
                                  htmlFor="companyEmail"
                                >
                                  Company Email
                                </label>
                                <input
                                  style={{
                                    padding: "7px",
                                    objectFit: "contain",
                                    borderRadius: "12px",
                                    border: "2px solid #f1f1f1",
                                    outline: "none",

                                    width: "100%",
                                  }}
                                  required
                                  type="text"
                                  placeholder="Enter valid company email address"
                                  onChange={
                                    getCustomerPersonalDetailsInputValue
                                  }
                                  name="companyEmail"
                                  id="companyEmail"
                                  value={customerDetails.companyEmail}
                                />
                              </Stack>
                            )}
                          </Grid>

                          <Grid item xs="5">
                            {/* individual phone number */}

                            {showIndividualDetails.individualPhone && (
                              <Stack>
                                <label
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",

                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#032541",
                                  }}
                                  htmlFor="customerPhoneNo"
                                >
                                  Phone Number
                                </label>

                                <Stack direction="row">
                                  <select
                                    onChange={
                                      getCustomerPersonalDetailsInputValue
                                    }
                                    required
                                    name="customerphone"
                                    id="customerphone"
                                    style={{
                                      // width: "30%",
                                      fontFamily: "Poppins",
                                      fontSize: "15px",
                                      fontWeight: "normal",
                                      fontStretch: "normal",
                                      fontStyle: "normal",
                                      borderRadius: "12px",
                                      border: "2px solid #f1f1f1",
                                      outline: "none",
                                      letterSpacing: "normal",
                                      textAlign: "left",
                                      color: "#032541",
                                    }}
                                    value={customerDetails.customerCode}
                                  >
                                    <option value="+254">+254</option>
                                    <option value="+254">+254</option>
                                  </select>

                                  <input
                                    style={{
                                      padding: "7px",
                                      objectFit: "contain",
                                      borderRadius: "12px",
                                      border: "2px solid #f1f1f1",
                                      outline: "none",

                                      width: "70%",
                                    }}
                                    required
                                    type="text"
                                    placeholder="eg (7013458762)"
                                    onChange={
                                      getCustomerPersonalDetailsInputValue
                                    }
                                    name="customerPhoneNo"
                                    id="customerPhoneNo"
                                    value={customerDetails.customerPhoneNo}
                                  />
                                </Stack>
                              </Stack>
                            )}

                            {/* company phoneNumber */}
                            {showCompanyDetails.companyPhone && (
                              <Stack>
                                <label
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",

                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#032541",
                                  }}
                                  htmlFor="companyPhoneNo"
                                >
                                  Company Phone Number
                                </label>

                                <Stack direction="row">
                                  <select
                                    style={{
                                      fontFamily: "Poppins",
                                      fontSize: "15px",
                                      fontWeight: "normal",
                                      fontStretch: "normal",
                                      fontStyle: "normal",
                                      borderRadius: "12px",
                                      border: "2px solid #f1f1f1",
                                      outline: "none",
                                      letterSpacing: "normal",
                                      textAlign: "left",
                                      color: "#032541",
                                    }}
                                    name="companyphone"
                                    id="companyphone"
                                    value={customerDetails.companyCode}
                                    onChange={
                                      getCustomerPersonalDetailsInputValue
                                    }
                                  >
                                    <option value="+254">+254</option>
                                    <option value="+254">+254</option>
                                  </select>

                                  <input
                                    style={{
                                      padding: "7px",
                                      objectFit: "contain",
                                      borderRadius: "12px",
                                      border: "2px solid #f1f1f1",
                                      outline: "none",

                                      width: "100%",
                                    }}
                                    required
                                    type="text"
                                    placeholder="eg (7013458762)"
                                    onChange={
                                      getCustomerPersonalDetailsInputValue
                                    }
                                    name="companyPhoneNo"
                                    id="companyPhoneNo"
                                    value={customerDetails.companyPhoneNo}
                                  />
                                </Stack>
                              </Stack>
                            )}
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          style={{ margin: "0 0 20px 0" }}
                        >
                          <Grid item xs="5">
                            <Stack>
                              <label
                                htmlFor="paymenttype"
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "15px",
                                  fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",

                                  letterSpacing: "normal",
                                  textAlign: "left",
                                  color: "#032541",
                                }}
                              >
                                Payment Type
                              </label>
                              <select
                                style={{
                                  padding: "7px",
                                  objectFit: "contain",
                                  borderRadius: "12px",
                                  border: "2px solid #f1f1f1",
                                  outline: "none",

                                  width: "100%",
                                }}
                                onChange={getCustomerPersonalDetailsInputValue}
                                value={customerDetails.paymentType}
                                name="paymentType"
                                id="paymentType"
                              >
                                <option
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",

                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#032541",
                                  }}
                                >
                                  Payment Type
                                </option>
                                <option value="Credit">Credit</option>
                                <option value="Prepaid">Prepaid</option>
                              </select>
                            </Stack>
                          </Grid>

                          <Grid item xs="5">
                            {showCompanyDetails.companyAdress && (
                              <Stack>
                                <label
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",

                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#032541",
                                  }}
                                  htmlFor="companyAdress"
                                >
                                  Physical Adress
                                </label>
                                <input
                                  style={{
                                    padding: "7px",
                                    objectFit: "contain",
                                    borderRadius: "12px",
                                    border: "2px solid #f1f1f1",
                                    outline: "none",

                                    width: "100%",
                                  }}
                                  required
                                  type="text"
                                  placeholder="Physical Adress"
                                  onChange={
                                    getCustomerPersonalDetailsInputValue
                                  }
                                  name="companyAdress"
                                  id="companyAdress"
                                  value={customerDetails.companyAdress}
                                />
                              </Stack>
                            )}
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          style={{ margin: "0 0 30px 0" }}
                        >
                          <Grid item xs="5">
                            {/* credit payment Instruments */}
                            {paymentInstruments.credit && (
                              <Stack>
                                <label
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",

                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#032541",
                                  }}
                                  htmlFor="paymentInstrument"
                                >
                                  Payment Instruments
                                </label>
                                <select
                                  style={{
                                    padding: "7px",
                                    objectFit: "contain",
                                    borderRadius: "12px",
                                    border: "2px solid #f1f1f1",
                                    outline: "none",

                                    width: "100%",
                                  }}
                                  onChange={
                                    getCustomerPersonalDetailsInputValue
                                  }
                                  name="creditPaymentInstruments"
                                  id="creditPaymentInstruments"
                                  value={
                                    customerDetails.creditPaymentInstruments
                                  }
                                >
                                  <option>Payment Instruments</option>
                                  <option value="contract">Contract</option>
                                  <option value="purchaseorder">
                                    Purchase Order
                                  </option>
                                </select>
                              </Stack>
                            )}

                            {/* prepaid payment instruments */}
                            {paymentInstruments.prepaid && (
                              <Stack>
                                <label
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",

                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#032541",
                                  }}
                                  htmlFor="paymentInstrument"
                                >
                                  Payment Instruments
                                </label>
                                <select
                                  style={{
                                    padding: "7px",
                                    objectFit: "contain",
                                    borderRadius: "12px",
                                    border: "2px solid #f1f1f1",
                                    outline: "none",

                                    width: "100%",
                                  }}
                                  onChange={
                                    getCustomerPersonalDetailsInputValue
                                  }
                                  name="prepaidPaymentInstruments"
                                  id="prepaidPaymentInstruments"
                                  value={
                                    customerDetails.prepaidPaymentInstruments
                                  }
                                >
                                  <option>Payment Instruments</option>
                                  <option value="mobilemoney">
                                    Mobile Money
                                  </option>
                                  <option value="cheque">Cheque</option>
                                  <option value="cash">Cash</option>
                                </select>
                              </Stack>
                            )}
                          </Grid>

                          <Grid item xs="5">
                            {/* LIMIT TO SPEND */}
                            {paymentInstruments.limit && (
                              <Stack>
                                <label
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",

                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#032541",
                                  }}
                                  htmlFor="limit"
                                >
                                  Limit
                                </label>
                                <input
                                  type="text"
                                  name="limit"
                                  id="limit"
                                  placeholder="Limit to spend"
                                  style={{
                                    padding: "7px",
                                    objectFit: "contain",
                                    borderRadius: "12px",
                                    border: "2px solid #f1f1f1",
                                    outline: "none",

                                    width: "100%",
                                  }}
                                  required
                                  value={customerDetails.limit}
                                  onChange={
                                    getCustomerPersonalDetailsInputValue
                                  }
                                />
                              </Stack>
                            )}

                            {/* AMT RECIEVED */}
                            {paymentInstruments.amountRecieved && (
                              <Stack>
                                <label
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",

                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#032541",
                                  }}
                                  htmlFor="amountRecieved"
                                >
                                  Amount Recieved
                                </label>
                                <input
                                  type="text"
                                  name="amountRecieved"
                                  id="amountRecieved"
                                  placeholder="amountrecieved"
                                  style={{
                                    padding: "7px",
                                    objectFit: "contain",
                                    borderRadius: "12px",
                                    border: "2px solid #f1f1f1",
                                    outline: "none",

                                    width: "100%",
                                  }}
                                  required
                                  onChange={
                                    getCustomerPersonalDetailsInputValue
                                  }
                                  value={customerDetails.amountRecieved}
                                />
                              </Stack>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          style={{ margin: "0 0 10px 0" }}
                        >
                          <Grid item xs="5">
                            <Button
                              style={{
                                border: "solid 1px #dc3545",
                                color: "#dc3545",
                                padding: "9px 28px 8.6px 29px",
                                margin: "0 10px 0 0",
                              }}
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                          </Grid>

                          <Grid item xs="5">
                            <Button
                              style={{
                                backgroundColor: "#032541",
                                color: "#ffffff",
                                padding: "9px 40px 8.6px 41px",
                                margin: "0 0 0 10px",
                              }}
                              onClick={(e) => setUservalue("AdditionalInfo")}
                              // onClick={addCustomerToDb}
                            >
                              NEXT
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </TabPanel>

                    {/* ADDITIONAL INFORMATION PANELS; */}
                    <TabPanel value="AdditionalInfo">
                      {/* ADDITIONAL INFO BUTTONS ADD VEHICLES AND ADD PERSON */}
                      <Grid container justifyContent="flex-end">
                        {
                          businessCat === "Service station (Gas station)"  &&          <Grid item>
                          <Button
                            onClick={activateAddVehicleInput}
                            style={{
                              borderRadius: "15px",
                              backgroundColor: "#fff",
                              color: "#032541",
                            }}
                            startIcon={
                              <img
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  margin: "0 9px 0 0",
                                  objectFit: "contain",
                                }}
                                src={addIcon}
                              />
                            }
                          >
                            Add Vehicle
                          </Button>

                          {/* <Button
                            style={{
                              borderRadius: "15px",
                              backgroundColor: "#fff",
                              color: "#032541",
                            }}
                            startIcon={
                              <img
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  margin: "0 9px 0 0",
                                  objectFit: "contain",
                                }}
                                src={addIcon}
                              />
                            }
                            onClick={activateAddEmployee}
                          >
                            Add Employee
                          </Button> */}
                        </Grid>
                        }
               
                      </Grid>

                      {/* ADDITIONAL INFO DATA GRIDS */}

                      {/* ADD EMPLOYEE ADDITIONAL INFO DATA GRID */}
                      {showAdditionalInfoDataGrids && (
                        <Grid container style={{ margin: "0 0 20px 0" }}>
                          {/* <Grid item style={{ width: "100%" }}>
                            <DataGrid
                              columns={additionalInfoAddEmployee}
                              style={{
                                height: "200px",
                                width: "100%",
                                // overflow: "visible",
                              }}
                              rows={additionalInfoAddEmployeeRow}
                              pageSize={5}
                              rowsPerPageOptions={[5]}
                            />
                          </Grid> */}

{
                              businessCat === "Service station (Gas station)" &&                           <Grid
                              item
                              style={{ width: "100%", margin: "0 0 20px 0" }}
                            >
                             
                              <DataGrid
                                columns={additionalInfoCarRegNo}
                                style={{
                                  height: "200px",
                                  width: "100%",
                                  // overflow: "visible",
                                }}
                                rows={additionalInfoCarRegNoRow}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                components={{
                                  NoRowsOverlay: () => (
                                    <Stack
                                      height="100%"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <img
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                          objectFit: "contain",
                                        }}
                                        src={emptyState}
                                        alt="empystateIcon"
                                      />
                                      <p
                                        style={{
                                          fontFamily: "Poppins",
                                          fontSize: "10px",
                                          fontWeight: "normal",
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "normal",
                                          textAlign: "center",
                                          color: "#b4bcc4",
                                          margin: "0 0 0 0",
                                        }}
                                      >
                                        No Vehicles found
                                      </p>
                                      <p
                                        style={{
                                          fontFamily: "Poppins",
                                          fontSize: "10px",
                                          fontWeight: "normal",
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "normal",
                                          textAlign: "center",
                                          color: "#b4bcc4",
                                          margin: "0 0 0 0",
                                        }}
                                      >
                                        Added Vehicles will appear here
                                      </p>
                                    </Stack>
                                  ),
                                }}
                              />
                            </Grid>
                            }

                          <Grid
                            item
                            style={{ width: "100%" }}
                            justifyContent="space-between"
                          >
                            <Button
                              style={{
                                width: "122px",
                                height: "42.6px",
                                padding: "9px 44px 8.6px 41px",
                                backgroundColor: "#041920",
                                margin: "0 30px 0 10px",
                                color: "#ffffff",
                              }}
                              onClick={addCustomerToDb}
                            >
                              Done
                            </Button>

                            <Button
                              style={{
                                margin: "0.4px 55px 0 0",
                                padding: "9px 28px 8.6px 29px",
                                border: "solid 1px #dc3545",
                                color: "#dc3545",
                              }}
                              onClick={closeAddtitonalInfo}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        </Grid>
                      )}

                      {/* ADDITIONAL INFO ADD PERSON INPUTS AND VEHICLE INPUTS; */}
                      <Grid container>
                        {showAdditionalInfoEmployeeInputs && (
                          <Grid item style={{ width: "50%" }}>
                            <Stack>
                              <label
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "15px",
                                  fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",

                                  letterSpacing: "normal",
                                  textAlign: "left",
                                  color: "#032541",
                                }}
                                htmlFor="name"
                              >
                                Name
                              </label>
                              <input
                                style={{
                                  padding: "7px",
                                  objectFit: "contain",
                                  borderRadius: "12px",
                                  border: "2px solid #f1f1f1",
                                  outline: "none",

                                  width: "100%",
                                }}
                                type="text"
                                placeholder="Enter Employee's Name"
                                value={employee.employeeName}
                                name="employeeName"
                                id="employeeName"
                                onChange={getEmployeeDetailsInputValue}
                              />
                            </Stack>

                            <Stack>
                              <label
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "15px",
                                  fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",

                                  letterSpacing: "normal",
                                  textAlign: "left",
                                  color: "#032541",
                                }}
                                htmlFor="phone"
                              >
                                Phone No
                              </label>
                              <input
                                style={{
                                  padding: "7px",
                                  objectFit: "contain",
                                  borderRadius: "12px",
                                  border: "2px solid #f1f1f1",
                                  outline: "none",

                                  width: "100%",
                                }}
                                type="text"
                                placeholder="Enter Employee's Phone No"
                                value={employee.employeePhone}
                                name="employeePhone"
                                id="employeePhone"
                                onChange={getEmployeeDetailsInputValue}
                              />
                            </Stack>

                            <Stack>
                              <label
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "15px",
                                  fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",

                                  letterSpacing: "normal",
                                  textAlign: "left",
                                  color: "#032541",
                                }}
                                htmlFor="phone"
                              >
                                Employee's ID NO.
                              </label>
                              <input
                                style={{
                                  padding: "7px",
                                  objectFit: "contain",
                                  borderRadius: "12px",
                                  border: "2px solid #f1f1f1",
                                  outline: "none",

                                  width: "100%",
                                }}
                                type="text"
                                placeholder="Enter Employee's ID NO"
                                value={employee.employeeIdNo}
                                name="employeeIdNo"
                                id="employeeIdno"
                                onChange={getEmployeeDetailsInputValue}
                              />
                            </Stack>

                            <Stack direction="row">
                              <Button
                                style={{
                                  margin: "0.4px 55px 0 0",
                                  padding: "9px 28px 8.6px 29px",
                                  border: "solid 1px #dc3545",
                                  color: "#dc3545",
                                }}
                              >
                                CANCEL
                              </Button>
                              <Button
                                style={{
                                  borderRadius: "15px",
                                  backgroundColor: "#041920",
                                  margin: "0.4px 55px 0 0",
                                  padding: "9px 28px 8.6px 29px",
                                  color: "#ffffff",
                                }}
                                onClick={saveToEmployeeArray}
                              >
                                SAVE
                              </Button>
                            </Stack>
                          </Grid>
                        )}

                        {/* ADDITIONAL INFORMATION ADD VEHICLE INPUTS */}
                        {showAdditionalInfoAddVehicleInputs && (
                          <Grid item style={{ width: "100%" }}>
                            <Stack>
                              <label
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "15px",
                                  fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",

                                  letterSpacing: "normal",
                                  textAlign: "left",
                                  color: "#032541",
                                }}
                                htmlFor="regno"
                              >
                                Registartion No
                              </label>
                              <input
                                style={{
                                  padding: "7px",
                                  objectFit: "contain",
                                  borderRadius: "12px",
                                  border: "2px solid #f1f1f1",
                                  outline: "none",

                                  width: "50%",
                                }}
                                type="text"
                                name="regNo"
                                id="regNo"
                                placeholder="Eg ( KBA001A )"
                                value={vehicleRegNo}
                                onChange={(e) =>
                                  setvehicleRegNo(e.target.value)
                                }
                              />
                            </Stack>

                            <Stack>
                              <label
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "15px",
                                  fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                  color: "#032541",
                                }}
                                htmlFor="cartype"
                              >
                                Car Type
                              </label>

                              {/* set CarModels */}
                              <Autocomplete
                                inputValue={vehcileInput}
                                onInputChange={(e, newInput) =>
                                  setVehcileInput(newInput)
                                }
                                style={{
                                  width: "50%",
                                  padding: "7px",
                                  objectFit: "contain",
                                  borderRadius: "12px",
                                }}
                                disablePortal
                                id="combo-box-demo"
                                options={carModelsSelection.map(
                                  (option) => option.modelName
                                )}
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField
                                    style={{
                                      border: "2px solid #f1f1f1",
                                      outline: "none",
                                    }}
                                    {...params}
                                    label="Car Type"
                                    inputValue={vehicleType}
                                    onChange={getVehicleType}
                                    // value={vehicleType}
                                    // onChange={getVehicleType}
                                  />
                                )}
                              />
                            </Stack>

                            <Stack>
                              <label
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "15px",
                                  fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",

                                  letterSpacing: "normal",
                                  textAlign: "left",
                                  color: "#032541",
                                }}
                                htmlFor="color"
                              >
                                Color
                              </label>
                              {/* color type selection */}
                              <Autocomplete
                                inputValue={colorInput}
                                onInputChange={(e, newValue) =>
                                  setColorInput(newValue)
                                }
                                freeSolo
                                style={{
                                  width: "50%",
                                  padding: "7px",
                                  objectFit: "contain",
                                  borderRadius: "12px",
                                }}
                                disablePortal
                                id="combo-box-demo"
                                options={colorTypeSelection.map(
                                  (option) => option.colorName
                                )}
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField
                                    style={{
                                      border: "2px solid #f1f1f1",
                                      outline: "none",
                                    }}
                                    {...params}
                                    label="Color"
                                    value={colorType}
                                    onChange={getColorType}
                                    InputProps={{
                                      ...params.InputProps,
                                      type: "search",
                                    }}
                                  />
                                )}
                              />
                            </Stack>

                            <Stack
                              direction="row"
                              style={{ margin: "20px 0 0 0" }}
                            >
                              <Grid container alignItems="flex-start">
                                <Grid item>
                                  <Button
                                    style={{
                                      border: "solid 1px #dc3545",
                                      color: "#dc3545",
                                      padding: "9px 28px 8.6px 29px",
                                      margin: "0 10px 0 0",
                                    }}
                                    onClick={cancelAddVehicle}
                                  >
                                    CANCEL
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    style={{
                                      backgroundColor: "#041920",
                                      color: "#ffffff",
                                      padding: "9px 40px 8.6px 41px",
                                      margin: "0 0 0 10px",
                                    }}
                                    onClick={saveVehicleDetails}
                                  >
                                    SAVE
                                  </Button>
                                </Grid>
                              </Grid>
                            </Stack>
                          </Grid>
                        )}
                      </Grid>
                    </TabPanel>
                  </TabContext>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Modal>}

        {/* CUSTOMMER SUCCESSFULLY ADDED MODAL! */}
        <Modal open={openSuccessModal}>
          <Box
            style={{
              width: "350px",
              height: "300px",
              position: "relative",
              top: "40%",
              left: "40%",
              padding: "47px 55px 46px",
              objectFit: "contain",
              borderRadius: "36px",
              boxShadow: "0 -3px 6px 0 rgba(120, 90, 236, 0.1)",
              backgroundColor: "#fff",
            }}
          >
            <Grid container justifyContent="center">
              <Grid item>
                <span>
                  <img
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "contain",
                      margin: "0 0 10px 0",
                    }}
                    src={successModal}
                    alt=""
                  />
                </span>
              </Grid>
              <Grid item>
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: "bold",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.25",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#032541",
                    margin: "0 0 15px 0",
                  }}
                >
                  Customer Added Successfully!
                </span>
              </Grid>
              <Grid item>
                {customerDetails.customerType === "Company" ? (
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "17px",
                      margin: "0 0 20px 0",
                    }}
                  >
                    {customerDetails.companyName} has successfully been added!
                  </p>
                ) : (
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "17px",
                      margin: "0 0 20px 0",
                    }}
                  >
                    {customerDetails.firstName} has successfully been added!
                  </p>
                )}
              </Grid>

              <Grid item>
                <Button
                  style={{
                    backgroundColor: "#032541",
                    color: "#ffffff",
                  }}
                  onClick={closeSuccessModal}
                >
                  ok
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        {/* SUSPEND CUSTOMER MODAL */}
        <Modal open={suspendCustomerModal} onClose={closeSuspendCustomerModal}>
          <Box
            style={{
              width: "20%",
              backgroundColor: "#ffffff",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: "20px",
            }}
          >
            <Box
              style={{
                width: "100%",
                padding: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box>
                <p style={{ textAlign: "center" }}>
                  <img
                    style={{
                      width: "81.9px",
                      height: "96px",
                    }}
                    src={suspendCustomer}
                    alt="approvepopupimg"
                    // onClick={openModal}
                  />
                </p>

                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "25px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#032541",
                  }}
                >
                  Suspend Customer?
                </p>
                <p style={{ textAlign: "center" }}>
                  Are you sure you want to suspend {customerName}
                </p>

                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    style={{
                      border: "solid 1px #dc3545",
                      color: "#dc3545",
                      outline: "none",
                      margin: "10px",
                    }}
                    onClick={closeSuspendCustomerModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      border: "solid 1px #dc3545",

                      outline: "none",
                      margin: "10px",
                      backgroundColor: "#dc3545",
                      color: "#ffffff",
                    }}
                    onClick={suspendCustomerFnc}
                  >
                    Suspend
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>

        {/* SUCCESS CUSTOMER APPROVAL */}
        <Modal
          open={suspendCustomerSuccess}
          onClose={setSuspendCustomerSuccessModal}
        >
          <Box
            style={{
              width: "20%",
              backgroundColor: "#ffffff",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: "20px",
            }}
          >
            <Box
              style={{
                width: "100%",
                padding: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box>
                <p style={{ textAlign: "center" }}>
                  <img
                    style={{
                      width: "82px",
                      height: "82px", objectFit: "contain",}} src={successIcon} alt="approvepopupimg"/></p><p style={{fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: "normal", textAlign: "left", color: "#032541",}}>Successful</p><p style={{ textAlign: "center" }}>Customer suspended <br /> Successfully!</p><Box style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center",}}><Button style={{border: "solid 1px #dc3545", outline: "none", margin: "10px", backgroundColor: "#032541", color: "#ffffff",}} onClick={() => {setSuspendCustomerSuccessModal(false);setTimeout(() => {window.location.reload();}, 2000);}}>ok
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>
      <AddParentChildren open={parentChildrenOpen} onClose={()=>setParentChildrenOpen(false)}/>
    </div>
  );
};

export default Customers;
