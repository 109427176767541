import {useDispatch, useSelector} from "react-redux";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import emptyInvoice from '../../../../common/images/invoice-empty.svg'
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import {makeStyles} from "@material-ui/core/styles";
import AddIcon from "@mui/icons-material/Add";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {DataGrid} from "@mui/x-data-grid";
import moment from "moment-timezone/moment-timezone-utils";
import {Link, useNavigate, useParams} from "react-router-dom";
import customerDetailssvg from "../../../../common/images/customeerdetailssvg.svg";
import {Box, Button, Card, Grid, Menu, styled, Tab, Typography ,Breadcrumbs} from "@mui/material";
import React, {useState, useEffect, useCallback} from "react";
import {handleTenantBioData, handleTenantHouseData, handleUpdateTenantHouseData} from "../../../../features/tenantsDataSlice";
import {NoRowsOverlay} from "../../../No Rows/noRowsOverlay";
import HttpComponent from "../../../School/MakeRequest";
import {CreateTenantInvoice} from "../../Modals/tenantModals/createInvoice";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from "@material-ui/core/MenuItem";
import MoveUpIcon from '@mui/icons-material/MoveUp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { alpha } from "@mui/material/styles";
import CurrencyFormatter from "../../../../utils/currencyFormatter";

//Urls
const baseUrl = process.env.REACT_APP_BASE_URL;
const getTenantDetailsUrl = baseUrl + "/api/get_customer_by_number";
const getTenantActivities = baseUrl + "/api/customerTransactions";
const getTenantActivityUrl = baseUrl + "/api/CustomersActivities";
const filterTranactionUrl = baseUrl + "/api/filterCustomerTransactionByDate";


//BreadCrumbs

const breadcrumbs = [
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Dashboard
    </Typography>,
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Tenants
    </Typography>,
    <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
        Tenant Profile
    </Typography>
]



const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
		transformOrigin={{
			vertical: "top",
			horizontal: "right",
			frontFamily: "Poppins",
		}}
		{...props}
	/>
))(({ theme }) => ({ "& .MuiPaper-root": { borderRadius: 6, marginTop: theme.spacing(1), minWidth: 18, frontFamily: "Poppins", color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300], boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px", "& .MuiMenu-list": { padding: "4px 0" }, "& .MuiMenuItem-root": { "& .MuiSvgIcon-root": { frontFamily: "Poppins", fontSize: 18, color: theme.palette.text.secondary, marginRight: theme.spacing(1.5), }, "&:active": { backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity), }, }, }, }));


//Styling
const tenantStyles = makeStyles(() => ({
	tenantName : {"fontSize": "26px", "fontWeight": "bold", "textAlign": "left", "color": "#032541"},
	dateAdded : {"fontSize": "17px", "textAlign": "left", "color": "#666f76"},
	customerType : {"fontSize": "19px", "fontWeight": "bold", "textAlign": "left", "color": "#032541"},
	unitData : {"fontFamily": "Poppins", "fontSize": "15px", "fontWeight": "bold", "color": "#032541"},
	balance : {"fontFamily": "Poppins", "fontSize": "15px", "fontWeight": "bold", "color": "#dc3545"},
	balanceData : {"fontSize": "17px", "textAlign": "left", "color": "#dc3545"}}))

//Custom Tab Styling
const AntTabs = styled(TabList)({borderBottom: "3px solid #e8e8e8", "& .MuiTabs-indicator": {backgroundColor: "#dc3545",},});
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({textTransform: "none", minWidth: 0,
	[theme.breakpoints.up("sm")]: {minWidth: 0,}, fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1),
	fontSize: "16px",
	textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","), "&:hover": {color: "#032541", opacity: 1,},
	"&.Mui-selected": {color: "#032541", fontWeight: 'bold',}, "&.Mui-focusVisible": {backgroundColor: "blue",},}));

//Currency Formatter
const numberFormat = (value) => new Intl.NumberFormat("en-US", {style: "currency", currency: "KES"}).format(value);

const useStyles = makeStyles((theme) => ({
	unpaid: {
		background : 'rgba(220, 53, 69, 0.1)',
		color : "#272d3b",
		borderRadius : "4px",
		padding : "5px"
	},
	paid : {
		background : 'rgba(23, 174, 123, 0.1)',
		color : "#272d3b",
		borderRadius : "4px",
		padding : "5px"
	},
	overPaid : {
		background : 'rgba(23, 174, 123, 0.1)',
		color : "#272d3b",
		borderRadius : "4px",
		padding : "5px"
	},
	partiallyPaid : {
		color : "#272d3b",
		background : 'rgba(255, 133, 3, 0.1)',
		borderRadius : "4px",
		padding : "5px"

	},forfeited : {
		color : "#272d3b",
		background : 'rgba(18, 104, 240, 0.1)',
		borderRadius : "4px",
		padding : "5px"
	}
}));

export default function TenantBioDetails (props) {
	const {id} = useParams()
	//Tenant Data
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [flagState ,setFlagState] = useState('')
	//const {tenantId} = useSelector((store) => store.tenantData.tenantBioData)

	const tenantid =  id
	const classes = tenantStyles()
	const invStatusClass = useStyles()
	
	// // Navigation
	function handleCreatInvoice () {
		props.setFlagStateInvoice()
		//props.setFlagStateInvoice();
		
	}

	//function to add more uniuts to tennat

	function handleAssignMoreUnits(){
		props.moreunitt()
	}
	
	//Create Invoice Modal
	const [createInvoiceModal, setCreateInvoiceModal] = useState(false)
	
	// Utility Columns
	const utilityColumns = [
		{field : 'name', flex : 1, headerAlign : 'left', align : 'left', renderHeader : () => {return <strong className={classes.unitData} >Utility Name</strong>}},
		{field : 'unitOfMeasure', flex : 1, headerAlign : 'left', align : 'left', renderHeader : () => {return <strong className={classes.unitData} >Unity Of Measure</strong>}},
		{field : 'amount', flex : 1, headerAlign : 'left', align : 'left', renderHeader : () => {return <strong className={classes.unitData} >Amount</strong>}},
		{field : 'createdAt', flex : 1, headerAlign : 'left', align : 'left', renderHeader : () => {return <strong className={classes.unitData} >Created On</strong>}},
		{field : 'updatedAt', flex : 1, headerAlign : 'left', align : 'left', renderHeader : () => {return <strong className={classes.unitData} >Updated On</strong>}},
		{
			headerName: "Action", field: 'action', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Action</strong>) },
			renderCell: (params) => {

           console.log(params)
				//const [selectedRowId ,setSelectedRowID] = useState(null)
				const [anchorEl, setAnchorEl] = React.useState(null);
				const ActionOpen = Boolean(anchorEl);
				const handleClick = (event) => {
					console.log(params.row._id)
					setAnchorEl(event.currentTarget)
				};
				const handleActionClose = () => { setAnchorEl(null); };

				function handleFlagState(state) {
					setFlagState(state)
					handleActionClose()
				}
		
///products/edit/${params.row._id

				return (
					<div>
						<MoreVertIcon onClick={handleClick} />
						<StyledMenu anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>

							{/* 
							<MenuItem disableRipple onClick={() => { updateStoreTenantId(params.row.id) }}>
								<DeleteForeverIcon style={{ color: "#DC3545FF" }} /> Remove
							</MenuItem> */}
							<MenuItem disableRipple onClick={()=>navigate(`/products/edit/${params?.row?.productId}`)}>
								<EditIcon style={{ color: "#DC3545FF" }} /> Edit Service
							</MenuItem> 
							<MenuItem disableRipple>
								<DeleteForeverIcon style={{ color: "#DC3545FF" }} /> Delete Service
							</MenuItem>
						</StyledMenu>
					</div>
				)
			}
		},
	]
	
	// Transactions Columns
	const tenantTransactionColumns = [
		{ field: "date", headerName: "Date", flex: 1, headerAlign : 'left', align: 'left',renderHeader : () => {return <strong className={classes.unitData} >Date & Time</strong>} },
		{ field: "itemNo", headerName: "No. of Items", flex: 1, headerAlign : 'left', align: 'left',renderHeader : () => {return <strong className={classes.unitData} >No. of Items</strong>} },
		{ field: "transactionNo", headerName: "Served By", flex: 1, headerAlign : 'left', align: 'left',renderHeader : () => {return <strong className={classes.unitData} >Ref.</strong>} },
		{ field: "amount", headerName: "Amount(KES)", flex: 1, headerAlign : 'left', align: 'left',renderHeader : () => {return <strong className={classes.unitData} >Amount(KES)</strong>} },
		//{ field: "balance", headerName: "Balance(KES)", flex: 1, headerAlign : 'left', align: 'left',renderHeader : () => {return <strong className={classes.unitData} >Balance(KES)</strong>} },
		{ field: "servedBy", headerName: "Balance(KES)", flex: 1, headerAlign : 'left', align: 'left',renderHeader : () => {return <strong className={classes.unitData} >Paid By</strong>} },
	];
	
	// Invoice Columns
	const tenantInvoiceColumns = [
		{field: 'invoiceNumber', headerName: 'Invoice No', flex : 1, align : 'left', headerAlign: 'left', renderHeader : () => {return <strong className={classes.unitData} >Invoice No</strong>}, renderCell : (params) => {return (<Link to={`/invoice/${params.value}`}>{params.value}</Link> )}},
		{field: 'createdAt', headerName: 'Date Issued', flex : 1, align : 'left', headerAlign: 'left', renderHeader : () => {return <strong className={classes.unitData} >Date Issued</strong>},},
		{field: 'invoiceAmount', headerName: 'Amount', flex : 1, align : 'left', headerAlign: 'left', renderHeader : () => {return <strong className={classes.unitData} >Amount</strong>},},
		{field : 'invoiceBalance', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >Paid Amount</strong>)}, renderCell : (params) => {return (
			<span className={params.row.invoiceStatus === 'CANCEL' ? invStatusClass.unpaid : params.row.invoiceStatus === 'Partially Paid' ? invStatusClass.partiallyPaid : params.row.invoiceStatus === 'FORFEIT' ? invStatusClass.forfeited : params.row.invoiceStatus === 'Paid' ? invStatusClass.paid : params.row.invoiceStatus === "Unpaid" ? invStatusClass.unpaid : params.row.invoiceStatus === "OverPaid" ? invStatusClass.overpaid : ""} >{CurrencyFormatter(parseInt(params.row.invoiceAmount) - parseInt(params.value), 'KES')}</span>
		)}},
		{field: 'invoiceType', headerName: 'Type', flex : 1, align : 'left', headerAlign: 'left', renderHeader : () => {return <strong className={classes.unitData} >Type</strong>},},
		{field: 'invoiceStatus', headerName: 'Status', flex : 1, align : 'left', headerAlign: 'left', renderHeader : () => {return <strong className={classes.unitData} >Status</strong>},},
	]
	
	// Receipts Columns
	const tenantReceiptsColumns = [
		{field: 'receiptNo', headerName: 'Receipt No', flex : 1, align : 'left', headerAlign: 'left', renderHeader : () => {return <strong className={classes.unitData} >Receipt No</strong>}, renderCell : (params) => {return (<Link to={`/receipt/${params.value}/${params.row.invoiceNumber}`}>{params.value}</Link> )}},
		{field: 'invoiceNumber', headerName: 'Invoice No', flex : 1, align : 'left', headerAlign: 'left', renderHeader : () => {return <strong className={classes.unitData} >Invoice No</strong>}, renderCell : (params) => {return (<Link to={`/tenantInvoice/${params.value}`}>{params.value}</Link> )}},
		{field: 'issuedDate', headerName: 'Date Issued', flex : 1, align : 'left', headerAlign: 'left', renderHeader : () => {return <strong className={classes.unitData} >Date Issued</strong>}, renderCell : (params) => {return (moment(params.value).format('llll'))} },
		{field: 'period', headerName: 'Period', flex : 1, align : 'left', headerAlign: 'left', renderHeader : () => {return <strong className={classes.unitData} >Period</strong>}, renderCell : (params) => {return (moment(params.value).format('MMMM yyy'))}},
		{field: 'amount', headerName: 'Amount', flex : 1, align : 'left', headerAlign: 'left', renderHeader : () => {return <strong className={classes.unitData} >Amount</strong>}, renderCell : (params) => {return (numberFormat(params.value))}},
	]
	
	// Houses Columns
	const tenantHousesColumns = [
		{field : 'itemNumber', flex : 1, align : 'left', headerAlign : 'left', renderHeader : () => {return <strong className={classes.unitData} >House No.</strong>}},
		{field : 'unitType', flex : 1, align : 'left', headerAlign : 'left', renderHeader : () => {return <strong className={classes.unitData} >House Type</strong>}},
		{field : 'createdAt', flex : 1, align : 'left', headerAlign : 'left', renderHeader : () => {return <strong className={classes.unitData} >Created On</strong>}, renderCell : (params) => {return (moment(params.value).format('llll'))} },
		{field : 'amount', flex : 1, align : 'left', headerAlign : 'left', renderHeader : () => {return <strong className={classes.unitData} >Amount(KES)</strong>}, renderCell : (params) => {return (numberFormat(params.value))}},
		{field : 'action', flex : 1, align : 'left', headerAlign : 'left', renderHeader : () => {return <strong className={classes.unitData} >Action</strong>}, renderCell : (params) => {
			
				//const [selectedRowId ,setSelectedRowID] = useState(null)
				const [anchorEl, setAnchorEl] = React.useState(null);
				const ActionOpen = Boolean(anchorEl);
				const handleClick = (event) => {
					console.log(params.row._id)
					setAnchorEl(event.currentTarget)
				};
				const handleActionClose = () => { setAnchorEl(null); };

				function handleFlagState(state) {
					setFlagState(state)
					handleActionClose()
				}
		


				return (
					<div>
						<MoreVertIcon onClick={handleClick} />
						<StyledMenu anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
							<MenuItem disableRipple onClick={()=>navigate(`/tenant/edit/${params.row._id}`)}>
								<EditIcon style={{ color: "#DC3545FF" }} /> Edit
							</MenuItem> 
							<MenuItem disableRipple>
								<DeleteForeverIcon style={{ color: "#DC3545FF" }} /> Exit Tenant
							</MenuItem>
							<MenuItem disableRipple onClick={handleAssignMoreUnits}>
								<ControlPointIcon style={{ color: "#DC3545FF" }} /> Add House
							</MenuItem>
						</StyledMenu>
					</div>
				)
		}}

	]
	
	
	// set tab values;
	const [tabValue, setChangeTabValue] = useState("Invoice");
	const handleChangeTabValue = (e, newValue) => {setChangeTabValue(newValue);};
	const {X_Authorization} = useSelector((store) => store.user)

	
	//Data, setters & Getters
	const [tenantDetails, setTenantDetails] = useState({})
	const [tenantInfo, setTenantInfo] = useState({tenantName: "", phone:"" , email:"", dayCreated: "", tenantType: "", houseNumber : "", rent : "", houseType : "",pendingInvoice:0, balance : "", utilities : [], billableItems : []});
	const [tenantTransactions, setTenantTransactions] = useState([]);
	const [invoiceRows, setInvoiceRows] = useState([]);
	const [tenantReceipts, setTenantsReceipts] = useState([])
	const [unitId ,setUnitId] = useState('')

	console.log(tenantDetails , 'customer info')

	
	function fetchTenantsReceipts () {
		try {
			HttpComponent({
				method : 'GET',
				url : `/api/get_business_receipts_by_customer?limit=1000&page=1&customerId=${tenantid}`,
				token : X_Authorization
			}).then((data) => {
				console.log(data,'receipts')
				if (data.status === 201) {
					setTenantsReceipts(data.response.data)
				} else {
					console.log(`Error Getting tenants Receipts`)
				}
			}).catch((e) => {
				console.log(`Error Getting tenants Receipts`, e.message)
			})
		} catch (e) {
			console.log(`Error Getting tenants Receipts`, e.message)
		}
	}
	
	
	//Tenant Details
	const fetchTenantDetails = useCallback(() => {
		try {
			HttpComponent({
				method: 'GET',
				url: `/api/get_customer_by_customer_id?customerId=${tenantid}`,
				token: X_Authorization
			})
				.then((data) => {
					if (data.status === 200) {
						setTenantDetails(data.response.data)
						setTenantInfo((prevTenantInfo) => ({
							...prevTenantInfo,
							customerId: data.response.data._id,
							tenantName: data.response.data.firstName + " " + data.response.data.lastName,
							dayCreated: data.response.data.createdAt,
							tenantType: data.response.data.customerType,
							phone: data.response.data?.phone,
							email: data.response.data?.email,
							houseNumber: data.response.data.customerType,
							balance: !data.response.data.pendingAmount ? 0 : data.response.data?.pendingAmount,
							pendingInvoice: data.response.data?.pendingInvoices,
							billableItems: data.response.data?.billableItems,
							utilities: data.response.data?.billableItems[0]?.extraServices,
						}))
					}
				})
				.catch((e) => { console.log(`Error Getting Tenant Data`, e.message) })
		} catch (error) {
			console.log(error.message);
		}
},[])

	

	
	//Tenant Transactions
	const fetchTenantTransactions = async () => {
		try {
			const getTransactionsResponse = await fetch(
				getTenantActivities + `?customerId=${tenantid}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
				}
			);
			await getTransactionsResponse.json()
				.then((data) => {console.log(`Tenant Data`, data); setTenantTransactions(data.transaction)})
				.catch((e) => {console.log(`Error Getting Tenant Data`, e.message)})
		} catch (error) {
			console.log(error.message);
		}
	};
	
	//Tenant invoice
	const fetchInvoiceDetails = async () => {
		try {
			const invoiceDetailsResponse = await fetch(baseUrl+`/api/get_invoice_by_customerId/${tenantid}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
				}
			);
			if (invoiceDetailsResponse.status === 200) {
				const invoiceDetails = await invoiceDetailsResponse.json();
				console.log("invoice data is here", invoiceDetails.data)
				const rowsWithIds = invoiceDetails.data.map(row => {
					const createdAt = new Date(row.createdAt).toLocaleDateString("de-DE", {
						day: "2-digit",
						month: "2-digit",
						year: "numeric"
					});
					return { ...row, id: row._id, createdAt }
				});
				console.log("rows", rowsWithIds)
				setInvoiceRows(rowsWithIds)
			}
		} catch (error) {
			console.error("error fetching invoice details",error.message);
		}
	};
	
	//Tenant Transaction
	const tenantTransaction = tenantTransactions?.length > 0 ? tenantTransactions?.map((customer) => ({
		id: customer?.transactionId,
		date: moment(customer.transactionTime).format('lll'),
		itemNo: customer?.noOfItems,
		servedBy: customer?.approvedBy,
		transactionNo:customer?.transactionNo,
		amount: customer?.amount,
		balance: customer?.customerBalance,
	})): []
	
	// Data useEffects
	useEffect(() => {
		fetchTenantTransactions()
			.then(() => {console.log(`Tenant Data usEffect`)})
			.catch((e) => {console.log(`Error Getting Tenant Data`, e.message)})
		fetchTenantDetails()
		fetchInvoiceDetails()
			.then(() => {console.log(`Tenant Data usEffect`)})
			.catch((e) => {console.log(`Error Getting Tenant Data`, e.message)})
		fetchTenantsReceipts()
	}, [id, tabValue ])



	return (
		<div style={{fontFamily : 'Poppins'}}>


			<Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
				<ArrowBackIosNewIcon/>
				<Typography variant="h6" style={{ color: '#032541', paddingLeft:"10px", fontWeight: 700, fontSize: "25px" }}>Tenant Profile</Typography>
			</Grid>
			{/*Header & Export Buttons */}
			<Grid container justifyContent="space-between" style={{ margin: "0px 0px 20px 0px" }}>
				
				{/*Page Name*/}
				<Grid item>
					<Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
						{breadcrumbs}
					</Breadcrumbs>
				</Grid>
				
				{/*Export Buttons*/}
				<Grid item>
					<Button style={{width: "175px", height: "37px", fontFamily: "Poppins", fontSize: "14px", fontWeight: "500", border : '1px solid #032541',  backgroundColor: "#fff", color: "#032541", margin: "0 12px", padding: "9px 12px 8px",}} onClick={() => navigate(`/tenantStatement/${id}`)}>
						View Statement
					</Button>
					<Button style={{width: "175px", height: "37px", fontFamily: "Poppins", fontSize: "14px", fontWeight: "500", backgroundColor: "#032541", color: "white", margin: "0 12px", padding: "9px 12px 8px",}} startIcon={<AddIcon />} onClick={handleCreatInvoice}>
						Create Invoice
					</Button>
				</Grid>
			</Grid>
			
			{/*Tenant Display Card*/}
			<Grid container justifyContent={'flex-start'} column={1}>
				<Grid item width={'100%'}>
					<Card style={{width:"100%",height:"186px",margin:"14px 43px 19px 0",padding:"33px 125px 33px 30px","borderRadius":"18px",boxShadow:"0 4px 9px 0 rgba(0, 0, 0, 0.16)",backgroundColor:"#fff"}} >
						<Grid container justifyContent={'space-between'} direction={'row'}>
							
							{/*Image and Bio Data*/}
							<Grid item>
								<Grid container direction={'row'} justifyContent={'space-between'} spacing={3}>
									<Grid item>
										<img style={{objectFit: "contain", width: "120px", height: "120px", margin: "0px 10px 0px 0px",}} src={customerDetailssvg} alt="customerdetails"/>
									</Grid>
									
									{/*Bio Data*/}
									<Grid item>
										<Grid container justifyContent={'space-between'} direction={'column'} spacing={1}>
											
											{/*name*/}
											<Grid item>
												<span className={classes.tenantName} >{tenantInfo.tenantName}</span>
											</Grid>
											
											{/*Date Added*/}
											<Grid item>
												<span className={classes.customerType} >Date Created: </span>
												<span className={classes.dateAdded} >{moment(tenantInfo.dayCreated).format('lll')}</span>
											</Grid>
											
											{/*Customer type*/}
											
										
										</Grid>
									</Grid>
								</Grid>
							
							</Grid>
							
							{/*Unit Data*/}
							<Grid item>
								<Grid container justifyContent={'space-between'} direction={'column'} spacing={2.5}> 
									<Grid item>
										<span className={classes.customerType} >Tenant Type: </span>
										<span className={classes.dateAdded} >{tenantInfo.tenantType}</span>
									</Grid>

									{/*House No.*/}
									<Grid item>
										<span className={classes.unitData}>Email: </span>
										<span className={classes.dateAdded}>{tenantInfo.email}</span>
									</Grid>
									
									{/*Rent*/}
									<Grid item>
										<span className={classes.unitData}>Phone No: </span>
										<span className={classes.dateAdded}>{tenantInfo.phone}</span>
									</Grid>
								
								</Grid>
							</Grid>
							
							{/*Utility Data*/}
							<Grid item>
								<Grid container justifyContent={'space-between'} direction={'column'} spacing={2.5}>
									{/*House Type.*/}
									<Grid item display={'flex'} direction={'column'}>
										<span className={classes.unitData}>Pending Invoices: </span>
										<span style={{color:"#17ae7b" , fontSize:"14px" , fontWeight:700}}>{tenantInfo.pendingInvoice}</span>
									</Grid>
									
									{/*Balance*/}
									<Grid item display={'flex'} direction={'column'}>
										<span className={classes.unitData}>Pending Balance : </span>
										<span className={classes.balanceData}>{numberFormat(tenantInfo.balance)}</span>
									</Grid>
								</Grid>
							</Grid>
						
						</Grid>
					</Card>
				</Grid>
			</Grid>
			
			{/*Tenants DataGrids and More Data*/}
			<Grid Container justifyContent={'space-between'}>
				
				{/*Tabs*/}
				<Grid item>
					<TabContext value={tabValue}>
						
						{/*Tabs*/}
						<Box style={{ width: "100%" }}>
							<AntTabs onChange={handleChangeTabValue} variant={'fullWidth'}>
								<AntTab label="Invoice" value="Invoice" />
								<AntTab label="Transactions" value="Transactions" />
								<AntTab label="Receipts" value="Receipts" />
								<AntTab label="Services" value="Services" />
								<AntTab label="Houses" value="Houses" />
							</AntTabs>
						</Box>
						
						{/*Receipt*/}
						<TabPanel value="Receipts">
							{/*{invoiceRows.length > 0 ?*/}
							<DataGrid
								keepNonExistentRowsSelected
								checkboxSelection
								//onSelectionModelChange={(ids) => {const selectedIDs = new Set(ids);const selectedRows = invoiceRows.filter((row) => selectedIDs.has(row._id));setSelectedRows(selectedRows)}}
								getRowId={row => row.transactionId}
								pageSize={5}
								rowsPerPageOptions={[5,10,25]}
								components={{NoRowsOverlay : NoRowsOverlay}}
								sx={{height : '400px', width : 'auto', fontFamily : 'Poppins', fontSize: "10px", color : '#272d3d',boxShadow: 0, border: 0}}
								columns={tenantReceiptsColumns}
								rows={tenantReceipts}
							/>
						</TabPanel>
						
						{/*Houses*/}
						<TabPanel value="Houses">
							<DataGrid
								components={{NoRowsOverlay : NoRowsOverlay}}
								sx={{height : '400px', width : 'auto', fontFamily : 'Poppins', fontSize: "10px", color : '#272d3d',boxShadow: 0, border: 0}}
								columns={tenantHousesColumns}
								rows={tenantInfo.billableItems}
								getRowId={row => row._id}
							/>
						</TabPanel>
						
						{/*Transactions*/}
						<TabPanel value="Transactions">
							<DataGrid
								components={{NoRowsOverlay : NoRowsOverlay}}
								sx={{height : '400px', width : 'auto', fontFamily : 'Poppins', fontSize: "10px", color : '#272d3d',boxShadow: 0, border: 0}}
								columns={tenantTransactionColumns}
								rows={tenantTransaction || []}
								getRowId={row => row.id}
							/>
						</TabPanel>
						
						{/*Invoice*/}
						<TabPanel value="Invoice">
							{invoiceRows.length > 0 ?
								<DataGrid
									components={{NoRowsOverlay : NoRowsOverlay}}
									sx={{height : '400px', width : 'auto', fontFamily : 'Poppins', fontSize: "10px", color : '#272d3d',boxShadow: 0, border: 0}}
									columns={tenantInvoiceColumns}
									getRowId={row => row._id}
									rows={invoiceRows}
								/> : <div>
									<Grid container direction={'column'} justifyContent={'space-between'} alignContent={'center'}>
										<Grid item>
											<img style={{marginTop : '5%', width: "135px", height: "135px", objectFit: "contain",}} src={emptyInvoice} alt="No Invoices Found"/>
										</Grid>
										<Grid item>
											<span style={{alignContent : 'center', textAlign : 'center', fontFamily: "Poppins", fontSize: "10px", fontWeight: "normal",color: "#b4bcc4",}}>No invoices created.</span>
										</Grid>
										{/*<Grid item>*/}
										{/*	<Button style={{width: "175px", height: "45px", fontFamily: "Poppins", fontSize: "14px", fontWeight: "500", backgroundColor: "#032541", borderRadius: "5px", color: "white",}} onClick={() => setCreateInvoiceModal(true)}>*/}
										{/*		Create Invoice*/}
										{/*	</Button>*/}
										{/*</Grid>*/}
									</Grid>
								</div>
							}
						</TabPanel>
						
						{/*Utility*/}
						<TabPanel value="Services">
							<DataGrid
								components={{NoRowsOverlay : NoRowsOverlay}}
								sx={{height : '400px', width : 'auto', fontFamily : 'Poppins', fontSize: "10px", color : '#272d3d',boxShadow: 0, border: 0}}
								columns={utilityColumns}
								rows={tenantInfo.utilities}
								getRowId={row => row._id}
							/>
						</TabPanel>
					</TabContext>
				</Grid>
			</Grid>
			
			{/*Create invoice*/}
			
		 <CreateTenantInvoice 
				customerId={tenantInfo.customerId} 
				billableItems={tenantInfo.billableItems} 
				tenantName={tenantInfo.tenantName} 
				paymentOpen={createInvoiceModal} 
				onClose={() => setCreateInvoiceModal(false)} 
			 />  
		
		</div>
	)
}