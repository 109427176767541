// import { Search } from '@mui/icons-material'
import { Button, FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { height } from "@mui/system";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    border: "solid 1px #d1cbcb",
    marginTop: "-5px",
    borderRadius: "4px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);

const columns = [
  {
    field: "cashierName",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "15px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        Cashier Name
      </strong>
    ),
    renderCell: (params) => {
      return (
          <div>
              <Typography variant="body2" color="textSecondary">
                <Link  to={`/cashier/${params.row.cashierId}`} >
                {params.value}
                </Link>
              </Typography>
          </div>
      );
  },
    
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "cashierBalance",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "15px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        Cashier Balance
      </strong>
    ),
    renderCell: (params) => {
      return (
          <div>
              <Typography variant="body2" color="textSecondary">
                <Link  to={`/cashier/${params.row.cashierId}`} style={{textDecoration:"none"}}>
                {params.value}
                </Link>
              </Typography>
          </div>
      );
  },
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    headerClassName: "super-app-theme--header",
    renderCell: (params) => {
      return (
          <div>
              <Typography variant="body2" color="textSecondary">
                <Link  to={`/cashier/${params.row.cashierId}`} style={{textDecoration:"none"}}>
                {params.value}
                </Link>
              </Typography>
          </div>
      );
  },
    valueFormatter: (params) => {
      // console.log("params", params);
      return numberFormat(params.value);
    },


  },

  {
    field: "branchName",
    renderCell: (params) => {
      return (
          <div>
              <Typography variant="body2" color="textSecondary">
                <Link  to={`/cashier/${params.row.cashierId}`} style={{textDecoration:"none"}}>
                {params.value}
                </Link>
              </Typography>
          </div>
      );
  },
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    headerClassName: "super-app-theme--header",
    renderCell: (params) => {
      return (
          <div>
              <Typography variant="body2" color="textSecondary">
                <Link  to={`/cashier/${params.row.cashierId}`} style={{textDecoration:"none"}}>
                {params.value}
                </Link>
              </Typography>
          </div>
      );
  },
  },
  {
    field: "status",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "15px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        Status
      </strong>
    ),
    renderCell: (params) => {
      const amt = params.row.cashierBalance;
      let color = "";
      let text = "";
      if (amt > 0) {
        color = "red";
        text = "Unsettled";
      } else if (amt == 0) {
        color = "green";
        text = "Settled";
      }
      return <div  variant="body2" > <Typography><div style={{ color, textTransform: "uppercase", fontSize: "14px" }}>{text}</div></Typography></div>
    },
  },
];

const baseUrl = process.env.REACT_APP_BASE_URL;

const ReportAllCashiersSettle = () => {
  useEffect(() => {
    getCashierDetails();
  }, []);
  const [CashierList, setCashierList] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const getCashierDetails = async () => {
    console.log("getCashierDetails");
    try {
      const response = await fetch(`${baseUrl}/api/get_cashier`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      await response.json().then((data) => {
        setCashierList(data.data.cashierList);
        console.log("cashier", data.data.cashierList);
      });
    } catch (e) {
      console.log(e);
    }
  };
  //&&  status == 'all' ? item : status == 'settled' ? item?.status.includes('settled') : item unsettled

  const [status, setStatus] = useState('all')
  console.log(status)
  const rows = CashierList
    .filter((item) => {

      return searchValue?.toLowerCase() === "" ? item : item?.cashierName?.toLowerCase().includes(searchValue?.toLowerCase())
    }).filter((item) => {
      return status === "settle" ? item?.cashierBalance == 0 : status === "unsettled" ? item?.cashierBalance > 0 : item
    })
    .map((item, index) => ({
      id: index,
      cashierId: item.cashierId,
      cashierName: item.cashierName,
      cashierBalance: item.cashierBalance,
      branchName: item.branchName,
    }));

  const handleEvent = () => {
    console.log("i was clicked");
  };
  const handleSearchBox = (event) => {
    setSearchValue(event.target.value);
    console.log(searchValue, "hello search.........................................");
  };






  return (
    <div>
      <div>
        <div>
          <div>
            <h1
              style={{
                margin: " 17px 170px 11px 0",

                fontSize: "25px",
                fontWeight: " 600",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: " 1.32",
                letterSpacing: "normal",
                textAlign: " left",
                color: "#dc3545",
              }}
            >
              Cashier Settlement
            </h1>
            <div className="topbar-filter">
              <div style={{ display: "flex", justifyContent: "space-between", marginRight: "20px" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  Filter by :
                  <FormControl
                    sx={{ m: 1 }}
                    size="small"
                    display="block"
                    style={{ marginLeft: "2rem", marginRight: "3rem" }}
                  >
                    <Select


                      sx={{
                        height: "36px",
                        borderRadius: "6px",
                        fontFamily: "Poppins",
                        border: "solid 1px #707070",
                        width: "170px",
                        marginTop: "-10px",
                        color: "#000",
                      }}
                      labelId="demo-select-small"
                      id="demo-select-small"

                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >

                      <MenuItem value='all'>All</MenuItem>
                      <MenuItem value='settle'>Settled</MenuItem>
                      <MenuItem value='unsettled'>Unsettled</MenuItem>
                    </Select>
                  </FormControl>
                  Filter by Date:
                  <FormControl style={{ marginLeft: "2rem", marginTop: "-1rem", height: "36px" }}></FormControl>
                  <div className="searchButton">
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        value={searchValue}
                        onChange={handleSearchBox}
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                      />
                    </Search>
                  </div>
                </div>

                <div>
                  <Button
                    style={{
                      width: "100px",
                      height: "45px",
                      margin: " -18px 0 9px 102px",
                      padding: "13px 13px 12px 18px",
                      borderRadius: "4px",
                      backgroundColor: " #f5f6f7",
                      color: "#032541",
                    }}
                  >
                    Export <MoreVertIcon />{" "}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="settlementts">
            <DataGrid
              style={{ height: "500px", width: "auto" }}
              rows={rows}
              columns={columns}
              // columns={[]}
              onRowClick={handleEvent}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              pagination
              sx={{
                fontFamily: "Poppins",
                fontSize: "10px",
                color: "#272d3b",
                type: "number",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportAllCashiersSettle;
