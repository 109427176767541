import { Typography ,Breadcrumbs ,Grid ,Button, FormControl, Select, MenuItem, TextField, InputLabel  } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useEffect, useState } from "react";
import HttpComponent from "../../School/MakeRequest";
import { useSelector } from "react-redux";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { useNavigate, useParams } from "react-router-dom";


//breadcrumbs

const breadcrumbs = [
    <Typography key="X" sx={{color:"#707070",fontSize:"0.875rem"}}>
    Dashboard
    </Typography>,
    <Typography key={"add"} sx={{color:"#707070", fontSize:"0.875rem"}}>
    Products / Services Categories
    </Typography>,
    <Typography key={"add"} sx={{color:"#dc3545", fontSize:"0.875rem"}}>
    Edit Product
    </Typography>
];


export default function EditSingleProduct(){
    const [successShow, setSuccessShow] = useState({state: false, message: ""})
	const [errorShow, setErrorShow] = useState({state: false, message: ""})
    const [productCatego ,setProductCategory] = useState([])
    const navigate = useNavigate()

    const {id} = useParams();
    console.log(id, 'service to edit')

    const { X_Authorization } = useSelector((store) => store.user)
    const [formData ,setFormData] = useState({productName:"" ,productCategory:"" ,priceStatus:"" ,productPrice:"",reorderLevel:"",productDescription:"" , buyingPrice:''})


    //fetch detail of the product

    function fetchProductToEdit(){
        try {
            HttpComponent({
                method:'GET',
                url:`/api/findProduct/${id}`,
                token:X_Authorization
            }).then((data)=>{
                console.log(data ,'fetch')
                if(data.status ===  200){
                    setFormData({
                        ...formData,
                        productName:data?.response?.data?.productName,
                        productCategory:data?.response?.data?.productCategory,
                        priceStatus:data?.response?.data?.priceStatus,
                        productPrice:data?.response?.data?.productPrice,
                        reorderLevel:data?.response?.data?.reorderLevel,
                        productDescription:data?.response?.data?.productDescription,
                        buyingPrice:data?.response?.data?.buyingPrice
                    })
                }
            })
            
        } catch (error) {
            
        }
    }

    //useeffect to get the customer  details

    useEffect(()=>{
        fetchProductToEdit()
    },[])

    const handleInputChange =(e)=>{
        const value = e.target.value;
        const name = e.target.name;

        setFormData({ ...formData, [name]: value })


    }

    function fetchProductCategories(){
        try {
            HttpComponent({
                method:'GET',
                url:`/api/listCategories/Active`,
                token:X_Authorization
            }).then((data)=>{
                if(data.status === 200){
                    setProductCategory(data.response.data)
                }
                console.log(data ,'product categoriess')
            })
        } catch (error) {
            
        }
    }

    useEffect(()=>{
        fetchProductCategories()
    },[])

      //service type
      const typeOptions = [
        {
            value:"Fixed",
            label:"Fixed" ,
        },
        {
            value:"Variable",
            label:"Variable" ,
        }
    ]

    const mapOptions = typeOptions?.map((option)=>({value:option.value ,label:option.label}))
    const productCa = productCatego?.map((option)=>(option.categoryName))
    const businessCat = localStorage.getItem('businessCategory')


    //update the product

    function updateProduct(){
        try {
            HttpComponent({
                method:'PUT',
                url:`/api/updateProduct/${id}`,
                body:{
                    productName:formData.productName,
                    productCategory:formData.productCategory,
                    priceStatus:formData.priceStatus,
                    productPrice:formData.productPrice,
                    reorderLevel:formData.reorderLevel,
                    productDescription:formData.productDescription,
                    buyingPrice:formData.buyingPrice,
                    productState:"Edited"
                },
                token:X_Authorization
            }).then((data)=>{

                if(data.status === 202){
                    setSuccessShow({state:true, message:data.response.message})
                    setTimeout(()=>{
                        navigate("/products")
                    },3000)
                } else{
                    setErrorShow({state:true, message:data.response.message})
                    setTimeout(()=>{
                        navigate("/products")
                    },3000)
                }
            })
        } catch (error) {
            
        }
    }

    return (
        <>
         <SuccessAlert vertical="top" horizontal="right" onClose={()=>setSuccessShow({...successShow, state:false})} open={successShow.state} message={successShow.message}/>
			<ErrorAlert vertical="top" horizontal="right" onClose={()=>setErrorShow({...errorShow, state:false})} open={errorShow.state} message={errorShow.message}/>
           <Grid container direction={'column'}>
                <Grid item display={'flex'}  alignContent={'center'} alignItems={'center'} marginBottom={'2px'}>
                    <Typography variant="h6" style={{color:'#032541' , fontWeight:700 , fontSize:"25px"}}> Edit Product</Typography>
                </Grid>
                <Grid item mb={'10px'} mt={'10px'}>
                     <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                            {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
                <Grid item mb={'10px'} mt={'10px'}>
                    <Typography variant="body2" style={{color:'#707070', fontSize:"18px"}}>Edit a Product /Service</Typography>
                </Grid>
                <Grid item mb={'10px'} mt={'10px'}>
                <FormControl sx={{ minWidth: 400 }}>
                    <InputLabel id="demo-simple-select-label">Category :</InputLabel>
                        <Select
                          label="Category :"
                            value={formData.productCategory}
                            onChange={handleInputChange}
                            name="productCategory"
                            displayEmpty
                        >
                            {productCa?.map((option) => {
                                return (
                                    <MenuItem value={option}>{option}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <TextField id="outlined-basic" name="productName" value={formData.productName} onChange={handleInputChange} label="Product Name" style={{fontWeight:200 , width:"400px"}}/>
                </Grid>
                <Grid item display={'flex'} alignItems={'center'} spacing={2}  mt={2} mb={2}>
                <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel id="demo-simple-select-label">Amount Type </InputLabel>
                        <Select
                          label="Category :"
                            value={formData.priceStatus}
                            onChange={handleInputChange}
                            name="priceStatus"
                            displayEmpty
                        >
                            {mapOptions.map((option) => {
                                return (
                                    <MenuItem value={option.value}>{option.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <TextField id="outlined-basic" name="productPrice" value={formData.productPrice} onChange={handleInputChange} label="Amount" style={{fontWeight:200 , marginLeft:'5px' ,width:"200px"}}/>
                </Grid>
                
                <Grid item mt={2} mb={2}>
                    <TextField id="outlined-basic" name="buyingPrice" type="number" value={formData.buyingPrice} onChange={handleInputChange} label="Buying Price" style={{fontWeight:200 , width:"400px"}}/>
                </Grid>
                {businessCat !== 'Rental' &&  businessCat !== 'School' && 
                <Grid item mt={2} mb={2}>
                    <TextField id="outlined-basic" name="reorderLevel" type="number" value={formData.reorderLevel} onChange={handleInputChange} label="Reorder Level" style={{fontWeight:200 , width:"400px"}}/>
                </Grid>
}
                <Grid item  mt={2} mb={2}>
                    <TextField id="outlined-basic" name="productDescription" value={formData.productDescription} onChange={handleInputChange} label="Description" style={{fontWeight:200 , width:"400px"}}/>
                </Grid>
                <Grid container>
                    <Grid item style={{width:"400px"}} display={'flex'} justifyContent={'flex-end'} alignContent={'flex-end'}>
                        <Button onClick={updateProduct}   style={{background:"#032541" ,color:"#fff" ,textTransform:'inherit' , cursor:'pointer', width:"100px"}}>update</Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}