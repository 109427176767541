import { Grid, TextField, Button, Breadcrumbs, MenuItem } from "@mui/material";
import React, { useState, useEffect } from "react";
import { warehousestyles } from "../styles";
import PhoneInput from "react-phone-input-2";
import HttpComponent from "../../School/MakeRequest";
import { SuccessAlertRightAligned } from "../../bills/startorderModals/modals";
import { NavigateNext } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router";
import AddUser from "../warehousecomponents/addUser";
import { ArrowBackIos } from "@material-ui/icons";

const Addsubstore = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  // add warehouse;
  const [success, setSucess] = useState({ state: false, message: "" });
  const [operators, setOperators] = useState([]);
  const businessType = localStorage.getItem("businessCategory");
  // Eatery, Bar and Restaurant
  // Hotel

  const [substoreDetails, setSubstoreDetails] = useState({ storeName: "", storeType: "", description: "" });
  const [storeType, setStoreType] = useState(["SubStore"]);
  const [allInputsFilled, setAllInputsFilled] = useState(false);
  const [storeOperatoruserId, setStoreOperatorUserId] = useState("");

  useEffect(() => {
    if (substoreDetails.storeName) {
      setAllInputsFilled(true);
    } else if (substoreDetails.storeName === "") {
      setAllInputsFilled(false);
    }
  }, [substoreDetails.storeName]);

  const getInputfields = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setSubstoreDetails({ ...substoreDetails, [name]: value });
  };

  const changeUserInput = (e) => {
    setStoreOperatorUserId(e.target.value);
  };

  const addSubstore = () => {
    setSubstoreDetails({ storeName: "", storeType: "", description: "" });
    HttpComponent({
      method: "POST",
      url: `/api/addStore`,
      body: {
        storeName: substoreDetails.storeName,
        // description: substoreDetails.description,
        storeType: "SubStore",
        assignedUserId:storeOperatoruserId
      },
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 201) {
          setSucess({ state: true, message: "substore added successfully!" });
        }

        setTimeout(() => {
          setSucess({ state: false });
          navigate("/substores");
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // get single substore details;

  const getSubstore = () => {
    HttpComponent({
      method: "GET",
      url: `/api/get_store_details?storeType=stores&storeId=${id}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 200) {
          const { name, storeType } = data.response.data[0];

          setSubstoreDetails({ storeName: name, });
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    if (id !== "addsubstore") {
      getSubstore();
    }
  }, [id]);

  const editSubstore = () => {
    HttpComponent({
      method: "POST",
      url: `/api/update_stores_by_id?storeId=${id}`,
      body: { storeName: substoreDetails.storeName,  storeType: "SubStore", assignedUserId:storeOperatoruserId },
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        console.log(data);
        if (data.status === 202) {
          setSucess({ state: true, message: "Substore has been edited sucessfully!" });
          setTimeout(() => {
            setSucess({ state: false });
            getSubstore();
            navigate("/substores");
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getActiveUser = () => {
    HttpComponent({
      method: "GET",
      url: `/api/listUsers/Active`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        //  get only storeoperators;
        const subStoresOperators = data?.response?.data?.filter((store) => {
          if (store.userRole === "STOREOPERATOR") return store;
        });

        setOperators(subStoresOperators);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  useEffect(() => {
    getActiveUser();
  }, []);

  return (
    <div style={warehousestyles.mainDivStyling}>
      <Grid container style={warehousestyles.gridContainerMargin}>
        <Grid item style={{display:'flex', alignItems:'center' , cursor:'pointer'}}>
        <ArrowBackIos onClick={() => navigate(-1)} />  <span style={warehousestyles.supplierText}>Substores</span>
        </Grid>
      </Grid>
      <Grid container style={warehousestyles.gridContainerMargin}>
        <Grid item>
          <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
            <span>Dashboard</span>
            <span onClick={() => navigate(-1)}>Substores</span>
            {id === "addsubstore" ? <span style={{ color: "red" }}>Add Substore</span> : id === "addOperator" ? <span style={{ color: "red" }}>Add Operator</span> : <span style={{ color: "red" }}>Edit Substore</span>}
          </Breadcrumbs>
        </Grid>
      </Grid>

      {id === "addOperator" ? (
        <AddUser storeTypeToAdd={"SubStore"} storeOperatoruserId={storeOperatoruserId} />
      ) : (
        <>
          <Grid container direction={"column"} width={"100%"}>
            <Grid item style={warehousestyles.addsupplierGridItems}>
              <TextField name="storeName" value={substoreDetails.storeName} onChange={getInputfields} style={warehousestyles.addsupplierInputs} label="Substore Name" variant="outlined" />
            </Grid>

            {/* <Grid item style={warehousestyles.addsupplierGridItems}>
              <TextField name="description" value={substoreDetails.description} onChange={getInputfields} style={warehousestyles.addsupplierInputs} label="Substore description" variant="outlined" />
            </Grid> */}

            {/* {businessType === "Hotel" || businessType === "Eatery, Bar and Restaurant" ? (
              <>
                {" "}
                <Grid item style={warehousestyles.addsupplierGridItems}>
                  <TextField style={{ width: "50%" }} value={substoreDetails.storeType} id="outlined-select-meals" select label="Store Type" helperText="" name="storeType" onChange={getInputfields}>
                    {storeType?.map((store, index) => {
                      return (
                        <MenuItem key={index} value={store}>
                          {store}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
              </>
            ) : null} */}

            <Grid item style={warehousestyles.addsupplierGridItems}>
              <TextField id="outlined-select-currency" style={{ width: "50%" }} select label="Assign User(Optional)" onChange={changeUserInput}>
                <MenuItem id="outlined-select-currency" onClick={() => navigate(`/addsubstore/addOperator`)}>
                  Create User
                </MenuItem>

                {operators?.map((option) => (
                  <MenuItem key={option._id} value={option?.userId}>
                    {option?.userName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item style={warehousestyles.addsupplierGridItems}>
              <div style={warehousestyles.rightAlignedButtonHolder}>
                {id === "addsubstore" ? (
                  <Button style={!allInputsFilled ? warehousestyles.createcustomerInactiveBtn : warehousestyles.createCustomerActiveBtn} onClick={() => (allInputsFilled ? addSubstore() : null)}>
                    Add
                  </Button>
                ) : (
                  <Button style={!allInputsFilled ? warehousestyles.createcustomerInactiveBtn : warehousestyles.createCustomerActiveBtn} onClick={() => (allInputsFilled ? editSubstore() : null)}>
                    Edit
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        </>
      )}

      {success && <SuccessAlertRightAligned message={success.message} sucess={success.state} />}
    </div>
  );
};

export default Addsubstore;
