import {useEffect , useState} from 'react'
import { useSelector } from 'react-redux';
import {Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Form from "react-bootstrap/Form";
import moment from "moment-timezone";
import {DataGrid , GridToolbar} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx'
import jsPDF from "jspdf";
import "jspdf-autotable";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./react-bootstrap-table2.min.css"
import "./bootstrap.css"
import {SessionModal} from "../Modals/SessionExpiredModal/sessionModal";
import DateTimeRangePicker from '@wojtekmaj/react-daterange-picker';
import HttpComponent from "../School/MakeRequest";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';


const baseUrl = process.env.REACT_APP_BASE_URL

// Number Format
const numberFormat = (value) => new Intl.NumberFormat('en-US' , {
    style : 'currency' , currency : 'KES'
}).format(value);

// OpenTable Modal Styling
const modalTableStyle = { transform: 'translate(-50%, -50%)',position: 'absolute', top:'50%', left:'50%', width: "900px", height: "350px", padding: "0 0 73.9px", borderRadius: "15px", backgroundColor: "#fff"}

//transactionType
const columns = [
    {
        field: "productName", 
        headerName: 'Product Name',
        flex: 1, 
        align:'left',
        renderHeader: () => (
            <strong 
                style={{
                    fontFamily: "Poppins", 
                    fontSize: "10px", 
                    fontWeight: "600", 
                    color: "#032541"
                }} 
                >
                    {'Product Name'}
            </strong>
            ),
            renderCell: (params) => {
                return (
                  <div>
                    <Typography variant="body2" color="textSecondary">
        
                    {params.value}
        
                    </Typography>
                  </div>
                );
            }
        },
    {
        field: "sellingPrice",
        headerName: "Selling Price",
        flex: 1,
        align: "left",
        renderHeader: () => (
          <strong
            style={{
              fontFamily: "Poppins",
              fontSize: "10px",
              fontWeight: "600",
              color: "#032541",
            }}
          >
            {"Selling Price"}
          </strong>
        ),
        renderCell: (params) => {
            return (
              <div>
                <Typography variant="body2" color="textSecondary">
    
                {numberFormat(params.value || 0)}
    
                </Typography>
              </div>
            );
        }
      },
      {
        field: "buyingPrice",
        headerName: "Buying Price",
        flex: 1,
        align: "left",
        renderHeader: () => (
          <strong
            style={{
              fontFamily: "Poppins",
              fontSize: "10px",
              fontWeight: "600",
              color: "#032541",
            }}
          >
            {"Buying Price"}
          </strong>
        ),
        renderCell: (params) => {
            return (
              <div>
                <Typography variant="body2" color="textSecondary">
    
                {numberFormat(params.value || 0)}
    
                </Typography>
              </div>
            );
        }
      },
      {
        field: "quantitySold",
        headerName: "Quantity Sold",
        flex: 1,
        align: "left",
        renderHeader: () => (
          <strong
            style={{
              fontFamily: "Poppins",
              fontSize: "10px",
              fontWeight: "600",
              color: "#032541",
            }}
          >
            {"Qty Sold"}
          </strong>
        ),
        renderCell: (params) => {
            return (
              <div>
                <Typography variant="body2" color="textSecondary">
    
                {params.value}
    
                </Typography>
              </div>
            );
        }
      },
      {
        field: "totalSales",
        headerName: "Total Sales",
        flex: 1,
        align: "left",
        renderHeader: () => (
          <strong
            style={{
              fontFamily: "Poppins",
              fontSize: "10px",
              fontWeight: "600",
              color: "#032541",
            }}
          >
            {"Total Sales"}
          </strong>
        ),
        renderCell: (params) => {
            return (
              <div>
                <Typography variant="body2" color="textSecondary">
    
                {numberFormat(params.value || 0)}
    
                </Typography>
              </div>
            );
        }
      },
      {
        field: "totalCost",
        headerName: "Total Cost",
        flex: 1,
        align: "left",
        renderHeader: () => (
          <strong
            style={{
              fontFamily: "Poppins",
              fontSize: "10px",
              fontWeight: "600",
              color: "#032541",
            }}
          >
            {"Total Cost"}
          </strong>
        ),
        renderCell: (params) => {
            return (
              <div>
                <Typography variant="body2" color="textSecondary">
    
                {numberFormat(params.value || 0)}
    
                </Typography>
              </div>
            );
        }
      },
    {
        field: "grossMargin",
        headerName: "Gross Margin",
        flex: 1,
        align: "left",
        renderHeader: () => (
          <strong
            style={{
              fontFamily: "Poppins",
              fontSize: "10px",
              fontWeight: "600",
              color: "#032541",
            }}
          >
            {"Gross Margin"}
          </strong>
        ),
        renderCell: (params) => {
            return (
              <div>
                <Typography variant="body2" color="textSecondary">
    
                {numberFormat(params.value || 0)}
    
                </Typography>
              </div>
            );
        }
      },
];

const ReportSalesTotalByProductByPayment = () => {
    // page size setter and getter
    const [pageSize, setPageSize] = useState(5);

    //add days to current date
    function addDays(date, days) {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    // session Modal Config
    const [sessionOpen, setSessionOpen] = useState(false);
    const handleSessionClose = () => setSessionOpen(false)
    const handleSessionOpen = () => setSessionOpen(true);

    //date range Filter setters and Getters
    const [dValue, onDValueChange] = useState([moment (new Date()).format("YYYY-MM-DD HH:MM:ss"), moment(addDays(new Date(),1)).format('YYYY-MM-DD HH:MM:ss')]);

    // Transactions Setters and Getters
    // const [transactions , setTransactions] = useState([]);

    //const dp open state
    const [isOpen, setIsOpen] = useState(false)

    //chart dropDown
    const [dropDown, setDropDown] = useState("7days")

    // Transaction typesetters and Getters
    const [transactionType, setTransactionType] = useState([])
    const [cashiers, setCashiers] = useState([])
    const [productSalesList, setProductSalesList] = useState([])
    const [salesSummary, setSalesSummary] = useState({});

    // payment Type setter and getter
    const [paymentType , setPaymentType] = useState('All');
    const [cashierSelected , setCashierSelected] = useState('All Cashiers');
    const { userId, X_Authorization } = useSelector((store) => store.user);
    const {branchId} = useSelector((store) => store.branch);

    // Filter data grid by transaction type using select input
    const [filteredData , setFilteredData] = useState([]);

    //const
    const [searchValue, setSearchValue] = useState('')

    //"DD-MM-YYYY HH:MM:ss"
    // const [startDate, setStartDate] = useState(new Date());
    // const [endDate, setEndDate] = useState(new Date());

    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 10,
      });

    let totalsList = []

    //   const handleEndDateChange = (value) => {
    //     setEndDate(value)
    //   };

    //   const handleStartDateChange =(value) => {
    //     setStartDate(value)
    // }

    // get product sales
    const getProductSales = async () =>{
        try {
            HttpComponent({
                method: "POST",
                url:`/api/v1/sales/get_product_gross_margin?startDate=${dValue[0]}&endDate=${dValue[1]}&page=${pageState.page}&limit=${pageState.pageSize}`,
                token: X_Authorization,
                body: { 
                    "branchId": branchId
                }
            }).then((data) => {
                console.log('product sales report', data);
                setProductSalesList(data.response.data); 
                setPageState((old) => ({
                ...old,
                isLoading: false,
                data: data.response.data,
                total: data.response.data.count,
                }));
            })
            } catch (error) {
            console.log(error, 'Error data');
        }
    }

  useEffect(() =>{
    getProductSales();
  },[dValue])

  console.log('sales summary data', salesSummary);

  // get product sales summary
  const getProductSalesSummary = async () =>{
    try {
        HttpComponent({
            method: "POST",
            url:`/api/v1/sales/sales_summary?startDate=${dValue[0]}&endDate=${dValue[1]}&page=${pageState.page}&limit=${pageState.pageSize}`,
            token: X_Authorization,
            body: { 
                "branchId": branchId
            }
        }).then((data) => {
            console.log('product sales summary report', data);
            setSalesSummary(data.response.data); 
            setPageState((old) => ({
            ...old,
            isLoading: false,
            data: data.response.data,
            total: data.response.data.count,
            }));
        })
        } catch (error) {
        console.log(error, 'Error data');
    }
}

useEffect(() =>{
getProductSalesSummary();
},[dValue])



    // Currency Formatter
    const numberFormat = (value) => new Intl.NumberFormat('en-US' , {
        style : 'currency' , currency : 'KES'
    }).format(value);

    let count = 0
    const rowData = productSalesList.map(order => {
        return {
            id : ++count ,
            totalSales: order.totalSales,
            quantitySold: order.quantitySold,
            productName: order.productName,
            sellingPrice: order.sellingPrice,
            totalCost: order.totalCost,
            grossMargin: order.grossMargin,
            buyingPrice: order.buyingPrice
        };
    });

    // console.log(rowData, 'Row Data')

    // Fetch Payment Types from DB
    const fetchTransactionTypes = async() => {
        try {
            const response = await fetch(baseUrl + '/api/transactionType',{method : 'GET',headers: {"Accept" : "application/json" , "X-Authorization" : localStorage.getItem('X-Authorization')}});
            const data = await response.json();
            console.log(`datadatatt ${JSON.stringify(data)}`)
            if (response.status === 200 && data.Status === 'SUCCESS') {setTransactionType(data.data)}
            else if (response.status === 401) {handleSessionOpen()}
        } catch (e) {console.log(e)}
    }

    //get_cashiers_by_business
    const fetchCashiers = async() => {
        try {
            const response = await fetch(baseUrl + '/api/get_cashiers_by_business',{method : 'GET',headers: {"Accept" : "application/json" , "X-Authorization" : localStorage.getItem('X-Authorization')}});
            const data = await response.json();
            console.log(`datadata ${JSON.stringify(data)}`)
            if (response.status === 200 && data.Status === 'SUCCESS') {setCashiers(data.data)}
            else if (response.status === 401) {handleSessionOpen()}
        } catch (e) {console.log(e)}
    }

        // //fetch cashiers
    // useEffect(() => {
    //     const fetchCashiers = async() => {
    //         try {
    //             const res = await axios.get(baseUrl + `/get_cashiers_by_business`,{headers:{"Content-Type" : "application/json","Accept" : "application/json","X-Authorization" : localStorage.getItem('X-Authorization')}})
    //             if  (res.status === 200){setCashierList(res.data.data)}
    //             else if (res.status === 401) {handleSessionOpen()}
    //         } catch (e) {console.log(e)}
    //     }
    //     fetchCashiers();
    // },[])

    useEffect( () => {
        const doFetchTransactionTypes = async () => {
            await fetchTransactionTypes()
        }
        doFetchTransactionTypes().then(()=>console.log(transactionType,"check"))

    }, [])


    useEffect( () => {
        const doFetchCashiers = async () => {
            await fetchCashiers()
        }

        doFetchCashiers().then(()=>console.log(cashiers,"check cashiers"))
    }, [])

    const paymnentTypes = transactionType.map(type => {
        return type._id.transactionType
    })

    const handleChange = (event) => {
        setPaymentType(event.target.value);
    }

    //
    const handleCashierChange = (event) => {
        setCashierSelected(event.target.value);
    }

    const handleSearchBox =(event) => {
        setSearchValue(event.target.value)
    }

    // filters
    // date range filter
    const filterByDateRange = (data) => {
        return data.filter((item) => (dropDown === "range"?item.transtime >= moment(new Date(dValue[0]).toDateString()).format("YYYY-MM-DD") && item.transtime <= moment(new Date(dValue[1]).toDateString()).format("YYYY-MM-DD"):
            item.transtime >= dValue[0] && item.transtime <= dValue[1]))
    }

    const filterByPaymentType = (data) => {
        return data.filter((item) => {
            if(paymentType === 'All'){return item}
            else if (item.transactionType === paymentType.includes(paymnentTypes)){return item}
            else if (item.transactionType === paymentType) {return item}
        })
    }

    const filterBySearchBox = (data) => {
        return data.filter((item) => {
            if (item.businessNo.toLowerCase().includes(searchValue.toLowerCase()) ||
                item.businessName.toLowerCase().includes(searchValue.toLowerCase()) ||
                item.transactionID.toLowerCase().includes(searchValue.toLowerCase()) ||
                item.serialNo.toLowerCase().includes(searchValue.toLowerCase()))
            {return item}})}

    useEffect(() => {
        let filteredData = rowData

        filteredData = filterByDateRange(filteredData)
        filteredData = filterByPaymentType(filteredData)
        filteredData = filterBySearchBox(filteredData)
        setFilteredData(filteredData)
    } , [transactionType])


    useEffect(() => {
        let filteredData = rowData

        filteredData = filterByDateRange(filteredData)
        filteredData = filterByPaymentType(filteredData)
        filteredData = filterBySearchBox(filteredData)
        setFilteredData(filteredData)
    } , [dValue,paymentType,searchValue])

    //use Effect
    useEffect( () => {
        if (dropDown === "Today") {
            onDValueChange([moment (new Date()).format("YYYY-MM-DD HH:MM:ss"), moment(addDays(new Date(),1)).format('YYYY-MM-DD HH:MM:ss')])
        } else if (dropDown === "YesterDay") {
            onDValueChange([moment(addDays(new Date(),-1)).format('YYYY-MM-DD HH:MM:ss'), moment(addDays(new Date(),0)).format('YYYY-MM-DD HH:MM:ss')])
        } else if (dropDown === "7days") {
            onDValueChange([moment (addDays(new Date(Date.now()) , -7)).format("YYYY-MM-DD HH:MM:ss"), moment(addDays(new Date(Date.now()),+1)).format('YYYY-MM-DD HH:MM:ss')])
        } else if (dropDown === "this Month") {
            onDValueChange([moment (new Date(new Date().getFullYear(), new Date().getMonth())).format("YYYY-MM-DD HH:MM:ss"), moment(addDays(new Date(Date.now()),+1)).format('YYYY-MM-DD HH:MM:ss')])
        } else if (dropDown === "last Month") {
            onDValueChange([moment (new Date(new Date().getFullYear(), new Date().getMonth() -1)).format("YYYY-MM-DD HH:MM:ss"), moment(new Date(new Date().getFullYear(),new Date().getMonth())).format('YYYY-MM-DD HH:MM:ss')])
        } else if (dropDown === "range") {
            setIsOpen(true)
            handleRange()
            onDValueChange([moment (new Date(new Date().getFullYear(), new Date().getMonth() -1)).format("YYYY-MM-DD HH:MM:ss"), moment(new Date(new Date().getFullYear(),new Date().getMonth())).format('YYYY-MM-DD HH:MM:ss')])
        }
    },[dropDown])

    // handle delay
    function delayChange() {
        setDropDown('Today')
    }

    // handle drop down change
    const dropChange = (event) => {
        setDropDown(event.target.value)
    }

    // handle custom range
    const handleRange = () => {
        const x = document.getElementById('rangeDiv')
        if (x.style.display === 'none') {
            x.style.display  = 'flex'

            // work on open calendar
        } else if (x.style.display === 'flex') {
            x.style.display = 'none'
            setIsOpen(false)
            setTimeout(delayChange, 5000000)

        }
        else {
            x.style.display = 'none'
        }
    }

    // CSV Export Format
    const csvColumns = [
        {label:"Product Name", key:"productName"},
        {label:"Selling Price", key:"sellingPrice"},
        {label:"Buying Price", key:"buyingPrice"},
        {label:"Qty Sold", key:"quantitySold"},
        {label:"Total Sales", key:"totalSales"},
        {label:"Total Cost", key:"totalCost"},
        {label:"Gross Margin", key:"grossMargin"},
    ]

    // CSV Export
    const csvReport = {
        data: rowData,
        headers: csvColumns,
        filename: cashierSelected.toLowerCase()+`_${dValue[0]}-${dValue[1]}_report.csv`
    };
//transactionType
    // columns selection for Excel Export
    const pdfData = rowData.map((order) => {
        return {
            productName: order.productName,
            sellingPrice: order.sellingPrice,
            buyingPrice: order.buyingPrice,
            quantitySold: order.quantitySold,
            totalSales: order.totalSales,
            totalCost: order.totalCost,
            grossMargin: order.grossMargin,
        }
    })
    console.log(pdfData, 'pdfData')
    //Excel Export Format
    const xlsxReport = (e, data) => {
        console.log(data);
        e.preventDefault();
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, cashierSelected.toLowerCase()+`_${dValue[0]}-${dValue[1]}_report.xlsx`);
    };

    const pdfTotal = []
    const data = pdfData.map((data) => [data.productName, numberFormat(data.sellingPrice),numberFormat(data.buyingPrice), data.quantitySold, numberFormat(data.totalSales), numberFormat(data.totalCost), numberFormat(data.grossMargin), moment(data.Date).format('llll'),])
    pdfData.map((data) => {const x = data.salesTotal; pdfTotal.push(x)})

    //PDF export Format
    const exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(12);

        const title = cashierSelected.toUpperCase()+` Sale Report from ${dValue[0]} to ${dValue[1]} For Business ${localStorage.getItem('businessName')} TOTAL AMOUNT ${numberFormat(salesSummary)}`
        const headers = [[
            "Product Name",
            "Selling Price",
            "Buying Price",
            "Quantity Sold",
            "Total Sales",
            "Total Cost",
            "Gross Margin",
        ]];

        console.log(data)

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content)
        doc.save(cashierSelected.toLowerCase()+`_${dValue[0]}-${dValue[1]}_report.pdf`)
    }

    return (
        <div>
            <div>
                <div>
                    <div>

                        {/*sessionModal*/}
                        <SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen} />

                        <Grid container sx={{flex:1}} >
                            <Grid item>
                                <label style={{width: "100%", height: "35px", margin: "10px 32px 21px 12px", fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.32", letterSpacing: "normal", textAlign: "left", color: "#dc3545"}} >Sales Report</label>
                            </Grid>
                        </Grid>
                        <Grid container sx={{margin:"0px 0px 20px 0px"}}>
                            <Grid item >
                                <span style={{width: "265px", height: "28px", margin: "21px 0px 39px 12px", fontFamily: "Poppins", fontSize: "20px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.65", letterSpacing: "normal", textAlign: "left", color: "#6e7074"}} >Dashboard</span>
                                <span style={{width: "265px", height: "28px", margin: "21px 0px 39px 12px", fontFamily: "Poppins", fontSize: "20px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.65", letterSpacing: "normal", textAlign: "left", color: "#6e7074"}} >\</span>
                                <span style={{width: "265px", height: "28px", margin: "21px 0px 39px 12px", fontFamily: "Poppins", fontSize: "20px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.65", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >Sales Report</span>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container style={{ display:'flex', width:'100%', alignItems:'center', justifyContent:'space-between' }}>
                                    <Box sx={{ display:'flex', alignItems:'center' }}>
                                        <Grid item>
                                            <input value={searchValue} onChange={handleSearchBox}
                                            style={{
                                                fontSize:'12px',
                                                color:'#272d3b', 
                                                borderRadius:"4px", 
                                                width:"407px",
                                                 height: "45px", 
                                                 margin: "0 0 0 0px", 
                                                 outline:'none',
                                                 padding: "13px 308px 12px 15px", 
                                                 objectFit: "contain", 
                                                 border: "solid 1px #d8dee2"
                                                 }} 
                                             placeholder='search here' ></input>
                                        </Grid>
                                        <Grid item>
                                            <Form.Select size='sm' aria-label="Default select example" 
                                            style={{
                                                borderRadius:"4px", 
                                                fontSize:'12px',
                                                color:'#272d3b',
                                                width: "172px", 
                                                height: "45px", 
                                                margin:'0px 0 0px 16px' ,
                                                padding: "0px 0px 0px 17px", 
                                                border: "solid 1px #b4bcc4", 
                                                backgroundColor: "#fff"
                                                }}
                                             value={dropDown} onChange={dropChange}>
                                                <option value="Today">Today</option>
                                                <option value="YesterDay">Yesterday</option>
                                                <option value="7days">Last 7 Days</option>
                                                <option value="this Month">This Month</option>
                                                <option value="last Month">Last Month</option>
                                                <option value="range">Custom range</option>
                                            </Form.Select>
                                            <Grid value={dropDown} onChange={dropChange} style={{display:'none'}} id="rangeDiv"  >
                                                <DateTimeRangePicker viewMode="time" dateFormat='YYYY-MM-DD HH:MM:ss' timeFormat='YYYY-MM-DD HH:MM:ss' maxDate={new Date()} isOpen={isOpen} onCalendarClose={handleRange} value={dValue} onChange={onDValueChange} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Grid item alignContent={'right'} sx={{margin: "0px 0px 0px 12px"}}>
                                        <CSVLink style={{borderRadius:"6px", fontWeight:600, color: '#fff',width: "50px", height: "37px", margin: "0 12px 0 0", padding: "9px 10px 8px 11px", backgroundColor: "#05d8e1"}} {...csvReport}> CSV </CSVLink>
                                        <Button onClick={(e) => {xlsxReport(e, pdfData)}} sx={{fontWeight:600,color: '#fff',width: "50px", height: "37px", margin: "0 12px", padding: "9px 7px 8px", backgroundColor: "#032541"}} >Excel</Button>
                                        <Button onClick={() => {exportPDF()}} sx={{fontWeight:600,color: '#fff',width: "50px", height: "37px", margin: "0 0 0 12px", padding: "9px 12px 8px", backgroundColor: "#dc3545"}} >PDF</Button>
                                    </Grid>
                                </Grid>
                                    {/* <Grid item  sx={{margin: "0px 0px 0px 12px"}}>
                                        <Form.Select value={paymentType} onChange={handleChange} aria-label="Default select example" style={{borderRadius:"6px", fontSize:'12px',color:'#272d3b',width: "220px", height: "36px", padding: "0px 0px 0px 17px", border: "solid 1px #b4bcc4", backgroundColor: "#fff"}}>
                                            <option value='All'>All</option>
                                            {transactionType.map((type) => (<option sx={{fontFamily:'Poppins',fontSize:'12px'}} value={type._id.transactionType}>{type._id.transactionType}</option>))}
                                        </Form.Select>
                                    </Grid> */}
                                    {/* <label style={{width: "20px", height: "15px", margin: "35px 0px 12px 20px", fontFamily: "Poppins", fontSize: "10px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >Cashiers</label>
                                    <Grid item  sx={{margin: "0px 0px 0px 12px"}}>
                                        <Form.Select value={cashierSelected} onChange={handleCashierChange} aria-label="Default select example" style={{borderRadius:"6px", fontSize:'12px',color:'#272d3b',width: "220px", height: "36px", padding: "0px 0px 0px 17px", border: "solid 1px #b4bcc4", backgroundColor: "#fff"}}>
                                            <option value='All Cashiers'>All Cashiers</option>
                                            {cashiers.map((cashierItem) => (<option sx={{fontFamily:'Poppins',fontSize:'12px'}} value={cashierItem._id.cashier}>{cashierItem._id.cashier}</option>))}
                                            {/* {cashierList.map((cashierItem) => (<option sx={{fontFamily:'Poppins',fontSize:'12px'}} value={cashierItem._id.cashier}>{cashierItem._id.cashier}</option>))}
                                        </Form.Select> */}
                                        {/* </Form.Select> */}

                                    {/* </Grid> */} 
                    
                                    {/* <label style={{width: "60px", height: "15px", margin: "35px 0px 12px 20px", fontFamily: "Poppins", fontSize: "10px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >Date Range</label> */}
                                    {/* <Grid item xs={4.65}> */}
                                        {/* <DatePicker
                                            selected={startDate}
                                            onChange={date => setStartDate(date)}
                                            showTimeSelect
                                            //showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="yyyy-MM-dd h:mm aa"
                                            /> */}
                                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                            label="From"
                                            value={startDate}
                                            onChange={handleStartDateChange}
                                            renderInput={(params) => <TextField {...params} />}
                                           // format="YYYY-MM-DD HH:MM:ss"
                                            inputFormat="DD-MM-YYYY HH:mm:ss"
                                            format="DD-MM-YYYY HH:MM:ss"
                                            />

                                            <DateTimePicker
                                            label="To"
                                            value={endDate}
                                            onChange={handleEndDateChange}
                                            renderInput={(params) => <TextField {...params} />}
                                           // format="YYYY-MM-DD HH:MM:ss"
                                            inputFormat="DD-MM-YYYY HH:mm:ss"
                                            format="DD-MM-YYYY HH:MM:ss"
                                            />
                                            </LocalizationProvider>
                                     */}
                                    {/* </Grid> */}

                                    {/* <label style={{width: "61px", height: "15px", margin: "35px 0px 12px 20px", fontFamily: "Poppins", fontSize: "10px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >Cashiers</label>
                                    <Grid item  sx={{margin: "0px 0px 0px 12px"}}>
                                        <Form.Select value={paymentType} onChange={handleChange} aria-label="Default select example" style={{borderRadius:"6px", fontSize:'12px',color:'#272d3b',width: "220px", height: "36px", padding: "0px 0px 0px 17px", border: "solid 1px #b4bcc4", backgroundColor: "#fff"}}>
                                            <option value='All'>All</option>
                                            {transactionType.map((type) => (<option sx={{fontFamily:'Poppins',fontSize:'12px'}} value={type._id.transactionType}>{type._id.transactionType}</option>))}
                                        </Form.Select>
                                    </Grid> */}

                                {/*datagrid grid*/}
                                <Grid sx={{ height: "400px", margin: "50px 0 0 0", border: "solid 0px transparent", backgroundColor: "#fff"}}>
                                    <DataGrid 
                                    sx={{fontFamily : 'Poppins' , fontSize : '10px' , color : '#272d3b'}}
                                    rows={rowData}
                                    columns={columns}
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                    pagination
                                    slots={{
                                        footer: CustomFooterStatusComponent,
                                      }}
                                    />
                                    <Box sx={{ display: 'flex', width:'90%', justifyContent:'space-between',alignItems:'center' }}>
                                        <Box>
                                            <Typography><strong>Totals</strong></Typography>
                                        </Box>
                                    <Grid container style={{ 
                                        display:'flex',
                                        width:'50%',
                                        justifyContent:'space-between',
                                        marginTop:'30px'
                                        }}>
                                        <Grid item><strong>{salesSummary.soldQuantity}</strong></Grid>
                                        <Grid item><strong>{salesSummary.totalSales ? numberFormat(salesSummary.totalSales) : 0}</strong></Grid>
                                        <Grid item><strong>{salesSummary.totalCostOfGoodsSold ? numberFormat(salesSummary.totalCostOfGoodsSold) : 0}</strong></Grid>
                                        <Grid item><strong>{salesSummary.grossMargin ? numberFormat(salesSummary.grossMargin) : 0}</strong></Grid>
                                    </Grid>
                                    </Box>
                                </Grid>
                            </Box>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportSalesTotalByProductByPayment

export function CustomFooterStatusComponent(props) {
    return (
      <Box sx={{ p: 1, display: 'flex' }}>
        <FiberManualRecordIcon
          fontSize="small"
          sx={{
            mr: 1,
            color: props.status === 'connected' ? '#4caf50' : '#d9182e',
          }}
        />
        Status {props.status}
      </Box>
    );
  }