import React, { useEffect, useState } from "react";
import { Grid, Breadcrumbs, Tab, styled, Button } from "@mui/material";
import { Add, AddAPhoto, NavigateNext } from "@material-ui/icons";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { customerstyles } from "./styles";
import SearchFilter from "./searchFilter";
import { CustomDate } from "./customDate";
import { Exports } from "./exports";
import OutgoingInvoices from "./outgoinginvoice";
import InvoicingInvoices from "./incominginvoice";
import SavedInvoices from "./savedInvoice";
import Outgoingreceipts from "./outgoingreceipts";
import IncomingReceipts from "./incomingreceipts";

const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#f9f9f8" } });
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: { minWidth: 0 },
  fontWeight: theme.typography.fontWeightRegular,
  //   marginRight: theme.spacing(0),
  margin: "5px",
  fontSize: "16px",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#6e7074",
  backgroundColor: "#f5f6f7",
  borderRadius: "6px",
  fontFamily: ["Poppins"].join(","),
  "&:hover": { color: "#032541", opacity: 1 },
  "&.Mui-selected": { color: "#dc3545", fontWeight: 600, backgroundColor: "", margin: "5px" },
  "&.Mui-focusVisible": { backgroundColor: "blue" },
}));

const CustomerReceipts = () => {
  const navigate = useNavigate();

    // FILTER BY DATE;
  const [dateFrom, setDateFrom] = React.useState("");
  const [dateTo, setDateTo] = React.useState("");

  useEffect(() => {
    // if(dateFrom && dateTo)
    // getFilteredData(dateFrom, dateTo);
  }, [dateFrom, dateTo, ]);

  const [businessReceipts, setBusinessReceipts] = useState("outgoing");

  const changeBusinessReceipts = (event, newTabValue) => {
    setBusinessReceipts(newTabValue);
  };



  return (
    <div style={customerstyles.universalStyle}>
      <Grid container direction={"row"} style={{ margin: "0px 0px 10px 0px" }}  justifyContent={'space-between'} width={'100%'} >
        <Grid item  >
          <span style={customerstyles.invoicesText}>Receipts</span>
        </Grid>

        {/* <Grid item   >
          <Button startIcon={<Add/>} style={customerstyles.createInvoiceButton} onClick={() => navigate(`/createInvoice/new`) } >Create Invoice</Button>
        </Grid> */}
      </Grid>

      <Grid item width={"100%"} xs={12}>
        <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
          <span>Dashboard</span>
          <span style={{ color: "red" }}>Receipts</span>
        </Breadcrumbs>
      </Grid>

      <Grid container style={{ margin: "0px 0px 10px 0px" }}>
        <Grid item width={"100%"}>
          <TabContext value={businessReceipts}>
            <AntTabs onChange={changeBusinessReceipts}>
              <AntTab label="Outgoing" value="outgoing" />
              <AntTab label="Incoming" value="incoming" />


              {/* <AntTab label="Expired" value="Expired" /> */}
            </AntTabs>

            {/* search , filter,  */}
            <Grid container direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
              <Grid item style={customerstyles.searchDateFilter}  >
                <SearchFilter />
                <CustomDate setDateFrom={setDateFrom} setDateTo={setDateTo} />
               
              </Grid>

              <Grid item>
                     {/* <Exports/> */}
              </Grid>
            </Grid>
            {/* active tab panel display */}
            <TabPanel value={"outgoing"}>
               <Outgoingreceipts/>
            </TabPanel>

            {/* suspended! */}
            <TabPanel value={"incoming"}>
                <IncomingReceipts/>
              {/* <InvoicingInvoices/> */}
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomerReceipts;
