import React, {useEffect, useState} from "react";
import {Box, Button, Grid, Modal, Select, Stack, styled, Tab, TablePagination} from "@mui/material";
import MenuItem from "@material-ui/core/MenuItem";
import {CheckCircle, Circle, Close} from "@mui/icons-material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from "@mui/material/TextField";
import {DataGrid} from "@mui/x-data-grid";
import {Link} from "react-router-dom";
let baseUrl = process.env.REACT_APP_BASE_URL

const AntTabs = styled(TabList)({
    borderBottom: "3px solid #e8e8e8",
    "& .MuiTabs-indicator": {
        backgroundColor: "#dc3545",
    },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: "none",
        minWidth: 0,
        [theme.breakpoints.up("sm")]: {
            minWidth: 0,
        },
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        fontSize: "13px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "2.75",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#6e7074",
        fontFamily: ["Poppins"].join(","),
        "&:hover": {
            color: "#032541",
            opacity: 1,
        },
        "&.Mui-selected": {
            color: "#dc3545",
            fontWeight: 600,
        },
        "&.Mui-focusVisible": {
            backgroundColor: "blue",
        },
    })
);

const useStyles = makeStyles({
    table: {
        minWidth: 800,
    },
});

const SchoolStudents = (props) => {
    const [tabValue, setTabValue] = useState("all");
    const classes = useStyles();
    const setNewValue = (event, newValue) => {
        setTabValue(newValue);
    };
    return (
        <>
            <Grid container marginBottom="10px" >
                <Grid item style={{width:"100%"}}>
                    <TabContext value={tabValue}>
                        <Box>
                            <AntTabs onChange={setNewValue}>
                                <AntTab value="all" label="All Students" />
                                <AntTab value="cleared" label="Students Cleared" />
                                <AntTab value="dues" label="Students with Dues" />
                            </AntTabs>
                        </Box>


                        <TabPanel value="all">
                            <div style={{
                                height:"350px",
                                minWidth:"1000px"
                            }}>

                                <Table className={classes.table} aria-label="students data grid">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Student ID</TableCell>
                                            <TableCell>Student Name</TableCell>
                                            <TableCell>Grade</TableCell>
                                            <TableCell>Term</TableCell>
                                            <TableCell>Full Name</TableCell>
                                            <TableCell>Role</TableCell>
                                            <TableCell>Mobile Number</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Pending Invoices</TableCell>
                                            <TableCell>Pending Amount</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                    </TableBody>
                                </Table>

                            </div>

                        </TabPanel>

                        <TabPanel value="cleared">
                            <div style={{
                                height:"350px",
                                minWidth:"1000px"
                            }}>

                                <Table className={classes.table} aria-label="students data grid">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Student ID</TableCell>
                                            <TableCell>Student Name</TableCell>
                                            <TableCell>Grade</TableCell>
                                            <TableCell>Term</TableCell>
                                            <TableCell>Full Name</TableCell>
                                            <TableCell>Role</TableCell>
                                            <TableCell>Mobile Number</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Pending Invoices</TableCell>
                                            <TableCell>Pending Amount</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                    </TableBody>
                                </Table>


                            </div>
                        </TabPanel>

                        <TabPanel value="dues">
                            <div style={{
                                height:"350px",
                                minWidth:"1000px"
                            }}>

                                <Table className={classes.table} aria-label="students data grid">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Student ID</TableCell>
                                            <TableCell>Student Name</TableCell>
                                            <TableCell>Grade</TableCell>
                                            <TableCell>Term</TableCell>
                                            <TableCell>Full Name</TableCell>
                                            <TableCell>Role</TableCell>
                                            <TableCell>Mobile Number</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Pending Invoices</TableCell>
                                            <TableCell>Pending Amount</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                    </TableBody>
                                </Table>

                            </div>
                        </TabPanel>

                    </TabContext>
                </Grid>
            </Grid>
        </>
    )
}

export {SchoolStudents}
