import { Box, Drawer, Grid, Typography } from "@mui/material";
import {useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import profileIcon from './images/profile.svg'
import notificationIcon from './images/notification.svg'
import logout from "./images/logout-icon.png";
import React, { useEffect, useState } from "react";
import AccountingImg from "../images/accounting.svg"
import HttpComponent from "../components/School/MakeRequest";
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import personimg from "../images/person.svg"
import crosimg from "../images/cro.svg"
import notificationIon from "../images/notification.svg"
import DateFormatter from "../utils/dateFormatter";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const UserNavbar = () => {
	const navigate = useNavigate();
	const { group } = useSelector((store) => store.user);
	const [accountStatus, setAccountStatus] = useState({})
	const bsGroup = localStorage.getItem('group')
	const [bsNotifications, setBusinessNotifications] = useState([])
	const [customerDetails , setCustomerDetails] = useState({})
	const userIdd = localStorage.getItem('userId')

	function fetchCustomerDetaisl(){
		try { 
		  HttpComponent({
			method:'GET',
			url:`/api/get_customer_profile_by_userId?customerId=${userIdd}`,
			token: localStorage.getItem('X-Authorization')
		  }).then((data)=>{
			console.log(data , 'customer data here')
			if(data.status === 200){
			  setCustomerDetails(data?.response?.data)
			}
		  })
		} catch (error) {
		  console.log(error , 'customer data here')
		}
	  }
	
	  useEffect(()=>{
		fetchCustomerDetaisl()
	  },[userIdd])

	  const {firstName ,secondName ,userName,userPhone,userEmail} = customerDetails
	   
  const fullName = firstName + secondName;

	function getAllNotifications() {
		try {
		  HttpComponent({
			method: 'GET',
			url: `/api/v1/notification/all`,
			token: localStorage.getItem('X-Authorization')
		  }).then((data) => {
			setBusinessNotifications(data?.response?.data)
		  })
	
		} catch (error) {
	
		}
	  }
	
	  useEffect(() => {
		getAllNotifications()
	  }, [])


	//get accounting status

	function getAccountingStatus() {
		try {
			HttpComponent({
				method: "POST",
				url: '/api/v1/check_accounting_status',
				token: localStorage.getItem('X-Authorization'),
			}).then((data) => {
				if (data.status === 200) {
					setAccountStatus(data.response.data)
				}
			})
		} catch (error) {
		}
	}

	useEffect(() => {
		getAccountingStatus()
	}, [])

	const { accountingEnabled } = accountStatus

	const accounturl = process.env.REACT_APP_ACCOUNTING_URL

	const userGroup = localStorage.getItem('group')
	const hideAcc = userGroup === 'Customer' || userGroup === 'Cashier' || userGroup === 'STOREMANAGER' || userGroup === 'StoreOperator'
	const userId = localStorage.getItem('userId')
	let companyId = localStorage.getItem('zedAccountingId')

	const openInSameTab = () => {
		//alert(`${accounturl}?id=${userId}&companyId=${companyId}`)
		 window.open(`${accounturl}?id=${userId}&companyId=${companyId}`, '_self');
	};

 const [state, setState] = useState({ right: false });
  const [profileState, setProfileState] = useState({ right: false })

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const toggleNotifications = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setProfileState({ ...profileState, [anchor]: open });
  };

  const handleContentClick = (event) => {
    event.stopPropagation();
  };
	const notificationList = (anchor) => (
		<Box
		  sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 400 }}
		  role="presentation"
		  onClick={toggleDrawer(anchor, false)}
		  onKeyDown={toggleDrawer(anchor, false)}
		>
		  <List onClick={handleContentClick}>
			<Grid mt={5} p py={3} item display={'flex'}>
			  <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
				<Grid item display={'flex'} alignItems={'center'}>
				  <img src={notificationIon} alt='person img' /> <Typography style={{ fontWeight: 600, color: "#032541", marginLeft: '10px', fontSize: "18px" }}>Notifications</Typography>
				</Grid>
				<Grid item>
				  <img style={{ cursor: "pointer" }} onClick={toggleNotifications(anchor, false)} src={crosimg} alt="" />
				</Grid>
			  </Grid>
			</Grid>
			<Divider />
			<Grid item px={5} mt={2} display={'flex'} direction={'column'}>
			  <Grid container display={'flex'} direction={'column'}>
				{bsNotifications?.map((notification) => {
				  return (
					<Grid item my={2} display={'flex'} alignItems={'center'}>
					  <FiberManualRecordIcon style={{color:"#dc3545" , fontSize:"10px"}} />
					  <div style={{display:"flex" , flexDirection:"column" , marginLeft:"10px"}}>
						<Typography style={{ color: "#032541", fontWeight: 500, fontSize: "14px" }}>{notification?.title}</Typography>
						<Typography style={{ color: "#a1a7b2", fontWeight: 500, fontSize: "14px" }}>{notification?.message}</Typography>
						<Typography style={{ color: "#a1a7b2", fontWeight: 500, fontSize: "14px" }}>{DateFormatter(notification?.updatedAt)}</Typography>
					  </div>
					</Grid>)
				})}
			  </Grid>
			</Grid>
		  </List>
		</Box>
	  )
	  const list = (anchor) => (
		<Box
		  sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 400 }}
		  role="presentation"
		  onClick={toggleDrawer(anchor, false)}
		  onKeyDown={toggleDrawer(anchor, false)}
		>
		  <List onClick={handleContentClick}>
			<Grid mt={5} p py={3} item display={'flex'}>
			  <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
				<Grid item display={'flex'} alignItems={'center'}>
				  <img src={personimg} alt='person img' /> <Typography style={{ fontWeight: 600, color: "#032541", marginLeft: '10px', fontSize: "18px" }}>My profile</Typography>
				</Grid>
				<Grid item>
				  <img style={{ cursor: "pointer" }} onClick={toggleDrawer(anchor, false)} src={crosimg} alt="" />
				</Grid>
			  </Grid>
			</Grid>
			<Divider />
			<Grid item px={5} mt={2}>
			  <Typography style={{ color: "#dc3545" }}>Personal Information</Typography>
			</Grid>
			<Grid item px={5} mt={2} display={'flex'} direction={'column'}>
			  <Grid container display={'flex'} direction={'column'}>
				<Grid item my={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
				  <Typography style={{ color: "#032541", fontWeight: 500, fontSize: "14px" }}>Full Names</Typography>
				  <Typography style={{ color: "#a1a7b2", fontWeight: 500, fontSize: "14px" }}>{fullName}</Typography>
				</Grid>
				<Grid item my={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
				  <Typography style={{ color: "#032541", fontWeight: "500px", fontSize: "14px" }}> Username</Typography>
				  <Typography style={{ color: "#a1a7b2", fontWeight: 500, fontSize: "14px" }}>{userName}</Typography>
				</Grid>
				<Grid item my={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
				  <Typography style={{ color: "#032541", fontWeight: 500, fontSize: "14px" }}>Email Address</Typography>
				  <Typography style={{ color: "#a1a7b2", fontWeight: 500, fontSize: "14px" }}>{userEmail}</Typography>
				</Grid>
				<Grid item my={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
				  <Typography style={{ color: "#032541", fontWeight: 500, fontSize: "14px" }}>Phone Number</Typography>
				  <Typography style={{ color: "#a1a7b2", fontWeight: 500, fontSize: "14px" }}>{userPhone}</Typography>
				</Grid>
				<Grid item my={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
				  <Typography style={{ color: "#032541", fontWeight: 500, fontSize: "14px" }}>User Group</Typography>
				  <Typography style={{ color: "#a1a7b2", fontWeight: 500, fontSize: "14px" }}>{userGroup}</Typography>
				</Grid>
			  </Grid>
			</Grid>
		  </List>
		</Box>
	  );

	

	return (
		<div style={{ fontFamily: 'Poppins' }} >
			{/*businessName and Action Buttons Container*/}
			<Grid container justifyContent="space-between" alignItems="center" direction={'row'} marginBottom="2%" padding="2px">

				{/* Business Name Label */}
				<Grid item>
					{<span style={{ fontSize: "1.25rem", fontWeight: "700" }}>{localStorage.getItem("businessName") === "All" ? null : localStorage.getItem("businessName")}</span>}
				</Grid>

				{/*Action buttons*/}
				<Grid item display={'flex'}>
					{accountingEnabled === true && !hideAcc ?(
						<div onClick={openInSameTab} style={{ display: "flex", cursor: "pointer", alignContent: "center", alignItems: "center", marginRight: "15px" }}>
							<img src={AccountingImg} style={{ width: "34px", height: "34px", objectFit: "contain" }} alt="Accounting status" />
						</div>):null
					}
					<div>
            {['right'].map((anchor) => (
              <div key={anchor}>
                <img onClick={toggleDrawer(anchor, true)} style={{ width: "34px", height: "34px", objectFit: "contain", backgroundColor: "#C4E0F8", borderRadius: "50%", border: "1px solid transparent", padding: "2px", marginRight: "8px", cursor: "pointer" }} src={profileIcon} alt={"img"} />
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </div>
            ))}
          </div>

          <div>
            {['right'].map((anchor) => (
              <div key={anchor}>
                <img onClick={toggleNotifications(anchor, true)} style={{ width: "34px", height: "34px", objectFit: "contain", backgroundColor: "#C4E0F8", borderRadius: "50%", border: "1px solid transparent", padding: "2px", marginRight: "8px", cursor: "pointer" }} src={notificationIcon} alt={"img"} />
                <Drawer
                  anchor={anchor}
                  open={profileState[anchor]}
                  onClose={toggleNotifications(anchor, false)}
                >
                  {notificationList(anchor)}
                </Drawer>
              </div>
            ))}
          </div>
					<img style={{ width: "40px", height: "40px", objectFit: "contain", backgroundColor: "#C4E0F8", borderRadius: "50%", border: "1px solid transparent", padding: "2px", marginRight: "8px", cursor: "pointer" }} src={logout} alt={'img'} onClick={() => { navigate("/logout"); }} />
				</Grid>
			</Grid>
		</div>
	)
}
export { UserNavbar }
