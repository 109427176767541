import React, {useEffect, useState} from 'react';
import {Box, Button, Typography} from "@mui/material";
import CustomDropZone from "./CustomDropZone";
import ServiceCategoryForm from "./ServiceCategoryForm";
import ServiceForm from "./ServiceForm";
import CustomInputLabel from "./CustomInputLabel";
import CustomTextField from "./CustomTextField";
import PhoneInput from "react-phone-input-2";
import {useDispatch} from "react-redux";
import CustomSelectField from "./CustomSelectField";
import {addParent} from "../../features/customerSlice";


const ParentGuardianForm = (props) =>{
    const [addAnother, setAddAnother] = useState(false)
    const [formData, setFormData] = useState(
        {
            firstName:"",
            lastName:"",
            gender:"",
            email:"",
            mobileNumber:"",
            address:""
        }
    )
    const [formDataTwo, setFormDataTwo] = useState(
        {
            firstName:"",
            lastName:"",
            gender:"",
            email:"",
            mobileNumber:"",
            address:""
        }
    )
    const buttonStyles =
        {"minWidth":"7.813rem",
            "height":"2.813rem",
            "borderRadius":"4px",
        }

    const cancelButtonStyles =
        {"width":"7.813rem",
            "height":"2.813rem",
            "borderRadius":"4px",
            "border":"solid 1px #dc3545",
            "color":"#dc3545"
        }

    const doneButtonStyles ={
        "width":"7.813rem",
        "height":"2.813rem",
        "borderRadius":"4px",
        "backgroundColor":"#032541",
        "color":"white",
        "marginLeft":2,
        '&:hover': {
            backgroundColor: '#032541',
            color: 'white'
        }
    }

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    // Handle Change Two
    const handleInputChangeTwo = (e) => {
        setFormDataTwo({
            ...formDataTwo,
            [e.target.name]: e.target.value,
        });
    };


    const genderOptions = [
        {value:"Male", label:"Male"},
        {value:"Female", label:"Female"}
    ]

    const dispatch = useDispatch();

    const submit = () =>{
        let newObject = {
            firstName:formData.firstName,
            lastName:formData.lastName,
            paymentType:"Prepaid",
            phone: "+"+formData.mobileNumber,
            amountReceived:0,
            customerAddress:formData.address,
            businessId:"",
            customerType:"Individual",
            email:formData.email,
            gender:formData.gender
        }
        dispatch(addParent(newObject));
        console.log('Parent data 1' , newObject)
        setAddAnother(true)
        // props.setView("student")
    }
    const submitTwo = () =>{
        let newObject2 = {
            firstName:formDataTwo.firstName,
            lastName:formDataTwo.lastName,
            paymentType:"Prepaid",
            phone: "+"+formDataTwo.mobileNumber,
            amountReceived:0,
            customerAddress:formDataTwo.address,
            businessId:"",
            customerType:"Individual",
            email:formDataTwo.email,
            gender:formDataTwo.gender
        }
        dispatch(addParent(newObject2));
        console.log('Parent2 data' , newObject2)
        setAddAnother(true)
        props.setView("student")
    }


    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const [validEmail, setValidEmail] = useState(true);

    useEffect(() => {
        if(formData.email != ""){
            let result = validateEmail(formData.email);
            setValidEmail(result);
            // console.log("email valid", result)
        }else{
            setValidEmail(true);
        }
    }, [formData.email]);


    return(
        <Box component="div" sx={{display:"flex", justifyContent:"center", margin:2, width:"100%"}}>
            {addAnother ? <>
                <Box component="div" sx={{display:"flex", flexDirection:"column", width:"100%"}}>
                    <h4 style={{fontSize:"16px", fontWeight:"600", color:"#707070", textAlign:"center"}}>Add Secondary Parent/Guardian (Optional)</h4>
                <Box component="div" sx={{display:"flex"}}>
                    <Box component="div" sx={{display:"flex", flexDirection:"column", marginX:1}}>
                        <CustomInputLabel required={true} label={"First Name"}/>
                        <CustomTextField value={formDataTwo.firstName} onChange={handleInputChangeTwo} name={"firstName"} placeholder={"First Name"} />

                        <CustomInputLabel required={true} label={"Gender"}/>
                        <CustomSelectField value={formDataTwo.gender} onChange={handleInputChangeTwo}  name={"gender"} placeholder={"Select Gender"} options={genderOptions} />
                        {/*<CustomTextField value={formData.gender} onChange={handleInputChangeTwo} name={"gender"} placeholder={"Select Gender"} />*/}

                        <CustomInputLabel required={true} label={"Email"}/>
                        {!validEmail ?
                            <Typography sx={{color:"#dc3545", fontSize: "0.75rem"}}>
                                Please enter a valid email
                            </Typography>
                            :
                            null
                        }
                        <CustomTextField value={formDataTwo.email} onChange={handleInputChangeTwo} name={"email"} placeholder={"Email"} />
                    </Box>


                    <Box component="div" sx={{display:"flex", flexDirection:"column", marginX:1}}>
                        <CustomInputLabel required={true} label={"Last Name"}/>
                        <CustomTextField value={formDataTwo.lastName} onChange={handleInputChangeTwo} name={"lastName"} placeholder={"Last Name"} />

                        <CustomInputLabel required={true} label={"Mobile Number"}/>
                        <Box component="div" sx={{marginTop:1, marginBottom:2}}>
                            <PhoneInput inputStyle={{height : '3.438rem'}} required country={"ke"} enableSearch={true} value={formDataTwo.mobileNumber} name={"mobileNumber"}  onChange={(phone) => formDataTwo.mobileNumber=phone}/>
                        </Box>
                        {/*<CustomTextField value={formData.mobileNumber} onChange={handleInputChangeTwo} name={"mobileNumber"} placeholder={"Mobile Number"} />*/}

                        <CustomInputLabel required={true} label={"Physical Address"}/>
                        <CustomTextField value={formDataTwo.address} onChange={handleInputChangeTwo} name={"address"} placeholder={"Physical Address"} />
                    </Box>
                </Box>

                <Box component="div" sx={{width:"95%", display:"flex", justifyContent:"end"}}>
                    <Box component="div">
                    <Button sx={{ ...buttonStyles, marginRight:1, color:"red", '&:hover': {     backgroundColor:"transparent",     color: 'red' }}} onClick={()=>props.setView("student")}>
                            Skip
                        </Button>
                        <Button sx={cancelButtonStyles} onClick={()=>props.setView("initial")}>
                            Cancel
                        </Button>

                        <Button
                            sx={doneButtonStyles}
                            onClick={submitTwo}
                            disabled={!formDataTwo.firstName.trim()
                                || !formDataTwo.lastName.trim()
                                || !formDataTwo.gender.trim()
                                || !formDataTwo.email.trim()
                                || !formDataTwo.mobileNumber.trim()
                                || !formDataTwo.address.trim()
                        }
                        >
                            Next
                        </Button>
                    </Box>

                </Box>
            </Box>
            </> : <>
            <Box component="div" sx={{display:"flex", flexDirection:"column", width:"100%"}}>
                     <h4 style={{fontSize:"16px", fontWeight:"600", color:"#707070",textAlign:"center" }}>Add Primary Parent/Guardian <strong style={{color:"#DC3545"}}> (Complusory)</strong> </h4>
                <Box component="div" sx={{display:"flex"}}>
                    <Box component="div" sx={{display:"flex", flexDirection:"column", marginX:1}}>
                        <CustomInputLabel required={true} label={"First Name"}/>
                        <CustomTextField value={formData.firstName} onChange={handleInputChange} name={"firstName"} placeholder={"First Name"} />

                        <CustomInputLabel required={true} label={"Gender"}/>
                        <CustomSelectField value={formData.gender} onChange={handleInputChange}  name={"gender"} placeholder={"Select Gender"} options={genderOptions} />
                        {/*<CustomTextField value={formData.gender} onChange={handleInputChange} name={"gender"} placeholder={"Select Gender"} />*/}

                        <CustomInputLabel required={true} label={"Email"}/>
                        {!validEmail ?
                            <Typography sx={{color:"#dc3545", fontSize: "0.75rem"}}>
                                Please enter a valid email
                            </Typography>
                            :
                            null
                        }
                        <CustomTextField value={formData.email} onChange={handleInputChange} name={"email"} placeholder={"Email"} />
                    </Box>


                    <Box component="div" sx={{display:"flex", flexDirection:"column", marginX:1}}>
                        <CustomInputLabel required={true} label={"Last Name"}/>
                        <CustomTextField value={formData.lastName} onChange={handleInputChange} name={"lastName"} placeholder={"Last Name"} />

                        <CustomInputLabel required={true} label={"Mobile Number"}/>
                        <Box component="div" sx={{marginTop:1, marginBottom:2}}>
                            <PhoneInput inputStyle={{height : '3.438rem'}} required country={"ke"} enableSearch={true} value={formData.mobileNumber} name={"mobileNumber"}  onChange={(phone) => formData.mobileNumber=phone}/>
                        </Box>
                        {/*<CustomTextField value={formData.mobileNumber} onChange={handleInputChange} name={"mobileNumber"} placeholder={"Mobile Number"} />*/}

                        <CustomInputLabel required={true} label={"Physical Address"}/>
                        <CustomTextField value={formData.address} onChange={handleInputChange} name={"address"} placeholder={"Physical Address"} />
                    </Box>
                </Box>

                <Box component="div" sx={{width:"95%", display:"flex", justifyContent:"end"}}>
                    <Box component="div">
                        <Button sx={cancelButtonStyles} onClick={()=>props.setView("initial")}>
                            Cancel
                        </Button>

                        <Button
                            sx={doneButtonStyles}
                            onClick={submit}
                            disabled={!formData.firstName.trim()
                                || !formData.lastName.trim()
                                || !formData.gender.trim()
                                || !formData.email.trim()
                                || !formData.mobileNumber.trim()
                                || !formData.address.trim()
                        }
                        >
                            Next
                        </Button>
                    </Box>

                </Box>
            </Box>
            </>}
            
         
        </Box>
    )
}

export default ParentGuardianForm;
