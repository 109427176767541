import { Grid, Breadcrumbs, Typography, Button, TextField} from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useState } from "react";
import HttpComponent from "../../../School/MakeRequest";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Zed-Payit</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Outlets</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Create Outlet</Typography>
]


export default function CreateTerminalOutlets() {

    const [outletName, setOutletName] = useState('')
    const [outletDescription, setOutletDescription] = useState('')
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })

    // create a outlet

    const navigate = useNavigate()

    const formData = {
        storeName: outletName,
        description: outletDescription,
        storeType:'outlet'
    }

    function addOutlet() {
        try {
            HttpComponent({
                method: "POST",
                url: `/api/addStore`,
                body: formData,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data)
                if (data.status === 201){
                    setSuccessShow({message:"Outlet created successfully" , state:true})
                }else{
                    setErrorShow({message:"Error creating outlet" , state:true})
                }
                setTimeout(()=>{
                    navigate(-1)
                },[1000])
            })
        } catch (error) {

        }
    }

    return (
        <Grid direction={'column'} container>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{cursor:"pointer"}} onClick={()=>navigate(-1)}/>
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Create Outlet</Typography>
            </Grid>
            <Grid item display={'flex'} mt={2}>
                <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                    <Grid item>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={3}>
                <Typography style={{ fontWeight: 600, color: "#707070", fontSize: "18px" }}>Outlet Details</Typography>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <TextField id="outlined-basic" onChange={(e)=>setOutletName(e.target.value)} style={{ width: "100%" }} value={outletName} label="Name" variant="outlined" />
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <TextField id="outlined-basic"  onChange={(e)=>setOutletDescription(e.target.value)} style={{ width: "100%" }} value={outletDescription} label="Description" variant="outlined" />
            </Grid>
            <Grid mt={2} item style={{ width: "50%" }} display={'flex'} justifyContent={'flex-end'}>
                <Button onClick={()=>navigate(-1)} style={{ background: "#fff", color: "#DC3545", border: "1px solid #DC3545", width: "125px", marginRight: "5px", textTransform: "inherit" }}>Cancel</Button>
                <Button onClick={addOutlet} style={!outletName || !outletDescription ? {opacity:0.4 ,width: "125px",background: "#032541", textTransform: "inherit",}:{ background: "#032541", textTransform: "inherit", width: "125px", color: "#fff" , opacity:1 }}>Submit</Button>
            </Grid>
        </Grid>
    )
}