import React, { useState, useEffect } from "react";
import { stockStyles } from "../stockStyling";
import { ErrorAlertRightAligned, SuccessAlertRightAligned } from "../../../bills/startorderModals/modals";
import { Grid, Button } from "@mui/material";
import SearchFilter from "../../../customerAccounts/customerComponents/searchFilter";
import StockFunctions from "../stockEndpoints";
import CustomDataGrid from "../customDataGrid";
import { Delete } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";

const TransferStockBatch = ({ storeFromId, storeToId, storeFromName, storeNameTo }) => {
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const [isSuccess, setSuccess] = useState({ state: false, message: "" });
  const [isError, setIsError] = useState({state:false, message:""})

  //DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  useEffect(() => {
    const stockTakeItems = JSON.parse(localStorage.getItem("stockTransferProducts"));
    const newArray = [];
    stockTakeItems.map((stock, index) => {
      if (stock?.changed) {
        newArray.push(stock);
      }
    });
    setPageState({...pageState , data: newArray, count: newArray?.length });
  }, []);

  useEffect(() => {
    if (isSuccess.state) {
      setTimeout(() => {
        setSuccess({ state: false });
      }, 2000);
    }
  }, [isSuccess.state]);

  const stockTransferBatch = [
    { field: "productName", headerName: "Product Name", flex: 1 },
    { field: "inStock", headerName: "In Stock", flex: 1 },
    { field: "quantity", headerName: "Transfer Qty", flex: 1 },
    {
      field: "Action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return <Delete style={{ color: "red", cursor: "pointer" }} onClick={() => removeItem(params.row.id)} />;
      },
    },
  ];

  const removeItem = (id) => {
    pageState?.data?.filter((prod) => prod.id !== id);
    const removeProducts = [...pageState?.data];
    // get index
    const currentIndex = removeProducts.findIndex((prod) => prod.id === id);
    removeProducts.splice(currentIndex, 1);
    localStorage.setItem("stockTransferProducts", JSON.stringify(removeProducts));
    setPageState({...pageState,  data: removeProducts, count: removeProducts?.length });
  };

  // stock to send to db;

  const newStockProducts = pageState?.data
  ?.filter((product) => {
    return inputValue?.toLocaleLowerCase() === "" ? product : product?.productName?.toLocaleLowerCase().includes(inputValue?.toLocaleLowerCase())
  })
  ?.map((stock) => {
    if (stock.changed)
      return {
        id: stock?.id || stock._id,
        productName: stock?.productName,
        inStock: stock?.inStockQuantity,
        quantity: Number(stock?.newStock),
        productId: stock?._id,
      };
  });

  const submitBatch = () => {
    const body = { from: storeFromId, to: storeToId, products: newStockProducts };
    const stockfunctions = new StockFunctions();
    stockfunctions.createStockTranferRequest(body).then((data) => {
      if (data.status === 201) {
        setSuccess({ state: true });
        localStorage.removeItem("stockTransferProducts");
        setTimeout(() => {
          setSuccess({ state: false });
          navigate(`/stock`);
        }, 2000);
      }else if(data?.status === 400){
        setIsError({state: true, message : data.response.message})
      }

    }).catch((e) => {
      console.log(e);
    })
  };

  const batchAdded = <SuccessAlertRightAligned sucess={isSuccess.state} message={"Batch added succesfully!"} />;
  const ERROR = <ErrorAlertRightAligned error={isError.state} message={isError.message} />
  const clearStage = () => {
    localStorage.removeItem("stockTransferProducts");
    navigate(`/transferstock/transfer`);
  };

  return (
    <div style={{ width: "100%" }}>
      {isSuccess.state && batchAdded}
      {isError.state && ERROR }
      <Grid container>
        <Grid item>{/* <Breadcrumbcomponent breadcrumbs={breadcrumb} /> */}</Grid>
      </Grid>

      <Grid container style={stockStyles.batchDetailsHolder} width={"100%"} alignItems={"center"} justifyContent={"space-between"} direction={"row"} margin={"0px 0px 1% 0px"}>
        <Grid item>
          <span style={{ margin: "0% 0% 0% 0%" }}>Batch No:</span>
          <span style={stockStyles.submitBatchdisplay}>xxxx</span>
        </Grid>

        <Grid item>
          <span style={{ margin: "0% 5% 0% 0%" }}>Transfer From:</span>
          <span style={stockStyles.submitBatchdisplay}>{storeFromName}</span>
        </Grid>

        <Grid item>
          <span style={{ margin: "0% 5% 0% 0%" }}>Transfer To:</span>
          <span style={stockStyles.submitBatchdisplay}>{storeNameTo}</span>
        </Grid>
      </Grid>

      <Grid container direction={"row"} width={"100%"} alignItems={"center"} justifyContent={"space-between"} margin={"0px 0px 1% 0px"}>
        <Grid item>
          <SearchFilter setInputValue={setInputValue} placeholder={"Search Products"} />
        </Grid>
        <Grid item sx={{ display: "flex", gap: "20px", width: "50%", justifyContent: "flex-end" }}>
          <Button style={stockStyles.cancelButton} onClick={clearStage}>
            Clear
          </Button>
          <Button style={stockStyles.viewBatchButton} onClick={() => submitBatch()}>
            Submit Batch
          </Button>
        </Grid>
      </Grid>

      <Grid container>
        <CustomDataGrid column={stockTransferBatch} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={newStockProducts} />
      </Grid>
    </div>
  );
};

export default TransferStockBatch;
