import { Breadcrumbs,Button , FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Tab, Typography, styled } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useEffect, useState } from "react";
import { CustomDate } from "./customDate";
import HttpComponent from "../../School/MakeRequest";
import { NoRowsOverlay } from "../../No Rows/noRowsOverlay";
import { DataGrid } from "@mui/x-data-grid";
import ExportMenu from "../../School/ExportMenu";
import { Link, useNavigate } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ListOrders from "./ordersList";

//format currency
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);

const breadcrumbs = [
    <Typography key={1} style={{color:"#707070" , fontSize:"14px" , fontFamily:"Poppins"}}>Dashboard</Typography>,
    <Typography key={2} style={{color:"#707070" , fontSize:"14px" , fontFamily:"Poppins"}}>Customer Account</Typography>,
    <Typography key={2} style={{color:"#dc3545" , fontSize:"14px" , fontFamily:"Poppins"}}>Customer Transactions</Typography>,
]

const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#f9f9f8" } });
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: { minWidth: 0 },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(0),
  fontSize: "16px",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#6e7074",
  fontFamily: ["Poppins"].join(","),
  "&:hover": { color: "#032541", opacity: 1 },
  "&.Mui-selected": { color: "#dc3545", fontWeight: 600 },
  "&.Mui-focusVisible": { backgroundColor: "blue" },
}));

export default function AllcustomersTransactions(){
    const defaultOption = 'customrange'
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [filterOption , setFilterOption] = useState(defaultOption)
    const [tabValue ,setTabValue] = useState('All')
    const navigate = useNavigate()

    const  handleChangeTabValue =(e, newTabValue) =>{
        setTabValue(newTabValue)
    }
    const options = [
    {
        label:"today" ,
        value:'today'
    },
    {
        label:"This Week" ,
        value:'thisWeek'
    },
    {
        label:"Last Week" ,
        value:'lastWeek'
    },
    {
        label:"This Month" ,
        value:'thisMonth'
    },
    {
        label:"Last Month" ,
        value:'lastMonth'
    },
    {
        label:"Last 3 Months" ,
        value:'lastthreemonths'
    },
    {
        label:"This Year" ,
        value:'thisyear'
    },
    {
        label:"Custom Range" ,
        value:'customrange'
    }
]

const showFilters = options.map((result)=>({value : result.value, label : result.label}))
 const [dataGridPageSize, setPageSize] = useState(5);
 const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });


 //exports

 let fileHeaders = [["Customer Name", "Customer Type", "Payment Type" , "Total Orders" ,"Amount"]]
 let csvColumns = [
    {label:"Customer Name" , key:"Customer Name"},
    {label:"Customer Type" , key:"Customer Type"},
    {label:"Payment Type" , key:"Payment Type" },
    {label:"Total Orders" , key:"Total Orders"},
    {label:"Amount" , key:"Amount" ,}
 ];

 let filedata = pageState?.data?.map((data)=>{
    return{
        "Customer Name":data?.CustomerName,
        "Customer Type":data?.customerType,
        "Payment Type":data?.paymentType,
        "Total Orders":data?.noOfOrders,
        "Amount":data?.totalAmountOfOrders
    }
 })
 let fileDataPDF = pageState?.data?.map((data)=>[data?.CustomerName ,  data?._id.customerId , data?.customerType ,data?.noOfOrders ,data?.totalAmountOfOrders]);


 const ordersColumns = [
    {headerName: "CustomerName", field: 'CustomerName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: (params) => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Customer Name</strong>) },renderCell:(params)=>{
        const customerId = params?.row?.id
        return(
            <>
            <Link to={`/allcustomerstransaction?${customerId}?orderDetails`}>{params?.row?.CustomerName}</Link>
            </>
        )
    }},
    {headerName: "Customer Type", field: 'customerType', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Customer Type</strong>) }} ,
    {headerName: "Payment Type", field: 'paymentType', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Payment Type</strong>) }} ,
    {headerName: "Total Orders", field: 'noOfOrders', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Total Orders</strong>) }} ,
    {headerName: "Amount", field: 'totalAmountOfOrders', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Amount (KES)</strong>) } , renderCell:(params)=>{
        return (
            <div>
                {numberFormat(params?.row?.totalAmountOfOrders)}
            </div>
        )
    }}
  ]

const getAllCustomers = () => {
    HttpComponent({
      method: "GET",
      url:`/api/v1/customers/orders/report/summary?page=${pageState.page}&limit=${dataGridPageSize}&customerPaymentType=${tabValue}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 200) {
           // setPageOrders(data?.response?.data)
          setPageState({ ...pageState, isLoading: false, data: data.response.data, count: data.response.count });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllCustomers();
  }, [pageState.page,  dataGridPageSize ,tabValue]);

    const data = pageState?.data?.map((data, index) => ({
        id: data?._id.customerId,
        CustomerName: data?.CustomerName,
        customerType: data?.customerType,
        paymentType: data?.customerPaymentType,
        noOfOrders: data?.noOfOrders,
        totalAmountOfOrders: data?.totalAmountOfOrders,
    }))


    //get the queryparams form the url

    const queryparams = decodeURIComponent(window.location.search)
    const state = queryparams.slice(1)
    const d = state.split("?")

    return(
        <Grid direction={'column'}>
            {d[1] === 'orderDetails' ? <ListOrders/>:
            <>
            <Grid item mb={2} display={'flex'} alignItems={'center'}>
                <ArrowBackIosNewIcon/>
               <Typography style={{fontSize:"25px" , fontFamily:"Poppins" , color:"#032541", fontWeight:600}}>Customer Transactions</Typography>
            </Grid>
            <Grid item mb={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={3} mb={2} marginLeft={-1} display={'flex'} alignContent={'center'} justifyContent={'space-between'} alignItems={'center'}>
                <Grid container display={'flex'} alignContent={'center'} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item>
                        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">search</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={'text'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                        >
                                            <SearchIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Grid container display={'flex'} alignContent={'center'} alignItems={'center'}>
                            <Grid item>
                                <CustomDate setDateFrom={setDateFrom} setDateTo={setDateTo} />
                            </Grid>
                            <Grid item>
                                <FormControl sx={{ m: 1, width: '25ch' }}>
                                    <Select
                                        id="demo-simple-select"
                                        value={filterOption}
                                        onChange={(e) => setFilterOption(e.target.value)}
                                    >

                                        {showFilters?.map((option) => {
                                            return (
                                                <MenuItem value={option.value}>{option.label}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Button style={{background:"#f5f6f7"}}>
                                     <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={'customer orders'} title={'customer orders'}/>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <TabContext value={tabValue}>
                    <AntTabs onChange={handleChangeTabValue}>
                        <AntTab label="All" value="All" />
                        <AntTab label="Credit" value="Credit" />
                        <AntTab label="Prepaid" value="Prepaid" />
                        <AntTab label="Normal" value="Normal" />
                    </AntTabs>

                    <TabPanel value="All">
                        <DataGrid
                            components={{ NoRowsOverlay: NoRowsOverlay }}
                            sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                            rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                            rowCount={pageState.total}
                            loading={pageState.isLoading}
                            pagination
                            page={pageState.page - 1}
                            pageSize={dataGridPageSize}
                            paginationMode="server"
                            onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            columns={ordersColumns}
                            rows={data}
                        //  getRowId={row => row._id} 
                        />
                    </TabPanel>
                    <TabPanel value="Credit">
                        <DataGrid
                            components={{ NoRowsOverlay: NoRowsOverlay }}
                            sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                            rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                            rowCount={pageState.total}
                            loading={pageState.isLoading}
                            pagination
                            page={pageState.page - 1}
                            pageSize={dataGridPageSize}
                            paginationMode="server"
                            onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            columns={ordersColumns}
                            rows={data}
                        //  getRowId={row => row._id} 
                        />
                    </TabPanel>
                    <TabPanel value="Prepaid">
                        <DataGrid
                            components={{ NoRowsOverlay: NoRowsOverlay }}
                            sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                            rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                            rowCount={pageState.total}
                            loading={pageState.isLoading}
                            pagination
                            page={pageState.page - 1}
                            pageSize={dataGridPageSize}
                            paginationMode="server"
                            onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            columns={ordersColumns}
                            rows={data}
                        //  getRowId={row => row._id} 
                        />
                    </TabPanel>
                    <TabPanel value="Normal">
                        <DataGrid
                            components={{ NoRowsOverlay: NoRowsOverlay }}
                            sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                            rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                            rowCount={pageState.total}
                            loading={pageState.isLoading}
                            pagination
                            page={pageState.page - 1}
                            pageSize={dataGridPageSize}
                            paginationMode="server"
                            onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            columns={ordersColumns}
                            rows={data}
                        //  getRowId={row => row._id} 
                        />
                    </TabPanel>
                </TabContext>
            </Grid>
            </>
}
        </Grid>
    )
}