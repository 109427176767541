import { Breadcrumbs, Grid, Paper, Typography } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SearchFilter from "../customerAccounts/customerComponents/searchFilter";
import { useEffect, useState } from "react";
import HttpComponent from "../School/MakeRequest";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { useNavigate } from "react-router-dom";

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Join business</Typography>,
]

export default function RequestJoinBs() {
    const [searchInputValue, setSearchInputValue] = useState('')
    const [availableBusiness, setAvailablebusiness] = useState([])
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const navigate = useNavigate()

    //fetch business

    const getBusinesses = () => {
        HttpComponent({
            method: "GET",
            url: `/api/search_businesses?searchValue=${searchInputValue}`,
            body: null,
            token: localStorage.getItem("X-Authorization"),
        })
            .then((data) => {
                if (data.status === 200) {
                    setAvailablebusiness(data?.response?.data)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        getBusinesses();
    }, [searchInputValue]);

    function RequestToJoinBusiness(businessId) {
        try {
            HttpComponent({
                method: "POST",
                url: `/api/addJoinBusinessRequest`,
                body: { businessId: businessId },
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data.status === 201) {
                    setSuccessShow({ state: true, message: 'Request send successfully' })
                    setTimeout(() => {
                        navigate('/approverequests')
                    }, 1000)

                } else {
                    setErrorShow({ state: true, message: 'Error sending request' })
                    setTimeout(() => {
                        navigate('/approverequests')
                    }, 1000)

                }
            })
        } catch (error) {
            console.log();
        }
    }




    return (
        <Grid conatiner direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item>
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Join Business</Typography>
            </Grid>
            <Grid item my={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid my={2} item width={'50%'}>
                <SearchFilter placeholder={'search business here'} setInputValue={setSearchInputValue} />
            </Grid>
            <Grid item width={'50%'}>
                {availableBusiness?.map((business) => {
                    console.log(business, 'hell')
                    return (
                        <Grid item onClick={() => RequestToJoinBusiness(business?._id)} key={business._id} width={"100%"} height={'50px'} style={{ cursor: "pointer" }}>
                            <Paper sx={{ my: 4, px: 2, py: 1 }}>
                                <Typography>{business?.businessName}</Typography>
                                <Typography>{business?.country}</Typography>
                            </Paper>
                        </Grid>
                    )
                })}
            </Grid>
            <Grid item>
                {availableBusiness?.length === 0 && (
                    <Grid item width={"40%"} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <p >No available business</p>
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}