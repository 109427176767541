import React, { useEffect, useState } from "react";
import { Box, Breadcrumbs, Button, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ParentGuardianForm from "./ParentGuardianForm";
import StudentSetup from "./StudentSetUp";
import { useDispatch, useSelector } from "react-redux";
import { clearAdditions, removeFromStudentsToAdd } from "../../features/customerSlice";
import CustomTable from "./CustomTable";
import HttpComponent from "./MakeRequest";
import { useNavigate } from "react-router-dom";
import StudentServicesForm from "./StudentServicesForm";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
let baseUrl = process.env.REACT_APP_BASE_URL;


const SchoolAddParent = () => {
  const { schoolTypeName, schoolTypeId } = useSelector((store) => store.schoolType.schoolTypeDetail)
  console.log("school type name ===>", schoolTypeName);
  const [formView, setFormView] = useState("parent");


  // const [currentSetUp, setCurrentSetUp] = useState("")
  const [tableData, setTableData] = useState([]);
  const { parentToAdd, studentToAdd, studentsToAdd, additionServices } = useSelector((store) => store.customers);
  const dispatch = useDispatch();


  console.log('Student to add', studentToAdd);
  console.log('students', studentsToAdd)

  console.log("keli checking set form view", formView);
  const breadcrumbs = [
    <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
      Dashboard
    </Typography>,
    // add condition to check if school type is university
    schoolTypeName?.includes("University") ? ( 
     null
    ) : (
      <Typography key={"Parents"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
      Parents/Guardians
    </Typography>
    ),
   
    <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
      {formView == "student" ? "Add Student" : schoolTypeName?.includes("University") ? "Add Student" : "Add Parent"}
    </Typography>,
  ];

  const columns = [
    { headerName: "Admission No", field: "itemNumber", flex: 1 },
    {
      headerName: "Student Name",
      field: "studentName",
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {params.row.firstName} {params.row.lastName}
        </Typography>
      ),
    },
    { headerName: "Gender", field: "gender", flex: 1 },
    { headerName: "Grade", field: "grade", flex: 1 },
    { headerName: "Term", field: "term", flex: 1 },
    { headerName: "Additional Info", field: "additionalInfo", flex: 1, renderCell: (params) => <Typography>{params.row.additionalInfo == "" ? "N/A" : params.row.additionalInfo}</Typography> },
    {
      field: "Action",
      headerName: "Action",
      renderCell: (params) => (
        <Button
          sx={{
            color: "#dc3545",
            "&:hover": {
              color: "#dc3545",
            },
          }}
          onClick={() => {
            const index = studentsToAdd.findIndex((student) => student.itemNumber === params.row.itemNumber);
            dispatch(removeFromStudentsToAdd({ index }));
          }}
        >
          Remove
        </Button>
      ),
    },
  ];
  const columnsCollege = [
    { headerName: "Admission No", field: "itemNumber", flex: 1 },
    {
      headerName: "Student Name",
      field: "studentName",
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {params.row.firstName} {params.row.lastName}
        </Typography>
      ),
    },
    { headerName: "Gender", field: "gender", flex: 1 },
    { headerName: "Course", field: "grade", flex: 1 },
    { headerName: "Semester", field: "term", flex: 1 },
    { headerName: "Additional Info", field: "additionalInfo", flex: 1, renderCell: (params) => <Typography>{params.row.additionalInfo == "" ? "N/A" : params.row.additionalInfo}</Typography> },
    {
      field: "Action",
      headerName: "Action",
      renderCell: (params) => (
        <Button
          sx={{
            color: "#dc3545",
            "&:hover": {
              color: "#dc3545",
            },
          }}
          onClick={() => {
            const index = studentsToAdd.findIndex((student) => student.itemNumber === params.row.itemNumber);
            dispatch(removeFromStudentsToAdd({ index }));
          }}
        >
          Remove
        </Button>
      ),
    },
  ];

  const buttonStyles = { minWidth: "7.813rem", height: "2.813rem", borderRadius: "4px" };

  useEffect(() => {
    const dataWithGridId = studentsToAdd?.map((row, index) => ({
      ...row,
      gridId: `${index}-${row.id}`,
    }));
    setTableData(dataWithGridId);
  }, [studentsToAdd]);

  const navigate = useNavigate();

  const { userId, X_Authorization } = useSelector((store) => store.user);
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });
  const submit = () => {
    // let data = { ...parentToAdd, extraServices: [...additionServices], billableItems: [...studentToAdd] };
    console.log(parentToAdd, 'Parent to Add');
    const firstParent = parentToAdd[0]
    const secondParent = parentToAdd[1]

    console.log(secondParent, 'Secondary PARENT', secondParent !== undefined);

    let data = []
    if (secondParent && firstParent) {
      data = [{ ...firstParent, billableItems: [{ ...addStude }] }, { ...secondParent }];

    } else if (firstParent) {
      data = [{ billableItems: [{ ...addStude }], ...firstParent }];

    } else if (schoolTypeName.includes('University')) {
      data = { ...addStude, extraServices: [{ ...additionServices }] }


    }

    HttpComponent({
      method: "POST",
      url: "/api/addCustomer",
      body: data,
      token: X_Authorization,
    })
      .then((data) => {
        console.log("here store is data", data);
        if (data.status === 201) {
          dispatch(clearAdditions());
          navigate("/school/parents");
        }
        else {
          console.error("Error setting info");
          setErrorShow({ state: true, message: data.response.message });
        }
        if (data.status === 400) {

          console.error("Error setting info");
          setErrorShow({ state: true, message: data.response.message });

        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

 


  const lookUp = localStorage.getItem("studentsetup")
  console.log("lookUp", lookUp);


  useEffect(() => {

    if (lookUp === "studentsetup" && !schoolTypeName.includes('University') ) {
      console.log("lookUp", lookUp, !schoolTypeName.includes('University'))
      setFormView("continue");
      // dispatch(clearAdditions());
    }else if(lookUp === "studentsetup" && schoolTypeName.includes('University') || lookUp === null && schoolTypeName.includes('University')){

      console.log("here in university")
      setFormView("student");
    } else {
      setFormView("parent");
      // dispatch(clearAdditions());
    }

  }, [lookUp]);
  console.log("here is the form view ===>", formView);

  return (
    <Box component="div">
      <Box component="div" sx={{}}>
        <Typography sx={{ color: "#032541", fontSize: "1.563rem", fontWeight: 700 }}>{formView == "student"  ? "Add New Student" : schoolTypeName.includes('University') ? "Add New Student"  : "Add New Parent/Guardian"}</Typography>
      </Box>
      <Box component="div" sx={{}}>
        <Breadcrumbs separator={<FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: "Poppins", color: "#e1e5e8" }} />} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <Box component={"div"} sx={{ width: "100%" }}>
        {formView == "parent" ? (
          <Box component={"div"} sx={{ width: "66%" }}>
            <ParentGuardianForm setView={setFormView} />
          </Box>
        ) : formView == "student" ? (
          <Box component="div" sx={{ width: "100%" }}>
            <StudentSetup setView={setFormView} />
          </Box>
        ) : formView == "continue" ? <Box>
          <StudentServicesForm setView={setFormView} />
        </Box> : (
          <>
            <Box component={"div"} sx={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: "2%" }}>
              <Box component="div" sx={{ marginLeft: "5.8%" }}>
                <Typography sx={{ fontSize: "1rem", color: "#707070" }}>Students</Typography>
              </Box>
              <Box sx={{ display: "flex", marginRight: "5%" }}>
                <Button
                  sx={{
                    ...buttonStyles,
                    border: " solid 1px #002543",
                    color: "#002543",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#002543",
                    },
                  }}
                >
                  Upload File
                </Button>

                <Button
                  sx={{
                    ...buttonStyles,
                    marginLeft: 1,
                    backgroundColor: " #032541",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#032541",
                      color: "white",
                    },
                  }}
                  onClick={() => setFormView("student")}
                >
                  Add Student
                </Button>
              </Box>
            </Box>
            <Box sx={{ alignSelf: "center", width: "90%", marginTop: "2%" }}>
              <CustomTable data={tableData} columns={schoolTypeName.includes("University") ? columnsCollege :columns } rowsPerPage={5} />
            </Box>
            <Box component="div" sx={{ display: "flex", width: "95%", justifyContent: "end", marginTop: "2%" }}>
              <Button
                sx={{
                  backgroundColor: "#17ae7b",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#17ae7b",
                    color: "white",
                  },
                }}
                onClick={submit}
              >
                Save
              </Button>
            </Box>
          </>
        )}
      </Box>
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />

    </Box>
  );
};

export default SchoolAddParent;
