import React from "react";

export const universalStyles = {
  universalFont: {
    fontFamily: "Poppins",
    width:"100%"
  },
  smNormalParaTextAlignCenter: {
    fontSize: "13px",
    textAlign: "center",
    color: "#032541",
  },
  mdNormalParaTextAlignCenter: {
    fontSize: "15px",
    textAlign: "center",
    color: "#032541",
    margin: "0",
  },
  paraWithImg: {
    textAlign: "center",
  },

  xlParaBold: {
    fontSize: "15px",
    fontStyle: "normal",
    color: "#032541",
    margin: "0",
    fontWeight: "bold",
  },
  xlParaBoldCenter: {
    fontSize: "15px",
    fontStyle: "normal",
    color: "#032541",
    margin: "0",
    fontWeight: "bold",
    textAlign: "center",
  },
  xlNormalPara: {
    fontSize: "15px",
    fontStyle: "normal",
    color: "#032541",
    margin: "0",
    fontWeight: "bold",
  },
  xlNormalParaNotBold: {
    fontSize: "15px",
    fontStyle: "normal",
    color: "#032541",
    margin: "0",
    fontWeight: "normal",
  },
  xlNormalParaTextCenter: {
    fontSize: "15px",
    fontStyle: "normal",
    color: "#032541",
    margin: "0",
    fontWeight: "bold",
    textAlign: "center",
  },
  xlNormalParaCenter: {
    fontSize: "15px",
    fontStyle: "normal",
    color: "#032541",
    margin: "0px 0px 5px 0px",
    fontWeight: "bold",
    textAlign: "center",
  },
  xxlNormalPara: {
    fontSize: "17px",
    fontStyle: "normal",
    color: "#032541",
    margin: "0",
    fontWeight: "bold",
  },
  xxxlParaBold: {
    fontSize: "20px",
    fontStyle: "normal",
    color: "#032541",
    margin: "0",
    fontWeight: "bold",
  },
  xxxlParaBoldCenter: {
    fontSize: "20px",
    fontStyle: "normal",
    color: "#032541",
    margin: "0",
    fontWeight: "bold",
    textAlign: "center",
  },
  xxxlParaBoldTextCenter: {
    fontSize: "20px",
    fontStyle: "normal",
    color: "#032541",
    margin: "0",
    fontWeight: "bold",
    textAlign: "center",
  },
  xxxlParaBoldGreen: {
    fontSize: "15px",
    fontStyle: "normal",
    color: "#17ae7b",
    margin: "0",
    fontWeight: "bold",
  },
  xxxlParaBoldWithWidth: {
    fontSize: "20px",
    fontStyle: "normal",
    color: "#032541",
    margin: "0",
    fontWeight: "bold",
    // "width":"100%"
    textAlign: "center",
  },
  xxxlParaNotBoldWithMarginTextCenter: {
    fontSize: "17px",
    color: "#032541",
    margin: "0px 0px 10px 0px",
    textAlign: "center",
  },
  xxlParaBold: {
    fontSize: "17px",
    fontStyle: "normal",
    color: "#032541",
    margin: "0",
    fontWeight: "bold",
  },
  smParaNotBold: {
    fontSize: "11px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    color: "#707070",
    margin: "0",
  },
  smParaNotBoldGreen: {
    fontSize: "11px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    color: "#17ae7b",
  },
  smParaNotBoldGreenAlignRight: {
    fontSize: "11px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    color: "#17ae7b",
  },
  searchLabelBold: {
    fontSize: "12px",
    fontWeight: "bold",
    textAlign: "left",
    color: "#032541",
    margin: "0px 10px 0px 0px",
  },
  searchInputBlack: {
    padding: "7px",
    fontSize: "12px",
    color: "#272d3b",
    borderRadius: "6px",
    border: "1px solid #032541",
    outline: "none",
  },
  spanBold: {
    fontSize: "14px",
    // "fontWeight": "bold",
  },
  spanXLRed: {
    fontSize: "17px",
    fontWeight: "500",
    color: "#dc3545",
  },
  spanNotBold: {
    fontSize: "13px",
    color: "#272d3b",
  },
  spanXLBlue: {
    fontSize: "17px",
    fontWeight: "500",
    color: "#032541",
  },
  spanXLBlueNormalFont: {
    fontSize: "17px",
    color: "#032541",
  },
  spanXXLBlueBold: {
    fontSize: "19px",
    fontWeight: "600",
    color: "#032541",
  },
  spanXLBlueBold: {
    fontSize: "17px",
    fontWeight: "600",
    color: "#032541",
  },
  spanLBlueBold: {
    fontSize: "17px",
    fontWeight: "600",
    color: "#032541",
  },
  spansmBlueBold: {
    fontSize: "17px",
    fontWeight: "600",
    color: "#032541",
  },
  spanSmGrey: {
    fontFamily: "Poppins",
    fontSize: "12px",
    color: "#9c9ea4",
  },
  spanXLRedBold: {
    fontSize: "17px",
    fontWeight: "600",
    color: "#dc3545",
  },
  spanLRedBold: {
    fontSize: "15px",
    fontWeight: "600",
    color: "#dc3545",
  },
  spanLGreyBold: {
    fontSize: "15px",
    fontWeight: "600",
    color: "#b2b4bb",
  },
  spanWithMarginRight: {
    fontSize: "15px",
    fontWeight: "600",
    margin: "0px 10px 0px 0px",
  },
  spanNormalBlack: {
    fontSize: "15px",
    color: "#032541",
  },
  spanBoldBlack: {
    fontSize: "14px",
    color: "#032541",
    fontWeight: "600",
  },
  spanXXLwhite: {
    fontSize: "20px",
    fontWeight: "500",
    color: "#ffffff",
  },
  spanXXLwhiteBold: {
    fontSize: "20px",
    fontWeight: "600",
    color: "#ffffff",
  },
  spanXXLBlack: {
    fontSize: "20px",
    fontWeight: "500",
    color: "#041920",
  },
  listItemText: {
    fontSize: "15px",
    fontWeight: "500",
    color: "#6e7074",
    // "width":"100%"
  },
  customerNoteModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "300px",
    height: "350px",
  },
  saveBillModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "300px",
    height: "fit-content",
  },
  saveBillSuccessModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "300px",
    height: "220px",
  },
  divWithDisplayFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  cancelButton: {
    border: "1px solid #dc3545",
    padding: "7px",
    borderRadius: "7px",
    width: "30%",
    color: "#dc3545",
    // marginRight:"15px"
  },
  confirmButton: {
    padding: "7px",
    borderRadius: "7px",
    width: "30%",
    backgroundColor: "#17ae7b",
    color: "#fff",
    // marginLeft:"15px"
  },
  printButton: {
    padding: "7px",
    borderRadius: "7px",
    width: "30%",
    backgroundColor: "#032541",
    color: "#fff",
    // marginLeft:"15px"
  },
  payNowButton: {
    padding: "7px",
    borderRadius: "7px",
    width: "30%",
    backgroundColor: "#032541",
    color: "#fff",
    // marginLeft:"15px"
  },
  customerOnAccountsModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "30%",
    height: "700px",
  },
  modeOfPaymentModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "30%",
    height: "fit-content",
  },
  cashPaymentModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "30%",
    height: "450px",
  },
  cashPaymentDetailsModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "25%",
    height: "400px",
  },
  successFullyPaidModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "25%",
    height: "250px",
  },
  printBillModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "30%",
    height: "fit-content",
  },
  cardPayModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "30%",
    height: "fit-content",
  },
  confirmPayModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "20%",
    height: "fit-content",
  },
  sendRequestModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "20%",
    height: "200px",
  },
  confirmBankPaymentsModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "25%",
    height: "450px",
  },
  customerOnAccountsDetails: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "20%",
    height: "450px",
  },
  customerOnAccountsConfirmOrder: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "20%",
    height: "300px",
  },
  customerOnAccountsSuccess: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "15%",
    height: "250px",
    padding: "10px",
  },
  mpesaPaymentModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "30%",
    height: "700px",
  },
  creditnotepaymodal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "25%",
    height: "400px",
    padding: "0px 20px 0px 20px",
  },
  creditnoteintro: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#041920",
    padding: "20px",
    cursor: "pointer",
    margin: "0px 0px 5px 0px",
  },
  creditNotetext: {
    fontSize: "15px",
    fontWeight: 500,
  },
  creditNoteBillHolder: {
    backgroundColor: "rgba(216,234,245, .4)",
    padding: "20px",
    margin: "0px 0px 10px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  creditnotebilltext: {
    color: "#032541",
    fontWeight: "bold",
  },
  creditnoteamounttext: {
    color: "#17ae7b",
    fontWeight: "bold",
  },
  creditNoteSearchInput: {
    width: "100%",
  },
  creditNoteSearch: {
    width: "100%",
  },
  creditNoteCardholder: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0px 0px 0px",
    padding: "20px",
    boxShadow: "0 0px 4px 0 rgba(0, 0, 0, 0.16)",
    cursor: "pointer",
  },
  unusedcreditNotes: {
    margin: "0px",
    color: "#032541",
    fontSize: "11px",
  },
  unusedcreditnotestatus: {
    color: "#17ae7b",
    fontWeight: "bold",
  },
  creditOverpaymodal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "20%",
    height: "fit-content",
    padding: "20px",
  },
  closeCreditPaymodal: {
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: "12px",
    backgroundColor: "#032541",
    padding: "10px",
    width: "30%",
    borderRadius: "10px",
    margin: "10px 0px 0px 0px",
  },
  allPayMethodGridStyles: {
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "#fff",
    margin: "0px 0px 5px 0px",
    padding: "5px 0px 5px 5px",
    cursor: "pointer",
  },
  payrequestuniversalStying:{
    fontFamily:"Poppins",
    padding:"0px 20px 0px 20px"
  },
  requestmpesatext: {
    color: "##032541",
    fontSize: "25px",
    fontWeight: "bold",
  },
  requestPayselectmethodstext: {
    color: "#707070",
    fontSize: "16px",
  },
  payTextInactive: {
    color: "#707070",
    fontSize: "16px",
    fontWeight:"bold"
  },
  payTextInactivesmallpara:{
    color: "#707070",
    fontSize: "14px",
    fontWeight:"bold",
    margin:"0px"
  },
  payTextactivesmallpara:{
    color: "#032541",
    fontSize: "14px",
    fontWeight:"bold",
    margin:"0px"
  },
  payTextactivesmallparanormal:{
    color: "#032541",
    fontSize: "14px",
    margin:"0px"
  },
  payTextActive: {
    color: "#032541",
    fontSize: "16px",
    fontWeight:"bold"
  },
  payIconStyling:{
    width:"80px",
    objectFit:"cover",
    margin:"0px 30px 0px 0px"
  },
  payTextdiv:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
  },
  paraGraphButton:{
    margin:"0px",
    textAlign:"center",
    margin:"0px 0px 10px 0px"
  },
  textFieldStyles:{
    width:"70%"
  },
  awaitPaymentInactiveBtn:{
    backgroundColor:"rgba(3, 37, 65, 0.4)",
    color:"white",
    margin:"0px 0px 10px 0px"
  },
  awaitPaymentActivebtn:{
    backgroundColor:"rgba(3, 37, 65, 1)",
    color:"white",
    margin:"0px 0px 10px 0px"
  }
};
