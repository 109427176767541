import React, { useState, useEffect } from "react";
import { Grid, Accordion, Typography, AccordionSummary, AccordionDetails } from "@mui/material";
import StockFunctions from "../stockEndpoints";
import { ArrowForwardIos } from "@material-ui/icons";

const StockOrderCategories = ({ storeId, supplierId, setCategoryId, setSupplierBsNo, inputValue }) => {
  const [suppliers, setSuppliers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [expanded, setExpanded] = React.useState("");
  const stockfunctions = new StockFunctions();

  // get businessNumber! <> <>
  // get suppliers
  useEffect(() => {
    const stockFunctions = new StockFunctions();
    stockFunctions.getSuppliers(1, 1000, "ACTIVE").then((data) => {
      setSuppliers(data?.response?.data);
    });
  }, []);

  useEffect(() => {
    const supplierBusinessNo = suppliers.find((supplier) => supplier._id === supplierId);
    setSupplierBsNo(supplierBusinessNo?.supplierBusinessNo);
    if (inputValue === "")
      stockfunctions.getSupplierCategories(1, 10, supplierBusinessNo?.supplierBusinessNo).then((data) => {
        if (data?.status === 200) {
          setCategories(data?.response?.data);
        }
      });
  }, [suppliers, inputValue]);

  useEffect(() => {
    if (inputValue)
      stockfunctions
        .searchSupplierCategories(1, 1000, inputValue)
        .then((data) => {
          if (data?.status === 200) {
            setCategories(data?.response?.data);
          }
        })
        .catch((e) => {
          // console.log(e);
        });
  }, [inputValue]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div style={{ width: "100%" }}>
      <Grid container direction={"column"}>
        <Grid item>
          {categories?.map((category) => {
            return (
              <Accordion key={category?._id} onChange={handleChange(category?._id)} expanded={expanded === category._id}>
                <AccordionSummary expandIcon={<ArrowForwardIos />} onClick={() => setCategoryId(category?._id)}>
                  <Typography>{category?.categoryName}</Typography>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
              </Accordion>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};

export default StockOrderCategories;
