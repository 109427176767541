import React, {useEffect, useState} from 'react';
import {Box, Breadcrumbs, Button, Step, StepLabel, Stepper, Typography} from "@mui/material";
import SchoolDetailsForm from "./SchoolDetailsForm";
import ServiceCategorySetup from "./ServiceCategorySetup";
import ServiceSetup from "./ServiceSetup";
import PaymentSetup from "./PaymentSetup";
import ParentStudentSetUp from "./ParentStudentSetUp";
import "./MulitStep.css"
import back from "./Images/back-icn.svg"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {useNavigate} from "react-router-dom";
import HttpComponent from "./MakeRequest";
import {useSelector} from "react-redux";
import PaymentSettingUp from '../paymentmodule/paymentsettingup';
import SchoolGradesForm from './schoolGradesForm';

const SchoolMultiStepSetupForm = (props) =>{
     props = props
     const schoolType = props.schoolTypeState;
     const { schoolTypeName, schoolTypeId } = useSelector((store) => store.schoolType.schoolTypeDetail)

    console.log("props",props)
    const schoolTypeState = localStorage.getItem("schoolTypeState")
    let steps = [];
    if(schoolType.includes("University") || schoolTypeName.includes("University")) {
         steps = ['School Details',"Course & Programs", 'Service Categories', 'Services', 'Payment Options', 'Students Details'];
    }else{
        steps = ['School Details',"Grades & Streams", 'Service Categories', 'Services', 'Payment Options', 'Parent/Guardians & Students'];
    }



    const [activeStep, setActiveStep] = useState(0);
    const { userId ,X_Authorization } = useSelector((store) => store.user);
    let group = localStorage.getItem("group")

    const checkFormStatus =() =>{
        HttpComponent({
            method: 'GET',
            url: '/api/get_setup_status',
            body: null,
            token: X_Authorization,
        }).then((data)=>{
            console.log("here store is data",data);
            if(data.status === 201){
                let state = data.response.data.workflowState;
                console.log("state",state)
                if(state == "BASIC"){
                    console.log("1")
                    setActiveStep(1)
                }else if(state == "GRADE_STREAMS" || state == "COURSE_PROGRAMS"){
                    console.log("2")
                    setActiveStep(2)
                }else if(state == "CATEGORY"){
                    console.log("3")
                    setActiveStep(3)
                }else if(state == "SERVICE"){
                    console.log("4")
                    setActiveStep(4)
                }else if(state === "PAYMENT"){
                    console.log("5")
                    setActiveStep(5)
                }
            }else{

            }
        }).catch((error)=>{
            console.error(error.message);
        })
    }

    useEffect(() => {
        console.log("running")
        checkFormStatus();
    }, []);
    
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const breadcrumbs = [
        <Typography key="X" sx={{color:"#707070",fontSize:"0.875rem"}}>
            Dashboard
        </Typography>,
        <Typography key={steps[activeStep]} sx={{color:"#dc3545", fontSize:"0.875rem"}}>
            {steps[activeStep]}
        </Typography>
    ];

    const renderFormContent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <Box component="div" sx={{width:"100%"}}>
                        <SchoolDetailsForm next={handleNext}/>
                    </Box>
                );
            case 1:
                    return (
                        <Box
                            component="div"
                            sx={{width:"100%"}}
                        >
                           <SchoolGradesForm next={handleNext}/>
                        </Box>
                    );
            case 2:
                return (
                    <Box
                        component="div"
                        sx={{width:"100%"}}
                    >
                       <ServiceCategorySetup next={handleNext}/>
                    </Box>
                );
            case 3:
                return (
                    <Box component="div"
                         sx={{width:"100%"}}
                    >
                        <ServiceSetup next={handleNext}/>
                    </Box>
                );
            case 4:
                return(
                    <Box component="div"
                         sx={{width:"100%"}}
                    >
                       <PaymentSettingUp next={handleNext}/>
                    </Box>
                )
            case 5:
                return(
                    <Box component="div"
                         sx={{width:"100%"}}
                    >
                       <ParentStudentSetUp/>
                    </Box>
                )
            default:
                return null;
        }
    };
    const navigate = useNavigate();
    return(
        <Box component="div" sx={{width:"100%", display:"flex", flexDirection:"column", marginTop:"-2%"}}>
            <Box sx={{display:"flex"}} onClick={()=>navigate("/createBusiness")}>
                <img src={back} alt=""/>
                <Typography sx={{color:"#032541", fontSize:"1.563rem", marginLeft:"2%", fontWeight:700}}>
                    Create Business
                </Typography>
            </Box>
            <Box sx={{marginBottom:4, marginTop:2}}>
                <Breadcrumbs
                    separator={<FiberManualRecordIcon sx={{fontSize:"0.625rem", color:"#e1e5e8"}} />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Box>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => {
                    return (
                        <Step key={label}>
                            <StepLabel
                                StepIconProps={{
                                    classes: {
                                        completed: 'step-icon-completed',
                                        text: index !== activeStep ? "step-icon-text" : null,
                                    },
                                    style: {
                                        color: index === activeStep ? '#032541' : '#f5f6f7',
                                        width: "2.188rem",
                                        height: "2.188rem",
                                    }
                                }}
                            >
                                {label}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            <Box component="div">
                {renderFormContent()}
            </Box>

            {/*<Box component="div" sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>*/}
            {/*    <Button*/}
            {/*        color="inherit"*/}
            {/*        disabled={activeStep === 0}*/}
            {/*        onClick={handleBack}*/}
            {/*        sx={{ mr: 1 }}*/}
            {/*    >*/}
            {/*        Back*/}
            {/*    </Button>*/}
            {/*    <Button onClick={handleNext}>*/}
            {/*        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}*/}
            {/*    </Button>*/}
            {/*</Box>*/}
        </Box>
    )
}

export default SchoolMultiStepSetupForm;
