import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storageSession from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import transactionDateReducer from "../features/transactionDateSlice";
import userReducer from "../features/userSlice";
import branchReducer from "../features/branchSlice";
import businessCategoryReducer from "../features/businessCategorySlice";
import businessBranchesReducer from "../features/businessBranchesSlice";
import servicesReducer from "../features/servicesSlice";
import customersReducer from "../features/customerSlice";
import tenantReducer from "../features/tenantsUnitsSlice";
import tenantDataReducer from "../features/tenantsDataSlice";
import sideBarReducer from "../features/sideBarSlice";
import tenantBusinessReducer from "../features/tenantBusinessSlice";
import autorefreshReducer from "../features/autorefreshonpageload";
import startorderReducer from "../features/startorderSlice";
import saveStudentReducer from "../features/saveStudentDetails";
import tabReducer from "../features/tabSlice";
import categoryReducer from '../features/generalBusinessCategorySlice';
import paymentOptionsSlice from "../features/paymentOptionsSlice";
import schoolType from "../features/schoolType";

const rootReducer = combineReducers({
  transactionDate: transactionDateReducer,
  user: userReducer,
  branch: branchReducer,
  businessCategory: businessCategoryReducer,
  businessBranches: businessBranchesReducer,
  services: servicesReducer,
  customers: customersReducer,
  tenant: tenantReducer,
  tenantData: tenantDataReducer,
  sidebar: sideBarReducer,
  tenantBusinessData: tenantBusinessReducer,
  pageload: autorefreshReducer,
  startorder: startorderReducer,
  studentdetails: saveStudentReducer,
  tab: tabReducer,
  category: categoryReducer,
  payment: paymentOptionsSlice,
  schoolType:schoolType
});


const reduxPersistConfig = {
  key: "root",
  storage: storageSession,
  blacklist: [],
};

const persistedRootReducer = persistReducer(reduxPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedRootReducer,
  middleware: [thunk],
});

export const persistedStore = persistStore(store);
