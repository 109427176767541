import React, { useState, useEffect } from "react";
import { Grid, Button } from "@mui/material";
import { Delete } from "@material-ui/icons";
import { NoRowsOverlay } from "../../../No Rows/noRowsOverlay";
import SearchFilter from "../../../customerAccounts/customerComponents/searchFilter";
import CustomDataGrid from "../customDataGrid";
import StockFunctions from "../stockEndpoints";
import { stockStyles } from "../stockStyling";
import { SuccessAlertRightAligned } from "../../../bills/startorderModals/modals";
import { useNavigate, useParams } from "react-router-dom";

const ViewBatchStockTake = ({wareHouseId}) => {

  const [fetch, setFetch] = useState(false)
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  //DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize });

  // get items and update;

  useEffect(() => {
    const stockTakeItems = JSON.parse(localStorage.getItem("stockTakeItems"));
    const newArray = [];
    stockTakeItems.map((stock, index) => {
      if (stock?.changed) {
        newArray.push(stock);
      }
    });
    setPageState({ data: newArray, count: newArray?.length });
  }, [pageState.page,dataGridPageSize ]);

  const productsColumn = [
    { field: "productName", headerName: "Product Name", flex: 1 },
    { field: "expectedQuantity", headerName: "expectedQuantity", flex: 1 },
    { field: "quantity", headerName: "Actual", flex: 1, editable: true },
    {
      field: "Action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return <Delete style={{ color: "red", cursor: "pointer" }} onClick={() => removeProductFromBatch(params?.row?.id)} />;
      },
    },
  ];

  const productsRow = pageState?.data
  ?.filter((product) => {
    return inputValue?.toLocaleLowerCase() === "" ? product : product?.productName?.toLocaleLowerCase().includes(inputValue?.toLocaleLowerCase())
  })
  ?.map((stock) => ({
    id: stock?._id || stock?.id,
    expectedQuantity: stock?.expectedQuantity,
    quantity: stock?.actualQuantity,
    productName: stock?.productName,
    productId: stock?._id || stock?.id,
  }));

  const [isSuccess, setSuccess] = useState({ state: false, message: "" });
  const batchAdded = <SuccessAlertRightAligned sucess={isSuccess.state} message={"Batch added succesfully!"} />;



  useEffect(() => {
    if (isSuccess.state) {
      setTimeout(() => {
        setSuccess({ state: false });
      }, 2000);
    }
  }, [isSuccess.state]);



  const removeProductFromBatch = (id) => {
    const stockItems = JSON.parse(localStorage.getItem("stockTakeItems"));
    const newStockItems = [...stockItems];
    // check index;
    const currentIndex = newStockItems.findIndex((stock) => stock._id === id);

    newStockItems.splice(currentIndex, 1);
    localStorage.setItem("stockTakeItems", JSON.stringify(newStockItems));
  
    // updates;
    const newArray = [];
    newStockItems.map((stock, index) => {
      if (stock?.changed) {
        newArray.push(stock);
      }
    });
    setPageState({ data: newArray, count: newArray?.length });
  };

  const clearStage = () => {
    localStorage.removeItem("stockTakeItems")
    navigate(`/stockTake`)
  };


  // wareHouseId

  const submitBatch = () => {
    const stockfunctions = new StockFunctions();
    stockfunctions.createStockTakeRequest(productsRow, wareHouseId).then((data) => {
      if (data.status === 201) {
        setSuccess({ state: true });
      }
      setTimeout(() => {
        navigate(`/stock`);
      }, 2000);
    })
  }

  return (
    <div style={{ width: "100%" }}>
      {isSuccess.state && batchAdded}
      <Grid container>
        <Grid item>{/* <Breadcrumbcomponent breadcrumbs={breadcrumb} /> */}</Grid>
      </Grid>

      <Grid container style={stockStyles.batchDetailsHolder} width={"100%"} alignItems={"center"} justifyContent={"space-between"} direction={"row"} margin={"0px 0px 1% 0px"}>
        <Grid item>
          <span style={{ margin: "0% 0% 0% 0%" }}>Batch No:</span>
          <span style={stockStyles.submitBatchdisplay}>xxxx</span>
        </Grid>

        <Grid item>
          <span style={{ margin: "0% 5% 0% 0%" }}>Supplier:</span>
          <span style={stockStyles.submitBatchdisplay}>{"supplierName"}</span>
        </Grid>

        <Grid item>
          <span style={{ margin: "0% 5% 0% 0%" }}>Warehouse:</span>
          <span style={stockStyles.submitBatchdisplay}>{"warehouseName"}</span>
        </Grid>
      </Grid>

      <Grid container direction={"row"} width={"100%"} alignItems={"center"} justifyContent={"space-between"} margin={"0px 0px 1% 0px"}>
        <Grid item>
          <SearchFilter setInputValue={setInputValue} placeholder={"Search products"} />
        </Grid>
        <Grid item sx={{ display: "flex", gap: "20px", width: "50%", justifyContent: "flex-end" }}>
          <Button style={stockStyles.cancelButton} onClick={clearStage}>
            Clear
          </Button>
          <Button style={stockStyles.viewBatchButton} onClick={() => submitBatch()}>
            Submit Batch
          </Button>
        </Grid>
      </Grid>

      <Grid container>
        {/* <CustomDataGrid/> */}
        <CustomDataGrid column={productsColumn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={productsRow} />
      </Grid>
    </div>
  );
};

export default ViewBatchStockTake;
