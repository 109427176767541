import { useEffect, useState } from "react"
import { Typography ,Breadcrumbs ,Grid, TextField, Button, FormControl, Select, MenuItem } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate, useParams } from "react-router-dom";
import HttpComponent from "../../School/MakeRequest";
import { useSelector } from "react-redux";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";

//breadcrumbs

const breadcrumbs = [
	<Typography key="X" sx={{color:"#707070",fontSize:"0.875rem"}}>
		Dashboard
	</Typography>,
	<Typography key={"add"} sx={{color:"#707070", fontSize:"0.875rem"}}>
		Products / Services Categories
	</Typography>,
    <Typography key={"add"} sx={{color:"#dc3545", fontSize:"0.875rem"}}>
        Edit Categories
    </Typography>
];


export default function EditProductService(){
    const [formData ,setFormData] = useState({categoryDescription:"" ,productService:"",categoryName:''})
    const [successShow, setSuccessShow] = useState({state: false, message: ""})
	const [errorShow, setErrorShow] = useState({state: false, message: ""})
    const {X_Authorization} = useSelector((store)=>store.user)

    //update the product / service category or description

    const {id} = useParams()
    console.log(id)

    function updateProductCategory(){
        try {
            HttpComponent({
                method:'GET' ,
                url:`/api/getCategoryById?categoryId=${id}`,
                token:X_Authorization
            }).then((data)=>{
                console.log(data,'wueh')
                if(data.status ===  200){
                    setFormData({
                        ...formData,
                        categoryDescription:data?.response?.data?.categoryDescription,
                        productService:data?.response?.data?.productService,
                        categoryName:data?.response?.data?.categoryName
                    })
                }

            })
            
        } catch (error) {
            
        }
    }

    //handle input change

    const handleInputChange =(e)=>{
        const value = e.target.value;
        const name = e.target.name;

        setFormData({ ...formData, [name]: value })


    }



    useEffect(()=>{
        updateProductCategory()
    },[])

     //type

     const typeOptions = [
        {
            value:"Product",
            label:"Product" ,
        },
        {
            value:"Service",
            label:"Service" ,
        }
    ]

    //map through the types
 
    const mapOptions = typeOptions.map((option)=>({value:option.value ,label:option.label}))
    const navigate = useNavigate()


    //update the product category

    function updateProductCat(){
        try {
            HttpComponent({
                method:'PUT',
                url:`/api/updateCategory/${id}`,
                body:{
                    categoryDescription:formData.categoryDescription,
                    categoryName:formData.categoryName,
                    productService:formData.productService,
                    categoryState:"Edited"
                },
                token:X_Authorization
            }).then((data)=>{

                if(data.status === 202){
                    setSuccessShow({state:true, message:data.response.message})
                    setTimeout(()=>{
                        navigate("/categories")
                    },3000)
                } else{
                    setErrorShow({state:true, message:data.response.message})
                    setTimeout(()=>{
                        navigate("/categories")
                    },3000)
                }
            })
        } catch (error) {
            
        }
    }
    return(
        <>
         <SuccessAlert vertical="top" horizontal="right" onClose={()=>setSuccessShow({...successShow, state:false})} open={successShow.state} message={successShow.message}/>
			<ErrorAlert vertical="top" horizontal="right" onClose={()=>setErrorShow({...errorShow, state:false})} open={errorShow.state} message={errorShow.message}/>
         <Grid container direction={'column'}>
                <Grid item display={'flex'} justifyContent={'space-between'} marginBottom={'2px'}>
                    <Typography variant="h6" style={{color:'#032541' , fontWeight:700 , fontSize:"25px"}}> Edit Product / Service Category</Typography>
                </Grid>
                <Grid item mb={'10px'} mt={'10px'}>
                     <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                            {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
                <Grid item mb={'10px'} mt={'10px'}>
                    <Typography variant="body2" style={{color:'#707070', fontSize:"18px"}}>Edit Category Details</Typography>
                </Grid>
                <Grid item mt={3} mb={2}>
                    <TextField id="outlined-basic" name="categoryName" label="Category Name" value={formData?.categoryName} onChange={handleInputChange} style={{fontWeight:200 , width:"400px"}}/>
                </Grid>
                <Grid item>
                <FormControl sx={{ minWidth: 400 }}>
                            <Select
                              name="productService"
                              value={formData?.productService}
                              onChange={handleInputChange}
                              displayEmpty
                            >
                              <MenuItem value="ALL"> ALL </MenuItem>
                              {mapOptions.map((option)=>{
                                return(
                                    <MenuItem value={option.value}>{option.label}</MenuItem>
                                )
                              })}
                            </Select>
                    </FormControl>
                </Grid>
                <Grid container>
                     <Grid item lg={6} xl={6} md={12} mt={2} mb={2}>
                         <TextField id="outlined-basic" name="categoryDescription" value={formData?.categoryDescription} onChange={handleInputChange} label="Category Description" style={{fontWeight:200,width:"400px"}}/>
                     </Grid>
                </Grid>
                <Grid container>
                    <Grid item style={{width:"400px"}} display={'flex'} justifyContent={'flex-end'} alignContent={'flex-end'}>
                        <Button onClick={updateProductCat}  style={{background:"#032541" ,color:"#fff" ,textTransform:'inherit' , cursor:'pointer', width:"100px"}}>Update</Button>
                    </Grid>
                </Grid>
         </Grid>
        </>
    )

}