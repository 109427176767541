import React, {useState} from 'react';
import {Box, Button, Divider, Grid, Typography} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {handleSetBusinessData} from "../../../features/tenantBusinessSlice";
import {changeToken} from "../../../features/userSlice";
import CustomInputLabel from "../../School/CustomInputLabel";
import CustomSelectField from "../../School/CustomSelectField";
import CustomTextField from "../../School/CustomTextField";
import HttpComponent from "../../School/MakeRequest";
import {ErrorAlert} from "../../snackBar Alerts/errorAlert";
import {SuccessAlert} from "../../snackBar Alerts/successAlert";
import SetWorkFlow from "../Utility/updateSetupWorkFlow";
import GeoLocation from '../../../hooks/useLocation';

const BusinessDetailsForm = (props) =>{
	const [showSchoolDetails, setShowSchoolDetails] = useState(false);
	const [selectedValue ,setSelectedValue] = useState('')
	const [formData, setFormData] = useState({
		businessName:"",
		businessOwner:"",
		phoneNumber:"",
		email:"",
		location:"",
		country:"Kenya",
		currency :"KES"
	})
	const countryOptions = [
		{value:"Kenya", label:"Kenya"}
	]
	const currencyOptions = [
		{value:"KES", label:"KES"}
	]
	const submitRentDetails = () =>{
		let submitObject ={
			businessCategory : "Rental",
			businessName:formData.businessName,
			businessOwnerName:formData.businessOwner,
			businessOwnerUserName:formData.businessOwner.split(" ").join(""),
			businessOwnerPhone:formData.phoneNumber,
			businessOwnerEmail:formData.email,
			businessOwnerAddress: selectedValue?.description,
			country:formData.country,
			localCurrency:formData.currency,
			deviceCreatedOn:"Web",
			userId:userId,
			Paybill:"",
			Vooma:"",
			Equitel:"",
			Till:"",
			workflowState: "Basic"
		}

		HttpComponent({
			method: 'POST',
			url: '/postBusiness',
			body: submitObject,
			token: X_Authorization
		}).then((data)=>{
			console.log("uh",data);
			if(data.status === 201){

				dispatch(changeToken({token: data.response.data.token}))
				dispatch(handleSetBusinessData(submitObject))
				setSuccessShow({state:true, message:data.response.message})
				dispatch(changeToken({token: data.response.data.token}))
				localStorage.setItem("X-Authorization", data.response.data.token);
				localStorage.setItem("group", 'Merchant');
				localStorage.setItem("businessCategory", "Rental")
				localStorage.setItem("businessId", data.response.businessNumber)
				localStorage.setItem("businessID", data.response.businessId)
				localStorage.setItem("businessName", data.response.data.businessName)
				localStorage.setItem("BranchID", data.response.data.branchId)
				//SetWorkFlow('UNITS', localStorage.getItem('X-Authorization'))
				SetWorkFlow({flowName:'UNITS' ,token:localStorage.getItem('X-Authorization')})
				props.next()
				//navigate('/defineUnits')
				//navigate('/setup/rental/rentalSetup')
			
			}else{
				console.error("Error setting info")
				setErrorShow({state:true, message:data.response.message})
			}
		}).catch((error)=>{
			console.error(error.message);
		})
	}
	
	const handleInputChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};
	
	const navigate = useNavigate();
	const dispatch = useDispatch();
	
	const { userId ,X_Authorization } = useSelector((store) => store.user);
	
	const [errorShow, setErrorShow] = useState({state: false, message: ""})
	// const [emailError, setEmailError] = useState(false);
	const [successShow, setSuccessShow] = useState({state: false, message: ""})

	// Check if the input value matches the email format
	// const emailRegex = /^\S+@\S+\.\S+$/;
	// setEmailError(!emailRegex.test(formData.email));
	
	const cancelButtonStyles = {"width":"7.813rem",
			"height":"2.813rem",
			textTransform : "none",
			"borderRadius":"4px",
			marginRight : '20px',
			"border":"solid 1px #dc3545",
			"color":"#dc3545"
		}
	
	const doneButtonStyles ={
		opacity : !formData.businessName.trim() || !formData.businessOwner.trim() || (!formData.phoneNumber.trim() || formData.phoneNumber.length < 12) || !formData.email.trim() || !formData.country.trim() || !formData.currency.trim() ? "0.5" : "1",
		"width":"7.813rem",
		"height":"2.813rem",
		"borderRadius":"4px",
		"backgroundColor":"#17ae7b",
		"color":"white",
		textTransform : "none",
		"marginLeft":2,
		'&:hover': {
			backgroundColor: '#17ae7b',
			color: 'white'
		}
	}
	
	return(
		<Box component="div" sx={{display:"flex", justifyContent:"center", margin:2, fontFamily : 'Poppins'}}>
			<Box component="div" sx={{display:"flex", flexDirection:"column"}}>
				
				<ErrorAlert
					vertical="top"
					horizontal="right"
					onClose={()=>setErrorShow({...errorShow, state:false})}
					open={errorShow.state}
					message={errorShow.message}/>
				
				<SuccessAlert
					vertical="top"
					horizontal="right"
					onClose={()=>setSuccessShow({...successShow, state:false})}
					open={successShow.state}
					message={successShow.message}/>
				
				{/*title*/}
				{!showSchoolDetails ? <div>
				<Box component="div" sx={{alignSelf:"left", marginBottom:'40px', marginTop : '53px'}}>
					<Typography sx={{fontSize:"16px", fontWeight : 500, color:"#032541"}}>
						Set-up your Business details.
					</Typography>
				</Box>
				
				{/*begin of form*/}
				<Box compnent="div" sx={{display:"flex"}}>
					
					
					{/*column one*/}
					<Box component="div" sx={{display:"flex", flexDirection:"column", marginX:1}}>
						
						<CustomInputLabel required={true} label={"Business Name"}/>
						<CustomTextField value={formData.businessName} onChange={handleInputChange} name={"businessName"} placeholder={"Business Name"} />
						
						<CustomInputLabel required={true} label={"Business Owner"}/>
						<CustomTextField value={formData.businessOwner} onChange={handleInputChange} name={"businessOwner"} placeholder={"Business Owner"} />
						
						<CustomInputLabel required={true} label={"Phone Number"}/>
						<Box component="div" sx={{marginTop:1}}>
							<PhoneInput inputStyle={{height : '3.438rem'}} required country={"ke"} enableSearch={true} value={formData.phoneNumber} name={"phoneNumber"}  onChange={(phone) => formData.phoneNumber=phone}/>
						</Box>
					</Box>
					
					{/*colum two*/}
					<Box component="div" sx={{display:"flex", flexDirection:"column", marginX:1}}>
						
						<CustomInputLabel required={true} label={"Email"}/>
						<CustomTextField  required={true} value={formData.email} onChange={handleInputChange} name={"email"} placeholder={"Email"} />
						
						<CustomInputLabel required={true} label={"Location"}/>
						<GeoLocation sx={{marginBottom:"10px" , marginTop:"10px"}}  selectedValue={selectedValue} onValueChange={(location)=>setSelectedValue(location)}/>
						{/* <CustomTextField value={formData.location} onChange={handleInputChange} name={"location"} placeholder={"Location"} /> */}
						
						<Grid container direction={'row'} spacing={2} >
							
							<Grid item >
								<CustomInputLabel required={true} label={"Country"}/>
								{/*this will change to select*/}
								<CustomSelectField style={{width : '12.1rem'}} value={formData.country} onChange={handleInputChange} name={"country"} placeholder={"Select Country"} options={countryOptions} />
							</Grid>
							
							<Grid item>
								<CustomInputLabel required={true} label={"Currency"}/>
								{/*this will change to select*/}
								<CustomSelectField style={{width : '12.1rem'}} value={formData.currency} onChange={handleInputChange} name={"currency"} placeholder={"Select Currency"} options={currencyOptions} />
							</Grid>
							
						</Grid>

						
					</Box>
				
				</Box>
				
				{/*    end of form */}
				<Box component="div" sx={{width:"100%", display:"flex", justifyContent:"end"}}>
					<Box component="div" >
						<Button style={cancelButtonStyles} onClick={()=>navigate("/createBusiness")}>
							Cancel
						</Button>
						
						{/*onClick={()=>setShowSchoolDetails(true)}*/}
						<Button
							disabled={!formData.businessName.trim() || !formData.businessOwner.trim() || !formData.phoneNumber.trim() || !formData.email.trim() || !formData.country.trim() || !formData.currency.trim()}
							style={doneButtonStyles} onClick={()=>setShowSchoolDetails(true)}>
							Done
						</Button>
					</Box>
				
				</Box>
					</div>
				: <div>
							
					{/*title*/}
					<Box component="div" sx={{display:"flex", width:"100%", justifyContent:"center",alignSelf:"center",marginY:2}}>
						<Typography sx={{alignSelf:"center",fontSize:"1rem", color:"#17ae7b", fontWeight:700}}>
							Rental Details Summary
						</Typography>
					</Box>
					
					{/*Form	*/}
					<Grid container justifyContent={'space-between'}>
						
						{/*column one*/}
						<Box component="div" sx={{display:"flex", flexDirection:"column", marginX:1}}>
							
							<CustomInputLabel required={true} label={"Business Name"}/>
							<CustomTextField style={{width : '25.438rem'}} InputProps={{readOnly : true}} value={formData.businessName} onChange={handleInputChange} name={"businessName"} placeholder={"Business Name"} />
							
							<CustomInputLabel required={true} label={"Business Owner"}/>
							<CustomTextField InputProps={{readOnly : true}} value={formData.businessOwner} onChange={handleInputChange} name={"businessOwner"} placeholder={"Business Owner"} />
							
							<CustomInputLabel required={true} label={"Phone Number"}/>
							{/*<Box component="div" sx={{marginTop:1}}>*/}
								<CustomTextField InputProps={{readOnly : true}} value={"+" + formData.phoneNumber}  name={"phoneNumber"} placeholder={"Phone Number"} />
								{/*<PhoneInput InputProps={{readOnly : true}} inputStyle={{height : '3.438rem'}} required country={"ke"} enableSearch={true} value={formData.phoneNumber} name={"phoneNumber"}  onChange={(phone) => formData.phoneNumber=phone}/>*/}
							{/*</Box>*/}
						</Box>
						
						{/*colum two*/}
						<Box component="div" sx={{display:"flex", flexDirection:"column", marginX:1}}>
							
							<CustomInputLabel required={true} label={"Email"}/>
							<CustomTextField InputProps={{readOnly : true}} value={formData.email} onChange={handleInputChange} name={"email"} placeholder={"Email"} />
							
							<CustomInputLabel required={true} label={"Location"}/>
							{/* <CustomTextField InputProps={{readOnly : true}} value={formData.location} onChange={handleInputChange} name={"location"} placeholder={"Location"} /> */}
							<GeoLocation sx={{marginBottom:"10px" , marginTop:"10px"}}  selectedValue={selectedValue} onValueChange={(location)=>setSelectedValue(location)}/>
							
							<Grid container direction={'row'} spacing={2} >
								
								<Grid item >
									<CustomInputLabel required={true} label={"Country"}/>
									<CustomTextField InputProps={{readOnly : true}} value={formData.country} onChange={handleInputChange} name={"country"} placeholder={"country"} />
									
									{/*this will change to select*/}
									{/*<CustomSelectField InputProps={{readOnly : true}} style={{width : '12.1rem'}} value={formData.country} onChange={handleInputChange} name={"country"} placeholder={"Select Country"} options={countryOptions} />*/}
								</Grid>
								
								<Grid item>
									<CustomInputLabel required={true} label={"Currency"}/>
									<CustomTextField InputProps={{readOnly : true}} value={formData.currency} onChange={handleInputChange} name={"currency"} placeholder={"currency"} />
									
									{/*this will change to select*/}
									{/*<CustomSelectField InputProps={{readOnly : true}} style={{width : '12.1rem'}} value={formData.currency} onChange={handleInputChange} name={"currency"} placeholder={"Select Currency"} options={currencyOptions} />*/}
								</Grid>
							
							</Grid>
						
						
						</Box>
						
					</Grid>
					
					{/*begin of form*/}
					<Grid container justifyContent={'flex-end'}>
						
						{/*Edit Button*/}
						<Grid item>
							<Button sx={
								{"width":"7.813rem",
									"height":"2.813rem",
									"padding":"12.5px 36.7px 12.5px 38.3px",
									"borderRadius":"4px",
									"border":"solid 1px #032541",
									"color":"#032541"
								}} onClick={()=>setShowSchoolDetails(false)}>
								Edit
							</Button>
						</Grid>
						
						{/*Next Button*/}
						<Grid item>
							<Button sx={doneButtonStyles} onClick={submitRentDetails}>
								Next
							</Button>
						</Grid>
						
					</Grid>

					</div> }
			</Box>

		</Box>
	)
}

export default BusinessDetailsForm;
