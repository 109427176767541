import React, { useEffect, useState } from "react";
import Dash from "../../common/dash";
import {
  DataGrid,
  GridCellParams,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import {
  Alert,
  Button,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  Input,
  Modal,
  Select,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ElIf, If } from "rc-if-else";
import Menu, { MenuProps } from "@mui/material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import deactivateGroup from "../../common/images/deactivate.svg";
import deactivate from "../../common/images/deactivate.svg";
import success from "../../common/images/success.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@material-ui/core/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import activateGroup from "../../common/images/activate.svg";
import { SessionModal } from "../Modals/SessionExpiredModal/sessionModal";
import { SuccessModal } from "../Modals/SuccessModal/successModal";
import { QuestionModal } from "../Modals/QuestionModal/questionModal";
import Pagination from "@mui/material/Pagination";
import moment from "moment-timezone";
import { ProductCategoryUploadModal } from "../Modals/Upload/ProductCategoryUploadModal";
import { ProductUploadModal } from "../Modals/Upload/ProductUploadModal";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { ArrowLeft } from "@material-ui/icons";
import { random } from "lodash";
import decline from "../../common/images/dissaprovetick.svg";
import { ValidationMessage } from "./alertModal";

// session Expired
const sessionStyle = {
  borderRadius: "36px",
  boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
  backgroundColor: "#fff",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "660px",
  height: "570px",
  p: 4,
};

const baseUrl = process.env.REACT_APP_BASE_URL;

// Currency Formatter
const numberFormat = (value) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "KES",
  }).format(value);

// Grid Items
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": { padding: "4px 0" },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const style = {
  width: "665px",
  height: "900px",
  margin: "20px Auto 0 Auto",
  padding: "0 0 26.8px",
  borderRadius: "15px",
  backgroundColor: "#fff",
  textAlign: "right",
};
const SuccessModalStyle = {
  width: "506px",
  height: "506px",
  margin: "10% Auto 0 Auto",
  padding: "86px 24px 97.7px 61px",
  borderRadius: "36px",
  boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
  backgroundColor: "#fff",
};

// Custom Tab Styling
const AntTabs = styled(TabList)({
  borderBottom: "0px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#ffffff00",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  fontSize: "12px",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "2.75",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#6e7074",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    color: "#032541",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "#dc3545",
    fontWeight: 600,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "blue",
  },
}));

const AddProductModalstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "15px",
  backgroundColor: "#fff",
  width: "665px",
  height: "650px",
};
function CustomCellRenderer(params) {
  const value = params.value;
  const color = value === "approved" ? "#17ae7b" : value === "declined" ? "#dc3545" : "#ff8503";
  return <span style={{ color, textTransform: "capitalize", fontSize: "13px" }}>{value}</span>;
}
// const [deactivateModalOpen2, setDeactivateModalOpen2] = useState(false);
//     const DeactivateModalHandleOpen2 = () => setDeactivateModalOpen2(true);
//     const DeactivateModalHandleClose2 = () => setDeactivateModalOpen2(false);
//Active Columns
const activeColumns = [
  {
    field: "cashierName",
    headerName: "Cashier Name",
    renderHeader: (params) => (
      <strong>
        <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          Cashier Name
        </Typography>
      </strong>
    ),
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    headerClassName: "super-app-theme--header",
    renderCell: (params) => {
      return (
        <div>
          <Typography variant="body2" color="textSecondary">
            {params.value}
          </Typography>
        </div>
      );
    },
  },
  {
    field: "reason",
    headerName: "Reason",
    renderHeader: (params) => (
      <strong>
        <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          Reason
        </Typography>
      </strong>
    ),
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    headerClassName: "super-app-theme--header",
    renderCell: (params) => {
      return (
        <div>
          <Typography variant="body2" color="textSecondary">
            {params.value}
          </Typography>
        </div>
      );
    },
  },

  {
    field: "amount",
    headerName: "Amount",
    renderHeader: (params) => (
      <strong>
        <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          Amount
        </Typography>
      </strong>
    ),
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    headerClassName: "super-app-theme--header",
    renderCell: (params) => {
      return (
        <div>
          <Typography variant="body2" color="textSecondary">
            {params.value}
          </Typography>
        </div>
      );
    },
    valueFormatter: (params) => {
      // console.log("params", params);
      return numberFormat(params.value);
    },
  },
  {
    field: "createdAt",
    headerName: "Created At",
    renderHeader: (params) => (
      <strong>
        <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          Created At
        </Typography>
      </strong>
    ),
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    headerClassName: "super-app-theme--header",
    renderCell: (params) => {
      return (
        <div>
          <Typography variant="body2" color="textSecondary">
            {moment(params.value).format("DD-MM-YYYY")}
          </Typography>
        </div>
      );
    },
    
  },

  {
    field: "status",
    headerName: "Status",
    flex: 1,
    headerAlign: "center",
    renderHeader: (params) => (
      <strong>
        <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          Status
        </Typography>
      </strong>
    ),
    align: "center",
    editable: false,
    headerClassName: "super-app-theme--header",
    renderCell: CustomCellRenderer,
  },
];

const approveColumns = [
  {
    field: "cashierName",
    headerName: "Cashier Name",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => (
      <strong>
        <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          Cashier Name
        </Typography>
      </strong>
    ),
    renderCell: (params) => {
      return (
        <div>
          <Typography variant="body2" color="textSecondary">
            {params.value}
          </Typography>
        </div>
      );
    },
  },
  {
    field: "reason",
    headerName: "Reason",
    renderHeader: (params) => (
      <strong>
        <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          Reason
        </Typography>
      </strong>
    ),
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    headerClassName: "super-app-theme--header",
    renderCell: (params) => {
      return (
        <div>
          <Typography variant="body2" color="textSecondary">
            {params.value}
          </Typography>
        </div>
      );
    },
  },

  {
    field: "amount",
    headerName: "Amount",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => (
      <strong>
        <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          Amount
        </Typography>
      </strong>
    ),
    renderCell: (params) => {
      return (
        <div>
          <Typography variant="body2" color="textSecondary">
            {params.value}
          </Typography>
        </div>
      );
    },
    valueFormatter: (params) => {
      // console.log("params", params);
      return numberFormat(params.value);
    },
  },
  {
    field: "createdAt",
    headerName: "Created At",
    renderHeader: (params) => (
      <strong>
        <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          Created At
        </Typography>
      </strong>
    ),
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    headerClassName: "super-app-theme--header",
    renderCell: (params) => {
      return (
        <div>
          <Typography variant="body2" color="textSecondary">
            {moment(params.value).format("DD-MM-YYYY")}
          </Typography>
        </div>
      );
    },
    
  },

  {
    field: "status",
    headerName: "Status",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => (
      <strong>
        <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          Status
        </Typography>
      </strong>
    ),
    renderCell: (params) => {
      return (
        <div>
          <Typography variant="body2" color="textSecondary">
            {params.value}
          </Typography>
        </div>
      );
    },
  },
];
approveColumns.push({
  field: "Actions",
  headerName: "Actions",
  flex: 1,
  headerAlign: "center",
  align: "center",
  editable: false,

  renderCell: (params, event) => {
    const { row } = params;

    // Deactivate Modal Config
    const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
    const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
    const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

    const [deactivateModalOpen1, setDeactivateModalOpen1] = useState(false);
    const DeactivateModalHandleOpen1 = () => setDeactivateModalOpen1(true);
    const DeactivateModalHandleClose1 = () => setDeactivateModalOpen1(false);

    // Success Edit Modal Control
    const [successOpen, setSuccessOpen] = React.useState(false);
    const handleSuccessOpen = () => setSuccessOpen(true);
    const handleSuccessClose = () => setSuccessOpen(false);

    const [expenseRecordId, setExpenseRecordId] = useState("");
    const [expenseStatus, setExpenseState] = useState("");

    const baseUrl = process.env.REACT_APP_BASE_URL;

    const updateExpense = async (e) => {
      // {"expenseRecordId":"63f0dbb8d461567ea0a1b391","status":"approved"}
      console.log(expenseRecordId, "Expenses");
      console.log(expenseStatus, "Expenses Status");

      e.preventDefault();
      try {
        const updateExpenseResponse = await fetch(`${baseUrl}/api/action_expense`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
          body: JSON.stringify({
            expenseRecordId: expenseRecordId,
            status: expenseStatus,
          }),
        });
        if (updateExpenseResponse.status === 202) {
          DeactivateModalHandleClose();
          handleSuccessOpen();
          console.log("Succes ", updateExpenseResponse);
        } else if (updateExpenseResponse.status === 401) {
          console.log("Failed Update ");
        }
      } catch (error) {
        console.log(error);
      }
    };

    const updateExpenseDecline = async (e) => {
      // {"expenseRecordId":"63f0dbb8d461567ea0a1b391","status":"approved"}
      console.log(expenseRecordId, "Expenses");
      console.log(expenseStatus, "Expenses Status");

      // {"expenseRecordId":"63f0dbb8d461567ea0a1b391","status":"approved"}
      console.log(expenseRecordId, "Expenses");
      console.log(expenseStatus, "Expenses Status");

      e.preventDefault();
      try {
        const updateExpenseResponse = await fetch(`${baseUrl}/api/action_expense`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
          body: JSON.stringify({
            expenseRecordId: expenseRecordId,
            status: expenseStatus,
          }),
        });
        if (updateExpenseResponse.status === 202) {
          DeactivateModalHandleClose1();

          handleSuccessOpen();
          console.log("Succes ", updateExpenseResponse);
        } else if (updateExpenseResponse.status === 401) {
          console.log("Failed Update ");
        }
      } catch (error) {
        console.log(error);
      }
    };
    return (
      <div>
        <div
          className="two-icons"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            className="approve-icon"
            onClick={() => {
              DeactivateModalHandleOpen();
              console.log("I WAS CLICKED", row.id);
              setExpenseRecordId(row.id);
              setExpenseState("approved");
              // setDeactivateModalOpen2(true)
            }}
            style={{
              marginRight: "10px",
            }}
          >
            <img
              src={success}
              alt="success"
              style={{
                height: "30px",
                width: "30px",
              }}
              srcset=""
            />
          </div>
          <div className="decline-icon">
            <img
              style={{
                height: "30px",
                width: "30px",
              }}
              onClick={() => {
                DeactivateModalHandleOpen1();
                setExpenseRecordId(row.id);
                setExpenseState("declined");
              }}
              src={decline}
              alt=""
              srcset=""
            />
          </div>
        </div>
        {/* approve */}

        <QuestionModal
          deactivateModalOpen={deactivateModalOpen}
          onClose={DeactivateModalHandleClose}
          formAction={updateExpense}
          actionImage={success}
          action="Approve"
          item={row?.row}
          processName={"Approve"}
        />

        {/* Delcine */}

        <QuestionModal
          deactivateModalOpen={deactivateModalOpen1}
          onClose={DeactivateModalHandleClose1}
          formAction={updateExpenseDecline}
          actionImage={decline}
          action="Decline"
          item={row?.productName}
          processName={"Decline Expenses"}
        />

        <SuccessModal
          onClose={handleSuccessClose}
          editConfigOpen={successOpen}
          // modalRow={row.productName}
          successObject={"Expense"}
          successType={"Updated"}
        />
      </div>
    );
  },
});

const declinedColumns = [
  {
    field: "cashierName",
    headerName: "Cashier Name",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => (
      <strong>
        <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          Cashier name
        </Typography>
      </strong>
    ),

    renderCell: (params) => {
      return (
        <div>
          <Typography variant="body2" color="textSecondary">
            {params.value}
          </Typography>
        </div>
      );
    },
  },
  {
    field: "reason",
    headerName: "Reason",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => (
      <strong>
        <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          Reason
        </Typography>
      </strong>
    ),
    renderCell: (params) => {
      return (
        <div>
          <Typography variant="body2" color="textSecondary">
            {params.value}
          </Typography>
        </div>
      );
    },
  },

  {
    field: "amount",
    headerName: "Amount",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => (
      <strong>
        <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          amount
        </Typography>
      </strong>
    ),
    renderCell: (params) => {
      return (
        <div>
          <Typography variant="body2" color="textSecondary">
            {params.value}
          </Typography>
        </div>
      );
    },

    valueFormatter: (params) => {
      // console.log("params", params);
      return numberFormat(params.value);
    },
  },
  {
    field: "createdAt",
    headerName: "Created At",

    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => (
      <strong>
        <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          Created At
        </Typography>
      </strong>
    ),
    renderCell: (params) => {
      return (
        <div>
          <Typography variant="body2" color="textSecondary">
            {moment(params.value).format("DD-MM-YYYY")}
          </Typography>
        </div>
      );
    },
    valueFormatter: (params) => {
      // console.log("params", params);
      return moment(params.value);
    },
  },

  {
    field: "status",
    headerName: "Status",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => (
      <strong>
        <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          Status
        </Typography>
      </strong>
    ),
    renderCell: (params) => {
      return (
        <div>
          <Typography variant="body2" color="textSecondary">
            {params.value}
          </Typography>
        </div>
      );
    },
  },
  {
    field: "actionedBy",
    headerName: "Action By",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => (
      <strong>
        <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          Action By
        </Typography>
      </strong>
    ),
    renderCell: (params) => {
      return (
        <div>
          <Typography variant="body2" color="textSecondary">
            {params.value}
          </Typography>
        </div>
      );
    },
  },
];

const Expenditure = () => {
  // session Modal Config
  const [sessionOpen, setSessionOpen] = React.useState(false);
  const [productModalOpen, setProductModalOpen] = useState(false);
  const handleProductClose = () => {
    setProductModalOpen(false);
  };
  const handleSessionOpen = () => setSessionOpen(true);
  const handleSessionClose = () => setSessionOpen(false);
  const handleLogin = () => {
    window.location.href = "/logout";
  };

  // handle tab Changes
  const [tabValue, setTabValue] = React.useState("Active");
  console.log(tabValue);
  const handleTabChange = (event, newValue) => {
    console.log("TabValue", newValue);
    setTabValue(newValue);
  };

  // Modal Add Expenditure
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Modal Cashier

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  // Success Modal Control
  const [successOpen, setSuccessOpen] = React.useState(false);
  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleSuccessClose = () => setSuccessOpen(false);

  // Tabs Setters and Getters
  const [activeTab, setActiveTabs] = useState([]);
  const [approveTab, setApprovesTab] = useState([]);
  const [declineTab, setDeclineTab] = useState([]);

  const [searchName, setSearchName] = useState("");
  const [searchValue, setSearchValue] = useState("");

  // alert Config
  const [deactivateModalOpen, setDeactivateModalOpen3] = useState(false);
  const DeactivateModalHandleOpen3 = () => setDeactivateModalOpen3(true);
  const DeactivateModalHandleClose3 = () => setDeactivateModalOpen3(false);

  // Datagrid Config
  //Filtering and Search
  const activeRowData = activeTab.map((item) => ({
    id: item._id,
    cashierName: item.cashierName,
    reason: item.reason,
    amount: item.amount,
    createdAt: item.createdAt,
    status: item.status,
  }));
  const approveRowData = approveTab.map((item) => ({
    id: item._id,
    cashierName: item.cashierName,
    reason: item.reason,
    amount: item.amount,
    createdAt: item.createdAt,
    status: item.status,
  }));
  const DeclineRowData = declineTab.map((item) => {
    return {
      id: item._id,
      cashierName: item.cashierName,
      reason: item.reason,
      amount: item.amount,
      createdAt: item.createdAt,
      status: item.status,
      status: item.status,
      actionedBy: item.actionedBy,
    };
  });
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    let filteredData = activeRowData;
    setFilteredData(activeRowData);
    filteredData = filterBySearchBox(filteredData);

    // console.log("uE", filteredData)
  }, [activeTab, searchValue]);

  const [filteredApproveData, setFilteredApprove] = useState([]);

  useEffect(() => {
    let filteredApproveData = approveRowData;
    console.log(filteredApproveData, "filteredApproveData");

    setFilteredApprove(approveRowData);
    filteredApproveData = filterBySearchBox(filteredApproveData);
    // console.log("uE", filteredData)
  }, [approveTab, searchValue]);

  const [filteredDeclineData, setFilteredDecline] = useState([]);

  useEffect(() => {
    let filteredDeclineData = DeclineRowData;
    console.log(filteredDeclineData, "filteredDeclineData----------------------->");

    setFilteredDecline(DeclineRowData);
    filteredDeclineData = filterBySearchBox(filteredDeclineData);
    console.log(filteredDeclineData, "filteredDeclineData----------------------->");
  }, [declineTab, searchValue]);

  const filterBySearchBox = (data) => {
    return data.filter((item) => {
      if (
        item?.cashierName?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        item?.reason?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        item?.status?.toLowerCase().includes(searchValue?.toLowerCase())
      ) {
        return item;
      }
    });
  };

  const SuccessModalStyle = {
    width: "506px",
    height: "506px",
    margin: "10% Auto 0 Auto",
    padding: "86px 24px 97.7px 61px",
    borderRadius: "36px",
    boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
    backgroundColor: "#fff",
  };

  // Urls
  const fetchReasons = baseUrl + "/api";
  const postUrl = baseUrl + "/api/createProduct";

  const [pageSize, setPageSize] = React.useState(10);

  //Filtering and Search

  const handleSearchBox = (event) => {
    setSearchValue(event.target.value);
    console.log(searchValue, "hello search.........................................");
  };

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
    // search : searchValue
  });

  // Check if Tab is on Active
  // Fetch Active Tabs
  const ActiveTab = async () => {
    try {
      // console.log("ON");
      setPageState((old) => ({ ...old, isLoading: true }));
      const ActiveResponse = await fetch(
        // `${baseUrl}/api/get_branch_expenses?limit=1000&page=1&status=all&filter=all&search=`,
        `${baseUrl}/api/get_branch_expenses?limit=${pageState.pageSize}&page=${pageState.page}&status=all&filter=all&search=${searchValue}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        }
      );
      const data = await ActiveResponse.json();
      setActiveTabs(data.data.records);
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: data.data.records,
        total: data.data.records.count,
      }));

      if (ActiveResponse.status === 200) {
        setActiveTabs(data.data.records);
        console.log("response Data", data.data.records);

        localStorage.setItem("tabValue", "Active");
      } else if (ActiveResponse.status === 401) {
        handleSessionOpen();
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    ActiveTab();
  }, [tabValue, pageState.page, pageState.pageSize, searchValue]);

  // Check if tab is on New

  // Fetch Approves yet to be Approved

  const approvesTabs = async () => {
    try {
      const approvesResponse = await fetch(
        `${baseUrl}/api/get_branch_expenses?limit=${pageSize.pageSize}&page=${pageState.page}&status=pending&filter=all&search=${searchValue}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        }
      );
      console.log(">>>>>>>>>>>>>>AR", approvesResponse);

      const data = await approvesResponse.json();
      if (approvesResponse.status === 200) {
        console.log(">>>>>>>>>>>>>> Appoves Data ", data.data.records);

        setApprovesTab(data.data.records);
        localStorage.setItem("tabValue", "Approves");
      } else if (approvesResponse.status === 401) {
        handleSessionOpen();
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    approvesTabs();
  }, [tabValue]);

  // get all pending expenses

  // Check if tab is on Pending
  // Fetch Edits Approval Tabs
  console.log("Decline On");
  const DeclineTabs = async () => {
    try {
      const declineResponse = await fetch(
        `${baseUrl}/api/get_branch_expenses?limit=${pageSize.pageSize}&page=${pageState.page}&status=declined&filter=all&search=${searchValue}`,

        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        }
      );
      const data = await declineResponse.json();
      if (declineResponse.status === 200) {
        setDeclineTab(data.data.records);
        console.log("Response Decline Data", data.data.records);
        localStorage.setItem("tabValue", "Decline");
      } else if (declineResponse.status === 401) {
        handleSessionOpen();
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    DeclineTabs();
  }, [tabValue]);

  const [reasons, setReasons] = useState([]);
  const [CashierList, setCashierList] = useState([]);

  const [CashierName, setCashierName] = useState("");
  const [CashierId, setCashierId] = useState("");
  const [ReasonExpense, setReasonExpense] = useState("Select Reasons");
  const [Amount, setAmount] = useState("");
  const [cashierBalance, setCashierBalance] = useState("");
  const [message, setMessage] = useState("Error");

  // Error Notification
  const [errorNotificationOpen, setErrorNotificationOpen] = React.useState(false);
  const handleErrorNotificationClick = () => {
    setErrorNotificationOpen(true);
  };
  const handleErrorNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorNotificationOpen(false);
  };

  // console.log("clicked cashier name", CashierName);

  useEffect(() => {
    getReasons();
    getCashierDetails();
  }, []);

  // console.log("CashierId", CashierId);
  // console.log("ID", ReasonExpense);
  // console.log("Amount", Amount);
  // try {

  <SuccessModal
    onClose={handleSuccessClose}
    editConfigOpen={successOpen}
    modalRow={"Successfully Added"}
    successObject={"Product"}
    successType={"Created"}
  />;

  const handleSubmitExpense = async (e) => {
    e.preventDefault();
    console.log("CashierId", CashierId);
    console.log("ID", ReasonExpense);
    console.log("Amount", Amount);
    console.log("cashierBalance", cashierBalance);
    if (CashierId === "" || ReasonExpense === "" || Amount === "") {
      // alert("Please fill all the fields")
      setMessage("Please fill all the fields to create an expense request");
      setDeactivateModalOpen3(true);
      // setDeactivateModalOpen2(true)
    } else if (Amount > cashierBalance) {
      // alert("Amount is greater than cashier balance")
      setMessage("Amount is greater than cashier balance kindly choose another cashier with sufficient balance");
      // setDeactivateModalOpen2(true)
      setDeactivateModalOpen3(true);
    } else if (totalexpenditureCash + parseInt(Amount) > cashierBalance) {
      // alert("Amount is greater than cashier balance")
      setMessage("Amount is greater than cashier balance kindly approve or decline pending expenses");
      // setDeactivateModalOpen2(true)
      setDeactivateModalOpen3(true);

      //  console.log("checking if condition has been met", (totalexpenditureCash ), (cashierBalance) , ( Amount), (amount_pending + Amount) )
    } else {
      try {
        fetch(`${baseUrl}/api/save_business_expense`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
          body: JSON.stringify({
            cashierId: CashierId,
            reasonId: ReasonExpense,
            amount: Amount,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("Success:", data);
            console.log("ss", data.status);
            if (data.status == "SUCCESS") {
              handleSuccessOpen();
              setCashierName("");
              setReasonExpense("");
              setAmount("");
            } else if (data.status === "error") {
              setMessage("Error", data.message);
              // setDeactivateModalOpen2(true)
              setDeactivateModalOpen3(true);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getReasons = async () => {
    console.log("getReasons");
    try {
      const response = await fetch(`${baseUrl}/api/get_business_expense_reasons`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      await response.json().then((data) => {
        setReasons(data.reasons);
        console.log("reasons", data.reasons);
      });
    } catch (e) {
      console.log(e);
    }
  };
  const [pendingExpenses, setpendingExpenses] = useState("");

  const checkPendingSettlement = async (cashierId) => {
    try {
      const res = await fetch(`${baseUrl}/api/get_all_expenditure_pending_not_settled?cashierId=${cashierId}`, {
        method: "GET",
        headers: {
          Content_Type: "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      const data = await res.json();
      if (res.status === 200) {
        console.log("-------------get_settlements-->", data.data);
        // setSetCashierDetails(data.data)SetSettlements
        setpendingExpenses(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  var totalexpenditureCash = 0;

  for (let i = 0; i < pendingExpenses.length; i++) {
    totalexpenditureCash += pendingExpenses[i].amount;
  }
  console.log(totalexpenditureCash, "Wewe ng");

  const getCashierDetails = async () => {
    console.log("getCashierDetails");
    try {
      const response = await fetch(`${baseUrl}/api/get_cashier`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      await response.json().then((data) => {
        setCashierList(data.data.cashierList);
        console.log("cashier", data.data.cashierList);
      });
    } catch (e) {
      console.log(e);
    }
  };

  // Hiding elements handler
  const [showButton, setShowButton] = useState(true);
  const handleButtonVisibility = () => {
    if (localStorage.group.getItem === "Admin") {
      setShowButton(false);
    }
  };
  // create an object with and amount,name and id

  // SAVE EXPENSE

  return (
    <div>
      <div>
        <div>
          <Box>
            <Grid container>
              <Grid item marginTop="22px" marginBottom="22px">
                <span
                  style={{
                    width: "78px",
                    height: "35px",
                    margin: "0px 41px 22px 12px",
                    fontFamily: "Poppins",
                    fontSize: "25px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.32",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#dc3545",
                  }}
                >
                  Expenditure
                </span>
              </Grid>
            </Grid>
            <Grid container columns={1}>
              <Grid item xs={1}>
                <span
                  style={{
                    height: "28px",
                    margin: "21px 0px 39px 12px",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.65",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#6e7074",
                  }}
                >
                  Dashboard
                </span>
                <span
                  style={{
                    height: "28px",
                    margin: "21px 0px 39px 12px",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.65",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#6e7074",
                  }}
                >
                  \
                </span>
                <span
                  style={{
                    height: "28px",
                    margin: "21px 0px 39px 12px",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.65",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#032541",
                  }}
                >
                  Expenditure
                </span>
              </Grid>
            </Grid>
            <Grid container columns={1}>
              <Grid item xs={0.9} md={0.85}></Grid>
              {localStorage.getItem("group") !== "Admin" ? (
                <Grid container justifyContent={"flex-end"}>
                  <Grid item>
                    <Button
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "9px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "2.78",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#f5f5f5",
                        width: "136px",
                        height: "37px",
                        padding: "12px 8.1px 12px 11px",
                        borderRadius: "8px",
                        backgroundColor: "#032541",
                        marginBottom: "20px",
                        marginRight: "20px",
                      }}
                      onClick={handleOpen}
                      startIcon={<AddIcon />}
                    >
                      Add Expense
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                // <div>
                //
                //
                // </div>

                ""
              )}
            </Grid>
            {/* <Grid container columns={1} sx={{ marginTop: "22px" }}>
              <Grid item xs={0.9} md={0.755}></Grid>
              <Grid item>
                <label
                  style={{
                    width: "35px",
                    height: "12px",
                    fontFamily: "Poppins",
                    fontSize: "9px",
                    fontWeight: "bold",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "2.78",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#032541",
                    marginTop: "20px",
                  }}
                >
                  Search:
                </label>
                <input
                  value={searchValue}
                  onChange={handleSearchBox}
                  style={{
                    fontSize: "12px",
                    color: "#272d3b",
                    borderRadius: "6px",
                    width: "238px",
                    height: "36px",
                    margin: "0 0 0 20.4px",
                    padding: "7px 107.7px 6.4px 11.1px",
                    objectFit: "contain",
                    border: "solid 1px #032541",
                  }}
                  placeholder="search here"
                />
              </Grid>
            </Grid> */}
            <TabContext value={tabValue}>
              <Box>
                <Grid container justifyContent="space-between" marginBottom="10px">
                  <Grid item xs={5} md={5}>
                    <AntTabs
                      onChange={handleTabChange}
                      aria-label="Expenses tabs"
                      TabIndicatorProps={{ hidden: false }}
                      textColor="primary"
                    >
                      <AntTab
                        label="Expenses"
                        style={{
                          fontSize: "15px",
                        }}
                        value="Active"
                      />
                      <AntTab
                        label="Approval"
                        style={{
                          fontSize: "15px",
                        }}
                        value="Approves"
                      />
                      <AntTab
                        label="Decline"
                        style={{
                          fontSize: "15px",
                        }}
                        value="Decline"
                      />
                    </AntTabs>
                  </Grid>

                  <Grid
                    item
                    style={{
                      marginTop: "-10px",
                    }}
                  >
                    <label
                      style={{
                        width: "35px",
                        height: "12px",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                        fontWeight: "bold",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "2.78",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#032541",
                        marginTop: "20px",
                        marginRight: "5px",
                      }}
                    >
                      Search:
                    </label>
                    <input
                      value={searchValue}
                      onChange={handleSearchBox}
                      style={{
                        fontSize: "12px",
                        color: "#272d3b",
                        borderRadius: "6px",
                        width: "238px",
                        height: "36px",
                        margin: "0 0 0 20.4px",
                        padding: "7px 107.7px 6.4px 11.1px",
                        objectFit: "contain",
                        border: "solid 1px #032541",
                      }}
                      placeholder="search here"
                    />
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <Grid item>
                      <Button
                        style={{
                          width: "50px",
                          height: "37px",
                          margin: "0 12px 0 0",
                          padding: "9px 10px 8px 11px",
                          backgroundColor: "#05d8e1",
                          color: "white",
                        }}
                      >
                        CSV
                      </Button>
                      <Button
                        style={{
                          width: "50px",
                          height: "37px",
                          margin: "0 12px",
                          padding: "9px 7px 8px",
                          backgroundColor: "#032541",
                          color: "white",
                        }}
                      >
                        Excel
                      </Button>

                      <Button
                        style={{
                          width: "50px",
                          height: "37px",
                          margin: "0 0 0 12px",
                          padding: "9px 12px 8px",
                          backgroundColor: "#dc3545",
                          color: "white",
                        }}
                      >
                        PDF
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <TabPanel value="Active">
                <DataGrid
                  style={{
                    height: "500px",
                    width: "auto",
                    overflow: "visible",
                  }}
                  rows={filteredData}
                  rowCount={pageState.total}
                  loading={pageState.isLoading}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  pagination
                  page={pageState.page - 1}
                  pageSize={pageState.pageSize}
                  paginationMode="server"
                  onPageChange={(newPage) => {
                    setPageState((old) => ({
                      ...old,
                      page: newPage + 1,
                      pageSize: pageSize,
                    }));
                  }}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  columns={activeColumns}
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "10px",
                    color: "#272d3b",
                  }}
                />
              </TabPanel>

              <TabPanel value="Approves">
                <DataGrid
                  style={{ height: "500px", width: "auto" }}
                  rows={filteredApproveData}
                  columns={approveColumns}
                  pageSize={pageSize}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  pagination
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "10px",
                    color: "#272d3b",
                    type: "number",
                  }}
                />
              </TabPanel>

              <TabPanel value="Decline">
                <DataGrid
                  style={{ height: "500px", width: "auto" }}
                  rows={filteredDeclineData}
                  columns={declinedColumns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  pagination
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "10px",
                    color: "#272d3b",
                  }}
                />
              </TabPanel>
            </TabContext>
          </Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={AddProductModalstyle}>
              <Box
                sx={{
                  width: "665px",
                  height: "90px",
                  margin: "0 0 9px",
                  padding: "35px 30px 34px",
                  borderRadius: "16px",
                  backgroundColor: "#dc3545",
                }}
              >
                <Typography
                  style={{
                    width: "260px",
                    height: "21px",
                    margin: "0 423px 0 0",
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#fff",
                  }}
                >
                  Add Expenses
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "605.2px",
                  height: "494.5px",
                  margin: "18.2px 29.4px 6.2px 30.4px",
                  padding: "33.8px 55.6px 0.8px 43.6px",
                  backgroundColor: "#f5f7ff",
                }}
              >
                <form>
                  <FormControl
                    variant="standard"
                    style={{
                      width: "100%",
                      height: "100%",
                      margin: "0 0 9px",
                    }}
                  >
                    <TextField id="Cashier" label="Select Cashier" value={CashierName} required onClick={handleOpen1} />
                    <Modal
                      open={open1}
                      onClose={handleClose1}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={AddProductModalstyle}>
                        <Box
                          sx={{
                            width: "665px",
                            height: "90px",
                            margin: "0 0 9px",
                            padding: "35px 30px 34px",
                            borderRadius: "16px",
                            backgroundColor: "#dc3545",
                          }}
                        >
                          <Typography
                            style={{
                              width: "260px",
                              height: "21px",
                              margin: "0 423px 0 0",
                              fontFamily: "Poppins",
                              fontSize: "15px",
                              fontWeight: "normal",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              textAlign: "center",
                              color: "#fff",
                            }}
                          >
                            Add Expense
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            width: "605.2px",
                            height: "494.5px",
                            margin: "18.2px 29.4px 6.2px 30.4px",
                            padding: "33.8px 55.6px 0.8px 43.6px",
                            backgroundColor: "#f5f7ff",
                          }}
                        >
                          <form>
                            <FormControl variant="standard">
                              <Grid container spacing={3}>
                                <Grid item xs={4}>
                                  <ArrowLeft onClick={handleClose1} height="20px" width="20px" />
                                </Grid>
                                <Grid item xs={4}>
                                  SELECT CASHIER
                                </Grid>
                                <Grid item xs={4}>
                                  <ArrowLeft />
                                </Grid>
                              </Grid>
                              <Input
                                text="text"
                                style={{
                                  width: "90%",
                                  height: "40px",
                                  margin: "0 0 10px",
                                  padding: "0 0 0 10px",
                                  borderRadius: "4px",
                                  outline: "none",

                                  backgroundColor: "#ffffff",
                                  fontFamily: "Poppins",
                                  outline: "none",
                                  border: "none",
                                }}
                                startDecorator={<SearchIcon />}
                                placeholder="Search Cashier Name"
                                value={searchName}
                                onChange={(e) => setSearchName(e.target.value)}
                              />
                              <div className="all-cashier" style={{
                                height: "230px",
                                overflow:"scroll",
                                // add a webkit scrollbar
                                
                                // hide the scrollbar
                                
                              }}>
                              {CashierList.length > 0 &&
                                CashierList.filter((cashier) => {
                                  return searchName.toLowerCase() === ""
                                    ? cashier
                                    : cashier.cashierName.toLowerCase().includes(searchName.toLowerCase());
                                }).map((cashier) => (
                                  <div style={{
                                    // height:"600px",
                                    // overflow:"scroll"

                                  }}>
                                    
                                    <Grid
                                      onClick={() => {
                                        // cashier.cashierBalance == 0 ||
                                        if (cashier.cashierBalance == 0 || cashier.cashierBalance < 0) {
                                          setMessage(
                                            "Cashier Balance is less than zero. Please choose another cashier with  sufficient balance"
                                          );
                                          // setDeactivateModalOpen2(true)
                                          setDeactivateModalOpen3(true);
                                        } else {
                                          setCashierName(cashier.cashierName);
                                          setCashierId(cashier.cashierId);
                                          setCashierBalance(cashier.cashierBalance);
                                          checkPendingSettlement(cashier.cashierId);
                                          handleClose1();
                                          console.log("we want to run a function ======>");
                                        }

                                        // setCashierName(cashier.cashierName);
                                        // setCashierId(cashier.cashierId);
                                        // handleClose1();
                                      }}
                                      container
                                      spacing={2}
                                      justifyContent="center"
                                      style={{
                                        border: "solid 1px #d9d9d9",
                                        borderRadius: "4px",
                                        width: "90%",
                                        marginLeft: "2px",
                                        marginTop: "5px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <Grid item xs={6}>
                                        <h6
                                          style={{
                                            margin: " 0 5px 2px 0",

                                            fontSize: "15px",
                                            fontWeight: "bold",
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "1.67",
                                            letterSpacing: "normal",
                                            textAlign: "left",
                                            color: "#032541",
                                          }}
                                        >
                                          {cashier.cashierName}
                                        </h6>
                                        <h6
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "normal",
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "normal",
                                            textAlign: "left",
                                            color: "#6e7074",
                                          }}
                                        >
                                          Station: <span>{cashier.branchName}</span>
                                        </h6>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          textAlign: "right",
                                          width: "79x",
                                          height: "16px",
                                          margin: "1px 0 0 0",
                                          fontFamily: "Poppins",
                                          fontSize: "11px",
                                          fontWeight: "bold",
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "1.27",
                                          letterSpacing: "normal",
                                          color: "#17ae7b",
                                        }}
                                      >
                                        <h6
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "normal",
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "normal",
                                            color: "#6e7074",
                                          }}
                                        >
                                          Available Balance
                                        </h6>
                                        <h6
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "normal",
                                            color: "#17ae7b",
                                          }}
                                        >
                                          {cashier.localCurrency} {cashier.cashierBalance}
                                        </h6>
                                      </Grid>
                                    </Grid>
                                  </div>
                                ))}
                                
                                
                              </div>

                              {/* get the current variable stored for productType the use for the first time to dedicate show or hide */}
                              <Grid container spacing={3} sx={{ marginTop: 5 }}>
                                <Grid item xs={3}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    style={{
                                      alignSelf: "right",
                                      width: "120px",
                                      height: "39px",
                                      margin: "10px 230px 0",
                                      borderRadius: "4px",
                                      backgroundColor: "#dc3545",
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                      fontWeight: "normal",
                                      fontStretch: "normal",
                                      fontStyle: "normal",
                                      lineHeight: "3.14",
                                      letterSpacing: "normal",
                                      textAlign: "left",
                                      color: "#fff",
                                    }}
                                  >
                                    Save
                                  </Button>
                                </Grid>
                                <Grid item xs={3}>
                                  <Button
                                    onClick={handleClose1}
                                    variant="contained"
                                    color="primary"
                                    style={{
                                      width: "120px",
                                      height: "39px",
                                      margin: "10px 230px 0",
                                      borderRadius: "4px",
                                      backgroundColor: "#032541",
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                      fontWeight: "normal",
                                      fontStretch: "normal",
                                      fontStyle: "normal",
                                      lineHeight: "3.14",
                                      letterSpacing: "normal",
                                      textAlign: "left",
                                      color: "#fff",
                                    }}
                                  >
                                    Close
                                  </Button>
                                </Grid>
                              </Grid>
                            </FormControl>
                          </form>
                        </Box>
                      </Box>
                    </Modal>

                    <TextField
                      value={ReasonExpense}
                      onChange={(e) => setReasonExpense(e.target.value)}
                      id="Reasons"
                      select="true"
                      label="Reasons"
                      defaultValue="Reasons"
                      required
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      {/* <MenuItem  style={{ fontFamily: "Poppins" }}>
                        Select Reasons
                      </MenuItem> */}
                      {reasons.map((option) => (
                        <MenuItem value={option.id} style={{ fontFamily: "Poppins" }}>
                          {option.reason}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      value={Amount}
                      onChange={(e) => setAmount(e.target.value)}
                      style={{
                        marginTop: "20px",
                      }}
                      required
                      id="outlined-multiline-static"
                      label="Enter Amount"
                      defaultValue="Amount"
                      type="number"
                      placeholder="Enter Amount"
                    />

                    <Grid container spacing={3} sx={{ marginTop: 5 }}>
                      <Grid item xs={3}>
                        <Button
                          onClick={handleClose}
                          variant="contained"
                          color="primary"
                          style={{
                            width: "120px",
                            height: "39px",
                            margin: "10px 230px 0",
                            borderRadius: "4px",
                            backgroundColor: "#032541",
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: "normal",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "3.14",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#fff",
                          }}
                        >
                          Close
                        </Button>
                      </Grid>
                      <Grid item xs={3}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{
                            alignSelf: "right",
                            width: "120px",
                            height: "39px",
                            margin: "10px 230px 0",
                            borderRadius: "4px",
                            backgroundColor: "#dc3545",
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: "normal",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "3.14",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#fff",
                          }}
                          onClick={handleSubmitExpense}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </FormControl>
                </form>
              </Box>
            </Box>
          </Modal>
        </div>
      </div>
      <SuccessModal editConfigOpen={successOpen} onClose={handleSuccessClose} message={"Expense"} />
      <ValidationMessage
        deactivateModalOpen={deactivateModalOpen}
        onClose={DeactivateModalHandleClose3}
        message={message}
      />
      {/* <Alert onClose={handleErrorNotificationClose} severity="error" sx={{ width: '100%' }}>
Expense Failed to add
</Alert> */}
    </div>
  );
};
export default Expenditure;
