import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { customerstyles } from "./styles";

const baseUrl = process.env.REACT_APP_BASE_URL;
import { Link } from "react-router-dom";
import DateFormatter from "../../../utils/dateFormatter";


const CustomerInvoice = (props) => {

    const customerId = props?.customerId;
    const [dataGridPageSize, setPageSize] = React.useState(5);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
  const getInvoiceDetails = async () => {
    try {
      const invoiceDetailsResponse = await fetch(baseUrl + `/api/get_invoice_by_customerId/${customerId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      if (invoiceDetailsResponse.status === 200) {
        const invoiceDetails = await invoiceDetailsResponse.json();
        const rowsWithIds = invoiceDetails.data.map((row) => {
          const createdAt = DateFormatter(row?.createdAt) 
          return { ...row, id: row._id, createdAt };
        });

       setPageState({...pageState, isLoading: false, data: rowsWithIds, count: invoiceDetails.count}) 
      }
    } catch (error) {
      console.error("error fetching invoice details", error.message);
    }
  };

  useEffect(() => {
    // console.log("running")
    getInvoiceDetails();
  }, [customerId, pageState.page, dataGridPageSize]);
  return (
    <div>
      <DataGrid
        pagination
        rows={pageState?.data}
        style={customerstyles.dataGridStyling}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        rowCount={pageState.count}
        loading={pageState.isLoading}
        page={pageState.page - 1}
        pageSize={dataGridPageSize}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize }));
        }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        columns={[
          {
            field: "invoiceNumber",
            headerName: "Invoice No",
            minWidth: 250,
            renderCell: (params) => {
              return <Link to={`/invoice/${params.value}`}>{params.value}</Link>;
            },
          },
          { field: "createdAt", headerName: "Date Issued", flex: 1 },
          { field: "invoiceAmount", headerName: "Amount", flex: 1 },
          { field: "invoiceType", headerName: "Type", flex: 1 },
          {
            field: "invoiceStatus",
            headerName: "Status",
            renderCell: (params) => {
              return (
                <span style={params?.row?.invoiceStatus === "Paid" ? { color: "green" } : params?.row?.invoiceStatus === "Unpaid" ? { color: "red" } : { color: "orange" }}>{params.row.invoiceStatus}</span>
                // Paid
                // Unpaid
                // Partially Paid
              );
            },
            flex: 1,
          },
        ]}
        getRowId={(row) => row._id}
      />
    </div>
  );
};

export default CustomerInvoice;
