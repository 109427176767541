import React, { useState, useEffect } from "react";
import { Card, Grid, Input, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { stockStyles } from "./stockComponents/stockStyling";
import Breadcrumbcomponent from "./stockComponents/breadcrumb";
import stocktakeicon from "../../common/images/stocktakeicon.svg";
import addstockicon from "../../common/images/addstockicon.svg";
import activestockicon from "../../common/images/ActiveStock.svg";
import stocktransfericon from "../../common/images/stocktransfericon.svg";
import stockreturnicon from "../../common/images/stockreturnsicon.svg";
import stockapprovalicon from "../../common/images/approvalsicon.svg";
import reportsicon from "../../common/images/stockreporticon.svg";
import stockOrderIcon from "../../common/images/stockOrder.svg"
import { useSelector } from "react-redux";
import { ErrorAlertRightAligned } from "../bills/startorderModals/modals";
import jsondecode from "jwt-decode"

// Format currency to Local
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);

// Active
const activeStyles = makeStyles({ active: { border: "2px solid #53365c" } });

// Add Stock
const addStyles = makeStyles({ active: { border: "2px solid #06684c" } });

// Stock Take
const stockTakeStyles = makeStyles({ active: { border: "2px solid #81343B" } });

// Report
const reportStyles = makeStyles({ active: { border: "2px solid #032541" } });

// Approval
const approvalStyles = makeStyles({ active: { border: "2px solid #DC3545" } });

export default function Stock() {
  // Handle Page Size
  const [pageSize, setPageSize] = useState(5);
  const [categoriesCount, setCategoriesCount] = useState(5);
  const navigate = useNavigate();
  // Conditional Rendering
  const [level, setLevel] = useState(1);
  const setActiveLevel = (level) => {
    setLevel(level);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  // Pagination Configs
  const noOfPages = Math.ceil(categoriesCount / pageSize);
  const [page, setPage] = React.useState(1);
  const handlePage = (event, value) => {
    setPage(value);
    console.log(value);
  };

  // Tab on Click
  const classes = [activeStyles(), addStyles(), stockTakeStyles(), reportStyles(), approvalStyles()];
  const [activeCard, setActiveCard] = useState(null);

  const handleClick = (index) => {
    setActiveCard(index);
  };

  // search stock;
  const [searchstock, setSearchStock] = useState("");

  const breadcrumb = [<span key={1} style={stockStyles.smallTextIntro}>Dashboard</span>, <span key={1} style={stockStyles.smallTextActive}>Stock</span>];


  const { wareHouseState } = useSelector((store) => store.sidebar);

  const stockCards = [
    { img: activestockicon, text: "Active Stock", url: `/activestock` },
    { img: stocktakeicon, text: "Stock Take", url: `/stockTake` },
    { img: addstockicon, text: "Add Stock", url: `/addstock/addstock` },
    { img: stocktransfericon, text: "Transfer Stock", url: `/transferstock/transfer` },
    { img: stockOrderIcon, text: "Stock Order", url:`/stockOrder`, },
    // { img: stockreturnicon, text: "Returns" },
    { img: stockapprovalicon, text: "Approvals", url: `/approvestock` },
    { img: reportsicon, text: "Report", url:`/reports/newReports` },
  ];

  const [isError, setIsError] = useState({state:false , message:""})
  const newToken = jsondecode(localStorage.getItem("X-Authorization"))
  const branchID = newToken?.branchId
  const ISERROR = <ErrorAlertRightAligned  message={isError.message} error={isError.state} />

  useEffect(() => {
    if(!branchID) {
      setIsError({state:true, message : 'Please select branch to continue!'})
    }
  },[])
  
  return (
    <div style={{ width: "100%" }}>
      {isError.state &&  ISERROR}
      <Grid container direction={"row"} width={"100%"} margin={"0px 0px 1% 0px"}>
        <span style={stockStyles.mediumIntroText}>Stock Management</span>
      </Grid>

      <Grid container direction={"row"} width={"100%"} margin={"0px 0px 1% 0px"}>
        <Breadcrumbcomponent breadcrumbs={breadcrumb} />
      </Grid>

      <Grid container direction={"row"} justifyContent={"center"} alignItems={"center"} width={"100%"} margin={"0px 0px 1% 0px"}>
        <Grid item>
          <span style={stockStyles.smallUltraText}>What would you like to do today?</span>
        </Grid>
      </Grid>

      <Grid container width={"100%"} margin={"4% 0px 1% 0px"} padding={"0% 5% 0% 20%"} spacing={2} justifyContent={"center"} alignItems={"center"}>
        {stockCards
		?.filter((stock) => {
			if(wareHouseState === false){
				return stock.text !== "Transfer Stock" && stock.text !== "Stock Order"
			}
      return stock
		})
		?.map((stockCard, index) => {
          return (
            <Grid item key={index} sm={6} md={4} onClick={() => navigate(stockCard?.url)}>
              <Card key={index} sx={{ width: "50%", height: "50%", objectFit: "cover", cursor: "pointer" }}>
                <CardContent >
                  {" "}
                  <p style={{textAlign:'center'}}>
                  <img src={stockCard?.img} alt="x" />{" "}
                  <Typography variant="span" component={"div"}>
                    {stockCard?.text}
                  </Typography>{" "}
                  </p>
            
                </CardContent>{" "}
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
