import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {ExpandLess} from "@material-ui/icons";
import {ExpandMore} from "@mui/icons-material";
import {Collapse} from "@mui/material";
import List from "@material-ui/core/List";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import InventoryIcon from '@mui/icons-material/Inventory';
import reportIcon from '../../components/School/Images/report.svg';
const baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

export default function SchoolReport (props) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [status, setStatus] = useState()


	const handleClick = () => {setOpen(!open);};

	const userGroup = localStorage.getItem('group')
	const businessCat = localStorage.getItem('businessCategory')
	// useEffect(()=>{
	// 	console.log("category in inventory", props.businessCat)
	// },[props.businessCat])

	const getWareHouseStatus = async () => {
		try {
			const response = await fetch(`${baseUrl}/api/v1/stores/get_inventory_status `, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					"X-Authorization": localStorage.getItem("X-Authorization"),
	
				},
			})
			const data = response.json()
				.then((response) => {
					// console.log('status getWareHouseStatus ', response.data.enabled);
					setStatus(response.data.enabled)
				})
	
		} catch (error) {
			
	
		}
	
	
	}
	
	useEffect(() => {
		getWareHouseStatus()
	
	}, [])
	return (
		<div>

			{/*Users*/}
			{businessCat === 'School' ? 
			<>
			<ListItem button onClick={handleClick}>
				<div style={{marginRight : '5%'}}>
					<img src= {reportIcon}/>
				</div>
				
					<ListItemText primary="Reports" style={{fontFamily : 'Poppins'}}/>
			


				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItem>

			{/*Collapsed Users*/}
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>

					{userGroup !== 'Admin' && userGroup === 'Merchant' || userGroup === "Director" ?
						<div>
							<Link style={{textDecoration: 'none', color : '#fff'}} to="/school/reports">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Student Report" />
									
								</ListItem>
							</Link>
							<Link style={{textDecoration: 'none', color : '#fff'}} to="/payment-reports">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Payment Report" />
								</ListItem>
							</Link>
							<Link style={{textDecoration: 'none', color : '#fff'}} to="/school/studentspaidinvoices">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Paid  Invoices Report" />
								</ListItem>
							</Link>
							<Link style={{textDecoration: 'none', color : '#fff'}} to="/school/unpaidinvoices">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Unpaid invoices Report" />
								</ListItem>
							</Link>
							<Link style={{textDecoration: 'none', color : '#fff'}} to="/school/partiallypaidinvoices">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Partially Paid invoices Report" />
								</ListItem>
							</Link>
							<Link style={{textDecoration: 'none', color : '#fff'}} to="/school/failedtransactions">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Failed Transactions" />
								</ListItem>
							</Link>
							
							{status === true ?  <>
							<Link style={{textDecoration: 'none', color : '#fff'}} to="/reports">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Product Sales Report" />
								</ListItem>
							</Link>

							

							<Link style={{textDecoration: 'none', color : '#fff'}} to="/get_total_sales_by_item_by_payments">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Sales Report" />
								</ListItem>
							</Link> </>
							: null}

							
						</div> : null}

				</List>
			</Collapse>
			</> : null }
		</div>
	)
}
